<template>
  <div>
    <v-tabs
      background-color="#151D21"
      v-model="tab"
      fixed-tabs
      centered
      class="mt-n3"
    >
      <v-tab v-for="(item, i) in tabList" :key="i">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="background-color: #212429">
      <v-tab-item v-for="(item, i) in tabList" :key="i">
        <component v-if="data" :is="item.component" :userInfo="userInfo" @updated="afterUpdate($event)" @cancel="goToOverview()"/>
        <div v-else class="pt-8 text-center">
          <v-progress-circular size="80" color="primary" indeterminate />
          <p class="pt-8">Loading...</p>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      timeout="3000"
      top
      right
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

// axios call on page, data pass through props, loader in page also -can be used using event bus

<script>
import store from "../store";
export default {
  data() {
    return {
      tab: 0,
      tabList: [
        {
          name: "Overview",
          component: () => import("../components/Profile/ProfileInfo"),
        },
        {
          name: 'Edit Profile',
          component: () => import('../components/Profile/ProfileEdit'),
        },
        {
          name: 'Change Password',
          component: () => import('../components/Profile/ChangePass'),
        }
      ],
      userInfo: {},
      data: false,

      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    };
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    afterUpdate(res) {
      // console.log('need to api call again to see the updated info, also the loader')
      this.snackbar = true
      this.data = false
      // console.log(res.success)
      if(res.success) {
        this.tab = 0
        this.getUserInfo()
        // console.log('success')
        this.snackbarText = res.snackbarText
        this.snackbarColor = res.snackbarColor
      } else {
        this.data = true
        // console.log('failed')
        this.snackbarText = res.snackbarText
        this.snackbarColor = res.snackbarColor
      }
    },
    goToOverview() {
      // console.log('tab ', this.tab)
      this.tab = 0
    },
    getUserInfo() {
      // console.log('get userInfo is calling')
      store.dispatch("userInfo").then((res) => {
        this.data = true
        this.userInfo = res.data.data.user
      })
    }
  }
};
</script>
