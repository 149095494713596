import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
// import axios from 'axios';
import configureAxios from './lib/axios';

// global custom plugin
import MyPlugin from './plugins/MyPlugin'
Vue.use(MyPlugin)

// event bus
export const eventBus = new Vue()

import Default from './templates/default';
import Bare from './templates/bare';
Vue.component('default-layout', Default);
Vue.component('bare-layout', Bare);

configureAxios();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
