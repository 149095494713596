<template>
  <v-stepper v-model="e1" style="background-color: #212429" class="elevation-0">
    <v-stepper-items>
      <v-stepper-content step="1" class="pa-3 pa-md-6">
        <RestaurantForm  @stepNum="step($event)"/>
      </v-stepper-content>

      <v-stepper-content step="2" class="pa-3 pa-md-6">
        <BranchForm @stepNum="step($event)"/>
      </v-stepper-content>

      <v-stepper-content step="3" class="pa-3 pa-md-6">
        <component :is="component" @stepNum="step($event)"/>
      </v-stepper-content>

    </v-stepper-items>

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      timeout="3000"
      top
      right
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-stepper>
</template>

<script>
import RestaurantForm from '../components/RestaurantForm.vue'
import BranchForm from '../components/BranchForm.vue'

export default {
  components: { RestaurantForm, BranchForm },
  data() {
    return {
      e1: 1,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      component: null,
      branch: '',
    }
  },
  methods: {
    step(n) {
      if(n.step) {
        if(this.e1==1) {
          this.snackbar = true
          this.snackbarColor = '#31A274'
          this.snackbarText = n.snackbarText
          this.e1 = 2
        } else if(this.e1==2) {
          this.snackbar = true
          this.snackbarColor = '#31A274'
          this.snackbarText = n.snackbarText
          this.component = () => import('../components/StaffForm')
          this.e1 = 3
        } else {
          if(n.back) {
            this.e1 = 1
          } else {
            this.e1 = 3
          }
          this.snackbar = true
          this.snackbarColor = '#31A274'
          this.snackbarText = n.snackbarText
        }
      } else {
        this.snackbarText = n.snackbarText
        this.snackbarColor = '#FF5252'
        this.snackbar = true
      }
    }
  }
}
</script>

