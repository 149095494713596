import Profile from '../../views/Profile';

export default [
  {
    path: '/profile',
    name: 'profile',
    meta: {
      layout: 'default',
      requiresAuth: true,
      title: "Profile | FoodQo Backoffice"
    },
    component: Profile,
  },
];
