import Foodcourt from '../../views/Foodcourt'

export default [
  {
    path: '/foodcourt',
    name: 'foodcourt',
    meta: {
      layout: 'default',
      requiresAuth: true,
      title: "Foodcourt | FoodQo Backoffice"
    },
    component: Foodcourt,
  },
];
