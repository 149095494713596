import Login from '../../views/Login';

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'bare',
      requiresVisitor: true,
      title: "Login | FoodQo Backoffice"
    },
    component: Login,
  },
];
