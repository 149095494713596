import Tenant from '../../views/Tenant.vue';

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'default',
      requiresAuth: true
    },
    component: Tenant,
  },
];
