<template>
  <div>
    <v-form ref="form" v-model="form">
      <span v-if="!isDialog" class="headline">Restaurant Information</span>
      <v-card :class="cardClass">
        <h3 class="mb-2"><v-chip
          class="primary black--text mr-4"
          label
        >1</v-chip>Basic</h3>

        <v-row>
          <v-col cols="12" md="10">
            <v-text-field
              v-model="restaurant.tenant_name"
              type="text"
              :rules="nameRules"
              :loading="isDialog && editLoading"
              label="Restaurant Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="restaurant.address"
              type="text"
              :rules="addressRules"
              :loading="isDialog && editLoading"
              label="Restaurant Address"
              :disabled="isDialog"
              required
            ></v-text-field>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="restaurant.user_name"
                  type="text"
                  :rules="nameRules"
                  :loading="isDialog && editLoading"
                  label="Owner Name"
                  :disabled="isDialog"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="restaurant.types_of_restaurant"
                  :items="res"
                  label="Types of Restaurant"
                  :rules="itemRules"
                  :loading="isDialog && editLoading"
                  :disabled="isDialog"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="restaurant.package_id"
                  :items="packages"
                  item-text="packageName"
                  item-value="packageId"
                  label="Package Name"
                  :rules="itemRules"
                  :loading="isDialog && editLoading"
                  :disabled="isDialog"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="restaurant.pos"
                  type="number"
                  label="No. of POS"
                  :rules="posRules"
                  :loading="isDialog && editLoading"
                  :disabled="isDialog"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="2">
            <v-card style="border-radius: 2px;border-color: gray !important;" class="card ml-2 mt-6" height="120" width="120">
              <v-img v-if="!selected" class="ml-10 mt-10" height="36" width="36" src="../assets/default-res-icon.svg"></v-img>
              <v-img v-else :src="imageBase64" height="120" width="120"><v-btn @click="removeFile" icon class="float-right"><v-icon class="primary--text">mdi-close</v-icon></v-btn></v-img>
            </v-card>
            <input v-if="!file" type="file" @change="onChange" style="display: none" ref="fileInput" accept=".jpg,.jpeg,.png">
            <!-- <v-btn :disabled="isDialog" @click="onPickFile" small class="primary--text mt-4 ml-3">Select Logo</v-btn> -->
            <v-btn :disabled="selected" @click="onPickFile" small class="primary--text mt-4 ml-3">Select Logo</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card :class="cardClass">
        <h3 class="mb-2"><v-chip
          class="primary black--text mr-4"
          label
        >2</v-chip>Security</h3>

        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="restaurant.owner_nid"
              :rules="idRules"
              :loading="isDialog && editLoading"
              type="number"
              label="Owner NID"
              :disabled="isDialog"
              required
            ></v-text-field>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="restaurant.cell"
                  :rules="contactRules"
                  :loading="isDialog && editLoading"
                  type="number"
                  label="Contact No.* (unique)"
                  :disabled="isDialog"
                  required
                  prefix="+880"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="restaurant.email"
                  :rules="emailRules"
                  :loading="isDialog && editLoading"
                  label="Email* (unique)"
                  :disabled="isDialog"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="!isDialog">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="restaurant.password"
                  :rules="passwordRules"
                  :loading="isDialog && editLoading"
                  :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  label="Password"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="restaurant.password_confirmation"
                  :rules="confirmPasswordRules"
                  :loading="isDialog && editLoading"
                  :append-icon="show2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  label="Confirm Password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="restaurant.bin_no"
                  :rules="[v => (v && v.length >=9 && v.length <= 20) || 'Length should be 9-20 Characters']"
                  :loading="isDialog && editLoading"
                  label="Bin No. (optional)"
                  :disabled="isDialog"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="restaurant.musak_no"
                  :rules="[v => (v && v.length <= 6) || 'Max 6 Characters']"
                  :loading="isDialog && editLoading"
                  label="Mushak No. (optional)"
                  :disabled="isDialog"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-card>
      <v-card :class="cardClass">
        <h3 class="mb-2">
          <v-chip
            class="primary black--text mr-4"
            label
          >3</v-chip>
          Admin Information
        </h3>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="restaurant.onboarded_by"
              :loading="isDialog && editLoading"
              type="text"
              label="Onboarding By"
              :disabled="isDialog"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="restaurant.onboarding_date"
              :loading="isDialog && editLoading"
              type="date"
              label="Onboarding Date"
              :disabled="isDialog"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="restaurant.branch_address"
              :loading="isDialog && editLoading"
              type="text"
              label="Branch Adresses"
              :disabled="isDialog"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <v-card-actions class="pr-0">
        <v-spacer></v-spacer>
        <v-btn v-if="isDialog" :disabled='(loading || !changeValue) && !imageBase64' :loading="loading" class="primary mt-4" @click="updateTenant">
          Save Changes
        </v-btn>
        <v-btn v-else :disabled='!form || loading' :loading="loading" class="primary mt-4" @click="addTenant">
          Add Restaurant
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import store from '../store'
import packages from '../dummy/packages'
export default {
  props: ["isDialog", "tenant"],
  data() {
    return {
      cardClass: "mt-6 pa-6 card",
      loading: false,
      form: false,
      step: null,
      editLoading: true,
      change: null,
      changeValue: false,
      currentRestaurant: {},
      restaurant: {
        tenant_name : "",
        bin_no: "000000000",
        musak_no: "000000",
        owner_nid: "",
        types_of_restaurant: "",
        user_name : "",
        cell : "",
        address : "",
        email : "",
        password : "",
        password_confirmation : "",
        package_id : null,
        pos: null,
        onboarded_by: '',
        branch_address: '',
        onboarding_date: ''
      },
      show1: false,
      show2: false,

      file: null,
      imageBase64: '',
      imageData: '',
      selected: false,

      res: ["Fast food", "Fast casual", "Casual dine", "Fine dine", "Cafes", "Bengali Cuisine", "Thai Cusine", "Japanese Cuisine", "Chinese Cuisine", "Korean Cuisine", "Arabian Cuisine", "Indian Cuisine", "Bakery", "Drinks and Juice Bar"],
      packages,

      nameRules: [v => !!v || 'Name is required'],
      addressRules: [v => !!v || 'Address is required'],
      idRules: [v => !!v || 'ID is required'],
      posRules: [v => !!v || 'POS is required'],
      contactRules: [v => !!v || 'Contact No is required', v => (v && v.length == 10) || 'Must be 10 Characters'],
      itemRules: [v => !!v || 'Item is required'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Please type password.',
        (v) => (v && v.length >= 6) || 'minimum 6 characters',
      ],
      confirmPasswordRules: [
        v => !!v || 'type confirm password',
        v => v === this.restaurant.password|| 'The password confirmation does not match'
      ],
    }
  },

  watch: {
    tenant() {
      this.editLoading = true
      this.loadTenantDetails()
    },
    restaurant: {
      handler(val){
        this.change+=1
        if(this.change==1) {
          this.currentRestaurant = Object.assign({}, val)
        } else {
          this.changeValue = JSON.stringify(this.currentRestaurant) !== JSON.stringify(val)
          // console.log('change >1', this.currentRestaurant, val)
        }
      },
      deep: true
    }
  },

  created() {
    this.editLoading = true
    this.loadTenantDetails()
    // if(this.isDialog) {
    //   console.log('load tenant')
    //   this.loadTenantDetails()
    // }
  },

  methods: {
    // onPickFile() {
    //   this.$refs.fileInput.click()
    // },
    // previewImage(event) {
    //   // console.log(event)
    //   if(event.target.files[0]) {
    //     // console.log(event.target.files[0])
    //     this.restaurant['logo'] = event.target.files[0]
    //     this.selectLogo = true
    //     var input = event.target
    //     if (input.files && input.files[0]) {
    //       var reader = new FileReader()
    //       reader.onload = (e) => {
    //         this.imageData = e.target.result
    //       }
    //       reader.readAsDataURL(input.files[0])
    //     }
    //   }
    // },

    // for image upload
    onChange (e) {
      const files = e.target.files || e.dataTransfer.files
      // console.log(e.target.files)
      this.createFile(files[0]) // for multiple, pass files
    },
    createFile (file) {
      // const formData = new FormData()
      // formData.append('avatar', file)
      // this.imageData = formData
      this.file = file
      this.selected = true
      this.previewImage(file)
    },
    previewImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.imageBase64 = e.target.result
        // console.log(this.imageBase64)
      }
      reader.readAsDataURL(file)
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
    removeFile () {
      this.imageBase64 = ''
      this.file = ''
      this.selected = false
    },

    loadTenantDetails() {
      this.restaurant.tenant_name = this.tenant.name
      this.restaurant.address = this.tenant.users[0].address
      this.restaurant.user_name = this.tenant.users[0].name
      this.restaurant.package_id = this.tenant.subscription.packages[0].id
      this.restaurant.types_of_restaurant = this.tenant.types_of_restaurant
      this.restaurant.pos = this.tenant.subscription.number_of_pos
      this.restaurant.owner_nid = this.tenant.owner_nid
      this.restaurant.cell = this.tenant.users[0].cell.slice(1)
      this.restaurant.email = this.tenant.users[0].email
      // password, confirm password field not shown
      this.restaurant.bin_no = this.tenant.bin_no
      this.restaurant.musak_no = this.tenant.musak_no
      this.restaurant.onboarded_by = this.tenant.onboarded_by
      this.restaurant.branch_address = this.tenant.branch_address
      this.restaurant.onboarding_date = this.tenant.onboarding_date

      this.editLoading = false

      this.changeValue = false
      // console.log('after load disable',this.form, this.loading, this.changeValue, this.change)
    },

    updateTenant() {
      // console.log(this.tenant)
      // if(this.$refs.form.validate()) {
      this.loading = true
      this.form = false
      const payload = {
        tenantName: this.restaurant.tenant_name,
        logo: this.file,
        oldLogo: this.tenant.logo,
        tenantId: this.tenant.id
      }
      store
        .dispatch('updateRestaurant', payload)
        .then(() => {
          this.form = false
          this.loading = false
          const payload = {
            snackbarColor: "#31A274",
            snackbarText: "Restaurant successfully updated",
          }
          this.$emit('tenantEditInfo',payload)
          // this.restaurant = Object.assign({}, this.currentRestaurant)
          this.currentRestaurant.tenant_name = this.restaurant.tenant_name
          this.$refs.form.reset()
          this.file = ''
          this.imageBase64 = ''
          this.selected = false
        }).catch(err => {
          console.log(err)
          this.loading = false
          const payload = {
            snackbarColor: "#FF5252",
            snackbarText: "Something Went Wrong!",
          }
          this.$emit('tenantEditInfo',payload)
        })
      // }
    },

    addTenant() {
      if(this.$refs.form.validate()) {
        this.loading = true
        this.form = false
        let restaurantPayload = {}
        Object.assign(restaurantPayload, this.restaurant)
        restaurantPayload.cell = '0' + this.restaurant.cell

        const formData = new FormData()
        if (this.file) {
          // console.log('file get >> ', this.file)
          formData.append('logo', this.file)
        }
        formData.append('tenant_name', restaurantPayload.tenant_name)
        formData.append('bin_no', restaurantPayload.bin_no)
        formData.append('musak_no', restaurantPayload.musak_no)
        formData.append('owner_nid', restaurantPayload.owner_nid)
        formData.append('types_of_restaurant', restaurantPayload.types_of_restaurant)
        formData.append('user_name', restaurantPayload.user_name)
        formData.append('cell', restaurantPayload.cell)
        formData.append('address', restaurantPayload.address)
        formData.append('email', restaurantPayload.email)
        formData.append('password', restaurantPayload.password)
        formData.append('password_confirmation', restaurantPayload.password_confirmation)
        formData.append('package_id', restaurantPayload.package_id)
        formData.append('pos', restaurantPayload.pos)
        formData.append('onboarded_by', restaurantPayload.onboarded_by)
        formData.append('branch_address', restaurantPayload.branch_address)
        formData.append('onboarding_date', restaurantPayload.onboarding_date)
        // console.log(restaurantPayload, formData)
        store
          .dispatch('createRestaurant', formData)
          .then((res) => {
            // console.log(res)
            if(res.status > 205) {
              this.form = true
              this.loading = false
              if(res.status == 422) {
                if(res.data.errors.cell && res.data.errors.email) {
                  const payload = {
                    step: this.step,
                    snackbarText: 'Email and Phone no. should be unique'
                  }
                  this.$emit('stepNum', payload)
                } else if (res.data.errors.cell) {
                  const payload = {
                    step: this.step,
                    snackbarText: 'Phone no. should be unique'
                  }
                  this.$emit('stepNum', payload)
                } else {
                  const payload = {
                    step: this.step,
                    snackbarText: 'Email should be unique'
                  }
                  this.$emit('stepNum', payload)
                }
              } else if(res.status == 500) {
                const payload = {
                  step: this.step,
                  snackbarText: 'Something Went Wrong'
                }
                this.$emit('stepNum', payload)
              } else {
                const payload = {
                  step: this.step,
                  snackbarText: res.data.message
                }
                this.$emit('stepNum', payload)
              }
            } else {
              if(restaurantPayload.logo) {
                delete restaurantPayload.logo
              }
              this.loading = false
              const payload = {
                step: 2,
                snackbarText: res.data.message
              }
              this.$emit('stepNum', payload)
              this.$refs.form.reset()
            }
          })
      }
    },
  }
}
</script>
