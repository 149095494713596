import Restaurant from '../../views/Restaurant'

export default [
  {
    path: '/restaurant/:id',
    name: 'restaurant',
    meta: {
      layout: 'default',
      requiresAuth: true,
    },
    component: Restaurant,
  },
];
