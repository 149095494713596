import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import home from './modules/home'
import auth from './modules/auth'
import addTenant from './modules/addTenant'
import profile from './modules/profile'
import notFound from './modules/notFound'
import foodcourt from './modules/foodcourt.js'
import restaurant from './modules/restaurant';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...home,
    ...auth,
    ...addTenant,
    ...profile,
    ...notFound,
    ...foodcourt,
    ...restaurant
  ],
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'FoodQo Backoffice'
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({
        name: 'login',
      });
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
