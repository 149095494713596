<template>
  <v-app style="background-color: #212429">
    <component :is="layout">
      <v-main>
        <router-view />
      </v-main>
    </component>
  </v-app>
</template>

<script>
const defaultLayout = 'default'
export default {
  name: 'App',
  computed: {
    layout() {
      return `${this.$route.meta.layout || defaultLayout}-layout`
    },
  },
  created() {
    // console.log('Running on '+ process.env.VUE_APP_MODE, 'mode')
    // console.log(this.$shortForm('abdullh al mamun'))
    // console.log(this.$myAddedProperty)
  }
};
</script>
