const MyPlugin = {
  install(Vue) {
    // Vue.prototype.$myAddedProperty = 'Example Property'

    Vue.prototype.$shortForm = function(x) {
      let shortForm = ''
      if(x.split(' ').length==1) {
        shortForm = x[0]
      } else {
        let temp = x.split(' ').map(name => name[0]).join('').toUpperCase()
        let t = temp.split('')
        // console.log(t[0], t[t.length-1])
        shortForm = [t[0],t[t.length-1]].join('')
      }
      return shortForm
    },

    Vue.prototype.$date = function(d) {
      let date = new Date(d).toDateString()
      date = date.split("")
      date.splice(10, 0, ",")
      date = date.join("")
      const data = date.slice(4)
      return data
    }
  }
};
export default MyPlugin;
