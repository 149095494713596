<template>
  <div>
    <v-tabs background-color="#151D21" v-model="tab" fixed-tabs centered class="mt-n3">
      <v-tab v-for="(item, i) in tabList" :key="i">
        <span>{{ item.name }}</span>
      </v-tab>
    </v-tabs>
    <span class="d-flex flex-row-reverse d-inline">
      <v-btn
        text
        class="mx-6 mt-4"
        color="white"
        dark
        @click="onGoBack"
      >
        <v-icon
          dark
          left
        >
          mdi-arrow-left
        </v-icon>Back
      </v-btn>
    </span>
    <v-tabs-items v-model="tab" style="background-color: #212429">
      <v-tab-item v-for="(item, i) in tabList" :key="i">
        <component :is="item.component" @tenant="getTenant" :singleTenant="singleTenant" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import store from '../../../store'
export default {
  // props:['singleTenant'],
  data () {
    return {
      tab: null,
      tabList: [
        {
          name: 'Restaurant',
          component: () => import('../SingleTenant/Restaurant'),
          title: 'Restaurant'
        },
        {
          name: 'Branch',
          component: '',
          title: 'Branch'
        }
      ],
      tenant: '',
      singleTenant: ''
    }
  },
  mounted () {
    // const nbr = store.getters.getList
    // if(!nbr.lenth) {
    //   store.dispatch('getNBRlist');
    // }
  },
  methods: {
    onGoBack() {
      this.$router.push('/')
    },
    getTenant (tenant) {
      this.singleTenant = tenant
      this.tabList[1].component = () => import('../SingleTenant/Branch')
    }
  },
}
</script>
