<template>
  <div>
    <v-form ref="form1" v-model="form1">
      <v-row>
        <v-col cols="6">
          <span v-if="!isDialog && !isDialog1" class="headline">Branch Information</span>
        </v-col>
      </v-row>
      <v-card :class="cardClass">
        <h3 class="mb-2"><v-chip
          class="primary black--text mr-4"
          label
        >1</v-chip>Basic</h3>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="branch.name"
              :rules="nameRules"
              :loading="isDialog1 && editLoading"
              type="text"
              label="Branch Name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="branch.address"
              :rules="addressRules"
              :loading="isDialog1 && editLoading"
              type="text"
              label="Branch Address"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="branch.area"
              :rules="[v => !!v || 'Area is required']"
              :loading="isDialog1 && editLoading"
              type="text"
              label="Area"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="branch.district"
              :rules="[v => !!v || 'District is required']"
              :loading="isDialog1 && editLoading"
              type="text"
              label="District"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-card :class="cardClass">
        <h3 class="mb-5"><v-chip
          class="primary black--text mr-4"
          label
        >2</v-chip>Security</h3>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="branch.phone"
              :rules="contactRules"
              :loading="isDialog1 && editLoading"
              type="number"
              label="Branch Contact No."
              prefix="+880"
              :disabled="isDialog1"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="branch.email"
              :rules="emailRules"
              :loading="isDialog1 && editLoading"
              label="Branch Email"
              :disabled="isDialog1"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-card :class="cardClass">
        <h3 class="mb-5"><v-chip
          class="primary black--text mr-4"
          label
        >3</v-chip>Settings</h3>
        <v-row class="mt-n3">
          <v-col cols="12" md="4">
            <v-checkbox
              class="ma-0"
              v-model="settings.status"
              label="VAT Status"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox
              class="ma-0"
              v-model="settings.refund"
              label="Refund"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox
              class="ma-0"
              v-model="settings.gift"
              label="Gift"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" class="pt-0">
            <v-checkbox
              class="ma-0"
              v-model="settings.mother"
              label="Mother Kitchen"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" class="pt-0">
            <v-checkbox
              class="ma-0"
              v-model="settings.use_branch_name"
              label="Use Branch Name"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" class="pt-0">
            <v-checkbox
              class="ma-0"
              v-model="settings.unpaid_checker"
              label="Unpaid Checker"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" class="pt-0">
            <v-checkbox
              class="ma-0"
              v-model="settings.dashboard"
              label="Dashboard"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" class="pt-0">
            <v-checkbox
              class="ma-0"
              v-model="settings.delivery"
              label="Online Delivery"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" class="pt-0">
            <v-checkbox
              class="ma-0"
              v-model="settings.inventory"
              label="Inventory"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" class="pt-0">
            <v-checkbox
              class="ma-0"
              v-model="settings.customer"
              label="Customer"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" class="pt-0">
            <v-checkbox
              class="ma-0"
              v-model="settings.reservation"
              label="Reservation"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-text-field
              v-model="settings.vat"
              :rules="[v => !!v || 'Vat is required']"
              type="number"
              label="VAT"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-select
              v-model="settings.service_type"
              :items="types"
              label="Service Type"
              :rules="[v => !!v || 'Service type is required']"
              required
              ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-text-field
              v-model="settings.cashier_range"
              :rules="chashierRangeRules"
              type="number"
              label="Cashier Data View Limit"
              required
              placeholder="In days"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <!-- <v-card :class="cardClass">
        <h3 class="mb-5"><v-chip
          class="primary black--text mr-4"
          label
        >4</v-chip>NBR</h3>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            <v-select
              v-model="commissioner"
              :items="commisions"
              label="Commision Type"
              item-text="name"
              return-object
              @change="selectCommision"
              ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-select
              v-model="divison"
              :items="divisons"
              label="Division Type"
              item-text="name"
              return-object
              @change="selectDivision"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-select
              v-model="circle"
              :items="circles"
              label="Circle Type"
              item-text="name"
              return-object
              @change="selectCircle"
            ></v-select>
          </v-col>
        </v-row>
      </v-card> -->
      <v-card-actions class="pr-0">
        <v-spacer></v-spacer>
        <v-btn v-if="isDialog1" :disabled='!form1 || loading || !changeValue' :loading="loading" class="primary mt-4" @click="updateBranch">
          Save Changes
        </v-btn>
        <v-btn v-else :disabled='!form1 || loading' :loading="loading" class="primary mt-4" @click="addBranch">
          Add Branch
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import store from '../store'
export default {
  props: ["isDialog","isDialog1", "editBranch", "editSettings", "isCredential"],
  data() {
    return {
      cardClass: "mt-6 pa-6 card",
      loading: false,
      form1: false,
      step: null,
      editLoading: true,
      change: null,
      changeValue: false,
      currentBranch: {},
      branch: {
        name: '',
        phone: '',
        email: '',
        address: '',
        district: '',
        area: '',
      },
      settings: {
        refund: false,
        gift: false,
        cashier_range: 3,
        status: false,
        vat: 0,
        service_type: '',
        mother: false,
        use_branch_name: false,
        unpaid_checker: false,
        dashboard: false,
        inventory: false,
        customer: false,
        reservation: false,
        delivery:false
      },
      types: ['counter', 'both'],
      // branch need to get from api call and render it in template
      branches: [],
      // nbr
      commissioner: {},
      divison: {},
      circle: {},
      divisons: [],
      commisions: [],
      circles: [],
      circle_id: '',
      nameRules: [v => !!v || 'Name is required'],
      addressRules: [v => !!v || 'Address is required'],
      contactRules: [v => !!v || 'Contact No is required', v => (v && v.length == 10) || 'Must be 10 Characters'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      chashierRangeRules: [
        v => !!v || 'Cahsier data view limit is required.',
        v => (Number.isInteger(Number(v)) && ((parseInt(v)) > 0)) || 'Cahsier data view limit must be a positive integer number.'
      ],
    }
  },

  // deep watcher
  // Object.assign() keeps no reference
  watch: {
    editBranch() {
      this.editLoading = true
      // console.log('branch changed',this.editBranch)
      this.loadBranchDetails()
    },
    branch: {
      handler(val){
        this.change+=1
        if(this.change==1) {
          this.currentBranch = Object.assign({}, val)
        } else {
          // console.log(JSON.stringify(this.currentBranch) === JSON.stringify(val))
          this.changeValue = JSON.stringify(this.currentBranch) !== JSON.stringify(val)
        }
      },
      deep: true
    }
  },

  created() {
    if(this.isDialog1) {
      this.editLoading = true
      this.loadBranchDetails()
      // console.log('edit to be this branch+ load', this.editBranch)
    }
  },
  mounted() {
    let nbr = store.getters.getList
    // if(!nbr.lenth) {
    //   store.dispatch('getNBRlist').then(() => {
    //     nbr = store.getters.getList
    //   })
    // }
    this.commisions = nbr
  },

  methods: {
    selectCommision() {
      this.divisons = this.commissioner.children
    },
    selectDivision() {
      this.circles = this.divison.children
    },
    selectCircle() {
      this.circle_id = this.circle.id
    },
    loadBranchDetails() {
      if (this.editSettings !== null) {
        this.settings.refund = this.editSettings.refund
        this.settings.gift = this.editSettings.gift
        this.settings.cashier_range = this.editSettings.cashier_range
        this.settings.status = this.editSettings.vat_status
        this.settings.vat = this.editSettings.vat_percent
        this.settings.service_type = this.editSettings.service_type
        this.settings.mother = this.editSettings.mother
        this.settings.use_branch_name = this.editSettings.use_branch_name
        this.settings.unpaid_checker = this.editSettings.unpaid_checker
        this.settings.dashboard = this.editSettings.dashboard
        this.settings.inventory = this.editSettings.inventory
        this.settings.customer = this.editSettings.customer
        this.settings.reservation = this.editSettings.reservation
        this.settings.delivery = this.editSettings.delivery
      }

      this.branch.name = this.editBranch.name

      this.branch.email = this.editBranch.email
      this.branch.phone = this.editBranch.phone.slice(1)
      
      if (this.editBranch.circle_id !== null) {
        store.dispatch('getCircleParent', this.editBranch.circle_id).then((res) => {
          if (res.parents) {
            this.divison = res.parents
            this.circles = this.divison.child
            this.circle = res
            if (this.divison.parents) {
              this.commissioner = this.divison.parents
              this.divisons = this.commissioner.child
            }
          }
        })
      }
      let str = this.editBranch.address.split(";")
      if (str.length) {
        this.branch.address = str[0]
        this.branch.area = str[1]
        this.branch.district = str[2]
      }

      this.editLoading = false

      this.changeValue = false

      // console.log('after load', this.change, this.form1, this.loading, this.changeValue)
    },
    updateBranch() {
      const branchPayload = {
        name: this.branch.name,
        phone: '0' + this.branch.phone,
        email: this.branch.email,
        address: this.branch.address + ';' + this.branch.area + ';' + this.branch.district,
        refund: this.settings.refund,
        gift: this.settings.gift,
        cashier_range: this.settings.cashier_range,
        vat_status: this.settings.status,
        vat_percent: '0' + this.settings.vat,
        service_type: this.settings.service_type,
        mother: this.settings.mother,
        use_branch_name: this.settings.use_branch_name,
        unpaid_checker: this.settings.unpaid_checker,
        dashboard: this.settings.dashboard,
        inventory: this.settings.inventory,
        customer: this.settings.customer,
        reservation: this.settings.reservation,
        delivery: this.settings.delivery
      }
      // console.log(branchPayload)
      if(this.$refs.form1.validate()) {
        this.form1 = false
        this.loading = true
        // console.log('edit branch', this.editBranch)
        // console.log(store.getters.TenantSlug)
        const payload = [
          {
            tenantSlug: store.getters.TenantSlug,
            branchId: this.editBranch.id
          },
          branchPayload
        ]
        store.dispatch('updateBranch', payload)
        .then((res) => {
          // console.log(res)
          if (res.status === 422) {
            this.form1 = true
            this.loading = false
            const payload = {
              snackbarColor: "#FF5252",
              snackbarText: res.data.message,
            }
            this.$emit('branchEditInfo',payload)
            return
          }
          if (this.circle_id !== '') {
            const nbrData = {
              tenant_slug: store.getters.TenantSlug,
              branch_id: this.editBranch.id,
              circle_id: this.circle_id
            }
            store.dispatch('attachCircle', nbrData)
          }
          this.loading = false
          const payload = {
            snackbarColor: "#31A274",
            snackbarText: "Branch successfully updated"
          }
          this.$emit('branchEditInfo',payload)
          this.currentBranch = Object.assign({}, this.branch)
          this.$refs.form1.reset()
        })
      }
    },
    addBranch() {
      const branchPayload = {
        name: this.branch.name,
        phone: '0' + this.branch.phone,
        email: this.branch.email,
        address: this.branch.address + ';' + this.branch.area + ';' + this.branch.district,
        refund: this.settings.refund,
        gift: this.settings.gift,
        cashier_range: this.settings.cashier_range,
        vat_status: this.settings.status,
        vat_percent: '0' + this.settings.vat,
        service_type: this.settings.service_type,
        mother: this.settings.mother,
        use_branch_name: this.settings.use_branch_name,
        unpaid_checker: this.settings.unpaid_checker,
        dashboard: this.settings.dashboard,
        inventory: this.settings.inventory,
        customer: this.settings.customer,
        reservation: this.settings.reservation,
        delivery: this.settings.delivery
      }
      if(this.$refs.form1.validate()) {
        this.form1 = false
        this.loading = true
        store.dispatch('createBranch', branchPayload)
          .then( (res) => {
            // console.log('this is res status', res.status)
            if(this.isDialog) {
              // console.log('its dialog')
              this.loading = false
              if (this.circle_id !== '') {
                const nbrData = {
                  tenant_slug: store.getters.TenantSlug,
                  branch_id: this.editBranch.id,
                  circle_id: this.circle_id
                }
                store.dispatch('attachCircle', nbrData)
              }
              const payload = {
                snackbarColor: "#31A274",
                snackbarText: "Branch successfully added",
                dialog: false,
              }
              
              this.$emit('branchInfo', payload)
              this.$refs.form1.reset()
            } else {
              // console.log('its not dialog')
              this.loading = false
              this.step = 3
              const payload = {
                step: 3,
                snackbarText: res.data.message
              }
              this.$emit('stepNum', payload)
              this.$refs.form1.reset()
            }
          }).catch(err => {
            // console.log('this is error', err.response.status)
            // console.log(err)
            this.form1 = true
            this.loading = false
            if(this.isDialog) {
              if (err.response.status == 422) {
                if(err.response.data.errors.phone && err.response.data.errors.email) {
                  const payload = {
                    snackbarColor: "#FF5252",
                    snackbarText: "Email and Phone no. should be unique",
                    dialog: true,
                    error: 1
                  }
                  this.$emit('branchInfo', payload)
                } else if (err.response.data.errors.phone) {
                  const payload = {
                    snackbarColor: "#FF5252",
                    snackbarText: "Phone no. should be unique",
                    dialog: true,
                    error: 1
                  }
                  this.$emit('branchInfo', payload)
                } else {
                  const payload = {
                    snackbarColor: "#FF5252",
                    snackbarText: "Email should be unique",
                    dialog: true,
                    error: 1
                  }
                  this.$emit('branchInfo', payload)
                }
              } else {
                const payload = {
                  snackbarColor: "#FF5252",
                  snackbarText: "Something Went Wrong!",
                  dialog: true,
                  error: 1
                }
                this.$emit('branchInfo', payload)
              }
            } else {
              this.$emit('stepNum', this.step)
            }
          })
      }
    }
  },
}
</script>

<style>

</style>
