import axios from "axios";
import router from "../router";
import store from "../store";
import apiOptions from "../../api.options";

export default function configureAxios() {
  axios.defaults.withCredentials = true;
  axios.defaults.headers = { "Content-Type": "application/json" };
  axios.defaults.baseURL = process.env.VUE_APP_NODE_ENDPOINT;

  axios.interceptors.request.use(
    (config) => {
      const token = store.state.auth.token;

      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }

      return config;
    },
    (err) => {
      Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      const originalRequest = err.config;
      // console.log(originalRequest)
      if (
        err.response.status === 403 &&
        originalRequest.url === apiOptions.refreshUrl
      ) {
        // await store.dispatch("logout");
        router.push({ name: 'login' });
        return Promise.reject(err);
      } else if (err.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        const res = await store.dispatch("refreshToken");
        store.commit("setToken", res.data.accessToken);

        originalRequest.headers.Authorization = "Bearer " + res.data.accessToken;

        return axios(originalRequest);
      }
      return Promise.reject(err);
    }
  );
}
