import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth';
import tenant from './modules/tenant';
import profile from './modules/profile';
import foodcourt from './modules/foodcourt';
import nbr from './modules/nbr';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    tenant,
    profile,
    nbr,
    foodcourt
  },
});
