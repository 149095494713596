<template>
  <div>
    <component :is="currentComponent" :singleTenant="singleTenant"/>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      timeout="3000"
      top
      right
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { eventBus } from '../main'
export default {
  data() {
    return {
      currentComponent1: () => import('../components/Tenant/TenantList'),
      currentComponent2 : () => import('../components/Tenant/SingleTenant/TenantDetails'),
      currentComponent: null,
      singleTenant: null,

      snackbar: false,
      snackbarText: "",
      snackbarColor: "",

    }
  },
  created () {
    this.currentComponent = this.currentComponent1
  },

  beforeMount() {
    eventBus.$on('snackbar',(res) => {
      this.snackbar = true
      this.snackbarText = res.snackbarText
      this.snackbarColor = res.snackbarColor
    })
  },

  mounted() {
    eventBus.$on('singleTenant', (res) => {
      // console.log('event bus response',res)
      this.singleTenant = res
      this.currentComponent = this.currentComponent2
    }),
    eventBus.$on('onBack', () => {
      // console.log('parent component')
      this.currentComponent = () => import('../components/Tenant/TenantList')
    })
  },
  beforeDestroy() {
    eventBus.$off('singleTenant')
    eventBus.$off('onBack')
  }
}
</script>
