import axios from 'axios'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  async updatePass(context, payload) {
    try {
      const res = await axios.put('/profile/update/password', payload)
      return res
    } catch (error) {
      return error.response
    }
  },
  async updateProfile(context, payload) {
    try {
      const res = await axios.put('/profile', payload)
      return res
    } catch (error) {
      return error.response
    }
  },
  async userInfo() {
    try {
      const res = await axios.get('/profile')
      // console.log('get call profile', res)
      return res
    } catch (error) {
      return error.response
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
