<template>
  <nav>
    <v-app-bar color="#151D21" clipped-left app>
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <img src="../assets/foodqo-icon-white.svg" height="36px" width="36px" alt="foodqo icon" />
      </v-app-bar-nav-icon>
      <router-link to="/">
        <div class="d-flex justify-center">
          <div class="font-weight-bold pl-2 title-1">Backoffice</div>
        </div>
      </router-link>

      <v-spacer></v-spacer>

      <!-- <v-icon class="mr-7">mdi-bell-ring</v-icon> -->
      <!-- <v-menu offset-y transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mr-1 mr-md-9" v-bind="attrs" v-on="on"> mdi-menu-down </v-icon>
          <span v-bind="attrs" v-on="on">{{ userName }}</span>
        </template>
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            router
            :to="item.route"
          >
            <v-list-item-title
              ><v-btn @click="menuItemClick(item.title)" text>{{
                item.title
              }}</v-btn></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-btn @click="menuItemClick('Profile')" text class="px-0 px-md-1 mr-n2 mr-md-3">
        <v-avatar size="30" class="font-weight-bold secondary">
          <span class="">{{ $shortForm(userName) }}</span>
        </v-avatar>
        <span v-if="!$vuetify.breakpoint.xs" class="ml-3 text-capitalize">{{ userName }}</span>
      </v-btn>
      <v-btn @click="menuItemClick('Logout')" icon>
        <v-icon color="#CF6679">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      clipped
      fixed
      app
      color="#151D21"
    >
      <!-- <v-list-item class="pl-2">
        <v-list-item-avatar class="secondary" style="cursor: pointer">
          <span>{{ $shortForm(userName) }}</span>
        </v-list-item-avatar>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-title
              v-bind="attrs"
              v-on="on"
              @click="goToProfile"
              style="cursor: pointer"
              >{{ userName }}</v-list-item-title
            >
          </template>
          <span>View Profile Info</span>
        </v-tooltip>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item> -->

      <v-list shaped>
        <v-list-item
          color="primary"
          v-for="link in links"
          :key="link.text"
          :to="link.route"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import router from "../router";
import store from "../store";
import { eventBus } from "../main";

export default {
  name: "Navbar",
  data() {
    return {
      clipped: false,
      drawer: true,
      mini: false,
      fixed: false,
      links: [
        {
          icon: "mdi-view-dashboard",
          text: "All Restaurants",
          route: "/",
        },
        {
          icon: "mdi-cellphone-link",
          text: "Add Restaurant",
          route: "/addtenant",
        },
        {
          icon: "mdi-food",
          text: "All Foodcourts",
          route: "/foodcourt",
        },
        // { icon: 'mdi-tag-outline', text: 'Promo Code', route: '/promo' },
      ],
      items: [
        { title: "View Profile" },
        // { title: 'Settings'},
        { title: "Logout" },
      ],
      userName: "",
    };
  },

  created() {
    this.getUserInfo()
  },

  beforeMount() {
    eventBus.$on("userUpdated", () => {
      // console.log("event bus is on in navbar")
      this.getUserInfo()
    })
  },

  methods: {
    getUserInfo() {
      store.dispatch("userInfo").then((res) => {
        this.userName = res.data.data.user.name
      })
    },
    goToProfile() {
      // console.log(this.$route.path)
      if (this.$route.path !== "/profile") {
        router.push("/profile")
      }
    },
    menuItemClick(item) {
      if (item == "Logout") {
        store.dispatch("logout")
      } else if (item == "Profile") {
        router.push("/profile");
      }
    },
  },
};
</script>
