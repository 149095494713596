export default [
  {
    packageId: 1,
    packageName: "Counter Basic",
  },
  {
    packageId: 2,
    packageName: "Counter Standard",
  },
  {
    packageId: 3,
    packageName: "Counter Advanced",
  },
  {
    packageId: 4,
    packageName: "Table Basic",
  },
  {
    packageId: 5,
    packageName: "Table Standard",
  },
  {
    packageId: 6,
    packageName: "Table Advanced",
  },
  {
    packageId: 7,
    packageName: "Food Court Basic",
  },
  {
    packageId: 8,
    packageName: "Food Court Standard",
  },
  {
    packageId: 9,
    packageName: "Food Court Advanced",
  },
]
