import axios from 'axios'

const state = {
  tenant_slug: '',
  branch_id: '',
  tenantList: [],
  singleTenant: '',
  analytics: {
    activeTenant: 0,
    inactiveTenant: 0,
    partiallyActiveTenant: 0,
    totalTenant:0,
  },
  // branchList: [],
}

const getters = {
  TenantSlug: state => {
    return state.tenant_slug
  },
  Id: state => {
    return state.branch_id
  },
  allTenants: state => {
    return state.tenantList
  },
  singleTenant: state => {
    return state.singleTenant
  },
  analytics: state => {
    return state.analytics
  }
  // Branch: state => {
  //   return state.branchList
  // }
}

const mutations = {
  setTenantSlug: (state, slug) => {
    state.tenant_slug = slug;
  },
  setTenants: (state, payload) => {
    state.tenantList = payload.slice();
  },
  setSingleTenant: (state, payload) => {
    state.singleTenant = payload
  },
  setAnalytics: (state, payload) => {
    state.analytics = payload
  }
  // setBranches: (state, branches) => {
  //   state.branchList = branches
  // }
}

const actions = {
  tenantSlug(context, slug) {
    context.commit('setTenantSlug', slug)
  },

  async deleteRestaurant(context,ids) {
    try {
      // console.log('store getting ids', ids)
      const res = await axios.delete(`/tenants`, {data: {"ids":ids} })
      return res
    } catch (error) {
      return error.response
    }
  },
  async getAllTenantsFilter (context, payload) {
    try {
      const res = await axios.get(`/tenants/filter?search=${payload.search}&page=${payload.page}`)
      // context.commit('setTenants', res.data.data.tenants.data)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getTenantsFilterStatus (context, payload) {
    try {
      const res = await axios.get(`/tenants/branches/filter?status=${payload.status}&page=${payload.page}&count=10`)
      // context.commit('setTenants', res.data.data.tenants.data)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getAllTenantsPaged(context, payload) {
    try {
      const res = await axios.get(`/tenants?page=${payload.page}&count=10`)
      context.commit('setTenants', res.data.data.tenants.data)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getAllTenants(context) {
    try {
      const res = await axios.get(`/tenants`)
      context.commit('setTenants', res.data.data.tenants.data)
      return res
    } catch (error) {
      return error.response
    }
  },

  async getTenantAnalytics(context) {
    try {
      const res = await axios.get(`/tenants/analytics`)
      context.commit('setAnalytics', res.data.data)
      return res;
    } catch (error) {
      return error.response
    }
  },

  async getSingleTenant(context,tenantId) {
    try {
      const res = await axios.get(`/tenants/${tenantId}`)
      return res
    } catch (error) {
      return error.response
    }
  },

  async getSingleBranch(context,payload) {
    try {
      const res = await axios.get(`/tenants/${payload.tenantSlug}/branches/${payload.branchId}`)
      // context.commit('setBranches', res.data.data.branches.branches)
      // console.log('get Branch', res)
      return res
    } catch (error) {
      return error.response
    }
  },

  async getBranch(context,tenantSlug) {
    try {
      const res = await axios.get(`/tenants/${tenantSlug}/branches`)
      // context.commit('setBranches', res.data.data.branches.branches)
      // console.log('get Branch', res)
      return res
    } catch (error) {
      return error.response
    }
  },

  async getStaffDetails(context,payload) {
    try {
      const res = await axios.get(`/tenants/${payload.tenantSlug}/staff/${payload.userId}`)
      return res
    } catch (error) {
      return error.response
    }
  },

  async getStaff(context,payload) {
    try {
      const res = await axios.get(`/tenants/${payload.tenantSlug}/branches/${payload.branchId}/staff`)
      return res
    } catch (error) {
      return error.response
    }
  },

  async upadatePackage(context,payload) {
    // console.log('package Payload-', payload)
    try {
      const res = await axios.post(`/packages/update`, payload)
      return res
    } catch (error) {
      return error.response
    }
  },

  async updateRestaurant(context, payload) {
    const formData = new FormData()
    formData.append('name', payload.tenantName)
    if (payload.logo) {
      formData.append('logo', payload.logo)
      formData.append('oldLogo', payload.oldLogo)
    }
    try {
      const res = await axios.put(`/tenants/${payload.tenantId}`, formData)
      return res
    } catch (error) {
      return error.response
    }
  },

  async updateAdminInfo(context, payload) {
    try {
      const res = await axios.put(`/tenants/onboarding`, payload)
      return res;
    } catch (error) {
      return error.response
    }
  },

  async updateBranch(context,payload) {
    // console.log('branch Payload-', payload)
    try {
      const res = await axios.put(`/tenants/${payload[0].tenantSlug}/branches/${payload[0].branchId}`, payload[1])
      return res
    } catch (error) {
      return error.response
    }
  },

  async updateStaff(context,payload) {
    // console.log('staff Payload-', payload)
    try {
      const res = await axios.put(`/tenants/${payload[0].tenantSlug}/staff/${payload[0].userId}`, payload[1])
      return res
    } catch (error) {
      return error.response
    }
  },

  async createRestaurant(context, payload) {
    try {
      const res = await axios.post('/tenants', payload)
      context.commit('setTenantSlug', res.data.data.tenant.tenant_slug)
      return res
    } catch (error) {
      return error.response
    }
  },

  async resetPassword(context, payload) {
    try {
      const res = await axios.put('/tenants/owner/password/reset', payload)
      return res
    } catch (error) {
      return error.response
    }
  },

  async createBranch(context, payload) {
    try {
      const res = axios.post(`/tenants/${state.tenant_slug}/branches`, payload)
      return res
    } catch (error) {
      return error.response
    }
  },

  async createStaff(context, payload) {
    try {
      const res = await axios.post(`/tenants/${state.tenant_slug}/branches/${payload.branch_id}/staff`, payload)
      return res
    } catch (error) {
      return error.response
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
