import NotFound from '../../views/NotFound';

export default [
  {
    path: '/404',
    alias: '*',
    name: 'notFound',
    meta: {
      layout: 'bare',
      requiresVisitor: true,
      title: "404 | FoodQo Backoffice"
    },
    component: NotFound,
  },
];
