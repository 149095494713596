import axios from 'axios'
import router from "../../router"
import apiOptions from '../../../api.options';

const state = {
  token: window.localStorage.getItem('foodqo_backofc_token'),
}

const getters = {
  isLoggedIn: state => !!state.token,
}

const mutations = {
  setToken: (state, token) => {
    if (token !== undefined) {
      state.token = token
      window.localStorage.setItem('foodqo_backofc_token', token)
    } else {
      window.localStorage.removeItem('foodqo_backofc_token')
      state.token = ''
    }
  }
}

const actions = {
  async refreshToken() {
    const payload = {
      client_id: process.env.VUE_APP_USER_ID,
      client_secret: process.env.VUE_APP_USER_SECRET,
    }

    try {
      const res = await axios.post(apiOptions.refreshUrl, payload, {
        withCredentials: true
      })
      return res
    } catch (error) {
      return error.response
    }
  },

  async retrieveToken(context, credentials) {
    const payload = {
      email: credentials.email,
      password: credentials.password,
      client_id: process.env.VUE_APP_USER_ID,
      client_secret: process.env.VUE_APP_USER_SECRET,
    }

    try {
      const res = await axios.post(apiOptions.loginUrl, payload, {
        withCredentials: true
      })
      context.commit("setToken", res.data.accessToken)
      router.push("/")
      return res.status
    } catch (error) {
      return error.response
    }
  },

  async logout() {
    // console.log(state.token)

    const payload = {
      client_id: process.env.VUE_APP_USER_ID,
      client_secret: process.env.VUE_APP_USER_SECRET,
    }
    try {
      await axios.post(apiOptions.logoutUrl, payload)

      window.localStorage.removeItem('foodqo_backofc_token')
      // context.commit('setToken', null)
      // router.push('/login')
      window.location.href = '/login'
    } catch (error) {
      console.log(error)
      window.localStorage.removeItem('foodqo_backofc_token')
      window.location.href = '/login'
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
