import AddTenant from '../../views/AddTenant'

export default [
  {
    path: '/addtenant',
    name: 'addtenant',
    meta: {
      layout: 'default',
      requiresAuth: true
    },
    component: AddTenant,
  },
];
