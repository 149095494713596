import axios from 'axios'

const state = {
  fcData: '',
  foodcourt_id: '',
  tenant_id: '',
  branch_id: ''
}

const getters = {
  FcData: state => {
    return state.fcData
  },
  FcId: state => {
    return state.foodcourt_id
  },
  TenantId: state => {
    return state.tenant_id
  },
  BranchId: state => {
    return state.branch_id
  },
}

const mutations = {
  setFoocourtId(state, id) {
    state.foodcourt_id = id
  },
  setsingleFc(state, data) {
    state.fcData = data
  },

  // Another info for the slab req
  setTenantId(state, id) {
    state.tenant_id = id
  },
  setBranchId(state, id) {
    state.branch_id = id
  }
}

const actions = {
  async editBranch(context, payload) {
    try {
      // console.log('foodcourt_id for edit in call', state.foodcourt_id)
      const res = await axios.put(`/food-court/${state.foodcourt_id}/branch/update`, payload)
      // console.log('in attach branch call',payload, res)
      return res
    } catch (error) {
      return error.response
    }
  },
  async detachBranch(context, payload) {
    try {
      const res = await axios.put(`/food-court/${state.foodcourt_id}/detach/branch`, payload)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getSingleFoodcourt(context, id) {
    // console.log('fc id', id)
    try {
      const res = await axios.get(`/food-court/${id}`)
      context.commit('setFoocourtId', id)
      context.commit('setsingleFc', res.data.data)
      // console.log('foodcourt state', state.foodcourt_id)
      return res
    } catch (error) {
      return error.response
    }
  },
  async attachBranch(context, payload) {
    try {
      // console.log('foodcourt_id', state.foodcourt_id)
      const res = await axios.put(`/food-court/${state.foodcourt_id}/attach/branch`, payload)
      // console.log('in attach branch call',payload, res)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getFoodcourt() {
    try {
      const res = await axios.get('/food-court')
      return res
    } catch (error) {
      return error.response
    }
  },
  async addFoodcourt(context, payload) {
    try {
      const res = await axios.post('/food-court', payload)
      // console.log('in call',payload, res)
      context.commit('setFoocourtId', res.data.data.foodCourt.id)
      // const res = 'its getting'
      return res
    } catch (error) {
      return error.response
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
