<template>
  <v-row class="full-height">
    <!-- <v-col sm="6" md="6" lg="6" class="bg hidden-xs-only">
      <svg width="100%" height="100%" viewBox="0 0 720 1024" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMid slice">
        <g clip-path="url(#clip0)">
        <g clip-path="url(#clip1)">

        <path d="M407.763 1029.44C409.979 1029.44 411.485 1027.01 411.003 1023.92C410.554 1021.07 408.389 1018.6 406.333 1018.52C404.277 1018.44 402.78 1021.07 403.169 1024.06C403.558 1027.05 405.588 1029.49 407.763 1029.44Z" fill="white" fill-opacity="0.6"/>
        <path d="M388.152 1023.89C388.533 1026.95 390.512 1029.37 392.72 1029.31C394.929 1029.26 396.325 1027.04 396.028 1024.08C395.707 1020.95 393.49 1018.32 391.325 1018.25C389.159 1018.17 387.771 1020.87 388.152 1023.89Z" fill="white" fill-opacity="0.6"/>
        <path d="M373.076 1023.75C373.372 1026.76 375.327 1029.27 377.526 1029.31C378.035 1029.31 378.538 1029.17 378.999 1028.91C379.461 1028.64 379.87 1028.26 380.199 1027.77C380.528 1027.29 380.768 1026.73 380.904 1026.13C381.04 1025.52 381.068 1024.88 380.986 1024.26C380.741 1021.12 378.643 1018.51 376.418 1018.34C374.193 1018.17 372.806 1020.75 373.076 1023.75Z" fill="white" fill-opacity="0.6"/>
        <path d="M358.144 1024.29C358.576 1027.25 360.598 1029.52 362.789 1029.36C364.98 1029.19 366.427 1026.69 365.894 1023.6C365.403 1020.79 363.195 1018.48 361.19 1018.59C359.185 1018.71 357.721 1021.34 358.144 1024.29Z" fill="white" fill-opacity="0.6"/>
        <path d="M343.145 1024.43C343.286 1025.81 343.824 1027.08 344.659 1028.01C345.495 1028.94 346.572 1029.46 347.696 1029.49C348.199 1029.47 348.692 1029.32 349.142 1029.04C349.592 1028.76 349.988 1028.37 350.302 1027.88C350.617 1027.4 350.842 1026.83 350.963 1026.23C351.085 1025.63 351.098 1025 351.004 1024.39C350.852 1022.97 350.308 1021.66 349.462 1020.68C348.617 1019.7 347.524 1019.11 346.368 1019.01C344.21 1019.04 342.789 1021.58 343.145 1024.43Z" fill="white" fill-opacity="0.6"/>
        <path d="M328.196 1024.86C328.363 1026.2 328.916 1027.42 329.754 1028.3C330.591 1029.18 331.657 1029.65 332.756 1029.64C333.278 1029.61 333.788 1029.43 334.248 1029.13C334.707 1028.82 335.105 1028.39 335.411 1027.86C335.717 1027.34 335.923 1026.74 336.014 1026.1C336.105 1025.46 336.079 1024.81 335.936 1024.19C335.695 1022.89 335.112 1021.72 334.278 1020.87C333.444 1020.03 332.405 1019.54 331.326 1019.5C330.82 1019.56 330.329 1019.74 329.887 1020.05C329.445 1020.36 329.062 1020.78 328.765 1021.29C328.467 1021.8 328.262 1022.38 328.164 1023C328.066 1023.61 328.077 1024.25 328.196 1024.86Z" fill="white" fill-opacity="0.6"/>
        <path d="M313.23 1025.06C313.385 1026.38 313.921 1027.59 314.74 1028.47C315.559 1029.34 316.606 1029.82 317.688 1029.82C318.179 1029.82 318.665 1029.7 319.112 1029.45C319.56 1029.2 319.958 1028.84 320.28 1028.38C320.602 1027.92 320.84 1027.38 320.976 1026.8C321.113 1026.22 321.146 1025.6 321.072 1025C320.909 1023.64 320.364 1022.4 319.531 1021.49C318.699 1020.57 317.633 1020.05 316.521 1020.02C316.022 1020.03 315.532 1020.18 315.085 1020.45C314.638 1020.72 314.244 1021.11 313.931 1021.59C313.618 1022.07 313.393 1022.63 313.272 1023.23C313.151 1023.83 313.136 1024.45 313.23 1025.06Z" fill="white" fill-opacity="0.6"/>
        <path d="M298.306 1025.42C298.482 1026.72 299.033 1027.89 299.856 1028.72C300.68 1029.55 301.719 1029.99 302.782 1029.94C303.287 1029.94 303.785 1029.79 304.239 1029.51C304.693 1029.24 305.091 1028.84 305.404 1028.35C305.717 1027.86 305.938 1027.29 306.049 1026.68C306.16 1026.07 306.16 1025.44 306.047 1024.83C305.789 1023.58 305.205 1022.48 304.386 1021.69C303.567 1020.9 302.559 1020.47 301.521 1020.47C301.033 1020.49 300.554 1020.64 300.118 1020.91C299.681 1021.18 299.297 1021.56 298.991 1022.03C298.686 1022.5 298.466 1023.05 298.348 1023.63C298.23 1024.22 298.216 1024.83 298.306 1025.42Z" fill="white" fill-opacity="0.6"/>
        <path d="M283.4 1025.43C283.54 1026.72 284.056 1027.9 284.851 1028.75C285.645 1029.6 286.664 1030.06 287.714 1030.05C288.189 1030.06 288.66 1029.95 289.099 1029.73C289.537 1029.5 289.932 1029.17 290.259 1028.74C290.585 1028.31 290.835 1027.81 290.993 1027.25C291.151 1026.7 291.212 1026.11 291.174 1025.53C291.02 1024.22 290.492 1023.02 289.685 1022.14C288.879 1021.27 287.847 1020.78 286.775 1020.76C286.293 1020.75 285.815 1020.86 285.373 1021.1C284.931 1021.34 284.537 1021.69 284.216 1022.13C283.895 1022.58 283.656 1023.1 283.515 1023.67C283.374 1024.24 283.335 1024.84 283.4 1025.43Z" fill="white" fill-opacity="0.6"/>
        <path d="M272.554 1030.09C273.051 1030.13 273.551 1030.05 274.019 1029.83C274.486 1029.61 274.909 1029.27 275.259 1028.83C275.609 1028.39 275.877 1027.86 276.044 1027.28C276.21 1026.7 276.273 1026.08 276.225 1025.46C276.03 1024.17 275.469 1023.01 274.642 1022.19C273.816 1021.36 272.778 1020.93 271.716 1020.97C271.26 1020.98 270.81 1021.1 270.393 1021.32C269.975 1021.55 269.6 1021.88 269.288 1022.3C268.977 1022.71 268.736 1023.19 268.581 1023.72C268.425 1024.25 268.358 1024.82 268.383 1025.38C268.487 1026.66 268.97 1027.85 269.74 1028.72C270.51 1029.59 271.512 1030.08 272.554 1030.09Z" fill="white" fill-opacity="0.6"/>
        <path d="M253.349 1025.7C253.497 1026.95 254.007 1028.09 254.785 1028.92C255.563 1029.74 256.556 1030.18 257.579 1030.17C258.05 1030.2 258.52 1030.12 258.962 1029.92C259.404 1029.72 259.807 1029.4 260.147 1029C260.488 1028.6 260.758 1028.12 260.94 1027.58C261.123 1027.04 261.214 1026.47 261.209 1025.88C261.074 1023.35 259.085 1021.35 256.877 1021.3C256.404 1021.27 255.931 1021.36 255.488 1021.56C255.045 1021.77 254.643 1022.1 254.31 1022.51C253.976 1022.93 253.717 1023.43 253.552 1023.98C253.386 1024.52 253.317 1025.11 253.349 1025.7Z" fill="white" fill-opacity="0.6"/>
        <path d="M238.248 1025.91C238.432 1027.16 238.972 1028.29 239.77 1029.09C240.568 1029.89 241.571 1030.3 242.597 1030.27C244.703 1030.27 246.311 1028.33 246.158 1026.02C246.006 1023.71 243.95 1021.65 241.751 1021.63C239.551 1021.61 238.02 1023.56 238.248 1025.91Z" fill="white" fill-opacity="0.6"/>
        <path d="M223.105 1025.85C223.302 1027.11 223.849 1028.24 224.652 1029.04C225.455 1029.85 226.462 1030.28 227.496 1030.26C229.602 1030.26 231.201 1028.38 231.04 1026.07C230.88 1023.76 228.807 1021.74 226.59 1021.66C224.374 1021.57 222.859 1023.5 223.105 1025.85Z" fill="white" fill-opacity="0.6"/>
        <path d="M207.927 1025.75C208.178 1027.01 208.771 1028.12 209.607 1028.9C210.444 1029.69 211.473 1030.09 212.521 1030.05C214.755 1029.97 216.261 1027.89 215.821 1025.41C215.432 1023.16 213.283 1021.32 211.244 1021.23C210.769 1021.18 210.291 1021.27 209.847 1021.49C209.404 1021.7 209.006 1022.04 208.686 1022.48C208.366 1022.92 208.13 1023.44 207.999 1024C207.867 1024.57 207.843 1025.17 207.927 1025.75Z" fill="white" fill-opacity="0.6"/>
        <path d="M197.107 1030.18C199.341 1030.18 200.956 1028.09 200.601 1025.68C200.246 1023.28 197.962 1021.41 195.804 1021.5C193.647 1021.6 192.369 1023.45 192.632 1025.68C192.851 1026.95 193.415 1028.09 194.229 1028.91C195.043 1029.73 196.058 1030.18 197.107 1030.18Z" fill="white" fill-opacity="0.6"/>
        <path d="M177.505 1025.89C177.759 1027.15 178.349 1028.27 179.18 1029.06C180.011 1029.85 181.034 1030.26 182.082 1030.24C184.18 1030.24 185.694 1028.34 185.466 1026.06C185.238 1023.78 183.046 1021.74 180.847 1021.69C178.647 1021.64 177.167 1023.54 177.505 1025.89Z" fill="white" fill-opacity="0.6"/>
        <path d="M162.514 1026.04C162.794 1027.29 163.407 1028.38 164.254 1029.14C165.1 1029.9 166.13 1030.28 167.175 1030.22C169.383 1030.11 170.788 1028.03 170.263 1025.59C169.79 1023.36 167.624 1021.61 165.619 1021.64C163.614 1021.67 162.099 1023.69 162.514 1026.04Z" fill="white" fill-opacity="0.6"/>
        <path d="M147.539 1025.65C147.73 1026.92 148.27 1028.07 149.067 1028.91C149.863 1029.74 150.869 1030.21 151.913 1030.23C154.003 1030.23 155.576 1028.45 155.382 1026.17C155.179 1023.72 153.191 1021.72 151 1021.54C150.542 1021.46 150.075 1021.51 149.635 1021.69C149.195 1021.86 148.793 1022.16 148.459 1022.56C148.126 1022.95 147.869 1023.44 147.71 1023.97C147.551 1024.51 147.492 1025.08 147.539 1025.65Z" fill="white" fill-opacity="0.6"/>
        <path d="M132.701 1025.68C132.89 1026.95 133.438 1028.1 134.247 1028.92C135.055 1029.74 136.072 1030.17 137.117 1030.15C139.206 1030.15 140.695 1028.22 140.458 1025.86C140.25 1024.6 139.7 1023.47 138.898 1022.64C138.097 1021.82 137.092 1021.36 136.051 1021.34C135.587 1021.3 135.121 1021.38 134.686 1021.58C134.251 1021.79 133.859 1022.11 133.538 1022.53C133.217 1022.94 132.975 1023.44 132.83 1023.99C132.685 1024.53 132.641 1025.11 132.701 1025.68Z" fill="white" fill-opacity="0.6"/>
        <path d="M122.176 1030.07C122.662 1030.09 123.147 1029.98 123.595 1029.75C124.043 1029.51 124.443 1029.16 124.766 1028.71C125.09 1028.26 125.329 1027.73 125.466 1027.15C125.604 1026.57 125.636 1025.97 125.56 1025.37C125.343 1024.17 124.81 1023.09 124.044 1022.31C123.279 1021.52 122.325 1021.07 121.33 1021.02C120.855 1020.98 120.379 1021.07 119.935 1021.29C119.491 1021.5 119.09 1021.83 118.759 1022.25C118.429 1022.68 118.178 1023.18 118.023 1023.74C117.868 1024.3 117.813 1024.89 117.862 1025.48C118 1026.76 118.517 1027.94 119.312 1028.79C120.108 1029.63 121.127 1030.09 122.176 1030.07Z" fill="white" fill-opacity="0.6"/>
        <path d="M103.073 1025.23C103.159 1026.53 103.635 1027.73 104.406 1028.61C105.178 1029.48 106.188 1029.97 107.236 1029.96C107.711 1029.98 108.184 1029.88 108.627 1029.67C109.069 1029.45 109.471 1029.12 109.806 1028.71C110.142 1028.29 110.405 1027.8 110.578 1027.25C110.752 1026.7 110.832 1026.12 110.814 1025.53C110.721 1024.24 110.252 1023.04 109.496 1022.15C108.739 1021.26 107.747 1020.74 106.703 1020.69C106.215 1020.65 105.728 1020.75 105.273 1020.97C104.818 1021.19 104.406 1021.52 104.063 1021.95C103.72 1022.38 103.455 1022.9 103.284 1023.46C103.114 1024.03 103.042 1024.63 103.073 1025.23Z" fill="white" fill-opacity="0.6"/>
        <path d="M88.2683 1025.12C88.3231 1026.42 88.7839 1027.63 89.5531 1028.52C90.3223 1029.4 91.3392 1029.88 92.3884 1029.85C92.8967 1029.85 93.3995 1029.72 93.8639 1029.46C94.3282 1029.2 94.7437 1028.83 95.0831 1028.36C95.4224 1027.89 95.6781 1027.34 95.8333 1026.75C95.9886 1026.15 96.0399 1025.51 95.9839 1024.89C95.851 1023.67 95.3732 1022.54 94.6348 1021.71C93.8964 1020.89 92.945 1020.41 91.9485 1020.36C91.4499 1020.36 90.956 1020.48 90.497 1020.72C90.038 1020.96 89.6235 1021.31 89.279 1021.76C88.9345 1022.21 88.6671 1022.73 88.4934 1023.31C88.3196 1023.89 88.243 1024.5 88.2683 1025.12Z" fill="white" fill-opacity="0.6"/>
        <path d="M73.4294 1024.94C73.4403 1025.58 73.5538 1026.22 73.7632 1026.8C73.9726 1027.39 74.2739 1027.92 74.6496 1028.37C75.0254 1028.81 75.4682 1029.16 75.9528 1029.39C76.4374 1029.62 76.9541 1029.73 77.4733 1029.71C77.9916 1029.7 78.5032 1029.57 78.9764 1029.31C79.4496 1029.04 79.8744 1028.67 80.2242 1028.19C80.5741 1027.72 80.8416 1027.16 81.0102 1026.56C81.1788 1025.95 81.2448 1025.31 81.2042 1024.67C81.1144 1023.44 80.6662 1022.3 79.9437 1021.45C79.2212 1020.61 78.2737 1020.11 77.2787 1020.07C76.7656 1020.07 76.2579 1020.2 75.7852 1020.44C75.3125 1020.69 74.8844 1021.05 74.526 1021.51C74.1675 1021.96 73.8859 1022.5 73.6976 1023.09C73.5093 1023.68 73.4181 1024.31 73.4294 1024.94Z" fill="white" fill-opacity="0.6"/>
        <path d="M58.5481 1024.57C58.515 1025.21 58.5871 1025.86 58.7601 1026.47C58.9331 1027.08 59.2034 1027.64 59.5552 1028.12C59.907 1028.6 60.3331 1028.99 60.8082 1029.26C61.2833 1029.54 61.7978 1029.69 62.3213 1029.71C63.3469 1029.71 64.3311 1029.21 65.0702 1028.33C65.8092 1027.45 66.2463 1026.26 66.2909 1024.99C66.3355 1023.72 65.9842 1022.48 65.3097 1021.53C64.6353 1020.57 63.6894 1019.98 62.6682 1019.86C61.6207 1019.82 60.6036 1020.3 59.834 1021.18C59.0643 1022.06 58.6029 1023.27 58.5481 1024.57Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.3638 1029.65C48.4401 1029.65 49.4735 1029.13 50.2378 1028.19C51.002 1027.25 51.4349 1025.98 51.4416 1024.65C51.4574 1024 51.3686 1023.36 51.1803 1022.76C50.992 1022.16 50.7081 1021.61 50.3454 1021.14C49.9826 1020.68 49.5484 1020.31 49.0683 1020.06C48.5882 1019.8 48.0719 1019.67 47.5499 1019.67C46.5331 1019.75 45.5736 1020.28 44.8478 1021.16C44.1219 1022.05 43.6786 1023.23 43.5991 1024.48C43.555 1025.13 43.6191 1025.78 43.7873 1026.4C43.9555 1027.02 44.2243 1027.59 44.5771 1028.07C44.9298 1028.56 45.3591 1028.95 45.8384 1029.22C46.3177 1029.49 46.8368 1029.64 47.3638 1029.65Z" fill="white" fill-opacity="0.6"/>
        <path d="M28.5402 1024.68C28.5133 1025.33 28.5947 1025.98 28.7792 1026.58C28.9637 1027.19 29.2474 1027.74 29.6125 1028.21C29.9777 1028.67 30.4165 1029.04 30.9016 1029.29C31.3866 1029.53 31.9075 1029.65 32.4318 1029.64C33.4549 1029.58 34.4248 1029.06 35.1521 1028.17C35.8794 1027.28 36.312 1026.08 36.3657 1024.82C36.4157 1024.17 36.3573 1023.51 36.194 1022.89C36.0307 1022.27 35.766 1021.69 35.4163 1021.2C35.0667 1020.71 34.6395 1020.32 34.1611 1020.04C33.6826 1019.76 33.1632 1019.61 32.6349 1019.59C31.5554 1019.63 30.5298 1020.18 29.7685 1021.13C29.0071 1022.07 28.5677 1023.35 28.5402 1024.68Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.3477 1029.71C18.4316 1029.66 19.4552 1029.08 20.1965 1028.1C20.9377 1027.12 21.3368 1025.82 21.307 1024.48C21.2983 1023.23 20.8949 1022.04 20.1833 1021.16C19.4717 1020.27 18.5085 1019.77 17.5 1019.75C16.4159 1019.78 15.3823 1020.33 14.6124 1021.27C13.8425 1022.21 13.3953 1023.49 13.363 1024.83C13.3488 1025.48 13.4424 1026.12 13.6381 1026.73C13.8337 1027.33 14.1273 1027.88 14.5006 1028.33C14.874 1028.79 15.3193 1029.15 15.8089 1029.39C16.2986 1029.62 16.8223 1029.73 17.3477 1029.71Z" fill="white" fill-opacity="0.6"/>
        <path d="M723.424 1029.93C723.93 1029.95 724.433 1029.84 724.896 1029.59C725.36 1029.34 725.773 1028.96 726.104 1028.49C726.435 1028.02 726.676 1027.46 726.81 1026.86C726.943 1026.25 726.966 1025.62 726.876 1025C726.642 1023.73 726.07 1022.58 725.25 1021.75C724.43 1020.92 723.409 1020.45 722.35 1020.42C721.853 1020.39 721.357 1020.49 720.897 1020.73C720.438 1020.96 720.026 1021.32 719.694 1021.78C719.361 1022.23 719.115 1022.78 718.974 1023.37C718.834 1023.95 718.802 1024.58 718.881 1025.18C719.068 1026.51 719.625 1027.71 720.455 1028.58C721.286 1029.44 722.337 1029.92 723.424 1029.93Z" fill="white" fill-opacity="0.6"/>
        <path d="M703.602 1025.41C703.864 1026.73 704.479 1027.89 705.348 1028.72C706.217 1029.54 707.287 1029.98 708.382 1029.95C708.88 1029.96 709.372 1029.82 709.819 1029.55C710.266 1029.27 710.655 1028.88 710.956 1028.39C711.256 1027.9 711.46 1027.33 711.551 1026.72C711.642 1026.11 711.617 1025.49 711.479 1024.9C711.156 1023.64 710.519 1022.54 709.659 1021.75C708.799 1020.96 707.76 1020.53 706.69 1020.52C706.204 1020.51 705.724 1020.64 705.286 1020.9C704.848 1021.16 704.464 1021.55 704.166 1022.02C703.867 1022.49 703.66 1023.05 703.563 1023.63C703.465 1024.22 703.479 1024.83 703.602 1025.41Z" fill="white" fill-opacity="0.6"/>
        <path d="M693.154 1029.98C693.648 1030.01 694.14 1029.9 694.589 1029.64C695.039 1029.39 695.432 1029 695.735 1028.52C696.039 1028.04 696.244 1027.47 696.334 1026.87C696.424 1026.27 696.395 1025.65 696.251 1025.07C695.912 1023.8 695.269 1022.7 694.407 1021.9C693.544 1021.1 692.505 1020.65 691.428 1020.6C690.947 1020.57 690.467 1020.67 690.027 1020.92C689.586 1021.16 689.2 1021.53 688.899 1021.99C688.598 1022.46 688.392 1023.01 688.298 1023.59C688.203 1024.17 688.224 1024.78 688.357 1025.35C688.63 1026.67 689.249 1027.84 690.117 1028.68C690.985 1029.52 692.053 1029.98 693.154 1029.98Z" fill="white" fill-opacity="0.6"/>
        <path d="M673.248 1025.4C673.543 1026.71 674.176 1027.87 675.05 1028.7C675.925 1029.53 676.994 1029.98 678.095 1029.99C678.583 1030.01 679.068 1029.89 679.507 1029.62C679.946 1029.36 680.326 1028.97 680.615 1028.48C680.903 1028 681.091 1027.43 681.162 1026.83C681.233 1026.24 681.185 1025.63 681.022 1025.06C680.653 1023.8 679.99 1022.71 679.118 1021.93C678.245 1021.14 677.203 1020.69 676.124 1020.64C675.652 1020.62 675.184 1020.75 674.758 1021C674.333 1021.25 673.963 1021.63 673.68 1022.09C673.398 1022.56 673.211 1023.11 673.136 1023.68C673.06 1024.26 673.099 1024.85 673.248 1025.4Z" fill="white" fill-opacity="0.6"/>
        <path d="M658.265 1025.35C658.55 1026.66 659.171 1027.82 660.034 1028.66C660.897 1029.49 661.956 1029.96 663.053 1029.99C663.516 1030.03 663.978 1029.92 664.403 1029.69C664.827 1029.46 665.202 1029.11 665.497 1028.67C665.792 1028.23 665.999 1027.71 666.1 1027.15C666.202 1026.59 666.196 1026.01 666.082 1025.45C665.767 1024.12 665.121 1022.93 664.235 1022.07C663.349 1021.21 662.267 1020.72 661.141 1020.65C660.672 1020.62 660.203 1020.73 659.777 1020.98C659.35 1021.22 658.979 1021.59 658.695 1022.06C658.411 1022.52 658.224 1023.06 658.149 1023.64C658.074 1024.21 658.114 1024.8 658.265 1025.35Z" fill="white" fill-opacity="0.6"/>
        <path d="M648.13 1029.96C648.617 1029.99 649.104 1029.87 649.546 1029.62C649.989 1029.36 650.374 1028.98 650.668 1028.5C650.963 1028.02 651.157 1027.45 651.236 1026.86C651.314 1026.26 651.273 1025.65 651.116 1025.08C650.767 1023.83 650.126 1022.74 649.275 1021.94C648.424 1021.15 647.402 1020.68 646.336 1020.6C644.247 1020.6 642.952 1022.69 643.451 1025.3C643.7 1026.61 644.295 1027.78 645.142 1028.62C645.99 1029.47 647.041 1029.94 648.13 1029.96Z" fill="white" fill-opacity="0.6"/>
        <path d="M628.672 1025.32C628.894 1026.63 629.473 1027.8 630.313 1028.64C631.153 1029.48 632.202 1029.94 633.282 1029.93C633.751 1029.96 634.219 1029.85 634.65 1029.63C635.082 1029.4 635.466 1029.05 635.773 1028.61C636.081 1028.18 636.305 1027.66 636.427 1027.1C636.55 1026.54 636.568 1025.95 636.48 1025.38C636.255 1024.04 635.67 1022.83 634.822 1021.95C633.973 1021.07 632.909 1020.57 631.802 1020.53C631.32 1020.51 630.843 1020.64 630.406 1020.89C629.968 1021.13 629.584 1021.51 629.281 1021.97C628.978 1022.43 628.766 1022.97 628.66 1023.56C628.555 1024.14 628.559 1024.74 628.672 1025.32Z" fill="white" fill-opacity="0.6"/>
        <path d="M618.206 1030.11C620.431 1030.04 621.937 1027.88 621.413 1025.27C621.087 1024.02 620.452 1022.92 619.598 1022.14C618.743 1021.36 617.712 1020.93 616.65 1020.92C616.179 1020.88 615.709 1020.99 615.278 1021.22C614.846 1021.46 614.465 1021.81 614.164 1022.26C613.863 1022.71 613.651 1023.24 613.545 1023.8C613.439 1024.37 613.442 1024.96 613.553 1025.53C613.795 1026.83 614.387 1027.99 615.232 1028.82C616.077 1029.66 617.126 1030.11 618.206 1030.11Z" fill="white" fill-opacity="0.6"/>
        <path d="M598.41 1025.61C598.662 1026.91 599.257 1028.06 600.1 1028.89C600.943 1029.72 601.986 1030.18 603.063 1030.19C605.17 1030.19 606.684 1028.26 606.354 1025.84C606.024 1023.43 603.757 1021.16 601.532 1021.08C601.062 1021.03 600.589 1021.12 600.152 1021.34C599.715 1021.56 599.327 1021.9 599.021 1022.35C598.715 1022.79 598.5 1023.32 598.393 1023.89C598.287 1024.45 598.293 1025.05 598.41 1025.61Z" fill="white" fill-opacity="0.6"/>
        <path d="M583.343 1025.8C583.644 1027.09 584.281 1028.22 585.155 1029.01C586.028 1029.8 587.089 1030.22 588.173 1030.19C590.39 1030.06 591.76 1027.91 591.117 1025.31C590.727 1024.07 590.051 1023.01 589.173 1022.25C588.296 1021.49 587.256 1021.07 586.185 1021.04C585.716 1021.03 585.25 1021.15 584.828 1021.41C584.406 1021.66 584.04 1022.04 583.761 1022.5C583.482 1022.97 583.298 1023.52 583.225 1024.09C583.152 1024.66 583.193 1025.25 583.343 1025.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M568.267 1025.48C568.523 1026.79 569.123 1027.96 569.974 1028.8C570.825 1029.65 571.879 1030.12 572.971 1030.15C575.069 1030.15 576.524 1028.21 576.143 1025.75C575.849 1024.4 575.22 1023.21 574.345 1022.33C573.471 1021.45 572.395 1020.93 571.27 1020.85C570.799 1020.81 570.326 1020.9 569.892 1021.14C569.458 1021.37 569.076 1021.73 568.78 1022.18C568.484 1022.64 568.282 1023.18 568.193 1023.75C568.104 1024.32 568.129 1024.92 568.267 1025.48Z" fill="white" fill-opacity="0.6"/>
        <path d="M553.276 1025.09C553.483 1026.42 554.044 1027.64 554.869 1028.54C555.694 1029.43 556.738 1029.97 557.835 1030.06C558.3 1030.11 558.769 1030.04 559.206 1029.83C559.643 1029.63 560.037 1029.31 560.359 1028.89C560.68 1028.47 560.92 1027.97 561.06 1027.42C561.201 1026.87 561.238 1026.29 561.169 1025.72C560.931 1024.34 560.346 1023.09 559.497 1022.15C558.649 1021.21 557.581 1020.63 556.448 1020.49C555.969 1020.42 555.484 1020.5 555.037 1020.73C554.589 1020.95 554.191 1021.3 553.879 1021.75C553.566 1022.21 553.348 1022.75 553.243 1023.33C553.138 1023.91 553.149 1024.51 553.276 1025.09Z" fill="white" fill-opacity="0.6"/>
        <path d="M538.344 1025C538.551 1026.35 539.124 1027.57 539.969 1028.47C540.813 1029.36 541.878 1029.87 542.988 1029.91C543.461 1029.94 543.933 1029.83 544.368 1029.6C544.803 1029.37 545.189 1029.02 545.497 1028.57C545.805 1028.13 546.026 1027.6 546.144 1027.03C546.262 1026.46 546.274 1025.87 546.178 1025.3C545.923 1023.92 545.322 1022.68 544.46 1021.75C543.598 1020.81 542.52 1020.25 541.381 1020.12C539.257 1020.02 537.921 1022.31 538.344 1025Z" fill="white" fill-opacity="0.6"/>
        <path d="M527.895 1029.78C528.402 1029.82 528.909 1029.71 529.375 1029.46C529.841 1029.21 530.252 1028.83 530.576 1028.34C530.899 1027.86 531.125 1027.29 531.236 1026.67C531.346 1026.06 531.338 1025.42 531.212 1024.82C530.957 1023.49 530.378 1022.29 529.554 1021.37C528.729 1020.46 527.699 1019.89 526.601 1019.72C524.469 1019.5 523.039 1021.81 523.386 1024.59C523.547 1025.96 524.081 1027.22 524.901 1028.17C525.721 1029.11 526.777 1029.68 527.895 1029.78Z" fill="white" fill-opacity="0.6"/>
        <path d="M508.437 1024.82C508.653 1026.18 509.249 1027.41 510.123 1028.29C510.996 1029.17 512.091 1029.64 513.217 1029.63C515.408 1029.42 516.753 1027.03 516.119 1024.08C515.544 1021.42 513.243 1019.2 511.263 1019.31C509.165 1019.43 507.938 1021.99 508.437 1024.82Z" fill="white" fill-opacity="0.6"/>
        <path d="M493.446 1024.39C493.878 1027.35 495.874 1029.62 498.065 1029.52C500.256 1029.43 501.737 1027.05 501.204 1024.05C500.722 1021.31 498.514 1018.97 496.5 1018.95C494.487 1018.93 493.032 1021.5 493.446 1024.39Z" fill="white" fill-opacity="0.6"/>
        <path d="M482.862 1029.46C485.071 1029.46 486.669 1027.17 486.246 1024.14C486.054 1022.75 485.512 1021.48 484.699 1020.51C483.886 1019.53 482.843 1018.9 481.72 1018.7C479.58 1018.5 478.099 1021.01 478.404 1023.93C478.709 1026.85 480.663 1029.42 482.862 1029.46Z" fill="white" fill-opacity="0.6"/>
        <path d="M463.43 1024.02C463.768 1027.04 465.722 1029.45 467.922 1029.42C468.43 1029.41 468.931 1029.26 469.388 1028.99C469.846 1028.72 470.25 1028.32 470.572 1027.84C470.895 1027.35 471.127 1026.78 471.254 1026.18C471.381 1025.57 471.398 1024.93 471.306 1024.31C471.018 1021.26 468.869 1018.65 466.67 1018.54C464.47 1018.44 463.091 1021.04 463.43 1024.02Z" fill="white" fill-opacity="0.6"/>
        <path d="M452.948 1029.43C455.164 1029.36 456.687 1026.93 456.222 1023.82C455.799 1020.97 453.684 1018.59 451.603 1018.53C449.521 1018.47 448.049 1021.18 448.413 1024.15C448.777 1027.12 450.748 1029.51 452.948 1029.43Z" fill="white" fill-opacity="0.6"/>
        <path d="M433.337 1024.11C433.617 1027.09 435.545 1029.51 437.745 1029.54C438.252 1029.55 438.754 1029.42 439.216 1029.16C439.679 1028.9 440.091 1028.53 440.425 1028.06C440.759 1027.58 441.007 1027.03 441.152 1026.43C441.296 1025.83 441.335 1025.2 441.264 1024.58C441.159 1023.11 440.653 1021.73 439.829 1020.66C439.005 1019.6 437.912 1018.91 436.73 1018.72C434.581 1018.56 433.058 1021.15 433.337 1024.11Z" fill="white" fill-opacity="0.6"/>
        <path d="M418.304 1024.56C418.761 1027.53 420.757 1029.87 422.991 1029.71C425.224 1029.55 426.662 1027.13 426.138 1024.08C425.664 1021.31 423.439 1019 421.417 1019.12C419.234 1019.04 417.915 1021.64 418.304 1024.56Z" fill="white" fill-opacity="0.6"/>
        <path d="M351.52 1008.63C349.439 1008.82 348.44 1011.25 349.075 1014.18C349.71 1017.11 351.765 1019.31 353.838 1019.26C355.911 1019.21 357.417 1016.5 356.655 1013.52C355.894 1010.54 353.559 1008.43 351.52 1008.63Z" fill="white" fill-opacity="0.6"/>
        <path d="M334.16 1014.78C334.752 1017.57 336.757 1019.74 338.847 1019.72C340.936 1019.7 342.468 1017.09 341.757 1014.21C341.046 1011.33 338.745 1009.31 336.681 1009.48C334.617 1009.66 333.568 1011.93 334.16 1014.78Z" fill="white" fill-opacity="0.6"/>
        <path d="M321.876 1010.41C319.795 1010.58 318.729 1012.78 319.287 1015.46C319.546 1016.75 320.135 1017.92 320.965 1018.77C321.796 1019.63 322.827 1020.13 323.906 1020.21C324.412 1020.21 324.91 1020.06 325.357 1019.77C325.805 1019.48 326.191 1019.06 326.481 1018.55C326.772 1018.03 326.959 1017.44 327.028 1016.82C327.097 1016.21 327.044 1015.57 326.876 1014.98C326.541 1013.67 325.876 1012.51 324.978 1011.69C324.079 1010.87 322.993 1010.42 321.876 1010.41Z" fill="white" fill-opacity="0.6"/>
        <path d="M304.431 1016.11C304.689 1017.38 305.27 1018.52 306.088 1019.35C306.905 1020.18 307.917 1020.66 308.974 1020.73C309.472 1020.75 309.967 1020.62 310.416 1020.35C310.866 1020.09 311.257 1019.69 311.556 1019.2C311.856 1018.71 312.055 1018.13 312.137 1017.52C312.219 1016.92 312.182 1016.29 312.028 1015.71C311.682 1014.42 311.019 1013.31 310.133 1012.52C309.246 1011.73 308.181 1011.3 307.088 1011.3C305.015 1011.44 303.915 1013.51 304.431 1016.11Z" fill="white" fill-opacity="0.6"/>
        <path d="M292.308 1012.06C291.859 1012.04 291.413 1012.16 291.009 1012.4C290.605 1012.64 290.255 1013 289.988 1013.45C289.722 1013.9 289.548 1014.42 289.48 1014.97C289.413 1015.52 289.454 1016.08 289.601 1016.61C289.868 1017.85 290.449 1018.95 291.258 1019.75C292.068 1020.55 293.065 1021.01 294.102 1021.06C294.588 1021.1 295.074 1020.99 295.518 1020.74C295.963 1020.49 296.353 1020.12 296.656 1019.65C296.959 1019.18 297.165 1018.62 297.258 1018.03C297.351 1017.44 297.327 1016.83 297.19 1016.25C296.829 1015.01 296.166 1013.94 295.291 1013.19C294.416 1012.44 293.373 1012.04 292.308 1012.06Z" fill="white" fill-opacity="0.6"/>
        <path d="M277.554 1012.55C277.114 1012.51 276.673 1012.6 276.269 1012.82C275.865 1013.04 275.511 1013.38 275.238 1013.81C274.964 1014.24 274.78 1014.74 274.699 1015.28C274.619 1015.82 274.647 1016.37 274.779 1016.89C275.041 1018.11 275.614 1019.2 276.414 1019.99C277.213 1020.78 278.197 1021.23 279.22 1021.28C279.698 1021.33 280.178 1021.24 280.621 1021.02C281.064 1020.79 281.457 1020.43 281.765 1019.98C282.074 1019.53 282.29 1018.99 282.395 1018.41C282.499 1017.83 282.49 1017.23 282.367 1016.66C282.006 1015.44 281.35 1014.4 280.488 1013.66C279.626 1012.92 278.601 1012.54 277.554 1012.55Z" fill="white" fill-opacity="0.6"/>
        <path d="M262.816 1013.05C260.684 1013.05 259.432 1014.85 259.779 1017.15C260.036 1018.42 260.634 1019.54 261.475 1020.33C262.316 1021.13 263.35 1021.54 264.407 1021.51C266.446 1021.44 267.952 1019.42 267.537 1017.14C267.122 1014.86 264.906 1012.98 262.816 1013.05Z" fill="white" fill-opacity="0.6"/>
        <path d="M247.512 1013.75C245.397 1013.91 244.238 1015.68 244.729 1017.93C245.051 1019.11 245.665 1020.12 246.482 1020.83C247.3 1021.54 248.28 1021.92 249.28 1021.9C251.471 1021.9 253.028 1019.7 252.427 1017.43C251.827 1015.16 249.559 1013.59 247.512 1013.75Z" fill="white" fill-opacity="0.6"/>
        <path d="M229.509 1017.98C229.817 1019.15 230.413 1020.17 231.213 1020.9C232.014 1021.64 232.98 1022.04 233.976 1022.07C236.167 1022.17 237.825 1020.16 237.36 1017.97C236.895 1015.77 234.636 1014.2 232.521 1014.22C230.406 1014.24 229.086 1015.82 229.509 1017.98Z" fill="white" fill-opacity="0.6"/>
        <path d="M214.323 1017.9C214.697 1019.07 215.342 1020.08 216.178 1020.79C217.013 1021.51 218.002 1021.91 219.019 1021.93C221.083 1021.93 222.58 1020.11 222.132 1018.05C221.684 1015.99 219.196 1014.1 216.997 1014.12C214.797 1014.14 213.765 1015.72 214.323 1017.9Z" fill="white" fill-opacity="0.6"/>
        <path d="M201.768 1013.89C199.56 1013.95 198.384 1015.56 198.85 1017.73C199.202 1018.9 199.836 1019.92 200.668 1020.64C201.499 1021.36 202.488 1021.74 203.503 1021.75C205.728 1021.75 207.361 1019.74 206.794 1017.57C206.227 1015.39 203.875 1013.82 201.768 1013.89Z" fill="white" fill-opacity="0.6"/>
        <path d="M186.49 1014.19C184.315 1014.19 183.106 1015.72 183.562 1017.85C183.923 1019.08 184.585 1020.14 185.456 1020.88C186.326 1021.62 187.362 1022 188.418 1021.98C190.491 1021.98 191.921 1020.03 191.422 1017.97C190.923 1015.91 188.605 1014.22 186.49 1014.19Z" fill="white" fill-opacity="0.6"/>
        <path d="M171.405 1014.23C169.273 1014.17 168.021 1015.71 168.512 1017.86C168.863 1019.09 169.512 1020.15 170.372 1020.9C171.231 1021.65 172.257 1022.05 173.309 1022.04C175.356 1022.04 176.769 1020.13 176.261 1018.07C175.754 1016.01 173.487 1014.29 171.405 1014.23Z" fill="white" fill-opacity="0.6"/>
        <path d="M156.583 1014.02C154.477 1013.92 153.199 1015.48 153.656 1017.66C153.965 1018.9 154.586 1019.99 155.43 1020.76C156.273 1021.53 157.294 1021.95 158.343 1021.95C160.373 1021.95 161.786 1020.06 161.312 1017.98C160.839 1015.89 158.631 1014.14 156.583 1014.02Z" fill="white" fill-opacity="0.6"/>
        <path d="M139.003 1017.7C139.31 1018.88 139.907 1019.91 140.711 1020.65C141.516 1021.38 142.487 1021.79 143.487 1021.8C145.509 1021.8 146.947 1019.93 146.533 1017.8C146.118 1015.66 143.766 1013.61 141.66 1013.69C139.553 1013.76 138.513 1015.42 139.003 1017.7Z" fill="white" fill-opacity="0.6"/>
        <path d="M127.455 1013.22C125.391 1013.07 124.071 1014.69 124.334 1016.99C124.533 1018.26 125.079 1019.41 125.88 1020.24C126.681 1021.07 127.688 1021.53 128.733 1021.55C130.738 1021.55 132.218 1019.65 131.872 1017.46C131.525 1015.28 129.427 1013.35 127.455 1013.22Z" fill="white" fill-opacity="0.6"/>
        <path d="M109.799 1016.74C109.967 1017.96 110.465 1019.07 111.211 1019.89C111.957 1020.71 112.906 1021.19 113.902 1021.25C114.375 1021.32 114.853 1021.25 115.301 1021.05C115.748 1020.85 116.152 1020.53 116.481 1020.1C116.809 1019.68 117.053 1019.17 117.193 1018.6C117.333 1018.04 117.365 1017.45 117.286 1016.87C117.007 1015.69 116.432 1014.65 115.645 1013.9C114.857 1013.14 113.898 1012.72 112.904 1012.68C112.467 1012.62 112.024 1012.68 111.61 1012.86C111.195 1013.04 110.822 1013.34 110.519 1013.74C110.217 1014.13 109.994 1014.61 109.869 1015.13C109.744 1015.66 109.72 1016.21 109.799 1016.74Z" fill="white" fill-opacity="0.6"/>
        <path d="M95.2562 1016.19C95.3465 1017.41 95.7837 1018.56 96.4909 1019.43C97.1982 1020.29 98.13 1020.82 99.1225 1020.93C99.6038 1020.99 100.09 1020.92 100.547 1020.72C101.004 1020.52 101.42 1020.2 101.765 1019.78C102.109 1019.36 102.374 1018.85 102.541 1018.29C102.707 1017.73 102.77 1017.13 102.726 1016.53C102.533 1015.33 102.019 1014.23 101.267 1013.43C100.515 1012.62 99.5669 1012.14 98.5726 1012.08C98.1242 1012.02 97.6703 1012.08 97.2446 1012.26C96.819 1012.45 96.4325 1012.75 96.1139 1013.15C95.7953 1013.54 95.5527 1014.02 95.4042 1014.55C95.2557 1015.07 95.2051 1015.63 95.2562 1016.19Z" fill="white" fill-opacity="0.6"/>
        <path d="M84.0553 1011.5C83.5898 1011.47 83.1246 1011.57 82.6915 1011.78C82.2585 1011.99 81.8678 1012.32 81.5463 1012.74C81.2248 1013.15 80.9802 1013.65 80.8292 1014.2C80.6781 1014.74 80.6243 1015.32 80.6713 1015.89C80.7319 1017.13 81.1574 1018.3 81.867 1019.17C82.5766 1020.04 83.5209 1020.56 84.5206 1020.63C85.017 1020.66 85.513 1020.56 85.975 1020.34C86.437 1020.11 86.8543 1019.76 87.1984 1019.32C87.5426 1018.88 87.8057 1018.35 87.9699 1017.77C88.1341 1017.19 88.1955 1016.57 88.15 1015.96C87.9885 1014.74 87.4924 1013.64 86.7461 1012.83C85.9997 1012.01 85.049 1011.55 84.0553 1011.5Z" fill="white" fill-opacity="0.6"/>
        <path d="M66.0522 1015.38C66.0312 1016.62 66.3927 1017.83 67.0634 1018.75C67.7341 1019.68 68.664 1020.25 69.6646 1020.36C70.1733 1020.4 70.683 1020.31 71.1618 1020.09C71.6405 1019.88 72.0779 1019.54 72.4465 1019.1C72.8152 1018.67 73.107 1018.15 73.3038 1017.56C73.5006 1016.98 73.5981 1016.36 73.5901 1015.73C73.5143 1014.49 73.08 1013.34 72.3677 1012.47C71.6554 1011.6 70.7132 1011.08 69.7154 1011.01C69.2368 1010.97 68.7568 1011.06 68.3063 1011.26C67.8558 1011.46 67.4447 1011.78 67.0996 1012.19C66.7546 1012.6 66.483 1013.1 66.3026 1013.65C66.1222 1014.2 66.0369 1014.79 66.0522 1015.38Z" fill="white" fill-opacity="0.6"/>
        <path d="M51.2978 1015.18C51.2798 1016.43 51.6637 1017.64 52.365 1018.54C52.7122 1018.98 53.1269 1019.34 53.5854 1019.58C54.0439 1019.83 54.5372 1019.96 55.0371 1019.97C56.0468 1020 57.0223 1019.52 57.7489 1018.65C58.4756 1017.79 58.8939 1016.6 58.9119 1015.35C58.8681 1014.11 58.4488 1012.94 57.7373 1012.06C57.0259 1011.18 56.0741 1010.67 55.071 1010.62C54.5777 1010.6 54.0858 1010.7 53.6252 1010.92C53.1647 1011.14 52.7452 1011.47 52.3922 1011.9C52.0392 1012.33 51.7601 1012.84 51.572 1013.4C51.3838 1013.97 51.2906 1014.57 51.2978 1015.18Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.1898 1010.39C39.6847 1010.39 39.1847 1010.52 38.7185 1010.76C38.2524 1011 37.8293 1011.35 37.4737 1011.79C37.1182 1012.24 36.8373 1012.76 36.6471 1013.34C36.4569 1013.92 36.3613 1014.54 36.3658 1015.16C36.3408 1015.78 36.4153 1016.4 36.5851 1016.98C36.7549 1017.57 37.0165 1018.1 37.3548 1018.56C37.6932 1019.02 38.1015 1019.39 38.5561 1019.65C39.0108 1019.91 39.5028 1020.05 40.0036 1020.08C40.5368 1020.08 41.0652 1019.95 41.5583 1019.7C42.0515 1019.45 42.4996 1019.08 42.8771 1018.62C43.2545 1018.15 43.5537 1017.6 43.7575 1016.99C43.9613 1016.38 44.0656 1015.73 44.0644 1015.07C44.0296 1013.82 43.6074 1012.64 42.8856 1011.77C42.1639 1010.9 41.1983 1010.41 40.1898 1010.39Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.1563 1010.38C24.1245 1010.41 23.1419 1010.93 22.4169 1011.84C21.692 1012.75 21.2817 1013.97 21.2731 1015.25C21.2527 1015.87 21.3324 1016.49 21.5074 1017.07C21.6824 1017.65 21.9493 1018.18 22.2925 1018.64C22.6357 1019.09 23.0484 1019.46 23.5067 1019.71C23.9649 1019.96 24.4595 1020.1 24.9617 1020.12C26.0422 1020.1 27.0748 1019.57 27.8396 1018.62C28.6045 1017.68 29.041 1016.4 29.0564 1015.07C29.0498 1014.44 28.9436 1013.83 28.7439 1013.25C28.5442 1012.68 28.2549 1012.16 27.8927 1011.73C27.5304 1011.29 27.1022 1010.95 26.6326 1010.72C26.1631 1010.49 25.6614 1010.37 25.1563 1010.38Z" fill="white" fill-opacity="0.6"/>
        <path d="M9.76744 1010.63C8.72682 1010.69 7.74303 1011.23 7.02338 1012.16C6.30372 1013.1 5.90441 1014.34 5.90963 1015.62C5.91061 1016.25 6.01246 1016.86 6.20923 1017.44C6.40599 1018.01 6.6937 1018.54 7.05544 1018.97C7.41719 1019.41 7.84571 1019.75 8.31578 1019.98C8.78586 1020.2 9.28805 1020.31 9.79284 1020.3C10.8864 1020.26 11.923 1019.69 12.6782 1018.71C13.4334 1017.73 13.8466 1016.42 13.8283 1015.07C13.7652 1013.81 13.302 1012.64 12.5405 1011.81C11.779 1010.98 10.7815 1010.56 9.76744 1010.63Z" fill="white" fill-opacity="0.6"/>
        <path d="M712.663 1011.74C710.413 1011.74 709.177 1013.7 709.702 1016.22C710.002 1017.51 710.617 1018.65 711.465 1019.49C712.312 1020.32 713.348 1020.82 714.431 1020.89C714.929 1020.96 715.432 1020.87 715.898 1020.64C716.363 1020.42 716.776 1020.05 717.102 1019.58C717.427 1019.11 717.655 1018.55 717.766 1017.94C717.877 1017.34 717.868 1016.71 717.739 1016.11C717.352 1014.83 716.659 1013.73 715.753 1012.95C714.846 1012.17 713.768 1011.75 712.663 1011.74Z" fill="white" fill-opacity="0.6"/>
        <path d="M694.152 1016.4C694.513 1017.67 695.17 1018.79 696.041 1019.6C696.913 1020.42 697.96 1020.9 699.051 1020.98C701.284 1021.04 702.79 1018.77 702.096 1016.24C701.651 1014.97 700.917 1013.89 699.982 1013.12C699.048 1012.36 697.955 1011.94 696.834 1011.93C694.626 1011.93 693.51 1013.9 694.152 1016.4Z" fill="white" fill-opacity="0.6"/>
        <path d="M678.772 1016.51C679.169 1017.78 679.851 1018.88 680.737 1019.68C681.623 1020.48 682.676 1020.95 683.772 1021.04C685.989 1021.04 687.393 1018.82 686.598 1016.3C686.109 1015.04 685.348 1013.98 684.397 1013.22C683.447 1012.47 682.346 1012.06 681.217 1012.04C679.051 1012.09 678.053 1014.02 678.772 1016.51Z" fill="white" fill-opacity="0.6"/>
        <path d="M663.612 1016.5C664.014 1017.76 664.696 1018.86 665.578 1019.67C666.461 1020.47 667.509 1020.96 668.603 1021.06C670.794 1021.13 672.156 1018.9 671.319 1016.4C670.818 1015.15 670.052 1014.09 669.103 1013.33C668.155 1012.57 667.058 1012.15 665.93 1012.1C663.798 1012.12 662.859 1014.02 663.612 1016.5Z" fill="white" fill-opacity="0.6"/>
        <path d="M651.099 1012.08C648.976 1012.01 648.011 1013.83 648.705 1016.26C649.089 1017.59 649.78 1018.74 650.691 1019.58C651.603 1020.43 652.695 1020.92 653.832 1021C654.276 1021.04 654.72 1020.93 655.122 1020.69C655.523 1020.45 655.868 1020.09 656.123 1019.64C656.379 1019.19 656.535 1018.66 656.578 1018.12C656.622 1017.57 656.55 1017.02 656.37 1016.51C655.883 1015.27 655.14 1014.2 654.215 1013.42C653.29 1012.64 652.215 1012.18 651.099 1012.08Z" fill="white" fill-opacity="0.6"/>
        <path d="M636.404 1011.93C634.391 1011.93 633.519 1013.81 634.23 1016.29C634.59 1017.54 635.229 1018.64 636.074 1019.46C636.918 1020.28 637.934 1020.78 639.001 1020.92C639.466 1020.98 639.936 1020.89 640.361 1020.65C640.787 1020.41 641.152 1020.03 641.419 1019.56C641.686 1019.08 641.844 1018.53 641.877 1017.95C641.91 1017.37 641.817 1016.8 641.607 1016.28C641.129 1015.05 640.396 1014 639.482 1013.24C638.568 1012.47 637.505 1012.02 636.404 1011.93Z" fill="white" fill-opacity="0.6"/>
        <path d="M621.574 1012.25C619.45 1012.39 618.511 1014.35 619.247 1016.8C619.644 1018.05 620.327 1019.12 621.21 1019.9C622.094 1020.67 623.14 1021.11 624.222 1021.16C626.421 1021.16 627.809 1018.81 626.963 1016.33C626.464 1015.1 625.694 1014.06 624.741 1013.34C623.788 1012.62 622.691 1012.24 621.574 1012.25Z" fill="white" fill-opacity="0.6"/>
        <path d="M604.036 1017.06C604.451 1018.3 605.144 1019.37 606.034 1020.14C606.924 1020.92 607.975 1021.36 609.061 1021.42C611.142 1021.42 612.504 1019.4 611.87 1017.05C611.235 1014.69 608.528 1012.51 606.32 1012.6C604.112 1012.69 603.283 1014.61 604.036 1017.06Z" fill="white" fill-opacity="0.6"/>
        <path d="M591.346 1012.61C589.163 1012.51 588.106 1014.3 588.765 1016.79C589.153 1018.13 589.852 1019.29 590.773 1020.14C591.694 1020.98 592.797 1021.47 593.943 1021.54C596.007 1021.54 597.327 1019.45 596.659 1017.16C595.99 1014.87 593.478 1012.72 591.346 1012.61Z" fill="white" fill-opacity="0.6"/>
        <path d="M576.118 1012.34C573.96 1012.24 572.979 1014.09 573.681 1016.6C574.063 1017.88 574.726 1019 575.596 1019.84C576.466 1020.68 577.508 1021.2 578.605 1021.34C580.796 1021.52 582.26 1019.38 581.481 1016.88C580.982 1015.61 580.225 1014.53 579.284 1013.73C578.344 1012.94 577.253 1012.46 576.118 1012.34Z" fill="white" fill-opacity="0.6"/>
        <path d="M558.792 1016.38C559.18 1017.66 559.853 1018.79 560.734 1019.62C561.615 1020.46 562.669 1020.97 563.775 1021.1C564.222 1021.16 564.674 1021.08 565.087 1020.86C565.5 1020.64 565.86 1020.29 566.132 1019.85C566.404 1019.4 566.578 1018.88 566.638 1018.33C566.698 1017.78 566.641 1017.22 566.473 1016.7C566.001 1015.34 565.237 1014.17 564.265 1013.31C563.293 1012.45 562.149 1011.93 560.957 1011.82C558.885 1011.79 558.013 1013.79 558.792 1016.38Z" fill="white" fill-opacity="0.6"/>
        <path d="M546.228 1011.16C544.122 1011.05 543.166 1013.04 543.868 1015.72C544.21 1017.04 544.848 1018.21 545.71 1019.11C546.571 1020 547.619 1020.57 548.732 1020.75C549.213 1020.82 549.702 1020.74 550.147 1020.5C550.591 1020.26 550.976 1019.88 551.26 1019.39C551.544 1018.91 551.717 1018.33 551.76 1017.74C551.804 1017.14 551.716 1016.54 551.507 1016C551.05 1014.69 550.321 1013.55 549.394 1012.7C548.467 1011.85 547.375 1011.32 546.228 1011.16Z" fill="white" fill-opacity="0.6"/>
        <path d="M529.046 1015.4C529.799 1018.14 531.939 1020.34 533.995 1020.36C534.462 1020.38 534.925 1020.25 535.342 1020C535.759 1019.74 536.118 1019.35 536.383 1018.88C536.649 1018.4 536.815 1017.85 536.865 1017.28C536.914 1016.71 536.847 1016.12 536.668 1015.59C536.24 1014.18 535.498 1012.95 534.528 1012.03C533.559 1011.12 532.4 1010.56 531.186 1010.42C529.131 1010.45 528.276 1012.62 529.046 1015.4Z" fill="white" fill-opacity="0.6"/>
        <path d="M516.415 1009.7C514.342 1009.7 513.403 1011.89 514.114 1014.74C514.825 1017.6 516.897 1019.88 518.978 1019.97C521.06 1020.07 522.515 1017.6 521.686 1014.74C520.857 1011.89 518.454 1009.73 516.415 1009.7Z" fill="white" fill-opacity="0.6"/>
        <path d="M499.182 1014.17C499.842 1017.1 501.881 1019.46 503.979 1019.59C506.077 1019.71 507.574 1017.22 506.804 1014.29C506.035 1011.37 503.657 1009.06 501.601 1009.06C499.546 1009.06 498.522 1011.22 499.182 1014.17Z" fill="white" fill-opacity="0.6"/>
        <path d="M484.25 1013.76C484.893 1016.76 486.923 1019.17 489.03 1019.28C491.136 1019.4 492.634 1016.84 491.881 1013.82C491.128 1010.81 488.768 1008.49 486.695 1008.45C484.622 1008.41 483.598 1010.75 484.25 1013.76Z" fill="white" fill-opacity="0.6"/>
        <path d="M471.915 1008.02C469.775 1007.89 468.683 1010.23 469.25 1013.25C469.859 1016.48 472.025 1019.07 474.208 1019.04C476.247 1019.04 477.592 1016.54 476.991 1013.64C476.391 1010.73 473.996 1008.14 471.915 1008.02Z" fill="white" fill-opacity="0.6"/>
        <path d="M454.344 1013.6C455.02 1016.67 457.119 1019.04 459.191 1019C461.264 1018.96 462.575 1016.46 462.025 1013.51C461.408 1010.37 458.819 1007.67 456.662 1007.81C454.504 1007.95 453.65 1010.49 454.344 1013.6Z" fill="white" fill-opacity="0.6"/>
        <path d="M439.268 1013.57C439.902 1016.66 441.941 1019.05 444.048 1019.08C446.154 1019.12 447.686 1016.41 446.95 1013.27C446.214 1010.13 443.853 1007.79 441.772 1007.85C439.691 1007.91 438.642 1010.46 439.268 1013.57Z" fill="white" fill-opacity="0.6"/>
        <path d="M426.721 1008.23C424.598 1008.33 423.566 1010.88 424.183 1013.95C424.801 1017.01 426.848 1019.35 428.963 1019.37C431.078 1019.39 432.618 1016.68 431.891 1013.55C431.163 1010.42 428.811 1008.11 426.721 1008.23Z" fill="white" fill-opacity="0.6"/>
        <path d="M409.116 1013.7C409.793 1016.77 411.908 1019.21 413.998 1019.26C416.087 1019.31 417.441 1016.92 416.865 1014.03C416.248 1010.9 413.651 1008.09 411.468 1008.1C409.285 1008.11 408.422 1010.57 409.116 1013.7Z" fill="white" fill-opacity="0.6"/>
        <path d="M393.99 1013.1C394.607 1016.24 396.621 1018.71 398.744 1018.85C400.868 1019 402.416 1016.35 401.714 1013.2C401.011 1010.04 398.634 1007.58 396.544 1007.55C394.455 1007.52 393.38 1009.97 393.99 1013.1Z" fill="white" fill-opacity="0.6"/>
        <path d="M378.998 1013.41C379.684 1016.46 381.799 1018.82 383.871 1018.77C385.944 1018.72 387.255 1016.18 386.689 1013.21C386.062 1010.07 383.474 1007.37 381.316 1007.55C379.159 1007.73 378.321 1010.29 378.998 1013.41Z" fill="white" fill-opacity="0.6"/>
        <path d="M366.528 1007.94C364.422 1008.04 363.381 1010.49 363.99 1013.5C364.599 1016.51 366.613 1018.88 368.719 1018.93C370.826 1018.98 372.366 1016.28 371.646 1013.2C370.927 1010.11 368.592 1007.84 366.528 1007.94Z" fill="white" fill-opacity="0.6"/>
        <path d="M404.15 1007.89C406.299 1007.95 407.382 1005.57 406.477 1002.36C405.571 999.149 403.05 996.482 400.935 996.43C398.82 996.378 397.932 999.024 398.812 1002.16C399.692 1005.3 402.018 1007.84 404.15 1007.89Z" fill="white" fill-opacity="0.6"/>
        <path d="M419.277 1008.7C421.426 1008.57 422.517 1006.07 421.612 1002.89C420.706 999.714 418.228 997.214 416.07 997.371C413.964 997.194 413.05 999.735 413.921 1002.86C414.793 1005.99 417.145 1008.79 419.277 1008.7Z" fill="white" fill-opacity="0.6"/>
        <path d="M434.251 1008.15C436.425 1008.15 437.576 1005.78 436.747 1002.58C435.918 999.379 433.43 996.754 431.29 996.733C429.15 996.712 428.194 999.379 428.997 1002.52C429.801 1005.66 432.119 1008.14 434.251 1008.15Z" fill="white" fill-opacity="0.6"/>
        <path d="M449.284 1007.95C451.459 1008.01 452.601 1005.66 451.755 1002.52C450.909 999.38 448.447 996.691 446.315 996.618C444.183 996.545 443.236 999.087 444.039 1002.25C444.843 1005.4 447.169 1007.89 449.284 1007.95Z" fill="white" fill-opacity="0.6"/>
        <path d="M464.496 1008.03C466.602 1007.98 467.6 1005.51 466.628 1002.37C465.655 999.233 463.117 996.754 461.044 996.796C458.971 996.838 458.184 999.547 459.123 1002.61C460.063 1005.68 462.389 1008.09 464.496 1008.03Z" fill="white" fill-opacity="0.6"/>
        <path d="M479.411 1008.36C481.517 1008.36 482.524 1005.99 481.551 1002.91C480.578 999.84 478.049 997.371 475.976 997.35C473.903 997.329 473.108 999.913 474.047 1002.93C474.986 1005.94 477.304 1008.36 479.411 1008.36Z" fill="white" fill-opacity="0.6"/>
        <path d="M491.06 998.177C488.945 997.957 488.023 1000.2 488.844 1003.19C489.664 1006.18 491.991 1008.69 494.097 1008.86C496.204 1009.02 497.38 1006.9 496.525 1003.94C495.671 1000.98 493.209 998.396 491.06 998.177Z" fill="white" fill-opacity="0.6"/>
        <path d="M505.831 999.149C503.733 998.951 502.854 1001.14 503.716 1004.02C504.579 1006.91 506.923 1009.35 509.029 1009.49C511.136 1009.64 512.253 1007.58 511.356 1004.7C510.459 1001.83 507.938 999.38 505.831 999.149Z" fill="white" fill-opacity="0.6"/>
        <path d="M520.654 1000.24C518.547 1000.01 517.667 1002.06 518.53 1004.85C519.393 1007.64 521.753 1010.03 523.86 1010.19C525.967 1010.36 527.049 1008.41 526.203 1005.66C525.708 1004.26 524.943 1003.03 523.976 1002.09C523.008 1001.14 521.867 1000.51 520.654 1000.24Z" fill="white" fill-opacity="0.6"/>
        <path d="M538.75 1010.88C540.89 1011.04 541.931 1009.12 540.983 1006.46C540.455 1005.11 539.671 1003.93 538.697 1003.04C537.724 1002.14 536.59 1001.54 535.391 1001.3C533.301 1001.09 532.455 1003.1 533.369 1005.77C534.283 1008.44 536.643 1010.78 538.75 1010.88Z" fill="white" fill-opacity="0.6"/>
        <path d="M553.597 1011.63C555.737 1011.77 556.761 1009.91 555.797 1007.35C555.238 1006.03 554.435 1004.9 553.455 1004.04C552.474 1003.19 551.343 1002.63 550.154 1002.41C548.064 1002.21 547.244 1004.14 548.174 1006.71C548.678 1008.02 549.437 1009.16 550.384 1010.02C551.33 1010.87 552.434 1011.43 553.597 1011.63Z" fill="white" fill-opacity="0.6"/>
        <path d="M568.614 1012.23C570.746 1012.29 571.71 1010.39 570.652 1007.91C570.045 1006.64 569.204 1005.56 568.197 1004.77C567.19 1003.97 566.046 1003.48 564.857 1003.33C562.785 1003.24 562.032 1005.21 563.055 1007.66C563.605 1008.93 564.4 1010.01 565.37 1010.81C566.34 1011.61 567.454 1012.1 568.614 1012.23Z" fill="white" fill-opacity="0.6"/>
        <path d="M583.52 1012.62C585.72 1012.74 586.811 1010.95 585.864 1008.55C585.267 1007.29 584.434 1006.21 583.436 1005.42C582.437 1004.63 581.3 1004.14 580.119 1004C577.945 1003.87 577.082 1005.66 578.004 1008.06C578.554 1009.32 579.343 1010.39 580.305 1011.19C581.266 1011.98 582.37 1012.47 583.52 1012.62Z" fill="white" fill-opacity="0.6"/>
        <path d="M599.298 1012.74C601.329 1012.52 602.082 1010.36 600.813 1008.02C600.13 1006.84 599.227 1005.89 598.18 1005.25C597.133 1004.6 595.975 1004.28 594.806 1004.32C592.818 1004.55 592.268 1006.73 593.512 1008.99C594.139 1010.17 594.999 1011.14 596.012 1011.79C597.025 1012.45 598.156 1012.77 599.298 1012.74Z" fill="white" fill-opacity="0.6"/>
        <path d="M614.01 1012.59C616.21 1012.59 617.301 1010.74 616.362 1008.41C615.767 1007.17 614.931 1006.14 613.931 1005.41C612.93 1004.68 611.796 1004.27 610.635 1004.22C608.469 1004.22 607.581 1006.06 608.503 1008.41C609.061 1009.62 609.859 1010.64 610.821 1011.37C611.784 1012.1 612.881 1012.52 614.01 1012.59Z" fill="white" fill-opacity="0.6"/>
        <path d="M625.804 1003.56C623.68 1003.68 622.834 1005.66 623.782 1007.98C624.328 1009.2 625.125 1010.22 626.091 1010.94C627.058 1011.66 628.16 1012.06 629.289 1012.09C631.455 1011.99 632.309 1010.2 631.32 1007.82C630.782 1006.58 629.989 1005.53 629.021 1004.78C628.053 1004.03 626.944 1003.61 625.804 1003.56Z" fill="white" fill-opacity="0.6"/>
        <path d="M640.194 1003.67C638.121 1003.58 637.335 1005.37 638.248 1007.73C638.777 1008.95 639.538 1009.99 640.467 1010.76C641.397 1011.54 642.465 1012.02 643.578 1012.16C645.693 1012.23 646.717 1010.44 645.769 1008.06C645.197 1006.83 644.391 1005.78 643.42 1005.02C642.448 1004.25 641.342 1003.79 640.194 1003.67Z" fill="white" fill-opacity="0.6"/>
        <path d="M658.459 1012.22C660.6 1012.22 661.615 1010.36 660.634 1007.97C660.039 1006.73 659.205 1005.69 658.207 1004.95C657.209 1004.21 656.078 1003.78 654.915 1003.71C652.8 1003.71 651.987 1005.58 652.96 1007.89C653.513 1009.12 654.305 1010.16 655.265 1010.92C656.226 1011.68 657.324 1012.13 658.459 1012.22Z" fill="white" fill-opacity="0.6"/>
        <path d="M673.485 1012.19C675.71 1012.25 676.869 1010.44 676.023 1008.01C675.466 1006.74 674.654 1005.67 673.666 1004.9C672.678 1004.13 671.546 1003.69 670.38 1003.62C668.155 1003.55 667.182 1005.36 668.036 1007.74C668.563 1008.99 669.339 1010.06 670.293 1010.84C671.246 1011.62 672.345 1012.08 673.485 1012.19Z" fill="white" fill-opacity="0.6"/>
        <path d="M688.941 1012.1C691.2 1012.15 692.452 1010.31 691.691 1007.91C690.929 1005.52 688.408 1003.56 686.098 1003.48C683.789 1003.4 682.714 1005.27 683.518 1007.66C684.022 1008.93 684.79 1010.01 685.743 1010.79C686.696 1011.57 687.8 1012.02 688.941 1012.1Z" fill="white" fill-opacity="0.6"/>
        <path d="M704.88 1011.93C707.105 1011.86 708.315 1009.84 707.528 1007.41C707.011 1006.14 706.213 1005.07 705.227 1004.33C704.241 1003.59 703.106 1003.21 701.953 1003.23C699.719 1003.33 698.628 1005.38 699.465 1007.75C699.961 1009 700.731 1010.06 701.688 1010.8C702.645 1011.54 703.751 1011.93 704.88 1011.93Z" fill="white" fill-opacity="0.6"/>
        <path d="M718.314 1002.96C716.039 1003.02 714.778 1005.05 715.447 1007.45C715.872 1008.73 716.592 1009.81 717.517 1010.58C718.441 1011.35 719.528 1011.76 720.641 1011.76C722.908 1011.76 724.262 1009.74 723.636 1007.27C723.01 1004.79 720.607 1002.9 718.314 1002.96Z" fill="white" fill-opacity="0.6"/>
        <path d="M21.5185 1005.58C21.491 1004.96 21.3628 1004.36 21.1416 1003.8C20.9204 1003.24 20.6107 1002.74 20.2309 1002.34C19.8511 1001.93 19.4091 1001.63 18.9312 1001.43C18.4533 1001.24 17.9493 1001.18 17.4493 1001.23C16.423 1001.32 15.4635 1001.89 14.7691 1002.83C14.0747 1003.77 13.6985 1005 13.7183 1006.27C13.75 1006.89 13.8824 1007.5 14.1076 1008.05C14.3327 1008.61 14.6461 1009.1 15.0289 1009.51C15.4118 1009.91 15.8563 1010.21 16.336 1010.4C16.8158 1010.59 17.321 1010.65 17.8215 1010.59C18.8472 1010.53 19.8106 1009.96 20.5028 1009.03C21.195 1008.09 21.56 1006.85 21.5185 1005.58Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.6943 1010.5C33.1939 1010.52 33.6916 1010.41 34.1583 1010.19C34.6251 1009.97 35.0513 1009.64 35.4122 1009.21C35.773 1008.78 36.0613 1008.27 36.2599 1007.7C36.4585 1007.13 36.5636 1006.52 36.569 1005.91C36.5826 1004.66 36.1965 1003.46 35.4954 1002.57C34.7944 1001.68 33.8356 1001.17 32.8296 1001.15C31.823 1001.13 30.852 1001.61 30.1245 1002.47C29.3971 1003.33 28.9711 1004.51 28.938 1005.75C28.9313 1006.37 29.0232 1006.98 29.2086 1007.55C29.3939 1008.12 29.6691 1008.65 30.0181 1009.09C30.3671 1009.53 30.7832 1009.88 31.2424 1010.12C31.7017 1010.36 32.1951 1010.49 32.6943 1010.5Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.6686 1010.61C48.1556 1010.63 48.6408 1010.53 49.0939 1010.31C49.547 1010.09 49.9582 1009.75 50.302 1009.32C50.6457 1008.9 50.9146 1008.39 51.0918 1007.83C51.269 1007.27 51.3507 1006.66 51.3318 1006.06C51.2935 1004.83 50.878 1003.66 50.1694 1002.8C49.4609 1001.93 48.5124 1001.43 47.5163 1001.4C47.0308 1001.39 46.5489 1001.5 46.0992 1001.73C45.6495 1001.95 45.2412 1002.29 44.8987 1002.72C44.5561 1003.14 44.2863 1003.65 44.1052 1004.2C43.9242 1004.76 43.8356 1005.36 43.8447 1005.96C43.8667 1007.2 44.2784 1008.38 44.9925 1009.25C45.7065 1010.11 46.6666 1010.6 47.6686 1010.61Z" fill="white" fill-opacity="0.6"/>
        <path d="M62.2535 1010.88C62.7253 1010.94 63.2007 1010.86 63.6479 1010.67C64.0952 1010.48 64.5039 1010.17 64.8467 1009.77C65.1896 1009.36 65.4586 1008.87 65.6359 1008.33C65.8131 1007.79 65.8945 1007.2 65.8745 1006.62C65.8207 1005.39 65.4062 1004.24 64.7094 1003.37C64.0126 1002.49 63.0817 1001.97 62.0928 1001.89C61.626 1001.85 61.1579 1001.93 60.7181 1002.13C60.2783 1002.33 59.8764 1002.64 59.5377 1003.04C59.199 1003.43 58.931 1003.92 58.7505 1004.45C58.5699 1004.98 58.4809 1005.56 58.4889 1006.14C58.5194 1007.37 58.9236 1008.54 59.6211 1009.42C60.3186 1010.3 61.2584 1010.82 62.2535 1010.88Z" fill="white" fill-opacity="0.6"/>
        <path d="M76.9742 1011.37C77.4316 1011.41 77.8909 1011.32 78.3185 1011.12C78.746 1010.91 79.1309 1010.59 79.445 1010.18C79.759 1009.77 79.9942 1009.27 80.1332 1008.73C80.2722 1008.19 80.3115 1007.62 80.2483 1007.06C80.1005 1005.85 79.6152 1004.74 78.8764 1003.93C78.1377 1003.12 77.1923 1002.65 76.2044 1002.61C75.7529 1002.58 75.3019 1002.68 74.8828 1002.89C74.4638 1003.1 74.0868 1003.42 73.7782 1003.82C73.4696 1004.23 73.2369 1004.72 73.0963 1005.25C72.9557 1005.78 72.9106 1006.34 72.9641 1006.9C73.0844 1008.12 73.5565 1009.24 74.2934 1010.06C75.0304 1010.88 75.9825 1011.34 76.9742 1011.37Z" fill="white" fill-opacity="0.6"/>
        <path d="M91.1954 1011.93C93.2681 1012.09 94.7401 1010.37 94.5286 1008.03C94.3647 1006.82 93.8808 1005.72 93.1531 1004.89C92.4254 1004.06 91.4955 1003.56 90.5101 1003.46C90.0781 1003.37 89.6362 1003.41 89.2185 1003.57C88.8007 1003.73 88.4184 1004.01 88.1011 1004.38C87.7838 1004.75 87.5399 1005.21 87.3885 1005.72C87.237 1006.23 87.1819 1006.77 87.2276 1007.31C87.3559 1008.53 87.8211 1009.65 88.5443 1010.49C89.2675 1011.34 90.2041 1011.84 91.1954 1011.93Z" fill="white" fill-opacity="0.6"/>
        <path d="M105.738 1012.48C107.794 1012.55 109.122 1010.79 108.716 1008.52C108.434 1007.36 107.867 1006.33 107.092 1005.58C106.318 1004.83 105.375 1004.4 104.393 1004.34C103.979 1004.27 103.559 1004.33 103.167 1004.51C102.775 1004.69 102.423 1004.98 102.14 1005.36C101.858 1005.74 101.653 1006.19 101.543 1006.69C101.433 1007.19 101.421 1007.71 101.508 1008.22C101.744 1009.4 102.284 1010.45 103.047 1011.22C103.81 1011.99 104.754 1012.44 105.738 1012.48Z" fill="white" fill-opacity="0.6"/>
        <path d="M118.479 1005.19C116.525 1005.09 115.298 1006.76 115.738 1008.94C116.038 1010.1 116.62 1011.11 117.404 1011.85C118.188 1012.59 119.137 1013 120.121 1013.05C122.176 1013.12 123.445 1011.42 122.955 1009.24C122.607 1008.1 121.998 1007.12 121.204 1006.4C120.409 1005.68 119.462 1005.26 118.479 1005.19Z" fill="white" fill-opacity="0.6"/>
        <path d="M134.697 1013.57C136.744 1013.57 137.92 1011.89 137.294 1009.79C136.862 1008.67 136.189 1007.73 135.349 1007.06C134.51 1006.4 133.538 1006.03 132.54 1006C130.585 1006 129.469 1007.63 130.044 1009.7C130.426 1010.84 131.071 1011.82 131.899 1012.5C132.727 1013.19 133.7 1013.56 134.697 1013.57Z" fill="white" fill-opacity="0.6"/>
        <path d="M147.074 1006.7C145.027 1006.57 143.876 1008.02 144.375 1010.06C144.875 1012.1 146.964 1013.9 149.028 1014.01C151.093 1014.13 152.413 1012.58 151.854 1010.55C151.296 1008.52 149.122 1006.83 147.074 1006.7Z" fill="white" fill-opacity="0.6"/>
        <path d="M163.935 1014.26C166.042 1014.26 167.209 1012.65 166.473 1010.67C165.737 1008.68 163.461 1007.16 161.397 1007.13C159.333 1007.1 158.301 1008.66 158.969 1010.61C159.452 1011.72 160.169 1012.64 161.043 1013.29C161.918 1013.93 162.918 1014.27 163.935 1014.26Z" fill="white" fill-opacity="0.6"/>
        <path d="M178.706 1014.34C180.889 1014.4 182.158 1012.85 181.515 1010.88C180.872 1008.92 178.546 1007.4 176.439 1007.34C174.333 1007.28 173.173 1008.71 173.783 1010.65C174.392 1012.6 176.583 1014.29 178.706 1014.34Z" fill="white" fill-opacity="0.6"/>
        <path d="M193.96 1014.18C196.176 1014.18 197.462 1012.58 196.802 1010.61C196.143 1008.65 193.757 1007.19 191.574 1007.19C189.391 1007.19 188.249 1008.68 188.901 1010.6C189.552 1012.53 191.794 1014.18 193.96 1014.18Z" fill="white" fill-opacity="0.6"/>
        <path d="M207.048 1006.8C204.907 1006.63 203.723 1008.38 204.425 1010.31C205.127 1012.23 207.437 1013.7 209.594 1013.83C211.751 1013.97 212.978 1012.44 212.293 1010.48C211.608 1008.51 209.196 1006.96 207.048 1006.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M224.983 1014.37C227.132 1014.37 228.325 1012.64 227.521 1010.67C226.717 1008.69 224.382 1007.3 222.25 1007.37C220.118 1007.44 219.103 1008.99 219.864 1010.88C220.396 1011.97 221.149 1012.86 222.047 1013.47C222.945 1014.08 223.958 1014.39 224.983 1014.37Z" fill="white" fill-opacity="0.6"/>
        <path d="M240.118 1014.14C242.284 1014.04 243.502 1012.31 242.825 1010.24C242.148 1008.17 239.796 1006.73 237.664 1006.87C235.533 1007 234.433 1008.68 235.126 1010.64C235.82 1012.61 237.978 1014.22 240.118 1014.14Z" fill="white" fill-opacity="0.6"/>
        <path d="M255.075 1013.51C257.25 1013.51 258.569 1011.73 257.986 1009.56C257.402 1007.38 255.126 1005.75 252.986 1005.84C250.845 1005.94 249.669 1007.62 250.228 1009.72C250.786 1011.83 252.943 1013.54 255.075 1013.51Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.303 1012.86C272.402 1012.74 273.569 1010.82 272.841 1008.53C272.377 1007.36 271.643 1006.38 270.732 1005.71C269.82 1005.05 268.771 1004.73 267.715 1004.8C265.667 1004.98 264.627 1006.89 265.337 1009.1C265.779 1010.25 266.485 1011.22 267.367 1011.89C268.249 1012.56 269.269 1012.9 270.303 1012.86Z" fill="white" fill-opacity="0.6"/>
        <path d="M284.956 1012.46C287.071 1012.41 288.273 1010.5 287.579 1008.11C287.148 1006.9 286.437 1005.86 285.534 1005.14C284.631 1004.42 283.577 1004.05 282.503 1004.07C280.447 1004.16 279.398 1006.07 280.058 1008.35C280.459 1009.56 281.137 1010.59 282.008 1011.32C282.878 1012.05 283.904 1012.45 284.956 1012.46Z" fill="white" fill-opacity="0.6"/>
        <path d="M299.812 1011.83C300.27 1011.85 300.725 1011.73 301.132 1011.47C301.538 1011.21 301.883 1010.82 302.131 1010.34C302.379 1009.87 302.522 1009.32 302.546 1008.75C302.57 1008.19 302.473 1007.62 302.265 1007.12C301.828 1005.88 301.099 1004.82 300.174 1004.09C299.248 1003.36 298.168 1002.99 297.071 1003.03C295.041 1003.22 294.059 1005.34 294.795 1007.72C295.208 1008.94 295.905 1009.98 296.798 1010.71C297.691 1011.45 298.741 1011.83 299.812 1011.83Z" fill="white" fill-opacity="0.6"/>
        <path d="M314.541 1011C316.656 1010.88 317.798 1008.74 317.028 1006.09C316.616 1004.8 315.897 1003.69 314.966 1002.91C314.035 1002.12 312.934 1001.71 311.809 1001.71C309.753 1001.9 308.763 1004.11 309.499 1006.63C309.889 1007.9 310.579 1008.99 311.477 1009.77C312.376 1010.55 313.443 1010.98 314.541 1011Z" fill="white" fill-opacity="0.6"/>
        <path d="M329.507 1010.07C331.588 1009.86 332.637 1007.51 331.749 1004.72C330.86 1001.94 328.415 1000.01 326.377 1000.3C324.338 1000.59 323.466 1003.05 324.321 1005.67C324.719 1006.97 325.429 1008.08 326.356 1008.87C327.283 1009.66 328.382 1010.08 329.507 1010.07Z" fill="white" fill-opacity="0.6"/>
        <path d="M341.266 998.867C339.202 999.055 338.263 1001.53 339.058 1004.33C339.854 1007.12 342.129 1009.29 344.227 1009.18C346.326 1009.06 347.451 1006.71 346.622 1003.79C345.793 1000.88 343.348 998.679 341.266 998.867Z" fill="white" fill-opacity="0.6"/>
        <path d="M359.252 1008.38C361.351 1008.21 362.366 1005.7 361.435 1002.65C360.505 999.61 358.051 997.424 355.945 997.643C353.838 997.863 353.06 1000.58 353.965 1003.47C354.87 1006.36 357.154 1008.56 359.252 1008.38Z" fill="white" fill-opacity="0.6"/>
        <path d="M370.902 996.712C368.846 996.848 367.958 999.547 368.829 1002.56C369.701 1005.57 372.01 1007.94 374.117 1007.84C376.223 1007.75 377.306 1005.28 376.393 1002.13C375.479 998.982 372.992 996.566 370.902 996.712Z" fill="white" fill-opacity="0.6"/>
        <path d="M389.083 1007.64C391.223 1007.64 392.306 1005.19 391.409 1001.99C390.512 998.794 388.025 996.221 385.902 996.263C383.778 996.304 382.907 998.972 383.778 1002.08C384.65 1005.18 386.959 1007.65 389.083 1007.64Z" fill="white" fill-opacity="0.6"/>
        <path d="M395.208 990.332C394.04 986.996 391.282 984.266 389.286 984.423C387.289 984.58 386.748 987.477 387.966 990.803C389.091 993.857 391.544 996.336 393.473 996.336C395.546 996.336 396.384 993.69 395.208 990.332Z" fill="white" fill-opacity="0.6"/>
        <path d="M372.882 990.928C373.981 994.066 376.579 996.671 378.558 996.493C380.538 996.315 381.308 993.815 380.318 990.74C379.261 987.456 376.587 984.757 374.523 984.831C372.459 984.904 371.765 987.707 372.882 990.928Z" fill="white" fill-opacity="0.6"/>
        <path d="M358.026 991.974C359.117 995.028 361.706 997.455 363.669 997.204C365.631 996.953 366.384 994.453 365.361 991.462C364.303 988.324 361.655 985.772 359.608 985.95C357.56 986.128 356.918 988.868 358.026 991.974Z" fill="white" fill-opacity="0.6"/>
        <path d="M343.195 993.292C344.236 996.221 346.749 998.522 348.745 998.281C350.742 998.041 351.528 995.645 350.598 992.79C349.6 989.726 347.011 987.351 344.947 987.56C342.882 987.77 342.146 990.343 343.195 993.292Z" fill="white" fill-opacity="0.6"/>
        <path d="M328.424 994.788C329.388 997.591 331.808 999.777 333.83 999.631C335.852 999.484 336.698 997.225 335.852 994.516C334.938 991.577 332.468 989.286 330.344 989.412C328.221 989.537 327.46 992.006 328.424 994.788Z" fill="white" fill-opacity="0.6"/>
        <path d="M313.78 996.608C314.736 999.223 317.164 1001.27 319.16 1001.06C321.157 1000.85 322.011 998.679 321.165 996.127C320.252 993.376 317.781 991.305 315.692 991.462C313.602 991.619 312.824 993.982 313.78 996.608Z" fill="white" fill-opacity="0.6"/>
        <path d="M306.47 997.654C305.972 996.387 305.193 995.321 304.224 994.581C303.255 993.84 302.136 993.455 300.997 993.47C298.992 993.711 298.23 995.949 299.22 998.417C299.705 999.585 300.429 1000.57 301.319 1001.29C302.21 1002 303.236 1002.42 304.296 1002.49C306.343 1002.42 307.401 1000.18 306.47 997.654Z" fill="white" fill-opacity="0.6"/>
        <path d="M291.817 999.034C291.286 997.833 290.496 996.838 289.533 996.16C288.571 995.482 287.476 995.148 286.369 995.196C284.389 995.478 283.687 997.56 284.677 999.892C285.188 1001.01 285.924 1001.94 286.813 1002.61C287.702 1003.27 288.715 1003.63 289.753 1003.67C291.784 1003.56 292.773 1001.47 291.817 999.034Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.033 1000.61C270.548 1001.77 271.301 1002.73 272.214 1003.41C273.128 1004.09 274.17 1004.45 275.236 1004.47C277.131 1004.35 278.095 1002.52 277.317 1000.38C276.807 999.199 276.048 998.207 275.121 997.511C274.193 996.816 273.131 996.442 272.046 996.43C270.033 996.514 269.153 998.407 270.033 1000.61Z" fill="white" fill-opacity="0.6"/>
        <path d="M262.554 1001.13C261.986 1000 261.179 999.093 260.221 998.497C259.263 997.901 258.191 997.642 257.123 997.748C255.092 998.041 254.305 999.934 255.27 1002.02C255.819 1003.05 256.573 1003.9 257.461 1004.48C258.349 1005.06 259.342 1005.36 260.346 1005.33C262.41 1005.21 263.451 1003.28 262.554 1001.13Z" fill="white" fill-opacity="0.6"/>
        <path d="M240.135 1003.25C240.741 1004.29 241.551 1005.13 242.489 1005.67C243.427 1006.22 244.463 1006.47 245.499 1006.39C247.444 1006.12 248.4 1004.4 247.605 1002.51C246.81 1000.61 244.221 999.076 242.182 999.306C240.143 999.536 239.221 1001.3 240.135 1003.25Z" fill="white" fill-opacity="0.6"/>
        <path d="M224.856 1004.07C225.483 1005.09 226.3 1005.91 227.237 1006.47C228.175 1007.02 229.206 1007.29 230.245 1007.26C232.216 1007.09 233.215 1005.52 232.436 1003.74C231.658 1001.96 229.103 1000.48 226.988 1000.6C224.873 1000.73 223.951 1002.23 224.856 1004.07Z" fill="white" fill-opacity="0.6"/>
        <path d="M209.4 1003.69C210.246 1005.48 212.564 1007.17 214.704 1007.29C216.844 1007.4 217.86 1006.02 217.183 1004.33C216.506 1002.63 214.044 1001.02 211.836 1000.84C209.628 1000.67 208.596 1001.92 209.4 1003.69Z" fill="white" fill-opacity="0.6"/>
        <path d="M193.833 1003.81C194.679 1005.59 197.158 1007.12 199.281 1007.03C201.405 1006.93 202.42 1005.42 201.675 1003.73C200.931 1002.04 198.368 1000.59 196.151 1000.59C193.934 1000.59 192.962 1002.03 193.833 1003.81Z" fill="white" fill-opacity="0.6"/>
        <path d="M178.495 1003.94C179.341 1005.75 181.634 1007.33 183.749 1007.35C185.864 1007.37 186.862 1005.95 186.16 1004.21C185.457 1002.48 183.004 1000.95 180.838 1000.84C178.672 1000.74 177.716 1002.15 178.495 1003.94Z" fill="white" fill-opacity="0.6"/>
        <path d="M163.741 1003.87C164.325 1004.9 165.097 1005.76 165.997 1006.35C166.896 1006.95 167.897 1007.28 168.918 1007.32C170.839 1007.32 171.845 1005.86 171.101 1004.18C170.356 1002.5 167.928 1000.85 165.872 1000.76C163.817 1000.67 162.911 1002.03 163.741 1003.87Z" fill="white" fill-opacity="0.6"/>
        <path d="M149.282 1003.41C149.822 1004.46 150.561 1005.34 151.434 1005.96C152.308 1006.58 153.291 1006.93 154.299 1006.98C156.177 1006.98 157.159 1005.52 156.439 1003.76C155.72 1002 153.36 1000.31 151.363 1000.18C149.367 1000.06 148.495 1001.47 149.282 1003.41Z" fill="white" fill-opacity="0.6"/>
        <path d="M142.049 1002.97C141.526 1001.92 140.803 1001.04 139.943 1000.42C139.083 999.791 138.113 999.435 137.117 999.379C135.213 999.317 134.367 1000.83 135.146 1002.84C135.62 1003.86 136.29 1004.73 137.097 1005.35C137.904 1005.98 138.823 1006.35 139.773 1006.44C141.753 1006.5 142.819 1004.92 142.049 1002.97Z" fill="white" fill-opacity="0.6"/>
        <path d="M121.051 1001.66C121.444 1002.78 122.076 1003.74 122.88 1004.44C123.684 1005.15 124.628 1005.57 125.611 1005.66C127.455 1005.73 128.555 1004.23 128.031 1002.33C127.506 1000.43 125.408 998.522 123.471 998.292C121.533 998.062 120.484 999.589 121.051 1001.66Z" fill="white" fill-opacity="0.6"/>
        <path d="M107.185 1000.75C107.527 1001.89 108.128 1002.88 108.917 1003.6C109.705 1004.33 110.646 1004.75 111.627 1004.83C113.454 1004.83 114.537 1003.24 114.055 1001.24C113.708 1000.1 113.111 999.108 112.33 998.369C111.55 997.629 110.617 997.175 109.638 997.058C107.752 996.942 106.669 998.564 107.185 1000.75Z" fill="white" fill-opacity="0.6"/>
        <path d="M93.2852 999.505C93.5088 1000.68 94.0272 1001.75 94.7668 1002.54C95.5064 1003.33 96.4296 1003.82 97.4053 1003.93C99.2411 1004.05 100.51 1002.45 100.206 1000.37C99.9717 999.206 99.458 998.152 98.7319 997.352C98.0058 996.551 97.1011 996.04 96.1363 995.886C95.737 995.78 95.3224 995.8 94.9313 995.946C94.5403 996.092 94.1857 996.358 93.9007 996.72C93.6157 997.082 93.4097 997.527 93.3021 998.014C93.1944 998.501 93.1886 999.014 93.2852 999.505Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.3682 998.469C79.5162 999.668 79.9834 1000.77 80.696 1001.61C81.4085 1002.44 82.3257 1002.96 83.3021 1003.07C83.7044 1003.12 84.1112 1003.07 84.4926 1002.9C84.874 1002.73 85.2202 1002.46 85.5059 1002.11C85.7916 1001.75 86.0094 1001.32 86.1434 1000.85C86.2774 1000.38 86.3241 999.877 86.2801 999.379C86.1289 998.188 85.6711 997.089 84.9747 996.245C84.2782 995.401 83.3802 994.857 82.4138 994.694C81.9982 994.613 81.573 994.655 81.1731 994.816C80.7731 994.977 80.4096 995.254 80.1126 995.622C79.8155 995.99 79.5932 996.44 79.4638 996.935C79.3344 997.43 79.3017 997.956 79.3682 998.469Z" fill="white" fill-opacity="0.6"/>
        <path d="M65.3331 997.842C65.4767 999.051 65.958 1000.16 66.6936 1000.97C67.4292 1001.79 68.3726 1002.26 69.3601 1002.31C69.7882 1002.31 70.2125 1002.21 70.6038 1001.99C70.995 1001.78 71.3441 1001.46 71.6269 1001.06C71.9098 1000.67 72.1198 1000.2 72.2425 999.69C72.3653 999.182 72.3979 998.648 72.3381 998.124C72.2085 996.924 71.7512 995.815 71.0419 994.98C70.3326 994.145 69.4139 993.634 68.4379 993.533C67.988 993.492 67.5366 993.581 67.1186 993.79C66.7006 994 66.3272 994.326 66.0271 994.742C65.7271 995.158 65.5085 995.655 65.3881 996.192C65.2678 996.73 65.2489 997.294 65.3331 997.842Z" fill="white" fill-opacity="0.6"/>
        <path d="M58.1504 997.288C58.061 996.073 57.618 994.941 56.9037 994.1C56.1895 993.259 55.2526 992.768 54.2672 992.717C53.8039 992.719 53.3464 992.843 52.9253 993.082C52.5043 993.321 52.1296 993.669 51.8265 994.102C51.5234 994.535 51.299 995.044 51.1684 995.593C51.0378 996.143 51.004 996.721 51.0693 997.288C51.167 998.448 51.5933 999.529 52.2729 1000.34C52.9524 1001.15 53.8416 1001.63 54.7833 1001.71C55.249 1001.74 55.7134 1001.64 56.145 1001.42C56.5767 1001.2 56.9654 1000.87 57.2848 1000.45C57.6042 1000.03 57.8469 999.532 57.9962 998.986C58.1456 998.44 58.1981 997.861 58.1504 997.288Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.726 996.587C43.6655 995.358 43.2268 994.204 42.5001 993.361C41.7733 992.519 40.8135 992.053 39.8174 992.058C39.3352 992.092 38.8637 992.248 38.432 992.516C38.0002 992.784 37.6173 993.158 37.3068 993.615C36.9962 994.073 36.7646 994.604 36.626 995.176C36.4875 995.748 36.4449 996.349 36.501 996.942C36.5899 998.11 37.0222 999.197 37.7161 999.997C38.41 1000.8 39.3172 1001.25 40.2658 1001.28C40.7478 1001.28 41.2236 1001.15 41.6634 1000.9C42.1033 1000.66 42.4978 1000.31 42.8224 999.866C43.147 999.425 43.3947 998.907 43.5502 998.343C43.7057 997.778 43.7655 997.181 43.726 996.587Z" fill="white" fill-opacity="0.6"/>
        <path d="M28.9886 996.618C28.9947 995.712 28.7848 994.824 28.3853 994.065C27.9857 993.306 27.4142 992.709 26.7419 992.348C26.0696 991.988 25.3264 991.88 24.605 992.039C23.8836 992.197 23.2159 992.615 22.6852 993.24C22.1546 993.864 21.7844 994.668 21.621 995.551C21.4575 996.434 21.508 997.357 21.7661 998.205C22.0242 999.053 22.4786 999.788 23.0724 1000.32C23.6663 1000.85 24.3733 1001.15 25.1054 1001.19C25.6035 1001.21 26.0993 1001.1 26.5644 1000.88C27.0294 1000.66 27.4546 1000.33 27.8155 999.901C28.1763 999.477 28.4657 998.968 28.6671 998.405C28.8684 997.841 28.9777 997.234 28.9886 996.618Z" fill="white" fill-opacity="0.6"/>
        <path d="M13.8112 996.576C13.8066 995.954 13.6992 995.34 13.4957 994.77C13.2922 994.201 12.9968 993.69 12.6277 993.267C12.2586 992.844 11.8236 992.518 11.3492 992.31C10.8748 992.102 10.371 992.017 9.86882 992.058C8.83354 992.131 7.86228 992.701 7.16215 993.647C6.46203 994.592 6.08844 995.839 6.12101 997.12C6.17564 998.316 6.60433 999.439 7.31639 1000.25C8.02845 1001.06 8.96807 1001.5 9.93651 1001.47C10.9733 1001.45 11.9613 1000.92 12.6865 1000.01C13.4117 999.091 13.8158 997.858 13.8112 996.576Z" fill="white" fill-opacity="0.6"/>
        <path d="M704.668 999.013C705.234 1000.28 706.063 1001.33 707.068 1002.07C708.073 1002.8 709.219 1003.19 710.387 1003.2C712.494 1003.02 713.594 1001.11 712.832 998.815C712.071 996.524 709.381 994.464 707.071 994.537C704.761 994.61 703.721 996.597 704.668 999.013Z" fill="white" fill-opacity="0.6"/>
        <path d="M688.298 999.264C688.916 1000.51 689.775 1001.54 690.797 1002.27C691.819 1003 692.972 1003.41 694.152 1003.45C696.225 1003.31 697.24 1001.43 696.369 999.181C695.759 997.901 694.895 996.836 693.859 996.085C692.822 995.335 691.648 994.924 690.447 994.892C688.163 994.903 687.241 996.88 688.298 999.264Z" fill="white" fill-opacity="0.6"/>
        <path d="M680.176 999.442C679.521 998.179 678.629 997.13 677.576 996.384C676.523 995.637 675.34 995.215 674.128 995.154C671.894 995.154 671.099 997.099 672.241 999.453C672.868 1000.63 673.699 1001.61 674.674 1002.33C675.649 1003.06 676.743 1003.5 677.875 1003.64C680.075 1003.74 681.2 1001.79 680.176 999.442Z" fill="white" fill-opacity="0.6"/>
        <path d="M656.742 999.704C658.011 1002 660.778 1003.89 662.757 1003.73C664.737 1003.57 665.431 1001.71 664.305 999.484C663.605 998.252 662.686 997.234 661.619 996.509C660.551 995.783 659.363 995.37 658.146 995.3C656.015 995.415 655.456 997.361 656.742 999.704Z" fill="white" fill-opacity="0.6"/>
        <path d="M641.649 999.578C642.901 1001.87 645.592 1003.84 647.571 1003.76C649.551 1003.69 650.194 1001.88 649.077 999.683C648.374 998.457 647.46 997.438 646.401 996.698C645.343 995.959 644.165 995.517 642.952 995.405C640.913 995.374 640.414 997.288 641.649 999.578Z" fill="white" fill-opacity="0.6"/>
        <path d="M627.293 999.379C628.545 1001.67 631.142 1003.66 633.02 1003.56C634.898 1003.47 635.49 1001.72 634.357 999.526C633.673 998.329 632.792 997.326 631.771 996.583C630.75 995.84 629.613 995.374 628.435 995.217C626.472 995.196 626.032 997.099 627.293 999.379Z" fill="white" fill-opacity="0.6"/>
        <path d="M612.293 999.965C613.545 1002.25 616.294 1004.15 618.316 1003.96C620.338 1003.77 621.024 1001.92 619.898 999.777C619.191 998.564 618.27 997.566 617.204 996.86C616.138 996.153 614.956 995.756 613.748 995.698C611.65 995.719 611.024 997.654 612.293 999.965Z" fill="white" fill-opacity="0.6"/>
        <path d="M604.45 999.934C603.689 998.747 602.728 997.778 601.634 997.096C600.54 996.413 599.339 996.032 598.114 995.98C596.083 996.137 595.694 998.072 597.107 1000.47C598.41 1002.62 601.024 1004.4 602.962 1004.33C605.085 1004.25 605.804 1002.28 604.45 999.934Z" fill="white" fill-opacity="0.6"/>
        <path d="M581.634 999.84C582.894 1002.2 585.618 1004.24 587.674 1004.24C589.73 1004.24 590.424 1002.45 589.307 1000.25C588.589 998.999 587.667 997.952 586.6 997.173C585.533 996.395 584.345 995.903 583.114 995.729C580.982 995.593 580.348 997.465 581.634 999.84Z" fill="white" fill-opacity="0.6"/>
        <path d="M574.028 999.662C573.309 998.398 572.388 997.331 571.321 996.527C570.254 995.722 569.065 995.198 567.827 994.987C565.746 994.84 565.221 996.754 566.533 999.223C567.192 1000.42 568.04 1001.43 569.026 1002.21C570.012 1002.99 571.115 1003.51 572.268 1003.75C574.383 1003.97 575.272 1002.07 574.028 999.662Z" fill="white" fill-opacity="0.6"/>
        <path d="M551.584 997.957C552.802 1000.52 555.391 1002.78 557.446 1002.95C559.502 1003.11 560.187 1001.32 559.138 998.972C558.469 997.652 557.589 996.516 556.552 995.633C555.514 994.75 554.342 994.139 553.106 993.836C550.991 993.501 550.365 995.405 551.584 997.957Z" fill="white" fill-opacity="0.6"/>
        <path d="M536.905 996.817C538.124 999.474 540.755 1001.84 542.751 1001.94C544.748 1002.05 545.408 1000.16 544.308 997.706C543.665 996.353 542.8 995.182 541.768 994.269C540.736 993.355 539.561 992.72 538.318 992.403C536.262 992.058 535.679 994.15 536.905 996.817Z" fill="white" fill-opacity="0.6"/>
        <path d="M522.151 995.436C523.335 998.229 525.924 1000.67 527.946 1000.83C529.968 1001 530.662 999.045 529.638 996.472C528.504 993.711 525.823 991.242 523.716 990.876C521.609 990.51 520.975 992.644 522.151 995.436Z" fill="white" fill-opacity="0.6"/>
        <path d="M507.464 994.275C508.657 997.162 511.305 999.704 513.268 999.746C515.231 999.787 515.907 997.727 514.825 995.028C513.666 992.152 510.941 989.569 508.903 989.328C506.864 989.088 506.263 991.347 507.464 994.275Z" fill="white" fill-opacity="0.6"/>
        <path d="M499.977 993.533C498.759 990.531 495.984 987.937 494.004 987.853C492.025 987.77 491.534 990.238 492.803 993.303C493.979 996.148 496.407 998.532 498.336 998.7C500.265 998.867 501.195 996.535 499.977 993.533Z" fill="white" fill-opacity="0.6"/>
        <path d="M477.905 992.058C479.072 995.196 481.712 997.821 483.666 997.79C485.62 997.758 486.323 995.489 485.265 992.56C484.14 989.422 481.424 986.724 479.343 986.556C477.262 986.389 476.729 988.92 477.905 992.058Z" fill="white" fill-opacity="0.6"/>
        <path d="M470.291 991.336C469.081 988.198 466.272 985.469 464.369 985.563C462.465 985.657 461.941 988.418 463.218 991.671C464.394 994.662 466.873 997.12 468.76 997.11C470.773 997.11 471.534 994.568 470.291 991.336Z" fill="white" fill-opacity="0.6"/>
        <path d="M448.075 990.74C449.174 994.003 451.738 996.723 453.768 996.712C455.799 996.702 456.56 994.307 455.587 991.242C454.53 987.947 451.89 985.103 449.775 984.967C447.66 984.831 446.975 987.456 448.075 990.74Z" fill="white" fill-opacity="0.6"/>
        <path d="M433.143 991.012C434.31 994.286 436.975 996.901 438.921 996.65C440.867 996.399 441.569 993.878 440.511 990.74C439.386 987.414 436.654 984.653 434.589 984.736C432.525 984.82 431.992 987.686 433.143 991.012Z" fill="white" fill-opacity="0.6"/>
        <path d="M418.041 991.389C419.167 994.673 421.798 997.277 423.794 997.058C425.791 996.838 426.561 994.307 425.554 991.158C424.48 987.811 421.789 985.05 419.691 985.113C417.593 985.176 416.908 988.073 418.041 991.389Z" fill="white" fill-opacity="0.6"/>
        <path d="M410.301 991.127C409.15 987.791 406.384 984.851 404.378 984.851C402.373 984.851 401.764 987.644 402.983 991.002C404.108 994.139 406.536 996.681 408.499 996.827C410.605 997.016 411.493 994.474 410.301 991.127Z" fill="white" fill-opacity="0.6"/>
        <path d="M188.19 1000.75C190.339 1000.75 191.346 999.254 190.254 997.612C189.163 995.97 186.71 994.955 184.654 994.966C182.446 994.966 181.651 996.221 182.75 997.884C184.186 999.69 186.142 1000.72 188.19 1000.75Z" fill="white" fill-opacity="0.6"/>
        <path d="M173.089 1000.81C175.17 1000.86 176.126 999.421 175.06 997.8C173.994 996.179 171.617 995.028 169.654 994.966C167.539 994.903 166.778 996.137 167.835 997.831C169.201 999.64 171.089 1000.71 173.089 1000.81Z" fill="white" fill-opacity="0.6"/>
        <path d="M155.264 994.422C153.216 994.265 152.429 995.468 153.394 997.246C154.013 998.17 154.778 998.928 155.642 999.475C156.506 1000.02 157.451 1000.34 158.419 1000.43C160.441 1000.54 161.406 999.128 160.424 997.455C159.088 995.654 157.237 994.566 155.264 994.422Z" fill="white" fill-opacity="0.6"/>
        <path d="M141.186 993.439C139.342 993.292 138.555 994.484 139.291 996.179C139.844 997.208 140.581 998.065 141.445 998.686C142.309 999.306 143.277 999.672 144.274 999.756C146.11 999.85 147.049 998.543 146.287 996.89C145.526 995.238 143.115 993.595 141.186 993.439Z" fill="white" fill-opacity="0.6"/>
        <path d="M127.642 992.173C125.704 991.943 124.841 993.219 125.628 995.196C126.116 996.193 126.783 997.036 127.579 997.66C128.374 998.285 129.276 998.673 130.213 998.794C132.151 998.972 133.157 997.486 132.328 995.656C131.796 994.693 131.102 993.883 130.293 993.282C129.484 992.68 128.579 992.302 127.642 992.173Z" fill="white" fill-opacity="0.6"/>
        <path d="M114.257 990.688C112.346 990.426 111.398 991.817 112.075 993.826C112.486 994.86 113.1 995.75 113.861 996.415C114.622 997.08 115.506 997.499 116.432 997.633C118.344 997.821 119.427 996.283 118.707 994.349C118.247 993.353 117.605 992.504 116.832 991.868C116.06 991.233 115.178 990.829 114.257 990.688Z" fill="white" fill-opacity="0.6"/>
        <path d="M102.904 996.44C104.681 996.555 105.747 995.039 105.248 993.114C104.876 992.011 104.27 991.053 103.494 990.34C102.718 989.628 101.801 989.188 100.84 989.067C99.0886 988.889 98.1157 990.29 98.5725 992.319C98.9013 993.446 99.4838 994.432 100.251 995.162C101.019 995.892 101.939 996.336 102.904 996.44Z" fill="white" fill-opacity="0.6"/>
        <path d="M89.199 995.196C90.984 995.321 92.16 993.752 91.7962 991.723C91.5132 990.584 90.9667 989.572 90.224 988.812C89.4814 988.051 88.5752 987.577 87.6169 987.445C87.2252 987.353 86.8205 987.392 86.4455 987.56C86.0705 987.728 85.739 988.018 85.4861 988.399C85.2332 988.78 85.0681 989.239 85.0084 989.726C84.9486 990.214 84.9963 990.712 85.1465 991.169C85.428 992.257 85.9624 993.22 86.6829 993.936C87.4035 994.652 88.2785 995.09 89.199 995.196Z" fill="white" fill-opacity="0.6"/>
        <path d="M75.282 994.035C75.6978 994.139 76.1282 994.113 76.5337 993.958C76.9392 993.803 77.3069 993.526 77.6031 993.15C77.8993 992.774 78.1147 992.313 78.2294 991.808C78.3442 991.303 78.3547 990.77 78.2599 990.259C78.0514 989.147 77.5737 988.14 76.8906 987.372C76.2074 986.604 75.3512 986.111 74.436 985.96C74.027 985.854 73.6029 985.876 73.2029 986.026C72.8029 986.176 72.4398 986.448 72.1472 986.817C71.8547 987.186 71.6421 987.64 71.529 988.137C71.4159 988.635 71.4061 989.159 71.5003 989.663C71.6746 990.793 72.1347 991.825 72.8144 992.611C73.4941 993.397 74.3582 993.895 75.282 994.035Z" fill="white" fill-opacity="0.6"/>
        <path d="M61.5259 993.041C61.9423 993.08 62.3604 993.004 62.7505 992.82C63.1406 992.636 63.4932 992.348 63.7831 991.977C64.0731 991.606 64.2933 991.16 64.4281 990.671C64.5629 990.183 64.609 989.663 64.5631 989.15C64.4397 987.958 63.9879 986.855 63.2837 986.026C62.5796 985.198 61.666 984.694 60.6968 984.6C60.2601 984.556 59.8211 984.639 59.4147 984.842C59.0083 985.044 58.6457 985.361 58.3558 985.767C58.0659 986.173 57.8566 986.658 57.7445 987.181C57.6325 987.705 57.6208 988.254 57.7104 988.784C57.8635 989.922 58.3241 990.963 59.017 991.736C59.71 992.509 60.5943 992.969 61.5259 993.041Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.127 992.278C47.5859 992.33 48.0486 992.259 48.4831 992.069C48.9176 991.879 49.3135 991.575 49.6434 991.177C49.9733 990.779 50.2293 990.297 50.3937 989.765C50.5582 989.232 50.627 988.662 50.5956 988.094C50.5158 986.944 50.1138 985.864 49.4589 985.041C48.8041 984.218 47.9375 983.703 47.0086 983.586C46.5481 983.519 46.0814 983.581 45.6427 983.766C45.204 983.952 44.8046 984.256 44.4735 984.657C44.1425 985.059 43.8883 985.546 43.7296 986.085C43.571 986.624 43.512 987.199 43.5568 987.77C43.6247 988.922 44.0206 990.007 44.6742 990.832C45.3278 991.657 46.1968 992.169 47.127 992.278Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.6602 991.849C33.1413 991.87 33.6207 991.767 34.068 991.547C34.5153 991.327 34.9208 990.995 35.2588 990.571C35.5968 990.147 35.8599 989.641 36.0315 989.085C36.2032 988.529 36.2795 987.935 36.2558 987.341C36.1922 986.178 35.7843 985.085 35.1101 984.271C34.4359 983.457 33.5426 982.978 32.601 982.927C32.1177 982.888 31.6333 982.977 31.1805 983.19C30.7276 983.402 30.3169 983.732 29.976 984.157C29.6352 984.583 29.3722 985.094 29.2047 985.655C29.0373 986.217 28.9693 986.817 29.0055 987.414C29.0557 988.584 29.4589 989.689 30.1354 990.51C30.812 991.331 31.7128 991.809 32.6602 991.849Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.6352 991.828C18.1342 991.835 18.6295 991.719 19.0921 991.488C19.5548 991.257 19.9758 990.914 20.3306 990.48C20.6855 990.046 20.9672 989.53 21.1595 988.96C21.3517 988.391 21.4507 987.78 21.4507 987.163C21.4489 986.246 21.2279 985.349 20.8155 984.587C20.403 983.824 19.8174 983.229 19.1323 982.877C18.4472 982.524 17.6931 982.43 16.9648 982.606C16.2365 982.781 15.5664 983.219 15.0387 983.864C14.5111 984.51 14.1495 985.333 13.9992 986.231C13.8489 987.13 13.9168 988.063 14.1942 988.914C14.4717 989.765 14.9463 990.495 15.5585 991.014C16.1707 991.532 16.8932 991.815 17.6352 991.828Z" fill="white" fill-opacity="0.6"/>
        <path d="M575.212 995.353C577.378 995.53 578.072 993.7 576.583 991.389C574.944 989.042 572.677 987.505 570.196 987.058C567.962 986.828 567.505 988.533 569.028 990.949C570.576 993.316 572.78 994.886 575.212 995.353Z" fill="white" fill-opacity="0.6"/>
        <path d="M590.61 995.865C592.759 995.928 593.393 994.003 591.862 991.776C590.192 989.527 587.914 988.113 585.458 987.801C583.241 987.717 582.835 989.485 584.4 991.817C585.979 994.092 588.199 995.539 590.61 995.865Z" fill="white" fill-opacity="0.6"/>
        <path d="M605.956 995.834C608.105 995.834 608.765 993.878 607.259 991.65C605.608 989.424 603.326 988.072 600.889 987.874C598.664 987.874 598.224 989.652 599.772 991.943C601.342 994.191 603.562 995.588 605.956 995.834Z" fill="white" fill-opacity="0.6"/>
        <path d="M621.328 995.196C623.308 995.06 623.722 993.428 622.411 991.336C621.1 989.244 618.249 987.153 616.117 987.299C613.985 987.445 613.579 989.391 615.178 991.619C616.777 993.847 619.34 995.342 621.328 995.196Z" fill="white" fill-opacity="0.6"/>
        <path d="M635.66 995.311C637.529 995.311 638.062 993.48 636.717 991.378C635.91 990.223 634.925 989.277 633.82 988.594C632.715 987.912 631.511 987.507 630.279 987.404C628.367 987.404 628.071 989.108 629.433 991.263C630.795 993.418 633.646 995.363 635.66 995.311Z" fill="white" fill-opacity="0.6"/>
        <path d="M650.431 995.342C652.563 995.342 653.273 993.418 651.827 991.158C650.219 988.916 647.96 987.547 645.541 987.351C643.308 987.351 642.842 989.14 644.357 991.409C645.885 993.655 648.067 995.068 650.431 995.342Z" fill="white" fill-opacity="0.6"/>
        <path d="M661.26 987.205C659.128 987.289 658.561 989.014 659.83 991.19C660.593 992.387 661.556 993.366 662.655 994.057C663.753 994.749 664.96 995.138 666.192 995.196C668.231 995.112 669.009 993.271 667.808 991.138C667.014 989.932 666.017 988.955 664.886 988.276C663.755 987.596 662.517 987.231 661.26 987.205Z" fill="white" fill-opacity="0.6"/>
        <path d="M682.308 994.955C684.415 994.861 685.337 993.01 684.254 990.845C683.171 988.68 680.176 986.755 677.799 986.828C675.591 986.933 674.855 988.69 676.022 990.887C676.747 992.117 677.691 993.123 678.781 993.829C679.871 994.535 681.078 994.92 682.308 994.955Z" fill="white" fill-opacity="0.6"/>
        <path d="M698.577 994.631C700.921 994.631 702.113 992.675 701.073 990.364C700.032 988.052 697.41 986.41 695.083 986.41C692.545 986.41 691.589 988.272 692.723 990.594C693.379 991.781 694.25 992.764 695.265 993.464C696.281 994.165 697.415 994.564 698.577 994.631Z" fill="white" fill-opacity="0.6"/>
        <path d="M715.455 994.234C717.807 994.234 719.101 992.142 718.162 989.809C717.223 987.477 714.668 985.845 712.342 985.887C709.804 985.887 708.729 987.885 709.761 990.269C710.369 991.463 711.208 992.45 712.202 993.142C713.196 993.834 714.314 994.209 715.455 994.234Z" fill="white" fill-opacity="0.6"/>
        <path d="M560.196 994.39C562.294 994.6 562.912 992.706 561.389 990.301C559.783 987.931 557.555 986.332 555.094 985.782C552.929 985.5 552.556 987.278 554.105 989.809C555.543 992.058 558.216 994.15 560.196 994.39Z" fill="white" fill-opacity="0.6"/>
        <path d="M545.602 993.104C547.497 993.24 548.03 991.42 546.677 989.035C545.323 986.651 542.277 984.214 540.247 983.994C538.217 983.774 537.988 985.552 539.359 988.052C540.729 990.552 543.58 992.916 545.602 993.104Z" fill="white" fill-opacity="0.6"/>
        <path d="M530.933 991.577C532.828 991.723 533.377 989.82 532.058 987.309C530.738 984.799 527.701 982.237 525.67 982.007C523.64 981.776 523.386 983.638 524.731 986.264C526.076 988.889 528.902 991.409 530.933 991.577Z" fill="white" fill-opacity="0.6"/>
        <path d="M511.111 979.988C509.165 979.737 508.767 981.682 510.07 984.433C511.373 987.184 514.19 989.841 516.221 989.966C518.251 990.092 518.759 988.157 517.439 985.531C516.051 982.801 513.15 980.239 511.111 979.988Z" fill="white" fill-opacity="0.6"/>
        <path d="M501.568 988.512C503.463 988.627 504.038 986.515 502.752 983.753C501.382 980.825 498.471 978.21 496.441 978.001C494.41 977.791 494.131 979.904 495.434 982.791C496.737 985.678 499.537 988.376 501.568 988.512Z" fill="white" fill-opacity="0.6"/>
        <path d="M486.822 987.121C488.725 987.215 489.36 985.029 488.065 982.122C486.72 979.068 483.835 976.359 481.788 976.181C479.741 976.003 479.445 978.273 480.722 981.233C482 984.193 484.749 987.017 486.822 987.121Z" fill="white" fill-opacity="0.6"/>
        <path d="M471.873 985.96C473.954 986.148 474.597 983.691 473.243 980.553C471.89 977.415 469.225 974.936 467.237 974.695C465.105 974.444 464.623 976.913 466.044 980.197C467.355 983.209 469.919 985.782 471.873 985.96Z" fill="white" fill-opacity="0.6"/>
        <path d="M452.482 973.493C450.325 973.273 449.801 975.846 451.188 979.224C452.465 982.362 455.029 984.967 457 985.113C459.098 985.28 459.826 982.697 458.464 979.455C457.102 976.212 454.479 973.691 452.482 973.493Z" fill="white" fill-opacity="0.6"/>
        <path d="M442.271 984.684C444.2 984.684 444.868 982.101 443.659 978.952C442.364 975.616 439.488 972.792 437.415 972.813C435.342 972.834 434.962 975.396 436.197 978.639C437.432 981.881 440.207 984.736 442.271 984.684Z" fill="white" fill-opacity="0.6"/>
        <path d="M427.221 984.736C429.175 984.642 429.877 982.038 428.693 978.848C427.432 975.469 424.547 972.645 422.449 972.729C420.351 972.813 419.911 975.867 421.341 979.224C422.72 982.467 425.275 984.862 427.221 984.736Z" fill="white" fill-opacity="0.6"/>
        <path d="M411.993 985.437C414.124 985.364 414.894 982.519 413.558 979.162C412.221 975.804 409.581 973.367 407.543 973.43C405.343 973.503 404.768 975.752 406.13 979.214C407.39 982.425 409.971 985.176 411.993 985.437Z" fill="white" fill-opacity="0.6"/>
        <path d="M396.967 984.454C398.93 984.454 399.675 982.027 398.516 978.837C397.297 975.449 394.404 972.489 392.289 972.457C390.174 972.426 389.692 975.292 391.105 978.733C392.433 981.902 394.988 984.412 396.967 984.454Z" fill="white" fill-opacity="0.6"/>
        <path d="M381.79 984.444C383.914 984.496 384.709 981.766 383.406 978.45C382.103 975.135 379.506 972.677 377.484 972.677C375.31 972.677 374.726 975.407 376.046 978.764C377.264 981.881 379.802 984.402 381.79 984.444Z" fill="white" fill-opacity="0.6"/>
        <path d="M367.154 985.207C369.066 984.987 369.743 982.362 368.558 979.308C367.306 976.055 364.481 973.555 362.416 973.848C360.352 974.141 359.98 976.756 361.181 979.821C362.383 982.885 365.073 985.448 367.154 985.207Z" fill="white" fill-opacity="0.6"/>
        <path d="M347.628 975.752C345.716 976.139 345.243 978.733 346.435 981.651C347.628 984.569 350.336 986.881 352.358 986.577C354.244 986.263 354.896 983.628 353.711 980.72C352.442 977.593 349.659 975.323 347.628 975.752Z" fill="white" fill-opacity="0.6"/>
        <path d="M337.544 988.376C339.464 988.104 340.209 985.667 339.126 982.885C337.975 979.936 335.201 977.75 333.153 978.116C331.106 978.482 330.615 981.18 331.918 984.036C333.221 986.891 335.581 988.648 337.544 988.376Z" fill="white" fill-opacity="0.6"/>
        <path d="M322.773 990.395C324.82 990.154 325.598 987.592 324.355 984.872C323.111 982.153 320.615 980.438 318.67 980.751C316.58 981.097 315.996 983.544 317.257 986.263C318.424 988.784 320.844 990.635 322.773 990.395Z" fill="white" fill-opacity="0.6"/>
        <path d="M308.162 992.434C310.201 992.204 311.022 989.82 309.854 987.268C308.687 984.716 306.199 983.084 304.262 983.429C302.172 983.753 301.546 986.012 302.756 988.554C303.839 990.897 306.242 992.644 308.162 992.434Z" fill="white" fill-opacity="0.6"/>
        <path d="M289.795 985.929C287.723 986.222 287.088 988.314 288.264 990.667C288.842 991.781 289.636 992.698 290.575 993.334C291.513 993.97 292.566 994.304 293.636 994.307C295.667 994.098 296.496 991.849 295.328 989.485C294.737 988.348 293.915 987.421 292.944 986.797C291.974 986.174 290.888 985.874 289.795 985.929Z" fill="white" fill-opacity="0.6"/>
        <path d="M279.068 995.823C281.115 995.708 282.012 993.679 280.912 991.462C280.314 990.37 279.504 989.481 278.557 988.875C277.61 988.268 276.556 987.964 275.489 987.989C273.408 988.178 272.706 990.081 273.797 992.257C274.378 993.333 275.16 994.22 276.078 994.841C276.996 995.462 278.022 995.799 279.068 995.823Z" fill="white" fill-opacity="0.6"/>
        <path d="M260.904 989.6C258.958 989.872 258.239 991.577 259.212 993.543C259.822 994.64 260.647 995.528 261.611 996.122C262.574 996.717 263.644 996.999 264.72 996.942C266.632 996.733 267.495 994.955 266.573 992.957C265.944 991.838 265.091 990.94 264.098 990.351C263.104 989.763 262.003 989.504 260.904 989.6Z" fill="white" fill-opacity="0.6"/>
        <path d="M249.686 998.501C251.793 998.302 252.749 996.409 251.674 994.495C251.014 993.506 250.167 992.734 249.208 992.245C248.249 991.756 247.208 991.566 246.175 991.692C244.018 991.974 243.231 993.69 244.323 995.593C244.971 996.562 245.793 997.33 246.725 997.835C247.656 998.34 248.671 998.568 249.686 998.501Z" fill="white" fill-opacity="0.6"/>
        <path d="M234.61 1000.02C236.717 999.84 237.673 998.062 236.573 996.304C235.473 994.547 233.037 993.522 231.006 993.752C228.841 994.003 228.062 995.551 229.179 997.298C229.871 998.211 230.715 998.927 231.653 999.397C232.59 999.866 233.6 1000.08 234.61 1000.02Z" fill="white" fill-opacity="0.6"/>
        <path d="M219.162 1000.84C221.328 1000.84 222.377 999.359 221.345 997.706C220.313 996.053 217.893 994.966 215.804 994.997C213.57 994.997 212.699 996.263 213.756 997.926C215.175 999.745 217.119 1000.79 219.162 1000.84Z" fill="white" fill-opacity="0.6"/>
        <path d="M203.875 1000.33C206.015 1000.53 206.988 999.17 205.863 997.57C204.738 995.97 202.276 994.798 200.212 994.6C198.012 994.39 197.141 995.907 198.291 997.539C199.442 999.17 201.862 1000.14 203.875 1000.33Z" fill="white" fill-opacity="0.6"/>
        <path d="M474.402 974.967C476.374 975.114 476.737 972.876 475.147 969.738C473.556 966.6 470.68 963.922 468.81 963.734C466.941 963.546 466.712 966.087 468.201 969.11C469.69 972.133 472.482 974.821 474.402 974.967Z" fill="white" fill-opacity="0.6"/>
        <path d="M459.699 973.576C461.662 973.639 462.017 971.244 460.418 967.981C458.819 964.717 455.934 962.008 454.081 961.946C452.229 961.883 452.009 964.56 453.506 967.709C455.003 970.857 457.778 973.524 459.699 973.576Z" fill="white" fill-opacity="0.6"/>
        <path d="M444.741 972.625C446.763 972.719 447.279 970.303 445.748 966.966C444.217 963.63 441.391 960.826 439.445 960.69C437.5 960.555 437.17 963.305 438.599 966.527C440.029 969.748 442.77 972.53 444.741 972.625Z" fill="white" fill-opacity="0.6"/>
        <path d="M429.869 972.248C431.891 972.248 432.347 969.644 430.833 966.234C429.319 962.824 426.442 960.063 424.505 960.073C422.568 960.084 422.255 963.023 423.659 966.286C425.063 969.55 427.897 972.269 429.869 972.248Z" fill="white" fill-opacity="0.6"/>
        <path d="M414.784 972.53C416.84 972.447 417.322 969.811 415.876 966.37C414.429 962.929 411.536 960.178 409.548 960.241C407.559 960.304 407.196 963.379 408.6 966.631C410.004 969.884 412.779 972.614 414.784 972.53Z" fill="white" fill-opacity="0.6"/>
        <path d="M399.768 972.332C401.781 972.332 402.213 969.821 400.656 966.339C399.099 962.856 396.206 960.063 394.294 960.063C392.382 960.063 392.094 963.002 393.55 966.339C395.005 969.675 397.805 972.3 399.768 972.332Z" fill="white" fill-opacity="0.6"/>
        <path d="M384.666 971.976C386.68 971.892 387.145 969.298 385.639 965.868C384.133 962.437 381.265 959.77 379.345 959.896C377.425 960.021 377.103 963.033 378.499 966.171C379.895 969.309 382.704 972.06 384.666 971.976Z" fill="white" fill-opacity="0.6"/>
        <path d="M369.574 972.719C371.638 972.719 372.247 970.125 370.86 966.83C369.472 963.535 366.706 960.91 364.684 960.983C362.662 961.057 362.247 963.943 363.559 967.081C364.87 970.219 367.56 972.771 369.574 972.719Z" fill="white" fill-opacity="0.6"/>
        <path d="M354.929 974.277C356.926 974.005 357.467 971.411 355.995 968.221C354.523 965.031 351.765 962.751 349.845 963.086C347.924 963.42 347.578 966.328 348.931 969.299C350.285 972.269 352.983 974.549 354.929 974.277Z" fill="white" fill-opacity="0.6"/>
        <path d="M340.082 976.495C342.112 976.264 342.73 973.786 341.393 970.784C340.057 967.782 337.349 965.554 335.361 965.868C333.373 966.182 332.95 968.901 334.211 971.725C335.471 974.549 338.145 976.725 340.082 976.495Z" fill="white" fill-opacity="0.6"/>
        <path d="M325.7 979.047C327.671 978.67 328.196 976.243 326.817 973.409C325.438 970.575 322.722 968.702 320.835 969.152C318.949 969.602 318.56 972.29 319.862 974.884C321.165 977.478 323.762 979.413 325.7 979.047Z" fill="white" fill-opacity="0.6"/>
        <path d="M489.114 976.683C491.06 976.85 491.382 974.706 489.749 971.683C488.116 968.66 485.214 966.077 483.37 965.889C481.526 965.701 481.357 968.085 482.888 971.014C484.419 973.942 487.219 976.526 489.114 976.683Z" fill="white" fill-opacity="0.6"/>
        <path d="M503.767 978.618C505.696 978.796 505.975 976.756 504.309 973.869C502.642 970.982 499.715 968.504 497.896 968.305C496.077 968.106 495.942 970.397 497.507 973.158C499.072 975.919 501.881 978.461 503.767 978.618Z" fill="white" fill-opacity="0.6"/>
        <path d="M518.183 980.626C520.188 980.94 520.594 979.12 519.029 976.442C517.464 973.765 514.537 971.286 512.6 970.899C510.662 970.512 510.442 972.52 511.94 975.177C513.437 977.833 516.229 980.323 518.183 980.626Z" fill="white" fill-opacity="0.6"/>
        <path d="M532.971 982.645C534.892 982.833 535.137 981.013 533.42 978.409C531.702 975.804 528.741 973.566 526.922 973.346C525.104 973.127 525.019 975.124 526.626 977.656C528.234 980.187 531.051 982.488 532.971 982.645Z" fill="white" fill-opacity="0.6"/>
        <path d="M547.497 984.559C549.443 984.736 549.697 982.99 547.963 980.532C546.228 978.074 543.217 975.961 541.381 975.752C539.545 975.543 539.477 977.425 541.11 979.821C542.743 982.216 545.602 984.391 547.497 984.559Z" fill="white" fill-opacity="0.6"/>
        <path d="M562.133 986.117C564.24 986.379 564.722 984.778 563.131 982.488C561.364 980.09 559.027 978.446 556.456 977.791C554.451 977.467 554.13 979.162 555.653 981.431C557.176 983.701 560.044 985.856 562.133 986.117Z" fill="white" fill-opacity="0.6"/>
        <path d="M577.353 987.184C579.493 987.362 580.009 985.772 578.419 983.555C576.61 981.224 574.232 979.692 571.651 979.193C569.612 978.952 569.248 980.636 570.805 982.812C572.52 985.151 574.831 986.693 577.353 987.184Z" fill="white" fill-opacity="0.6"/>
        <path d="M593.038 987.655C595.085 987.592 595.398 985.866 593.664 983.68C591.93 981.494 588.833 979.831 586.896 979.883C584.958 979.936 584.789 981.735 586.439 983.837C588.089 985.939 591.016 987.707 593.038 987.655Z" fill="white" fill-opacity="0.6"/>
        <path d="M608.393 987.404C610.432 987.257 610.745 985.479 609.027 983.314C607.31 981.149 604.247 979.622 602.302 979.779C600.356 979.936 600.195 981.766 601.828 983.826C603.461 985.887 606.405 987.55 608.393 987.404Z" fill="white" fill-opacity="0.6"/>
        <path d="M622.944 987.038C624.882 987.038 625.22 985.437 623.587 983.293C621.857 981.132 619.584 979.764 617.141 979.413C615.296 979.413 615.169 981.097 616.735 983.147C618.3 985.197 621.066 987.006 622.944 987.038Z" fill="white" fill-opacity="0.6"/>
        <path d="M637.478 987.132C639.585 987.194 640.135 985.573 638.621 983.429C636.876 981.191 634.541 979.8 632.039 979.507C630.008 979.423 629.628 981.149 631.1 983.209C632.781 985.414 635.042 986.804 637.478 987.132Z" fill="white" fill-opacity="0.6"/>
        <path d="M10.0973 982.571C10.5717 982.555 11.0388 982.423 11.472 982.183C11.9052 981.944 12.2959 981.601 12.6219 981.174C12.948 980.748 13.2028 980.247 13.3721 979.698C13.5413 979.15 13.6215 978.566 13.6082 977.98C13.5949 977.393 13.4883 976.816 13.2944 976.28C13.1005 975.745 12.8232 975.261 12.4783 974.858C12.1335 974.455 11.7278 974.14 11.2844 973.931C10.841 973.722 10.3687 973.623 9.89426 973.639C8.91593 973.712 7.99579 974.239 7.31699 975.113C6.63819 975.987 6.25049 977.145 6.23103 978.356C6.25788 978.948 6.37958 979.528 6.58907 980.061C6.79856 980.594 7.09165 981.071 7.45134 981.463C7.81104 981.855 8.23015 982.155 8.68437 982.345C9.13858 982.536 9.61887 982.612 10.0973 982.571Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.1816 982.446C25.6434 982.468 26.1038 982.372 26.5337 982.162C26.9636 981.952 27.3534 981.634 27.6782 981.227C28.003 980.821 28.2556 980.335 28.4201 979.801C28.5845 979.267 28.6571 978.697 28.6333 978.126C28.6063 976.952 28.2203 975.834 27.5531 974.997C26.8859 974.161 25.9871 973.668 25.0377 973.618C24.1012 973.632 23.207 974.102 22.5495 974.927C21.892 975.751 21.5245 976.864 21.5268 978.022C21.5573 979.199 21.9544 980.317 22.6354 981.141C23.3163 981.966 24.2286 982.433 25.1816 982.446Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.6146 982.854C40.0547 982.926 40.5023 982.877 40.9251 982.709C41.3478 982.542 41.7352 982.26 42.059 981.885C42.3829 981.509 42.6352 981.05 42.7977 980.539C42.9603 980.028 43.0289 979.479 42.9987 978.931C42.9577 977.773 42.586 976.669 41.9494 975.817C41.3129 974.965 40.4527 974.419 39.5215 974.277C39.0832 974.241 38.6435 974.317 38.2303 974.502C37.8172 974.687 37.4396 974.976 37.1214 975.35C36.8033 975.725 36.5516 976.177 36.3822 976.679C36.2128 977.18 36.1295 977.719 36.1375 978.262C36.1643 979.42 36.5326 980.526 37.173 981.372C37.8134 982.217 38.6818 982.744 39.6146 982.854Z" fill="white" fill-opacity="0.6"/>
        <path d="M53.8865 983.69C54.3032 983.744 54.7242 983.675 55.1154 983.49C55.5065 983.304 55.8568 983.008 56.1379 982.623C56.4189 982.239 56.6227 981.779 56.7328 981.279C56.8428 980.779 56.8561 980.255 56.7714 979.747C56.6322 978.618 56.198 977.577 55.535 976.782C54.872 975.987 54.0167 975.482 53.0997 975.344C52.6853 975.322 52.2719 975.413 51.8892 975.611C51.5065 975.809 51.1638 976.109 50.8858 976.489C50.6078 976.87 50.4012 977.322 50.2808 977.813C50.1604 978.304 50.1293 978.821 50.1895 979.329C50.3054 980.471 50.7365 981.529 51.4101 982.323C52.0836 983.118 52.9584 983.601 53.8865 983.69Z" fill="white" fill-opacity="0.6"/>
        <path d="M67.6258 984.946C68.0162 985.004 68.4122 984.94 68.7777 984.761C69.1433 984.582 69.4668 984.293 69.7189 983.92C69.9711 983.547 70.1438 983.102 70.2215 982.625C70.2992 982.148 70.2794 981.655 70.1638 981.191C69.9508 980.088 69.4734 979.091 68.794 978.329C68.1147 977.568 67.2652 977.078 66.3568 976.923C65.9672 976.886 65.5759 976.964 65.2153 977.15C64.8546 977.336 64.5348 977.625 64.2821 977.994C64.0295 978.362 63.8511 978.8 63.7617 979.27C63.6723 979.74 63.6745 980.23 63.768 980.699C63.9582 981.825 64.4357 982.845 65.1303 983.609C65.8248 984.374 66.6996 984.842 67.6258 984.946Z" fill="white" fill-opacity="0.6"/>
        <path d="M80.9587 986.389C82.7776 986.588 83.8352 985.113 83.3868 982.99C83.1101 981.919 82.6009 980.962 81.9147 980.224C81.2284 979.487 80.3914 978.996 79.4951 978.806C79.1321 978.722 78.7578 978.757 78.4089 978.906C78.0599 979.055 77.7482 979.313 77.5043 979.656C77.2604 979.999 77.0927 980.414 77.0175 980.861C76.9424 981.307 76.9623 981.771 77.0755 982.205C77.3206 983.296 77.8192 984.274 78.5095 985.017C79.1997 985.761 80.0513 986.238 80.9587 986.389Z" fill="white" fill-opacity="0.6"/>
        <path d="M94.3341 988C96.1192 988.157 97.016 986.713 96.3899 984.684C96.013 983.647 95.4324 982.744 94.7011 982.055C93.9697 981.366 93.1107 980.915 92.2022 980.741C90.5609 980.563 89.6642 982.069 90.1718 984.046C90.5138 985.107 91.0809 986.034 91.8135 986.73C92.546 987.426 93.4169 987.865 94.3341 988Z" fill="white" fill-opacity="0.6"/>
        <path d="M107.574 989.631C109.351 989.788 110.155 988.418 109.393 986.494C108.932 985.504 108.298 984.655 107.537 984.009C106.775 983.363 105.906 982.936 104.994 982.76C103.361 982.582 102.557 983.994 103.217 985.897C103.64 986.914 104.259 987.786 105.018 988.437C105.778 989.089 106.656 989.499 107.574 989.631Z" fill="white" fill-opacity="0.6"/>
        <path d="M120.823 991.2C122.625 991.368 123.361 990.092 122.515 988.303C121.983 987.35 121.298 986.542 120.504 985.928C119.709 985.315 118.822 984.909 117.895 984.736C116.203 984.527 115.501 985.845 116.254 987.581C116.748 988.569 117.415 989.406 118.206 990.033C118.996 990.659 119.891 991.058 120.823 991.2Z" fill="white" fill-opacity="0.6"/>
        <path d="M134.283 992.602C136.118 992.748 136.821 991.556 135.873 989.851C134.64 988.025 132.892 986.844 130.983 986.546C129.291 986.368 128.614 987.592 129.469 989.244C130.043 990.182 130.766 990.963 131.594 991.541C132.422 992.118 133.337 992.479 134.283 992.602Z" fill="white" fill-opacity="0.6"/>
        <path d="M147.979 993.742C149.883 993.888 150.619 992.696 149.595 991.138C148.238 989.368 146.414 988.255 144.451 988C142.692 987.822 142.023 988.972 142.946 990.531C144.224 992.367 146.028 993.518 147.979 993.742Z" fill="white" fill-opacity="0.6"/>
        <path d="M162.218 994.537C164.163 994.537 164.84 993.407 163.707 991.88C162.2 990.207 160.279 989.216 158.258 989.067C156.456 988.993 155.847 990.112 156.888 991.64C158.323 993.37 160.216 994.399 162.218 994.537Z" fill="white" fill-opacity="0.6"/>
        <path d="M176.947 994.851C178.952 994.851 179.637 993.606 178.427 992.121C176.805 990.506 174.791 989.611 172.708 989.579C170.822 989.579 170.229 990.771 171.355 992.194C172.906 993.869 174.887 994.81 176.947 994.851Z" fill="white" fill-opacity="0.6"/>
        <path d="M192.276 994.589C194.374 994.474 195.102 993.198 193.858 991.734C192.615 990.269 189.882 989.297 187.936 989.422C185.99 989.548 185.33 990.761 186.498 992.163C188.133 993.788 190.179 994.647 192.276 994.589Z" fill="white" fill-opacity="0.6"/>
        <path d="M207.902 994.84C209.966 994.84 210.66 993.606 209.425 992.11C208.19 990.615 205.499 989.59 203.503 989.61C201.506 989.631 200.914 990.74 202.082 992.142C203.728 993.81 205.774 994.759 207.902 994.84Z" fill="white" fill-opacity="0.6"/>
        <path d="M223.401 994.296C225.406 994.035 226.032 992.539 224.754 990.949C223.939 990.125 222.999 989.509 221.99 989.135C220.981 988.762 219.922 988.639 218.875 988.774C216.946 989.098 216.396 990.51 217.614 991.974C219.23 993.638 221.302 994.47 223.401 994.296Z" fill="white" fill-opacity="0.6"/>
        <path d="M238.341 992.55C240.423 992.309 241.226 990.719 240.118 988.962C239.01 987.205 236.438 986.107 234.407 986.41C232.377 986.713 231.683 988.345 232.715 989.956C233.443 990.869 234.324 991.575 235.296 992.023C236.268 992.471 237.307 992.651 238.341 992.55Z" fill="white" fill-opacity="0.6"/>
        <path d="M253.553 990.322C255.524 989.966 256.091 988.23 254.864 986.274C254.148 985.3 253.253 984.552 252.255 984.093C251.257 983.634 250.185 983.478 249.128 983.638C247.258 984.077 246.725 985.929 247.901 987.686C248.617 988.63 249.499 989.359 250.479 989.816C251.459 990.273 252.51 990.446 253.553 990.322Z" fill="white" fill-opacity="0.6"/>
        <path d="M267.926 988.533C269.906 988.397 270.608 986.65 269.457 984.58C268.8 983.519 267.952 982.663 266.979 982.08C266.007 981.497 264.937 981.203 263.857 981.222C261.996 981.389 261.37 983.22 262.452 985.165C263.095 986.207 263.923 987.053 264.872 987.637C265.821 988.221 266.866 988.528 267.926 988.533Z" fill="white" fill-opacity="0.6"/>
        <path d="M282.351 986.745C284.313 986.462 284.889 984.517 283.679 982.268C283.027 981.163 282.163 980.276 281.164 979.686C280.166 979.096 279.065 978.823 277.96 978.89C276.124 979.214 275.599 981.285 276.767 983.356C277.407 984.436 278.25 985.308 279.222 985.899C280.194 986.489 281.268 986.779 282.351 986.745Z" fill="white" fill-opacity="0.6"/>
        <path d="M296.826 984.391C298.755 984.004 299.271 981.839 297.926 979.402C296.58 976.965 293.924 975.459 292.097 975.94C290.269 976.421 289.872 978.733 291.124 980.95C292.376 983.168 294.939 984.736 296.826 984.391Z" fill="white" fill-opacity="0.6"/>
        <path d="M311.174 981.766C313.145 981.4 313.712 979.12 312.358 976.536C311.005 973.953 308.357 972.196 306.436 972.635C304.516 973.074 304.127 975.553 305.379 977.98C306.631 980.406 309.254 982.122 311.174 981.766Z" fill="white" fill-opacity="0.6"/>
        <path d="M720.725 985.301C723.145 985.301 724.439 983.534 723.644 981.233C722.849 978.932 720.26 977.112 717.773 977.049C715.286 976.986 714.101 978.952 714.922 981.149C715.742 983.345 718.365 985.291 720.725 985.301Z" fill="white" fill-opacity="0.6"/>
        <path d="M703.408 985.856C705.777 985.782 706.792 983.983 705.768 981.672C704.744 979.36 701.876 977.676 699.516 977.75C697.156 977.823 696.132 979.768 697.257 981.933C697.965 983.134 698.889 984.114 699.957 984.795C701.025 985.476 702.206 985.839 703.408 985.856Z" fill="white" fill-opacity="0.6"/>
        <path d="M686.31 986.347C688.594 986.222 689.415 984.412 688.12 982.163C687.319 980.993 686.32 980.054 685.192 979.415C684.065 978.775 682.838 978.449 681.598 978.461C679.356 978.597 678.611 980.553 679.906 982.645C680.698 983.79 681.679 984.714 682.784 985.352C683.889 985.991 685.091 986.33 686.31 986.347Z" fill="white" fill-opacity="0.6"/>
        <path d="M669.365 986.745C671.623 986.745 672.385 984.977 671.014 982.77C670.18 981.611 669.164 980.672 668.029 980.011C666.893 979.349 665.661 978.979 664.407 978.921C662.199 978.921 661.547 980.814 662.909 982.906C663.722 984.049 664.712 984.977 665.821 985.637C666.93 986.296 668.135 986.673 669.365 986.745Z" fill="white" fill-opacity="0.6"/>
        <path d="M653.028 987.017C655.228 987.017 655.905 985.374 654.466 983.209C652.739 980.902 650.363 979.499 647.825 979.287C645.693 979.287 645.16 981.023 646.582 983.105C648.253 985.37 650.557 986.767 653.028 987.017Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.156702 1021.09L0.156702 1028.96C0.557201 1029.37 1.03794 1029.66 1.55228 1029.78C2.06662 1029.89 2.59709 1029.85 3.09234 1029.64C3.98533 1029.38 4.7689 1028.72 5.292 1027.79C5.85288 1026.91 6.13849 1025.81 6.09654 1024.69C6.0546 1023.57 5.68789 1022.5 5.06356 1021.69C4.38297 1020.98 3.58656 1020.46 2.72856 1020.16C2.27231 1020.01 1.78965 1020.01 1.33515 1020.17C0.880648 1020.34 0.472456 1020.66 0.156702 1021.09Z" fill="white" fill-opacity="0.6"/>
        <path d="M513.133 962.27C511.263 961.925 511.331 963.828 513.209 966.59C514.901 969.121 517.667 971.411 519.486 971.767C521.305 972.123 521.584 970.198 519.858 967.677C518.133 965.157 514.985 962.625 513.133 962.27Z" fill="white" fill-opacity="0.6"/>
        <path d="M498.776 959.174C496.881 958.808 496.906 960.816 498.776 963.724C500.468 966.401 503.184 968.817 505.011 969.204C506.838 969.591 507.168 967.552 505.485 964.885C503.801 962.218 500.637 959.54 498.776 959.174Z" fill="white" fill-opacity="0.6"/>
        <path d="M484.132 960.774C485.824 963.776 488.742 966.443 490.603 966.705C492.465 966.966 492.608 964.947 491.12 962.291C489.537 959.467 486.433 956.622 484.495 956.162C482.558 955.701 482.397 957.741 484.132 960.774Z" fill="white" fill-opacity="0.6"/>
        <path d="M469.809 953.275C467.939 953.024 467.964 955.367 469.809 958.599C471.501 961.527 474.182 964.079 475.993 964.362C477.803 964.644 478.133 962.27 476.458 959.341C474.783 956.413 471.661 953.536 469.809 953.275Z" fill="white" fill-opacity="0.6"/>
        <path d="M455.249 950.817C453.371 950.618 453.371 953.149 455.156 956.475C456.797 959.519 459.504 962.113 461.323 962.354C463.142 962.594 463.506 960.084 461.856 956.998C460.206 953.913 457.119 951.005 455.249 950.817Z" fill="white" fill-opacity="0.6"/>
        <path d="M440.241 954.404C441.873 957.772 444.733 960.617 446.645 960.753C448.557 960.889 448.811 958.191 447.153 955.001C445.494 951.811 442.719 949.164 440.85 948.861C438.828 948.568 438.591 951.047 440.241 954.404Z" fill="white" fill-opacity="0.6"/>
        <path d="M425.529 953.725C427.221 957.176 430.106 959.927 431.958 959.822C433.811 959.718 433.98 957.124 432.508 954.028C430.935 950.691 427.881 947.752 425.935 947.595C423.989 947.439 423.837 950.221 425.529 953.725Z" fill="white" fill-opacity="0.6"/>
        <path d="M410.91 947.146C408.989 947.261 408.913 950.283 410.656 953.787C412.263 957.051 414.979 959.592 416.84 959.582C418.701 959.571 419.133 956.622 417.525 953.306C415.918 949.991 412.838 947.031 410.91 947.146Z" fill="white" fill-opacity="0.6"/>
        <path d="M395.318 953.484C396.917 957.009 399.785 960.345 401.773 960.199C403.761 960.053 404.091 956.967 402.475 953.599C400.859 950.231 398.076 947.679 396.13 947.669C394.032 947.658 393.719 949.938 395.318 953.484Z" fill="white" fill-opacity="0.6"/>
        <path d="M380.995 946.947C378.956 946.884 378.711 949.677 380.352 953.223C381.993 956.768 384.827 959.425 386.748 959.352C388.668 959.278 388.939 956.674 387.543 953.484C386.062 950.064 383 947.01 380.995 946.947Z" fill="white" fill-opacity="0.6"/>
        <path d="M365.411 954.007C366.977 957.396 369.768 959.979 371.731 959.854C373.694 959.728 374.024 956.716 372.45 953.474C370.877 950.231 368.136 947.773 366.232 947.805C364.176 947.836 363.846 950.629 365.411 954.007Z" fill="white" fill-opacity="0.6"/>
        <path d="M527.481 965.282C525.628 964.968 525.747 966.778 527.65 969.382C529.41 971.777 532.176 973.932 533.987 974.256C535.797 974.58 536.025 972.74 534.257 970.355C532.489 967.97 529.317 965.596 527.481 965.282Z" fill="white" fill-opacity="0.6"/>
        <path d="M542.176 968.075C540.154 967.688 540.103 969.288 541.956 971.725C543.809 974.162 546.778 976.317 548.673 976.526C550.441 976.725 550.678 975.103 549.096 972.98C547.2 970.587 544.805 968.889 542.176 968.075Z" fill="white" fill-opacity="0.6"/>
        <path d="M557.167 970.229C555.103 969.957 555.052 971.547 556.939 973.869C558.73 976.077 561.002 977.592 563.478 978.231C565.526 978.545 565.915 976.861 564.198 974.706C562.254 972.405 559.811 970.85 557.167 970.229Z" fill="white" fill-opacity="0.6"/>
        <path d="M572.429 971.61C570.407 971.526 570.424 973.179 572.37 975.407C574.147 977.499 577.023 979.203 578.969 979.329C580.915 979.455 581.27 977.676 579.51 975.616C577.527 973.435 575.061 972.039 572.429 971.61Z" fill="white" fill-opacity="0.6"/>
        <path d="M587.809 975.992C589.679 978.084 592.792 979.789 594.688 979.632C596.583 979.475 596.642 977.76 595.009 975.867C593.038 973.742 590.568 972.452 587.962 972.185C585.923 972.185 585.898 973.838 587.809 975.992Z" fill="white" fill-opacity="0.6"/>
        <path d="M603.148 975.626C604.975 977.718 608.038 979.298 609.958 979.078C611.878 978.858 611.743 977.342 610.136 975.438C608.528 973.535 605.432 971.579 603.41 971.694C601.388 971.809 601.278 973.503 603.148 975.626Z" fill="white" fill-opacity="0.6"/>
        <path d="M617.758 971.767C615.778 971.767 615.804 973.44 617.682 975.553C619.449 977.545 621.671 978.813 624.044 979.183C625.981 979.256 626.32 977.488 624.645 975.49C622.733 973.366 620.318 972.06 617.758 971.767Z" fill="white" fill-opacity="0.6"/>
        <path d="M632.758 975.323C634.45 977.415 637.495 979.13 639.526 979.057C641.429 979.057 641.895 977.342 640.456 975.417C638.562 973.187 636.102 971.835 633.494 971.589C631.294 971.62 631.015 973.231 632.758 975.323Z" fill="white" fill-opacity="0.6"/>
        <path d="M650.025 971.275C647.758 971.359 647.402 973.095 649.111 975.239C650.879 977.349 653.191 978.622 655.642 978.837C657.817 978.837 658.527 977.007 657.047 974.967C655.161 972.697 652.656 971.38 650.025 971.275Z" fill="white" fill-opacity="0.6"/>
        <path d="M667.486 970.711C665.126 970.836 664.602 972.614 666.192 974.8C667.901 976.96 670.206 978.245 672.647 978.398C674.897 978.398 675.769 976.474 674.415 974.392C673.517 973.238 672.442 972.317 671.252 971.685C670.062 971.053 668.782 970.721 667.486 970.711Z" fill="white" fill-opacity="0.6"/>
        <path d="M683.84 973.942C684.64 975.132 685.639 976.093 686.767 976.761C687.896 977.429 689.128 977.788 690.379 977.812C692.672 977.739 693.679 975.721 692.418 973.629C691.158 971.537 688.332 969.989 685.997 969.989C683.467 969.999 682.545 971.746 683.84 973.942Z" fill="white" fill-opacity="0.6"/>
        <path d="M702.046 973.168C702.763 974.395 703.705 975.394 704.795 976.085C705.884 976.776 707.09 977.138 708.315 977.143C710.641 977.143 711.868 975.051 710.853 972.959C709.837 970.867 707.096 969.215 704.719 969.194C702.105 969.173 700.963 970.93 702.046 973.168Z" fill="white" fill-opacity="0.6"/>
        <path d="M14.0482 968.891C14.0856 970.013 14.4707 971.074 15.1238 971.854C15.7769 972.634 16.6478 973.074 17.5561 973.082C18.4643 973.09 19.3403 972.666 20.0023 971.897C20.6644 971.129 21.0619 970.075 21.1123 968.953C21.0996 967.778 20.7204 966.654 20.0536 965.816C19.3868 964.978 18.4841 964.491 17.5337 964.456C17.0632 964.437 16.5948 964.54 16.1584 964.758C15.7219 964.976 15.3271 965.304 14.9991 965.722C14.671 966.139 14.417 966.637 14.2533 967.182C14.0896 967.728 14.0197 968.31 14.0482 968.891Z" fill="white" fill-opacity="0.6"/>
        <path d="M31.9582 964.968C31.519 964.945 31.0809 965.04 30.6741 965.246C30.2673 965.452 29.9015 965.765 29.6016 966.162C29.3018 966.56 29.0751 967.033 28.9372 967.549C28.7993 968.065 28.7534 968.612 28.8026 969.152C28.857 970.254 29.2278 971.295 29.8482 972.089C30.4686 972.883 31.2978 973.377 32.1866 973.482C32.6233 973.514 33.0606 973.434 33.4706 973.245C33.8806 973.057 34.2543 972.765 34.568 972.388C34.8816 972.011 35.1283 971.558 35.2922 971.056C35.4561 970.555 35.5337 970.017 35.5199 969.476C35.4641 968.321 35.0733 967.229 34.4191 966.401C33.7649 965.573 32.8912 965.064 31.9582 964.968Z" fill="white" fill-opacity="0.6"/>
        <path d="M45.7397 966.15C43.9377 965.941 42.6602 967.646 42.8379 970.041C42.9235 971.123 43.2959 972.139 43.8973 972.932C44.4986 973.724 45.2951 974.249 46.1627 974.424C46.5746 974.489 46.9931 974.439 47.3871 974.276C47.7811 974.114 48.1403 973.844 48.4379 973.486C48.7356 973.128 48.9641 972.691 49.1063 972.209C49.2485 971.727 49.3007 971.211 49.2591 970.7C49.1598 969.568 48.761 968.509 48.1246 967.686C47.4881 966.863 46.6498 966.323 45.7397 966.15Z" fill="white" fill-opacity="0.6"/>
        <path d="M56.2808 971.359C56.4072 972.482 56.8254 973.524 57.4724 974.328C58.1194 975.131 58.9601 975.653 59.8679 975.815C60.2547 975.87 60.6465 975.807 61.0093 975.632C61.3721 975.458 61.695 975.176 61.95 974.813C62.205 974.449 62.3845 974.014 62.4728 973.545C62.5611 973.076 62.5556 972.588 62.4567 972.122C62.2622 971.078 61.831 970.124 61.2137 969.372C60.5964 968.62 59.8184 968.101 58.9711 967.876C57.2368 967.541 56.0439 969.047 56.2808 971.359Z" fill="white" fill-opacity="0.6"/>
        <path d="M69.2755 973.147C69.4737 974.245 69.9293 975.247 70.5854 976.026C71.2414 976.806 72.0688 977.329 72.964 977.53C73.3221 977.61 73.6908 977.572 74.0331 977.419C74.3755 977.267 74.6793 977.006 74.9142 976.663C75.1492 976.319 75.3068 975.905 75.3714 975.463C75.436 975.02 75.4052 974.564 75.2821 974.141C75.0203 973.123 74.5527 972.204 73.9235 971.472C73.2943 970.74 72.5244 970.219 71.6866 969.957C70.0115 969.591 68.9455 970.982 69.2755 973.147Z" fill="white" fill-opacity="0.6"/>
        <path d="M82.0501 975.469C82.3624 976.521 82.8967 977.45 83.5974 978.16C84.298 978.869 85.1396 979.335 86.0348 979.507C86.3625 979.596 86.7041 979.568 87.0193 979.426C87.3345 979.283 87.6102 979.033 87.8139 978.703C88.0175 978.373 88.1406 977.978 88.1687 977.564C88.1967 977.151 88.1285 976.736 87.9721 976.369C87.6285 975.335 87.086 974.42 86.3916 973.703C85.6973 972.987 84.872 972.491 83.9874 972.258C82.3208 972.007 81.4833 973.388 82.0501 975.469Z" fill="white" fill-opacity="0.6"/>
        <path d="M94.5964 977.708C94.9956 978.725 95.5895 979.606 96.3267 980.275C97.0639 980.944 97.922 981.38 98.8265 981.546C100.341 981.672 101.111 980.364 100.519 978.691C100.083 977.701 99.4799 976.84 98.7497 976.167C98.0194 975.493 97.1797 975.023 96.2885 974.79C94.6218 974.486 93.8943 975.752 94.5964 977.708Z" fill="white" fill-opacity="0.6"/>
        <path d="M108.615 977.279C106.923 976.934 106.314 978.074 107.109 979.904C107.565 980.829 108.169 981.629 108.885 982.253C109.601 982.878 110.413 983.313 111.271 983.534C112.963 983.879 113.809 982.655 113.082 980.982C112.043 979.071 110.439 977.741 108.615 977.279Z" fill="white" fill-opacity="0.6"/>
        <path d="M119.706 981.944C120.817 983.83 122.487 985.097 124.35 985.469C126.042 985.699 126.719 984.423 125.763 982.896C124.577 981.21 122.974 980.056 121.203 979.611C119.477 979.256 118.843 980.26 119.706 981.944Z" fill="white" fill-opacity="0.6"/>
        <path d="M133.733 981.599C132.041 981.41 131.66 982.498 132.777 984.098C134.045 985.749 135.731 986.816 137.557 987.121C139.249 987.309 139.917 986.075 138.851 984.653C137.452 982.987 135.658 981.917 133.733 981.599Z" fill="white" fill-opacity="0.6"/>
        <path d="M147.04 983.22C145.264 983.073 144.866 984.077 146.067 985.594C147.477 987.17 149.254 988.154 151.143 988.408C152.937 988.575 153.614 987.414 152.489 986.044C150.945 984.444 149.045 983.46 147.04 983.22Z" fill="white" fill-opacity="0.6"/>
        <path d="M161.059 984.287C159.13 984.161 158.631 985.113 159.849 986.535C161.419 988.132 163.36 989.067 165.39 989.203C167.082 989.203 167.759 988.157 166.718 986.922C165.083 985.374 163.114 984.457 161.059 984.287Z" fill="white" fill-opacity="0.6"/>
        <path d="M175.5 984.642C173.546 984.642 173.156 985.741 174.527 987.1C176.166 988.534 178.112 989.335 180.119 989.401C182.073 989.401 182.784 988.157 181.515 986.891C179.732 985.429 177.64 984.647 175.5 984.642Z" fill="white" fill-opacity="0.6"/>
        <path d="M189.865 986.745C191.202 988.052 193.994 988.952 195.931 989.046C197.868 989.14 198.342 988.094 197.191 986.954C196.041 985.814 193.114 984.559 191.083 984.391C189.053 984.224 188.495 985.406 189.865 986.745Z" fill="white" fill-opacity="0.6"/>
        <path d="M205.389 986.943C207.106 988.489 209.189 989.288 211.311 989.213C213.147 989.035 213.79 987.749 212.682 986.452C211.574 985.155 208.68 984.13 206.641 984.287C204.602 984.444 204.061 985.563 205.389 986.943Z" fill="white" fill-opacity="0.6"/>
        <path d="M221.912 982.069C219.915 982.488 219.484 983.931 220.863 985.448C222.469 986.986 224.48 987.732 226.506 987.54C228.477 987.268 229.188 985.584 227.944 984.088C227.103 983.26 226.134 982.655 225.097 982.307C224.059 981.96 222.976 981.879 221.912 982.069Z" fill="white" fill-opacity="0.6"/>
        <path d="M236.988 978.712C235.033 979.214 234.636 980.898 236.023 982.582C236.781 983.405 237.668 984.026 238.629 984.408C239.589 984.789 240.603 984.922 241.607 984.799C243.536 984.464 244.204 982.603 242.952 980.919C242.158 980.017 241.208 979.349 240.175 978.967C239.142 978.585 238.052 978.498 236.988 978.712Z" fill="white" fill-opacity="0.6"/>
        <path d="M251.615 975.323C249.745 975.794 249.441 977.635 250.854 979.507C251.581 980.392 252.451 981.077 253.408 981.517C254.364 981.957 255.385 982.142 256.404 982.059C258.273 981.745 258.823 979.8 257.52 977.959C256.769 976.984 255.844 976.238 254.819 975.781C253.794 975.323 252.697 975.167 251.615 975.323Z" fill="white" fill-opacity="0.6"/>
        <path d="M265.879 972.541C263.984 972.917 263.654 974.779 265.033 976.829C265.711 977.798 266.551 978.574 267.494 979.105C268.437 979.635 269.462 979.908 270.498 979.904C272.385 979.706 273.036 977.687 271.733 975.637C271.024 974.587 270.124 973.759 269.106 973.221C268.089 972.683 266.983 972.45 265.879 972.541Z" fill="white" fill-opacity="0.6"/>
        <path d="M279.102 973.89C280.481 976.097 283.087 977.582 284.922 977.143C286.758 976.704 287.113 974.643 285.912 972.53C284.711 970.418 281.86 968.734 279.99 969.163C278.12 969.591 277.706 971.673 279.102 973.89Z" fill="white" fill-opacity="0.6"/>
        <path d="M294 965.24C292.097 965.774 291.834 968.085 293.323 970.47C294.694 972.719 297.139 974.246 298.966 973.932C300.794 973.618 301.411 971.181 300.049 968.702C298.687 966.223 295.861 964.707 294 965.24Z" fill="white" fill-opacity="0.6"/>
        <path d="M307.452 966.558C308.898 969.183 311.563 970.962 313.441 970.501C315.319 970.041 315.683 967.364 314.203 964.749C312.722 962.134 310.125 960.565 308.281 961.004C306.343 961.454 305.996 963.922 307.452 966.558Z" fill="white" fill-opacity="0.6"/>
        <path d="M322.316 956.925C320.472 957.542 320.362 960.293 321.986 963.138C323.475 965.742 326.038 967.5 327.832 967.102C329.625 966.705 330.091 963.828 328.585 961.046C327.079 958.264 324.152 956.298 322.316 956.925Z" fill="white" fill-opacity="0.6"/>
        <path d="M336.698 953.128C334.845 953.704 334.769 956.601 336.436 959.645C337.958 962.427 340.581 964.362 342.358 964.006C344.134 963.65 344.591 960.659 343.035 957.73C341.478 954.802 338.534 952.553 336.698 953.128Z" fill="white" fill-opacity="0.6"/>
        <path d="M351.283 949.97C349.371 950.399 349.253 953.327 350.928 956.57C352.459 959.55 355.082 961.715 356.926 961.517C358.77 961.318 359.244 958.316 357.713 955.178C356.182 952.041 353.17 949.551 351.283 949.97Z" fill="white" fill-opacity="0.6"/>
        <path d="M720.573 972.426C721.487 974.706 724.152 976.515 726.554 976.432C728.957 976.348 730.252 974.34 729.363 972.112C728.475 969.884 725.895 968.315 723.517 968.326C720.937 968.326 719.651 970.093 720.573 972.426Z" fill="white" fill-opacity="0.6"/>
        <path d="M549.046 968.64C551.093 968.953 551.169 967.353 549.13 965.094C547.159 962.941 544.787 961.424 542.227 960.68C540.222 960.304 540.298 961.925 542.286 964.184C544.274 966.443 547.117 968.336 549.046 968.64Z" fill="white" fill-opacity="0.6"/>
        <path d="M534.41 966.14C536.169 966.433 536.186 964.885 534.35 962.646C532.515 960.408 529.207 958.044 527.354 957.668C525.662 957.323 525.772 958.902 527.557 961.161C529.342 963.42 532.532 965.826 534.41 966.14Z" fill="white" fill-opacity="0.6"/>
        <path d="M519.85 963.148C521.821 963.609 521.88 961.925 519.901 959.383C517.921 956.841 515.104 954.729 513.276 954.227C511.449 953.725 511.415 955.461 513.336 958.013C515.256 960.565 518.022 962.772 519.85 963.148Z" fill="white" fill-opacity="0.6"/>
        <path d="M505.637 960.063C507.414 960.419 507.481 958.724 505.722 956.203C503.852 953.526 500.73 950.974 498.86 950.524C497.168 950.116 497.168 951.873 498.86 954.425C500.552 956.977 503.742 959.697 505.637 960.063Z" fill="white" fill-opacity="0.6"/>
        <path d="M491.204 956.925C493.006 957.291 493.124 955.513 491.416 952.846C489.597 950.012 486.5 947.303 484.605 946.853C482.845 946.424 482.829 948.265 484.495 950.974C486.162 953.683 489.292 956.496 491.204 956.925Z" fill="white" fill-opacity="0.6"/>
        <path d="M476.695 953.902C478.539 954.289 478.742 952.449 477.101 949.645C475.35 946.665 472.262 943.809 470.333 943.37C468.404 942.931 468.438 945.148 470.333 948.15C472.228 951.152 474.859 953.526 476.695 953.902Z" fill="white" fill-opacity="0.6"/>
        <path d="M462.271 951.214C464.081 951.497 464.242 949.436 462.575 946.487C460.807 943.349 457.736 940.452 455.807 940.096C454.031 939.772 453.971 941.926 455.596 944.907C457.22 947.888 460.35 950.911 462.271 951.214Z" fill="white" fill-opacity="0.6"/>
        <path d="M447.685 948.955C449.504 949.154 449.682 946.937 448.024 943.861C446.273 940.588 443.21 937.649 441.256 937.387C439.471 937.147 439.395 939.479 441.011 942.617C442.626 945.755 445.757 948.736 447.685 948.955Z" fill="white" fill-opacity="0.6"/>
        <path d="M426.789 935.369C424.81 935.076 424.691 937.795 426.468 941.153C428.244 944.51 430.952 947.052 432.83 947.271C434.852 947.512 435.072 945.012 433.253 941.602C431.535 938.412 428.676 935.64 426.789 935.369Z" fill="white" fill-opacity="0.6"/>
        <path d="M417.915 946.361C419.97 946.529 420.25 943.903 418.465 940.42C416.772 937.147 413.913 934.364 412.001 934.145C410.089 933.925 409.801 936.791 411.536 940.222C413.236 943.516 416.062 946.215 417.915 946.361Z" fill="white" fill-opacity="0.6"/>
        <path d="M403.219 946.372C405.064 946.225 405.267 943.537 403.634 940.201C401.891 936.665 398.812 933.831 396.866 933.998C395.055 934.155 394.937 937.042 396.544 940.326C398.236 943.83 401.248 946.539 403.219 946.372Z" fill="white" fill-opacity="0.6"/>
        <path d="M388.025 946.194C389.912 946.257 390.191 943.778 388.6 940.441C386.908 936.896 383.837 933.862 381.832 933.831C379.988 933.831 379.81 936.488 381.367 939.782C382.924 943.077 386.028 946.131 388.025 946.194Z" fill="white" fill-opacity="0.6"/>
        <path d="M564.417 970.48C566.304 970.606 566.414 969.1 564.587 967.092C562.52 964.907 560.01 963.461 557.328 962.908C555.475 962.741 555.484 964.236 557.286 966.255C559.088 968.274 562.395 970.334 564.417 970.48Z" fill="white" fill-opacity="0.6"/>
        <path d="M579.662 971.464C581.803 971.6 581.997 969.999 580.009 967.939C578.005 965.941 575.598 964.657 573.047 964.226C570.94 964.069 570.864 965.659 572.81 967.698C574.762 969.726 577.137 971.031 579.662 971.464Z" fill="white" fill-opacity="0.6"/>
        <path d="M595.187 971.537C597.285 971.537 597.412 969.801 595.398 967.782C593.41 965.859 591.004 964.709 588.486 964.477C586.422 964.477 586.397 966.171 588.368 968.158C590.339 970.146 593.232 971.568 595.187 971.537Z" fill="white" fill-opacity="0.6"/>
        <path d="M609.873 971.202C611.853 971.286 611.988 969.696 610.043 967.688C608.121 965.758 605.793 964.546 603.334 964.194C601.363 964.111 601.439 965.721 603.334 967.688C605.229 969.654 608.046 971.139 609.873 971.202Z" fill="white" fill-opacity="0.6"/>
        <path d="M618.418 964.153C616.438 964.153 616.269 965.659 617.936 967.552C619.897 969.663 622.35 970.949 624.941 971.223C626.929 971.223 627.259 969.706 625.601 967.792C623.589 965.649 621.068 964.371 618.418 964.153Z" fill="white" fill-opacity="0.6"/>
        <path d="M634.991 963.818C632.859 963.87 632.453 965.418 634.052 967.322C636.014 969.501 638.517 970.788 641.15 970.972C643.274 970.972 643.781 969.393 642.241 967.447C640.244 965.224 637.677 963.939 634.991 963.818Z" fill="white" fill-opacity="0.6"/>
        <path d="M658.333 970.48C660.549 970.407 661.234 968.817 659.83 966.851C657.891 964.547 655.312 963.248 652.622 963.222C650.372 963.305 649.796 964.906 651.243 966.841C653.164 969.091 655.688 970.387 658.333 970.48Z" fill="white" fill-opacity="0.6"/>
        <path d="M670.989 962.448C668.705 962.605 668.002 964.32 669.365 966.276C670.288 967.41 671.384 968.308 672.589 968.916C673.794 969.525 675.085 969.833 676.386 969.821C678.645 969.685 679.432 967.991 678.078 965.993C676.725 963.996 673.476 962.28 670.989 962.448Z" fill="white" fill-opacity="0.6"/>
        <path d="M690.109 961.527C687.748 961.705 686.843 963.462 688.044 965.45C689.246 967.437 692.401 969.204 694.872 969.048C697.19 968.891 698.154 967.165 697.012 965.115C695.87 963.065 692.663 961.339 690.109 961.527Z" fill="white" fill-opacity="0.6"/>
        <path d="M713.382 968.2C715.971 968.2 717.24 966.339 716.191 964.121C715.142 961.904 712.409 960.45 709.922 960.502C707.291 960.565 706.039 962.448 707.164 964.592C708.289 966.736 710.996 968.242 713.382 968.2Z" fill="white" fill-opacity="0.6"/>
        <path d="M9.88587 963.818C10.3569 963.839 10.8266 963.741 11.2659 963.53C11.7051 963.319 12.1048 962.998 12.4401 962.589C12.7754 962.179 13.0392 961.689 13.2154 961.148C13.3916 960.608 13.4764 960.028 13.4645 959.446C13.423 958.333 13.0355 957.283 12.3829 956.515C11.7303 955.747 10.8629 955.321 9.96198 955.325C9.48546 955.317 9.01237 955.425 8.56987 955.644C8.12737 955.862 7.72423 956.187 7.38373 956.599C7.04322 957.012 6.77205 957.503 6.58584 958.045C6.39964 958.588 6.3021 959.17 6.29879 959.76C6.35954 960.877 6.76624 961.922 7.43433 962.678C8.10242 963.433 8.98048 963.842 9.88587 963.818Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.0038 964.017C25.4231 964.017 25.8378 963.91 26.222 963.702C26.6062 963.495 26.9517 963.192 27.2368 962.812C27.5219 962.432 27.7405 961.983 27.8788 961.494C28.0172 961.005 28.0724 960.486 28.041 959.969C27.9897 958.819 27.5875 957.735 26.9155 956.938C26.2435 956.14 25.3517 955.687 24.4201 955.67C23.9979 955.682 23.5819 955.798 23.1963 956.011C22.8107 956.224 22.4633 956.53 22.1743 956.91C21.8853 957.291 21.6605 957.739 21.513 958.228C21.3655 958.717 21.2983 959.238 21.3153 959.76C21.3716 960.918 21.7858 962.006 22.4722 962.798C23.1586 963.591 24.0649 964.027 25.0038 964.017Z" fill="white" fill-opacity="0.6"/>
        <path d="M38.8531 964.864C39.2655 964.929 39.6846 964.873 40.076 964.699C40.4674 964.526 40.82 964.24 41.1048 963.865C41.3896 963.491 41.5985 963.038 41.7145 962.544C41.8304 962.051 41.8501 961.53 41.7718 961.025C41.6543 959.967 41.26 958.985 40.649 958.23C40.038 957.474 39.2439 956.987 38.3878 956.841C37.9736 956.791 37.5555 956.858 37.1655 957.038C36.7755 957.218 36.4239 957.505 36.1374 957.878C35.8509 958.252 35.6372 958.701 35.5126 959.192C35.388 959.683 35.3557 960.203 35.4183 960.711C35.531 961.78 35.931 962.77 36.554 963.523C37.177 964.276 37.9868 964.748 38.8531 964.864Z" fill="white" fill-opacity="0.6"/>
        <path d="M52.1777 966.359C52.5564 966.462 52.9501 966.444 53.3213 966.305C53.6924 966.166 54.0285 965.912 54.2976 965.567C54.5666 965.221 54.7595 964.797 54.8579 964.333C54.9563 963.869 54.9569 963.382 54.8596 962.918C54.7023 961.87 54.2967 960.902 53.6943 960.139C53.0918 959.375 52.3196 958.85 51.4755 958.63C51.0959 958.54 50.704 958.568 50.3354 958.712C49.9668 958.856 49.6331 959.112 49.3644 959.455C49.0957 959.799 48.9005 960.22 48.7964 960.68C48.6923 961.141 48.6826 961.626 48.7683 962.092C48.9138 963.15 49.3191 964.127 49.929 964.89C50.5389 965.653 51.324 966.166 52.1777 966.359Z" fill="white" fill-opacity="0.6"/>
        <path d="M65.1301 968.305C66.8221 968.629 67.8796 967.196 67.4397 965.094C67.2086 964.078 66.7645 963.157 66.1523 962.422C65.5402 961.687 64.7819 961.166 63.9541 960.91C62.3298 960.534 61.2554 962.04 61.6276 964.132C61.8342 965.164 62.2726 966.104 62.8922 966.842C63.5119 967.581 64.2876 968.088 65.1301 968.305Z" fill="white" fill-opacity="0.6"/>
        <path d="M77.8201 970.627C79.3429 970.846 80.1467 969.581 79.639 967.688C79.317 966.667 78.7966 965.76 78.1238 965.047C77.4509 964.333 76.6464 963.836 75.7812 963.598C74.3346 963.337 73.5054 964.644 73.9538 966.537C74.2502 967.579 74.7637 968.506 75.4425 969.224C76.1214 969.942 76.9416 970.426 77.8201 970.627Z" fill="white" fill-opacity="0.6"/>
        <path d="M87.803 966.548C86.2463 966.129 85.4595 967.374 86.1109 969.246C86.4809 970.195 87.0179 971.029 87.6825 971.688C88.3471 972.347 89.1225 972.813 89.9519 973.053C91.6439 973.419 92.3968 972.196 91.6439 970.344C91.2453 969.423 90.6993 968.611 90.039 967.958C89.3787 967.305 88.6179 966.826 87.803 966.548Z" fill="white" fill-opacity="0.6"/>
        <path d="M102.354 975.626C103.852 975.867 104.444 974.748 103.666 973.137C102.653 971.277 101.103 969.967 99.334 969.476C97.9212 969.183 97.3459 970.292 98.0565 971.924C98.523 972.879 99.1466 973.702 99.887 974.34C100.627 974.978 101.468 975.416 102.354 975.626Z" fill="white" fill-opacity="0.6"/>
        <path d="M114.545 978.032C116.127 978.335 116.753 977.404 115.941 975.94C114.793 974.161 113.192 972.915 111.398 972.405C109.808 972.018 109.266 973.074 110.197 974.695C111.284 976.405 112.823 977.587 114.545 978.032Z" fill="white" fill-opacity="0.6"/>
        <path d="M123.403 974.999C121.906 974.737 121.483 975.647 122.405 977.091C123.685 978.802 125.391 979.936 127.252 980.312C128.826 980.532 129.317 979.58 128.318 978.22C126.979 976.56 125.266 975.438 123.403 974.999Z" fill="white" fill-opacity="0.6"/>
        <path d="M140.027 982.101C141.837 982.362 142.379 981.421 141.178 980.009C139.735 978.581 138.019 977.637 136.195 977.268C134.503 976.965 134.003 977.854 135.137 979.245C136.513 980.755 138.21 981.745 140.027 982.101Z" fill="white" fill-opacity="0.6"/>
        <path d="M149.189 978.921C147.497 978.775 147.133 979.601 148.233 980.825C149.815 982.34 151.714 983.265 153.707 983.492C155.399 983.617 155.923 982.707 154.756 981.504C153.118 980.049 151.197 979.158 149.189 978.921Z" fill="white" fill-opacity="0.6"/>
        <path d="M167.962 984.234C169.772 984.234 170.272 983.314 169.028 982.143C167.247 980.765 165.205 979.99 163.106 979.894C161.363 979.894 160.966 980.71 162.158 981.86C163.876 983.306 165.886 984.128 167.962 984.234Z" fill="white" fill-opacity="0.6"/>
        <path d="M182.945 984.203C184.899 984.14 185.534 983.157 184.298 982.027C182.418 980.672 180.274 979.978 178.097 980.019C176.177 980.019 175.669 980.95 176.871 982.111C178.695 983.504 180.801 984.229 182.945 984.203Z" fill="white" fill-opacity="0.6"/>
        <path d="M198.672 984.328C200.584 984.328 201.125 983.283 199.84 982.059C197.957 980.683 195.799 979.994 193.613 980.072C191.726 980.155 191.253 981.013 192.513 982.163C194.37 983.559 196.499 984.307 198.672 984.328Z" fill="white" fill-opacity="0.6"/>
        <path d="M213.976 983.032C215.931 982.749 216.514 981.452 215.338 980.124C214.162 978.796 211.269 977.959 209.205 978.325C207.141 978.691 206.667 980.03 208.113 981.285C209.862 982.639 211.92 983.252 213.976 983.032Z" fill="white" fill-opacity="0.6"/>
        <path d="M229.255 980.04C231.15 979.601 231.692 978.063 230.448 976.568C229.603 975.73 228.619 975.132 227.567 974.816C226.514 974.5 225.419 974.473 224.357 974.737C222.496 975.26 222.013 976.745 223.232 978.147C224.08 978.95 225.051 979.531 226.087 979.857C227.122 980.182 228.2 980.244 229.255 980.04Z" fill="white" fill-opacity="0.6"/>
        <path d="M244.043 976.494C246.048 976.045 246.514 974.267 245.05 972.499C244.272 971.641 243.349 971.008 242.348 970.646C241.347 970.284 240.293 970.203 239.263 970.407C237.318 970.93 236.895 972.781 238.316 974.413C239.103 975.228 240.019 975.834 241.004 976.192C241.99 976.55 243.024 976.653 244.043 976.494Z" fill="white" fill-opacity="0.6"/>
        <path d="M258.451 973.43C260.236 973.127 260.667 971.432 259.39 969.581C258.643 968.583 257.721 967.81 256.694 967.322C255.667 966.834 254.563 966.644 253.468 966.767C251.632 967.165 251.353 969.068 252.816 970.867C253.562 971.752 254.447 972.436 255.417 972.877C256.386 973.318 257.42 973.507 258.451 973.43Z" fill="white" fill-opacity="0.6"/>
        <path d="M272.402 970.439C274.322 970.01 274.677 967.928 273.138 965.795C271.598 963.661 269.119 962.657 267.351 963.117C265.583 963.577 265.219 965.732 266.708 967.709C267.432 968.661 268.319 969.4 269.304 969.872C270.289 970.345 271.348 970.538 272.402 970.439Z" fill="white" fill-opacity="0.6"/>
        <path d="M286.547 966.673C288.323 966.171 288.679 964.048 287.3 961.82C285.921 959.592 283.07 958.034 281.183 958.682C279.491 959.268 279.186 961.412 280.523 963.535C281.86 965.659 284.669 967.207 286.547 966.673Z" fill="white" fill-opacity="0.6"/>
        <path d="M300.684 962.51C302.469 961.967 302.79 959.655 301.369 957.218C299.854 954.624 297.02 953.034 295.151 953.745C293.408 954.384 293.179 956.737 294.567 959.059C295.954 961.381 298.797 963.086 300.684 962.51Z" fill="white" fill-opacity="0.6"/>
        <path d="M309.245 948.683C307.485 949.29 307.257 951.821 308.661 954.352C310.066 956.883 312.968 958.797 314.871 958.243C316.775 957.689 317.011 955.283 315.556 952.626C314.101 949.97 311.14 948.024 309.245 948.683Z" fill="white" fill-opacity="0.6"/>
        <path d="M329.321 954.143C331.08 953.589 331.3 950.942 329.752 948.108C328.111 945.106 325.175 943.192 323.323 943.924C321.631 944.573 321.487 947.344 322.993 950.032C324.6 952.877 327.443 954.739 329.321 954.143Z" fill="white" fill-opacity="0.6"/>
        <path d="M343.711 950.608C345.522 950.179 345.784 947.47 344.236 944.499C342.595 941.288 339.625 939.071 337.722 939.625C335.953 940.148 335.793 942.941 337.307 945.849C338.889 948.913 341.774 951.078 343.711 950.608Z" fill="white" fill-opacity="0.6"/>
        <path d="M358.297 947.899C360.132 947.595 360.403 944.939 358.846 941.77C357.154 938.402 354.176 935.902 352.248 936.299C350.319 936.697 350.285 939.437 351.808 942.575C353.33 945.713 356.351 948.213 358.297 947.899Z" fill="white" fill-opacity="0.6"/>
        <path d="M372.958 946.319C374.988 946.257 375.25 943.454 373.457 939.96C371.765 936.686 368.931 934.145 367.044 934.249C365.158 934.354 364.912 937.387 366.655 940.765C368.398 944.144 371.08 946.382 372.958 946.319Z" fill="white" fill-opacity="0.6"/>
        <path d="M579.882 960.303C577.827 958.369 574.502 956.789 572.649 956.789C570.796 956.789 570.957 958.348 573.038 960.303C575.119 962.259 578.326 963.912 580.187 963.891C582.048 963.87 581.955 962.249 579.882 960.303Z" fill="white" fill-opacity="0.6"/>
        <path d="M557.37 958.975C559.358 961.004 562.582 962.876 564.536 963.075C566.49 963.274 566.473 961.726 564.468 959.728C562.347 957.68 559.857 956.299 557.209 955.701C555.264 955.45 555.374 956.946 557.37 958.975Z" fill="white" fill-opacity="0.6"/>
        <path d="M541.956 956.695C543.927 958.787 547.083 960.879 549.062 961.255C551.042 961.632 551.042 960.115 549.062 958.023C546.961 955.875 544.494 954.341 541.846 953.536C539.909 953.139 539.993 954.572 541.956 956.695Z" fill="white" fill-opacity="0.6"/>
        <path d="M527.193 953.714C529.198 955.984 532.32 958.222 534.147 958.599C535.975 958.975 535.839 957.438 533.843 955.21C531.846 952.982 528.555 950.796 526.762 950.367C524.968 949.938 525.188 951.434 527.193 953.714Z" fill="white" fill-opacity="0.6"/>
        <path d="M512.853 949.907C514.799 952.323 517.853 954.739 519.681 955.241C521.508 955.743 521.373 954.195 519.427 951.811C517.481 949.426 514.308 947.062 512.515 946.508C510.721 945.953 510.908 947.501 512.853 949.907Z" fill="white" fill-opacity="0.6"/>
        <path d="M498.59 945.849C500.476 948.422 503.471 950.974 505.358 951.57C507.244 952.166 507.202 950.524 505.299 948.024C503.395 945.525 500.29 942.962 498.437 942.314C496.585 941.665 496.712 943.297 498.59 945.849Z" fill="white" fill-opacity="0.6"/>
        <path d="M484.529 942.031C486.458 944.771 489.495 947.428 491.246 947.867C492.998 948.307 492.879 946.476 490.916 943.778C488.954 941.079 485.84 938.423 484.106 937.921C482.372 937.419 482.6 939.291 484.529 942.031Z" fill="white" fill-opacity="0.6"/>
        <path d="M470.198 938.015C472.076 940.912 475.079 943.694 476.881 944.186C478.683 944.677 478.632 942.7 476.729 939.866C474.825 937.032 471.737 934.218 469.961 933.685C468.184 933.151 468.32 935.117 470.198 938.015Z" fill="white" fill-opacity="0.6"/>
        <path d="M455.79 934.281C457.618 937.324 460.579 940.201 462.448 940.724C464.318 941.247 464.326 939.165 462.448 936.184C460.57 933.203 457.558 930.275 455.68 929.71C453.802 929.145 453.971 931.258 455.79 934.281Z" fill="white" fill-opacity="0.6"/>
        <path d="M441.332 931.112C443.134 934.249 446.087 937.22 447.973 937.701C449.86 938.182 449.911 935.933 448.1 932.816C446.29 929.7 443.244 926.698 441.383 926.175C439.522 925.652 439.547 927.953 441.332 931.112Z" fill="white" fill-opacity="0.6"/>
        <path d="M426.874 928.8C428.701 932.095 431.688 935.076 433.506 935.295C435.325 935.515 435.334 933.099 433.506 929.836C431.679 926.572 428.583 923.56 426.798 923.267C425.013 922.974 425.046 925.505 426.874 928.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M412.128 927.095C413.913 930.484 416.899 933.371 418.761 933.622C420.622 933.873 420.689 931.216 418.871 927.859C417.052 924.501 413.998 921.468 412.162 921.238C410.326 921.008 410.3 923.706 412.128 927.095Z" fill="white" fill-opacity="0.6"/>
        <path d="M397.23 926.478C399.006 929.93 402.001 932.816 403.888 932.9C405.774 932.984 405.876 930.212 404.082 926.781C402.289 923.351 399.226 920.349 397.314 920.244C395.402 920.14 395.453 923.027 397.23 926.478Z" fill="white" fill-opacity="0.6"/>
        <path d="M588.469 960.324C590.491 962.218 593.706 963.724 595.55 963.577C597.395 963.431 597.09 961.925 595.043 960.011C592.996 958.097 589.908 956.496 588.08 956.643C586.253 956.789 586.439 958.421 588.469 960.324Z" fill="white" fill-opacity="0.6"/>
        <path d="M603.105 960.157C605.077 962.05 608.181 963.64 610.085 963.619C611.988 963.598 611.98 961.987 610.009 960.073C608.038 958.159 604.738 956.611 602.852 956.643C600.965 956.674 601.117 958.254 603.105 960.157Z" fill="white" fill-opacity="0.6"/>
        <path d="M619.002 960.126C620.965 962.029 624.23 963.556 626.167 963.41C628.105 963.264 628.249 961.58 626.294 959.624C624.34 957.668 620.948 956.214 618.976 956.381C617.005 956.549 617.031 958.201 619.002 960.126Z" fill="white" fill-opacity="0.6"/>
        <path d="M635.863 959.383C637.877 961.496 640.391 962.744 643.028 962.939C645.27 962.939 645.837 961.266 644.187 959.31C642.147 957.159 639.582 955.918 636.903 955.785C634.602 955.806 634.162 957.448 635.863 959.383Z" fill="white" fill-opacity="0.6"/>
        <path d="M654.263 958.881C656.252 961.011 658.789 962.197 661.42 962.228C663.688 962.061 664.263 960.304 662.639 958.285C660.621 956.12 658.035 954.938 655.363 954.959C653.045 955.147 652.58 956.904 654.263 958.881Z" fill="white" fill-opacity="0.6"/>
        <path d="M682.114 957.49C680.769 955.398 677.613 953.85 675.117 953.934C672.622 954.017 671.733 955.785 673.18 957.793C675.025 960.056 677.51 961.343 680.109 961.381C682.503 961.318 683.451 959.53 682.114 957.49Z" fill="white" fill-opacity="0.6"/>
        <path d="M701.826 956.224C700.582 954.133 697.426 952.595 694.982 952.815C692.537 953.034 691.598 954.907 692.943 956.998C693.814 958.124 694.866 959.011 696.032 959.602C697.198 960.193 698.451 960.475 699.711 960.429C702.13 960.178 703.086 958.327 701.826 956.224Z" fill="white" fill-opacity="0.6"/>
        <path d="M712.883 955.785C713.991 957.877 716.918 959.509 719.33 959.341C721.741 959.174 722.934 957.249 721.91 955.158C720.886 953.066 717.917 951.424 715.421 951.622C712.925 951.821 711.783 953.683 712.883 955.785Z" fill="white" fill-opacity="0.6"/>
        <path d="M20.5454 950.65C20.5358 949.539 20.1913 948.474 19.5795 947.661C18.9676 946.849 18.1324 946.348 17.2375 946.257C16.8016 946.248 16.3688 946.348 15.9648 946.551C15.5608 946.753 15.194 947.054 14.8861 947.436C14.5782 947.818 14.3356 948.272 14.1727 948.772C14.0099 949.272 13.9301 949.807 13.9381 950.346C13.9516 951.467 14.3082 952.541 14.9361 953.35C15.564 954.159 16.4165 954.644 17.3221 954.708C17.7527 954.718 18.1805 954.62 18.5793 954.418C18.978 954.217 19.3394 953.917 19.6412 953.537C19.9429 953.157 20.1788 952.705 20.3343 952.209C20.4898 951.712 20.5616 951.182 20.5454 950.65Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.4115 951.769C34.3692 950.682 34.0312 949.644 33.4523 948.824C32.8735 948.004 32.0876 947.449 31.2221 947.25C30.8207 947.209 30.4168 947.276 30.0387 947.448C29.6606 947.619 29.3174 947.891 29.0329 948.244C28.7485 948.596 28.5298 949.021 28.3921 949.489C28.2544 949.957 28.201 950.457 28.2357 950.953C28.2651 952.051 28.6042 953.102 29.1945 953.923C29.7847 954.745 30.5888 955.285 31.4674 955.45C31.867 955.5 32.2705 955.439 32.6484 955.271C33.0263 955.103 33.369 954.833 33.6514 954.48C33.9337 954.127 34.1485 953.7 34.28 953.231C34.4116 952.762 34.4565 952.262 34.4115 951.769Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.4147 953.358C47.2851 952.3 46.8961 951.317 46.3 950.54C45.7039 949.764 44.9295 949.233 44.0814 949.018C42.5248 948.819 41.408 950.451 41.628 952.637C41.7461 953.708 42.1384 954.703 42.7473 955.479C43.3562 956.254 44.1498 956.768 45.012 956.946C46.6025 957.145 47.677 955.544 47.4147 953.358Z" fill="white" fill-opacity="0.6"/>
        <path d="M59.7664 955.816C59.5646 954.794 59.144 953.857 58.5486 953.104C57.9532 952.351 57.205 951.811 56.3824 951.539C54.9103 951.256 53.9628 952.752 54.2843 954.833C54.4621 955.867 54.875 956.817 55.4745 957.571C56.0739 958.326 56.835 958.852 57.6683 959.09C59.2081 959.331 60.1471 957.866 59.7664 955.816Z" fill="white" fill-opacity="0.6"/>
        <path d="M66.3228 957.375C66.5612 958.391 67.0035 959.315 67.6084 960.063C68.2134 960.811 68.9614 961.358 69.783 961.653C71.272 962.019 72.1349 960.711 71.6696 958.797C71.3984 957.805 70.9436 956.904 70.3415 956.167C69.7394 955.43 69.0064 954.876 68.201 954.551C66.7712 954.143 65.9167 955.45 66.3228 957.375Z" fill="white" fill-opacity="0.6"/>
        <path d="M77.9979 960.377C78.3184 961.356 78.8122 962.235 79.4431 962.948C80.0741 963.661 80.8262 964.191 81.6442 964.498C83.0993 964.906 83.8522 963.734 83.2516 961.956C82.8978 961.005 82.3915 960.154 81.7632 959.452C81.1349 958.751 80.3978 958.215 79.5968 957.877C78.1924 957.417 77.4733 958.588 77.9979 960.377Z" fill="white" fill-opacity="0.6"/>
        <path d="M94.6896 965.125C93.771 963.331 92.3778 961.985 90.7472 961.318C89.3597 960.9 88.8098 961.946 89.512 963.661C90.3542 965.53 91.7587 966.913 93.4205 967.51C94.8672 967.886 95.451 966.788 94.6896 965.125Z" fill="white" fill-opacity="0.6"/>
        <path d="M100.984 966.956C102.017 968.716 103.512 969.98 105.214 970.533C106.669 970.878 107.143 969.874 106.221 968.336C105.131 966.658 103.656 965.425 101.991 964.801C100.561 964.362 100.138 965.324 100.984 966.956Z" fill="white" fill-opacity="0.6"/>
        <path d="M117.904 971.213C116.638 969.672 115.069 968.565 113.352 968.002C111.948 967.667 111.66 968.577 112.675 970.093C113.88 971.719 115.455 972.853 117.193 973.346C118.648 973.587 118.987 972.645 117.904 971.213Z" fill="white" fill-opacity="0.6"/>
        <path d="M130.01 973.744C128.571 972.329 126.881 971.357 125.078 970.909C123.657 970.669 123.488 971.537 124.672 972.896C126.051 974.391 127.732 975.396 129.537 975.804C131.034 975.992 131.262 975.062 130.01 973.744Z" fill="white" fill-opacity="0.6"/>
        <path d="M136.99 975.03C138.491 976.452 140.251 977.396 142.117 977.781C143.758 978.022 144.155 977.164 142.963 975.972C141.397 974.619 139.608 973.712 137.726 973.315C136.11 973.032 135.805 973.796 136.99 975.03Z" fill="white" fill-opacity="0.6"/>
        <path d="M150.39 976.923C152.073 978.204 153.979 978.971 155.949 979.162C157.59 979.162 157.751 978.262 156.253 977.07C154.508 975.863 152.571 975.142 150.577 974.957C149.037 974.905 148.969 975.741 150.39 976.923Z" fill="white" fill-opacity="0.6"/>
        <path d="M164.231 977.614C166.017 978.869 168.017 979.585 170.069 979.706C171.921 979.706 172.387 978.806 170.999 977.739C169.171 976.533 167.148 975.847 165.077 975.731C163.208 975.721 162.869 976.526 164.231 977.614Z" fill="white" fill-opacity="0.6"/>
        <path d="M179.417 977.603C181.352 978.731 183.448 979.378 185.584 979.507C187.488 979.643 187.886 978.754 186.43 977.676C184.499 976.479 182.394 975.768 180.238 975.585C178.326 975.532 177.97 976.557 179.417 977.603Z" fill="white" fill-opacity="0.6"/>
        <path d="M194.907 977.415C196.755 978.698 198.856 979.317 200.973 979.203C202.911 979.015 203.41 977.76 202.014 976.578C200.152 975.297 198.019 974.743 195.897 974.988C193.96 975.208 193.528 976.264 194.907 977.415Z" fill="white" fill-opacity="0.6"/>
        <path d="M210.406 974.779C212.217 976.142 214.36 976.668 216.455 976.264C218.316 975.773 218.672 974.225 217.191 972.876C215.375 971.468 213.191 970.977 211.091 971.505C209.247 972.081 208.951 973.524 210.406 974.779Z" fill="white" fill-opacity="0.6"/>
        <path d="M232.199 968.336C231.363 967.525 230.394 966.95 229.36 966.65C228.325 966.35 227.25 966.333 226.21 966.6C224.357 967.196 224.002 968.911 225.364 970.376C226.205 971.145 227.162 971.699 228.179 972.004C229.196 972.31 230.253 972.36 231.286 972.154C233.215 971.662 233.621 969.895 232.199 968.336Z" fill="white" fill-opacity="0.6"/>
        <path d="M246.581 963.933C245.794 963.031 244.854 962.359 243.829 961.966C242.804 961.573 241.721 961.47 240.659 961.663C238.883 962.186 238.612 964.037 240.042 965.711C240.847 966.576 241.79 967.222 242.81 967.608C243.831 967.994 244.905 968.11 245.964 967.949C247.724 967.52 248.037 965.69 246.581 963.933Z" fill="white" fill-opacity="0.6"/>
        <path d="M254.077 961.935C255.574 963.766 258.214 964.958 259.923 964.498C261.632 964.037 261.818 962.019 260.287 960.084C258.755 958.149 256.057 957.051 254.365 957.595C252.673 958.138 252.58 960.094 254.077 961.935Z" fill="white" fill-opacity="0.6"/>
        <path d="M267.943 957.772C269.534 959.77 272.258 960.91 273.865 960.293C275.473 959.676 275.557 957.364 273.933 955.231C272.309 953.097 269.508 952.02 267.909 952.773C266.31 953.526 266.336 955.754 267.943 957.772Z" fill="white" fill-opacity="0.6"/>
        <path d="M281.589 952.459C283.146 954.718 285.819 956.12 287.63 955.597C289.44 955.074 289.575 952.564 287.993 950.21C286.411 947.857 283.636 946.445 281.885 947.072C280.134 947.7 280.016 950.221 281.589 952.459Z" fill="white" fill-opacity="0.6"/>
        <path d="M295.447 946.895C297.02 949.394 299.727 950.984 301.572 950.472C303.416 949.959 303.602 947.334 302.012 944.74C300.421 942.146 297.612 940.504 295.802 941.132C293.992 941.759 293.89 944.405 295.447 946.895Z" fill="white" fill-opacity="0.6"/>
        <path d="M309.693 941.749C311.385 944.395 314.262 946.047 315.928 945.336C317.595 944.625 317.62 941.791 315.861 938.977C314.101 936.163 311.191 934.532 309.541 935.358C307.891 936.184 307.934 939.061 309.693 941.749Z" fill="white" fill-opacity="0.6"/>
        <path d="M323.771 936.446C325.463 939.353 328.339 941.288 330.099 940.734C331.859 940.18 331.926 937.262 330.175 934.239C328.424 931.216 325.488 929.229 323.754 929.888C322.02 930.547 322.054 933.486 323.771 936.446Z" fill="white" fill-opacity="0.6"/>
        <path d="M338.035 931.906C339.727 935.044 342.637 937.241 344.456 936.875C346.275 936.509 346.376 933.549 344.633 930.348C342.891 927.147 339.921 924.815 338.119 925.286C336.317 925.756 336.309 928.79 338.035 931.906Z" fill="white" fill-opacity="0.6"/>
        <path d="M359.193 927.545C357.4 924.177 354.38 921.604 352.586 921.939C350.793 922.273 350.801 925.328 352.586 928.612C354.371 931.896 357.281 934.343 359.1 934.082C360.919 933.82 360.978 930.892 359.193 927.545Z" fill="white" fill-opacity="0.6"/>
        <path d="M367.399 926.949C369.235 930.327 372.247 932.973 373.998 932.754C375.75 932.534 375.758 929.616 373.871 926.175C371.985 922.734 368.905 919.962 367.18 920.234C365.454 920.506 365.555 923.549 367.399 926.949Z" fill="white" fill-opacity="0.6"/>
        <path d="M388.981 926.604C387.137 923.11 384.032 920.328 382.213 920.474C380.386 920.391 380.445 923.278 382.272 926.75C384.1 930.222 387.12 933.486 388.947 933.277C390.775 933.067 390.842 930.118 388.981 926.604Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.300633 1010.46C2.99094 1011.24 4.26841 1010.83 5.37668 1008.92C5.83032 1008.17 6.08453 1007.26 6.10423 1006.33C6.12393 1005.39 5.90814 1004.47 5.48666 1003.69C4.43761 1001.81 3.16014 1001.43 0.410619 1002.3C-0.0967455 1004.98 -0.134251 1007.76 0.300633 1010.46Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.385154 983.586C-0.0109643 985.995 -0.0966121 988.466 0.131361 990.907C0.131361 991.744 0.723576 992.152 1.40884 992.246C2.45075 992.446 3.51454 992.137 4.37618 991.386C5.23782 990.635 5.83006 989.5 6.02808 988.219C6.0996 987.599 6.06247 986.967 5.91923 986.366C5.77599 985.765 5.53 985.21 5.19787 984.737C4.86574 984.263 4.45522 983.884 3.99417 983.624C3.53313 983.364 3.03229 983.23 2.52558 983.23C1.80548 983.277 1.08961 983.396 0.385154 983.586Z" fill="white" fill-opacity="0.6"/>
        <path d="M316.055 931.509C317.841 930.829 317.883 927.869 316.055 925.014C314.228 922.158 311.326 920.495 309.592 921.374C307.858 922.253 307.993 924.878 309.592 927.576C311.191 930.275 314.262 932.189 316.055 931.509Z" fill="white" fill-opacity="0.6"/>
        <path d="M323.906 915.402C322.028 916.019 322.028 918.947 323.847 922.022C325.539 924.857 328.28 926.802 330.057 926.447C331.833 926.091 332.18 923.215 330.48 920.077C328.779 916.939 325.7 914.805 323.906 915.402Z" fill="white" fill-opacity="0.6"/>
        <path d="M338.001 910.737C336.207 911.333 336.385 914.45 338.356 917.671C340.192 920.694 342.899 922.681 344.633 922.294C346.368 921.907 346.512 918.874 344.633 915.632C342.755 912.389 339.786 910.141 338.001 910.737Z" fill="white" fill-opacity="0.6"/>
        <path d="M359.337 919.638C361.147 919.345 361.097 916.28 359.176 912.933C357.256 909.586 354.193 907.107 352.408 907.568C350.623 908.028 350.885 910.862 352.645 914.063C354.515 917.441 357.518 919.889 359.337 919.638Z" fill="white" fill-opacity="0.6"/>
        <path d="M373.981 918.843C375.919 918.979 376.063 916.27 374.235 912.797C372.408 909.325 369.261 906.365 367.374 906.375C365.488 906.386 365.53 908.917 367.23 912.264C368.931 915.611 372.044 918.686 373.981 918.843Z" fill="white" fill-opacity="0.6"/>
        <path d="M389.15 918.958C391.037 918.958 391.054 916.029 389.15 912.588C387.247 909.147 384.125 906.313 382.272 906.448C380.42 906.584 380.58 909.252 382.348 912.619C384.21 916.155 387.272 918.989 389.15 918.958Z" fill="white" fill-opacity="0.6"/>
        <path d="M404.192 919.387C405.977 919.387 405.884 916.542 403.879 913.195C401.874 909.848 398.803 907.024 397.044 907.076C395.411 907.076 395.58 909.785 397.407 913.069C399.302 916.531 402.39 919.366 404.192 919.387Z" fill="white" fill-opacity="0.6"/>
        <path d="M411.688 908.948C409.996 908.875 410.394 911.73 412.534 915.151C414.564 918.383 417.297 920.851 418.964 920.966C420.63 921.081 420.512 918.372 418.507 915.119C416.502 911.866 413.414 909.022 411.688 908.948Z" fill="white" fill-opacity="0.6"/>
        <path d="M433.303 923.455C435.156 923.874 435.148 921.594 433.227 918.435C431.307 915.276 428.151 912.285 426.4 911.908C424.649 911.532 424.767 913.624 426.544 916.751C428.321 919.878 431.451 923.027 433.303 923.455Z" fill="white" fill-opacity="0.6"/>
        <path d="M440.816 915.705C438.972 915.172 439.124 917.316 441.188 920.506C443.066 923.455 445.909 926.185 447.66 926.719C449.411 927.252 449.564 925.254 447.66 922.232C445.757 919.209 442.626 916.218 440.816 915.705Z" fill="white" fill-opacity="0.6"/>
        <path d="M462.211 930.61C463.903 931.028 463.692 928.915 461.645 926.039C459.597 923.162 456.501 920.412 454.809 920.004C453.117 919.596 453.531 921.562 455.418 924.397C457.305 927.231 460.477 930.191 462.211 930.61Z" fill="white" fill-opacity="0.6"/>
        <path d="M476.45 934.825C478.142 935.274 477.888 933.329 475.807 930.641C473.726 927.953 470.621 925.265 468.946 924.805C467.271 924.344 467.71 926.164 469.622 928.852C471.534 931.54 474.715 934.364 476.45 934.825Z" fill="white" fill-opacity="0.6"/>
        <path d="M490.612 939.197C492.304 939.667 492.025 937.879 489.927 935.316C487.829 932.754 484.698 930.223 483.023 929.741C481.475 929.292 481.813 930.923 483.751 933.454C485.688 935.986 488.886 938.715 490.612 939.197Z" fill="white" fill-opacity="0.6"/>
        <path d="M504.766 943.558C506.458 944.008 506.119 942.313 503.987 939.918C501.855 937.523 498.7 935.149 497.041 934.689C495.383 934.228 495.882 935.735 497.888 938.151C499.893 940.567 503.057 943.098 504.766 943.558Z" fill="white" fill-opacity="0.6"/>
        <path d="M518.775 947.658C520.577 948.171 520.391 946.696 518.285 944.458C516.204 942.24 513.801 940.532 511.204 939.427C509.562 938.946 509.842 940.243 511.796 942.46C513.75 944.677 516.965 947.146 518.775 947.658Z" fill="white" fill-opacity="0.6"/>
        <path d="M526.017 943.621C524.021 943.087 524.181 944.374 526.305 946.591C528.325 948.703 530.666 950.292 533.191 951.267C535.213 951.811 535.306 950.524 533.327 948.422C531.171 946.239 528.687 944.607 526.017 943.621Z" fill="white" fill-opacity="0.6"/>
        <path d="M541.262 946.769C539.198 946.372 539.308 947.669 541.44 949.75C543.5 951.747 545.883 953.173 548.428 953.934C550.509 954.352 550.636 953.034 548.665 951.057C546.473 948.993 543.949 947.53 541.262 946.769Z" fill="white" fill-opacity="0.6"/>
        <path d="M564.324 955.701C566.211 955.785 566.016 954.185 563.834 952.313C561.651 950.44 558.318 948.84 556.448 948.767C554.756 948.704 554.976 950.074 556.998 951.905C559.02 953.735 562.421 955.618 564.324 955.701Z" fill="white" fill-opacity="0.6"/>
        <path d="M579.705 956.203C581.659 956.203 581.583 954.603 579.493 952.773C577.404 950.942 574.053 949.489 572.142 949.53C570.23 949.572 570.45 950.901 572.412 952.668C574.375 954.436 577.734 956.203 579.705 956.203Z" fill="white" fill-opacity="0.6"/>
        <path d="M594.73 955.984C596.498 955.932 596.261 954.279 594.121 952.48C591.98 950.681 588.698 949.258 586.93 949.342C585.297 949.436 585.678 950.88 587.683 952.637C589.688 954.394 592.945 956.036 594.73 955.984Z" fill="white" fill-opacity="0.6"/>
        <path d="M610.085 955.869C612.158 955.869 612.318 954.258 610.339 952.407C608.152 950.463 605.558 949.34 602.868 949.175C600.906 949.258 600.914 950.65 602.784 952.438C604.904 954.416 607.437 955.606 610.085 955.869Z" fill="white" fill-opacity="0.6"/>
        <path d="M627.073 955.45C629.323 955.45 629.746 953.766 627.919 951.884C625.716 949.854 623.052 948.734 620.305 948.683C618.147 948.798 617.902 950.221 619.653 952.041C621.783 954.094 624.375 955.285 627.073 955.45Z" fill="white" fill-opacity="0.6"/>
        <path d="M639.23 947.805C636.692 947.92 636.252 949.541 638.088 951.486C640.127 953.451 642.611 954.577 645.194 954.708C647.622 954.708 648.367 953.066 646.776 951.131C644.643 948.986 641.979 947.812 639.23 947.805Z" fill="white" fill-opacity="0.6"/>
        <path d="M664.576 953.756C667.055 953.641 667.901 951.884 666.37 949.938C664.838 947.993 661.429 946.539 658.891 946.738C656.488 946.926 655.828 948.474 657.284 950.346C659.29 952.558 661.888 953.772 664.576 953.756Z" fill="white" fill-opacity="0.6"/>
        <path d="M679.483 945.483C676.852 945.702 676.056 947.501 677.638 949.52C679.518 951.603 681.968 952.724 684.491 952.658C687.029 952.532 688.095 950.733 686.758 948.736C685.422 946.738 682.105 945.273 679.483 945.483Z" fill="white" fill-opacity="0.6"/>
        <path d="M704.854 951.465C707.435 951.319 708.67 949.468 707.519 947.428C706.369 945.389 703.188 943.893 700.523 944.133C697.985 944.353 696.961 945.995 698.061 947.951C699.161 949.907 702.266 951.612 704.854 951.465Z" fill="white" fill-opacity="0.6"/>
        <path d="M725.522 950.221C728.06 950.012 729.372 948.024 728.323 945.974C727.274 943.924 724.253 942.439 721.597 942.742C719.135 943.025 717.993 944.834 718.974 946.78C719.956 948.725 722.967 950.44 725.522 950.221Z" fill="white" fill-opacity="0.6"/>
        <path d="M9.75878 937.084C9.31121 937.069 8.86599 937.168 8.45017 937.373C8.03434 937.578 7.65662 937.886 7.34004 938.277C7.02345 938.669 6.77461 939.136 6.60866 939.65C6.44272 940.164 6.36312 940.714 6.37475 941.267C6.40226 942.33 6.75606 943.34 7.3642 944.092C7.97235 944.844 8.78919 945.281 9.6488 945.315C10.0898 945.348 10.5315 945.27 10.9478 945.088C11.3641 944.905 11.7465 944.621 12.0724 944.252C12.3982 943.884 12.6609 943.438 12.8448 942.941C13.0287 942.445 13.13 941.908 13.1428 941.362C13.1452 940.242 12.7918 939.167 12.1588 938.367C11.5258 937.566 10.6639 937.106 9.75878 937.084Z" fill="white" fill-opacity="0.6"/>
        <path d="M24.2509 945.828C24.6569 945.862 25.0641 945.787 25.444 945.607C25.824 945.426 26.1674 945.145 26.4503 944.784C26.7332 944.422 26.9487 943.988 27.0817 943.513C27.2148 943.037 27.2621 942.532 27.2204 942.031C27.1458 940.952 26.7653 939.941 26.1461 939.175C25.5269 938.41 24.7089 937.94 23.8364 937.847C23.4484 937.829 23.0617 937.911 22.7012 938.089C22.3407 938.268 22.0145 938.538 21.744 938.882C21.4735 939.226 21.2647 939.637 21.1311 940.088C20.9974 940.538 20.942 941.019 20.9684 941.498C20.9898 942.592 21.3363 943.638 21.9417 944.437C22.5471 945.235 23.3691 945.73 24.2509 945.828Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.2118 939.563C35.5705 939.217 34.3776 940.703 34.513 942.92C34.5891 943.936 34.9179 944.898 35.4542 945.673C35.9905 946.449 36.7079 947 37.5078 947.25C37.8703 947.353 38.2474 947.346 38.6072 947.23C38.967 947.114 39.2991 946.893 39.5753 946.585C39.8515 946.278 40.0639 945.893 40.1944 945.462C40.3248 945.032 40.3697 944.569 40.325 944.112C40.2582 943.046 39.9216 942.033 39.3628 941.216C38.804 940.399 38.0514 939.821 37.2118 939.563Z" fill="white" fill-opacity="0.6"/>
        <path d="M49.056 942.062C47.5924 941.843 46.7126 943.464 47.144 945.608C47.3306 946.577 47.7371 947.46 48.3156 948.153C48.894 948.846 49.6205 949.32 50.4096 949.52C51.924 949.75 52.88 948.192 52.4654 946.183C52.2654 945.171 51.84 944.249 51.2373 943.52C50.6347 942.792 49.879 942.287 49.056 942.062Z" fill="white" fill-opacity="0.6"/>
        <path d="M62.1437 952.313C63.6158 952.752 64.5126 951.518 64.1065 949.624C63.8568 948.632 63.4197 947.728 62.8313 946.986C62.2428 946.244 61.5199 945.685 60.7224 945.357C59.3688 944.949 58.582 946.037 58.8697 947.92C59.0562 948.942 59.4569 949.886 60.0315 950.657C60.6062 951.428 61.3345 951.999 62.1437 952.313Z" fill="white" fill-opacity="0.6"/>
        <path d="M73.7 955.513C75.1298 955.931 75.8235 954.77 75.2228 952.982C74.8814 952.045 74.3886 951.205 73.7746 950.512C73.1606 949.82 72.4385 949.29 71.6527 948.955C70.3752 948.547 69.7577 949.583 70.223 951.35C70.5095 952.325 70.9714 953.206 71.5738 953.927C72.1761 954.648 72.9032 955.19 73.7 955.513Z" fill="white" fill-opacity="0.6"/>
        <path d="M82.5578 952.951C81.1534 952.365 80.5782 953.275 81.2212 955.042C81.9581 956.821 83.1988 958.202 84.7067 958.923C86.1619 959.498 86.8556 958.609 86.2465 956.977C85.4259 955.153 84.1239 953.731 82.5578 952.951Z" fill="white" fill-opacity="0.6"/>
        <path d="M96.0263 962.489C97.4391 962.939 97.9128 962.019 97.0499 960.502C96.0079 958.84 94.6012 957.584 92.9976 956.883C91.737 956.444 91.3563 957.218 92.0754 958.735C93.0109 960.503 94.4037 961.826 96.0263 962.489Z" fill="white" fill-opacity="0.6"/>
        <path d="M103.801 960.795C102.405 960.314 102.109 961.109 103.124 962.604C104.218 964.15 105.634 965.294 107.219 965.91C108.674 966.37 109.114 965.564 108.141 964.184C106.938 962.637 105.448 961.474 103.801 960.795Z" fill="white" fill-opacity="0.6"/>
        <path d="M118.945 969.047C120.391 969.382 120.637 968.535 119.427 967.259C118.045 965.867 116.438 964.858 114.714 964.299C113.429 963.964 113.268 964.675 114.308 965.951C115.619 967.448 117.214 968.512 118.945 969.047Z" fill="white" fill-opacity="0.6"/>
        <path d="M131.017 971.777C132.523 972.039 132.709 971.213 131.389 970.052C129.851 968.777 128.127 967.885 126.313 967.426C124.968 967.165 124.858 967.834 126.017 969.006C127.495 970.38 129.203 971.328 131.017 971.777Z" fill="white" fill-opacity="0.6"/>
        <path d="M143.648 973.827C145.34 974.047 145.602 973.273 144.232 972.206C142.534 971.006 140.668 970.214 138.733 969.874C137.227 969.654 137.041 970.261 138.276 971.328C139.898 972.634 141.731 973.487 143.648 973.827Z" fill="white" fill-opacity="0.6"/>
        <path d="M152.074 971.422C150.382 971.317 150.264 972.049 151.812 973.127C153.51 974.235 155.372 974.907 157.286 975.103C159.062 975.208 159.392 974.392 157.929 973.388C156.097 972.275 154.109 971.608 152.074 971.422Z" fill="white" fill-opacity="0.6"/>
        <path d="M166.592 971.924C164.696 971.924 164.519 972.74 166.118 973.744C167.945 974.797 169.931 975.367 171.947 975.417C173.884 975.417 174.324 974.507 172.793 973.555C170.838 972.492 168.727 971.937 166.592 971.924Z" fill="white" fill-opacity="0.6"/>
        <path d="M182.353 971.746C180.297 971.861 179.865 972.614 181.354 973.618C183.204 974.722 185.223 975.321 187.276 975.375C189.341 975.375 189.95 974.392 188.605 973.336C186.658 972.177 184.511 971.631 182.353 971.746Z" fill="white" fill-opacity="0.6"/>
        <path d="M197.725 968.943C195.72 969.466 195.373 970.69 196.879 971.809C198.703 972.964 200.766 973.416 202.801 973.106C204.806 972.729 205.339 971.348 203.951 970.135C202.564 968.922 199.645 968.43 197.725 968.943Z" fill="white" fill-opacity="0.6"/>
        <path d="M218.113 968.681C220.025 968.075 220.398 966.37 218.909 965C217.995 964.304 216.973 963.851 215.91 963.671C214.846 963.491 213.764 963.587 212.733 963.954C210.956 964.665 210.66 966.046 212.031 967.374C213.84 968.782 216.026 969.251 218.113 968.681Z" fill="white" fill-opacity="0.6"/>
        <path d="M227.563 957.929C225.668 958.63 225.389 960.398 226.912 961.925C227.728 962.662 228.655 963.189 229.639 963.475C230.623 963.76 231.643 963.799 232.639 963.588C234.56 963.065 234.999 961.297 233.595 959.655C232.761 958.821 231.785 958.233 230.74 957.934C229.695 957.635 228.609 957.633 227.563 957.929Z" fill="white" fill-opacity="0.6"/>
        <path d="M247.021 959.027C248.713 958.546 248.891 956.611 247.317 954.844C245.744 953.076 243.028 952.198 241.395 952.815C239.763 953.432 239.703 955.053 241.175 956.695C241.962 957.572 242.889 958.233 243.896 958.635C244.904 959.037 245.969 959.17 247.021 959.027Z" fill="white" fill-opacity="0.6"/>
        <path d="M254.788 947.7C253.096 948.495 253.206 950.597 254.94 952.48C256.674 954.363 259.026 955.168 260.65 954.572C262.275 953.976 262.478 951.737 260.837 949.792C259.195 947.846 256.463 946.905 254.788 947.7Z" fill="white" fill-opacity="0.6"/>
        <path d="M274.449 949.175C276.141 948.39 276.141 945.859 274.373 943.746C272.605 941.634 269.855 940.609 268.222 941.655C266.589 942.7 266.792 944.792 268.366 946.769C269.94 948.746 272.774 949.949 274.449 949.175Z" fill="white" fill-opacity="0.6"/>
        <path d="M288.078 943.328C289.846 942.617 289.956 939.981 288.273 937.554C286.589 935.128 283.721 933.904 282.012 934.814C280.303 935.724 280.32 937.952 281.911 940.295C283.501 942.638 286.301 944.05 288.078 943.328Z" fill="white" fill-opacity="0.6"/>
        <path d="M295.954 927.848C294.11 928.622 294.051 931.31 295.785 933.936C297.384 936.373 300.015 937.889 301.783 937.377C303.661 936.833 303.924 934.176 302.308 931.446C300.692 928.716 297.782 927.085 295.954 927.848Z" fill="white" fill-opacity="0.6"/>
        <path d="M344.447 907.338C346.19 906.919 346.139 904.2 344.346 900.947C342.434 897.506 339.27 894.943 337.578 895.445C335.886 895.947 336.097 899.357 338.077 902.589C339.913 905.622 342.747 907.704 344.447 907.338Z" fill="white" fill-opacity="0.6"/>
        <path d="M359.456 904.932C361.071 904.346 360.555 901.135 358.212 897.537C356.139 894.399 353.085 892.088 351.74 892.789C350.395 893.489 350.775 896.69 352.696 899.817C354.794 903.227 357.848 905.507 359.456 904.932Z" fill="white" fill-opacity="0.6"/>
        <path d="M374.007 904.974C375.809 904.838 375.792 902.223 373.931 898.792C371.985 895.194 368.736 892.161 366.926 892.308C365.124 892.308 365.378 895.445 367.408 898.897C369.337 902.212 372.23 905.099 374.007 904.974Z" fill="white" fill-opacity="0.6"/>
        <path d="M388.584 898.677C386.646 895.393 383.626 892.559 381.925 892.464C380.225 892.37 380.352 895.456 382.374 898.918C384.396 902.38 387.517 905.12 389.235 904.911C390.952 904.702 390.597 902.108 388.584 898.677Z" fill="white" fill-opacity="0.6"/>
        <path d="M403.761 906.292C405.512 906.49 405.453 904.2 403.566 900.947C401.595 897.506 398.372 894.316 396.621 894.096C394.869 893.876 395.157 896.774 397.204 900.162C399.15 903.384 402.043 906.072 403.761 906.292Z" fill="white" fill-opacity="0.6"/>
        <path d="M418.363 908.781C420.055 909.084 419.953 906.971 418.033 903.802C416.036 900.476 412.813 897.297 411.104 896.962C409.395 896.627 409.759 899.347 411.823 902.641C413.888 905.936 416.679 908.478 418.363 908.781Z" fill="white" fill-opacity="0.6"/>
        <path d="M432.576 912.17C434.378 912.755 434.403 910.956 432.576 907.986C430.647 904.848 427.5 901.637 425.622 900.947C423.744 900.257 423.989 902.558 426.002 905.748C427.914 908.76 430.808 911.594 432.576 912.17Z" fill="white" fill-opacity="0.6"/>
        <path d="M447.043 916.395C448.735 916.876 448.54 915.109 446.586 912.212C444.631 909.314 441.298 906.093 439.632 905.559C437.965 905.026 438.388 907.317 440.478 910.35C442.567 913.383 445.393 915.914 447.043 916.395Z" fill="white" fill-opacity="0.6"/>
        <path d="M461.061 921.039C462.753 921.677 462.702 920.14 460.79 917.441C458.878 914.743 455.57 911.605 453.794 910.873C452.017 910.141 452.381 912.117 454.462 914.994C456.543 917.87 459.369 920.422 461.061 921.039Z" fill="white" fill-opacity="0.6"/>
        <path d="M475.181 926.018C476.915 926.677 476.78 925.254 474.842 922.734C472.787 920.046 469.563 917.19 467.804 916.458C466.044 915.726 466.442 917.504 468.548 920.234C470.655 922.964 473.489 925.38 475.181 926.018Z" fill="white" fill-opacity="0.6"/>
        <path d="M489.301 931.101C490.993 931.687 490.739 930.327 488.708 927.963C486.678 925.6 483.302 922.734 481.636 922.085C479.969 921.437 480.468 923.131 482.651 925.735C484.834 928.34 487.659 930.536 489.301 931.101Z" fill="white" fill-opacity="0.6"/>
        <path d="M503.361 936.038C505.011 936.571 504.715 935.285 502.642 933.026C500.569 930.766 497.152 928.152 495.519 927.566C493.886 926.98 494.453 928.612 496.678 931.007C498.903 933.402 501.728 935.525 503.361 936.038Z" fill="white" fill-opacity="0.6"/>
        <path d="M517.422 940.567C519.224 941.121 519.114 939.95 517.1 937.879C514.907 935.662 512.423 933.925 509.757 932.743C507.938 932.126 508.344 933.549 510.544 935.776C512.581 937.878 514.912 939.501 517.422 940.567Z" fill="white" fill-opacity="0.6"/>
        <path d="M532.379 944.311C534.257 944.73 534.19 943.548 532.168 941.602C529.926 939.488 527.383 937.913 524.672 936.958C522.777 936.477 523.115 937.889 525.332 939.971C527.434 941.963 529.827 943.436 532.379 944.311Z" fill="white" fill-opacity="0.6"/>
        <path d="M547.92 946.989C549.773 947.219 549.612 945.943 547.556 944.092C545.294 942.1 542.721 940.711 540.002 940.012C538.149 939.751 538.555 941.236 540.848 943.203C543.14 945.169 546.101 946.769 547.92 946.989Z" fill="white" fill-opacity="0.6"/>
        <path d="M563.318 948.422C565.272 948.589 565.314 947.376 563.368 945.566C561.117 943.611 558.532 942.319 555.814 941.791C553.817 941.581 554.003 943.025 556.177 944.928C558.313 946.745 560.755 947.939 563.318 948.422Z" fill="white" fill-opacity="0.6"/>
        <path d="M578.833 948.495C580.703 948.495 580.525 947.198 578.478 945.451C576.346 943.6 573.081 941.989 571.16 942.021C569.24 942.052 569.578 943.663 571.778 945.462C573.977 947.261 576.964 948.547 578.833 948.495Z" fill="white" fill-opacity="0.6"/>
        <path d="M593.884 948.453C595.762 948.39 595.728 947.01 593.723 945.242C591.718 943.474 588.08 941.864 586.168 941.947C584.256 942.031 584.628 943.684 586.853 945.472C589.078 947.261 592.082 948.505 593.884 948.453Z" fill="white" fill-opacity="0.6"/>
        <path d="M610.398 948.077C612.581 948.077 612.936 946.717 611.244 944.939C609.005 942.887 606.328 941.691 603.545 941.498C601.269 941.498 601.134 943.108 603.165 944.949C605.303 946.781 607.802 947.862 610.398 948.077Z" fill="white" fill-opacity="0.6"/>
        <path d="M628.57 947.365C630.914 947.365 631.54 945.922 629.949 944.113C627.746 941.962 625.039 940.751 622.234 940.661C619.789 940.661 619.357 942.376 621.252 944.238C623.385 946.158 625.933 947.246 628.57 947.365Z" fill="white" fill-opacity="0.6"/>
        <path d="M648.248 946.372C650.634 946.215 651.294 944.698 649.729 942.826C647.553 940.642 644.823 939.479 642.022 939.542C639.543 939.73 639.044 941.56 640.905 943.422C643.032 945.358 645.607 946.392 648.248 946.372Z" fill="white" fill-opacity="0.6"/>
        <path d="M668.4 945.19C670.938 945.096 671.928 943.569 670.667 941.665C669.407 939.761 665.938 938.078 663.239 938.193C660.541 938.308 659.669 940.128 661.251 942.052C663.256 944.118 665.791 945.231 668.4 945.19Z" fill="white" fill-opacity="0.6"/>
        <path d="M689.483 943.851C692.021 943.684 693.129 942.083 692.021 940.128C690.912 938.172 687.494 936.498 684.804 936.697C682.114 936.896 681.09 938.789 682.545 940.776C684 942.763 686.995 944.008 689.483 943.851Z" fill="white" fill-opacity="0.6"/>
        <path d="M710.633 942.439C713.247 942.345 714.6 940.755 713.763 938.768C712.925 936.781 709.82 935.002 706.995 935.118C704.169 935.233 702.875 937.136 704.034 939.165C705.193 941.194 708.044 942.544 710.633 942.439Z" fill="white" fill-opacity="0.6"/>
        <path d="M16.8399 936.216C17.2292 936.273 17.624 936.228 17.9981 936.083C18.3723 935.938 18.7172 935.696 19.01 935.374C19.3028 935.051 19.5368 934.656 19.6965 934.213C19.8562 933.77 19.938 933.291 19.9364 932.806C19.9698 931.721 19.6799 930.658 19.1216 929.819C18.5633 928.981 17.7755 928.424 16.9076 928.256C16.488 928.225 16.0677 928.303 15.6742 928.486C15.2806 928.668 14.9225 928.951 14.6232 929.316C14.324 929.681 14.0902 930.12 13.9372 930.604C13.7842 931.088 13.7153 931.607 13.7351 932.126C13.7674 933.156 14.0993 934.136 14.6698 934.888C15.2402 935.639 16.0108 936.111 16.8399 936.216Z" fill="white" fill-opacity="0.6"/>
        <path d="M30.7145 937.523C32.2797 937.659 33.3033 936.216 33.151 934.103C32.9988 931.99 31.5267 929.856 29.9785 929.731C29.6155 929.717 29.2544 929.802 28.9206 929.979C28.5869 930.156 28.2886 930.421 28.0467 930.756C27.8048 931.091 27.6252 931.488 27.5204 931.918C27.4157 932.348 27.3884 932.801 27.4405 933.245C27.4973 934.318 27.8555 935.333 28.4534 936.114C29.0512 936.895 29.851 937.394 30.7145 937.523Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.134 939.761C44.5976 940.086 45.5366 938.841 45.3336 936.854C45.2268 935.815 44.884 934.835 44.3449 934.028C43.8058 933.221 43.0925 932.619 42.2879 932.293C40.8666 931.938 39.8007 933.517 40.0799 935.599C40.2156 936.58 40.5751 937.493 41.1162 938.23C41.6572 938.968 42.3572 939.499 43.134 939.761Z" fill="white" fill-opacity="0.6"/>
        <path d="M54.978 942.711C56.3486 943.087 57.11 941.958 56.7716 940.086C56.5777 939.085 56.1899 938.157 55.6417 937.383C55.0934 936.609 54.4012 936.011 53.6245 935.64C52.2962 935.222 51.4502 936.686 51.8563 938.642C52.0563 939.591 52.4475 940.462 52.9934 941.174C53.5393 941.885 54.2221 942.414 54.978 942.711Z" fill="white" fill-opacity="0.6"/>
        <path d="M65.9761 946.194C67.3044 946.696 67.9896 945.765 67.5666 944.039C67.2956 943.075 66.8695 942.189 66.3141 941.434C65.7587 940.679 65.0854 940.071 64.3349 939.646C63.0574 939.071 62.3383 940.243 62.8121 942.115C63.071 943.036 63.4877 943.877 64.0338 944.581C64.5798 945.285 65.2424 945.835 65.9761 946.194Z" fill="white" fill-opacity="0.6"/>
        <path d="M77.1263 950.221C78.3784 950.514 78.6407 949.3 77.7185 947.459C76.8725 945.828 75.2904 944.154 74.2583 943.914C73.2262 943.673 72.837 944.782 73.4969 946.424C73.8655 947.325 74.3766 948.125 75 948.777C75.6235 949.43 76.3465 949.92 77.1263 950.221Z" fill="white" fill-opacity="0.6"/>
        <path d="M87.3293 954.122C88.6237 954.655 89.0721 953.923 88.3446 952.459C87.4138 950.738 86.1129 949.372 84.5883 948.516C83.3531 947.92 82.9555 948.819 83.7422 950.43C84.5958 952.094 85.8505 953.386 87.3293 954.122Z" fill="white" fill-opacity="0.6"/>
        <path d="M98.0481 958.201C99.3086 958.63 99.554 957.908 98.6149 956.528C97.493 954.968 96.0945 953.751 94.5287 952.971C93.4035 952.522 93.1751 953.264 94.0042 954.635C95.0586 956.264 96.4587 957.499 98.0481 958.201Z" fill="white" fill-opacity="0.6"/>
        <path d="M108.877 962.019C110.163 962.416 110.341 961.747 109.249 960.492C107.956 959.071 106.454 957.971 104.825 957.249C103.666 956.841 103.53 957.511 104.503 958.766C105.73 960.266 107.23 961.381 108.877 962.019Z" fill="white" fill-opacity="0.6"/>
        <path d="M120.044 965.418C121.44 965.795 121.652 965.209 120.493 964.09C119.041 962.776 117.419 961.779 115.696 961.14C114.342 960.701 114.274 961.391 115.501 962.615C116.853 963.907 118.396 964.859 120.044 965.418Z" fill="white" fill-opacity="0.6"/>
        <path d="M132.032 968.253C133.479 968.524 133.623 967.918 132.311 966.872C130.694 965.688 128.935 964.83 127.1 964.33C125.78 964.048 125.713 964.634 126.905 965.659C128.465 966.919 130.205 967.799 132.032 968.253Z" fill="white" fill-opacity="0.6"/>
        <path d="M145.027 968.796C143.329 967.767 141.515 967.062 139.646 966.705C138.047 966.464 137.954 967.112 139.401 968.127C141.117 969.277 142.995 970.012 144.934 970.292C146.44 970.428 146.507 969.769 145.027 968.796Z" fill="white" fill-opacity="0.6"/>
        <path d="M330.167 911.333C331.859 910.632 331.69 907.84 329.828 904.723C327.967 901.606 324.752 899.336 323.145 900.194C321.537 901.052 321.817 904.085 323.568 906.992C325.454 910.13 328.432 912.076 330.167 911.333Z" fill="white" fill-opacity="0.6"/>
        <path d="M315.928 916.824C317.621 916.061 317.544 913.331 315.759 910.392C313.974 907.453 310.819 905.434 309.16 906.344C307.502 907.254 307.79 910.601 309.719 913.404C311.648 916.207 314.296 917.588 315.928 916.824Z" fill="white" fill-opacity="0.6"/>
        <path d="M302.012 923.194C303.636 922.326 303.56 919.659 301.8 916.918C300.04 914.178 296.936 912.567 295.294 913.613C293.653 914.659 293.932 917.473 295.59 919.983C297.384 922.65 300.277 924.072 302.012 923.194Z" fill="white" fill-opacity="0.6"/>
        <path d="M288.002 930.191C289.694 929.417 289.812 926.959 288.188 924.459C286.564 921.96 283.56 920.338 281.818 921.228C280.075 922.117 280.202 925.045 281.987 927.503C283.772 929.961 286.335 930.955 288.002 930.191Z" fill="white" fill-opacity="0.6"/>
        <path d="M274.314 937.168C276.006 936.414 276.149 934.124 274.584 931.865C273.019 929.605 270.075 928.183 268.324 929.041C266.573 929.898 266.674 932.576 268.4 934.762C270.126 936.948 272.647 937.9 274.314 937.168Z" fill="white" fill-opacity="0.6"/>
        <path d="M260.862 943.715C262.503 942.962 262.554 940.86 260.972 938.831C259.39 936.801 256.471 935.693 254.821 936.509C253.172 937.324 253.324 939.824 255.05 941.738C256.776 943.652 259.263 944.447 260.862 943.715Z" fill="white" fill-opacity="0.6"/>
        <path d="M247.385 949.509C249.018 948.882 249.077 947.02 247.571 945.211C246.065 943.401 243.164 942.334 241.522 943.119C239.881 943.903 239.991 945.985 241.666 947.69C243.341 949.394 245.795 950.116 247.385 949.509Z" fill="white" fill-opacity="0.6"/>
        <path d="M233.917 954.404C235.558 953.704 235.609 952.02 234.086 950.399C232.563 948.777 229.644 947.93 228.012 948.735C226.379 949.541 226.413 951.382 227.868 952.846C228.718 953.647 229.703 954.203 230.749 954.473C231.796 954.742 232.878 954.719 233.917 954.404Z" fill="white" fill-opacity="0.6"/>
        <path d="M219.518 960.23C221.269 959.488 221.472 957.856 219.983 956.434C219.09 955.68 218.07 955.186 217.001 954.988C215.931 954.791 214.839 954.895 213.807 955.293C212.056 956.162 211.98 958.055 213.621 959.341C214.507 959.964 215.485 960.364 216.497 960.516C217.509 960.669 218.536 960.572 219.518 960.23Z" fill="white" fill-opacity="0.6"/>
        <path d="M204.602 966.014C206.413 965.366 206.667 963.922 205.195 962.73C203.28 961.43 201.029 961.111 198.926 961.841C197.115 962.594 196.988 964.173 198.63 965.251C200.492 966.339 202.599 966.608 204.602 966.014Z" fill="white" fill-opacity="0.6"/>
        <path d="M189.349 970.219C191.159 969.832 191.413 968.702 189.907 967.698C187.925 966.582 185.72 966.218 183.571 966.652C181.879 967.092 181.633 968.106 183.055 969.016C185.024 970.124 187.201 970.54 189.349 970.219Z" fill="white" fill-opacity="0.6"/>
        <path d="M173.546 971.38C175.483 971.526 175.965 970.857 174.62 969.978C172.632 968.921 170.505 968.315 168.343 968.19C166.372 968.064 166.025 968.891 167.565 969.79C169.48 970.7 171.498 971.236 173.546 971.38Z" fill="white" fill-opacity="0.6"/>
        <path d="M158.461 971.359C160.255 971.453 160.652 970.805 159.307 969.916C157.412 968.848 155.378 968.211 153.301 968.033C151.507 967.907 151.304 968.587 152.81 969.539C154.589 970.564 156.504 971.181 158.461 971.359Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.0890599 972.499C0.373643 972.975 0.770117 973.331 1.22434 973.518C1.67856 973.705 2.16841 973.715 2.62709 973.545C3.42675 973.271 4.17254 972.798 4.81829 972.154C5.39781 971.537 5.78504 970.69 5.91324 969.758C6.04143 968.826 5.90251 967.868 5.52044 967.05C5.13557 966.221 4.54892 965.563 3.84406 965.171C3.1392 964.778 2.35214 964.671 1.59499 964.864C1.22484 964.883 0.873155 965.069 0.606561 965.387C0.339966 965.705 0.17694 966.133 0.148287 966.59C0.0552258 968.619 0.0890601 970.7 0.0890599 972.499Z" fill="white" fill-opacity="0.6"/>
        <path d="M539.663 936.498C541.871 938.329 545.12 940.012 546.871 940.18C548.623 940.347 548.352 938.956 546.313 937.251C544.088 935.4 540.526 933.664 538.699 933.507C536.872 933.35 537.405 934.626 539.663 936.498Z" fill="white" fill-opacity="0.6"/>
        <path d="M524.148 933.569C526.373 935.515 529.596 937.398 531.313 937.753C533.031 938.109 532.726 936.707 530.679 934.929C528.373 932.949 525.792 931.509 523.065 930.683C521.398 930.348 521.88 931.582 524.148 933.569Z" fill="white" fill-opacity="0.6"/>
        <path d="M508.733 929.062C510.861 931.146 513.254 932.778 515.814 933.894C517.743 934.553 517.506 933.203 515.298 931.143C513.114 929.154 510.698 927.588 508.133 926.499C506.322 925.892 506.61 926.991 508.733 929.062Z" fill="white" fill-opacity="0.6"/>
        <path d="M494.453 923.79C496.508 925.976 498.822 927.761 501.314 929.083C503.133 929.867 502.828 928.497 500.612 926.29C498.495 924.201 496.158 922.477 493.666 921.165C491.982 920.433 492.363 921.573 494.453 923.79Z" fill="white" fill-opacity="0.6"/>
        <path d="M480.722 918.09C482.72 920.389 485.006 922.275 487.49 923.675C489.25 924.439 488.827 922.922 486.576 920.537C484.544 918.334 482.264 916.506 479.808 915.109C478.184 914.387 478.607 915.705 480.722 918.09Z" fill="white" fill-opacity="0.6"/>
        <path d="M466.983 912.18C469.081 914.722 472.059 917.337 473.7 918.006C475.341 918.675 474.969 917.19 473.015 914.868C470.9 912.327 467.575 909.482 465.934 908.781C464.293 908.08 464.843 909.586 466.983 912.18Z" fill="white" fill-opacity="0.6"/>
        <path d="M453.007 906.082C455.054 908.781 458.015 911.532 459.69 912.253C461.365 912.975 461.052 911.207 458.844 908.457C456.636 905.706 453.768 903.143 452.144 902.453C450.52 901.763 450.909 903.342 453.007 906.082Z" fill="white" fill-opacity="0.6"/>
        <path d="M439.065 900.445C441.104 903.311 444.082 906.135 445.706 906.72C447.33 907.306 447.06 905.559 445.156 902.892C443.1 900.016 439.809 896.91 438.151 896.282C436.493 895.655 437.017 897.527 439.065 900.445Z" fill="white" fill-opacity="0.6"/>
        <path d="M424.81 894.922C426.772 897.935 429.691 900.874 431.459 901.616C433.227 902.359 433.092 900.288 431.019 897.265C428.947 894.243 426.002 891.418 424.327 890.749C422.652 890.08 422.847 891.9 424.81 894.922Z" fill="white" fill-opacity="0.6"/>
        <path d="M410.554 890.414C412.492 893.552 415.393 896.544 417.195 897.223C418.997 897.903 418.888 895.623 416.866 892.464C414.928 889.463 411.883 886.429 410.182 885.844C408.482 885.258 408.609 887.256 410.554 890.414Z" fill="white" fill-opacity="0.6"/>
        <path d="M396.265 887.151C398.219 890.414 401.164 893.343 402.898 893.782C404.632 894.222 404.488 891.69 402.39 888.323C400.292 884.955 397.39 882.246 395.724 881.89C394.057 881.534 394.286 883.846 396.265 887.151Z" fill="white" fill-opacity="0.6"/>
        <path d="M381.816 885.216C383.812 888.563 386.807 891.366 388.448 891.492C390.089 891.617 389.878 888.95 388.025 885.791C386.003 882.34 382.721 879.233 381.02 879.192C379.32 879.15 379.768 881.796 381.816 885.216Z" fill="white" fill-opacity="0.6"/>
        <path d="M366.773 884.191C368.702 887.611 371.672 890.393 373.465 890.467C375.259 890.54 375.157 887.528 373.085 884.045C371.012 880.562 368.085 877.957 366.367 877.957C364.65 877.957 364.819 880.729 366.773 884.191Z" fill="white" fill-opacity="0.6"/>
        <path d="M351.858 884.39C353.779 887.768 356.714 890.446 358.491 890.488C360.268 890.529 360.183 887.413 358.11 883.919C356.038 880.426 353.127 877.884 351.427 878.02C349.726 878.156 349.913 880.97 351.858 884.39Z" fill="white" fill-opacity="0.6"/>
        <path d="M337.434 887.047C339.388 890.278 342.324 892.548 343.948 892.13C345.573 891.711 345.378 888.647 343.559 885.499C341.579 882.057 338.339 879.505 336.664 880.122C334.989 880.74 335.429 883.752 337.434 887.047Z" fill="white" fill-opacity="0.6"/>
        <path d="M555.069 937.847C557.231 939.632 559.693 940.788 562.269 941.226C564.223 941.341 563.961 939.741 561.668 937.962C559.481 936.266 557.016 935.195 554.451 934.825C552.624 934.793 552.903 936.069 555.069 937.847Z" fill="white" fill-opacity="0.6"/>
        <path d="M570.356 938.025C572.514 939.73 575.644 941.163 577.404 941.163C579.163 941.163 578.994 939.72 577.023 938.025C574.9 936.237 571.329 934.741 569.494 934.814C567.658 934.887 568.165 936.289 570.356 938.025Z" fill="white" fill-opacity="0.6"/>
        <path d="M586.084 937.67C588.27 939.488 590.794 940.59 593.419 940.87C595.593 940.87 595.728 939.27 593.613 937.492C591.367 935.719 588.787 934.699 586.126 934.532C584.028 934.532 584.053 935.944 586.084 937.67Z" fill="white" fill-opacity="0.6"/>
        <path d="M604.154 937.272C606.381 939.105 608.977 940.133 611.65 940.243C613.74 940.117 614.07 938.506 612.301 936.812C609.946 934.822 607.159 933.761 604.307 933.768C602.124 933.967 602.09 935.473 604.154 937.272Z" fill="white" fill-opacity="0.6"/>
        <path d="M623.638 936.31C625.844 938.221 628.473 939.244 631.167 939.238C633.401 939.05 633.959 937.377 632.318 935.651C629.98 933.582 627.154 932.527 624.281 932.649C621.954 932.911 621.709 934.532 623.638 936.31Z" fill="white" fill-opacity="0.6"/>
        <path d="M644.204 934.877C646.313 936.93 648.923 938.036 651.607 938.015C654.204 937.889 655.067 936.027 653.341 934.145C651.149 932.125 648.459 931.105 645.727 931.258C643.214 931.394 642.555 933.026 644.204 934.877Z" fill="white" fill-opacity="0.6"/>
        <path d="M665.887 933.381C667.326 935.253 670.515 936.655 673.078 936.519C675.642 936.383 676.86 934.427 675.337 932.534C673.814 930.641 670.557 929.396 667.952 929.605C665.346 929.815 664.424 931.478 665.887 933.381Z" fill="white" fill-opacity="0.6"/>
        <path d="M688.273 931.886C689.601 933.789 692.748 935.17 695.278 934.95C697.807 934.731 699 932.858 697.926 931.049C696.75 929.041 693.264 927.566 690.489 927.911C687.867 928.256 686.945 929.94 688.273 931.886Z" fill="white" fill-opacity="0.6"/>
        <path d="M710.76 930.107C711.825 932.063 714.787 933.517 717.35 933.339C719.913 933.162 721.343 931.363 720.522 929.438C719.702 927.514 716.377 925.819 713.56 926.102C710.886 926.374 709.677 928.12 710.76 930.107Z" fill="white" fill-opacity="0.6"/>
        <path d="M6.42554 922.587C6.40703 923.628 6.70847 924.638 7.26902 925.415C7.82957 926.191 8.60749 926.676 9.4458 926.771C9.87216 926.8 10.2988 926.72 10.6986 926.534C11.0984 926.349 11.4626 926.062 11.7681 925.693C12.0736 925.325 12.3138 924.881 12.4732 924.391C12.6327 923.902 12.7081 923.376 12.6945 922.849C12.6611 921.819 12.3214 920.841 11.7407 920.102C11.1599 919.362 10.3789 918.914 9.54731 918.843C9.14122 918.824 8.73633 918.908 8.357 919.088C7.97767 919.268 7.63173 919.541 7.34007 919.891C7.04841 920.241 6.81704 920.66 6.65987 921.124C6.5027 921.587 6.42299 922.085 6.42554 922.587Z" fill="white" fill-opacity="0.6"/>
        <path d="M20.5453 923.434C20.5573 924.451 20.8474 925.432 21.3682 926.218C21.889 927.005 22.6099 927.55 23.4133 927.765C23.7984 927.819 24.1884 927.763 24.5534 927.602C24.9184 927.441 25.2487 927.179 25.5191 926.836C25.7894 926.492 25.9925 926.077 26.1129 925.622C26.2333 925.166 26.2678 924.683 26.2136 924.208C26.1511 923.205 25.8257 922.253 25.2869 921.498C24.7481 920.744 24.025 920.226 23.2272 920.025C21.6874 919.784 20.503 921.29 20.5453 923.434Z" fill="white" fill-opacity="0.6"/>
        <path d="M33.3456 925.715C33.4399 926.697 33.7712 927.621 34.2963 928.367C34.8214 929.113 35.516 929.647 36.2897 929.898C37.6602 930.149 38.684 928.706 38.5063 926.76C38.3033 924.669 36.882 922.577 35.4268 922.347C33.9717 922.117 33.1341 923.602 33.3456 925.715Z" fill="white" fill-opacity="0.6"/>
        <path d="M44.9952 928.455C45.1155 929.425 45.4344 930.34 45.9215 931.114C46.4087 931.889 47.0479 932.496 47.7785 932.879C49.1491 933.423 50.1135 932.105 49.7836 930.16C49.6081 929.214 49.2558 928.333 48.7547 927.586C48.2537 926.84 47.618 926.249 46.8987 925.861C45.6128 925.359 44.7752 926.499 44.9952 928.455Z" fill="white" fill-opacity="0.6"/>
        <path d="M55.8239 932.283C56.0171 933.223 56.3748 934.099 56.8718 934.849C57.3688 935.599 57.9931 936.205 58.7004 936.624C59.9948 937.23 60.7731 936.08 60.2993 934.249C59.7852 932.429 58.7168 930.928 57.3214 930.066C56.1201 929.459 55.4517 930.453 55.8239 932.283Z" fill="white" fill-opacity="0.6"/>
        <path d="M66.0268 936.78C66.6149 938.576 67.7214 940.037 69.1316 940.881C70.3753 941.466 70.9167 940.41 70.2568 938.684C69.5752 936.968 68.4662 935.567 67.0928 934.689C65.9761 934.134 65.4938 935.044 66.0268 936.78Z" fill="white" fill-opacity="0.6"/>
        <path d="M75.832 941.477C76.5835 943.169 77.7345 944.537 79.1314 945.399C80.3666 946.027 80.8234 945.127 79.9774 943.537C79.1337 941.926 77.9682 940.611 76.5934 939.72C75.4936 939.113 75.1637 939.876 75.832 941.477Z" fill="white" fill-opacity="0.6"/>
        <path d="M85.5358 946.413C86.4732 947.976 87.7122 949.224 89.1398 950.043C90.2734 950.576 90.5695 949.865 89.7489 948.547C88.715 946.967 87.4161 945.682 85.9418 944.782C84.8589 944.227 84.6898 944.939 85.5358 946.413Z" fill="white" fill-opacity="0.6"/>
        <path d="M95.2733 951.152C96.3752 952.594 97.7057 953.741 99.1819 954.519C100.442 955.074 100.603 954.352 99.4864 953.034C98.3174 951.685 96.9769 950.583 95.5186 949.771C94.3934 949.175 94.2919 949.813 95.2733 951.152Z" fill="white" fill-opacity="0.6"/>
        <path d="M105.4 955.66C106.656 956.967 108.088 957.994 109.63 958.693C110.848 959.132 110.992 958.557 109.909 957.469C108.534 956.152 107.009 955.095 105.383 954.331C104.249 953.892 104.258 954.404 105.4 955.66Z" fill="white" fill-opacity="0.6"/>
        <path d="M116.068 959.634C117.489 960.834 119.057 961.742 120.713 962.322C122.007 962.699 122.126 962.134 120.933 961.151C119.395 959.971 117.736 959.053 116 958.421C114.748 958.002 114.791 958.515 116.068 959.634Z" fill="white" fill-opacity="0.6"/>
        <path d="M127.43 962.772C128.993 963.882 130.685 964.686 132.447 965.157C134.02 965.512 134.139 964.885 132.734 963.922C131.105 962.903 129.376 962.147 127.591 961.674C126.152 961.318 126.102 961.726 127.43 962.772Z" fill="white" fill-opacity="0.6"/>
        <path d="M140.01 965.083C141.73 966.094 143.569 966.761 145.458 967.06C147.15 967.248 147.277 966.548 145.687 965.659C143.912 964.729 142.041 964.109 140.129 963.818C138.589 963.65 138.555 964.173 140.01 965.083Z" fill="white" fill-opacity="0.6"/>
        <path d="M153.8 966.182C155.661 967.135 157.642 967.683 159.654 967.803C161.515 967.803 161.786 967.05 160.136 966.213C158.215 965.341 156.193 964.854 154.147 964.77C152.412 964.749 152.269 965.334 153.8 966.182Z" fill="white" fill-opacity="0.6"/>
        <path d="M169.045 966.182C170.971 967.137 173.036 967.59 175.111 967.51C177.048 967.343 177.319 966.234 175.661 965.345C173.698 964.459 171.6 964.123 169.519 964.362C167.691 964.613 167.497 965.334 169.045 966.182Z" fill="white" fill-opacity="0.6"/>
        <path d="M184.417 963.201C186.295 964.264 188.394 964.578 190.424 964.1C192.352 963.567 192.674 962.008 191.067 960.962C189.151 959.938 187.013 959.732 184.992 960.377C183.199 961.004 182.953 962.207 184.417 963.201Z" fill="white" fill-opacity="0.6"/>
        <path d="M199.704 957.323C201.525 958.512 203.647 958.786 205.626 958.086C207.471 957.312 207.631 955.409 205.956 954.174C205.039 953.583 204.032 953.233 202.999 953.149C201.966 953.064 200.931 953.246 199.958 953.683C198.266 954.582 198.148 956.151 199.704 957.323Z" fill="white" fill-opacity="0.6"/>
        <path d="M214.45 950.158C215.312 950.84 216.281 951.286 217.294 951.466C218.307 951.647 219.339 951.557 220.321 951.204C221.92 950.43 222.013 948.526 220.575 947.104C219.695 946.323 218.674 945.82 217.6 945.638C216.527 945.455 215.434 945.6 214.416 946.058C212.8 946.999 212.817 948.777 214.45 950.158Z" fill="white" fill-opacity="0.6"/>
        <path d="M228.409 943.318C230.017 944.876 232.571 945.681 234.103 945.075C235.634 944.468 235.634 942.533 234.103 940.891C232.571 939.249 229.661 938.37 228.096 939.176C226.531 939.981 226.717 941.728 228.409 943.318Z" fill="white" fill-opacity="0.6"/>
        <path d="M241.497 937.157C243.13 938.925 245.727 939.782 247.284 939.061C248.84 938.339 248.849 935.839 247.064 933.977C245.279 932.116 242.74 931.509 241.235 932.408C239.729 933.308 239.83 935.368 241.497 937.157Z" fill="white" fill-opacity="0.6"/>
        <path d="M254.635 929.71C256.327 931.729 258.865 932.712 260.557 931.948C262.249 931.185 262.25 928.413 260.422 926.3C258.595 924.187 256.023 923.445 254.5 924.407C252.977 925.369 252.943 927.691 254.635 929.71Z" fill="white" fill-opacity="0.6"/>
        <path d="M268.036 921.688C269.728 923.895 272.427 924.982 274.034 924.114C275.642 923.246 275.566 920.213 273.687 917.839C271.809 915.464 269.178 914.701 267.647 915.747C266.116 916.793 266.285 919.439 268.036 921.688Z" fill="white" fill-opacity="0.6"/>
        <path d="M281.471 913.247C283.239 915.715 285.929 917.002 287.579 916.165C289.229 915.328 289.144 912.128 287.232 909.544C285.32 906.961 282.638 905.915 281.082 906.961C279.525 908.007 279.686 910.768 281.471 913.247Z" fill="white" fill-opacity="0.6"/>
        <path d="M294.99 904.921C296.767 907.641 299.491 909.231 301.217 908.561C302.942 907.892 302.909 904.576 301.039 901.742C299.169 898.907 296.386 897.558 294.753 898.416C293.12 899.273 293.213 902.181 294.99 904.921Z" fill="white" fill-opacity="0.6"/>
        <path d="M308.949 897.673C310.793 900.549 313.619 902.307 315.269 901.627C316.918 900.947 316.834 897.924 315.099 894.985C313.247 891.847 310.108 889.891 308.424 890.801C306.741 891.711 307.088 894.734 308.949 897.673Z" fill="white" fill-opacity="0.6"/>
        <path d="M323.027 891.45C324.913 894.525 327.79 896.554 329.448 896.021C331.106 895.487 330.987 892.423 329.228 889.348C327.324 886.042 324.152 883.762 322.46 884.547C320.768 885.331 321.098 888.323 323.027 891.45Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.173731 947.428L0.17373 953.819C0.427682 954.228 0.768454 954.543 1.15823 954.727C1.548 954.912 1.97157 954.96 2.38185 954.865C3.32025 954.752 4.18999 954.207 4.81836 953.338C5.3624 952.605 5.67053 951.651 5.68456 950.657C5.69859 949.663 5.41752 948.696 4.89447 947.941C4.32074 947.254 3.62562 946.742 2.86408 946.445C2.38781 946.254 1.87583 946.245 1.3956 946.421C0.915366 946.596 0.48935 946.947 0.173731 947.428Z" fill="white" fill-opacity="0.6"/>
        <path d="M342.925 876.619C344.693 876.347 344.507 873.481 342.459 870.05C340.412 866.619 337.434 864.182 335.759 864.653C334.228 865.082 334.439 867.958 336.224 871.086C338.009 874.213 341.182 876.89 342.925 876.619Z" fill="white" fill-opacity="0.6"/>
        <path d="M350.699 863.565C348.855 863.565 349.007 866.274 350.979 869.694C352.814 872.832 355.716 875.604 357.467 875.855C359.413 876.137 359.472 873.46 357.552 869.987C355.632 866.515 352.561 863.597 350.699 863.565Z" fill="white" fill-opacity="0.6"/>
        <path d="M372.645 876.294C374.43 876.42 374.21 873.826 372.129 870.395C370.047 866.965 367.053 864.12 365.361 864.12C363.812 864.12 364.032 866.672 365.851 869.851C367.763 873.24 370.893 876.169 372.645 876.294Z" fill="white" fill-opacity="0.6"/>
        <path d="M387.264 878.323C389.049 878.679 388.837 876.347 386.773 873.01C384.709 869.674 381.697 866.734 380.005 866.483C378.465 866.253 378.668 868.575 380.47 871.64C382.272 874.704 385.521 877.978 387.264 878.323Z" fill="white" fill-opacity="0.6"/>
        <path d="M401.68 881.723C403.448 882.246 403.219 880.133 401.147 876.911C399.074 873.69 396.071 870.72 394.379 870.291C392.856 869.883 393.084 871.964 394.895 874.956C396.705 877.947 399.962 881.189 401.68 881.723Z" fill="white" fill-opacity="0.6"/>
        <path d="M415.918 886.252C417.661 886.89 417.398 884.976 415.3 881.89C413.202 878.805 410.224 875.855 408.532 875.28C407.026 874.757 407.297 876.629 409.125 879.463C410.952 882.298 414.217 885.634 415.918 886.252Z" fill="white" fill-opacity="0.6"/>
        <path d="M429.928 891.649C431.696 892.423 431.459 890.76 429.37 887.831C427.28 884.902 424.251 881.963 422.601 881.242C421.096 880.593 421.341 882.225 423.151 884.934C424.962 887.643 428.227 890.864 429.928 891.649Z" fill="white" fill-opacity="0.6"/>
        <path d="M443.938 897.652C445.63 898.437 445.325 896.868 443.176 894.096C441.027 891.324 438.016 888.511 436.408 887.768C434.936 887.099 435.266 888.605 437.144 891.178C439.022 893.751 442.263 896.878 443.938 897.652Z" fill="white" fill-opacity="0.6"/>
        <path d="M450.325 894.786C448.633 893.887 449.005 895.33 451.103 897.924C453.006 900.315 455.201 902.32 457.609 903.865C459.428 904.806 459.242 903.562 457.169 901.02C455.154 898.529 452.847 896.427 450.325 894.786Z" fill="white" fill-opacity="0.6"/>
        <path d="M463.895 901.794C462.304 900.989 462.812 902.422 464.986 904.869C467.161 907.317 470.215 909.89 471.754 910.538C473.294 911.187 472.786 909.9 470.756 907.641C468.695 905.315 466.387 903.348 463.895 901.794Z" fill="white" fill-opacity="0.6"/>
        <path d="M485.392 916.751C487.084 917.473 486.585 916.238 484.318 913.958C482.226 911.819 479.92 910.022 477.457 908.614C476.035 907.976 476.501 909.137 478.497 911.26C480.572 913.487 482.894 915.336 485.392 916.751Z" fill="white" fill-opacity="0.6"/>
        <path d="M499.258 922.598C501.094 923.319 500.823 922.221 498.598 920.119C496.406 918.063 494.002 916.379 491.449 915.109C489.884 914.471 490.214 915.485 492.152 917.42C494.305 919.562 496.699 921.306 499.258 922.598Z" fill="white" fill-opacity="0.6"/>
        <path d="M514.105 927.472C516.051 928.057 515.874 926.97 513.657 925.014C511.414 923.051 508.94 921.527 506.322 920.495C504.63 919.983 504.867 920.966 506.796 922.786C509.015 924.818 511.483 926.4 514.105 927.472Z" fill="white" fill-opacity="0.6"/>
        <path d="M529.706 931.122C531.601 931.478 531.313 930.243 529.021 928.382C526.776 926.554 524.282 925.243 521.66 924.512C520.011 924.219 520.315 925.328 522.329 927.053C524.562 928.964 527.064 930.343 529.706 931.122Z" fill="white" fill-opacity="0.6"/>
        <path d="M545.374 933.287C547.252 933.444 546.93 932.105 544.629 930.338C542.399 928.605 539.901 927.468 537.294 927.001C535.662 926.907 535.983 928.047 538.005 929.752C540.027 931.457 543.53 933.141 545.374 933.287Z" fill="white" fill-opacity="0.6"/>
        <path d="M560.89 933.8C562.658 933.695 562.133 932.293 559.798 930.578C557.616 928.978 554.68 927.723 552.946 927.733C551.211 927.744 551.431 929.145 553.639 930.871C555.847 932.597 559.121 933.894 560.89 933.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M568.571 927.566C566.592 927.629 566.879 929.124 569.054 930.808C571.228 932.492 574.553 933.789 576.456 933.664C578.36 933.538 578.148 932.199 576.126 930.526C573.856 928.753 571.254 927.733 568.571 927.566Z" fill="white" fill-opacity="0.6"/>
        <path d="M593.884 933.099C596.126 932.994 596.295 931.488 594.188 929.72C591.859 927.892 589.156 926.923 586.397 926.928C584.383 927.106 584.324 928.518 586.194 930.128C588.49 931.968 591.149 932.995 593.884 933.099Z" fill="white" fill-opacity="0.6"/>
        <path d="M613.139 932.137C615.618 932.011 616.117 930.474 614.196 928.675C611.868 926.737 609.109 925.743 606.303 925.83C604.053 926.018 603.664 927.461 605.356 929.114C607.651 931.051 610.36 932.103 613.139 932.137Z" fill="white" fill-opacity="0.6"/>
        <path d="M633.908 930.84C636.548 930.693 637.292 929.114 635.6 927.273C633.908 925.432 630.355 924.135 627.733 924.386C625.33 924.606 624.653 926.102 626.151 927.786C627.648 929.469 631.345 930.976 633.908 930.84Z" fill="white" fill-opacity="0.6"/>
        <path d="M650.651 922.681C647.817 922.817 646.751 924.439 648.248 926.279C649.746 928.12 652.893 929.354 655.448 929.302C658.316 929.239 659.534 927.733 658.214 925.851C656.894 923.968 653.485 922.535 650.651 922.681Z" fill="white" fill-opacity="0.6"/>
        <path d="M678.569 927.566C681.352 927.325 682.469 925.61 681.107 923.685C679.745 921.761 676.344 920.548 673.544 920.841C671.006 921.154 669.948 922.786 671.175 924.543C672.402 926.3 675.853 927.796 678.569 927.566Z" fill="white" fill-opacity="0.6"/>
        <path d="M701.665 925.756C704.448 925.495 705.709 923.665 704.482 921.74C703.255 919.816 700.024 918.519 697.198 918.895C694.609 919.23 693.425 920.924 694.508 922.723C695.591 924.522 698.915 926.018 701.665 925.756Z" fill="white" fill-opacity="0.6"/>
        <path d="M721.563 916.856C718.687 917.023 717.037 918.78 717.925 920.757C718.771 922.577 721.588 924.052 724.169 923.978C727.071 923.905 728.754 922.284 728.06 920.265C727.367 918.247 724.448 916.699 721.563 916.856Z" fill="white" fill-opacity="0.6"/>
        <path d="M16.3577 910.287C15.9705 910.246 15.5807 910.31 15.2154 910.474C14.8501 910.638 14.5179 910.898 14.2419 911.236C13.9659 911.574 13.7527 911.982 13.617 912.433C13.4812 912.883 13.4263 913.364 13.4559 913.843C13.4849 914.818 13.784 915.751 14.303 916.486C14.822 917.221 15.5294 917.713 16.3069 917.88C16.6834 917.958 17.0692 917.93 17.4357 917.798C17.8022 917.667 18.1399 917.434 18.4236 917.118C18.7074 916.803 18.93 916.412 19.0747 915.975C19.2194 915.539 19.2826 915.067 19.2595 914.596C19.2713 913.568 18.9852 912.569 18.453 911.778C17.9209 910.988 17.1777 910.459 16.3577 910.287Z" fill="white" fill-opacity="0.6"/>
        <path d="M29.5131 919.805C31.0021 920.066 31.9834 918.623 31.7465 916.542C31.6726 915.544 31.3444 914.602 30.8092 913.85C30.274 913.098 29.5594 912.575 28.7686 912.358C27.4488 912.17 26.4505 913.582 26.5605 915.496C26.6066 916.513 26.9199 917.484 27.4527 918.262C27.9855 919.04 28.7088 919.581 29.5131 919.805Z" fill="white" fill-opacity="0.6"/>
        <path d="M41.1796 922.796C42.5416 923.215 43.3623 921.991 43.0493 920.004C42.7362 918.016 41.4841 916.113 40.1813 915.736C38.8784 915.36 38.1678 916.594 38.337 918.445C38.445 919.422 38.7669 920.344 39.2679 921.11C39.7689 921.877 40.43 922.46 41.1796 922.796Z" fill="white" fill-opacity="0.6"/>
        <path d="M50.5703 920.025C49.4028 919.491 48.7091 920.684 49.0982 922.545C49.2899 923.48 49.6572 924.347 50.1701 925.073C50.6831 925.8 51.3274 926.368 52.0508 926.729C53.2014 927.127 53.7851 926.112 53.4129 924.376C53.2168 923.442 52.8618 922.571 52.3716 921.821C51.8813 921.07 51.2672 920.458 50.5703 920.025Z" fill="white" fill-opacity="0.6"/>
        <path d="M61.9913 931.237C63.1672 931.781 63.6156 930.808 63.015 929.061C62.4285 927.302 61.3631 925.852 60.0032 924.961C59.0049 924.48 58.5481 925.411 58.9964 927.053C59.5208 928.873 60.5939 930.372 61.9913 931.237Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.3906 936.122C72.575 936.822 72.981 936.122 72.3127 934.532C71.5706 932.858 70.4889 931.452 69.174 930.453C68.1842 929.835 67.8035 930.453 68.328 931.969C68.9858 933.704 70.0536 935.152 71.3906 936.122Z" fill="white" fill-opacity="0.6"/>
        <path d="M77.9302 936.09C76.9065 935.431 76.7034 936.163 77.4987 937.659C78.3593 939.234 79.5219 940.52 80.8827 941.403C81.8979 941.926 82.084 941.278 81.2973 939.918C80.3905 938.38 79.2461 937.079 77.9302 936.09Z" fill="white" fill-opacity="0.6"/>
        <path d="M90.2309 946.529C91.3561 947.083 91.3561 946.455 90.3409 945.096C89.2926 943.779 88.0992 942.653 86.7961 941.749C85.7217 941.069 85.6117 941.644 86.5339 943.004C87.5635 944.472 88.8205 945.671 90.2309 946.529Z" fill="white" fill-opacity="0.6"/>
        <path d="M99.8163 951.413C100.992 951.957 100.992 951.413 99.8163 950.179C98.5738 948.928 97.2096 947.874 95.7555 947.041C94.7825 946.539 94.8079 947.041 95.7555 948.181C96.9557 949.528 98.3278 950.62 99.8163 951.413Z" fill="white" fill-opacity="0.6"/>
        <path d="M109.875 955.785C111.186 956.318 111.254 955.869 109.985 954.791C108.581 953.645 107.084 952.685 105.518 951.926C104.427 951.444 104.418 951.831 105.459 952.825C106.811 954.063 108.298 955.06 109.875 955.785Z" fill="white" fill-opacity="0.6"/>
        <path d="M120.966 959.561C122.295 959.916 122.218 959.383 120.746 958.4C119.258 957.434 117.696 956.65 116.085 956.057C114.765 955.618 114.807 956.057 116.144 957.103C117.656 958.183 119.278 959.009 120.966 959.561Z" fill="white" fill-opacity="0.6"/>
        <path d="M132.811 962.322C134.325 962.594 134.342 962.05 132.811 961.172C131.11 960.231 129.323 959.55 127.489 959.142C126.203 958.902 126.254 959.341 127.557 960.188C129.22 961.197 130.988 961.915 132.811 962.322Z" fill="white" fill-opacity="0.6"/>
        <path d="M146.11 963.964C147.802 964.058 147.802 963.441 146.211 962.625C144.352 961.764 142.404 961.229 140.424 961.036C138.986 960.952 139.003 961.454 140.424 962.228C142.238 963.158 144.155 963.743 146.11 963.964Z" fill="white" fill-opacity="0.6"/>
        <path d="M328.847 879.85C330.218 879.014 329.786 875.96 327.849 872.926C325.759 869.653 322.866 867.697 321.292 868.628C319.719 869.559 320.285 873.01 322.358 875.949C324.431 878.888 327.341 880.771 328.847 879.85Z" fill="white" fill-opacity="0.6"/>
        <path d="M314.203 885.603C315.971 884.892 315.895 881.921 313.932 878.773C311.969 875.625 309.084 873.774 307.426 874.673C305.903 875.499 306.047 878.449 307.714 881.315C309.38 884.181 312.502 886.283 314.203 885.603Z" fill="white" fill-opacity="0.6"/>
        <path d="M300.26 892.789C302.037 891.941 301.952 889.023 300.083 886.063C298.213 883.103 295.337 881.534 293.687 882.559C292.173 883.511 292.266 886.388 293.882 889.086C295.497 891.785 298.56 893.615 300.26 892.789Z" fill="white" fill-opacity="0.6"/>
        <path d="M286.784 900.978C288.416 899.932 288.196 896.92 286.259 894.263C284.322 891.607 281.555 890.488 280.015 891.774C278.62 892.956 278.848 895.822 280.532 898.238C282.215 900.654 285.159 902.035 286.784 900.978Z" fill="white" fill-opacity="0.6"/>
        <path d="M273.324 909.816C274.914 908.676 274.677 905.821 272.765 903.415C270.853 901.01 268.138 900.183 266.64 901.533C265.278 902.777 265.532 905.486 267.198 907.651C268.865 909.816 271.759 910.946 273.324 909.816Z" fill="white" fill-opacity="0.6"/>
        <path d="M260.067 918.728C261.607 917.588 261.336 914.9 259.441 912.766C257.673 910.779 255.211 910.088 253.688 911.166C252.165 912.243 252.3 915.004 254.06 917.044C255.82 919.083 258.536 919.889 260.067 918.728Z" fill="white" fill-opacity="0.6"/>
        <path d="M240.727 920.474C239.136 921.52 239.238 923.884 240.93 925.704C242.622 927.524 245.033 928.204 246.573 927.409C248.265 926.52 248.341 924.271 246.674 922.179C245.008 920.087 242.334 919.439 240.727 920.474Z" fill="white" fill-opacity="0.6"/>
        <path d="M227.876 928.779C226.26 929.626 226.277 931.666 227.876 933.339C229.357 934.866 231.878 935.651 233.418 935.013C235.11 934.291 235.287 932.314 233.705 930.505C232.123 928.695 229.501 927.932 227.876 928.779Z" fill="white" fill-opacity="0.6"/>
        <path d="M220.575 941.351C222.208 940.452 222.123 938.548 220.398 937.042C218.672 935.536 216.083 935.117 214.535 936.163C212.987 937.209 213.173 938.998 214.67 940.347C215.525 941.066 216.503 941.531 217.527 941.706C218.552 941.88 219.595 941.759 220.575 941.351Z" fill="white" fill-opacity="0.6"/>
        <path d="M206.303 949.07C208.113 948.129 208.266 946.33 206.65 944.981C205.756 944.304 204.743 943.904 203.697 943.815C202.652 943.726 201.604 943.95 200.643 944.468C199.07 945.451 198.951 947.177 200.288 948.39C201.191 949.034 202.197 949.428 203.235 949.546C204.273 949.663 205.32 949.501 206.303 949.07Z" fill="white" fill-opacity="0.6"/>
        <path d="M191.659 956.496C193.461 955.639 193.562 954.017 191.879 952.94C190.919 952.407 189.882 952.122 188.83 952.102C187.778 952.082 186.734 952.328 185.762 952.825C184.18 953.735 184.07 955.168 185.534 956.12C187.467 957.182 189.65 957.316 191.659 956.496Z" fill="white" fill-opacity="0.6"/>
        <path d="M176.422 962.218C178.249 961.695 178.376 960.439 176.684 959.54C174.697 958.655 172.541 958.52 170.492 959.153C168.876 959.728 168.799 960.732 170.229 961.527C172.204 962.452 174.345 962.691 176.422 962.218Z" fill="white" fill-opacity="0.6"/>
        <path d="M160.729 964.278C162.675 964.351 162.979 963.63 161.397 962.824C159.392 961.974 157.29 961.529 155.17 961.506C153.478 961.506 153.242 962.082 154.578 962.814C156.557 963.684 158.63 964.178 160.729 964.278Z" fill="white" fill-opacity="0.6"/>
        <path d="M520.848 920.935C523.065 922.744 526.533 924.585 528.268 924.815C530.002 925.045 529.545 923.821 527.422 922.148C525.146 920.353 522.631 919.071 519.994 918.362C518.403 918.111 518.775 919.251 520.848 920.935Z" fill="white" fill-opacity="0.6"/>
        <path d="M505.375 917.159C507.539 919.002 509.914 920.433 512.422 921.405C514.317 921.97 513.987 920.767 511.694 918.874C509.507 917.076 507.126 915.668 504.622 914.69C502.752 914.094 503.116 915.234 505.375 917.159Z" fill="white" fill-opacity="0.6"/>
        <path d="M490.282 911.793C492.483 913.842 494.911 915.49 497.49 916.688C498.996 917.18 498.53 916.04 496.407 914.157C494.172 912.167 491.738 910.543 489.165 909.325C487.676 908.781 488.192 909.879 490.282 911.793Z" fill="white" fill-opacity="0.6"/>
        <path d="M476.289 905.34C478.33 907.393 480.555 909.148 482.922 910.569C484.614 911.406 484.081 910.203 481.771 908.007C479.687 906.011 477.445 904.28 475.079 902.84C473.429 901.93 474.013 903.154 476.289 905.34Z" fill="white" fill-opacity="0.6"/>
        <path d="M462.592 898.05C464.666 900.341 466.976 902.282 469.462 903.823C470.832 904.461 470.308 903.237 468.201 901.062C466.096 898.824 463.791 896.89 461.332 895.299C459.978 894.598 460.545 895.832 462.592 898.05Z" fill="white" fill-opacity="0.6"/>
        <path d="M454.479 893.542C452.5 891.278 450.314 889.308 447.965 887.674C446.366 886.733 446.907 888.176 449.115 890.749C451.065 893.055 453.256 895.029 455.629 896.617C457.271 897.506 456.746 896.094 454.479 893.542Z" fill="white" fill-opacity="0.6"/>
        <path d="M441.095 886.356C439.101 883.806 436.822 881.623 434.327 879.871C432.889 879.087 433.312 880.52 435.258 883.009C437.356 885.708 440.511 888.72 442.068 889.494C443.625 890.268 443.083 888.835 441.095 886.356Z" fill="white" fill-opacity="0.6"/>
        <path d="M421.527 875.96C423.617 878.836 426.789 881.942 428.295 882.601C429.801 883.26 429.293 881.618 427.322 878.972C425.19 876.137 422.06 873.052 420.554 872.32C419.048 871.588 419.607 873.313 421.527 875.96Z" fill="white" fill-opacity="0.6"/>
        <path d="M413.6 872.362C411.51 869.328 408.456 866.201 406.832 865.448C405.335 864.747 405.664 866.494 407.551 869.318C409.59 872.362 412.703 875.51 414.319 876.2C415.935 876.89 415.52 875.144 413.6 872.362Z" fill="white" fill-opacity="0.6"/>
        <path d="M393.837 864.193C395.834 867.184 398.727 870.061 400.267 870.604C401.807 871.148 401.468 869.025 399.277 865.814C397.238 862.812 394.404 859.946 392.864 859.35C391.325 858.754 391.688 860.971 393.837 864.193Z" fill="white" fill-opacity="0.6"/>
        <path d="M379.447 859.518C381.367 862.593 384.26 865.511 385.91 866.055C387.56 866.599 387.348 864.297 385.242 861.003C383.262 857.865 380.436 854.999 378.787 854.413C377.137 853.828 377.391 856.223 379.447 859.518Z" fill="white" fill-opacity="0.6"/>
        <path d="M364.921 856.463C366.824 859.601 369.734 862.478 371.418 862.885C373.102 863.293 372.941 860.794 370.86 857.363C368.905 854.162 366.071 851.297 364.379 850.868C362.687 850.439 362.924 853.075 364.921 856.463Z" fill="white" fill-opacity="0.6"/>
        <path d="M350.133 855.156C352.028 858.378 354.955 861.128 356.664 861.348C358.373 861.568 358.263 858.785 356.198 855.313C354.253 852.039 351.402 849.267 349.676 849.037C347.95 848.807 348.102 851.704 350.133 855.156Z" fill="white" fill-opacity="0.6"/>
        <path d="M335.234 855.554C337.223 858.974 340.387 861.683 342.002 861.421C343.618 861.16 343.271 858.419 341.402 855.146C339.38 851.642 336.241 848.87 334.634 849.142C333.026 849.414 333.39 852.374 335.234 855.554Z" fill="white" fill-opacity="0.6"/>
        <path d="M326.732 857.217C324.693 853.838 321.597 851.495 320.082 852.248C318.568 853.001 319.05 856.076 320.928 859.131C322.917 862.394 326.081 864.684 327.57 864.005C329.059 863.325 328.636 860.375 326.732 857.217Z" fill="white" fill-opacity="0.6"/>
        <path d="M536.626 923.225C538.775 924.874 541.168 925.977 543.665 926.468C545.543 926.625 545.171 925.213 542.87 923.476C540.718 921.871 538.332 920.805 535.848 920.338C533.995 920.161 534.359 921.499 536.626 923.225Z" fill="white" fill-opacity="0.6"/>
        <path d="M558.25 923.403C556.091 921.784 553.659 920.8 551.144 920.527C549.266 920.527 549.689 921.991 551.99 923.665C554.119 925.262 556.509 926.255 558.986 926.572C560.847 926.593 560.56 925.118 558.25 923.403Z" fill="white" fill-opacity="0.6"/>
        <path d="M575.839 922.838C573.594 921.14 571.039 920.172 568.411 920.025C566.245 920.077 566.329 921.615 568.529 923.319C570.752 924.987 573.269 925.963 575.864 926.164C578.021 926.164 578.047 924.585 575.839 922.838Z" fill="white" fill-opacity="0.6"/>
        <path d="M595.576 921.845C593.194 919.953 590.412 918.978 587.581 919.042C585.5 919.282 585.398 920.799 587.302 922.409C589.205 924.02 593.089 925.547 595.221 925.244C597.352 924.941 597.471 923.497 595.576 921.845Z" fill="white" fill-opacity="0.6"/>
        <path d="M617.115 920.548C614.782 918.513 611.974 917.48 609.12 917.609C606.752 917.807 606.219 919.334 607.902 920.987C610.231 922.979 613.006 924.018 615.838 923.957C618.164 923.759 618.756 922.242 617.115 920.548Z" fill="white" fill-opacity="0.6"/>
        <path d="M639.754 918.738C638.13 916.887 634.416 915.6 631.785 915.862C629.331 916.155 628.629 917.766 630.194 919.46C631.76 921.154 635.6 922.598 638.121 922.336C640.643 922.075 641.26 920.454 639.754 918.738Z" fill="white" fill-opacity="0.6"/>
        <path d="M663.493 916.918C662.14 914.983 658.679 913.613 655.82 913.864C653.206 914.094 652.123 915.726 653.417 917.462C654.712 919.198 658.333 920.757 661.031 920.527C663.645 920.318 664.737 918.696 663.493 916.918Z" fill="white" fill-opacity="0.6"/>
        <path d="M677.528 915.559C678.806 917.462 682.317 918.874 685.007 918.55C687.545 918.247 688.738 916.542 687.613 914.732C686.488 912.923 682.926 911.417 680.126 911.751C677.503 912.076 676.344 913.791 677.528 915.559Z" fill="white" fill-opacity="0.6"/>
        <path d="M712.012 912.756C711.089 910.726 707.942 909.283 705.032 909.555C702.359 909.796 700.904 911.521 701.809 913.341C702.799 915.318 706.039 916.793 708.805 916.552C711.419 916.322 712.858 914.659 712.012 912.756Z" fill="white" fill-opacity="0.6"/>
        <path d="M12.1784 904.796C12.2192 903.768 11.9448 902.758 11.4101 901.969C10.8753 901.18 10.1195 900.669 9.29354 900.539C8.91509 900.536 8.53994 900.626 8.18955 900.803C7.83917 900.979 7.52042 901.24 7.25163 901.569C6.98285 901.899 6.76928 902.291 6.62317 902.722C6.47706 903.154 6.40131 903.617 6.4002 904.085C6.37322 905.125 6.66949 906.138 7.22809 906.916C7.78669 907.694 8.56516 908.178 9.40352 908.268C9.77274 908.277 10.1395 908.193 10.4816 908.021C10.8237 907.849 11.134 907.593 11.3935 907.268C11.6531 906.943 11.8564 906.557 11.9914 906.132C12.1263 905.707 12.1899 905.252 12.1784 904.796Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.0887 906.72C25.021 904.629 23.8197 902.537 22.3645 902.286C20.9094 902.035 19.9196 903.447 19.9365 905.423C19.9376 906.436 20.2132 907.419 20.7193 908.216C21.2253 909.012 21.9327 909.575 22.7283 909.816C24.1073 910.046 25.1479 908.676 25.0887 906.72Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.5267 909.68C36.4228 908.775 36.1357 907.917 35.692 907.186C35.2483 906.454 34.6624 905.873 33.9886 905.497C32.7281 905.016 31.7805 906.417 31.9836 908.467C32.0802 909.39 32.3742 910.264 32.8352 910.999C33.2962 911.735 33.9075 912.305 34.6062 912.651C35.9091 913.059 36.7889 911.72 36.5267 909.68Z" fill="white" fill-opacity="0.6"/>
        <path d="M46.814 913.948C46.6438 913.073 46.331 912.251 45.8945 911.532C45.4581 910.812 44.9073 910.211 44.276 909.764C43.1508 909.199 42.4232 910.423 42.7532 912.379C42.9056 913.273 43.2189 914.114 43.67 914.838C44.1211 915.563 44.6983 916.152 45.3589 916.563C46.5179 917.065 47.2032 915.893 46.814 913.948Z" fill="white" fill-opacity="0.6"/>
        <path d="M52.4399 916.939C52.8735 918.788 53.8716 920.356 55.2232 921.311C56.2384 921.803 56.7038 920.872 56.2638 919.219C55.7869 917.408 54.8147 915.859 53.5143 914.837C52.5499 914.303 52.0592 915.255 52.4399 916.939Z" fill="white" fill-opacity="0.6"/>
        <path d="M65.0877 924.93C64.4453 923.211 63.4472 921.732 62.1944 920.642C61.2892 920.004 60.9338 920.757 61.4245 922.315C62.0093 924.078 63.0197 925.576 64.3178 926.604C65.2992 927.21 65.6376 926.457 65.0877 924.93Z" fill="white" fill-opacity="0.6"/>
        <path d="M73.5901 930.871C72.7695 929.278 71.7098 927.893 70.4683 926.792C69.6223 926.122 69.377 926.792 70.003 928.172C70.7632 929.826 71.8339 931.228 73.1248 932.262C74.047 932.879 74.25 932.262 73.5901 930.871Z" fill="white" fill-opacity="0.6"/>
        <path d="M81.957 936.812C80.9744 935.361 79.8359 934.084 78.573 933.015C77.727 932.377 77.6508 932.921 78.4291 934.228C79.3578 935.747 80.505 937.041 81.8132 938.046C82.7353 938.632 82.803 938.077 81.957 936.812Z" fill="white" fill-opacity="0.6"/>
        <path d="M87.0754 940.358C88.1343 941.653 89.3329 942.762 90.6371 943.652C91.6693 944.259 91.5762 943.652 90.4172 942.387C89.3097 941.164 88.1147 940.068 86.847 939.113C85.8826 938.454 85.9925 939.029 87.0754 940.358Z" fill="white" fill-opacity="0.6"/>
        <path d="M95.8147 945.723C97.0797 946.967 98.46 948.02 99.9263 948.861C100.933 949.363 100.848 948.861 99.7233 947.888C98.4119 946.706 97.0256 945.657 95.5778 944.75C94.6387 944.238 94.7403 944.656 95.8147 945.723Z" fill="white" fill-opacity="0.6"/>
        <path d="M109.554 952.469C108.156 951.463 106.699 950.586 105.197 949.844C104.021 949.29 104.156 949.698 105.459 950.744C106.818 951.817 108.264 952.711 109.774 953.411C111.026 953.934 110.941 953.484 109.554 952.469Z" fill="white" fill-opacity="0.6"/>
        <path d="M120.569 956.162C118.958 955.202 117.278 954.428 115.552 953.85C114.435 953.526 114.63 953.944 115.941 954.812C117.518 955.834 119.184 956.631 120.907 957.187C122.083 957.469 121.948 957.009 120.569 956.162Z" fill="white" fill-opacity="0.6"/>
        <path d="M127.633 957.783C129.368 958.722 131.193 959.379 133.064 959.739C134.401 959.916 134.359 959.404 132.929 958.693C131.16 957.809 129.315 957.178 127.43 956.81C126.136 956.496 126.246 956.998 127.633 957.783Z" fill="white" fill-opacity="0.6"/>
        <path d="M306.766 865.009C308.72 868.147 311.792 870.071 313.297 869.192C314.803 868.314 314.423 865.291 312.578 862.289C310.59 859.068 307.502 857.06 306.03 858.032C304.558 859.005 304.956 862.111 306.766 865.009Z" fill="white" fill-opacity="0.6"/>
        <path d="M298.755 869.621C296.817 866.536 293.856 864.831 292.308 865.94C290.76 867.048 291.157 870.123 292.909 872.832C294.804 875.782 297.782 877.445 299.33 876.441C300.878 875.437 300.54 872.466 298.755 869.621Z" fill="white" fill-opacity="0.6"/>
        <path d="M285.142 878.47C283.239 875.594 280.354 874.202 278.823 875.437C277.291 876.671 277.664 879.62 279.381 882.172C281.234 884.913 284.153 886.283 285.684 885.153C287.215 884.024 286.894 881.116 285.142 878.47Z" fill="white" fill-opacity="0.6"/>
        <path d="M271.792 888.406C269.931 885.75 267.122 884.599 265.583 885.896C264.043 887.193 264.39 889.986 266.065 892.318C267.875 894.839 270.709 895.979 272.258 894.807C273.806 893.636 273.501 890.843 271.792 888.406Z" fill="white" fill-opacity="0.6"/>
        <path d="M258.502 898.625C256.666 896.293 253.84 895.487 252.427 896.836C251.014 898.186 251.404 900.853 253.079 902.913C254.754 904.974 257.732 905.978 259.153 904.723C260.574 903.468 260.219 900.8 258.502 898.625Z" fill="white" fill-opacity="0.6"/>
        <path d="M245.406 908.844C243.713 906.898 241.175 906.26 239.72 907.348C238.265 908.436 238.443 911.187 240.236 913.132C241.928 914.941 244.466 915.632 245.913 914.649C247.36 913.665 247.25 910.915 245.406 908.844Z" fill="white" fill-opacity="0.6"/>
        <path d="M232.715 918.686C230.981 916.793 228.299 916.176 226.853 917.295C225.406 918.414 225.694 920.527 227.25 922.179C228.807 923.832 231.649 924.648 233.105 923.633C234.56 922.619 234.331 920.443 232.715 918.686Z" fill="white" fill-opacity="0.6"/>
        <path d="M219.949 927.106C218.257 925.432 215.626 924.846 214.196 925.777C212.766 926.708 213.029 928.518 214.543 929.961C216.057 931.404 218.858 932.21 220.296 931.363C221.734 930.515 221.489 928.643 219.949 927.106Z" fill="white" fill-opacity="0.6"/>
        <path d="M201.007 938.715C201.863 939.321 202.818 939.683 203.801 939.775C204.784 939.867 205.772 939.687 206.692 939.249C208.384 938.266 208.308 936.247 206.582 934.825C205.749 934.168 204.798 933.773 203.813 933.674C202.828 933.576 201.839 933.777 200.931 934.26C199.298 935.316 199.332 937.408 201.007 938.715Z" fill="white" fill-opacity="0.6"/>
        <path d="M186.557 947.805C187.466 948.307 188.447 948.579 189.443 948.604C190.438 948.629 191.428 948.407 192.352 947.951C194.044 946.905 194.044 945.096 192.352 943.914C191.455 943.375 190.472 943.088 189.475 943.074C188.478 943.059 187.49 943.317 186.583 943.83C184.865 944.918 184.857 946.738 186.557 947.805Z" fill="white" fill-opacity="0.6"/>
        <path d="M177.522 952.407C175.647 951.494 173.572 951.423 171.659 952.208C169.874 953.024 169.756 954.436 171.397 955.346C173.279 956.222 175.337 956.339 177.277 955.68C179.096 954.938 179.205 953.358 177.522 952.407Z" fill="white" fill-opacity="0.6"/>
        <path d="M155.957 959.435C157.858 960.246 159.874 960.563 161.879 960.366C163.724 960.084 163.867 959.017 162.184 958.191C160.278 957.405 158.253 957.166 156.262 957.49C154.451 957.825 154.308 958.661 155.957 959.435Z" fill="white" fill-opacity="0.6"/>
        <path d="M141.076 959.519C142.89 960.32 144.787 960.799 146.71 960.941C148.402 960.941 148.402 960.314 146.634 959.54C144.79 958.781 142.873 958.331 140.932 958.201C139.308 958.149 139.401 958.724 141.076 959.519Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.148299 928.926C0.0636978 931.143 -0.173167 933.329 0.325979 935.4C2.74557 936.299 4.01456 935.954 4.95363 934.176C5.34349 933.463 5.53091 932.609 5.48652 931.75C5.44212 930.891 5.16843 930.074 4.70831 929.428C4.22198 928.844 3.64688 928.385 3.01629 928.078C2.07721 927.618 1.20581 927.921 0.148299 928.926Z" fill="white" fill-opacity="0.6"/>
        <path d="M502.997 909.105C501.162 908.582 501.305 909.471 503.361 911.197C505.613 913.087 508.103 914.501 510.73 915.381C512.422 915.768 512.092 914.659 510.036 913.007C507.856 911.29 505.484 909.976 502.997 909.105Z" fill="white" fill-opacity="0.6"/>
        <path d="M488.133 906.166C490.272 908.063 492.605 909.6 495.07 910.737C496.932 911.448 496.602 910.329 494.343 908.436C492.178 906.633 489.846 905.158 487.397 904.043C485.697 903.394 486.027 904.304 488.133 906.166Z" fill="white" fill-opacity="0.6"/>
        <path d="M472.651 897.495C471.044 896.669 471.433 897.558 473.497 899.587C475.591 901.597 477.861 903.309 480.265 904.691C482.042 905.57 481.653 904.503 479.419 902.474C477.299 900.547 475.033 898.879 472.651 897.495Z" fill="white" fill-opacity="0.6"/>
        <path d="M459.09 889.808C457.491 888.762 457.787 889.64 459.775 891.732C461.888 893.963 464.192 895.902 466.645 897.516C468.108 898.322 467.651 897.297 465.638 895.278C463.585 893.228 461.394 891.398 459.09 889.808Z" fill="white" fill-opacity="0.6"/>
        <path d="M446.535 883.888C448.513 886.132 450.69 888.093 453.024 889.735C454.674 890.78 454.166 889.473 451.966 887.12C449.972 884.963 447.813 883.051 445.52 881.409C444.031 880.478 444.479 881.566 446.535 883.888Z" fill="white" fill-opacity="0.6"/>
        <path d="M432.212 873.01C430.63 871.964 430.918 873.01 432.872 875.489C434.881 878.004 437.158 880.168 439.64 881.921C441.087 882.737 440.647 881.461 438.667 879.129C436.704 876.802 434.539 874.75 432.212 873.01Z" fill="white" fill-opacity="0.6"/>
        <path d="M418.642 864.601C417.094 863.649 417.415 864.946 419.37 867.55C421.247 870.085 423.416 872.263 425.808 874.014C427.5 875.06 427.153 873.585 425.038 870.876C423.139 868.446 420.988 866.336 418.642 864.601Z" fill="white" fill-opacity="0.6"/>
        <path d="M404.954 856.631C403.431 855.784 403.778 857.321 405.741 860.114C407.703 862.906 410.554 865.793 412.145 866.672C413.735 867.55 413.439 865.877 411.299 863.042C409.158 860.208 406.468 857.457 404.954 856.631Z" fill="white" fill-opacity="0.6"/>
        <path d="M391.333 849.518C389.641 848.65 389.844 850.198 391.697 853.075C393.659 856.118 396.773 859.35 398.406 860.072C399.92 860.731 399.607 858.974 397.729 856.202C395.851 853.43 392.94 850.376 391.333 849.518Z" fill="white" fill-opacity="0.6"/>
        <path d="M377.095 843.264C375.589 842.762 375.961 844.822 377.941 847.897C379.92 850.972 382.738 853.817 384.32 854.393C385.902 854.968 385.546 852.677 383.423 849.571C381.299 846.464 378.592 843.776 377.095 843.264Z" fill="white" fill-opacity="0.6"/>
        <path d="M362.992 838.547C361.384 838.097 361.596 840.231 363.491 843.389C365.386 846.548 368.237 849.445 369.912 849.979C371.587 850.512 371.435 848.169 369.396 844.948C367.357 841.726 364.616 838.986 362.992 838.547Z" fill="white" fill-opacity="0.6"/>
        <path d="M348.542 835.472C346.85 835.179 347.002 837.501 348.855 840.701C350.708 843.902 353.593 846.778 355.319 847.176C357.044 847.573 357.011 845.01 354.989 841.695C352.967 838.379 350.192 835.754 348.542 835.472Z" fill="white" fill-opacity="0.6"/>
        <path d="M334.16 840.513C336.089 843.745 338.948 846.307 340.598 846.349C342.248 846.391 342.112 843.724 340.31 840.544C338.382 837.145 335.234 834.269 333.542 834.363C331.952 834.478 332.248 837.26 334.16 840.513Z" fill="white" fill-opacity="0.6"/>
        <path d="M319.668 842.866C321.571 846.004 324.389 848.18 325.996 847.855C327.603 847.531 327.409 844.718 325.624 841.58C323.712 838.233 320.607 835.702 318.957 836.246C317.308 836.79 317.739 839.687 319.668 842.866Z" fill="white" fill-opacity="0.6"/>
        <path d="M518.446 912.295C516.753 912.097 517.168 913.226 519.342 914.9C521.486 916.567 523.861 917.731 526.347 918.33C528.183 918.571 527.726 917.18 525.417 915.506C523.267 913.93 520.906 912.843 518.446 912.295Z" fill="white" fill-opacity="0.6"/>
        <path d="M534.173 913.55C532.371 913.477 532.523 914.596 534.528 916.186C536.533 917.776 540.027 919.324 541.812 919.324C543.462 919.324 543.123 918.006 541.101 916.458C538.986 914.89 536.624 913.898 534.173 913.55Z" fill="white" fill-opacity="0.6"/>
        <path d="M550.433 913.268C548.377 913.268 548.36 914.523 550.34 916.123C552.627 917.915 555.25 918.945 557.954 919.115C559.807 919.031 559.773 917.64 557.81 916.082C555.574 914.413 553.041 913.447 550.433 913.268Z" fill="white" fill-opacity="0.6"/>
        <path d="M568.876 915.705C571.146 917.398 573.738 918.319 576.388 918.372C578.512 918.257 578.808 916.803 576.981 915.234C574.583 913.391 571.809 912.433 568.986 912.473C566.812 912.567 566.812 914.042 568.876 915.705Z" fill="white" fill-opacity="0.6"/>
        <path d="M589.265 914.419C591.544 916.19 594.187 917.116 596.879 917.086C599.231 916.939 599.848 915.444 598.19 913.822C596.532 912.201 592.615 910.81 590.051 911.051C587.699 911.281 587.361 912.766 589.265 914.419Z" fill="white" fill-opacity="0.6"/>
        <path d="M612.944 909.293C610.406 909.565 609.738 911.092 611.472 912.787C613.743 914.652 616.43 915.588 619.154 915.464C621.836 915.276 622.648 913.477 620.795 911.772C618.452 909.953 615.706 909.086 612.944 909.293Z" fill="white" fill-opacity="0.6"/>
        <path d="M634.966 910.726C636.404 912.442 639.754 913.718 642.47 913.571C645.338 913.414 646.548 911.647 645.008 909.858C643.468 908.07 640.076 906.982 637.326 907.223C634.577 907.463 633.519 908.99 634.966 910.726Z" fill="white" fill-opacity="0.6"/>
        <path d="M662.317 904.963C659.483 905.235 658.29 906.836 659.585 908.614C660.879 910.392 664.119 911.647 666.886 911.469C669.813 911.27 671.217 909.377 669.813 907.61C668.409 905.842 665.126 904.691 662.317 904.963Z" fill="white" fill-opacity="0.6"/>
        <path d="M687.867 902.6C684.999 902.871 683.637 904.514 684.711 906.334C685.785 908.153 688.941 909.471 691.699 909.262C694.652 909.063 696.251 907.17 695.083 905.308C693.916 903.447 690.709 902.317 687.867 902.6Z" fill="white" fill-opacity="0.6"/>
        <path d="M713.331 900.225C710.514 900.539 709.042 902.254 710.007 904.116C710.971 905.978 713.932 907.254 716.682 907.045C719.566 906.804 721.275 904.817 720.209 902.934C719.143 901.052 716.14 899.922 713.331 900.225Z" fill="white" fill-opacity="0.6"/>
        <path d="M13.2022 895.749C13.237 896.7 13.5335 897.609 14.0427 898.324C14.5519 899.039 15.2433 899.518 16.0025 899.681C16.3471 899.719 16.6943 899.662 17.0191 899.515C17.3438 899.367 17.6381 899.132 17.8807 898.827C18.1234 898.522 18.3084 898.154 18.4225 897.751C18.5365 897.347 18.5768 896.917 18.5405 896.491C18.5323 895.486 18.2389 894.517 17.7117 893.753C17.1844 892.988 16.4568 892.477 15.6556 892.307C14.2005 892.182 13.1091 893.74 13.2022 895.749Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.6215 897.924C25.6742 898.849 25.9417 899.736 26.3919 900.478C26.8421 901.22 27.4558 901.786 28.1595 902.108C29.5047 902.547 30.503 901.156 30.283 899.169C30.209 898.252 29.9333 897.377 29.486 896.639C29.0386 895.902 28.4365 895.33 27.745 894.985C26.4421 894.734 25.52 896 25.6215 897.924Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.5435 901.805C36.6608 902.697 36.9483 903.542 37.3823 904.269C37.8163 904.997 38.3843 905.586 39.0392 905.988C40.1475 906.469 40.8751 905.413 40.6551 903.656C40.4351 901.899 39.2423 899.692 38.0748 899.19C36.9073 898.688 36.2982 899.911 36.5435 901.805Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.4485 904.524C46.4587 903.855 45.9003 904.712 46.1964 906.448C46.5161 908.229 47.3839 909.786 48.616 910.789C49.7158 911.511 50.308 910.569 49.9104 908.791C49.5098 907.062 48.6406 905.555 47.4485 904.524Z" fill="white" fill-opacity="0.6"/>
        <path d="M55.7817 910.475C54.8849 909.775 54.5211 910.475 54.9356 912.159C55.4251 913.836 56.309 915.293 57.4737 916.343C58.4889 917.086 58.8865 916.259 58.3197 914.596C57.7684 912.98 56.8953 911.562 55.7817 910.475Z" fill="white" fill-opacity="0.6"/>
        <path d="M63.531 917.033C62.685 916.333 62.5243 917.033 63.1418 918.539C63.7913 920.117 64.7264 921.488 65.8744 922.545C66.822 923.288 67.0165 922.545 66.2805 920.987C65.5653 919.474 64.6336 918.134 63.531 917.033Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.052 923.874C70.2059 923.121 70.1383 923.602 70.8659 924.993C71.6717 926.465 72.6694 927.762 73.8184 928.831C74.7829 929.626 74.8844 929.061 74.0299 927.66C73.1641 926.254 72.1642 924.983 71.052 923.874Z" fill="white" fill-opacity="0.6"/>
        <path d="M78.7846 931.697C79.7499 933.033 80.847 934.214 82.0502 935.212C82.8962 935.86 82.8962 935.421 81.9994 934.249C80.9544 932.913 79.8226 931.686 78.6154 930.578C77.7609 929.909 77.8202 930.348 78.7846 931.697Z" fill="white" fill-opacity="0.6"/>
        <path d="M86.6947 937.847C87.8034 939.077 89.0045 940.174 90.2818 941.121C91.3562 941.864 91.3224 941.498 90.1887 940.368C89.0269 939.219 87.8183 938.144 86.5678 937.147C85.6372 936.435 85.6964 936.728 86.6947 937.847Z" fill="white" fill-opacity="0.6"/>
        <path d="M95.0195 942.983C94.0466 942.397 94.2242 942.721 95.3917 943.746C96.6472 944.846 97.9744 945.815 99.3595 946.644C100.476 947.25 100.324 946.842 99.0211 945.807C97.7182 944.771 95.9924 943.579 95.0195 942.983Z" fill="white" fill-opacity="0.6"/>
        <path d="M104.537 948.045C103.42 947.543 103.556 947.836 104.808 948.735C106.213 949.733 107.685 950.58 109.207 951.267C110.476 951.79 110.383 951.403 108.987 950.482C107.544 949.561 106.058 948.747 104.537 948.045Z" fill="white" fill-opacity="0.6"/>
        <path d="M115.239 951.926C113.978 951.58 114.097 951.926 115.467 952.741C117.019 953.641 118.64 954.342 120.307 954.833C121.719 955.199 121.66 954.75 120.146 953.923C118.555 953.099 116.915 952.431 115.239 951.926Z" fill="white" fill-opacity="0.6"/>
        <path d="M305.396 848.012C307.215 851.035 309.998 853.001 311.631 852.478C313.264 851.956 313.145 848.619 311.166 845.46C309.186 842.302 306.487 840.534 304.93 841.277C303.374 842.019 303.535 844.958 305.396 848.012Z" fill="white" fill-opacity="0.6"/>
        <path d="M291.014 848.985C289.525 850.031 289.829 853.169 291.682 855.993C293.535 858.817 296.242 860.365 297.799 859.518C299.355 858.67 299.101 855.114 297.113 852.196C295.125 849.278 292.469 847.939 291.014 848.985Z" fill="white" fill-opacity="0.6"/>
        <path d="M277.689 858.524C276.115 859.57 276.225 862.551 277.934 865.27C279.736 868.147 282.647 869.579 284.229 868.408C285.811 867.237 285.455 864.224 283.713 861.536C281.97 858.848 279.237 857.457 277.689 858.524Z" fill="white" fill-opacity="0.6"/>
        <path d="M264.466 869.538C262.918 870.751 263.028 873.617 264.703 876.116C266.378 878.616 269.322 879.924 270.879 878.637C272.308 877.455 272.08 874.391 270.371 871.933C268.662 869.475 265.98 868.356 264.466 869.538Z" fill="white" fill-opacity="0.6"/>
        <path d="M251.488 881.283C249.965 882.538 250.05 885.258 251.683 887.559C253.316 889.86 256.209 890.906 257.74 889.578C259.271 888.249 258.95 885.446 257.283 883.197C255.617 880.949 252.943 880.049 251.488 881.283Z" fill="white" fill-opacity="0.6"/>
        <path d="M238.468 893.343C237.115 894.713 237.411 897.317 239.137 899.263C240.862 901.208 243.367 901.784 244.788 900.591C246.209 899.399 245.964 896.272 244.103 894.316C242.241 892.36 239.822 891.973 238.468 893.343Z" fill="white" fill-opacity="0.6"/>
        <path d="M225.998 904.524C224.56 905.716 224.687 908.007 226.277 909.754C227.868 911.5 230.347 912.159 231.844 911.155C233.341 910.151 233.299 907.421 231.556 905.539C229.814 903.656 227.411 903.342 225.998 904.524Z" fill="white" fill-opacity="0.6"/>
        <path d="M213.57 914.659C212.098 915.705 212.124 917.63 213.63 919.23C215.135 920.83 217.86 921.552 219.349 920.516C220.837 919.481 220.584 917.378 219.036 915.789C217.487 914.199 215.009 913.613 213.57 914.659Z" fill="white" fill-opacity="0.6"/>
        <path d="M200.922 923.497C199.366 924.543 199.281 926.457 200.745 927.88C201.578 928.619 202.553 929.079 203.573 929.213C204.594 929.346 205.625 929.15 206.565 928.643C208.012 927.66 207.944 925.694 206.438 924.282C205.652 923.573 204.731 923.129 203.764 922.991C202.797 922.854 201.818 923.028 200.922 923.497Z" fill="white" fill-opacity="0.6"/>
        <path d="M187.031 938.14C187.916 938.682 188.89 938.964 189.878 938.964C190.865 938.964 191.84 938.682 192.725 938.14C194.247 937.094 194.273 935.107 192.784 933.956C191.878 933.325 190.854 932.999 189.815 933.012C188.775 933.025 187.757 933.376 186.862 934.03C185.347 935.191 185.423 937.042 187.031 938.14Z" fill="white" fill-opacity="0.6"/>
        <path d="M172.378 943.945C170.729 944.991 170.61 946.539 172.133 947.491C173.979 948.456 176.058 948.482 177.92 947.564C179.705 946.581 179.815 944.709 178.165 943.715C177.239 943.26 176.248 943.046 175.252 943.086C174.257 943.125 173.278 943.418 172.378 943.945Z" fill="white" fill-opacity="0.6"/>
        <path d="M157.294 951.8C155.551 952.459 155.323 953.515 156.778 954.31C158.644 955.165 160.67 955.342 162.615 954.823C164.502 954.237 164.756 952.846 163.157 951.988C161.264 951.187 159.218 951.121 157.294 951.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M141.871 955.21C140.061 955.21 139.849 955.712 141.27 956.413C143.224 957.273 145.279 957.733 147.353 957.772C149.045 957.772 149.214 956.988 147.759 956.298C145.856 955.552 143.868 955.185 141.871 955.21Z" fill="white" fill-opacity="0.6"/>
        <path d="M127.523 954.404C126.076 954.227 126.11 954.645 127.523 955.388C129.224 956.23 131.003 956.806 132.819 957.103C134.418 957.291 134.511 956.737 132.878 955.994C131.144 955.229 129.349 954.696 127.523 954.404Z" fill="white" fill-opacity="0.6"/>
        <path d="M114.571 948.641C116.223 949.501 117.953 950.112 119.723 950.461C120.983 950.639 120.975 950.2 119.723 949.53C118.026 948.723 116.265 948.138 114.469 947.784C113.132 947.564 113.208 947.92 114.571 948.641Z" fill="white" fill-opacity="0.6"/>
        <path d="M127.946 950.137C129.77 950.994 131.705 951.434 133.656 951.434C135.188 951.329 135.348 950.555 134.046 949.886C132.156 949.118 130.169 948.788 128.183 948.913C126.592 949.028 126.542 949.457 127.946 950.137Z" fill="white" fill-opacity="0.6"/>
        <path d="M143.352 947.219C145.218 948.084 147.265 948.147 149.164 947.397C150.661 946.696 150.805 945.305 149.477 944.541C147.598 943.69 145.536 943.69 143.656 944.541C142.074 945.357 141.939 946.445 143.352 947.219Z" fill="white" fill-opacity="0.6"/>
        <path d="M158.614 938.904C159.476 939.36 160.408 939.583 161.346 939.558C162.285 939.533 163.208 939.26 164.053 938.757C165.746 937.711 165.915 935.735 164.443 934.72C163.525 934.234 162.526 934.027 161.53 934.114C160.534 934.201 159.569 934.581 158.715 935.222C157.218 936.425 157.159 937.973 158.614 938.904Z" fill="white" fill-opacity="0.6"/>
        <path d="M173.157 927.514C174.015 928.117 174.99 928.422 175.977 928.396C176.965 928.371 177.928 928.015 178.766 927.367C179.084 927.157 179.351 926.846 179.541 926.467C179.731 926.088 179.837 925.653 179.847 925.207C179.857 924.761 179.772 924.32 179.6 923.928C179.428 923.536 179.175 923.207 178.867 922.974C178.017 922.349 177.041 922.035 176.052 922.071C175.063 922.106 174.103 922.488 173.283 923.173C171.811 924.501 171.727 926.363 173.157 927.514Z" fill="white" fill-opacity="0.6"/>
        <path d="M186.845 915.538C187.623 916.257 188.551 916.688 189.519 916.783C190.488 916.877 191.459 916.63 192.319 916.071C193.647 915.025 193.638 912.933 192.319 911.594C191.562 910.844 190.641 910.387 189.675 910.283C188.708 910.179 187.738 910.431 186.887 911.009C185.491 912.18 185.457 914.157 186.845 915.538Z" fill="white" fill-opacity="0.6"/>
        <path d="M199.552 905.361C201.066 906.856 203.258 907.264 204.628 906.271C205.999 905.277 205.872 902.777 204.315 901.187C202.758 899.598 200.246 899.326 198.977 900.623C197.708 901.92 198.021 903.855 199.552 905.361Z" fill="white" fill-opacity="0.6"/>
        <path d="M211.633 893.981C213.274 895.811 215.753 896.219 217.073 894.849C218.392 893.479 218.054 891.042 216.599 889.337C215.008 887.475 212.453 887.13 211.151 888.574C209.848 890.017 210.102 892.266 211.633 893.981Z" fill="white" fill-opacity="0.6"/>
        <path d="M224.086 881.451C225.702 883.271 228.037 883.72 229.365 882.434C230.693 881.147 230.457 877.999 228.765 876.043C227.073 874.087 224.788 873.795 223.485 875.165C222.039 876.692 222.301 879.422 224.086 881.451Z" fill="white" fill-opacity="0.6"/>
        <path d="M236.522 867.99C238.155 870.081 240.549 870.646 241.911 869.37C243.274 868.094 243.037 864.716 241.319 862.53C239.602 860.344 237.258 859.863 235.922 861.243C234.441 862.77 234.695 865.709 236.522 867.99Z" fill="white" fill-opacity="0.6"/>
        <path d="M249.407 854.842C251.158 856.997 253.637 857.604 254.855 856.181C256.074 854.759 255.701 851.213 253.832 848.933C251.962 846.653 249.602 846.151 248.375 847.625C247.148 849.1 247.47 852.447 249.407 854.842Z" fill="white" fill-opacity="0.6"/>
        <path d="M261.936 841.444C263.738 844.195 266.429 845.335 267.858 843.986C269.288 842.636 268.874 839.582 267.275 837.061C265.532 834.321 262.765 833.129 261.353 834.541C259.94 835.953 260.253 838.934 261.936 841.444Z" fill="white" fill-opacity="0.6"/>
        <path d="M275.295 830.263C277.038 832.951 279.525 834.321 281.031 833.401C282.537 832.48 282.283 828.987 280.472 826.163C278.662 823.339 276.166 822.042 274.728 823.025C273.121 824.113 273.374 827.303 275.295 830.263Z" fill="white" fill-opacity="0.6"/>
        <path d="M288.865 820.891C290.624 823.736 293.213 825.441 294.711 824.803C296.208 824.165 296.013 820.766 294.169 817.785C292.325 814.804 289.787 813.151 288.315 813.852C286.665 814.637 286.902 817.743 288.865 820.891Z" fill="white" fill-opacity="0.6"/>
        <path d="M103.2 945.388C104.631 946.253 106.113 946.98 107.633 947.564C108.885 948.003 108.809 947.648 107.439 946.863C105.902 946.014 104.326 945.281 102.718 944.667C101.703 944.29 101.923 944.583 103.2 945.388Z" fill="white" fill-opacity="0.6"/>
        <path d="M93.5219 940.609C94.8116 941.551 96.1387 942.414 97.4982 943.192C98.5642 943.767 98.395 943.474 97.109 942.617C95.8231 941.759 93.835 940.525 92.879 939.96C91.923 939.395 92.2868 939.709 93.5219 940.609Z" fill="white" fill-opacity="0.6"/>
        <path d="M85.0196 934.532C86.1787 935.578 87.8707 936.99 88.7505 937.67C89.6304 938.349 89.3512 937.994 88.2852 937.115L84.512 933.977C83.7421 933.371 83.9367 933.59 85.0196 934.532Z" fill="white" fill-opacity="0.6"/>
        <path d="M77.634 927.744C78.5985 928.79 79.9775 930.285 80.8066 931.059C81.6357 931.833 81.4918 931.593 80.4427 930.515C79.3937 929.438 77.8201 927.827 77.0587 927.064C76.2973 926.3 76.6442 926.635 77.634 927.744Z" fill="white" fill-opacity="0.6"/>
        <path d="M70.849 920.318C71.7342 921.593 72.6806 922.801 73.6831 923.936C74.4614 924.773 74.3345 924.428 73.4124 923.225C72.4902 922.022 71.255 920.464 70.5613 919.617C69.8676 918.77 69.9184 918.989 70.849 920.318Z" fill="white" fill-opacity="0.6"/>
        <path d="M64.3687 912.63C65.1088 914.018 65.959 915.31 66.9067 916.489C67.6427 917.326 67.5919 916.866 66.7967 915.538C66.0055 914.197 65.1583 912.909 64.2587 911.678C63.5311 910.726 63.5819 911.145 64.3687 912.63Z" fill="white" fill-opacity="0.6"/>
        <path d="M57.8544 904.995C58.4587 906.478 59.2496 907.834 60.1979 909.011C60.9339 909.816 60.9931 909.199 60.3248 907.725C59.6691 906.277 58.886 904.923 57.9898 903.687C57.2707 902.788 57.203 903.363 57.8544 904.995Z" fill="white" fill-opacity="0.6"/>
        <path d="M50.9255 897.537C51.3822 899.121 52.1292 900.553 53.1082 901.721C53.9542 902.537 54.1657 901.836 53.6581 900.267C53.1453 898.72 52.4085 897.303 51.4838 896.083C50.6886 895.152 50.4348 895.832 50.9255 897.537Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.3284 890.833C43.6506 892.493 44.3913 893.984 45.4434 895.09C46.2895 895.811 46.7633 894.922 46.3741 893.228C45.9982 891.598 45.2733 890.123 44.2759 888.96C43.3876 888.145 42.9646 888.971 43.3284 890.833Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.6737 884.955C34.7619 885.811 34.9941 886.635 35.3555 887.373C35.7168 888.112 36.1993 888.749 36.7718 889.243C37.7701 889.923 38.4638 888.908 38.2016 887.151C37.9393 885.394 36.9156 883.26 35.9427 882.716C34.9698 882.172 34.4707 883.145 34.6737 884.955Z" fill="white" fill-opacity="0.6"/>
        <path d="M24.6487 880.352C24.7502 882.361 25.85 884.306 27.026 884.683C28.2019 885.059 28.8788 883.856 28.718 882.12C28.5573 880.384 27.5167 878.156 26.383 877.738C25.2494 877.319 24.5218 878.48 24.6487 880.352Z" fill="white" fill-opacity="0.6"/>
        <path d="M12.9313 877.539C12.9474 878.434 13.1953 879.299 13.6384 880.007C14.0815 880.714 14.6966 881.228 15.3933 881.472C16.7299 881.785 17.8466 880.269 17.7282 878.334C17.7 877.444 17.4523 876.586 17.0182 875.876C16.5842 875.165 15.9846 874.636 15.3002 874.359C13.9042 874.004 12.8467 875.416 12.9313 877.539Z" fill="white" fill-opacity="0.6"/>
        <path d="M701.97 887.308C702.816 889.097 705.641 890.32 708.467 890.163C711.293 890.006 713.306 888.134 712.494 886.304C711.682 884.473 708.382 883.166 705.413 883.522C702.579 883.888 701.098 885.509 701.97 887.308Z" fill="white" fill-opacity="0.6"/>
        <path d="M675.1 890.331C676.369 892.015 679.61 893.092 682.308 892.736C685.176 892.36 686.614 890.425 685.303 888.678C683.992 886.931 680.836 885.948 678.044 886.304C675.007 886.691 673.704 888.511 675.1 890.331Z" fill="white" fill-opacity="0.6"/>
        <path d="M648.028 892.789C649.323 894.452 652.529 895.602 655.295 895.393C658.062 895.184 659.678 893.301 658.392 891.596C657.106 889.891 653.857 888.804 651.04 889.044C647.935 889.316 646.624 890.948 648.028 892.789Z" fill="white" fill-opacity="0.6"/>
        <path d="M621.743 895.309C623.198 896.941 626.489 898.081 629.188 897.893C631.886 897.705 633.282 895.916 631.827 894.222C630.372 892.527 627.056 891.481 624.298 891.69C621.235 891.931 620.11 893.5 621.743 895.309Z" fill="white" fill-opacity="0.6"/>
        <path d="M596.422 897.495C598.114 899.242 601.709 900.434 604.357 900.131C606.768 899.849 607.64 898.28 606.219 896.742C604.67 895.048 600.889 893.835 598.173 894.138C595.457 894.441 594.84 895.832 596.422 897.495Z" fill="white" fill-opacity="0.6"/>
        <path d="M572.734 899.441C575.069 901.317 577.82 902.242 580.602 902.087C582.843 901.846 583.444 900.34 581.871 898.834C580.179 897.171 576.397 895.958 573.876 896.219C571.355 896.481 571.042 897.84 572.734 899.441Z" fill="white" fill-opacity="0.6"/>
        <path d="M551.33 901.062C553.54 902.688 556.059 903.561 558.631 903.593C560.881 903.468 561.169 901.867 559.223 900.288C556.97 898.666 554.411 897.808 551.804 897.799C549.375 897.987 549.198 899.409 551.33 901.062Z" fill="white" fill-opacity="0.6"/>
        <path d="M532.041 901.888C534.147 903.48 536.532 904.424 538.995 904.639C541.059 904.639 541.195 903.196 539.223 901.627C536.969 899.946 534.401 899.016 531.77 898.928C529.909 899.075 530.053 900.33 532.041 901.888Z" fill="white" fill-opacity="0.6"/>
        <path d="M515.747 901.92C517.879 903.562 521.017 904.974 522.633 904.974C524.105 904.974 523.801 903.656 521.931 902.212C519.821 900.63 517.456 899.638 515.002 899.305C513.454 899.284 513.759 900.382 515.747 901.92Z" fill="white" fill-opacity="0.6"/>
        <path d="M500.375 899.964C502.422 901.567 504.673 902.734 507.033 903.415C508.784 903.76 508.454 902.537 506.364 900.936C504.31 899.396 502.071 898.267 499.732 897.589C497.871 897.202 498.141 898.207 500.375 899.964Z" fill="white" fill-opacity="0.6"/>
        <path d="M484.487 895.56C486.637 897.371 488.992 898.781 491.475 899.744C492.998 900.162 492.685 899.169 490.815 897.652C488.64 895.899 486.284 894.517 483.81 893.542C482.211 893.029 482.49 893.887 484.487 895.56Z" fill="white" fill-opacity="0.6"/>
        <path d="M469.022 889.306C471.16 891.237 473.478 892.845 475.925 894.096C477.44 894.745 477.169 893.876 475.325 892.255C473.159 890.385 470.839 888.804 468.404 887.538C466.797 886.806 467.068 887.549 469.022 889.306Z" fill="white" fill-opacity="0.6"/>
        <path d="M454.623 881.576C456.702 883.568 458.925 885.319 461.264 886.806C462.634 887.559 462.211 886.67 460.333 884.923C458.216 882.968 455.978 881.22 453.641 879.694C452.203 878.888 452.601 879.662 454.623 881.576Z" fill="white" fill-opacity="0.6"/>
        <path d="M441.518 872.822C443.438 874.82 445.48 876.632 447.626 878.24C449.073 879.223 448.472 878.177 446.366 876.148C444.419 874.208 442.382 872.409 440.266 870.761C438.752 869.6 439.302 870.521 441.518 872.822Z" fill="white" fill-opacity="0.6"/>
        <path d="M428.261 863.262C430.149 865.404 432.181 867.342 434.336 869.056C435.816 870.102 435.266 869.056 433.219 866.787C431.306 864.701 429.282 862.775 427.161 861.024C425.605 859.842 426.079 860.815 428.261 863.262Z" fill="white" fill-opacity="0.6"/>
        <path d="M415.004 853.619C416.85 855.887 418.873 857.923 421.045 859.695C422.534 860.741 422.026 859.549 420.004 857.164C418.132 854.944 416.118 852.915 413.981 851.098C412.415 849.905 412.838 851.004 415.004 853.619Z" fill="white" fill-opacity="0.6"/>
        <path d="M401.451 843.902C403.318 846.439 405.439 848.671 407.762 850.543C409.082 851.401 408.676 850.167 406.916 847.866C405.011 845.371 402.889 843.143 400.588 841.224C399.218 840.252 399.582 841.444 401.451 843.902Z" fill="white" fill-opacity="0.6"/>
        <path d="M388.144 835.231C389.885 837.734 391.912 839.911 394.159 841.695C395.69 842.741 395.284 841.151 393.313 838.484C391.543 836.028 389.53 833.856 387.323 832.02C385.724 830.901 386.071 832.302 388.144 835.231Z" fill="white" fill-opacity="0.6"/>
        <path d="M374.354 827.021C376.325 830.012 379.201 833.087 380.657 833.819C381.985 834.488 381.621 832.857 379.887 830.273C377.983 827.449 375.039 824.238 373.584 823.443C372.129 822.648 372.509 824.228 374.354 827.021Z" fill="white" fill-opacity="0.6"/>
        <path d="M360.598 820.421C362.433 823.307 365.09 826.163 366.613 826.916C368.136 827.669 367.873 825.713 365.97 822.732C364.066 819.751 361.469 817 359.972 816.216C358.305 815.4 358.567 817.231 360.598 820.421Z" fill="white" fill-opacity="0.6"/>
        <path d="M346.283 814.961C348.195 818.182 351.097 821.236 352.628 821.623C354.033 822.01 353.77 819.971 352.078 817.157C350.243 814.103 347.273 810.945 345.733 810.463C344.194 809.982 344.49 811.949 346.283 814.961Z" fill="white" fill-opacity="0.6"/>
        <path d="M331.994 811.98C333.813 815.034 336.512 817.701 338.077 818.067C339.642 818.433 339.439 815.881 337.544 812.764C335.649 809.648 333.018 807.033 331.478 806.667C329.761 806.259 329.93 808.602 331.994 811.98Z" fill="white" fill-opacity="0.6"/>
        <path d="M317.028 810.798C318.923 814.145 321.8 816.718 323.416 816.666C324.888 816.666 324.71 814.103 323.035 811.133C321.225 807.911 318.213 805.035 316.588 805.056C314.964 805.077 315.26 807.66 317.028 810.798Z" fill="white" fill-opacity="0.6"/>
        <path d="M302.705 814.093C304.609 817.346 307.46 819.511 308.966 818.925C310.336 818.392 310.066 815.494 308.382 812.587C306.546 809.449 303.602 807.064 302.096 807.671C300.59 808.277 300.92 811.028 302.705 814.093Z" fill="white" fill-opacity="0.6"/>
        <path d="M303.975 830.985C305.802 834.122 308.628 836.11 310.235 835.513C311.842 834.917 311.631 832.376 309.922 829.342C308.111 826.121 305.252 823.768 303.594 824.228C301.936 824.688 302.164 827.847 303.975 830.985Z" fill="white" fill-opacity="0.6"/>
        <path d="M296.386 842.03C297.883 840.984 297.46 837.846 295.421 834.834C293.594 832.093 290.98 830.556 289.575 831.424C288.171 832.292 288.425 835.785 290.261 838.63C292.096 841.475 294.914 843.044 296.386 842.03Z" fill="white" fill-opacity="0.6"/>
        <path d="M276.555 847.97C278.307 850.763 281.039 852.154 282.587 851.046C284.136 849.937 283.941 847.28 282.308 844.508C280.557 841.559 277.816 839.906 276.209 840.879C274.601 841.852 274.821 845.199 276.555 847.97Z" fill="white" fill-opacity="0.6"/>
        <path d="M262.892 852.332C261.437 853.587 261.716 856.966 263.476 859.434C265.236 861.902 267.613 862.812 269.102 861.798C270.743 860.668 270.633 857.698 268.848 854.978C267.063 852.259 264.373 851.066 262.892 852.332Z" fill="white" fill-opacity="0.6"/>
        <path d="M250.532 871.671C252.3 873.962 254.898 874.663 256.31 873.24C257.723 871.818 257.419 869.172 255.778 866.85C254.136 864.528 251.387 863.45 249.949 864.831C248.51 866.212 248.815 869.423 250.532 871.671Z" fill="white" fill-opacity="0.6"/>
        <path d="M237.935 884.327C239.712 886.325 242.25 886.754 243.57 885.237C244.889 883.72 244.416 880.886 242.512 878.773C240.82 876.89 238.375 876.483 237.148 877.863C235.922 879.244 236.192 882.361 237.935 884.327Z" fill="white" fill-opacity="0.6"/>
        <path d="M230.778 897.234C232.191 895.864 231.886 893.312 230.067 891.345C228.434 889.599 226.083 889.191 224.763 890.404C223.443 891.617 223.587 894.462 225.22 896.282C226.852 898.102 229.39 898.573 230.778 897.234Z" fill="white" fill-opacity="0.6"/>
        <path d="M218.139 908.383C219.611 907.233 219.45 904.953 217.758 903.154C216.066 901.355 213.579 901.062 212.284 902.317C210.99 903.572 211.252 905.706 212.665 907.285C214.205 908.969 216.684 909.429 218.139 908.383Z" fill="white" fill-opacity="0.6"/>
        <path d="M205.685 917.87C207.174 916.939 207.056 914.962 205.432 913.383C203.807 911.804 201.371 911.458 200.059 912.494C198.748 913.529 198.96 915.339 200.322 916.74C201.684 918.142 204.23 918.78 205.685 917.87Z" fill="white" fill-opacity="0.6"/>
        <path d="M186.955 927.21C187.791 927.847 188.748 928.201 189.729 928.236C190.711 928.271 191.683 927.986 192.547 927.409C194.053 926.29 194.129 924.512 192.759 923.162C191.962 922.441 191.019 922.006 190.034 921.908C189.05 921.811 188.063 922.053 187.183 922.608C186.831 922.8 186.528 923.108 186.308 923.499C186.087 923.89 185.958 924.348 185.935 924.823C185.911 925.298 185.994 925.773 186.174 926.194C186.354 926.615 186.624 926.966 186.955 927.21Z" fill="white" fill-opacity="0.6"/>
        <path d="M178.495 937.973C180.111 936.749 180.043 934.929 178.351 933.789C177.489 933.315 176.55 933.094 175.607 933.145C174.665 933.196 173.745 933.516 172.92 934.082C171.338 935.285 171.228 937.22 172.75 938.266C173.661 938.771 174.654 939.007 175.65 938.956C176.647 938.905 177.621 938.569 178.495 937.973Z" fill="white" fill-opacity="0.6"/>
        <path d="M157.785 947.554C159.677 948.415 161.761 948.374 163.631 947.439C165.196 946.549 165.323 945.148 163.91 944.238C162.989 943.762 162.001 943.517 161.004 943.517C160.006 943.517 159.018 943.762 158.098 944.238C156.44 945.221 156.287 946.738 157.785 947.554Z" fill="white" fill-opacity="0.6"/>
        <path d="M148.157 953.536C149.951 953.118 150.069 952.093 148.453 951.308C146.647 950.602 144.73 950.433 142.861 950.817C141.11 951.214 140.831 952.103 142.277 952.825C144.165 953.631 146.179 953.874 148.157 953.536Z" fill="white" fill-opacity="0.6"/>
        <path d="M127.515 952.877C129.279 953.697 131.127 954.211 133.005 954.404C134.545 954.509 134.697 954.059 133.42 953.358C131.619 952.553 129.743 952.036 127.836 951.821C126.237 951.716 126.11 952.177 127.515 952.877Z" fill="white" fill-opacity="0.6"/>
        <path d="M114.883 950.702C116.487 951.575 118.16 952.238 119.875 952.679C121.212 952.961 121.288 952.679 120.01 951.936C118.368 951.097 116.669 950.435 114.934 949.959C113.53 949.614 113.538 949.938 114.883 950.702Z" fill="white" fill-opacity="0.6"/>
        <path d="M103.657 946.34C102.566 945.88 102.811 946.225 104.148 947.083C105.599 948.015 107.106 948.806 108.657 949.447C109.706 949.813 109.503 949.447 108.251 948.694C106.757 947.804 105.223 947.018 103.657 946.34Z" fill="white" fill-opacity="0.6"/>
        <path d="M98.5894 944.824C99.6469 945.399 99.4354 945.064 98.0649 944.081C96.8467 943.192 95.0954 941.989 94.1479 941.445C93.2004 940.901 93.3019 941.09 94.5201 942.031C95.8259 943.071 97.1852 944.003 98.5894 944.824Z" fill="white" fill-opacity="0.6"/>
        <path d="M86.0011 935.996C87.1684 937.135 88.3918 938.183 89.6643 939.134C90.5865 939.803 90.5103 939.594 89.5205 938.663C88.5307 937.732 86.7456 936.247 85.7896 935.452C84.8336 934.657 84.969 934.971 86.0011 935.996Z" fill="white" fill-opacity="0.6"/>
        <path d="M78.4208 929.448C79.44 930.717 80.5375 931.885 81.7034 932.942C82.4986 933.601 82.3971 933.287 81.458 932.22C80.5189 931.153 78.92 929.469 78.1332 928.685C77.3464 927.901 77.4733 928.256 78.4208 929.448Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.052 921.447C70.2991 920.642 70.3498 921.102 71.1535 922.409C72.0285 923.799 73.0275 925.062 74.1315 926.175C74.8844 926.865 74.8506 926.478 74.0384 925.286C73.1109 923.928 72.1134 922.646 71.052 921.447Z" fill="white" fill-opacity="0.6"/>
        <path d="M64.1571 913.979C63.438 913.237 63.4211 913.896 64.1571 915.37C64.8699 916.867 65.7923 918.195 66.8813 919.293C67.6089 919.92 67.6089 919.387 66.8813 918.006C66.1068 916.534 65.1921 915.182 64.1571 913.979Z" fill="white" fill-opacity="0.6"/>
        <path d="M57.1692 906.689C56.4163 905.988 56.2386 906.794 56.8139 908.383C57.3783 909.979 58.2496 911.379 59.3519 912.463C60.1302 913.048 60.2487 912.4 59.6649 910.894C59.0566 909.309 58.2097 907.882 57.1692 906.689Z" fill="white" fill-opacity="0.6"/>
        <path d="M51.3316 905.915C52.2791 906.616 52.6006 905.831 52.1015 904.085C51.653 902.478 50.8816 901.038 49.8596 899.901C49.0136 899.116 48.5482 899.901 48.9289 901.585C49.3248 903.315 50.1689 904.836 51.3316 905.915Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.2912 895.759C40.4125 896.65 40.6837 897.499 41.0874 898.253C41.4911 899.006 42.0182 899.646 42.6346 900.131C43.6244 900.717 44.1489 899.964 43.9121 898.311C43.6352 896.509 42.8386 894.892 41.6702 893.761C40.7226 893.029 40.0543 893.991 40.2912 895.759Z" fill="white" fill-opacity="0.6"/>
        <path d="M30.5283 891.136C30.6116 892.04 30.884 892.901 31.3187 893.635C31.7535 894.368 32.3354 894.949 33.0072 895.32C34.0986 895.717 34.7669 894.713 34.6061 892.945C34.4454 891.178 33.3879 889.023 32.2796 888.5C31.1713 887.977 30.3507 889.274 30.5283 891.136Z" fill="white" fill-opacity="0.6"/>
        <path d="M21.4085 884.63C20.1395 884.39 19.1412 885.938 19.3104 887.904C19.3688 888.834 19.669 889.715 20.1661 890.416C20.6632 891.118 21.3305 891.601 22.0683 891.795C23.3204 891.941 24.0988 890.749 23.9719 888.793C23.845 886.837 22.669 884.86 21.4085 884.63Z" fill="white" fill-opacity="0.6"/>
        <path d="M9.07369 882.361C8.70291 882.341 8.33297 882.418 7.98721 882.584C7.64146 882.751 7.3274 883.004 7.06499 883.329C6.80258 883.653 6.59751 884.042 6.46271 884.469C6.32791 884.896 6.26633 885.354 6.28188 885.812C6.28938 886.728 6.55434 887.612 7.03133 888.312C7.50831 889.012 8.16762 889.486 8.89606 889.651C9.25637 889.732 9.62697 889.71 9.97934 889.587C10.3317 889.463 10.6564 889.241 10.9286 888.938C11.2007 888.635 11.4129 888.259 11.549 887.838C11.685 887.418 11.7412 886.965 11.7132 886.513C11.7413 885.547 11.4915 884.6 11.0083 883.839C10.5251 883.079 9.83967 882.555 9.07369 882.361Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.385093 910.12C-0.173276 912.023 0.0636366 913.864 0.0974771 915.663C0.0985843 915.88 0.134253 916.095 0.202454 916.295C0.270655 916.495 0.370064 916.676 0.494975 916.829C0.619885 916.981 0.767875 917.102 0.930478 917.183C1.09308 917.265 1.26707 917.307 1.44261 917.305C3.4815 917.609 5.0974 916.144 5.2666 913.864C5.31378 913.285 5.25544 912.7 5.09589 912.152C4.93635 911.604 4.67965 911.107 4.34458 910.698C4.00952 910.289 3.60463 909.977 3.15958 909.787C2.71452 909.596 2.24058 909.531 1.77257 909.597C1.29327 909.694 0.826624 909.87 0.385093 910.12Z" fill="white" fill-opacity="0.6"/>
        <path d="M722.477 890.603C719.651 890.958 718.01 892.872 718.983 894.682C719.956 896.491 723.086 897.725 725.886 897.318C728.526 896.941 729.905 895.32 729.16 893.489C728.416 891.659 725.302 890.226 722.477 890.603Z" fill="white" fill-opacity="0.6"/>
        <path d="M692.867 896.983C693.916 898.813 697.147 900.037 700.041 899.713C702.756 899.399 704.178 897.851 703.366 896.083C702.553 894.316 699.347 892.851 696.411 893.102C693.476 893.353 691.818 895.194 692.867 896.983Z" fill="white" fill-opacity="0.6"/>
        <path d="M666.708 899.493C667.96 901.271 671.353 902.464 674.221 902.139C676.928 901.826 678.197 900.309 677.19 898.573C676.183 896.836 672.774 895.435 669.847 895.696C666.919 895.958 665.481 897.757 666.708 899.493Z" fill="white" fill-opacity="0.6"/>
        <path d="M643.443 898.238C640.643 898.541 639.5 900.33 640.998 902.003C642.495 903.677 646.074 904.859 648.815 904.493C651.412 904.158 652.411 902.641 651.15 900.957C649.889 899.273 646.269 897.924 643.443 898.238Z" fill="white" fill-opacity="0.6"/>
        <path d="M615.948 904.2C617.58 905.894 621.134 907.055 623.824 906.762C626.362 906.48 627.25 905.047 625.871 903.405C624.492 901.763 620.795 900.403 618.063 900.623C615.33 900.842 614.349 902.505 615.948 904.2Z" fill="white" fill-opacity="0.6"/>
        <path d="M592.285 906.187C594.655 908.002 597.42 908.867 600.204 908.666C602.742 908.373 603.173 906.794 601.193 905.078C598.914 903.381 596.28 902.551 593.622 902.694C591.075 902.924 590.5 904.566 592.285 906.187Z" fill="white" fill-opacity="0.6"/>
        <path d="M570.255 907.756C572.598 909.497 575.296 910.372 578.03 910.277C580.339 910.036 580.525 908.509 578.402 906.825C576.135 905.206 573.558 904.372 570.94 904.409C568.597 904.597 568.301 906.166 570.255 907.756Z" fill="white" fill-opacity="0.6"/>
        <path d="M557.768 911.438C559.874 911.281 559.857 909.869 557.658 908.195C555.46 906.622 552.98 905.748 550.441 905.654C548.326 905.748 548.276 907.233 550.281 908.791C552.543 910.477 555.127 911.39 557.768 911.438Z" fill="white" fill-opacity="0.6"/>
        <path d="M532.785 909.157C534.926 910.802 537.354 911.792 539.866 912.044C541.651 912.044 541.651 910.904 539.807 909.388C537.619 907.683 535.139 906.633 532.565 906.323C530.645 906.292 530.772 907.589 532.785 909.157Z" fill="white" fill-opacity="0.6"/>
        <path d="M524.376 911.73C526.203 911.908 525.89 910.685 523.674 909.074C521.54 907.47 519.171 906.403 516.703 905.936C515.205 905.842 515.501 906.982 517.38 908.415C519.51 910.086 521.888 911.213 524.376 911.73Z" fill="white" fill-opacity="0.6"/>
        <path d="M508.674 909.367C510.561 909.816 510.366 908.791 508.175 907.087C505.997 905.388 503.616 904.125 501.119 903.342C499.554 902.997 499.783 903.938 501.618 905.434C503.779 907.215 506.163 908.544 508.674 909.367Z" fill="white" fill-opacity="0.6"/>
        <path d="M492.262 902.85C490.093 901.091 487.75 899.684 485.291 898.667C483.869 898.238 484.292 899.19 486.221 900.842C488.374 902.677 490.723 904.131 493.201 905.162C494.96 905.727 494.554 904.712 492.262 902.85Z" fill="white" fill-opacity="0.6"/>
        <path d="M471.128 894.295C473.242 896.252 475.533 897.899 477.956 899.2C479.563 899.932 479.343 899.2 477.423 897.422C475.246 895.486 472.923 893.815 470.485 892.433C468.768 891.554 469.081 892.412 471.128 894.295Z" fill="white" fill-opacity="0.6"/>
        <path d="M456.179 884.494C454.716 883.553 455.291 884.547 457.364 886.586C459.392 888.613 461.571 890.399 463.87 891.921C465.232 892.695 464.8 891.826 462.821 889.923C460.717 887.915 458.498 886.101 456.179 884.494Z" fill="white" fill-opacity="0.6"/>
        <path d="M443.988 877.947C445.982 880.114 448.144 882.03 450.443 883.668C451.814 884.515 451.399 883.668 449.462 881.576C447.41 879.42 445.226 877.462 442.931 875.719C441.45 874.705 441.95 875.761 443.988 877.947Z" fill="white" fill-opacity="0.6"/>
        <path d="M437.102 875.133C438.54 876.012 437.948 874.767 435.672 872.278C433.783 870.153 431.748 868.235 429.589 866.546C428.151 865.553 428.676 866.776 430.706 869.14C432.65 871.427 434.796 873.437 437.102 875.133Z" fill="white" fill-opacity="0.6"/>
        <path d="M416.155 857.3C414.776 856.411 415.368 857.886 417.424 860.438C419.297 862.824 421.423 864.886 423.744 866.567C425.029 867.289 424.522 866.055 422.542 863.639C420.612 861.236 418.47 859.11 416.155 857.3Z" fill="white" fill-opacity="0.6"/>
        <path d="M410.114 858.179C411.578 858.995 411.02 857.468 408.845 854.654C407.052 852.286 405.022 850.209 402.805 848.473C401.341 847.531 401.79 849.1 403.761 851.757C405.732 854.413 408.659 857.363 410.114 858.179Z" fill="white" fill-opacity="0.6"/>
        <path d="M396.434 850.428C397.923 851.15 397.399 849.382 395.242 846.475C393.313 843.818 390.622 841.005 389.218 840.199C387.814 839.394 388.144 841.12 390.064 843.912C391.985 846.705 394.962 849.717 396.434 850.428Z" fill="white" fill-opacity="0.6"/>
        <path d="M382.34 843.483C384.032 844.299 383.761 842.636 381.765 839.624C379.768 836.612 376.883 833.547 375.394 832.857C373.905 832.166 374.261 833.903 375.961 836.643C377.788 839.572 380.69 842.668 382.34 843.483Z" fill="white" fill-opacity="0.6"/>
        <path d="M361.384 826.801C359.87 826.278 360.242 828.558 362.23 831.612C364.219 834.666 367.095 837.574 368.592 837.961C370.09 838.348 369.658 836.434 367.814 833.505C365.97 830.577 362.924 827.376 361.384 826.801Z" fill="white" fill-opacity="0.6"/>
        <path d="M347.561 827.355C349.396 830.493 352.315 833.463 353.982 833.861C355.648 834.258 355.479 832.386 353.762 829.416C351.943 826.278 349.041 823.14 347.307 822.502C345.572 821.864 345.733 824.207 347.561 827.355Z" fill="white" fill-opacity="0.6"/>
        <path d="M332.857 825.452C334.684 828.704 337.62 831.497 339.329 831.675C341.038 831.853 340.894 829.771 339.194 826.707C337.392 823.433 334.473 820.431 332.696 820.023C330.92 819.615 331.047 822.23 332.857 825.452Z" fill="white" fill-opacity="0.6"/>
        <path d="M324.473 831.895C326.216 831.779 326.072 829.186 324.135 825.839C322.197 822.492 319.279 819.96 317.722 820.264C316.165 820.567 316.445 823.266 318.086 826.257C319.862 829.489 322.756 832.031 324.473 831.895Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.0466909 894.222C0.0466908 895.372 0.0466907 895.738 0.0466907 896.104C0.122831 898.123 0.9519 898.949 2.50856 898.656C3.08445 898.56 3.62377 898.253 4.05667 897.773C4.48958 897.294 4.79612 896.665 4.93661 895.968C5.08832 895.258 5.07084 894.51 4.88621 893.812C4.70159 893.114 4.35771 892.497 3.89603 892.036C3.49863 891.589 3.00525 891.294 2.47536 891.187C1.94547 891.079 1.40171 891.163 0.909639 891.429C0.736152 891.529 0.582149 891.675 0.458079 891.855C0.334008 892.036 0.242738 892.247 0.190498 892.475C0.0973382 893.05 0.04919 893.635 0.0466909 894.222Z" fill="white" fill-opacity="0.6"/>
        <path d="M322.13 802.201C323.678 802.368 323.543 800.203 321.834 797.222C320.125 794.241 317.502 791.553 315.912 791.25C314.321 790.946 314.287 793.425 316.165 796.594C317.942 799.607 320.582 802.044 322.13 802.201Z" fill="white" fill-opacity="0.6"/>
        <path d="M307.485 802.504C308.966 802.159 308.78 799.596 307.054 796.594C305.328 793.593 302.731 791.365 301.191 791.574C299.651 791.783 299.727 794.858 301.631 797.954C303.535 801.05 305.996 802.849 307.485 802.504Z" fill="white" fill-opacity="0.6"/>
        <path d="M336.698 805.025C338.221 805.453 338.069 803.56 336.36 800.642C334.651 797.724 332.045 794.9 330.48 794.367C328.788 793.749 328.906 795.956 330.784 799.063C332.468 801.96 335.15 804.606 336.698 805.025Z" fill="white" fill-opacity="0.6"/>
        <path d="M350.835 809.93C352.358 810.599 352.205 808.978 350.496 806.165C348.787 803.351 346.19 800.422 344.642 799.607C342.95 798.739 343.094 800.653 344.955 803.654C346.689 806.478 349.346 809.292 350.835 809.93Z" fill="white" fill-opacity="0.6"/>
        <path d="M364.87 816.666C366.274 817.377 365.961 815.819 364.176 813.078C362.391 810.338 359.836 807.493 358.364 806.625C356.892 805.757 357.095 807.483 359.058 810.443C361.021 813.402 363.466 815.955 364.87 816.666Z" fill="white" fill-opacity="0.6"/>
        <path d="M378.541 824.709C379.92 825.546 379.557 824.165 377.746 821.571C376.067 819.096 374.117 816.924 371.951 815.118C370.394 814.072 370.758 815.62 372.738 818.465C374.392 820.914 376.351 823.022 378.541 824.709Z" fill="white" fill-opacity="0.6"/>
        <path d="M391.984 833.809C393.355 834.739 392.974 833.558 391.138 831.089C389.396 828.708 387.447 826.572 385.326 824.719C383.787 823.538 384.176 824.887 386.172 827.585C387.885 829.96 389.839 832.052 391.984 833.809Z" fill="white" fill-opacity="0.6"/>
        <path d="M405.174 843.473C406.612 844.519 406.324 843.661 404.531 841.381C402.719 839.09 400.755 836.991 398.659 835.106C397.077 833.788 397.365 834.813 399.302 837.312C401.089 839.602 403.056 841.666 405.174 843.473Z" fill="white" fill-opacity="0.6"/>
        <path d="M418.414 853.566C419.81 854.612 419.446 853.796 417.568 851.652C415.701 849.494 413.723 847.488 411.646 845.649C410.089 844.352 410.461 845.282 412.492 847.657C414.327 849.83 416.308 851.807 418.414 853.566Z" fill="white" fill-opacity="0.6"/>
        <path d="M431.535 863.576C432.931 864.622 432.567 863.911 430.689 861.913C428.797 859.887 426.82 857.984 424.767 856.212C423.202 854.936 423.583 855.731 425.613 857.949C427.476 859.997 429.455 861.878 431.535 863.576Z" fill="white" fill-opacity="0.6"/>
        <path d="M444.598 873.167C446.087 874.213 445.816 873.606 443.963 871.765C441.938 869.77 439.822 867.919 437.626 866.222C436.273 865.176 436.713 865.94 438.591 867.843C440.499 869.775 442.505 871.553 444.598 873.167Z" fill="white" fill-opacity="0.6"/>
        <path d="M458.658 881.838C460.291 882.737 460.122 882.089 458.269 880.363C456.184 878.459 453.968 876.786 451.645 875.363C450.139 874.527 450.46 875.238 452.356 877.006C454.332 878.852 456.442 880.47 458.658 881.838Z" fill="white" fill-opacity="0.6"/>
        <path d="M474.056 888.981C475.595 889.557 475.257 888.668 473.277 887.005C471.269 885.313 469.112 883.909 466.848 882.821C465.088 882.089 465.46 883.009 467.643 884.84C469.63 886.551 471.785 887.942 474.056 888.981Z" fill="white" fill-opacity="0.6"/>
        <path d="M489.537 894.065C491.229 894.525 491.111 893.657 489.233 892.098C487.222 890.48 485.034 889.226 482.736 888.375C480.849 887.789 480.967 888.689 483.015 890.362C485.026 891.995 487.225 893.244 489.537 894.065Z" fill="white" fill-opacity="0.6"/>
        <path d="M505.341 897.077C506.923 897.223 506.618 896.125 504.681 894.619C502.723 893.11 500.553 892.067 498.285 891.544C496.5 891.293 496.779 892.433 498.92 894.044C500.881 895.56 503.062 896.59 505.341 897.077Z" fill="white" fill-opacity="0.6"/>
        <path d="M520.958 897.569C522.752 897.569 522.752 896.313 520.899 894.818C518.855 893.248 516.531 892.318 514.131 892.109C512.109 892.109 512.21 893.448 514.275 895.006C516.301 896.518 518.595 897.398 520.958 897.569Z" fill="white" fill-opacity="0.6"/>
        <path d="M539.443 896.795C541.567 896.679 541.888 895.403 540.137 893.887C537.99 892.228 535.519 891.321 532.988 891.262C530.611 891.324 530.323 892.736 532.32 894.316C534.478 895.899 536.934 896.753 539.443 896.795Z" fill="white" fill-opacity="0.6"/>
        <path d="M560.89 895.383C563.182 895.142 563.639 893.73 561.888 892.245C559.647 890.551 557.051 889.714 554.426 889.839C551.829 890.038 551.389 891.586 553.428 893.176C555.695 894.773 558.283 895.539 560.89 895.383Z" fill="white" fill-opacity="0.6"/>
        <path d="M584.231 893.479C586.811 893.249 587.674 891.837 586.177 890.268C584.679 888.699 581.321 887.569 578.647 887.747C575.762 887.936 574.832 889.504 576.625 891.136C578.907 892.859 581.562 893.677 584.231 893.479Z" fill="white" fill-opacity="0.6"/>
        <path d="M609.763 891.157C612.411 890.812 613.35 889.274 611.87 887.674C610.389 886.074 607.039 885.049 604.256 885.331C601.253 885.645 600.237 887.35 602.048 888.992C603.858 890.634 607.081 891.492 609.763 891.157Z" fill="white" fill-opacity="0.6"/>
        <path d="M636.108 888.574C638.934 888.27 640.228 886.733 639.035 885.091C637.842 883.448 634.543 882.329 631.624 882.58C628.452 882.852 627.022 884.568 628.536 886.262C630.051 887.956 633.274 888.887 636.108 888.574Z" fill="white" fill-opacity="0.6"/>
        <path d="M663.189 885.833C666.175 885.572 667.74 884.097 666.86 882.371C665.98 880.645 662.503 879.317 659.314 879.694C656.37 880.039 654.872 881.649 655.93 883.302C656.987 884.955 660.304 886.084 663.189 885.833Z" fill="white" fill-opacity="0.6"/>
        <path d="M690.811 882.999C693.797 882.737 695.489 881.231 694.804 879.464C694.119 877.696 690.811 876.326 687.613 876.702C684.66 877.058 682.994 878.7 683.899 880.394C684.804 882.089 687.917 883.281 690.811 882.999Z" fill="white" fill-opacity="0.6"/>
        <path d="M718.424 880.143C721.301 879.851 723.035 878.24 722.417 876.462C721.8 874.684 718.983 873.449 716.064 873.68C712.9 873.931 710.895 875.771 711.834 877.581C712.773 879.39 715.573 880.478 718.424 880.143Z" fill="white" fill-opacity="0.6"/>
        <path d="M8.93827 871.18C9.26843 871.203 9.59872 871.137 9.90552 870.984C10.2123 870.831 10.488 870.596 10.7128 870.297C10.9376 869.997 11.106 869.639 11.2058 869.249C11.3056 868.859 11.3344 868.447 11.2901 868.042C11.2942 867.149 11.049 866.281 10.5959 865.586C10.1428 864.89 9.50948 864.41 8.80287 864.224C8.4464 864.195 8.08916 864.264 7.75713 864.427C7.42511 864.591 7.12667 864.843 6.88355 865.167C6.64043 865.49 6.45872 865.877 6.35169 866.298C6.24466 866.72 6.215 867.165 6.26484 867.603C6.29891 868.492 6.58564 869.339 7.0746 869.993C7.56357 870.647 8.22349 871.067 8.93827 871.18Z" fill="white" fill-opacity="0.6"/>
        <path d="M21.0953 873.868C22.2628 874.161 23.0411 872.968 22.9142 871.117C22.7873 869.266 21.8313 867.488 20.6892 867.09C19.5471 866.693 18.4896 868.073 18.6841 870.05C18.7381 870.909 18.9955 871.729 19.4244 872.408C19.8532 873.087 20.4344 873.595 21.0953 873.868Z" fill="white" fill-opacity="0.6"/>
        <path d="M31.2898 878.135C32.2796 878.637 32.8803 877.675 32.7026 875.928C32.6279 875.082 32.409 874.266 32.0605 873.532C31.712 872.798 31.2418 872.164 30.6807 871.671C29.6486 871.023 28.9041 872.152 29.1325 874.056C29.2107 874.897 29.4464 875.703 29.8209 876.411C30.1954 877.119 30.6984 877.709 31.2898 878.135Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.0544 883.898C40.9005 884.473 41.2727 883.626 40.9597 881.942C40.6761 880.305 39.9871 878.817 38.9884 877.685C38.1424 876.943 37.6264 877.947 37.9733 879.777C38.2409 881.417 38.9803 882.881 40.0544 883.898Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.4991 890.603C48.2521 891.366 48.5144 890.801 48.1336 889.264C47.7241 887.67 47.0595 886.193 46.1794 884.923C45.418 883.992 45.0711 884.672 45.4687 886.367C45.849 887.966 46.547 889.422 47.4991 890.603Z" fill="white" fill-opacity="0.6"/>
        <path d="M54.132 898.112C54.8257 898.991 54.978 898.604 54.4789 897.202C53.9091 895.619 53.1785 894.133 52.3047 892.778C51.6871 891.941 51.5602 892.464 52.0255 893.918C52.5347 895.451 53.2466 896.868 54.132 898.112Z" fill="white" fill-opacity="0.6"/>
        <path d="M60.4432 906.187C61.0693 907.013 61.0354 906.626 60.3755 905.308C59.6839 903.914 58.9327 902.566 58.1251 901.271C57.4736 900.225 57.4906 900.717 58.1251 902.202C58.7886 903.622 59.5652 904.957 60.4432 906.187Z" fill="white" fill-opacity="0.6"/>
        <path d="M66.526 914.314C67.1436 915.13 67.0082 914.816 66.213 913.603L63.6749 909.795C63.0235 908.823 63.1757 909.168 63.9964 910.549C64.7869 911.856 65.6313 913.113 66.526 914.314Z" fill="white" fill-opacity="0.6"/>
        <path d="M72.8033 922.232C73.4631 923.016 73.2686 922.765 72.3464 921.667C71.4243 920.569 70.1722 919.021 69.5292 918.152C68.8863 917.284 69.0555 917.483 70.0115 918.728C70.9674 919.972 72.1434 921.437 72.8033 922.232Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.6475 929.637C80.3835 930.358 80.1551 930.139 79.1145 929.145C78.0739 928.152 76.6526 926.76 75.9251 926.007C75.1975 925.254 75.4175 925.369 76.5088 926.499C77.6002 927.629 78.9114 928.926 79.6475 929.637Z" fill="white" fill-opacity="0.6"/>
        <path d="M87.4224 936.237C88.2684 936.843 88.0146 936.603 86.8555 935.714C85.6965 934.825 84.0976 933.622 83.26 932.911C82.4225 932.199 82.6509 932.408 83.886 933.433C85.1212 934.458 86.5848 935.578 87.4224 936.237Z" fill="white" fill-opacity="0.6"/>
        <path d="M96.39 941.665C97.4052 942.157 97.236 941.906 96.0347 941.111C94.8334 940.316 92.989 939.259 91.9654 938.705C90.9417 938.151 91.0686 938.349 92.3799 939.249C93.6913 940.148 95.3748 941.163 96.39 941.665Z" fill="white" fill-opacity="0.6"/>
        <path d="M106.94 945.64C108.192 945.985 108.2 945.64 106.94 944.96C105.462 944.157 103.938 943.493 102.38 942.972C101.026 942.554 101.077 942.847 102.439 943.642C103.887 944.475 105.393 945.144 106.94 945.64Z" fill="white" fill-opacity="0.6"/>
        <path d="M119.638 947.888C121.034 948.003 121.085 947.522 119.706 946.842C118.072 946.085 116.366 945.589 114.63 945.368C113.082 945.2 113.09 945.671 114.63 946.414C116.236 947.188 117.922 947.684 119.638 947.888Z" fill="white" fill-opacity="0.6"/>
        <path d="M134.629 946.665C136.195 946.152 136.321 945.064 134.892 944.332C133.147 943.611 131.272 943.516 129.486 944.06C127.726 944.625 127.565 945.587 129.156 946.288C130.921 947.013 132.81 947.143 134.629 946.665Z" fill="white" fill-opacity="0.6"/>
        <path d="M149.985 939.249C151.49 938.203 151.533 936.613 150.095 935.766C149.226 935.37 148.3 935.209 147.377 935.296C146.454 935.383 145.557 935.714 144.748 936.268C143.056 937.439 142.963 939.04 144.579 939.793C145.464 940.15 146.398 940.286 147.326 940.192C148.254 940.099 149.158 939.778 149.985 939.249Z" fill="white" fill-opacity="0.6"/>
        <path d="M164.713 928.089C166.194 926.76 166.228 924.878 164.823 923.821C163.995 923.317 163.072 923.101 162.152 923.197C161.232 923.292 160.351 923.696 159.603 924.365C157.954 925.819 157.852 927.88 159.417 928.821C160.28 929.251 161.216 929.408 162.14 929.281C163.064 929.153 163.949 928.743 164.713 928.089Z" fill="white" fill-opacity="0.6"/>
        <path d="M178.723 915.538C179.038 915.287 179.296 914.94 179.47 914.53C179.645 914.12 179.731 913.661 179.72 913.198C179.708 912.735 179.601 912.284 179.407 911.887C179.213 911.491 178.94 911.163 178.613 910.936C177.853 910.324 176.96 910.014 176.053 910.046C175.146 910.078 174.269 910.45 173.537 911.113C171.998 912.578 171.998 914.879 173.537 916.008C174.35 916.542 175.268 916.781 176.185 916.698C177.103 916.614 177.983 916.212 178.723 915.538Z" fill="white" fill-opacity="0.6"/>
        <path d="M191.464 904.273C192.733 903.227 192.615 901.187 191.202 899.723C190.541 899.018 189.722 898.576 188.855 898.454C187.987 898.333 187.109 898.538 186.337 899.043C184.941 900.194 185.043 902.369 186.583 903.771C188.122 905.172 190.204 905.329 191.464 904.273Z" fill="white" fill-opacity="0.6"/>
        <path d="M203.427 893.029C204.738 891.743 204.62 889.473 203.181 887.8C201.743 886.126 199.527 885.833 198.215 887.078C196.904 888.322 196.896 890.99 198.452 892.579C200.009 894.169 202.183 894.284 203.427 893.029Z" fill="white" fill-opacity="0.6"/>
        <path d="M215.618 879.997C216.887 878.543 216.684 875.97 215.169 874.14C213.655 872.309 211.413 872.048 210.093 873.46C208.773 874.872 208.883 877.874 210.542 879.61C212.2 881.346 214.349 881.451 215.618 879.997Z" fill="white" fill-opacity="0.6"/>
        <path d="M227.876 866.086C229.179 864.569 228.976 861.798 227.428 859.748C225.88 857.698 223.587 857.269 222.259 858.764C220.93 860.26 221.024 863.482 222.716 865.448C224.408 867.414 226.59 867.592 227.876 866.086Z" fill="white" fill-opacity="0.6"/>
        <path d="M240.439 851.83C241.708 850.272 241.421 847.322 239.797 845.105C238.172 842.887 235.854 842.364 234.534 843.912C233.215 845.46 233.409 848.88 235.186 851.004C236.962 853.127 239.162 853.388 240.439 851.83Z" fill="white" fill-opacity="0.6"/>
        <path d="M253.062 838.243C254.432 836.842 254.255 833.882 252.647 831.403C251.04 828.924 248.646 827.993 247.25 829.311C245.854 830.629 245.921 834.164 247.656 836.633C249.39 839.101 251.708 839.624 253.062 838.243Z" fill="white" fill-opacity="0.6"/>
        <path d="M266.014 825.755C267.478 824.573 267.368 821.697 265.769 818.977C264.077 816.08 261.446 814.794 259.991 816.3C258.535 817.806 258.865 820.787 260.49 823.307C262.114 825.828 264.601 826.937 266.014 825.755Z" fill="white" fill-opacity="0.6"/>
        <path d="M279.457 815.128C280.904 814.082 280.743 811.248 279.093 808.434C277.444 805.621 274.931 804.041 273.451 804.951C271.97 805.861 272.021 809.302 273.823 812.126C275.515 814.783 278.028 816.101 279.457 815.128Z" fill="white" fill-opacity="0.6"/>
        <path d="M293.391 807.075C294.761 806.248 294.44 803.351 292.672 800.454C290.904 797.557 288.442 795.841 286.961 796.594C285.481 797.347 285.751 800.778 287.689 803.665C289.626 806.552 292.003 807.901 293.391 807.075Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.0467818 876.671C0.0467818 876.922 0.0467818 877.162 0.0467818 877.403C-0.16472 879.631 1.48499 880.363 2.74554 879.934C3.43678 879.687 4.0256 879.123 4.39191 878.357C4.75821 877.591 4.87441 876.681 4.71675 875.813C4.60603 874.914 4.22639 874.099 3.65299 873.53C3.0796 872.961 2.3542 872.678 1.62034 872.738C1.23103 872.724 0.852699 872.899 0.566447 873.225C0.280196 873.552 0.108817 874.004 0.0890718 874.485C0.0383114 875.207 0.0890716 875.949 0.0890716 876.671L0.0467818 876.671Z" fill="white" fill-opacity="0.6"/>
        <path d="M306.403 787.139C307.917 787.139 307.705 784.754 305.954 781.69C304.203 778.625 301.572 776.094 300.1 776.084C298.662 776.241 298.865 778.95 300.565 781.93C302.266 784.911 304.93 787.129 306.403 787.139Z" fill="white" fill-opacity="0.6"/>
        <path d="M315.091 782.767C316.707 785.696 319.38 788.499 320.954 788.98C322.527 789.461 322.409 787.401 320.751 784.451C319.093 781.502 316.521 778.667 314.956 778.176C313.391 777.684 313.467 779.818 315.091 782.767Z" fill="white" fill-opacity="0.6"/>
        <path d="M335.319 793.268C336.825 793.927 336.639 792.086 334.947 789.21C333.255 786.334 330.717 783.426 329.202 782.736C327.688 782.045 327.84 783.939 329.49 786.815C331.14 789.691 333.805 792.609 335.319 793.268Z" fill="white" fill-opacity="0.6"/>
        <path d="M349.261 799.544C350.759 800.422 350.572 798.854 348.88 796.092C347.188 793.331 344.65 790.34 343.161 789.419C341.672 788.499 341.816 790.12 343.474 792.892C345.133 795.663 347.755 798.655 349.261 799.544Z" fill="white" fill-opacity="0.6"/>
        <path d="M356.638 797.703C355.302 796.824 355.615 798.362 357.357 801.05C358.974 803.599 360.914 805.806 363.102 807.587C364.481 808.445 364.126 806.98 362.324 804.261C360.522 801.542 357.967 798.561 356.638 797.703Z" fill="white" fill-opacity="0.6"/>
        <path d="M369.946 807.357C368.677 806.447 369.1 807.838 370.91 810.495C372.577 812.937 374.501 815.094 376.63 816.906C377.924 817.795 377.476 816.457 375.597 813.821C373.932 811.392 372.034 809.221 369.946 807.357Z" fill="white" fill-opacity="0.6"/>
        <path d="M389.802 826.937C391.155 827.983 390.749 826.937 388.956 824.479C387.208 822.139 385.301 819.99 383.254 818.057C381.942 817.011 382.298 818.057 384.1 820.504C385.816 822.886 387.727 825.041 389.802 826.937Z" fill="white" fill-opacity="0.6"/>
        <path d="M396.984 831.152C398.784 833.448 400.727 835.563 402.796 837.48C404.235 838.704 403.913 837.867 402.136 835.649C400.33 833.406 398.409 831.31 396.384 829.374C394.988 828.098 395.25 828.893 396.984 831.152Z" fill="white" fill-opacity="0.6"/>
        <path d="M415.901 848.337C417.28 849.487 416.874 848.692 415.055 846.6C413.218 844.486 411.292 842.493 409.285 840.628C407.932 839.436 408.279 840.199 410.072 842.322C411.908 844.481 413.855 846.489 415.901 848.337Z" fill="white" fill-opacity="0.6"/>
        <path d="M428.963 858.953C430.292 859.999 429.809 859.246 427.906 857.248C426.002 855.25 423.397 852.74 422.102 851.652C420.808 850.564 421.256 851.317 423.092 853.315C424.96 855.333 426.92 857.215 428.963 858.953Z" fill="white" fill-opacity="0.6"/>
        <path d="M436.281 863.733C438.198 865.632 440.222 867.359 442.339 868.9C443.743 869.82 443.312 869.036 441.357 867.153C439.422 865.301 437.399 863.593 435.3 862.038C433.938 861.097 434.386 861.86 436.281 863.733Z" fill="white" fill-opacity="0.6"/>
        <path d="M456.831 877.309C458.43 878.135 458.184 877.361 456.306 875.646C454.328 873.869 452.221 872.321 450.012 871.023C448.43 870.176 448.65 870.876 450.503 872.602C452.48 874.427 454.6 876.004 456.831 877.309Z" fill="white" fill-opacity="0.6"/>
        <path d="M466.01 879.861C467.998 881.572 470.16 882.951 472.44 883.961C474.047 884.526 473.768 883.626 471.831 881.974C469.85 880.308 467.706 878.958 465.452 877.957C463.887 877.372 464.132 878.219 466.01 879.861Z" fill="white" fill-opacity="0.6"/>
        <path d="M488.133 888.427C489.766 888.793 489.512 887.758 487.617 886.199C485.67 884.613 483.523 883.439 481.264 882.727C479.656 882.35 479.834 883.302 481.695 884.819C483.664 886.455 485.84 887.675 488.133 888.427Z" fill="white" fill-opacity="0.6"/>
        <path d="M497.439 887.601C499.343 889.097 502.295 890.456 503.733 890.477C505.172 890.498 504.935 889.191 502.964 887.643C501.036 886.18 498.873 885.256 496.627 884.934C495.163 884.902 495.51 886.084 497.439 887.601Z" fill="white" fill-opacity="0.6"/>
        <path d="M514.292 884.453C512.261 884.453 512.05 885.76 513.792 887.266C515.847 888.907 518.211 889.862 520.654 890.038C522.684 890.038 522.946 888.741 521.195 887.203C519.135 885.539 516.75 884.589 514.292 884.453Z" fill="white" fill-opacity="0.6"/>
        <path d="M533.944 886.283C536.128 887.96 538.649 888.844 541.22 888.835C543.462 888.678 543.885 887.277 542.134 885.697C539.952 883.99 537.414 883.11 534.833 883.166C532.599 883.344 532.201 884.756 533.944 886.283Z" fill="white" fill-opacity="0.6"/>
        <path d="M565.864 883.794C564.265 882.193 560.915 881.074 558.369 881.273C555.822 881.472 555.094 882.936 556.677 884.494C558.259 886.053 561.71 887.214 564.181 887.036C566.651 886.858 567.446 885.373 565.864 883.794Z" fill="white" fill-opacity="0.6"/>
        <path d="M583.926 878.878C581.261 879.171 580.314 880.729 581.82 882.308C583.326 883.888 586.854 885.007 589.434 884.745C592.014 884.484 593.046 882.936 591.532 881.304C590.018 879.673 586.591 878.595 583.926 878.878Z" fill="white" fill-opacity="0.6"/>
        <path d="M608.511 879.537C609.746 881.158 613.173 882.34 616.007 882.12C618.841 881.901 620.186 880.321 618.959 878.669C617.733 877.016 614.433 875.845 611.498 876.085C608.562 876.326 607.259 877.905 608.511 879.537Z" fill="white" fill-opacity="0.6"/>
        <path d="M643.798 879.244C646.717 879.035 648.324 877.413 647.318 875.719C646.311 874.025 643.088 872.811 640.093 873.052C637.098 873.292 635.482 874.903 636.514 876.577C637.546 878.25 640.829 879.453 643.798 879.244Z" fill="white" fill-opacity="0.6"/>
        <path d="M665.244 873.606C666.175 875.29 669.474 876.504 672.368 876.221C675.261 875.939 676.936 874.255 676.022 872.519C675.109 870.782 671.945 869.6 668.958 869.904C665.972 870.207 664.297 871.891 665.244 873.606Z" fill="white" fill-opacity="0.6"/>
        <path d="M698.053 866.735C695.125 867.048 693.306 868.774 694.093 870.521C694.88 872.268 698.019 873.481 700.861 873.188C703.704 872.895 705.497 871.19 704.744 869.402C703.992 867.613 700.988 866.41 698.053 866.735Z" fill="white" fill-opacity="0.6"/>
        <path d="M12.6015 859.141C12.595 860.025 12.8104 860.89 13.2179 861.617C13.6255 862.344 14.2052 862.897 14.8773 863.199C16.0786 863.555 17.0177 862.3 16.9754 860.417C16.9331 858.534 15.994 856.673 14.7927 856.307C13.5914 855.94 12.6015 857.258 12.6015 859.141Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.3678 860.396C24.4034 859.789 23.5912 860.835 23.6758 862.645C23.6994 863.512 23.8916 864.359 24.2362 865.115C24.5808 865.87 25.0677 866.512 25.6555 866.985C26.6623 867.571 27.4068 866.578 27.3475 864.789C27.3119 863.918 27.1148 863.069 26.7715 862.308C26.4283 861.546 25.948 860.892 25.3678 860.396Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.821 868.063C32.9895 869.771 33.6612 871.342 34.7076 872.477C35.5536 873.146 36.0443 872.288 35.8328 870.563C35.6544 868.868 35.0091 867.302 34.0055 866.128C33.2102 865.427 32.6518 866.327 32.821 868.063Z" fill="white" fill-opacity="0.6"/>
        <path d="M42.2878 879.223C43.0069 880.007 43.3115 879.327 42.99 877.706C42.6723 876.069 42.0591 874.543 41.1965 873.24C40.5281 872.424 40.1643 873.136 40.4435 874.778C40.731 876.434 41.3672 877.968 42.2878 879.223Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.2623 881.377C46.6785 880.457 46.4924 880.99 46.8816 882.518C47.2984 884.112 47.9399 885.603 48.7766 886.921C49.4111 887.81 49.555 887.297 49.115 885.791C48.6595 884.224 48.0364 882.74 47.2623 881.377Z" fill="white" fill-opacity="0.6"/>
        <path d="M54.6056 895.32C55.1809 896.209 55.164 895.79 54.6056 894.378C54.0049 892.911 53.3384 891.486 52.609 890.111C52.0845 889.191 52.076 889.619 52.609 891.052C53.1585 892.553 53.8273 893.983 54.6056 895.32Z" fill="white" fill-opacity="0.6"/>
        <path d="M57.5751 899.19C57.0675 898.259 57.1859 898.583 57.8374 899.901C58.4888 901.219 59.5294 903.039 60.0709 903.98C60.6123 904.921 60.5023 904.566 59.7917 903.269L57.5751 899.19Z" fill="white" fill-opacity="0.6"/>
        <path d="M62.592 908.174C62.0929 907.317 62.3467 907.63 63.142 908.865C63.9372 910.099 65.0962 911.845 65.68 912.661C66.2637 913.477 65.9845 913.195 65.1131 911.971C64.2417 910.747 63.0658 909.021 62.592 908.174Z" fill="white" fill-opacity="0.6"/>
        <path d="M68.6155 917.284C69.4615 918.33 70.7982 919.972 71.4665 920.82C72.1349 921.667 71.9065 921.499 70.9589 920.443C69.9583 919.336 69.0039 918.166 68.0994 916.939C67.4819 916.061 67.7187 916.217 68.6155 917.284Z" fill="white" fill-opacity="0.6"/>
        <path d="M75.003 925.296L78.2178 928.434C78.9369 929.145 78.6408 928.915 77.5326 927.921C76.4243 926.928 74.9946 925.516 74.3009 924.784C73.6071 924.052 73.9624 924.292 75.003 925.296Z" fill="white" fill-opacity="0.6"/>
        <path d="M86.0094 934.929C86.8977 935.546 86.6693 935.316 85.4934 934.438C84.3174 933.559 82.6508 932.346 81.8048 931.697C80.9588 931.049 81.2126 931.258 82.3378 932.147C83.463 933.036 85.138 934.323 86.0094 934.929Z" fill="white" fill-opacity="0.6"/>
        <path d="M91.1785 937.67C92.5206 938.564 93.9082 939.35 95.3324 940.023C96.3984 940.483 96.28 940.19 95.0279 939.395C93.7758 938.6 91.9145 937.586 90.857 937.094C89.7995 936.603 89.9771 936.906 91.1785 937.67Z" fill="white" fill-opacity="0.6"/>
        <path d="M101.923 941.644C103.413 942.492 104.977 943.127 106.584 943.537C107.853 943.799 107.845 943.38 106.525 942.638C105.017 941.824 103.443 941.211 101.83 940.807C100.586 940.514 100.662 940.891 101.923 941.644Z" fill="white" fill-opacity="0.6"/>
        <path d="M114.867 943.548C116.526 944.415 118.31 944.859 120.112 944.855C121.711 944.719 121.897 943.882 120.543 943.14C118.842 942.372 117.029 942.055 115.222 942.209C113.64 942.366 113.555 942.826 114.867 943.548Z" fill="white" fill-opacity="0.6"/>
        <path d="M135.594 939.897C137.167 939.071 137.286 937.628 135.932 936.76C134.223 935.952 132.326 936.001 130.645 936.896C129.105 937.764 128.91 939.061 130.239 939.856C131.962 940.665 133.863 940.68 135.594 939.897Z" fill="white" fill-opacity="0.6"/>
        <path d="M150.661 929.564C152.184 928.319 152.269 926.426 150.89 925.443C150.058 924.971 149.142 924.776 148.231 924.877C147.319 924.978 146.445 925.371 145.695 926.018C144.215 927.315 144.062 929.156 145.399 930.076C146.245 930.521 147.165 930.707 148.081 930.618C148.997 930.529 149.882 930.167 150.661 929.564Z" fill="white" fill-opacity="0.6"/>
        <path d="M164.959 916.563C166.431 915.234 166.507 913.09 165.162 911.866C164.396 911.252 163.494 910.952 162.583 911.009C161.672 911.065 160.798 911.475 160.086 912.18C158.639 913.571 158.487 915.747 159.798 916.908C160.594 917.47 161.504 917.737 162.42 917.676C163.335 917.615 164.217 917.228 164.959 916.563Z" fill="white" fill-opacity="0.6"/>
        <path d="M173.419 898.29C173.109 898.571 172.858 898.938 172.684 899.361C172.511 899.785 172.42 900.253 172.42 900.727C172.42 901.202 172.511 901.67 172.684 902.093C172.858 902.517 173.109 902.883 173.419 903.164C174.134 903.813 174.992 904.178 175.879 904.209C176.766 904.241 177.64 903.939 178.385 903.342C179.738 902.139 179.688 900.006 178.317 898.594C177.643 897.88 176.796 897.463 175.91 897.408C175.023 897.353 174.147 897.663 173.419 898.29Z" fill="white" fill-opacity="0.6"/>
        <path d="M190.018 886.68C188.571 885.101 186.447 884.902 185.238 886.189C184.028 887.475 184.189 889.745 185.584 891.23C186.98 892.715 189.205 892.977 190.415 891.774C191.625 890.571 191.456 888.228 190.018 886.68Z" fill="white" fill-opacity="0.6"/>
        <path d="M196.811 872.56C195.686 874.15 195.965 876.744 197.488 878.302C199.011 879.861 201.261 879.997 202.386 878.512C203.511 877.026 203.232 874.443 201.667 872.738C200.102 871.033 197.953 871.002 196.811 872.56Z" fill="white" fill-opacity="0.6"/>
        <path d="M214.239 864.193C215.516 862.739 215.305 859.863 213.807 857.917C212.31 855.972 210.085 855.595 208.816 857.164C207.547 858.733 207.741 861.568 209.205 863.44C210.668 865.312 212.97 865.657 214.239 864.193Z" fill="white" fill-opacity="0.6"/>
        <path d="M226.514 848.943C227.758 847.332 227.462 844.352 225.863 842.197C224.264 840.042 221.971 839.687 220.787 841.339C219.602 842.992 219.856 846.077 221.396 848.096C222.936 850.115 225.304 850.502 226.514 848.943Z" fill="white" fill-opacity="0.6"/>
        <path d="M233.545 832.376C235.042 834.687 237.436 835.44 238.807 834.06C240.177 832.679 239.991 829.531 238.443 827.136C236.895 824.74 234.577 823.925 233.223 825.399C231.869 826.874 232.03 830.064 233.545 832.376Z" fill="white" fill-opacity="0.6"/>
        <path d="M246.412 818.025C248.045 820.4 250.541 821.163 251.742 819.657C252.943 818.151 252.588 814.992 250.896 812.461C249.204 809.93 246.793 809.198 245.6 810.777C244.407 812.357 244.746 815.63 246.412 818.025Z" fill="white" fill-opacity="0.6"/>
        <path d="M258.679 797.462C257.351 798.676 257.579 801.939 259.195 804.585C260.811 807.231 263.341 808.424 264.686 807.294C266.031 806.165 265.794 802.995 264.128 800.255C262.461 797.515 260.016 796.239 258.679 797.462Z" fill="white" fill-opacity="0.6"/>
        <path d="M278.214 797.086C279.567 796.04 279.246 793.09 277.494 790.193C275.743 787.296 273.264 785.821 271.945 786.857C270.625 787.892 270.938 791.103 272.63 793.854C274.322 796.605 276.919 798.069 278.214 797.086Z" fill="white" fill-opacity="0.6"/>
        <path d="M285.726 779.452C284.381 780.173 284.685 783.165 286.411 786.072C288.137 788.98 290.785 790.789 292.113 790.131C293.442 789.472 293.179 786.658 291.394 783.646C289.609 780.633 287.054 778.751 285.726 779.452Z" fill="white" fill-opacity="0.6"/>
        <path d="M285.286 768.888C286.733 771.618 289.161 773.741 290.65 773.657C292.139 773.573 292.342 771 290.819 768.072C289.432 765.394 286.97 763.125 285.481 763.229C283.789 763.334 283.696 765.875 285.286 768.888Z" fill="white" fill-opacity="0.6"/>
        <path d="M299.905 762.842C298.264 762.466 298.264 764.652 299.905 767.653C301.428 770.383 303.898 772.883 305.37 773.155C306.842 773.427 306.893 771.251 305.277 768.375C303.797 765.677 301.394 763.177 299.905 762.842Z" fill="white" fill-opacity="0.6"/>
        <path d="M314.144 766.419C312.773 766.001 312.858 767.789 314.38 770.53C315.988 773.437 318.61 776.24 320.074 776.627C321.538 777.014 321.233 775.132 319.778 772.538C318.204 769.724 315.607 766.869 314.144 766.419Z" fill="white" fill-opacity="0.6"/>
        <path d="M328.314 772.507C326.791 771.68 326.935 773.396 328.669 776.303C330.26 778.96 332.646 781.627 334.016 782.328C335.387 783.029 335.277 781.282 333.61 778.552C331.943 775.822 329.71 773.27 328.314 772.507Z" fill="white" fill-opacity="0.6"/>
        <path d="M347.696 789.963C349.177 790.957 349.007 789.524 347.358 786.888C345.875 784.467 344.093 782.349 342.079 780.613C340.547 779.567 340.657 780.926 342.358 783.698C343.843 786.139 345.647 788.257 347.696 789.963Z" fill="white" fill-opacity="0.6"/>
        <path d="M355.86 792.902C357.401 795.28 359.194 797.391 361.19 799.178C362.636 800.297 362.408 799.052 360.733 796.532C359.136 794.07 357.277 791.885 355.209 790.036C353.99 789.147 354.261 790.434 355.86 792.902Z" fill="white" fill-opacity="0.6"/>
        <path d="M368.457 800.945C367.205 799.9 367.433 800.862 369.015 803.184C370.672 805.622 372.551 807.816 374.616 809.721C375.817 810.673 375.53 809.658 374.007 807.483C372.338 805.08 370.478 802.891 368.457 800.945Z" fill="white" fill-opacity="0.6"/>
        <path d="M387.458 820.672C388.888 821.948 388.617 821.09 386.917 818.841C385.266 816.659 383.496 814.618 381.621 812.733C380.149 811.342 380.36 812.158 382.12 814.543C383.762 816.761 385.547 818.811 387.458 820.672Z" fill="white" fill-opacity="0.6"/>
        <path d="M395.216 826.414C396.887 828.499 398.667 830.445 400.546 832.24C401.891 833.442 401.477 832.585 399.7 830.409C397.952 828.27 396.108 826.255 394.176 824.374C393.042 823.37 393.499 824.27 395.216 826.414Z" fill="white" fill-opacity="0.6"/>
        <path d="M408.084 838.139C409.788 840.1 411.575 841.947 413.439 843.672C414.776 844.843 414.327 844.069 412.509 842.04C410.69 840.011 408.16 837.375 406.95 836.287C405.74 835.2 406.333 836.12 408.084 838.139Z" fill="white" fill-opacity="0.6"/>
        <path d="M419.877 847.887C418.651 846.841 419.031 847.448 420.723 849.257C422.552 851.223 424.467 853.063 426.459 854.769C427.627 855.7 427.254 855.051 425.613 853.357C423.837 851.516 421.197 849.006 419.877 847.887Z" fill="white" fill-opacity="0.6"/>
        <path d="M440.291 864.611C441.789 865.521 441.434 864.716 439.555 862.938C437.745 861.228 435.836 859.683 433.845 858.315C432.28 857.269 432.601 858.001 434.572 859.915C436.37 861.674 438.283 863.245 440.291 864.611Z" fill="white" fill-opacity="0.6"/>
        <path d="M448.904 866.766C447.406 866.065 447.542 866.714 449.234 868.293C451.163 870.087 453.263 871.583 455.486 872.749C456.915 873.366 456.746 872.623 455.13 871.127C453.194 869.389 451.105 867.926 448.904 866.766Z" fill="white" fill-opacity="0.6"/>
        <path d="M471.128 878.71C472.753 879.192 472.507 878.187 470.629 876.619C468.803 875.098 466.808 873.91 464.707 873.094C463.015 872.55 463.193 873.418 465.164 875.102C466.994 876.669 469.005 877.885 471.128 878.71Z" fill="white" fill-opacity="0.6"/>
        <path d="M480.384 876.891C478.869 876.671 478.962 877.57 480.629 878.982C482.509 880.595 484.637 881.713 486.881 882.267C488.319 882.444 488.158 881.419 486.56 880.028C484.693 878.478 482.593 877.41 480.384 876.891Z" fill="white" fill-opacity="0.6"/>
        <path d="M496.542 880.541C498.399 882.002 500.529 882.845 502.727 882.988C504.537 882.915 504.546 881.524 502.684 880.039C500.701 878.5 498.41 877.677 496.069 877.665C494.503 877.842 494.732 879.097 496.542 880.541Z" fill="white" fill-opacity="0.6"/>
        <path d="M521.973 882.037C524.122 881.88 524.452 880.394 522.633 878.899C520.593 877.376 518.248 876.59 515.865 876.629C513.598 876.786 513.327 878.156 515.29 879.767C517.306 881.27 519.619 882.056 521.973 882.037Z" fill="white" fill-opacity="0.6"/>
        <path d="M537.007 877.999C539.092 879.642 541.523 880.486 543.995 880.426C546.533 880.311 547.379 878.836 545.865 877.288C544.35 875.74 540.789 874.663 538.335 874.935C536.034 875.196 535.467 876.535 537.007 877.999Z" fill="white" fill-opacity="0.6"/>
        <path d="M561.643 875.876C563.064 877.34 566.304 878.397 568.834 878.229C571.575 878.041 572.734 876.472 571.279 874.914C569.823 873.355 566.245 872.299 563.512 872.654C561 872.968 560.187 874.391 561.643 875.876Z" fill="white" fill-opacity="0.6"/>
        <path d="M588.326 873.219C589.586 874.715 592.86 875.792 595.55 875.594C598.461 875.374 599.924 873.763 598.647 872.163C597.369 870.563 593.757 869.506 590.821 869.893C588.122 870.238 587.031 871.703 588.326 873.219Z" fill="white" fill-opacity="0.6"/>
        <path d="M619.797 866.776C616.887 867.101 615.474 868.544 616.514 870.123C617.555 871.703 621.108 872.947 624.086 872.592C626.819 872.268 628.316 870.751 627.394 869.213C626.472 867.676 622.885 866.431 619.797 866.776Z" fill="white" fill-opacity="0.6"/>
        <path d="M645.846 867.08C646.835 868.638 649.991 869.715 652.842 869.464C655.913 869.192 657.757 867.477 656.776 865.804C655.795 864.13 652.318 863.063 649.162 863.513C646.336 863.921 644.83 865.5 645.846 867.08Z" fill="white" fill-opacity="0.6"/>
        <path d="M675.464 863.597C676.141 865.228 679.06 866.421 681.919 866.264C685.024 866.096 687.156 864.433 686.547 862.676C685.989 861.086 683.028 859.873 680.1 860.051C676.792 860.271 674.72 861.819 675.464 863.597Z" fill="white" fill-opacity="0.6"/>
        <path d="M709.558 856.746C706.377 857.018 704.347 858.712 705.083 860.501C705.819 862.289 708.611 863.283 711.403 863.042C714.414 862.791 716.479 861.013 715.861 859.267C715.243 857.52 712.452 856.505 709.558 856.746Z" fill="white" fill-opacity="0.6"/>
        <path d="M8.59984 845.994C7.25468 845.711 6.15486 847.123 6.21408 849.131C6.22687 849.971 6.46612 850.78 6.89377 851.432C7.32142 852.084 7.91297 852.541 8.57449 852.729C8.89803 852.769 9.22461 852.717 9.5293 852.577C9.83399 852.436 10.1087 852.212 10.3324 851.92C10.5561 851.628 10.7229 851.277 10.82 850.894C10.917 850.51 10.9419 850.104 10.8926 849.707C10.8926 848.863 10.6682 848.042 10.2539 847.371C9.83964 846.7 9.25844 846.216 8.59984 845.994Z" fill="white" fill-opacity="0.6"/>
        <path d="M20.0972 855.794C21.1885 856.296 22.0345 855.166 21.9415 853.357C21.9177 852.541 21.7286 851.746 21.3916 851.045C21.0545 850.343 20.5803 849.757 20.0126 849.341C18.9212 848.786 18.0414 849.926 18.1175 851.819C18.1386 852.638 18.3324 853.435 18.6797 854.132C19.0269 854.83 19.5156 855.402 20.0972 855.794Z" fill="white" fill-opacity="0.6"/>
        <path d="M27.8127 856.662C27.8706 857.47 28.0574 858.256 28.3624 858.974C28.6673 859.692 29.0843 860.328 29.5893 860.846C30.486 861.515 31.0867 860.595 30.9175 858.859C30.7483 857.122 29.9023 855.104 29.0309 854.539C28.1595 853.974 27.6773 854.957 27.8127 856.662Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.7058 863.126C35.9287 864.716 36.5159 866.193 37.3978 867.383C38.1423 868.199 38.5315 867.467 38.2438 865.814C38.0038 864.164 37.3975 862.628 36.4926 861.379C35.8412 860.72 35.4774 861.505 35.7058 863.126Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.8699 875.217C44.479 876.064 44.6397 875.416 44.2421 873.815C43.8697 872.33 43.3224 870.92 42.6178 869.632C42.0256 868.701 41.831 869.36 42.2032 871.075C42.5444 872.572 43.1097 873.977 43.8699 875.217Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.643 879.631C48.0835 881.097 48.6508 882.499 49.335 883.814C49.8765 884.766 49.8934 884.296 49.3942 882.821C48.89 881.308 48.2961 879.843 47.6176 878.438C47.2115 877.623 47.203 878.166 47.643 879.631Z" fill="white" fill-opacity="0.6"/>
        <path d="M52.44 888.835C52.9815 890.153 53.819 891.973 54.2928 892.893C54.7666 893.814 54.7073 893.573 54.0898 892.213C53.4722 890.854 52.6008 888.887 52.1693 887.946C51.7378 887.005 51.907 887.465 52.44 888.835Z" fill="white" fill-opacity="0.6"/>
        <path d="M57.0337 898.238C57.6851 899.43 58.6242 901.187 59.1233 902.118C59.6225 903.049 59.4786 902.924 58.7426 901.7C58.0066 900.476 57.0506 898.562 56.5853 897.631C56.12 896.7 56.3738 897.014 57.0337 898.238Z" fill="white" fill-opacity="0.6"/>
        <path d="M61.7459 907.505C62.4989 908.551 63.5733 910.214 64.1232 911.124C64.6731 912.034 64.5124 911.992 63.6664 910.894C62.7687 909.698 61.9404 908.426 61.1876 907.087C60.7561 906.218 61.0014 906.396 61.7459 907.505Z" fill="white" fill-opacity="0.6"/>
        <path d="M67.0334 916.238C67.8794 917.201 69.1146 918.707 69.7745 919.554C70.4344 920.401 70.3159 920.433 69.3769 919.46C68.4079 918.431 67.4897 917.33 66.6274 916.165C65.9167 915.151 66.1028 915.182 67.0334 916.238Z" fill="white" fill-opacity="0.6"/>
        <path d="M73.3955 924.261C74.3768 925.171 75.7728 926.509 76.5088 927.21C77.2448 927.911 77.0841 927.806 76.0096 926.876C74.9352 925.945 73.4293 924.543 72.744 923.811C72.0588 923.079 72.4056 923.34 73.3955 924.261Z" fill="white" fill-opacity="0.6"/>
        <path d="M80.9165 930.881C81.9402 931.676 83.5391 932.858 84.4782 933.454C85.4173 934.051 85.4596 933.946 84.3598 933.13C83.2599 932.314 81.6864 931.227 80.7557 930.599C79.8251 929.971 79.7998 930.003 80.9165 930.881Z" fill="white" fill-opacity="0.6"/>
        <path d="M90.2309 935.389C89.1396 934.981 89.1734 935.222 90.2309 935.986C91.5789 936.9 92.9956 937.649 94.461 938.224C95.5946 938.621 95.51 938.224 94.241 937.439C92.9475 936.634 91.6071 935.948 90.2309 935.389Z" fill="white" fill-opacity="0.6"/>
        <path d="M102.016 939.322C103.467 940.135 105.006 940.681 106.584 940.943C107.963 941.111 108.048 940.556 106.72 939.814C105.176 938.991 103.543 938.451 101.872 938.213C100.662 938.098 100.755 938.579 102.016 939.322Z" fill="white" fill-opacity="0.6"/>
        <path d="M116.906 936.624C115.213 937.23 114.909 938.234 116.271 939.019C117.865 939.791 119.61 939.951 121.279 939.479C122.912 938.925 123.183 937.628 121.872 936.854C120.278 936.128 118.539 936.048 116.906 936.624Z" fill="white" fill-opacity="0.6"/>
        <path d="M132.015 927.744C130.56 928.926 130.323 930.421 131.516 931.289C132.352 931.734 133.262 931.925 134.17 931.847C135.078 931.769 135.958 931.423 136.736 930.84C138.132 929.731 138.327 928.026 137.184 927.137C136.042 926.248 133.58 926.489 132.015 927.744Z" fill="white" fill-opacity="0.6"/>
        <path d="M146.897 914.115C145.315 915.653 145.095 917.734 146.44 918.78C147.785 919.826 149.824 919.439 151.27 918.121C152.717 916.803 152.962 914.46 151.66 913.393C150.357 912.327 148.335 912.714 146.897 914.115Z" fill="white" fill-opacity="0.6"/>
        <path d="M160.509 904.2C161.236 904.717 162.076 904.944 162.912 904.848C163.748 904.753 164.54 904.34 165.179 903.666C165.494 903.374 165.75 902.995 165.926 902.559C166.102 902.124 166.194 901.643 166.194 901.156C166.194 900.669 166.102 900.188 165.926 899.753C165.75 899.317 165.494 898.938 165.179 898.646C164.418 898.063 163.517 897.826 162.63 897.977C161.744 898.128 160.927 898.656 160.323 899.472C159.147 900.999 159.206 903.091 160.509 904.2Z" fill="white" fill-opacity="0.6"/>
        <path d="M172.92 885.624C172.66 885.921 172.458 886.285 172.327 886.691C172.197 887.096 172.14 887.533 172.163 887.969C172.186 888.404 172.287 888.828 172.459 889.21C172.63 889.591 172.869 889.921 173.156 890.174C174.485 891.607 176.642 891.795 177.793 890.519C178.042 890.201 178.234 889.821 178.356 889.405C178.478 888.989 178.528 888.545 178.501 888.104C178.475 887.662 178.373 887.233 178.203 886.843C178.033 886.453 177.798 886.113 177.513 885.844C176.895 885.134 176.092 884.722 175.249 884.682C174.407 884.641 173.58 884.976 172.92 885.624Z" fill="white" fill-opacity="0.6"/>
        <path d="M184.493 871.922C184.191 872.294 183.957 872.742 183.807 873.234C183.656 873.727 183.592 874.254 183.62 874.78C183.647 875.305 183.765 875.818 183.965 876.283C184.165 876.748 184.443 877.155 184.781 877.476C185.382 878.169 186.164 878.572 186.985 878.612C187.806 878.652 188.612 878.327 189.256 877.696C189.547 877.305 189.77 876.845 189.912 876.344C190.054 875.842 190.112 875.311 190.082 874.781C190.052 874.251 189.934 873.735 189.737 873.263C189.54 872.791 189.267 872.374 188.935 872.037C188.341 871.333 187.554 870.931 186.732 870.91C185.909 870.888 185.11 871.25 184.493 871.922Z" fill="white" fill-opacity="0.6"/>
        <path d="M200.922 862.927C202.208 861.411 202.09 858.451 200.677 856.652C200.396 856.259 200.055 855.939 199.674 855.711C199.292 855.482 198.878 855.35 198.454 855.321C198.031 855.291 197.607 855.366 197.208 855.541C196.808 855.716 196.44 855.986 196.126 856.338C195.492 857.195 195.158 858.327 195.198 859.488C195.237 860.648 195.647 861.742 196.337 862.53C196.921 863.298 197.717 863.765 198.567 863.839C199.417 863.912 200.258 863.587 200.922 862.927Z" fill="white" fill-opacity="0.6"/>
        <path d="M212.953 846.851C214.188 845.136 213.909 841.935 212.386 840.095C210.863 838.254 208.943 838.254 207.767 839.917C207.161 840.91 206.88 842.149 206.978 843.387C207.077 844.625 207.549 845.772 208.3 846.6C209.738 848.326 211.811 848.441 212.953 846.851Z" fill="white" fill-opacity="0.6"/>
        <path d="M225.076 830.671C226.337 828.966 226.057 825.619 224.509 823.569C222.961 821.519 220.981 821.362 219.78 823.015C218.579 824.667 218.706 827.941 220.313 830.054C221.785 831.968 223.908 832.24 225.076 830.671Z" fill="white" fill-opacity="0.6"/>
        <path d="M232.385 813.57C233.781 815.777 235.998 816.519 237.301 815.222C238.604 813.925 238.621 810.484 237.149 808.058C235.676 805.631 233.291 805.014 232.013 806.74C230.736 808.466 230.947 811.321 232.385 813.57Z" fill="white" fill-opacity="0.6"/>
        <path d="M245.092 798.739C246.548 801.071 248.815 801.97 250.109 800.83C251.404 799.69 251.361 796.176 249.813 793.645C248.265 791.114 245.862 790.256 244.568 791.888C243.274 793.519 243.637 796.375 245.092 798.739Z" fill="white" fill-opacity="0.6"/>
        <path d="M258.087 785.779C259.559 788.258 261.886 789.482 263.222 788.541C264.559 787.599 264.534 784.273 262.969 781.596C261.403 778.918 258.942 777.673 257.605 779.023C256.268 780.372 256.581 783.248 258.087 785.779Z" fill="white" fill-opacity="0.6"/>
        <path d="M276.758 779.221C278.256 778.562 278.163 775.529 276.572 772.726C275.092 770.101 272.757 768.438 271.352 769.076C269.813 769.787 269.872 772.778 271.505 775.644C272.994 778.249 275.379 779.839 276.758 779.221Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.165314 855.229L0.165314 860.166C2.01807 861.588 3.02479 861.588 3.94694 860.166C4.32818 859.529 4.53394 858.757 4.53394 857.964C4.53394 857.172 4.32818 856.4 3.94694 855.763C3.48295 855.165 2.93557 854.676 2.33109 854.319C1.93347 854.089 1.56122 854.309 0.165314 855.229Z" fill="white" fill-opacity="0.6"/>
        <path d="M284.779 747.896C283.45 748.042 283.459 750.573 284.779 753.209C286.267 756.096 288.831 758.104 290.311 758.031C291.792 757.958 291.58 755.866 290.024 753.052C288.467 750.239 286.191 747.969 284.779 747.896Z" fill="white" fill-opacity="0.6"/>
        <path d="M299.541 754.757C300.971 757.414 303.374 759.924 304.804 760.342C306.233 760.761 306.267 759.045 304.804 756.347C303.264 753.439 300.726 750.615 299.304 750.322C298.052 750.061 298.129 752.163 299.541 754.757Z" fill="white" fill-opacity="0.6"/>
        <path d="M319.143 765.342C320.497 765.896 320.353 764.296 318.83 761.618C317.307 758.941 315.074 756.284 313.678 755.583C312.282 754.883 312.35 756.849 313.949 759.631C315.548 762.413 317.824 764.809 319.143 765.342Z" fill="white" fill-opacity="0.6"/>
        <path d="M327.307 762.999C326.106 762.33 326.275 763.919 327.713 766.409C329.304 769.139 331.825 772.015 333.128 772.684C334.431 773.354 334.016 771.733 332.392 769.065C330.767 766.398 328.526 763.689 327.307 762.999Z" fill="white" fill-opacity="0.6"/>
        <path d="M341.241 775.289C342.69 777.689 344.424 779.805 346.385 781.564C347.747 782.61 347.637 781.564 346.165 779.106C344.649 776.562 342.835 774.309 340.784 772.423C339.6 771.544 339.794 772.904 341.241 775.289Z" fill="white" fill-opacity="0.6"/>
        <path d="M359.752 792.086C361.021 793.132 360.767 792.086 359.202 789.733C357.695 787.405 355.993 785.281 354.126 783.395C352.764 782.171 353.009 783.395 354.659 785.936C356.149 788.233 357.86 790.298 359.752 792.086Z" fill="white" fill-opacity="0.6"/>
        <path d="M367.552 797.243C369.11 799.496 370.829 801.572 372.687 803.445C374.007 804.658 373.829 803.926 372.315 801.74C370.675 799.366 368.878 797.166 366.943 795.161C365.801 794.105 366.071 795.078 367.552 797.243Z" fill="white" fill-opacity="0.6"/>
        <path d="M380.377 809.365C381.98 811.531 383.705 813.555 385.538 815.421C386.875 816.718 386.705 816.143 385.191 814.114C383.491 811.835 381.683 809.684 379.777 807.671C378.626 806.541 378.897 807.336 380.377 809.365Z" fill="white" fill-opacity="0.6"/>
        <path d="M398.397 827.679C399.683 828.903 399.429 828.338 397.856 826.403C396.282 824.468 393.626 821.477 392.441 820.295C391.257 819.113 391.68 819.95 393.22 821.885C394.859 823.932 396.587 825.866 398.397 827.679Z" fill="white" fill-opacity="0.6"/>
        <path d="M411.273 839.718C412.466 840.764 412.069 840.157 410.377 838.254C408.685 836.35 406.409 833.934 405.199 832.773C403.989 831.612 404.353 832.313 406.121 834.342C407.889 836.371 410.114 838.672 411.273 839.718Z" fill="white" fill-opacity="0.6"/>
        <path d="M418.989 845.91C420.693 847.724 422.493 849.394 424.378 850.91C425.808 851.955 425.368 851.181 423.388 849.163C421.683 847.413 419.898 845.785 418.041 844.289C416.908 843.431 417.331 844.163 418.989 845.91Z" fill="white" fill-opacity="0.6"/>
        <path d="M433.236 855.972C434.999 857.703 436.898 859.213 438.904 860.48C440.385 861.317 440.241 860.679 438.574 859.036C436.73 857.23 434.747 855.653 432.652 854.33C431.324 853.619 431.595 854.351 433.236 855.972Z" fill="white" fill-opacity="0.6"/>
        <path d="M454.513 868.052C456.002 868.617 455.816 867.822 454.09 866.243C452.312 864.627 450.373 863.302 448.32 862.3C446.73 861.62 446.924 862.519 448.743 864.182C450.51 865.8 452.453 867.103 454.513 868.052Z" fill="white" fill-opacity="0.6"/>
        <path d="M470.181 873.188C471.746 873.585 471.678 872.707 470.071 871.232C468.251 869.594 466.199 868.391 464.022 867.686C462.634 867.383 462.787 868.335 464.377 869.778C466.134 871.328 468.099 872.483 470.181 873.188Z" fill="white" fill-opacity="0.6"/>
        <path d="M479.868 872.77C481.562 874.254 483.505 875.248 485.553 875.677C487.118 875.834 487.245 874.694 485.671 873.251C483.865 871.675 481.773 870.672 479.58 870.333C478.209 870.249 478.319 871.378 479.868 872.77Z" fill="white" fill-opacity="0.6"/>
        <path d="M496.864 872.634C498.741 874.198 500.938 875.072 503.201 875.154C505.214 875.154 505.654 873.941 504.173 872.477C502.195 870.738 499.833 869.792 497.405 869.768C495.603 869.893 495.358 871.232 496.864 872.634Z" fill="white" fill-opacity="0.6"/>
        <path d="M518.978 868.314C516.846 868.481 516.22 869.893 517.616 871.305C519.743 873.056 522.273 873.904 524.824 873.721C527.024 873.46 527.481 872.152 525.865 870.646C523.818 869.012 521.413 868.197 518.978 868.314Z" fill="white" fill-opacity="0.6"/>
        <path d="M541.677 869.496C543.174 870.939 546.397 871.933 548.834 871.682C551.27 871.431 552.218 870.082 550.814 868.544C549.409 867.006 545.78 865.825 543.25 866.201C540.915 866.546 540.205 868.073 541.677 869.496Z" fill="white" fill-opacity="0.6"/>
        <path d="M575.272 869.109C577.996 868.837 579.18 867.435 577.97 865.887C576.761 864.339 573.081 863.116 570.297 863.513C567.759 863.869 566.676 865.438 567.979 866.891C569.282 868.345 572.615 869.37 575.272 869.109Z" fill="white" fill-opacity="0.6"/>
        <path d="M596.312 863.973C597.581 865.469 600.897 866.431 603.689 866.065C606.481 865.699 607.919 864.277 606.76 862.687C605.601 861.097 601.938 859.915 599.011 860.396C596.303 860.867 595.068 862.498 596.312 863.973Z" fill="white" fill-opacity="0.6"/>
        <path d="M629.416 856.976C626.599 857.3 624.882 858.953 625.761 860.49C626.641 862.028 630.313 863.241 633.375 862.802C636.311 862.383 637.682 860.867 636.59 859.235C635.499 857.604 632.335 856.641 629.416 856.976Z" fill="white" fill-opacity="0.6"/>
        <path d="M656.158 857.049C656.911 858.639 660.016 859.727 662.994 859.465C665.972 859.204 667.901 857.729 667.283 856.076C666.666 854.424 663.307 853.064 660.135 853.441C657.292 853.786 655.414 855.48 656.158 857.049Z" fill="white" fill-opacity="0.6"/>
        <path d="M690.971 849.895C688.146 850.219 686.115 851.924 686.682 853.535C687.249 855.146 690.625 856.432 693.746 855.982C696.716 855.564 698.349 853.985 697.587 852.29C696.826 850.596 693.89 849.55 690.971 849.895Z" fill="white" fill-opacity="0.6"/>
        <path d="M716.792 850.125C717.198 851.767 719.973 852.97 722.824 852.75C725.675 852.531 727.798 851.046 727.511 849.32C727.223 847.594 724.33 846.13 721.259 846.464C718.509 846.757 716.394 848.483 716.792 850.125Z" fill="white" fill-opacity="0.6"/>
        <path d="M12.3224 840.921C12.3213 841.744 12.5165 842.549 12.8837 843.236C13.2509 843.922 13.7737 844.459 14.3867 844.78C15.5119 845.178 16.3494 844.174 16.3579 842.417C16.3663 840.659 15.4357 838.547 14.2852 838.233C13.2615 837.929 12.3139 839.216 12.3224 840.921Z" fill="white" fill-opacity="0.6"/>
        <path d="M22.8296 845.282C22.8723 846.076 23.0624 846.846 23.3852 847.534C23.708 848.221 24.1549 848.807 24.6909 849.246C25.6638 849.78 26.2221 848.682 26.0106 846.726C25.816 844.969 24.9954 843.159 24.1663 842.793C23.3372 842.427 22.6604 843.536 22.8296 845.282Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.7788 855.459C33.5064 856.15 33.8871 855.459 33.7009 853.786C33.5456 852.179 33.0199 850.662 32.1866 849.414C31.4844 848.588 30.9514 849.508 31.146 851.265C31.3019 852.847 31.8758 854.322 32.7788 855.459Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.7785 858.702C38.0228 860.27 38.5395 861.755 39.2929 863.053C39.8851 863.942 40.1389 863.471 39.8767 862.007C39.6038 860.34 39.0816 858.751 38.3369 857.321C37.8293 856.516 37.5501 857.196 37.7785 858.702Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.3454 866.389C42.9309 865.511 42.8293 866.002 43.1424 867.435C43.5227 869.024 44.0657 870.546 44.7582 871.964C45.232 872.801 45.2489 872.299 44.8429 870.772C44.4436 869.262 43.9427 867.796 43.3454 866.389Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.6599 877.006C48.0998 878.355 48.8444 880.269 49.2843 881.262C49.7242 882.256 49.7073 882.057 49.2251 880.75C48.7428 879.443 47.9307 877.215 47.533 876.2C47.1354 875.186 47.2284 875.646 47.6599 877.006Z" fill="white" fill-opacity="0.6"/>
        <path d="M51.7884 887.036C52.3552 888.343 53.1674 890.174 53.565 891.094C53.9627 892.015 53.8019 891.826 53.1843 890.561C52.5667 889.295 51.6615 887.172 51.3231 886.241C50.9847 885.31 51.2215 885.76 51.7884 887.036Z" fill="white" fill-opacity="0.6"/>
        <path d="M55.7308 896.826C56.3738 897.935 57.3128 899.702 57.7781 900.696C58.2434 901.689 58.0996 901.679 57.4059 900.591C56.5907 899.298 55.8635 897.923 55.2316 896.481C54.8509 895.55 55.0878 895.717 55.7308 896.826Z" fill="white" fill-opacity="0.6"/>
        <path d="M62.592 910.214C63.0657 911.061 62.7696 910.862 61.8982 909.754C61.0325 908.643 60.2457 907.443 59.5463 906.166C59.0726 905.225 59.4363 905.476 60.2993 906.668C61.1124 907.8 61.8777 908.984 62.592 910.214Z" fill="white" fill-opacity="0.6"/>
        <path d="M68.1841 918.738C68.7679 919.502 68.4972 919.324 67.5328 918.33C66.5625 917.325 65.6468 916.242 64.7916 915.088C64.1825 914.22 64.5379 914.429 65.5023 915.517C66.4668 916.604 67.6173 917.985 68.1841 918.738Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.8982 923.267C72.8965 924.187 74.2924 925.474 75.0199 926.112C75.7475 926.75 75.5529 926.572 74.5208 925.662C73.4887 924.752 71.8643 923.309 71.2044 922.681C70.5446 922.054 70.9253 922.357 71.8982 923.267Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.6137 928.926C78.7169 928.413 78.7677 928.633 79.7575 929.417C80.9133 930.338 82.1173 931.162 83.3615 931.886C84.4867 932.513 84.4613 932.262 83.26 931.352C82.0587 930.442 80.5528 929.469 79.6137 928.926Z" fill="white" fill-opacity="0.6"/>
        <path d="M89.901 933.925C91.1942 934.818 92.5765 935.496 94.0126 935.944C95.2478 936.289 95.417 935.944 94.3594 935.18C92.9726 934.258 91.4974 933.555 89.9687 933.088C88.8942 932.785 88.8689 933.182 89.901 933.925Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.955 758.345C272.461 760.897 274.77 762.392 276.031 761.713C277.478 760.97 277.19 758.125 275.413 755.207C273.823 752.603 271.513 751.107 270.337 751.996C269.161 752.885 269.474 755.824 270.955 758.345Z" fill="white" fill-opacity="0.6"/>
        <path d="M262.173 769.902C263.578 769.013 263.586 766.336 262.173 763.627C260.684 760.729 258.256 759.129 256.894 760.238C255.676 761.231 255.778 764.369 257.123 766.869C258.468 769.369 260.803 770.76 262.173 769.902Z" fill="white" fill-opacity="0.6"/>
        <path d="M244.221 779.504C245.651 781.826 247.859 782.642 249.094 781.355C250.329 780.069 250.228 777.171 248.789 774.598C247.25 771.879 244.881 770.885 243.713 772.507C242.546 774.128 242.791 777.224 244.221 779.504Z" fill="white" fill-opacity="0.6"/>
        <path d="M231.379 794.126C232.698 796.396 234.873 797.107 236.159 795.726C237.445 794.346 237.461 791.375 236.159 788.949C234.712 786.302 232.402 785.434 231.083 787.087C229.957 788.583 230.101 791.898 231.379 794.126Z" fill="white" fill-opacity="0.6"/>
        <path d="M219.205 810.913C220.567 812.921 222.682 813.235 223.858 811.624C225.034 810.014 224.941 807.012 223.57 804.794C222.106 802.41 219.839 802.002 218.689 803.926C217.639 805.694 217.868 808.936 219.205 810.913Z" fill="white" fill-opacity="0.6"/>
        <path d="M207.014 828.035C208.232 829.991 210.33 830.357 211.574 828.84C212.817 827.324 212.902 824.427 211.675 822.303C210.33 819.981 208.139 819.532 206.87 821.331C205.753 822.92 205.812 826.1 207.014 828.035Z" fill="white" fill-opacity="0.6"/>
        <path d="M195.296 845.45C195.549 845.867 195.869 846.214 196.236 846.468C196.603 846.723 197.01 846.88 197.429 846.928C197.849 846.976 198.272 846.915 198.671 846.749C199.07 846.583 199.436 846.315 199.746 845.962C200.36 845.114 200.708 844.022 200.727 842.884C200.746 841.746 200.434 840.638 199.848 839.76C198.545 837.668 196.396 837.354 195.161 839.132C194.623 840.059 194.345 841.179 194.37 842.319C194.394 843.46 194.72 844.559 195.296 845.45Z" fill="white" fill-opacity="0.6"/>
        <path d="M184.002 862.247C184.292 862.597 184.635 862.871 185.012 863.054C185.388 863.237 185.791 863.325 186.196 863.312C186.6 863.3 186.999 863.187 187.367 862.981C187.736 862.774 188.068 862.479 188.342 862.111C188.882 861.281 189.15 860.23 189.094 859.167C189.037 858.104 188.661 857.106 188.038 856.369C187.759 855.953 187.408 855.621 187.011 855.396C186.614 855.171 186.181 855.06 185.744 855.071C185.307 855.082 184.878 855.215 184.489 855.46C184.099 855.705 183.759 856.055 183.495 856.484C183.026 857.369 182.82 858.427 182.912 859.478C183.005 860.53 183.39 861.509 184.002 862.247Z" fill="white" fill-opacity="0.6"/>
        <path d="M172.522 876.89C173.087 877.553 173.827 877.939 174.605 877.977C175.383 878.016 176.146 877.704 176.752 877.1C177.029 876.768 177.249 876.37 177.397 875.929C177.545 875.489 177.619 875.016 177.615 874.539C177.61 874.062 177.526 873.592 177.369 873.156C177.212 872.721 176.985 872.329 176.701 872.006C176.426 871.615 176.09 871.297 175.712 871.072C175.335 870.847 174.924 870.72 174.505 870.697C174.087 870.675 173.669 870.758 173.277 870.941C172.885 871.125 172.527 871.405 172.226 871.765C171.741 872.507 171.502 873.449 171.557 874.4C171.612 875.351 171.957 876.241 172.522 876.89Z" fill="white" fill-opacity="0.6"/>
        <path d="M160.796 890.488C161.456 891.059 162.254 891.335 163.058 891.273C163.861 891.21 164.624 890.811 165.221 890.142C165.505 889.848 165.734 889.48 165.891 889.064C166.048 888.649 166.129 888.195 166.129 887.737C166.129 887.278 166.048 886.825 165.891 886.409C165.734 885.994 165.505 885.626 165.221 885.331C164.588 884.622 163.756 884.245 162.902 884.28C162.047 884.315 161.238 884.759 160.644 885.519C160.385 885.855 160.183 886.251 160.05 886.684C159.917 887.118 159.855 887.58 159.869 888.043C159.884 888.507 159.973 888.962 160.132 889.382C160.291 889.802 160.517 890.178 160.796 890.488Z" fill="white" fill-opacity="0.6"/>
        <path d="M147.463 905.967C148.656 907.013 150.763 906.595 152.083 905.099C153.402 903.604 153.538 901.48 152.362 900.298C151.186 899.117 148.885 899.409 147.573 901.135C146.262 902.861 146.296 904.963 147.463 905.967Z" fill="white" fill-opacity="0.6"/>
        <path d="M132.963 920.684C134.08 921.614 136.254 921.353 137.709 920.046C139.164 918.738 139.486 916.824 138.386 915.747C137.286 914.669 134.917 914.931 133.437 916.427C132.108 917.797 131.863 919.753 132.963 920.684Z" fill="white" fill-opacity="0.6"/>
        <path d="M118.386 928.716C117.007 929.658 116.762 931.08 117.879 931.854C118.698 932.282 119.584 932.479 120.472 932.43C121.36 932.381 122.229 932.088 123.014 931.572C124.418 930.526 124.494 929.135 123.208 928.329C122.429 927.962 121.595 927.807 120.763 927.874C119.931 927.941 119.12 928.228 118.386 928.716Z" fill="white" fill-opacity="0.6"/>
        <path d="M107.43 937.565C108.928 937.335 109.122 936.456 107.862 935.682C106.328 934.889 104.661 934.572 103.006 934.762C101.441 934.961 101.364 935.546 102.667 936.331C104.147 937.228 105.784 937.652 107.43 937.565Z" fill="white" fill-opacity="0.6"/>
        <path d="M448.21 857.572C446.73 857.081 446.84 857.907 448.515 859.518C450.205 861.128 452.101 862.38 454.124 863.22C455.494 863.628 455.317 862.645 453.777 861.191C452.089 859.626 450.21 858.404 448.21 857.572Z" fill="white" fill-opacity="0.6"/>
        <path d="M432.508 851.903C434.207 853.654 436.074 855.14 438.066 856.327C439.589 857.154 439.657 856.526 438.236 855.041C436.531 853.332 434.675 851.869 432.703 850.679C431.095 849.811 430.994 850.282 432.508 851.903Z" fill="white" fill-opacity="0.6"/>
        <path d="M422.991 847.217C424.395 848.211 424.344 847.667 422.906 846.098C421.21 844.315 419.412 842.686 417.525 841.224C416.045 840.178 416.079 840.586 417.61 842.27C419.285 844.108 421.085 845.763 422.991 847.217Z" fill="white" fill-opacity="0.6"/>
        <path d="M409.37 836.214C410.504 837.26 410.216 836.622 408.668 834.896C407.119 833.171 404.818 830.713 403.592 829.562C402.365 828.412 402.686 828.955 404.302 830.849C405.917 832.742 407.609 834.532 409.37 836.214Z" fill="white" fill-opacity="0.6"/>
        <path d="M396.519 823.789C397.78 825.075 397.627 824.625 396.214 822.868C394.802 821.111 392.534 818.454 391.257 817.105C389.979 815.756 390.03 816.059 391.511 818.005C392.991 819.95 395.208 822.46 396.519 823.789Z" fill="white" fill-opacity="0.6"/>
        <path d="M384.074 811.237C385.191 812.346 384.853 811.593 383.389 809.637C381.925 807.681 379.667 804.92 378.499 803.696C377.332 802.473 377.56 803.142 379.134 805.297C380.678 807.403 382.328 809.387 384.074 811.237Z" fill="white" fill-opacity="0.6"/>
        <path d="M366.24 792.024C367.755 794.272 369.41 796.37 371.19 798.299C372.433 799.565 372.289 798.895 370.91 796.887C369.388 794.668 367.752 792.572 366.012 790.612C364.709 789.231 364.794 789.848 366.24 792.024Z" fill="white" fill-opacity="0.6"/>
        <path d="M358.347 785.853C359.599 787.045 359.464 786.208 358.11 784.085C356.637 781.792 355.001 779.667 353.22 777.736C351.909 776.429 351.977 777.213 353.415 779.504C354.878 781.825 356.531 783.952 358.347 785.853Z" fill="white" fill-opacity="0.6"/>
        <path d="M345.463 774.389C346.63 775.32 346.385 774.117 344.972 771.837C343.565 769.503 341.928 767.395 340.099 765.562C338.855 764.516 339.024 765.635 340.53 768.135C341.935 770.489 343.595 772.593 345.463 774.389Z" fill="white" fill-opacity="0.6"/>
        <path d="M327.248 757.968C328.703 760.478 331.021 763.302 332.197 764.055C333.373 764.809 333.128 763.355 331.732 760.991C330.389 758.632 328.736 756.567 326.842 754.883C325.581 754.004 325.7 755.353 327.248 757.968Z" fill="white" fill-opacity="0.6"/>
        <path d="M313.467 749.235C314.854 751.829 317.121 754.558 318.45 755.312C319.778 756.065 319.693 754.538 318.374 752.174C317.054 749.81 314.778 746.881 313.433 746.065C312.088 745.25 312.054 746.62 313.467 749.235Z" fill="white" fill-opacity="0.6"/>
        <path d="M299.474 743.032C300.853 745.678 303.145 748.262 304.55 748.816C305.954 749.371 305.861 747.561 304.55 745.072C303.238 742.582 300.946 739.842 299.558 739.256C298.171 738.671 298.052 740.344 299.474 743.032Z" fill="white" fill-opacity="0.6"/>
        <path d="M284.973 735.135C283.611 735.01 283.67 737.06 285.168 739.842C286.665 742.624 289 744.852 290.244 744.915C291.487 744.978 291.369 742.76 289.99 740.219C288.611 737.677 286.31 735.251 284.973 735.135Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.583 735.69C269.204 736.098 269.204 738.618 270.583 741.317C271.962 744.015 274.254 745.804 275.582 745.396C276.911 744.988 276.86 742.363 275.582 739.821C274.305 737.28 271.936 735.282 270.583 735.69Z" fill="white" fill-opacity="0.6"/>
        <path d="M469.614 867.268C471.111 867.582 471.137 866.546 469.707 865.176C468.079 863.645 466.208 862.554 464.216 861.976C462.634 861.641 462.524 862.519 464.073 864.015C465.709 865.579 467.599 866.689 469.614 867.268Z" fill="white" fill-opacity="0.6"/>
        <path d="M479.961 865.626C481.691 867.165 483.752 868.035 485.883 868.126C487.515 868.011 487.668 866.64 486.179 865.249C484.411 863.729 482.324 862.875 480.172 862.791C478.429 862.886 478.353 864.13 479.961 865.626Z" fill="white" fill-opacity="0.6"/>
        <path d="M499.334 864.705C501.251 866.306 503.528 867.119 505.84 867.027C507.769 866.808 508.217 865.333 506.745 863.89C504.791 862.3 502.484 861.507 500.146 861.62C498.057 861.871 497.718 863.189 499.334 864.705Z" fill="white" fill-opacity="0.6"/>
        <path d="M528.674 865.197C531.051 865.082 532.117 863.68 530.924 862.195C529.731 860.71 526.576 859.58 524.105 859.716C521.635 859.852 520.611 861.149 521.931 862.687C523.251 864.224 526.203 865.323 528.674 865.197Z" fill="white" fill-opacity="0.6"/>
        <path d="M547.785 860.375C549.062 861.902 552.243 862.959 554.849 862.718C557.455 862.478 558.648 860.94 557.489 859.476C556.33 858.011 553.013 856.924 550.34 857.164C547.667 857.405 546.508 858.827 547.785 860.375Z" fill="white" fill-opacity="0.6"/>
        <path d="M583.038 859.758C585.737 859.476 587.268 857.865 586.219 856.38C585.17 854.895 581.803 853.817 578.96 854.1C576.118 854.382 574.688 855.878 575.864 857.447C577.04 859.016 580.263 860.051 583.038 859.758Z" fill="white" fill-opacity="0.6"/>
        <path d="M605.567 853.995C606.489 855.627 609.543 856.704 612.53 856.474C615.516 856.244 617.25 854.654 616.481 853.085C615.711 851.516 612.462 850.397 609.459 850.648C606.455 850.899 604.653 852.374 605.567 853.995Z" fill="white" fill-opacity="0.6"/>
        <path d="M640.076 847.071C637.039 847.521 635.533 849.163 636.607 850.763C637.682 852.363 641.006 853.326 643.84 852.855C646.675 852.384 648.35 850.638 647.377 849.121C646.404 847.604 643.054 846.674 640.076 847.071Z" fill="white" fill-opacity="0.6"/>
        <path d="M667.774 846.799C668.409 848.483 671.327 849.602 674.347 849.33C677.368 849.058 679.381 847.395 678.865 845.774C678.349 844.153 675.295 842.981 672.249 843.264C669.204 843.546 667.14 845.105 667.774 846.799Z" fill="white" fill-opacity="0.6"/>
        <path d="M699 843.285C699.584 844.969 702.503 846.088 705.404 845.753C708.306 845.418 710.328 843.661 709.837 842.04C709.347 840.419 706.36 839.279 703.382 839.614C700.404 839.948 698.408 841.58 699 843.285Z" fill="white" fill-opacity="0.6"/>
        <path d="M6.21408 830.503C6.19782 831.335 6.40725 832.149 6.80899 832.817C7.21072 833.484 7.78162 833.966 8.43063 834.185C9.58121 834.405 10.5626 833.087 10.5457 831.382C10.5574 830.554 10.353 829.744 9.9629 829.072C9.57276 828.399 9.01763 827.9 8.37987 827.648C7.187 827.387 6.20562 828.673 6.21408 830.503Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.6605 833.61C17.6409 834.425 17.7917 835.233 18.0985 835.955C18.4053 836.676 18.8577 837.288 19.4117 837.731C20.3254 838.264 21.1038 837.271 21.1038 835.639C21.1038 834.007 20.317 832.02 19.4117 831.455C18.5065 830.89 17.652 831.832 17.6605 833.61Z" fill="white" fill-opacity="0.6"/>
        <path d="M27.9058 837.333C27.1613 836.57 26.6114 837.333 26.6876 839.059C26.7415 840.688 27.2809 842.234 28.2019 843.4C28.9379 844.101 29.4709 843.337 29.3779 841.726C29.2962 840.1 28.7782 838.554 27.9058 837.333Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.3861 844.916C33.8277 844.1 33.5401 844.801 33.7008 846.475C33.875 848.06 34.3664 849.566 35.1306 850.857C35.6889 851.6 35.9766 850.857 35.782 849.351C35.5737 847.768 35.0981 846.257 34.3861 844.916Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.1745 855.114C39.4532 856.667 39.915 858.162 40.545 859.549C40.9934 860.407 41.1034 859.873 40.8073 858.419C40.4946 856.868 40.0464 855.364 39.4706 853.932C39.0391 852.949 38.9038 853.524 39.1745 855.114Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.4977 864.674C43.894 866.184 44.3659 867.661 44.9105 869.098C45.2997 870.092 45.2912 869.789 44.9105 868.45C44.5298 867.111 43.8953 865.03 43.5315 863.984C43.1677 862.938 43.1339 863.21 43.4977 864.674Z" fill="white" fill-opacity="0.6"/>
        <path d="M48.7599 879.422C49.0729 880.321 48.8868 879.976 48.3792 878.669C47.8715 877.361 47.1778 875.353 46.8817 874.37C46.5856 873.387 46.721 873.711 47.2286 875.154C47.7362 876.598 48.4384 878.512 48.7599 879.422Z" fill="white" fill-opacity="0.6"/>
        <path d="M50.6887 885.697C51.2894 887.005 52.0931 888.887 52.3807 889.776C52.6684 890.665 52.3807 890.352 51.7716 889.128C51.1211 887.815 50.5553 886.44 50.0796 885.017C49.8089 884.034 50.0542 884.327 50.6887 885.697Z" fill="white" fill-opacity="0.6"/>
        <path d="M56.2725 899.828C56.6278 900.706 56.2724 900.487 55.5872 899.368C54.8275 898.16 54.1646 896.864 53.6075 895.498C53.2606 894.535 53.5483 894.744 54.2843 895.989C55.0133 897.214 55.6774 898.497 56.2725 899.828Z" fill="white" fill-opacity="0.6"/>
        <path d="M60.7899 909.189C61.2806 910.057 61.0268 909.963 60.2316 908.98C59.3602 907.878 58.5675 906.684 57.8627 905.413C57.372 904.461 57.6174 904.555 58.4211 905.633C59.2628 906.764 60.0536 907.951 60.7899 909.189Z" fill="white" fill-opacity="0.6"/>
        <path d="M63.1843 914.115C62.5921 913.32 62.8459 913.498 63.768 914.513C64.6902 915.527 65.9084 916.981 66.5091 917.713C67.1098 918.445 66.8306 918.236 65.9423 917.305C65.054 916.374 63.768 914.889 63.1843 914.115Z" fill="white" fill-opacity="0.6"/>
        <path d="M70.1383 921.447C69.36 920.809 69.5376 921.029 70.5275 921.97C71.5173 922.911 72.9386 924.167 73.7169 924.763C74.4953 925.359 74.3176 925.087 73.3701 924.229C72.4225 923.372 70.9082 922.085 70.1383 921.447Z" fill="white" fill-opacity="0.6"/>
        <path d="M82.896 930.055C83.8605 930.453 83.852 930.055 82.896 929.26C81.7167 928.335 80.4672 927.553 79.1651 926.928C78.1584 926.489 78.2091 926.813 79.2582 927.691C80.3959 928.644 81.6161 929.437 82.896 930.055Z" fill="white" fill-opacity="0.6"/>
        <path d="M90.4171 931.394C91.7244 932.316 93.1587 932.929 94.6472 933.203C95.7808 933.329 95.84 932.712 94.7402 931.917C93.4052 931.032 91.9572 930.438 90.4594 930.16C89.2412 929.992 89.2412 930.515 90.4171 931.394Z" fill="white" fill-opacity="0.6"/>
        <path d="M104.495 930.986C105.968 931.879 107.659 932.054 109.232 931.478C110.645 930.871 110.924 929.469 109.892 928.664C108.404 927.844 106.718 927.758 105.18 928.423C103.682 929.072 103.361 930.149 104.495 930.986Z" fill="white" fill-opacity="0.6"/>
        <path d="M119.934 922.294C120.691 922.701 121.52 922.855 122.339 922.741C123.159 922.627 123.94 922.25 124.604 921.646C125.933 920.412 126.102 918.508 124.985 917.619C123.868 916.73 121.745 917.159 120.349 918.445C118.953 919.732 118.733 921.395 119.934 922.294Z" fill="white" fill-opacity="0.6"/>
        <path d="M134.503 908.007C135.552 909.157 137.54 908.927 138.978 907.474C140.416 906.02 140.67 903.781 139.689 902.599C138.707 901.418 136.677 901.71 135.256 903.217C133.834 904.723 133.445 906.856 134.503 908.007Z" fill="white" fill-opacity="0.6"/>
        <path d="M148.885 886.534C147.548 888.082 147.294 890.352 148.36 891.669C149.426 892.987 151.355 892.83 152.683 891.356C153.29 890.747 153.677 889.867 153.761 888.907C153.845 887.947 153.619 886.985 153.132 886.231C152.108 884.913 150.162 885.028 148.885 886.534Z" fill="white" fill-opacity="0.6"/>
        <path d="M160.864 871.546C160.607 871.866 160.406 872.248 160.272 872.668C160.138 873.088 160.075 873.537 160.086 873.988C160.097 874.439 160.182 874.883 160.336 875.292C160.49 875.701 160.71 876.067 160.982 876.367C161.512 877.024 162.222 877.409 162.971 877.448C163.721 877.487 164.454 877.176 165.026 876.577C165.513 875.878 165.768 874.977 165.74 874.056C165.712 873.134 165.402 872.26 164.874 871.608C164.342 870.96 163.627 870.591 162.879 870.579C162.132 870.567 161.409 870.914 160.864 871.546Z" fill="white" fill-opacity="0.6"/>
        <path d="M172.124 862.049C172.387 862.403 172.706 862.685 173.061 862.878C173.416 863.072 173.8 863.171 174.189 863.171C174.577 863.171 174.961 863.072 175.316 862.878C175.671 862.685 175.99 862.403 176.253 862.049C176.742 861.21 176.986 860.19 176.943 859.157C176.9 858.125 176.573 857.141 176.016 856.369C175.75 856.021 175.426 855.746 175.067 855.565C174.708 855.383 174.322 855.298 173.934 855.316C173.546 855.334 173.166 855.454 172.819 855.668C172.472 855.882 172.166 856.185 171.921 856.558C171.413 857.349 171.154 858.341 171.191 859.35C171.228 860.359 171.56 861.318 172.124 862.049Z" fill="white" fill-opacity="0.6"/>
        <path d="M183.266 839.132C182.695 839.996 182.383 841.076 182.383 842.192C182.383 843.307 182.695 844.388 183.266 845.251C183.498 845.665 183.803 846.009 184.157 846.258C184.512 846.506 184.907 846.653 185.314 846.686C185.721 846.719 186.128 846.638 186.506 846.45C186.884 846.261 187.222 845.969 187.496 845.596C188.044 844.702 188.34 843.611 188.34 842.49C188.34 841.368 188.044 840.277 187.496 839.383C187.265 838.965 186.958 838.618 186.599 838.371C186.241 838.124 185.842 837.983 185.432 837.958C185.022 837.934 184.613 838.027 184.238 838.231C183.862 838.435 183.529 838.743 183.266 839.132Z" fill="white" fill-opacity="0.6"/>
        <path d="M199.137 827.617C199.645 826.599 199.889 825.414 199.838 824.219C199.787 823.025 199.442 821.879 198.85 820.933C198.604 820.514 198.282 820.173 197.909 819.938C197.536 819.702 197.124 819.58 196.705 819.58C196.286 819.58 195.874 819.702 195.501 819.938C195.128 820.173 194.806 820.514 194.56 820.933C194.017 821.915 193.749 823.089 193.797 824.277C193.844 825.465 194.204 826.601 194.823 827.512C195.067 827.932 195.388 828.275 195.759 828.516C196.129 828.756 196.54 828.886 196.959 828.897C197.377 828.907 197.792 828.796 198.17 828.574C198.548 828.352 198.879 828.024 199.137 827.617Z" fill="white" fill-opacity="0.6"/>
        <path d="M210.829 809.658C211.98 808.058 211.921 804.794 210.736 802.734C209.552 800.673 207.513 800.349 206.337 802.054C205.747 803.052 205.436 804.26 205.45 805.495C205.463 806.731 205.801 807.928 206.413 808.905C207.665 810.986 209.645 811.321 210.829 809.658Z" fill="white" fill-opacity="0.6"/>
        <path d="M218.257 784.043C217.073 785.738 217.157 788.844 218.477 791.051C219.797 793.258 221.861 793.655 223.012 792.034C224.162 790.413 224.019 787.076 222.775 784.953C221.531 782.83 219.425 782.37 218.257 784.043Z" fill="white" fill-opacity="0.6"/>
        <path d="M230.888 774.504C232.242 776.795 234.39 777.443 235.533 775.948C236.675 774.452 236.505 771.136 235.203 768.898C233.9 766.66 231.768 765.959 230.583 767.497C229.399 769.034 229.509 772.13 230.888 774.504Z" fill="white" fill-opacity="0.6"/>
        <path d="M243.468 759.663C244.779 762.246 246.852 763.417 248.223 762.392C249.593 761.367 249.551 758.334 248.316 755.803C247.081 753.272 244.932 752.006 243.587 753.084C242.241 754.161 242.157 757.09 243.468 759.663Z" fill="white" fill-opacity="0.6"/>
        <path d="M256.843 748.618C258.205 751.201 260.447 752.613 261.725 751.755C263.002 750.898 262.909 747.969 261.615 745.48C260.321 742.99 258.045 741.453 256.742 742.342C255.439 743.231 255.481 745.992 256.843 748.618Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.0637329 839.258C0.0637327 841.423 0.385173 842.166 1.39193 842.448C1.91745 842.651 2.48496 842.609 2.98698 842.329C3.489 842.05 3.89072 841.552 4.11612 840.931C4.37106 840.202 4.42636 839.389 4.27366 838.617C4.12097 837.845 3.76862 837.155 3.27011 836.653C2.73693 836.219 2.11512 835.979 1.47656 835.963C0.630548 835.963 0.156751 836.706 0.0721497 837.783C-0.0124518 838.306 0.063733 838.766 0.0637329 839.258Z" fill="white" fill-opacity="0.6"/>
        <path d="M258.019 709.217C256.75 709.51 256.48 711.832 257.461 714.196C258.552 716.8 260.701 718.599 262.046 718.118C263.392 717.637 263.375 715.441 262.292 713.056C261.209 710.671 259.28 708.935 258.019 709.217Z" fill="white" fill-opacity="0.6"/>
        <path d="M248.079 725.262C249.348 724.582 249.542 722.061 248.544 719.666C247.546 717.271 245.786 715.775 244.433 716.319C243.079 716.863 242.741 719.854 243.874 722.354C244.915 724.687 246.801 725.952 248.079 725.262Z" fill="white" fill-opacity="0.6"/>
        <path d="M230.998 728.327C229.881 729.571 229.78 732.51 230.803 734.728C231.928 737.165 234.027 738.022 235.169 736.558C236.311 735.094 236.167 732.374 235.042 730.136C233.917 727.898 232.106 727.092 230.998 728.327Z" fill="white" fill-opacity="0.6"/>
        <path d="M218.359 750.72C219.442 752.812 221.269 753.22 222.36 751.766C223.452 750.312 223.477 747.31 222.436 745.135C221.311 742.771 219.323 742.206 218.257 743.995C217.768 745.017 217.518 746.187 217.536 747.375C217.554 748.563 217.839 749.721 218.359 750.72Z" fill="white" fill-opacity="0.6"/>
        <path d="M210.152 769.275C210.664 768.259 210.935 767.087 210.935 765.891C210.935 764.695 210.664 763.523 210.152 762.507C209.137 760.478 207.42 760.07 206.32 761.639C205.22 763.208 205.085 766.796 206.235 768.856C207.386 770.917 209.103 771 210.152 769.275Z" fill="white" fill-opacity="0.6"/>
        <path d="M194.569 788.53C194.765 788.935 195.043 789.271 195.375 789.504C195.707 789.737 196.082 789.859 196.464 789.859C196.846 789.859 197.221 789.737 197.553 789.504C197.885 789.271 198.163 788.935 198.359 788.53C198.855 787.522 199.117 786.364 199.117 785.183C199.117 784.003 198.855 782.845 198.359 781.836C197.285 779.744 195.398 779.744 194.408 781.836C193.953 782.87 193.728 784.034 193.756 785.21C193.784 786.386 194.065 787.532 194.569 788.53Z" fill="white" fill-opacity="0.6"/>
        <path d="M186.794 808.434C187.294 807.474 187.567 806.36 187.583 805.219C187.6 804.077 187.359 802.952 186.887 801.97C186.723 801.564 186.476 801.218 186.169 800.968C185.863 800.717 185.509 800.57 185.143 800.541C184.776 800.513 184.41 800.603 184.081 800.805C183.751 801.006 183.471 801.311 183.266 801.688C182.708 802.695 182.396 803.88 182.367 805.1C182.339 806.32 182.595 807.525 183.106 808.57C183.309 808.953 183.588 809.264 183.916 809.476C184.244 809.687 184.611 809.791 184.981 809.777C185.351 809.764 185.712 809.633 186.029 809.398C186.346 809.163 186.61 808.831 186.794 808.434Z" fill="white" fill-opacity="0.6"/>
        <path d="M171.981 822.052C171.524 822.964 171.283 824.014 171.283 825.086C171.283 826.157 171.524 827.208 171.981 828.119C173.004 829.813 174.849 829.583 175.796 827.617C176.23 826.686 176.437 825.62 176.392 824.548C176.347 823.475 176.052 822.441 175.542 821.571C174.519 820.065 172.903 820.295 171.981 822.052Z" fill="white" fill-opacity="0.6"/>
        <path d="M161.05 846.266C161.259 846.598 161.529 846.866 161.836 847.048C162.144 847.23 162.482 847.32 162.823 847.312C163.163 847.304 163.498 847.197 163.8 847.001C164.102 846.805 164.362 846.525 164.561 846.182C165.092 845.343 165.381 844.308 165.381 843.243C165.381 842.178 165.092 841.143 164.561 840.304C164.338 839.987 164.057 839.741 163.742 839.587C163.426 839.432 163.085 839.374 162.747 839.417C162.409 839.46 162.085 839.603 161.802 839.834C161.518 840.065 161.283 840.377 161.118 840.743C160.664 841.551 160.415 842.508 160.403 843.492C160.391 844.475 160.616 845.441 161.05 846.266Z" fill="white" fill-opacity="0.6"/>
        <path d="M150.289 861.944C150.493 862.257 150.75 862.512 151.041 862.693C151.332 862.874 151.65 862.975 151.976 862.991C152.301 863.007 152.625 862.937 152.927 862.785C153.228 862.633 153.5 862.404 153.724 862.111C154.246 861.393 154.534 860.462 154.534 859.497C154.534 858.531 154.246 857.6 153.724 856.882C153.508 856.583 153.241 856.348 152.942 856.192C152.644 856.036 152.321 855.965 151.998 855.982C151.674 855.999 151.357 856.104 151.071 856.29C150.784 856.477 150.534 856.74 150.34 857.06C149.894 857.751 149.647 858.607 149.638 859.491C149.629 860.375 149.858 861.238 150.289 861.944Z" fill="white" fill-opacity="0.6"/>
        <path d="M138.462 873.617C137.379 875.269 137.142 877.633 138.013 878.846C138.885 880.06 140.915 879.631 142.023 877.8C143.132 875.97 143.098 873.941 142.133 872.759C141.169 871.577 139.528 871.996 138.462 873.617Z" fill="white" fill-opacity="0.6"/>
        <path d="M128.14 895.916C129.452 894.462 129.833 892.224 129.08 890.979C128.327 889.734 126.711 889.86 125.332 891.303C123.953 892.747 123.352 895.351 124.232 896.533C125.112 897.715 126.812 897.391 128.14 895.916Z" fill="white" fill-opacity="0.6"/>
        <path d="M110.476 907.871C109.156 909.105 108.674 910.936 109.469 911.95C110.264 912.965 112.413 912.87 113.792 911.49C115.171 910.109 115.374 908.352 114.478 907.306C113.581 906.26 111.804 906.637 110.476 907.871Z" fill="white" fill-opacity="0.6"/>
        <path d="M98.4964 922.232C99.8416 921.562 100.188 920.213 99.2747 919.282C98.361 918.351 96.5675 918.236 95.1885 918.843C93.8095 919.449 93.2511 920.83 94.2833 921.771C94.9152 922.225 95.6203 922.502 96.3484 922.582C97.0765 922.661 97.8098 922.542 98.4964 922.232Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.9944 921.981C81.0497 923.017 82.287 923.734 83.6069 924.072C84.7998 924.334 85.172 923.8 84.4106 922.911C83.2961 921.793 81.9727 921.038 80.5612 920.715C79.4445 920.558 79.1907 921.123 79.9944 921.981Z" fill="white" fill-opacity="0.6"/>
        <path d="M72.5325 920.704C73.3785 921.123 73.4631 920.705 72.6425 919.795C71.6892 918.784 70.6307 917.937 69.4953 917.274C68.5224 916.761 68.4801 917.201 69.3938 918.215C70.3371 919.228 71.3944 920.067 72.5325 920.704Z" fill="white" fill-opacity="0.6"/>
        <path d="M61.2129 911.908C62.052 912.954 62.942 913.936 63.8778 914.847C64.5716 915.496 64.5208 915.266 63.7509 914.335C62.981 913.404 61.5767 911.866 60.9168 911.197C60.2569 910.528 60.443 910.956 61.2129 911.908Z" fill="white" fill-opacity="0.6"/>
        <path d="M55.1895 903.771C55.9086 904.817 56.8815 906.292 57.4315 907.086C57.9814 907.881 57.7445 907.704 56.8985 906.532C56.0524 905.361 55.1134 903.98 54.6819 903.258C54.2504 902.537 54.4788 902.756 55.1895 903.771Z" fill="white" fill-opacity="0.6"/>
        <path d="M50.1895 893.74C49.885 892.883 50.1472 893.071 50.7817 894.148C51.5001 895.381 52.153 896.67 52.736 898.008C53.0321 898.782 52.736 898.51 52.0169 897.37C51.3235 896.228 50.7121 895.014 50.1895 893.74Z" fill="white" fill-opacity="0.6"/>
        <path d="M49.1066 887.465C49.3858 888.438 49.1066 888.322 48.5567 887.214C47.9293 885.962 47.4023 884.638 46.9831 883.26C46.6786 882.131 46.9831 882.214 47.5584 883.543C48.1517 884.801 48.6692 886.112 49.1066 887.465Z" fill="white" fill-opacity="0.6"/>
        <path d="M46.2979 876.42C46.5263 877.413 46.2979 877.246 45.7988 876.033C45.259 874.691 44.82 873.29 44.4874 871.849C44.2421 870.688 44.4875 870.897 45.0035 872.278C45.5032 873.624 45.9355 875.008 46.2979 876.42Z" fill="white" fill-opacity="0.6"/>
        <path d="M42.2877 860.114C42.1016 859.068 42.237 859.371 42.6092 860.668C42.9815 861.965 43.5991 864.256 43.8106 865.228C44.0221 866.201 43.8106 865.793 43.3876 864.423C42.9559 863.013 42.5887 861.574 42.2877 860.114Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.9614 849.424C40.249 850.815 40.7228 852.761 41.0104 853.838C41.2981 854.915 41.2642 854.696 40.9597 853.378C40.6551 852.06 40.1136 849.749 39.8852 848.671C39.6568 847.594 39.6822 848.054 39.9614 849.424Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.8565 837.898C36.535 836.936 36.3996 837.344 36.5857 838.756C36.8039 840.362 37.173 841.931 37.6855 843.431C38.0155 844.299 38.0747 843.829 37.8463 842.385C37.603 840.862 37.2722 839.363 36.8565 837.898Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.0172 829.468C32.1521 830.99 32.5348 832.463 33.1424 833.798C33.6162 834.656 33.8615 834.143 33.7262 832.637C33.5927 831.018 33.2108 829.446 32.601 828.004C32.1356 827.261 31.8903 827.941 32.0172 829.468Z" fill="white" fill-opacity="0.6"/>
        <path d="M27.1442 825.755C27.7703 826.32 28.1425 825.514 28.041 823.883C27.9733 822.366 27.5402 820.909 26.7974 819.699C26.146 818.967 25.6383 819.939 25.7652 821.728C25.8407 823.225 26.3272 824.646 27.1442 825.755Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.3137 815.421C17.2955 816.189 17.423 816.951 17.686 817.646C17.949 818.342 18.3401 818.952 18.8281 819.427C19.6741 820.034 20.3424 819.228 20.3762 817.649C20.3762 815.881 19.7417 813.894 18.8534 813.34C17.9651 812.785 17.3221 813.81 17.3137 815.421Z" fill="white" fill-opacity="0.6"/>
        <path d="M8.18525 809.25C7.12773 809.093 6.21404 810.369 6.23096 812.032C6.24788 813.695 7.3646 815.526 8.4898 815.578C9.615 815.63 10.2918 814.407 10.2241 812.67C10.1564 810.934 9.22585 809.397 8.18525 809.25Z" fill="white" fill-opacity="0.6"/>
        <path d="M693.713 825.828C694.076 827.408 696.707 828.485 699.635 828.265C702.562 828.046 704.711 826.508 704.482 824.887C704.254 823.266 701.437 821.906 698.332 822.293C695.405 822.617 693.332 824.259 693.713 825.828Z" fill="white" fill-opacity="0.6"/>
        <path d="M661.099 830.001C661.784 831.528 664.745 832.491 667.639 832.093C670.532 831.696 672.461 830.158 671.97 828.589C671.479 827.021 668.138 825.797 665.101 826.32C662.216 826.801 660.422 828.474 661.099 830.001Z" fill="white" fill-opacity="0.6"/>
        <path d="M628.291 833.997C629.095 835.503 632.157 836.444 635.059 836.089C637.961 835.733 639.78 834.175 639.162 832.627C638.544 831.079 635.093 829.897 632.064 830.42C629.171 830.89 627.496 832.501 628.291 833.997Z" fill="white" fill-opacity="0.6"/>
        <path d="M595.914 837.741C596.709 839.237 599.662 840.231 602.555 839.969C605.449 839.708 607.259 838.243 606.642 836.706C606.024 835.168 602.708 833.924 599.662 834.332C596.828 834.719 595.127 836.214 595.914 837.741Z" fill="white" fill-opacity="0.6"/>
        <path d="M571.71 843.536C574.409 843.212 575.872 841.737 575.001 840.252C574.13 838.766 571.143 837.752 568.394 837.992C565.441 838.254 563.758 839.917 564.891 841.486C566.025 843.055 569.02 843.902 571.71 843.536Z" fill="white" fill-opacity="0.6"/>
        <path d="M538.394 841.381C535.856 841.632 534.528 843.065 535.459 844.519C536.389 845.973 539.773 847.186 542.43 846.81C544.917 846.464 546.034 845.031 544.968 843.557C543.902 842.082 540.915 841.12 538.394 841.381Z" fill="white" fill-opacity="0.6"/>
        <path d="M508.657 847.113C509.672 848.556 512.346 849.634 514.765 849.56C517.185 849.487 518.378 848.19 517.506 846.747C516.534 845.136 513.581 843.954 511.043 844.163C508.75 844.341 507.659 845.69 508.657 847.113Z" fill="white" fill-opacity="0.6"/>
        <path d="M486.881 846.234C484.876 846.234 483.988 847.552 484.977 848.954C485.967 850.355 488.894 851.725 491.069 851.569C493.057 851.422 493.776 850.136 492.651 848.713C491.526 847.291 488.979 846.182 486.881 846.234Z" fill="white" fill-opacity="0.6"/>
        <path d="M465.435 850.303C466.906 851.817 468.742 852.686 470.655 852.771C472.296 852.771 472.719 851.516 471.577 850.104C470.033 848.439 468.059 847.511 466.01 847.489C464.445 847.646 464.216 848.912 465.435 850.303Z" fill="white" fill-opacity="0.6"/>
        <path d="M454.648 851.903C455.959 852.081 455.959 851.056 454.69 849.623C453.342 848.133 451.713 847.083 449.953 846.569C448.464 846.266 448.354 847.343 449.792 848.901C451.188 850.378 452.855 851.409 454.648 851.903Z" fill="white" fill-opacity="0.6"/>
        <path d="M438.692 847.28C440.114 847.824 440.266 847.071 439.073 845.638C437.67 844.046 436.041 842.787 434.268 841.925C432.686 841.235 432.424 842.019 433.777 843.599C435.215 845.194 436.882 846.443 438.692 847.28Z" fill="white" fill-opacity="0.6"/>
        <path d="M417.982 835.566C419.432 837.256 421.066 838.686 422.838 839.812C424.234 840.607 424.387 840.032 423.211 838.578C421.706 836.797 419.995 835.302 418.135 834.143C416.823 833.443 416.747 834.06 417.982 835.566Z" fill="white" fill-opacity="0.6"/>
        <path d="M407.72 829.824C409.023 830.87 409.099 830.378 407.915 828.851C406.495 827.094 404.949 825.502 403.295 824.092C401.84 822.9 401.713 823.339 403.05 825.054C404.479 826.826 406.043 828.423 407.72 829.824Z" fill="white" fill-opacity="0.6"/>
        <path d="M389.641 812.325C391.006 814.165 392.452 815.911 393.973 817.555C395.174 818.81 395.191 818.517 394.023 816.938C392.578 814.998 391.053 813.151 389.455 811.405C388.372 810.286 388.448 810.683 389.641 812.325Z" fill="white" fill-opacity="0.6"/>
        <path d="M381.536 804.094C382.721 805.432 382.729 805.14 381.596 803.508C380.462 801.876 378.677 799.46 377.408 797.944C376.139 796.427 376.012 796.668 377.264 798.529C378.604 800.48 380.03 802.338 381.536 804.094Z" fill="white" fill-opacity="0.6"/>
        <path d="M364.929 784.42C366.233 786.459 367.647 788.388 369.159 790.193C370.293 791.49 370.251 791.082 369.1 789.294C367.733 787.151 366.258 785.118 364.684 783.206C363.686 782.025 363.77 782.568 364.929 784.42Z" fill="white" fill-opacity="0.6"/>
        <path d="M356.765 776.241C357.865 777.454 357.78 776.868 356.621 774.933C355.349 772.83 353.958 770.842 352.459 768.982C351.224 767.528 351.224 768.187 352.527 770.362C353.801 772.468 355.219 774.435 356.765 776.241Z" fill="white" fill-opacity="0.6"/>
        <path d="M344.058 762.57C345.209 763.773 345.2 763.114 344.058 761.116C342.832 758.939 341.444 756.908 339.913 755.05C338.635 753.607 338.559 754.349 339.803 756.588C341.044 758.764 342.471 760.77 344.058 762.57Z" fill="white" fill-opacity="0.6"/>
        <path d="M326.935 741.777C325.844 740.731 325.81 741.704 326.935 743.869C328.168 746.232 329.686 748.348 331.436 750.145C332.485 751.023 332.282 749.988 331.106 747.76C329.935 745.547 328.533 743.535 326.935 741.777Z" fill="white" fill-opacity="0.6"/>
        <path d="M313.813 732.667C314.946 734.916 316.393 736.899 318.086 738.524C319.27 739.476 319.321 738.472 318.23 736.286C317.054 733.901 314.972 730.962 313.797 730.105C312.621 729.247 312.688 730.471 313.813 732.667Z" fill="white" fill-opacity="0.6"/>
        <path d="M300.32 723.536C301.399 725.762 302.874 727.654 304.634 729.069C305.836 729.791 305.895 728.546 304.795 726.266C303.619 723.808 301.487 720.953 300.311 720.367C299.135 719.781 299.211 721.225 300.32 723.536Z" fill="white" fill-opacity="0.6"/>
        <path d="M286.631 713.108C285.422 712.637 285.252 714.154 286.327 716.497C287.494 719.028 289.711 721.643 290.971 721.988C292.232 722.333 292.08 720.702 290.912 718.306C289.745 715.911 287.833 713.568 286.631 713.108Z" fill="white" fill-opacity="0.6"/>
        <path d="M272.461 708.684C271.149 708.349 270.836 710.064 271.826 712.386C272.926 714.959 275.134 717.355 276.513 717.616C277.892 717.878 277.892 716.058 276.809 713.683C275.726 711.309 273.772 709.029 272.461 708.684Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.879 725.827C272.165 728.431 274.33 730.01 275.76 730.01C277.046 730.01 277.088 728.086 275.895 725.659C274.601 723.034 272.427 720.911 271.014 720.942C269.779 721.162 269.711 723.442 270.879 725.827Z" fill="white" fill-opacity="0.6"/>
        <path d="M257.114 725.272C255.718 725.91 255.634 728.693 256.953 731.203C258.172 733.535 260.151 734.853 261.437 734.278C262.723 733.703 262.876 731.056 261.547 728.483C260.329 726.13 258.383 724.687 257.114 725.272Z" fill="white" fill-opacity="0.6"/>
        <path d="M243.35 740.731C244.432 743.043 246.404 744.235 247.698 743.419C249.153 742.509 249.322 739.811 248.104 737.227C246.996 734.864 245.101 733.598 243.806 734.414C242.368 735.324 242.148 738.179 243.35 740.731Z" fill="white" fill-opacity="0.6"/>
        <path d="M230.651 747.76C229.374 749.182 229.348 752.425 230.651 754.694C231.844 756.786 233.714 757.435 234.881 756.159C236.049 754.883 236.159 751.682 234.881 749.318C233.671 747.153 231.793 746.452 230.651 747.76Z" fill="white" fill-opacity="0.6"/>
        <path d="M218.257 763.92C216.988 765.562 216.937 768.814 218.164 771C219.298 773.019 221.134 773.448 222.301 771.984C223.469 770.519 223.587 767.225 222.301 764.976C221.167 762.895 219.391 762.413 218.257 763.92Z" fill="white" fill-opacity="0.6"/>
        <path d="M205.982 782.485C205.48 783.497 205.221 784.664 205.235 785.851C205.248 787.037 205.534 788.195 206.058 789.189C207.225 791.281 209.171 791.375 210.288 789.44C210.792 788.436 211.049 787.272 211.026 786.091C211.004 784.909 210.703 783.762 210.161 782.788C209.01 780.644 207.115 780.497 205.982 782.485Z" fill="white" fill-opacity="0.6"/>
        <path d="M194.29 801.866C193.804 802.851 193.555 803.987 193.572 805.141C193.588 806.294 193.869 807.418 194.383 808.382C194.606 808.822 194.918 809.181 195.288 809.426C195.659 809.671 196.074 809.792 196.494 809.777C196.913 809.762 197.322 809.612 197.681 809.342C198.039 809.071 198.334 808.69 198.537 808.236C199.029 807.259 199.279 806.125 199.256 804.975C199.234 803.825 198.939 802.707 198.41 801.761C198.199 801.31 197.895 800.938 197.528 800.682C197.161 800.427 196.746 800.297 196.325 800.308C195.904 800.319 195.493 800.469 195.135 800.743C194.777 801.017 194.485 801.405 194.29 801.866Z" fill="white" fill-opacity="0.6"/>
        <path d="M187.023 827.23C187.511 826.311 187.764 825.232 187.749 824.133C187.734 823.034 187.451 821.967 186.938 821.069C185.813 819.228 184.002 819.364 182.928 821.352C182.442 822.268 182.183 823.341 182.183 824.437C182.183 825.533 182.442 826.606 182.928 827.523C184.062 829.29 185.923 829.165 187.023 827.23Z" fill="white" fill-opacity="0.6"/>
        <path d="M175.669 845.575C176.212 844.704 176.499 843.63 176.48 842.53C176.462 841.431 176.14 840.372 175.568 839.53C175.328 839.198 175.03 838.94 174.697 838.774C174.363 838.609 174.004 838.542 173.645 838.577C173.287 838.613 172.941 838.75 172.633 838.979C172.325 839.208 172.064 839.522 171.87 839.896C171.336 840.764 171.05 841.828 171.06 842.919C171.069 844.01 171.372 845.066 171.921 845.92C172.168 846.244 172.471 846.496 172.806 846.657C173.142 846.818 173.503 846.885 173.862 846.852C174.221 846.819 174.569 846.687 174.882 846.466C175.195 846.245 175.464 845.941 175.669 845.575Z" fill="white" fill-opacity="0.6"/>
        <path d="M160.839 856.955C160.371 857.67 160.111 858.558 160.099 859.477C160.086 860.396 160.323 861.294 160.771 862.028C161.015 862.372 161.316 862.646 161.655 862.831C161.993 863.016 162.36 863.107 162.73 863.098C163.1 863.089 163.464 862.98 163.797 862.779C164.129 862.578 164.422 862.289 164.654 861.934C165.128 861.212 165.387 860.311 165.387 859.382C165.387 858.452 165.128 857.551 164.654 856.83C164.421 856.476 164.126 856.194 163.791 856.004C163.456 855.814 163.09 855.721 162.722 855.734C162.353 855.746 161.992 855.862 161.665 856.074C161.339 856.286 161.056 856.587 160.839 856.955Z" fill="white" fill-opacity="0.6"/>
        <path d="M149.375 877.006C150.416 878.313 152.269 877.989 153.445 876.347C153.948 875.704 154.239 874.853 154.261 873.959C154.283 873.065 154.034 872.193 153.563 871.514C152.506 870.144 150.754 870.343 149.595 872.027C148.555 873.585 148.436 875.845 149.375 877.006Z" fill="white" fill-opacity="0.6"/>
        <path d="M136.939 888.866C135.552 890.571 135.247 892.977 136.245 894.096C137.244 895.215 139.003 894.776 140.272 893.27C141.677 891.607 141.922 889.264 140.873 888.04C139.824 886.816 138.208 887.318 136.939 888.866Z" fill="white" fill-opacity="0.6"/>
        <path d="M122.811 905.821C121.322 907.348 120.89 909.45 121.965 910.454C123.039 911.459 124.782 911.072 126.195 909.722C127.7 908.227 128.039 906.093 126.998 905.036C125.958 903.98 124.181 904.419 122.811 905.821Z" fill="white" fill-opacity="0.6"/>
        <path d="M111.356 922.608C112.701 921.625 113.048 920.087 112.075 919.188C111.102 918.288 109.046 918.424 107.566 919.533C106.237 920.579 105.874 921.949 106.787 922.807C107.701 923.665 109.858 923.706 111.356 922.608Z" fill="white" fill-opacity="0.6"/>
        <path d="M91.5423 927.869C92.8706 928.942 94.4556 929.422 96.0346 929.229C97.3459 928.946 97.5659 927.953 96.5591 927.137C95.1428 926.235 93.5283 925.923 91.9568 926.248C90.6455 926.52 90.5101 927.064 91.5423 927.869Z" fill="white" fill-opacity="0.6"/>
        <path d="M82.8455 927.461C83.886 927.754 84.0468 927.346 83.1839 926.541C82.0291 925.537 80.7445 924.784 79.3852 924.313C78.37 923.999 78.2431 924.386 79.0807 925.202C80.2185 926.223 81.4929 926.988 82.8455 927.461Z" fill="white" fill-opacity="0.6"/>
        <path d="M69.6645 920.412C70.6742 921.414 71.7559 922.3 72.8963 923.058C73.6323 923.487 73.497 923.131 72.5748 922.242C71.5474 921.266 70.4614 920.389 69.3261 919.617C68.6155 919.167 68.7762 919.523 69.6645 920.412Z" fill="white" fill-opacity="0.6"/>
        <path d="M62.2028 913.299C62.9642 914.178 64.1487 915.527 64.8255 916.28C65.5023 917.033 65.5276 917.023 64.6478 916.061C63.7679 915.098 62.6766 913.875 61.9998 913.101C61.323 912.327 61.3568 912.316 62.2028 913.299Z" fill="white" fill-opacity="0.6"/>
        <path d="M56.7968 904.89C57.6428 906.03 58.6326 907.568 59.081 908.373C59.5294 909.178 59.1741 908.833 58.3957 907.819C57.5577 906.735 56.7911 905.57 56.1031 904.336C55.7393 903.604 56.0523 903.855 56.7968 904.89Z" fill="white" fill-opacity="0.6"/>
        <path d="M54.4534 898.834C54.7918 899.692 54.5211 899.545 53.8443 898.499C53.084 897.306 52.4288 896.016 51.89 894.65C51.5939 893.772 51.89 893.929 52.4992 894.995C53.2318 896.209 53.8853 897.493 54.4534 898.834Z" fill="white" fill-opacity="0.6"/>
        <path d="M49.1998 884.494C49.8261 885.805 50.3719 887.172 50.8326 888.584C51.0948 889.463 50.8326 889.232 50.2065 888.051C49.548 886.761 49.0062 885.386 48.5907 883.95C48.3707 883.061 48.6414 883.281 49.1998 884.494Z" fill="white" fill-opacity="0.6"/>
        <path d="M46.2049 873.345C46.6449 874.516 47.2709 876.367 47.5924 877.455C47.9139 878.543 47.8039 878.637 47.2794 877.361C46.7437 876.03 46.2857 874.652 45.9088 873.24C45.5874 871.985 45.7227 872.037 46.2049 873.345Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.3116 862.582C43.7177 864.057 44.3099 866.023 44.5975 866.996C44.8851 867.969 44.7329 867.529 44.3268 866.191C43.9207 864.852 43.337 862.749 43.0747 861.745C42.8124 860.741 42.9394 861.233 43.3116 862.582Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.8682 852.07C40.1779 853.601 40.594 855.095 41.1119 856.537C41.4418 857.363 41.4249 856.924 41.1119 855.491C40.7864 853.96 40.3881 852.455 39.919 850.983C39.6229 850.156 39.589 850.648 39.8682 852.07Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.4605 842.249C35.6546 843.755 36.0491 845.212 36.628 846.558C37.1525 847.604 37.364 847.259 37.144 845.69C36.9302 844.187 36.5517 842.727 36.0189 841.35C35.5198 840.251 35.2744 840.67 35.4605 842.249Z" fill="white" fill-opacity="0.6"/>
        <path d="M29.7077 834.049C29.8198 835.628 30.3286 837.124 31.1628 838.327C31.7297 838.934 32.0089 838.212 31.8735 836.654C31.7619 835.073 31.283 833.565 30.4945 832.313C29.9446 831.696 29.5977 832.501 29.7077 834.049Z" fill="white" fill-opacity="0.6"/>
        <path d="M23.7349 831.34C24.4963 831.842 25.0124 831.037 24.9531 829.457C24.8939 827.878 24.1917 825.797 23.3796 825.274C22.5674 824.751 22.0428 825.65 22.0767 827.24C22.0868 828.033 22.2411 828.814 22.5281 829.524C22.8151 830.233 23.2276 830.854 23.7349 831.34Z" fill="white" fill-opacity="0.6"/>
        <path d="M12.1531 822.617C12.1454 823.381 12.3129 824.133 12.6363 824.784C12.9597 825.436 13.4257 825.961 13.9804 826.299C15.0718 826.78 15.8924 825.765 15.867 823.987C15.8811 823.224 15.7262 822.471 15.419 821.809C15.1118 821.148 14.6641 820.602 14.1243 820.232C13.0668 819.751 12.1446 820.86 12.1531 822.617Z" fill="white" fill-opacity="0.6"/>
        <path d="M711.834 832.7C712.147 834.258 714.727 835.367 717.545 835.168C720.7 834.938 722.891 833.401 722.621 831.654C722.333 830.064 719.871 828.966 717.003 829.165C713.814 829.395 711.479 830.985 711.834 832.7Z" fill="white" fill-opacity="0.6"/>
        <path d="M686.513 838.798C689.702 838.505 691.818 836.873 691.225 835.2C690.633 833.526 687.96 832.658 685.024 832.941C681.801 833.254 679.601 834.907 680.236 836.57C680.87 838.233 683.56 839.08 686.513 838.798Z" fill="white" fill-opacity="0.6"/>
        <path d="M648.104 840.325C648.714 841.82 651.598 842.835 654.543 842.594C657.808 842.322 659.889 840.774 659.246 839.111C658.603 837.448 655.862 836.57 652.876 836.821C649.602 837.093 647.436 838.683 648.104 840.325Z" fill="white" fill-opacity="0.6"/>
        <path d="M623.409 846.297C626.261 845.91 627.91 844.456 627.2 842.95C626.489 841.444 623.156 840.325 620.009 840.754C617.149 841.141 615.44 842.636 616.176 844.121C616.912 845.607 620.271 846.674 623.409 846.297Z" fill="white" fill-opacity="0.6"/>
        <path d="M585.009 847.667C585.779 849.1 588.774 850.146 591.591 849.968C594.764 849.77 596.625 848.337 595.821 846.726C595.018 845.115 592.158 844.226 589.282 844.404C586.05 844.582 584.155 846.067 585.009 847.667Z" fill="white" fill-opacity="0.6"/>
        <path d="M562.201 853.263C565.137 853.012 566.609 851.537 565.458 849.979C564.307 848.42 561.448 847.615 558.741 847.887C555.78 848.138 554.291 849.634 555.467 851.171C556.643 852.709 559.528 853.483 562.201 853.263Z" fill="white" fill-opacity="0.6"/>
        <path d="M530.543 850.805C527.811 851.014 526.618 852.447 527.912 853.943C529.207 855.438 531.999 856.286 534.46 856.108C537.168 855.899 538.369 854.476 537.091 852.97C535.814 851.464 533.039 850.617 530.543 850.805Z" fill="white" fill-opacity="0.6"/>
        <path d="M503.167 856.265C505.152 857.929 507.55 858.67 509.935 858.357C511.948 858.053 512.532 856.694 511.195 855.323C509.858 853.953 506.737 852.897 504.427 853.232C502.371 853.535 501.821 854.916 503.167 856.265Z" fill="white" fill-opacity="0.6"/>
        <path d="M487.482 859.967C489.326 859.863 489.867 858.702 488.7 857.321C486.965 855.638 484.814 854.744 482.609 854.79C480.781 854.895 480.274 856.066 481.441 857.436C483.164 859.103 485.294 859.996 487.482 859.967Z" fill="white" fill-opacity="0.6"/>
        <path d="M464.217 857.677C465.65 859.135 467.358 860.121 469.183 860.543C470.807 860.773 471.103 859.601 469.699 858.106C468.235 856.704 466.505 855.789 464.673 855.449C463.074 855.219 462.838 856.192 464.217 857.677Z" fill="white" fill-opacity="0.6"/>
        <path d="M448.861 852.458C447.466 852.081 447.322 852.845 448.565 854.215C450.145 855.853 451.987 857.058 453.971 857.75C455.384 858.116 455.46 857.311 454.191 855.92C452.628 854.321 450.813 853.142 448.861 852.458Z" fill="white" fill-opacity="0.6"/>
        <path d="M432.711 847.876C434.238 849.493 435.948 850.825 437.787 851.83C439.479 852.656 439.691 852.05 438.286 850.502C436.771 848.919 435.078 847.616 433.261 846.632C431.595 845.785 431.324 846.328 432.711 847.876Z" fill="white" fill-opacity="0.6"/>
        <path d="M417.331 838.976C418.853 840.676 420.521 842.167 422.305 843.421C423.913 844.467 423.997 843.986 422.593 842.375C421.073 840.711 419.421 839.243 417.661 837.992C416.07 836.915 415.91 837.344 417.331 838.976Z" fill="white" fill-opacity="0.6"/>
        <path d="M403.169 827.91C404.724 829.777 406.393 831.493 408.16 833.045C409.311 833.986 409.141 833.432 407.771 831.821C406.205 829.994 404.543 828.296 402.796 826.738C401.654 825.755 401.84 826.278 403.169 827.91Z" fill="white" fill-opacity="0.6"/>
        <path d="M395.072 820.525C396.206 821.697 396.104 821.352 394.861 819.751C393.617 818.151 391.358 815.452 390.132 814.135C388.905 812.817 389.057 813.256 390.275 814.877C391.797 816.858 393.398 818.743 395.072 820.525Z" fill="white" fill-opacity="0.6"/>
        <path d="M377.585 800.402C376.519 799.241 376.646 799.732 377.898 801.51C379.383 803.583 380.965 805.546 382.636 807.388C383.719 808.539 383.541 808.037 382.247 806.269C380.859 804.345 378.77 801.699 377.585 800.402Z" fill="white" fill-opacity="0.6"/>
        <path d="M365.098 786.522C364.024 785.361 364.125 785.936 365.361 787.829C366.808 789.996 368.383 792.028 370.073 793.906C371.173 795.057 371.004 794.492 369.743 792.609C368.297 790.464 366.746 788.431 365.098 786.522Z" fill="white" fill-opacity="0.6"/>
        <path d="M352.772 774.588C354.168 776.81 355.738 778.856 357.459 780.696C358.508 781.742 358.305 780.916 356.977 778.866C355.591 776.656 354.048 774.605 352.366 772.737C351.368 771.701 351.537 772.538 352.772 774.588Z" fill="white" fill-opacity="0.6"/>
        <path d="M339.811 761.608C341.101 763.819 342.591 765.84 344.253 767.633C345.674 769.045 345.725 768.386 344.413 766.126C343.128 763.937 341.662 761.918 340.04 760.102C338.644 758.669 338.525 759.338 339.811 761.608Z" fill="white" fill-opacity="0.6"/>
        <path d="M331.47 756.316C332.646 757.246 332.595 756.316 331.402 754.13C330.121 751.751 328.549 749.634 326.74 747.854C325.59 746.913 325.573 747.906 326.74 750.082C328.031 752.465 329.629 754.571 331.47 756.316Z" fill="white" fill-opacity="0.6"/>
        <path d="M313.45 737.583C312.257 736.788 312.189 737.97 313.331 740.208C314.555 742.617 316.175 744.68 318.086 746.264C319.296 747.049 319.304 745.94 318.086 743.681C316.868 741.421 314.753 738.451 313.45 737.583Z" fill="white" fill-opacity="0.6"/>
        <path d="M299.601 732.521C300.728 734.802 302.284 736.714 304.135 738.095C305.624 738.964 305.759 737.646 304.49 735.104C303.379 732.825 301.852 730.898 300.032 729.477C298.568 728.598 298.348 729.979 299.601 732.521Z" fill="white" fill-opacity="0.6"/>
        <path d="M285.354 727.228C286.454 729.571 288.535 731.956 289.931 732.542C291.504 733.19 291.741 731.694 290.514 729.111C289.389 726.747 287.41 724.352 286.014 723.745C284.465 723.065 284.144 724.634 285.354 727.228Z" fill="white" fill-opacity="0.6"/>
        <path d="M352.679 766.9C353.859 768.969 355.183 770.904 356.638 772.684C357.696 773.908 357.662 773.375 356.638 771.523C355.462 769.476 354.161 767.542 352.747 765.739C351.605 764.411 351.562 764.913 352.679 766.9Z" fill="white" fill-opacity="0.6"/>
        <path d="M340.403 751.086C339.236 749.716 339.092 750.207 340.133 752.216C341.275 754.357 342.6 756.342 344.084 758.136C345.2 759.412 345.277 758.878 344.278 756.995C343.147 754.873 341.849 752.894 340.403 751.086Z" fill="white" fill-opacity="0.6"/>
        <path d="M327.696 736.851C326.563 735.679 326.487 736.464 327.578 738.629C328.677 740.788 330.012 742.749 331.546 744.455C332.629 745.501 332.629 744.716 331.597 742.687C330.505 740.542 329.194 738.581 327.696 736.851Z" fill="white" fill-opacity="0.6"/>
        <path d="M368.846 787.181C369.963 788.499 370.014 788.227 368.998 786.501C367.792 784.526 366.49 782.643 365.098 780.864C363.939 779.441 363.821 779.713 364.87 781.512C366.086 783.515 367.414 785.409 368.846 787.181Z" fill="white" fill-opacity="0.6"/>
        <path d="M377.239 795.423C376.13 794.136 376.113 794.503 377.239 796.281C378.479 798.172 379.816 799.964 381.24 801.646C382.306 802.839 382.247 802.431 381.164 800.768C379.936 798.898 378.626 797.114 377.239 795.423Z" fill="white" fill-opacity="0.6"/>
        <path d="M389.92 809.187C388.685 808.026 388.617 808.403 389.768 810.087C391.047 811.915 392.463 813.589 393.998 815.086C395.182 816.132 395.242 815.714 394.142 814.135C392.85 812.341 391.438 810.686 389.92 809.187Z" fill="white" fill-opacity="0.6"/>
        <path d="M314.609 726.109C315.659 728.299 317.007 730.248 318.593 731.872C319.736 732.918 319.812 731.956 318.813 729.874C317.774 727.693 316.451 725.739 314.896 724.09C313.712 723.013 313.56 723.892 314.609 726.109Z" fill="white" fill-opacity="0.6"/>
        <path d="M301.284 715.273C302.276 717.497 303.647 719.428 305.303 720.932C306.504 721.831 306.69 720.806 305.751 718.693C304.766 716.494 303.424 714.571 301.809 713.045C300.548 712.073 300.32 713.035 301.284 715.273Z" fill="white" fill-opacity="0.6"/>
        <path d="M287.63 706.833C288.557 709.056 289.962 710.92 291.69 712.219C292.943 712.93 293.188 711.717 292.274 709.552C291.355 707.352 289.98 705.494 288.29 704.165C286.961 703.381 286.657 704.552 287.63 706.833Z" fill="white" fill-opacity="0.6"/>
        <path d="M273.603 701.488C274.618 703.799 276.496 705.912 277.757 706.247C279.017 706.582 279.186 704.96 278.197 702.743C277.207 700.526 275.388 698.329 274.11 697.963C272.833 697.597 272.562 699.135 273.603 701.488Z" fill="white" fill-opacity="0.6"/>
        <path d="M259.711 695.129C258.282 694.951 257.96 697.032 258.865 699.386C259.771 701.739 261.649 703.465 262.985 703.392C264.339 703.569 264.677 701.833 263.831 699.605C262.985 697.377 261.099 695.306 259.711 695.129Z" fill="white" fill-opacity="0.6"/>
        <path d="M245.126 705.253C246.15 707.502 247.96 708.653 249.162 707.899C250.363 707.146 250.481 704.668 249.492 702.492C248.502 700.316 246.717 699.03 245.498 699.794C244.28 700.557 244.077 702.963 245.126 705.253Z" fill="white" fill-opacity="0.6"/>
        <path d="M232.081 709.803C230.922 711.048 230.803 713.746 231.853 715.932C232.902 718.118 234.644 718.798 235.778 717.585C236.912 716.371 236.954 713.547 235.964 711.477C234.974 709.406 233.232 708.611 232.081 709.803Z" fill="white" fill-opacity="0.6"/>
        <path d="M403.981 822.146C405.303 823.901 406.821 825.412 408.49 826.634C409.81 827.512 409.962 826.937 408.854 825.441C407.532 823.721 406.026 822.233 404.378 821.017C402.991 820.065 402.813 820.525 403.981 822.146Z" fill="white" fill-opacity="0.6"/>
        <path d="M419.471 831.8C420.802 833.461 422.375 834.795 424.107 835.733C425.486 836.361 425.672 835.618 424.556 834.175C423.235 832.546 421.68 831.237 419.97 830.315C418.524 829.625 418.287 830.252 419.471 831.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M435.351 838.515C436.633 840.117 438.218 841.3 439.961 841.956C441.374 842.375 441.653 841.496 440.588 840.105C439.322 838.544 437.765 837.394 436.053 836.758C434.572 836.308 434.242 837.041 435.351 838.515Z" fill="white" fill-opacity="0.6"/>
        <path d="M451.755 839.875C450.291 839.687 449.911 840.586 450.909 842.03C452.099 843.586 453.662 844.627 455.367 845C456.755 845.188 457.212 843.954 456.213 842.615C455.002 841.137 453.439 840.177 451.755 839.875Z" fill="white" fill-opacity="0.6"/>
        <path d="M473.962 844.341C475.756 844.174 476.357 842.814 475.231 841.433C473.715 839.873 471.779 839.074 469.809 839.195C467.939 839.363 467.423 840.618 468.607 842.082C470.094 843.651 472.01 844.459 473.962 844.341Z" fill="white" fill-opacity="0.6"/>
        <path d="M490.485 840.398C491.492 841.841 494.114 842.887 496.322 842.72C498.53 842.553 499.571 841.182 498.624 839.781C497.676 838.379 494.969 837.323 492.702 837.501C490.434 837.679 489.461 838.881 490.485 840.398Z" fill="white" fill-opacity="0.6"/>
        <path d="M516.204 838.066C517.244 839.519 520.07 840.461 522.54 840.157C525.01 839.854 526.237 838.4 525.247 837.02C524.258 835.639 521.347 834.656 518.852 834.928C516.356 835.2 515.146 836.58 516.204 838.066Z" fill="white" fill-opacity="0.6"/>
        <path d="M544.816 835.064C545.839 836.528 548.8 837.407 551.491 837.041C554.181 836.674 555.585 835.126 554.621 833.714C553.656 832.302 550.636 831.403 547.895 831.759C545.154 832.114 543.8 833.568 544.816 835.064Z" fill="white" fill-opacity="0.6"/>
        <path d="M575.669 831.445C576.515 832.93 579.544 833.83 582.437 833.453C585.331 833.076 586.989 831.508 586.177 830.064C585.365 828.621 582.285 827.7 579.409 828.077C576.532 828.453 574.789 829.939 575.669 831.445Z" fill="white" fill-opacity="0.6"/>
        <path d="M614.611 829.573C617.547 829.238 619.56 827.679 618.993 826.184C618.426 824.688 615.432 823.694 612.411 824.092C609.391 824.489 607.411 825.891 608.046 827.449C608.68 829.008 611.65 829.939 614.611 829.573Z" fill="white" fill-opacity="0.6"/>
        <path d="M641.336 823.224C641.776 824.793 644.636 825.807 647.656 825.493C650.676 825.18 652.834 823.632 652.461 822.094C652.089 820.557 649.196 819.521 646.142 819.856C642.952 820.191 640.888 821.571 641.336 823.224Z" fill="white" fill-opacity="0.6"/>
        <path d="M674.855 819.103C675.219 820.693 677.994 821.718 680.98 821.383C683.967 821.048 686.149 819.479 685.845 817.921C685.54 816.362 682.765 815.327 679.728 815.662C676.691 815.996 674.483 817.482 674.855 819.103Z" fill="white" fill-opacity="0.6"/>
        <path d="M707.9 815.296C708.365 816.896 711.09 817.827 713.983 817.388C716.876 816.948 718.898 815.296 718.475 813.769C718.052 812.242 715.336 811.258 712.392 811.677C709.448 812.095 707.418 813.633 707.9 815.296Z" fill="white" fill-opacity="0.6"/>
        <path d="M15.5457 805.663C15.5457 804.041 14.8012 802.305 13.8536 801.908C12.9061 801.51 12.1108 802.504 12.1024 804.198C12.0755 804.957 12.2254 805.709 12.5344 806.366C12.8433 807.022 13.2983 807.555 13.8452 807.901C14.7842 808.277 15.5457 807.263 15.5457 805.663Z" fill="white" fill-opacity="0.6"/>
        <path d="M24.1749 811.457C24.1812 809.938 23.7147 808.473 22.872 807.367C22.1529 806.729 21.5946 807.535 21.62 809.198C21.6152 809.95 21.7343 810.696 21.9699 811.39C22.2055 812.084 22.5527 812.712 22.9905 813.235C23.6758 813.831 24.2172 813.026 24.1749 811.457Z" fill="white" fill-opacity="0.6"/>
        <path d="M28.6248 816.446C28.6913 817.969 29.0729 819.446 29.7331 820.734C30.2407 821.508 30.5791 820.891 30.4776 819.375C30.4053 817.85 30.0461 816.367 29.4286 815.045C28.9125 814.25 28.5487 814.835 28.6248 816.446Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.0308 824.468C33.6755 823.506 33.4894 823.987 33.6332 825.514C33.7771 827.039 34.0991 828.529 34.5892 829.939C34.9446 830.828 35.0884 830.357 34.9361 828.893C34.7728 827.38 34.469 825.895 34.0308 824.468Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.1695 835.105C36.941 834.133 36.9157 834.603 37.1695 836.151C37.4002 837.638 37.6968 839.108 38.0578 840.555C38.3031 841.465 38.2778 841.005 38.0578 839.509C37.8378 838.013 37.4064 836.078 37.1695 835.105Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.2997 851.297C39.9867 849.958 39.5891 847.918 39.4537 846.82C39.3184 845.722 39.3437 845.931 39.6483 847.353C39.9529 848.776 40.3674 850.742 40.5704 851.799C40.7735 852.855 40.5959 852.614 40.2997 851.297Z" fill="white" fill-opacity="0.6"/>
        <path d="M41.5349 859.131C41.9462 860.554 42.2964 862.002 42.584 863.471C42.7786 864.569 42.584 864.517 42.2117 863.262C41.7784 861.841 41.4389 860.379 41.1966 858.89C41.0189 857.708 41.1542 857.813 41.5349 859.131Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.5315 871.19C44.0174 872.527 44.4191 873.908 44.7329 875.322C44.919 876.294 44.6482 876.075 44.166 874.84C43.6581 873.499 43.2613 872.097 42.9816 870.657C42.8124 869.632 43.0492 869.862 43.5315 871.19Z" fill="white" fill-opacity="0.6"/>
        <path d="M45.3927 882.298C45.1051 881.252 45.2997 881.252 45.8412 882.455C46.4249 883.729 46.9338 885.054 47.364 886.419C47.6601 887.465 47.4486 887.35 46.9071 886.272C46.2971 885.013 45.7898 883.682 45.3927 882.298Z" fill="white" fill-opacity="0.6"/>
        <path d="M51.0863 896.794C51.4501 897.6 51.2047 897.349 50.5702 896.251C49.9073 895.104 49.3053 893.906 48.7681 892.663C48.4044 891.795 48.6328 892.025 49.2758 893.207C49.9188 894.389 50.7479 895.968 51.0863 896.794Z" fill="white" fill-opacity="0.6"/>
        <path d="M53.9965 902.348C54.6733 903.394 55.6885 904.869 56.2299 905.612C56.7714 906.354 56.6953 906.114 56.0269 905.11C55.3586 904.106 54.3349 902.631 53.785 901.867C53.2351 901.104 53.2859 901.282 53.9965 902.348Z" fill="white" fill-opacity="0.6"/>
        <path d="M63.438 912.714C64.191 913.268 64.284 912.912 63.548 911.95C62.7416 910.877 61.8358 909.926 60.8492 909.116C60.0709 908.519 60.0032 908.833 60.7647 909.858C61.5532 910.948 62.4517 911.907 63.438 912.714Z" fill="white" fill-opacity="0.6"/>
        <path d="M69.9438 915.192C70.8329 916.288 71.9205 917.105 73.1163 917.577C74.0977 917.901 74.3261 917.337 73.5901 916.385C72.6762 915.31 71.577 914.505 70.3753 914.031C69.3601 913.686 69.1655 914.178 69.9438 915.192Z" fill="white" fill-opacity="0.6"/>
        <path d="M82.1095 917.912C82.627 918.497 83.237 918.943 83.901 919.222C84.5649 919.501 85.2685 919.607 85.9672 919.533C87.3293 919.272 87.7862 918.121 86.9825 917.19C85.8295 916.17 84.3978 915.753 82.9978 916.029C81.5765 916.28 81.2719 916.897 82.1095 917.912Z" fill="white" fill-opacity="0.6"/>
        <path d="M97.4304 912.358C98.1749 913.404 99.9684 913.467 101.347 912.473C102.726 911.479 103.234 909.701 102.523 908.687C101.813 907.672 100.07 907.735 98.6741 908.76C97.2782 909.785 96.6775 911.323 97.4304 912.358Z" fill="white" fill-opacity="0.6"/>
        <path d="M116.33 898.29C117.642 896.836 118.115 894.609 117.413 893.479C116.711 892.349 115.027 892.705 113.691 894.211C112.354 895.717 111.796 897.778 112.54 898.918C113.285 900.058 115.002 899.765 116.33 898.29Z" fill="white" fill-opacity="0.6"/>
        <path d="M126.77 881.189C127.481 882.455 129.13 882.141 130.374 880.52C131.618 878.899 132.015 876.493 131.33 875.217C130.645 873.941 129.046 874.244 127.811 875.907C126.575 877.57 126.042 879.882 126.77 881.189Z" fill="white" fill-opacity="0.6"/>
        <path d="M140.424 857.645C139.325 859.277 139.054 861.557 139.866 862.875C140.678 864.193 142.218 863.921 143.25 862.342C144.282 860.762 144.494 858.451 143.715 857.185C142.937 855.92 141.465 856.087 140.424 857.645Z" fill="white" fill-opacity="0.6"/>
        <path d="M150.94 846.674C151.719 848.117 153.182 848.127 154.155 846.674C154.63 845.914 154.916 845.001 154.979 844.045C155.042 843.089 154.879 842.131 154.51 841.287C153.749 839.833 152.311 839.906 151.346 841.412C150.853 842.134 150.55 843.024 150.477 843.964C150.405 844.905 150.566 845.85 150.94 846.674Z" fill="white" fill-opacity="0.6"/>
        <path d="M161.575 828.976C162.421 830.545 163.884 830.462 164.874 828.788C165.355 827.92 165.639 826.908 165.697 825.858C165.755 824.807 165.585 823.757 165.204 822.816C164.417 821.247 162.945 821.393 161.964 823.109C161.461 823.943 161.157 824.936 161.089 825.974C161.02 827.011 161.188 828.052 161.575 828.976Z" fill="white" fill-opacity="0.6"/>
        <path d="M172.513 809.376C173.36 810.986 174.916 810.798 175.898 808.936C176.372 807.979 176.638 806.887 176.671 805.766C176.703 804.645 176.501 803.532 176.084 802.535C175.238 800.924 173.723 801.165 172.742 803.058C172.245 803.987 171.961 805.065 171.921 806.178C171.88 807.29 172.085 808.396 172.513 809.376Z" fill="white" fill-opacity="0.6"/>
        <path d="M183.571 788.499C184.417 790.319 185.999 790.329 187.039 788.499C187.55 787.51 187.849 786.377 187.908 785.206C187.967 784.035 187.785 782.865 187.378 781.805C186.532 779.974 184.992 780.006 183.994 781.868C183.455 782.828 183.132 783.947 183.057 785.113C182.983 786.278 183.16 787.446 183.571 788.499Z" fill="white" fill-opacity="0.6"/>
        <path d="M195.246 761.169C194.724 762.168 194.43 763.324 194.394 764.513C194.358 765.702 194.582 766.881 195.043 767.925C195.982 769.777 197.581 769.756 198.63 767.925C199.128 766.903 199.405 765.74 199.433 764.547C199.461 763.354 199.24 762.173 198.79 761.116C197.851 759.244 196.261 759.276 195.246 761.169Z" fill="white" fill-opacity="0.6"/>
        <path d="M207.259 741.233C206.67 742.17 206.308 743.295 206.216 744.476C206.123 745.657 206.303 746.847 206.735 747.906C207.581 749.998 209.273 750.437 210.406 748.879C210.966 747.92 211.306 746.791 211.389 745.613C211.473 744.434 211.297 743.25 210.88 742.185C210 740.083 208.342 739.633 207.259 741.233Z" fill="white" fill-opacity="0.6"/>
        <path d="M222.893 731.517C223.976 730.021 224.044 726.998 223.071 724.99C222.098 722.982 220.423 722.48 219.315 724.028C218.748 724.965 218.424 726.092 218.387 727.261C218.349 728.43 218.599 729.585 219.103 730.575C220.093 732.604 221.802 733.023 222.893 731.517Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.0382269 820.285C0.0382267 822.9 0.630425 823.799 1.98404 823.83C2.30328 823.833 2.61925 823.751 2.91014 823.588C3.20104 823.425 3.45996 823.187 3.66905 822.888C3.87813 822.59 4.03238 822.239 4.12123 821.86C4.21008 821.481 4.23142 821.083 4.18371 820.693C4.04835 818.486 2.32248 816.906 0.799659 817.816C0.482205 818.051 0.246931 818.424 0.139745 818.862C0.06504 819.331 0.0310105 819.807 0.0382269 820.285Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.0467418 801.97C0.0467416 804 0.613569 804.993 1.73876 805.108C2.05591 805.142 2.37512 805.088 2.67333 804.95C2.97154 804.813 3.24129 804.595 3.46311 804.313C3.68492 804.03 3.85325 803.691 3.95593 803.318C4.0586 802.946 4.09307 802.55 4.05682 802.159C4.0594 801.373 3.82361 800.615 3.39598 800.034C2.96836 799.453 2.38002 799.092 1.74723 799.021C0.579737 799.031 0.0382819 799.91 0.0467418 801.97Z" fill="white" fill-opacity="0.6"/>
        <path d="M261.293 687.295C262.139 689.439 263.941 691.395 265.227 691.593C266.513 691.792 266.725 690.422 265.997 688.382C265.21 686.186 263.51 684.073 262.207 683.77C260.904 683.467 260.473 685.161 261.293 687.295Z" fill="white" fill-opacity="0.6"/>
        <path d="M279.322 696.122C280.659 696.792 281.014 695.589 280.109 693.351C279.203 691.112 277.571 688.874 276.369 688.382C275.168 687.891 274.889 689.156 275.523 691.07C276.299 693.231 277.636 695.009 279.322 696.122Z" fill="white" fill-opacity="0.6"/>
        <path d="M293.256 703.873C294.482 704.647 294.634 703.507 293.687 701.226C292.876 699.24 291.693 697.522 290.235 696.216C289.051 695.359 288.67 696.509 289.482 698.601C290.34 700.74 291.642 702.56 293.256 703.873Z" fill="white" fill-opacity="0.6"/>
        <path d="M306.656 714.123C307.807 715.033 307.892 714.018 306.902 711.759C306.053 709.765 304.904 707.99 303.518 706.529C302.392 705.536 302.088 706.529 302.942 708.621C303.856 710.756 305.121 712.63 306.656 714.123Z" fill="white" fill-opacity="0.6"/>
        <path d="M316.538 719.07C315.396 717.804 315.04 718.453 315.844 720.398C316.736 722.443 317.878 724.305 319.228 725.921C320.514 727.354 320.852 726.967 320.074 724.99C319.158 722.796 317.964 720.798 316.538 719.07Z" fill="white" fill-opacity="0.6"/>
        <path d="M328.593 734.152C329.598 736.291 330.833 738.251 332.265 739.978C333.297 741.108 333.441 740.658 332.654 738.932C331.676 736.779 330.482 734.786 329.101 733.002C328.001 731.621 327.739 732.238 328.593 734.152Z" fill="white" fill-opacity="0.6"/>
        <path d="M341.072 748.858C342.113 750.947 343.342 752.884 344.735 754.632C345.742 755.814 345.852 755.437 345.048 753.743C344.022 751.641 342.829 749.672 341.486 747.864C340.412 746.557 340.183 747.007 341.072 748.858Z" fill="white" fill-opacity="0.6"/>
        <path d="M353.618 763.24C352.594 761.953 352.451 762.413 353.381 764.202C354.465 766.233 355.703 768.131 357.078 769.871C358.026 771.011 358.06 770.603 357.205 768.94C356.148 766.913 354.947 765.005 353.618 763.24Z" fill="white" fill-opacity="0.6"/>
        <path d="M365.589 779.41C366.694 781.365 367.934 783.199 369.295 784.89C370.344 786.135 370.284 785.633 369.218 783.75C368.189 781.946 367.058 780.233 365.834 778.625C364.794 777.339 364.616 777.705 365.589 779.41Z" fill="white" fill-opacity="0.6"/>
        <path d="M377.721 794.116C378.845 795.989 380.099 797.739 381.469 799.345C382.484 800.464 382.585 800.171 381.731 798.697C380.608 796.834 379.366 795.085 378.017 793.467C376.926 792.191 376.782 792.494 377.721 794.116Z" fill="white" fill-opacity="0.6"/>
        <path d="M390.842 807.482C392.009 809.309 393.379 810.921 394.912 812.273C396.155 813.319 396.468 813.047 395.673 811.687C394.528 809.882 393.187 808.281 391.688 806.928C390.394 805.736 389.996 805.997 390.842 807.482Z" fill="white" fill-opacity="0.6"/>
        <path d="M405.791 818.705C406.965 820.458 408.401 821.914 410.021 822.994C411.366 823.82 411.713 823.433 410.977 822.105C409.835 820.35 408.431 818.883 406.84 817.785C405.47 816.854 404.937 817.272 405.791 818.705Z" fill="white" fill-opacity="0.6"/>
        <path d="M426.028 830.985C427.508 831.57 427.787 830.807 426.738 829.259C425.642 827.745 424.279 826.564 422.754 825.807C421.341 825.18 420.808 825.86 421.688 827.272C422.863 828.934 424.355 830.21 426.028 830.985Z" fill="white" fill-opacity="0.6"/>
        <path d="M437.787 832.899C438.938 834.434 440.456 835.475 442.119 835.869C443.439 836.026 443.54 834.907 442.415 833.39C441.355 832.008 439.98 831.056 438.473 830.66C437.144 830.451 436.789 831.487 437.787 832.899Z" fill="white" fill-opacity="0.6"/>
        <path d="M453.557 834.405C454.169 835.182 454.904 835.794 455.716 836.203C456.529 836.611 457.401 836.808 458.278 836.779C459.783 836.654 460.299 835.566 459.47 834.196C458.88 833.391 458.161 832.746 457.359 832.303C456.556 831.86 455.688 831.628 454.809 831.623C453.21 831.675 452.601 833.003 453.557 834.405Z" fill="white" fill-opacity="0.6"/>
        <path d="M475.621 830.326C473.624 830.441 472.643 831.832 473.539 833.244C474.436 834.656 476.923 835.702 478.945 835.503C480.832 835.315 481.678 834.164 480.908 832.804C480.138 831.445 477.668 830.211 475.621 830.326Z" fill="white" fill-opacity="0.6"/>
        <path d="M500.468 828.119C498.158 828.307 496.838 829.771 497.701 831.183C498.564 832.595 501.288 833.599 503.623 833.327C505.815 833.066 506.923 831.863 506.212 830.483C505.502 829.102 502.845 827.93 500.468 828.119Z" fill="white" fill-opacity="0.6"/>
        <path d="M525.357 828.443C526.254 829.876 529.215 830.765 531.795 830.388C534.376 830.012 535.611 828.548 534.528 827.041C533.581 825.713 530.933 824.887 528.538 825.169C525.975 825.473 524.452 827.041 525.357 828.443Z" fill="white" fill-opacity="0.6"/>
        <path d="M561.837 826.947C564.79 826.644 566.448 825.232 565.695 823.694C564.942 822.157 562.489 821.383 559.824 821.603C557.159 821.822 554.993 823.297 555.534 824.74C556.076 826.184 558.944 827.24 561.837 826.947Z" fill="white" fill-opacity="0.6"/>
        <path d="M587.92 821.101C588.639 822.575 591.743 823.433 594.688 822.994C597.42 822.575 599.028 821.226 598.52 819.783C598.012 818.339 595.051 817.262 592.09 817.628C589.129 817.994 587.209 819.657 587.92 821.101Z" fill="white" fill-opacity="0.6"/>
        <path d="M627.919 818.862C631.049 818.465 632.91 816.959 632.25 815.369C631.675 813.957 629.078 813.068 626.277 813.34C623.325 813.622 620.998 815.264 621.438 816.77C621.878 818.277 624.865 819.249 627.919 818.862Z" fill="white" fill-opacity="0.6"/>
        <path d="M655.583 812.566C656.015 814.093 658.967 815.013 662.038 814.574C664.872 814.176 666.75 812.796 666.488 811.311C666.226 809.825 663.468 808.675 660.431 809.02C657.394 809.365 655.143 811.049 655.583 812.566Z" fill="white" fill-opacity="0.6"/>
        <path d="M694.474 804.732C691.572 805.056 689.195 806.761 689.525 808.298C689.855 809.836 692.689 810.798 695.684 810.39C698.467 810.003 700.379 808.623 700.218 807.116C700.058 805.61 697.452 804.408 694.474 804.732Z" fill="white" fill-opacity="0.6"/>
        <path d="M6.29879 793.519C6.29665 794.273 6.49996 795.007 6.87598 795.6C7.25199 796.194 7.77877 796.614 8.37146 796.793C9.36975 796.95 10.0635 795.967 10.0635 794.408C10.0635 792.85 9.2767 791.093 8.21919 790.852C7.16168 790.612 6.26495 791.909 6.29879 793.519Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.1529 797.107C17.1207 797.847 17.2357 798.586 17.4879 799.259C17.7401 799.932 18.1218 800.518 18.5996 800.966C19.3525 801.447 19.9024 800.747 19.9447 799.261C19.987 797.776 19.4371 795.779 18.6503 795.161C17.8635 794.544 17.1782 795.507 17.1529 797.107Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.1478 803.644C25.172 805.129 25.5913 806.562 26.3407 807.723C26.8822 808.309 27.1868 807.723 27.1868 806.186C27.1825 804.662 26.8146 803.174 26.1292 801.908C25.5793 801.207 25.0971 802.054 25.1478 803.644Z" fill="white" fill-opacity="0.6"/>
        <path d="M31.6959 816.342C32.1104 817.262 32.3135 816.739 32.1951 815.097C32.1033 813.663 31.841 812.253 31.4168 810.913C31.0445 809.961 30.7399 810.474 30.7991 811.959C30.8728 813.477 31.177 814.963 31.6959 816.342Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.6314 822.157C34.7835 823.678 35.0439 825.18 35.4098 826.644C35.6636 827.564 35.7228 827.261 35.579 825.912C35.4203 824.357 35.183 822.817 34.8683 821.299C34.623 820.274 34.4961 820.693 34.6314 822.157Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.1441 833.463C37.3472 834.865 37.7025 836.925 37.8886 837.992C38.0747 839.059 38.0155 838.808 37.804 837.543C37.5925 836.277 37.2626 834.07 37.0934 832.909C36.9242 831.748 36.9411 832.03 37.1441 833.463Z" fill="white" fill-opacity="0.6"/>
        <path d="M38.5992 845.157C38.4638 844.017 38.5992 844.195 38.8614 845.523C39.1237 846.851 39.4621 848.661 39.6228 849.707C39.7835 850.753 39.699 850.972 39.3859 849.707C39.0533 848.21 38.7906 846.691 38.5992 845.157Z" fill="white" fill-opacity="0.6"/>
        <path d="M41.2218 862.122C41.3825 863.283 41.2217 863.168 40.7818 861.787C40.4106 860.44 40.1277 859.059 39.9358 857.656C39.7835 856.474 39.9358 856.537 40.2996 857.771C40.6838 859.195 40.9918 860.648 41.2218 862.122Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.1338 874.202C43.3115 875.248 43.0577 874.914 42.5585 873.523C42.1178 872.266 41.7668 870.964 41.5095 869.632C41.3403 868.586 41.5687 868.826 42.0001 870.071C42.4645 871.409 42.8435 872.791 43.1338 874.202Z" fill="white" fill-opacity="0.6"/>
        <path d="M44.3351 881.357C44.8713 882.618 45.3542 883.913 45.7818 885.237C46.0694 886.168 45.9341 886.095 45.4688 885.07C44.8994 883.821 44.4018 882.524 43.9798 881.189C43.6837 880.143 43.8529 880.227 44.3351 881.357Z" fill="white" fill-opacity="0.6"/>
        <path d="M49.775 895.205C50.2403 896.094 50.1388 895.874 49.5296 894.692C48.9205 893.51 48.2437 892.192 47.8376 891.324C47.4315 890.456 47.4654 890.561 47.9899 891.628C48.5144 892.694 49.3181 894.326 49.775 895.205Z" fill="white" fill-opacity="0.6"/>
        <path d="M55.604 903.541C56.2638 904.252 56.2723 903.928 55.604 902.798C54.9713 901.723 54.2697 900.712 53.5059 899.775C52.8629 899.033 52.8037 899.294 53.3705 900.33C54.0191 901.497 54.768 902.573 55.604 903.541Z" fill="white" fill-opacity="0.6"/>
        <path d="M61.1284 907.055C61.8482 908.21 62.7711 909.147 63.8271 909.795C64.6224 910.214 64.8085 909.795 64.2417 908.833C63.5159 907.667 62.5954 906.707 61.5429 906.02C60.7054 905.528 60.5108 906.02 61.1284 907.055Z" fill="white" fill-opacity="0.6"/>
        <path d="M72.4904 909.67C71.3905 909.45 70.9929 910.182 71.6443 911.207C72.4915 912.386 73.6366 913.175 74.893 913.446C75.9336 913.603 76.2889 912.986 75.739 912.002C74.9025 910.79 73.7565 909.967 72.4904 909.67Z" fill="white" fill-opacity="0.6"/>
        <path d="M247.656 684.376C246.463 684.858 246.141 687.242 247.013 689.324C247.884 691.405 249.703 692.681 250.888 692.106C252.072 691.531 252.19 689.669 251.404 687.608C250.558 685.381 248.866 683.874 247.656 684.376Z" fill="white" fill-opacity="0.6"/>
        <path d="M233.984 691.949C232.859 692.89 232.571 695.662 233.426 697.691C234.28 699.72 236.049 700.557 237.174 699.511C238.299 698.465 238.4 696.321 237.622 694.281C236.776 692.054 235.177 690.976 233.984 691.949Z" fill="white" fill-opacity="0.6"/>
        <path d="M220.423 710.703C221.269 712.794 222.91 713.276 224.027 711.895C224.548 711.101 224.873 710.138 224.963 709.121C225.053 708.105 224.904 707.078 224.535 706.163C223.739 703.967 222.166 703.172 221.032 704.437C219.898 705.703 219.619 708.611 220.423 710.703Z" fill="white" fill-opacity="0.6"/>
        <path d="M208.021 727.437C208.816 729.414 210.423 729.644 211.506 727.981C212 727.1 212.303 726.076 212.386 725.009C212.469 723.941 212.329 722.865 211.98 721.884C211.21 719.792 209.687 719.248 208.596 720.838C208.054 721.786 207.717 722.89 207.616 724.045C207.515 725.2 207.655 726.368 208.021 727.437Z" fill="white" fill-opacity="0.6"/>
        <path d="M196.151 747.017C196.997 748.848 198.52 748.774 199.535 746.881C200 745.949 200.276 744.891 200.339 743.799C200.403 742.706 200.252 741.611 199.899 740.606C199.12 738.587 197.64 738.399 196.599 740.208C196.089 741.216 195.782 742.36 195.704 743.545C195.626 744.73 195.779 745.921 196.151 747.017Z" fill="white" fill-opacity="0.6"/>
        <path d="M187.86 767.706C188.374 766.722 188.674 765.591 188.732 764.423C188.79 763.254 188.603 762.086 188.19 761.033C187.437 759.401 186.151 759.422 185.195 761.033C184.679 762.036 184.357 763.174 184.254 764.357C184.151 765.54 184.271 766.736 184.603 767.852C185.331 769.714 186.836 769.63 187.86 767.706Z" fill="white" fill-opacity="0.6"/>
        <path d="M174.062 782.872C173.564 783.848 173.254 784.952 173.154 786.1C173.054 787.247 173.168 788.408 173.487 789.492C174.129 791.061 175.449 791.061 176.431 789.492C176.975 788.559 177.325 787.474 177.447 786.333C177.57 785.192 177.462 784.032 177.133 782.955C176.439 781.052 175.111 781.01 174.062 782.872Z" fill="white" fill-opacity="0.6"/>
        <path d="M165.745 810.066C166.227 809.162 166.51 808.118 166.565 807.037C166.619 805.957 166.445 804.877 166.058 803.905C165.348 802.493 164.138 802.661 163.233 804.271C162.328 805.882 161.989 808.947 162.666 810.547C163.343 812.147 164.772 811.896 165.745 810.066Z" fill="white" fill-opacity="0.6"/>
        <path d="M152.125 830.106C152.836 831.487 154.24 831.225 155.154 829.478C155.57 828.697 155.819 827.798 155.878 826.865C155.937 825.932 155.804 824.996 155.492 824.144C154.798 822.586 153.487 822.711 152.556 824.385C151.626 826.058 151.423 828.725 152.125 830.106Z" fill="white" fill-opacity="0.6"/>
        <path d="M141.685 846.893C142.37 848.201 143.741 848.106 144.646 846.632C145.053 845.957 145.302 845.157 145.364 844.319C145.427 843.481 145.302 842.638 145.001 841.883C144.333 840.429 143.056 840.429 142.142 841.883C141.704 842.587 141.429 843.426 141.349 844.309C141.268 845.192 141.385 846.086 141.685 846.893Z" fill="white" fill-opacity="0.6"/>
        <path d="M130.518 859.35C129.41 860.971 128.944 863.618 129.587 864.852C130.23 866.086 131.795 865.636 132.912 863.9C134.029 862.164 134.249 860.166 133.673 858.88C133.098 857.593 131.643 857.677 130.518 859.35Z" fill="white" fill-opacity="0.6"/>
        <path d="M117.016 878.836C115.814 880.52 115.324 883.02 115.992 884.066C116.66 885.111 118.386 884.536 119.587 882.79C120.789 881.043 121.034 879.108 120.433 877.968C119.833 876.828 118.234 877.121 117.016 878.836Z" fill="white" fill-opacity="0.6"/>
        <path d="M102.414 896.146C101.128 897.485 100.544 899.556 101.246 900.539C101.948 901.522 103.708 901.25 104.994 899.849C106.28 898.447 106.593 896.711 105.984 895.665C105.375 894.619 103.725 894.786 102.414 896.146Z" fill="white" fill-opacity="0.6"/>
        <path d="M89.3596 911.521C90.8232 910.883 91.3985 909.429 90.7301 908.383C90.0618 907.338 88.412 907.066 87.0499 907.766C85.8317 908.383 85.2056 909.618 85.6879 910.611C86.1701 911.605 87.9214 912.138 89.3596 911.521Z" fill="white" fill-opacity="0.6"/>
        <path d="M413.879 817.628C412.876 816.008 411.569 814.712 410.072 813.852C408.609 813.099 408.152 813.789 409.116 815.442C410.161 817.056 411.505 818.337 413.033 819.176C414.26 819.72 414.641 818.977 413.879 817.628Z" fill="white" fill-opacity="0.6"/>
        <path d="M393.448 805.045C394.445 806.783 395.703 808.267 397.154 809.418C398.355 810.317 398.846 809.92 398.211 808.612C397.171 806.766 395.838 805.2 394.294 804.01C393.042 803.121 392.661 803.55 393.448 805.045Z" fill="white" fill-opacity="0.6"/>
        <path d="M379.929 791.584C378.787 790.434 378.465 790.674 379.21 792.191C380.156 793.989 381.295 795.622 382.594 797.044C383.804 798.31 384.176 797.933 383.338 796.354C382.373 794.589 381.227 792.986 379.929 791.584Z" fill="white" fill-opacity="0.6"/>
        <path d="M366.816 777.391C367.728 779.267 368.797 781.02 370.005 782.621C371.122 784.064 371.401 783.855 370.606 782.223C369.689 780.391 368.632 778.671 367.45 777.088C366.401 775.707 366.114 775.843 366.816 777.391Z" fill="white" fill-opacity="0.6"/>
        <path d="M355.378 761.451C354.346 760.091 354.058 760.29 354.76 761.911C355.661 763.846 356.725 765.659 357.933 767.319C359.041 768.794 359.303 768.532 358.516 766.817C357.617 764.904 356.566 763.106 355.378 761.451Z" fill="white" fill-opacity="0.6"/>
        <path d="M343.254 745.522C342.231 744.193 341.943 744.476 342.645 746.201C343.52 748.196 344.582 750.057 345.809 751.745C346.909 753.199 347.171 752.791 346.385 751.023C345.509 749.052 344.459 747.207 343.254 745.522Z" fill="white" fill-opacity="0.6"/>
        <path d="M330.437 730.92C331.297 732.94 332.375 734.806 333.644 736.464C334.625 737.656 334.887 737.259 334.244 735.617C333.368 733.46 332.245 731.47 330.911 729.707C329.93 728.483 329.684 729.038 330.437 730.92Z" fill="white" fill-opacity="0.6"/>
        <path d="M318.585 714.886C317.536 713.683 317.198 714.175 317.866 716.037C318.678 718.1 319.752 719.989 321.047 721.633C322.172 722.961 322.502 722.344 321.741 720.398C320.93 718.367 319.865 716.507 318.585 714.886Z" fill="white" fill-opacity="0.6"/>
        <path d="M308.374 708.37C309.406 709.416 309.761 708.736 309.169 706.989C308.375 704.8 307.223 702.837 305.785 701.226C304.727 700.243 304.423 700.996 305.134 702.973C305.916 705.018 307.017 706.852 308.374 708.37Z" fill="white" fill-opacity="0.6"/>
        <path d="M296.047 694.721C295.323 692.713 294.217 690.951 292.824 689.585C291.614 688.612 291.259 689.69 292.113 691.949C292.876 693.922 294.021 695.633 295.447 696.928C296.462 697.618 296.724 696.582 296.047 694.721Z" fill="white" fill-opacity="0.6"/>
        <path d="M278.442 682.787C279.133 684.771 280.295 686.449 281.775 687.598C282.867 688.236 283.29 687.169 282.723 685.328C282.08 683.236 280.447 680.81 279.246 680.193C278.044 679.576 277.748 680.737 278.442 682.787Z" fill="white" fill-opacity="0.6"/>
        <path d="M264.297 676.689C264.903 678.669 266.094 680.291 267.63 681.228C268.924 681.824 269.449 680.35 268.747 678.268C268.045 676.187 266.606 674.336 265.414 673.907C264.221 673.478 263.679 674.67 264.297 676.689Z" fill="white" fill-opacity="0.6"/>
        <path d="M253.307 678.415C254.475 678.415 254.932 676.814 254.322 674.932C253.637 672.84 251.928 670.978 250.642 671.062C249.475 671.334 249.128 673.154 249.864 675.162C250.158 676.029 250.63 676.786 251.234 677.356C251.839 677.927 252.553 678.292 253.307 678.415Z" fill="white" fill-opacity="0.6"/>
        <path d="M424.979 822.167C425.898 823.778 427.208 824.986 428.718 825.619C430.165 826.173 430.748 825.232 429.962 823.768C429.016 822.228 427.706 821.091 426.214 820.515C424.835 820.065 424.276 820.755 424.979 822.167Z" fill="white" fill-opacity="0.6"/>
        <path d="M445.537 826.142C444.611 824.716 443.267 823.799 441.797 823.59C440.41 823.485 439.978 824.636 440.951 826.226C441.885 827.661 443.237 828.588 444.716 828.809C445.833 828.788 446.29 827.46 445.537 826.142Z" fill="white" fill-opacity="0.6"/>
        <path d="M464.707 825.211C464.121 824.442 463.406 823.839 462.613 823.443C461.82 823.048 460.967 822.87 460.113 822.92C458.21 823.046 457.465 824.427 458.421 825.964C459.024 826.715 459.752 827.296 460.553 827.666C461.355 828.036 462.212 828.187 463.066 828.108C464.716 827.847 465.477 826.529 464.707 825.211Z" fill="white" fill-opacity="0.6"/>
        <path d="M483.353 821.121C481.187 821.216 479.969 822.397 480.519 823.841C481.069 825.284 483.641 826.56 485.84 826.341C487.829 826.152 489.03 824.782 488.378 823.443C487.727 822.105 485.477 821.017 483.353 821.121Z" fill="white" fill-opacity="0.6"/>
        <path d="M506.923 821.571C507.667 822.952 510.214 823.914 512.506 823.663C514.799 823.412 516.28 821.99 515.721 820.64C515.095 819.113 512.253 818.067 509.706 818.433C507.354 818.831 506.153 820.17 506.923 821.571Z" fill="white" fill-opacity="0.6"/>
        <path d="M536.262 818.182C536.787 819.636 539.24 820.619 541.88 820.452C544.519 820.285 546.626 818.601 545.924 817.126C545.222 815.651 542.607 814.825 540.002 815.097C537.396 815.369 535.738 816.708 536.262 818.182Z" fill="white" fill-opacity="0.6"/>
        <path d="M574.003 816.624C576.541 816.394 578.749 814.877 578.495 813.486C578.241 812.095 575.652 810.997 572.886 811.154C569.79 811.321 567.624 812.827 568.165 814.438C568.647 815.871 571.287 816.854 574.003 816.624Z" fill="white" fill-opacity="0.6"/>
        <path d="M601.752 810.359C602.242 811.802 604.958 812.733 607.758 812.451C610.559 812.168 612.64 810.589 612.386 809.177C612.098 807.598 609.002 806.562 605.939 807.012C603.063 807.409 601.253 808.863 601.752 810.359Z" fill="white" fill-opacity="0.6"/>
        <path d="M636.184 805.83C636.446 807.336 638.917 808.319 641.835 808.089C644.754 807.859 647.385 806.06 646.912 804.533C646.438 803.006 643.807 802.169 640.913 802.514C638.02 802.859 635.939 804.292 636.184 805.83Z" fill="white" fill-opacity="0.6"/>
        <path d="M670.93 801.563C671.26 803.048 673.865 804 676.666 803.654C679.466 803.309 681.691 801.74 681.589 800.297C681.471 798.665 678.569 797.609 675.447 798.08C672.562 798.561 670.591 800.025 670.93 801.563Z" fill="white" fill-opacity="0.6"/>
        <path d="M705.032 797.086C705.108 798.634 707.359 799.669 710.15 799.471C712.942 799.272 715.616 797.483 715.362 795.904C715.108 794.325 712.697 793.446 709.922 793.76C707.147 794.074 704.947 795.517 705.032 797.086Z" fill="white" fill-opacity="0.6"/>
        <path d="M13.8282 783.416C12.9822 783.05 12.2462 784.033 12.2208 785.633C12.1905 786.344 12.3141 787.052 12.5788 787.685C12.8434 788.317 13.2397 788.851 13.7267 789.231C14.5727 789.702 15.4187 788.562 15.4187 786.919C15.4187 785.277 14.6742 783.771 13.8282 783.416Z" fill="white" fill-opacity="0.6"/>
        <path d="M21.4506 790.999C21.4329 792.426 21.8531 793.809 22.6266 794.869C23.2103 795.433 23.7264 794.659 23.7349 793.216C23.7349 791.605 23.2103 789.587 22.5419 789.032C21.8736 788.478 21.4337 789.388 21.4506 790.999Z" fill="white" fill-opacity="0.6"/>
        <path d="M27.9987 798.561C28.0248 800.016 28.3139 801.445 28.8447 802.744C29.2423 803.508 29.5808 802.975 29.5638 801.615C29.5589 800.049 29.2698 798.505 28.7178 797.096C28.2948 796.396 27.9649 797.034 27.9987 798.561Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.711 807.022C32.4572 806.206 32.2964 806.803 32.3895 808.372C32.4753 809.83 32.7055 811.27 33.0748 812.66C33.3709 813.58 33.4978 812.921 33.3709 811.29C33.2791 809.841 33.0577 808.41 32.711 807.022Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.1645 819.479C35.2999 820.944 35.5876 822.941 35.7483 823.851C35.9091 824.761 35.9006 824.353 35.7483 823.004C35.5876 821.477 35.3338 819.249 35.173 818.297C35.0123 817.346 35.0123 817.984 35.1645 819.479Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.6366 830.681C36.552 829.708 36.6366 830.106 36.8311 831.57C37.0257 833.035 37.2965 834.97 37.4065 835.963C37.5164 836.957 37.4065 836.612 37.1526 835.074C36.8988 833.537 36.7212 831.643 36.6366 830.681Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.9309 844.101C38.1932 845.429 38.4978 847.385 38.5993 848.441C38.7008 849.498 38.5316 849.32 38.2693 848.138C37.956 846.605 37.7327 845.046 37.601 843.473C37.5333 842.49 37.6687 842.73 37.9309 844.101Z" fill="white" fill-opacity="0.6"/>
        <path d="M38.6162 856.254C38.5147 855.208 38.667 855.355 38.997 856.631C39.3452 858.004 39.6277 859.401 39.843 860.815C39.9952 861.975 39.7753 861.787 39.4115 860.448C39.0554 859.079 38.7894 857.677 38.6162 856.254Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.5958 868.941C41.0047 870.242 41.3577 871.569 41.6533 872.916C41.8309 873.826 41.6533 873.659 41.281 872.55C40.8263 871.168 40.4586 869.745 40.1812 868.293C40.0459 867.372 40.2235 867.665 40.5958 868.941Z" fill="white" fill-opacity="0.6"/>
        <path d="M44.3098 883.229C43.8529 882.057 43.2522 880.394 42.9646 879.526C42.6769 878.658 42.7954 878.93 43.3115 880.321C43.7599 881.534 44.3943 883.239 44.6481 883.971C44.9019 884.704 44.7497 884.327 44.3098 883.229Z" fill="white" fill-opacity="0.6"/>
        <path d="M49.056 892.506C48.5531 891.342 47.9878 890.22 47.364 889.149C46.8648 888.343 46.9325 888.793 47.5163 890.121C48.0099 891.276 48.5756 892.381 49.2083 893.427C49.6482 894.023 49.5806 893.594 49.056 892.506Z" fill="white" fill-opacity="0.6"/>
        <path d="M53.6919 897.715C54.2358 898.891 54.9566 899.928 55.8154 900.769C56.543 901.418 56.7291 900.905 56.1792 899.723C55.6107 898.577 54.8765 897.57 54.0134 896.753C53.3366 896.209 53.2012 896.606 53.6919 897.715Z" fill="white" fill-opacity="0.6"/>
        <path d="M65.3076 905.926C66.1536 906.239 66.5597 905.591 66.1028 904.566C65.4445 903.288 64.4493 902.331 63.2856 901.857C62.3889 901.595 62.1435 902.233 62.6934 903.363C63.3214 904.54 64.2374 905.438 65.3076 905.926Z" fill="white" fill-opacity="0.6"/>
        <path d="M74.7491 905.873C75.0734 906.532 75.5332 907.073 76.0816 907.44C76.63 907.808 77.2474 907.989 77.8709 907.965C79.2668 907.881 80.0621 906.522 79.4952 905.403C79.0862 904.825 78.5672 904.383 77.9852 904.117C77.4031 903.85 76.7765 903.767 76.162 903.875C74.8168 904.032 74.2669 904.765 74.7491 905.873Z" fill="white" fill-opacity="0.6"/>
        <path d="M91.6184 896.564C90.3494 897.61 89.7741 899.2 90.324 900.298C90.8739 901.397 92.3883 901.522 93.6404 900.56C94.8925 899.598 95.6285 897.422 94.9771 896.376C94.3257 895.33 92.8705 895.518 91.6184 896.564Z" fill="white" fill-opacity="0.6"/>
        <path d="M105.484 886.053C106.001 887.172 107.464 886.952 108.665 885.53C109.867 884.107 110.451 881.817 110.036 880.635C109.571 879.296 108.022 879.641 106.72 881.304C105.417 882.967 104.951 884.892 105.484 886.053Z" fill="white" fill-opacity="0.6"/>
        <path d="M122.658 866.546C123.724 865.051 124.291 862.498 123.894 861.201C123.496 859.905 122.202 860.02 121.068 861.536C119.782 863.241 119.122 865.793 119.68 867.174C120.205 868.45 121.567 868.157 122.658 866.546Z" fill="white" fill-opacity="0.6"/>
        <path d="M132.582 847.719C133.098 849.006 134.376 848.828 135.348 847.395C135.81 846.678 136.132 845.838 136.289 844.942C136.446 844.046 136.434 843.118 136.254 842.228C135.771 840.722 134.443 840.837 133.403 842.532C132.362 844.226 132.049 846.37 132.582 847.719Z" fill="white" fill-opacity="0.6"/>
        <path d="M146.152 830.368C146.544 829.584 146.789 828.702 146.87 827.787C146.95 826.871 146.864 825.944 146.617 825.075C146 823.632 144.748 823.966 143.885 825.744C143.49 826.499 143.252 827.363 143.191 828.259C143.131 829.154 143.25 830.055 143.538 830.88C144.121 832.093 145.365 831.874 146.152 830.368Z" fill="white" fill-opacity="0.6"/>
        <path d="M154.434 806.06C153.991 806.897 153.704 807.846 153.592 808.837C153.481 809.828 153.549 810.837 153.792 811.792C154.308 813.277 155.484 813.256 156.397 811.719C157.311 810.181 157.649 806.96 157.032 805.443C156.414 803.926 155.306 804.376 154.434 806.06Z" fill="white" fill-opacity="0.6"/>
        <path d="M164.409 790.957C164.992 792.463 166.236 792.285 167.124 790.549C167.56 789.606 167.841 788.567 167.951 787.49C168.061 786.413 167.999 785.32 167.767 784.273C167.201 782.495 165.864 782.757 164.925 784.817C164.482 785.732 164.207 786.756 164.118 787.816C164.029 788.877 164.128 789.949 164.409 790.957Z" fill="white" fill-opacity="0.6"/>
        <path d="M175.246 768.877C175.838 770.498 177.116 770.383 178.038 768.626C178.945 766.667 179.189 764.343 178.715 762.162C178.131 760.259 176.752 760.436 175.779 762.528C175.328 763.478 175.046 764.536 174.954 765.631C174.863 766.726 174.962 767.833 175.246 768.877Z" fill="white" fill-opacity="0.6"/>
        <path d="M189.29 746.881C189.759 745.911 190.066 744.835 190.193 743.716C190.319 742.596 190.262 741.457 190.026 740.365C189.493 738.535 188.232 738.326 187.234 739.936C186.665 740.914 186.292 742.045 186.145 743.236C185.998 744.427 186.081 745.644 186.388 746.787C187.014 748.523 188.326 748.565 189.29 746.881Z" fill="white" fill-opacity="0.6"/>
        <path d="M200.931 726.392C201.432 725.468 201.767 724.423 201.913 723.326C202.059 722.228 202.013 721.105 201.777 720.032C201.261 718.118 199.95 717.627 198.909 718.986C198.311 719.919 197.912 721.023 197.748 722.196C197.584 723.369 197.66 724.574 197.97 725.701C198.562 727.605 199.916 727.898 200.931 726.392Z" fill="white" fill-opacity="0.6"/>
        <path d="M210.804 700.934C210.269 701.772 209.916 702.765 209.778 703.82C209.64 704.874 209.723 705.954 210.017 706.958C210.618 708.893 212.005 709.458 213.071 708.234C214.137 707.01 214.568 703.726 213.858 701.791C213.147 699.856 211.836 699.553 210.804 700.934Z" fill="white" fill-opacity="0.6"/>
        <path d="M223.333 686.092C222.789 686.844 222.436 687.778 222.317 688.776C222.199 689.775 222.319 690.794 222.665 691.708C223.333 693.633 224.805 694.344 225.863 693.319C226.92 692.294 227.318 689.271 226.54 687.357C225.761 685.443 224.408 684.931 223.333 686.092Z" fill="white" fill-opacity="0.6"/>
        <path d="M239.213 683.079C240.304 682.504 240.744 680.297 240.169 678.362C239.534 676.208 237.893 674.9 236.692 675.695C235.49 676.49 235.219 678.572 235.913 680.611C236.607 682.651 238.096 683.676 239.213 683.079Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.0468002 783.384C0.0468 785.236 0.748965 786.365 1.87416 786.459C2.17025 786.456 2.46265 786.378 2.73287 786.228C3.00308 786.078 3.24527 785.861 3.44405 785.59C3.64284 785.318 3.79388 784.999 3.88771 784.652C3.98155 784.305 4.01611 783.937 3.98918 783.573C3.97654 782.855 3.76044 782.165 3.37853 781.624C2.99662 781.083 2.47318 780.725 1.89956 780.613C0.72361 780.487 0.0468003 781.47 0.0468002 783.384Z" fill="white" fill-opacity="0.6"/>
        <path d="M243.798 652.402C243.947 653.214 244.301 653.945 244.809 654.491C245.317 655.036 245.953 655.367 246.624 655.436C247.681 655.436 248.316 654.149 248.096 652.601C247.984 651.747 247.668 650.954 247.189 650.324C246.709 649.693 246.088 649.255 245.405 649.066C244.213 648.94 243.468 650.645 243.798 652.402Z" fill="white" fill-opacity="0.6"/>
        <path d="M229.856 657.663C230.177 659.462 231.455 660.477 232.555 659.86C233.519 659.326 234.137 657.454 233.908 655.854C233.638 654.013 232.495 652.81 231.37 653.291C230.245 653.772 229.543 655.843 229.856 657.663Z" fill="white" fill-opacity="0.6"/>
        <path d="M216.7 669.054C217.081 670.821 218.392 671.323 219.357 670.173C219.839 669.526 220.192 668.747 220.386 667.901C220.58 667.054 220.61 666.162 220.474 665.299C220.144 663.458 219.002 662.715 217.936 663.751C217.391 664.439 216.992 665.284 216.777 666.209C216.561 667.135 216.535 668.112 216.7 669.054Z" fill="white" fill-opacity="0.6"/>
        <path d="M206.667 685.516C207.169 684.693 207.522 683.746 207.7 682.738C207.878 681.731 207.878 680.687 207.699 679.68C207.327 678.153 206.32 677.86 205.423 679.042C204.895 679.854 204.509 680.793 204.29 681.8C204.072 682.807 204.026 683.859 204.154 684.889C204.484 686.646 205.686 686.96 206.667 685.516Z" fill="white" fill-opacity="0.6"/>
        <path d="M193.41 698.151C192.4 699.99 191.973 702.224 192.209 704.427C192.53 706.163 193.689 706.205 194.62 704.521C195.489 702.795 195.861 700.757 195.677 698.737C195.415 696.823 194.375 696.562 193.41 698.151Z" fill="white" fill-opacity="0.6"/>
        <path d="M180.771 726.203C181.067 727.908 182.175 727.814 183.08 726.005C183.907 724.186 184.267 722.099 184.112 720.022C183.842 718.202 182.869 718.087 181.947 719.781C180.992 721.703 180.578 723.961 180.771 726.203Z" fill="white" fill-opacity="0.6"/>
        <path d="M172.023 748.774C172.88 746.835 173.178 744.592 172.869 742.415C172.53 741.066 171.642 741.286 170.847 742.896C169.944 744.837 169.556 747.08 169.739 749.308C170.086 750.919 171.16 750.668 172.023 748.774Z" fill="white" fill-opacity="0.6"/>
        <path d="M159.197 772.569C159.544 773.971 160.636 773.469 161.422 771.523C162.155 769.754 162.433 767.751 162.218 765.781C161.905 764.233 160.94 764.537 160.12 766.409C159.299 768.281 158.842 771.168 159.197 772.569Z" fill="white" fill-opacity="0.6"/>
        <path d="M149.917 788.352C149.054 790.11 148.666 792.161 148.808 794.21C149.062 795.726 150.052 795.569 150.881 793.875C151.635 792.195 151.97 790.278 151.846 788.363C151.617 786.742 150.754 786.742 149.917 788.352Z" fill="white" fill-opacity="0.6"/>
        <path d="M140.721 813.674C141.526 812.075 141.828 810.165 141.567 808.309C141.279 807.116 140.475 807.2 139.739 808.476C138.894 810.048 138.515 811.932 138.665 813.81C138.944 815.243 139.917 815.191 140.721 813.674Z" fill="white" fill-opacity="0.6"/>
        <path d="M128.014 831.361C128.208 832.815 129.266 832.731 130.213 831.256C131.066 829.827 131.507 828.085 131.465 826.309C131.296 824.761 130.425 824.563 129.477 825.943C128.486 827.478 127.967 829.398 128.014 831.361Z" fill="white" fill-opacity="0.6"/>
        <path d="M116.804 846.37C115.747 848.033 114.985 850.638 115.264 851.914C115.544 853.19 116.77 852.761 117.802 851.025C118.718 849.57 119.189 847.762 119.131 845.92C118.936 844.519 117.862 844.728 116.804 846.37Z" fill="white" fill-opacity="0.6"/>
        <path d="M103.581 871.127C104.757 869.705 105.518 867.278 105.273 866.013C105.028 864.747 104.029 864.967 102.93 866.18C101.83 867.393 100.806 869.925 100.984 871.274C101.161 872.623 102.354 872.571 103.581 871.127Z" fill="white" fill-opacity="0.6"/>
        <path d="M85.6963 886.021C85.9162 887.308 87.2529 887.643 88.4966 886.67C89.0291 886.255 89.4701 885.683 89.7802 885.006C90.0902 884.329 90.2596 883.567 90.2732 882.789C90.1125 881.44 88.945 881.116 87.6844 882.005C86.4238 882.894 85.434 884.745 85.6963 886.021Z" fill="white" fill-opacity="0.6"/>
        <path d="M70.0538 891.826C70.2649 892.527 70.6592 893.121 71.1741 893.513C71.6891 893.905 72.2952 894.073 72.8964 893.991C73.1474 893.976 73.3931 893.895 73.6175 893.756C73.842 893.616 74.0402 893.419 74.1996 893.179C74.3589 892.938 74.4758 892.659 74.5428 892.36C74.6098 892.06 74.6253 891.747 74.5884 891.439C74.39 890.75 74.006 890.166 73.5008 889.785C72.9956 889.405 72.3999 889.25 71.8135 889.348C70.5191 889.504 69.7492 890.603 70.0538 891.826Z" fill="white" fill-opacity="0.6"/>
        <path d="M59.8087 892.077C60.7054 892.454 61.2384 891.638 60.9508 890.383C60.7985 889.779 60.546 889.22 60.2096 888.743C59.8731 888.266 59.46 887.88 58.9965 887.611C58.1505 887.172 57.4821 887.894 57.7105 889.096C57.8581 889.755 58.1232 890.365 58.4863 890.881C58.8493 891.397 59.3012 891.806 59.8087 892.077Z" fill="white" fill-opacity="0.6"/>
        <path d="M49.4367 882.371C48.8191 881.869 48.5906 882.549 48.8952 883.815C49.2118 885.071 49.8209 886.184 50.6465 887.015C51.1964 887.392 51.4163 886.806 51.1456 885.666C50.8393 884.39 50.2468 883.248 49.4367 882.371Z" fill="white" fill-opacity="0.6"/>
        <path d="M44.0898 879.097C44.5128 879.85 44.6228 879.369 44.3182 878.051C44.0329 876.881 43.6352 875.758 43.1338 874.704C42.7108 873.878 42.55 874.234 42.8123 875.447C43.1039 876.729 43.5336 877.957 44.0898 879.097Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.0223 864.643C38.777 863.764 38.7855 864.13 39.0223 865.417C39.2592 866.703 39.7415 868.554 39.9783 869.37C40.2152 870.186 40.1814 869.768 39.9783 868.649C39.7753 867.529 39.2761 865.5 39.0223 864.643Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.9411 853.618C37.161 854.905 37.4825 856.892 37.6179 857.855C37.7533 858.817 37.6179 858.503 37.4571 857.415C37.2964 856.327 36.9242 854.204 36.7888 853.148C36.6534 852.091 36.7211 852.332 36.9411 853.618Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.7566 840.398C35.6974 839.352 35.7988 839.54 35.9765 840.879C36.1542 842.218 36.3826 844.289 36.4419 845.293C36.5011 846.297 36.3826 845.994 36.205 844.864C36.0104 843.598 35.8158 841.559 35.7566 840.398Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.8837 832.125C35.926 833.233 35.8075 832.993 35.6552 831.591C35.5029 830.19 35.4014 828.453 35.3676 827.313C35.3337 826.173 35.3676 826.267 35.5368 827.554C35.706 828.84 35.8414 830.985 35.8837 832.125Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.0968 814.386C35.1814 815.766 35.3252 817.91 35.3845 818.998C35.4437 820.086 35.3844 819.772 35.2914 818.632C35.1898 817.293 35.0883 815.17 35.0375 813.967C34.9868 812.764 35.0122 813.005 35.0968 814.386Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.3946 806.416C34.5046 807.326 34.5131 806.792 34.4454 805.223C34.3862 803.843 34.2762 801.866 34.1831 800.977C34.0901 800.088 34.0055 800.422 34.0562 801.908C34.107 803.393 34.2762 805.516 34.3946 806.416Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.2543 789.147C32.0428 788.196 31.8313 788.708 31.8398 790.193C31.854 791.683 32.013 793.165 32.3135 794.607C32.5081 795.36 32.6519 794.942 32.6434 793.655C32.636 792.14 32.5056 790.63 32.2543 789.147Z" fill="white" fill-opacity="0.6"/>
        <path d="M27.9057 780.382C27.8909 781.798 28.1521 783.197 28.6671 784.462C29.014 785.016 29.2593 784.388 29.2677 782.987C29.3059 781.527 29.0865 780.074 28.6247 778.73C28.2271 778.04 27.9057 778.824 27.9057 780.382Z" fill="white" fill-opacity="0.6"/>
        <path d="M22.6098 776.084C23.1851 776.722 23.6843 775.916 23.7097 774.358C23.7688 773.025 23.4715 771.706 22.8636 770.603C22.3137 769.871 21.7046 770.603 21.6285 772.109C21.5493 773.538 21.8987 774.953 22.6098 776.084Z" fill="white" fill-opacity="0.6"/>
        <path d="M13.9888 764.725C13.1428 764.453 12.4237 765.603 12.4576 767.162C12.4914 768.72 13.3036 770.3 14.0988 770.425C14.8941 770.551 15.4271 769.651 15.444 768.229C15.4609 766.806 14.801 764.986 13.9888 764.725Z" fill="white" fill-opacity="0.6"/>
        <path d="M708.382 781.146C711.098 780.905 713.458 779.316 713.408 777.799C713.357 776.282 711.479 775.435 708.941 775.55C706.403 775.665 703.619 777.255 703.475 778.772C703.332 780.288 705.557 781.407 708.382 781.146Z" fill="white" fill-opacity="0.6"/>
        <path d="M668.451 783.437C668.502 784.932 671.073 785.936 673.908 785.528C676.395 785.204 678.654 783.74 678.747 782.391C678.848 780.864 676.547 779.765 673.713 780.037C670.879 780.309 668.4 781.93 668.451 783.437Z" fill="white" fill-opacity="0.6"/>
        <path d="M632.8 788.101C632.919 789.587 635.583 790.559 638.435 790.193C640.973 789.859 643.172 788.405 643.198 787.055C643.198 785.539 640.829 784.472 637.969 784.754C635.11 785.037 632.716 786.595 632.8 788.101Z" fill="white" fill-opacity="0.6"/>
        <path d="M597.412 792.662C597.589 794.136 600.254 795.109 603.071 794.754C605.55 794.429 607.716 792.986 607.691 791.616C607.691 790.11 605.246 789.043 602.412 789.315C599.577 789.587 597.268 791.187 597.412 792.662Z" fill="white" fill-opacity="0.6"/>
        <path d="M567.59 793.812C564.9 794.074 562.725 795.622 563.013 797.076C563.301 798.529 565.957 799.492 568.673 799.115C571.075 798.78 573.03 797.379 572.903 796.071C572.734 794.565 570.356 793.53 567.59 793.812Z" fill="white" fill-opacity="0.6"/>
        <path d="M534.308 797.881C531.77 798.216 529.96 799.764 530.442 801.186C530.924 802.608 533.597 803.498 536.093 803.079C538.403 802.682 539.959 801.343 539.663 800.015C539.367 798.686 536.804 797.515 534.308 797.881Z" fill="white" fill-opacity="0.6"/>
        <path d="M500.375 804.292C500.654 805.736 502.913 806.813 505.282 806.604C507.32 806.426 509.038 805.098 508.894 803.832C508.725 802.357 506.618 801.217 504.249 801.332C501.881 801.447 500.096 802.859 500.375 804.292Z" fill="white" fill-opacity="0.6"/>
        <path d="M473.827 807.043C474.25 808.476 476.365 809.533 478.404 809.292C480.206 809.072 481.483 807.817 481.196 806.531C480.874 805.045 478.903 803.947 476.83 804.083C474.758 804.219 473.413 805.61 473.827 807.043Z" fill="white" fill-opacity="0.6"/>
        <path d="M453.895 805.882C452.203 805.882 451.078 807.19 451.433 808.612C451.789 810.035 453.582 811.217 455.249 811.112C456.712 811.028 457.787 809.825 457.541 808.56C457.262 807.106 455.57 805.882 453.895 805.882Z" fill="white" fill-opacity="0.6"/>
        <path d="M434.133 809.083C434.407 809.833 434.84 810.477 435.387 810.947C435.934 811.417 436.573 811.694 437.237 811.75C437.479 811.754 437.719 811.684 437.933 811.546C438.148 811.407 438.331 811.204 438.466 810.956C438.601 810.708 438.684 810.422 438.708 810.124C438.731 809.826 438.694 809.526 438.599 809.25C438.361 808.498 437.958 807.843 437.435 807.359C436.911 806.875 436.289 806.581 435.638 806.51C435.378 806.469 435.113 806.515 434.872 806.645C434.631 806.774 434.422 806.981 434.268 807.244C434.114 807.507 434.02 807.817 433.996 808.14C433.973 808.464 434.02 808.789 434.133 809.083Z" fill="white" fill-opacity="0.6"/>
        <path d="M419.801 803.602C418.515 803.32 417.813 804.251 418.321 805.694C418.664 806.48 419.138 807.167 419.713 807.708C420.288 808.249 420.95 808.632 421.654 808.832C422.805 809.01 423.346 808.141 422.915 806.824C422.625 806.032 422.195 805.33 421.656 804.773C421.117 804.215 420.483 803.815 419.801 803.602Z" fill="white" fill-opacity="0.6"/>
        <path d="M402.069 798.268C402.739 799.983 403.878 801.35 405.284 802.127C406.434 802.682 407.103 802.127 406.798 800.872C406.217 799.159 405.14 797.775 403.778 796.992C402.492 796.281 401.663 796.866 402.069 798.268Z" fill="white" fill-opacity="0.6"/>
        <path d="M386.384 787.652C387.026 789.452 388.075 790.984 389.404 792.065C390.479 792.913 391.206 792.599 390.944 791.448C390.375 789.67 389.387 788.144 388.11 787.066C386.875 786.01 386.037 786.292 386.384 787.652Z" fill="white" fill-opacity="0.6"/>
        <path d="M373.542 774.044C372.459 772.852 371.85 773.05 372.222 774.483C372.859 776.327 373.818 777.974 375.031 779.305C375.987 780.278 376.528 780.048 376.206 778.834C375.623 777.009 374.712 775.371 373.542 774.044Z" fill="white" fill-opacity="0.6"/>
        <path d="M361.08 758.753C360.124 757.466 359.608 757.654 360.022 759.171C360.637 761.049 361.516 762.777 362.62 764.275C363.466 765.321 363.846 765.154 363.508 763.857C362.95 761.99 362.128 760.262 361.08 758.753Z" fill="white" fill-opacity="0.6"/>
        <path d="M348.136 743.032C348.752 744.952 349.637 746.722 350.75 748.262C351.596 749.308 351.943 749.088 351.596 747.697C351.033 745.771 350.202 743.98 349.143 742.405C348.195 741.16 347.696 741.432 348.136 743.032Z" fill="white" fill-opacity="0.6"/>
        <path d="M338.72 731.83C339.668 733.065 340.023 732.646 339.566 730.931C339.021 729.109 338.238 727.409 337.248 725.9C336.292 724.572 335.75 724.854 336.148 726.444C336.727 728.421 337.599 730.248 338.72 731.83Z" fill="white" fill-opacity="0.6"/>
        <path d="M326.597 715.639C327.561 716.821 327.942 716.33 327.485 714.593C326.959 712.755 326.171 711.047 325.158 709.552C324.186 708.255 323.619 708.59 324.008 710.263C324.567 712.254 325.448 714.083 326.597 715.639Z" fill="white" fill-opacity="0.6"/>
        <path d="M311.656 694.951C312.188 696.943 313.082 698.757 314.27 700.254C315.167 701.237 315.59 700.818 315.286 699.312C314.808 697.325 313.972 695.498 312.841 693.968C311.859 692.754 311.276 693.236 311.656 694.951Z" fill="white" fill-opacity="0.6"/>
        <path d="M301.606 686.186C302.621 687.18 303.078 686.468 302.646 684.648C302.191 682.842 301.393 681.196 300.32 679.848C299.304 678.739 298.628 679.314 299.017 681.05C299.494 683.017 300.388 684.79 301.606 686.186Z" fill="white" fill-opacity="0.6"/>
        <path d="M285.938 669.367C286.399 671.286 287.352 672.967 288.653 674.158C289.592 674.848 290.066 674.158 289.77 672.547C289.363 670.617 288.475 668.893 287.232 667.621C286.217 666.773 285.574 667.589 285.938 669.367Z" fill="white" fill-opacity="0.6"/>
        <path d="M276.386 662.412C276.006 660.592 274.779 658.511 273.721 658.008C272.664 657.506 272.029 658.615 272.486 660.435C272.937 662.141 273.856 663.599 275.083 664.556C276.175 665.225 276.767 664.211 276.386 662.412Z" fill="white" fill-opacity="0.6"/>
        <path d="M258.375 654.222C258.555 655.109 258.903 655.929 259.392 656.615C259.881 657.301 260.495 657.833 261.183 658.165C262.199 658.458 262.816 657.402 262.571 655.843C262.435 654.948 262.127 654.105 261.674 653.387C261.221 652.668 260.635 652.094 259.965 651.712C258.865 651.262 258.019 652.465 258.375 654.222Z" fill="white" fill-opacity="0.6"/>
        <path d="M242.326 668.029C243.519 667.642 244.018 665.937 243.553 664.044C243.087 662.15 241.734 660.906 240.549 661.282C239.365 661.659 238.764 663.583 239.238 665.393C239.712 667.202 241.159 668.405 242.326 668.029Z" fill="white" fill-opacity="0.6"/>
        <path d="M225.66 673.687C226.057 675.559 227.428 676.501 228.553 675.779C229.158 675.261 229.611 674.513 229.841 673.649C230.072 672.784 230.068 671.85 229.83 670.989C229.407 669.074 228.138 668.06 227.005 668.813C225.871 669.566 225.254 671.794 225.66 673.687Z" fill="white" fill-opacity="0.6"/>
        <path d="M215.719 688.529C216.266 687.784 216.648 686.879 216.829 685.898C217.009 684.918 216.983 683.897 216.751 682.933C216.26 681.071 215.059 680.559 213.959 681.835C213.409 682.598 213.019 683.518 212.826 684.513C212.633 685.507 212.641 686.546 212.851 687.535C213.299 689.334 214.645 689.784 215.719 688.529Z" fill="white" fill-opacity="0.6"/>
        <path d="M200.491 705.034C200.914 706.833 202.183 707.125 203.224 705.609C203.757 704.765 204.128 703.782 204.307 702.734C204.486 701.686 204.468 700.602 204.256 699.563C203.807 697.691 202.64 697.377 201.608 698.894C201.074 699.757 200.697 700.752 200.504 701.812C200.311 702.872 200.307 703.971 200.491 705.034Z" fill="white" fill-opacity="0.6"/>
        <path d="M189.721 719.352C188.774 721.162 188.359 724.164 188.875 725.743C189.391 727.322 190.627 727.176 191.574 725.356C192.047 724.423 192.361 723.38 192.494 722.292C192.628 721.203 192.58 720.093 192.353 719.028C191.82 717.334 190.686 717.47 189.721 719.352Z" fill="white" fill-opacity="0.6"/>
        <path d="M177.505 747.593C177.877 749.308 179.07 749.287 180.043 747.53C181.041 745.64 181.399 743.342 181.041 741.129C180.635 739.34 179.552 739.33 178.596 741.129C177.613 743.05 177.226 745.346 177.505 747.593Z" fill="white" fill-opacity="0.6"/>
        <path d="M169.079 770.049C170.015 768.175 170.342 765.932 169.992 763.773C169.586 762.1 168.537 762.246 167.615 764.087C166.689 765.992 166.339 768.241 166.625 770.436C166.998 772.015 168.165 771.848 169.079 770.049Z" fill="white" fill-opacity="0.6"/>
        <path d="M156.05 792.609C156.423 794.126 157.548 793.938 158.436 792.201C159.348 790.413 159.666 788.259 159.324 786.187C158.927 784.608 157.903 784.786 157.015 786.564C156.104 788.364 155.761 790.516 156.05 792.609Z" fill="white" fill-opacity="0.6"/>
        <path d="M145.704 813.005C146.034 814.48 147.117 814.459 147.996 812.932C148.445 812.129 148.759 811.222 148.921 810.267C149.082 809.311 149.087 808.325 148.935 807.367C148.58 805.83 147.59 805.872 146.719 807.441C145.824 809.07 145.461 811.058 145.704 813.005Z" fill="white" fill-opacity="0.6"/>
        <path d="M135.543 830.639C135.848 832.041 136.905 832.198 137.768 830.932C138.217 830.245 138.537 829.442 138.703 828.583C138.87 827.723 138.88 826.829 138.733 825.964C138.394 824.51 137.438 824.406 136.575 825.703C136.133 826.39 135.813 827.185 135.635 828.036C135.457 828.886 135.426 829.773 135.543 830.639Z" fill="white" fill-opacity="0.6"/>
        <path d="M123.682 849.738C124.08 850.951 125.374 850.533 126.381 848.86C127.388 847.186 127.904 844.801 127.506 843.525C127.108 842.249 126 842.563 124.968 844.299C123.936 846.036 123.293 848.535 123.682 849.738Z" fill="white" fill-opacity="0.6"/>
        <path d="M110.104 869.851C110.527 870.981 111.88 870.531 113.039 868.858C114.198 867.184 114.731 864.831 114.359 863.628C113.987 862.425 112.718 862.875 111.533 864.611C110.349 866.347 109.681 868.753 110.104 869.851Z" fill="white" fill-opacity="0.6"/>
        <path d="M96.8214 882.779C95.5947 884.128 94.8756 886.21 95.3409 887.224C95.8062 888.239 97.2359 888.103 98.4796 886.722C99.7232 885.342 100.315 883.281 99.8163 882.235C99.3171 881.189 98.1243 881.377 96.8214 882.779Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.7406 897.684C80.079 898.824 81.5341 899.378 82.8454 898.729C84.1567 898.081 84.935 896.638 84.5374 895.435C84.1398 894.232 82.8454 893.887 81.5172 894.493C80.1889 895.1 79.4022 896.543 79.7406 897.684Z" fill="white" fill-opacity="0.6"/>
        <path d="M65.5108 898.363C65.7887 898.984 66.1766 899.519 66.6454 899.929C67.1142 900.338 67.6518 900.611 68.218 900.727C69.2671 900.874 69.7746 900.026 69.3347 898.855C69.06 898.231 68.6717 897.694 68.2004 897.288C67.729 896.881 67.1877 896.616 66.6191 896.512C65.5785 896.376 65.0709 897.244 65.5108 898.363Z" fill="white" fill-opacity="0.6"/>
        <path d="M54.9779 893.991C55.4206 895.226 56.1588 896.262 57.093 896.962C57.939 897.485 58.2858 896.962 57.939 895.853C57.4959 894.619 56.754 893.584 55.8155 892.893C54.9779 892.402 54.648 892.893 54.9779 893.991Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.6262 887.339C48.036 888.535 48.6083 889.635 49.3183 890.592C49.8513 891.199 49.9443 890.728 49.5213 889.546C49.1064 888.354 48.5347 887.255 47.8293 886.293C47.3132 885.697 47.2202 886.178 47.6262 887.339Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.8952 881.712C44.2928 882.622 44.3181 882.434 43.9544 881.273C43.5906 880.112 43.0068 878.48 42.6346 877.591C42.2623 876.702 42.2454 876.911 42.5754 878.031C42.9628 879.286 43.4033 880.514 43.8952 881.712Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.5367 871.295C40.7567 872.236 40.6551 872.006 40.3083 870.782C39.9614 869.558 39.5384 867.801 39.3354 866.839C39.1323 865.877 39.2254 866.128 39.5469 867.341C39.8684 868.554 40.3252 870.374 40.5367 871.295Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.787 855.135C38.0493 856.286 38.4046 858.273 38.54 859.319C38.6754 860.365 38.54 860.365 38.2777 859.193C37.9581 857.82 37.7122 856.423 37.5417 855.01C37.4148 853.901 37.5248 853.995 37.787 855.135Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.6111 842.134C36.5519 841.088 36.6788 841.203 36.8903 842.458C37.1363 843.899 37.3199 845.355 37.4402 846.82C37.5164 847.929 37.381 847.803 37.1356 846.506C36.8838 845.065 36.7086 843.604 36.6111 842.134Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.2475 829.656C36.4167 831.089 36.6197 833.055 36.679 834.07C36.7382 835.085 36.6113 834.76 36.4167 833.338C36.2221 831.915 36.0698 829.949 36.0275 828.903C35.9852 827.857 36.0782 828.265 36.2475 829.656Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.6806 821.299C35.7821 822.345 35.7398 821.99 35.6044 820.557C35.4691 819.124 35.3168 817.126 35.2238 816.08C35.1307 815.034 35.1392 815.442 35.2238 816.854C35.3084 818.266 35.5791 820.295 35.6806 821.299Z" fill="white" fill-opacity="0.6"/>
        <path d="M33.4133 804.941C33.4971 806.405 33.6696 807.86 33.9294 809.292C34.124 810.212 34.1832 809.773 34.0902 808.246C34.017 806.774 33.8644 805.31 33.6333 803.864C33.4556 802.975 33.3456 803.477 33.4133 804.941Z" fill="white" fill-opacity="0.6"/>
        <path d="M30.8667 798.435C31.1882 799.251 31.3827 798.707 31.3404 797.201C31.3197 795.753 31.1082 794.319 30.7144 792.954C30.4014 792.139 30.1306 792.756 30.1645 794.231C30.1996 795.672 30.4372 797.095 30.8667 798.435Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.8753 789.147C26.3998 789.911 26.7805 789.377 26.7974 787.871C26.8328 786.43 26.5396 785.006 25.9514 783.761C25.4607 783.008 24.987 783.635 24.9531 785.1C24.9337 786.531 25.2543 787.939 25.8753 789.147Z" fill="white" fill-opacity="0.6"/>
        <path d="M18.5574 782.244C19.3019 782.778 19.8687 782.025 19.9025 780.487C19.9363 778.949 19.4119 777.266 18.6758 776.732C17.9398 776.199 17.2799 777.088 17.2376 778.573C17.2124 779.269 17.3184 779.964 17.5475 780.601C17.7765 781.238 18.1223 781.801 18.5574 782.244Z" fill="white" fill-opacity="0.6"/>
        <path d="M6.44267 774.828C6.44091 775.533 6.62372 776.22 6.96379 776.785C7.30386 777.351 7.7829 777.765 8.32926 777.966C9.33601 778.186 10.1059 777.161 10.1143 775.623C10.156 774.919 10.0041 774.217 9.68322 773.633C9.36232 773.048 8.89125 772.614 8.3462 772.402C8.08629 772.379 7.82548 772.426 7.58203 772.541C7.33858 772.656 7.11839 772.836 6.93699 773.067C6.75558 773.298 6.61735 773.576 6.53195 773.88C6.44655 774.184 6.41607 774.508 6.44267 774.828Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.0552347 764.725C0.0552345 766.409 0.681286 767.465 1.74727 767.653C2.02527 767.687 2.30583 767.646 2.56989 767.533C2.83395 767.421 3.0753 767.239 3.27757 767.001C3.47984 766.763 3.63828 766.474 3.74209 766.153C3.84591 765.833 3.89265 765.488 3.87919 765.143C3.92289 764.414 3.73837 763.691 3.3637 763.125C2.98903 762.558 2.4528 762.191 1.86567 762.1C0.83353 762.058 0.0721552 763.125 0.0552347 764.725Z" fill="white" fill-opacity="0.6"/>
        <path d="M691.809 786.982C688.958 787.327 686.572 788.896 686.733 790.34C686.894 791.783 689.491 792.724 692.35 792.369C695.21 792.013 697.426 790.528 697.317 789.043C697.207 787.558 694.736 786.616 691.809 786.982Z" fill="white" fill-opacity="0.6"/>
        <path d="M651.725 794.879C652.013 796.302 654.771 797.191 657.647 796.804C660.524 796.417 662.723 794.91 662.495 793.446C662.267 791.982 659.661 791.103 656.666 791.49C653.671 791.877 651.412 793.457 651.725 794.879Z" fill="white" fill-opacity="0.6"/>
        <path d="M616.548 799.209C616.65 800.642 619.315 801.646 622.208 801.364C625.245 801.06 627.428 799.701 627.284 798.226C627.14 796.751 624.67 795.758 621.709 796.04C618.748 796.322 616.438 797.766 616.548 799.209Z" fill="white" fill-opacity="0.6"/>
        <path d="M586.346 800.558C583.52 800.956 581.473 802.525 581.989 803.895C582.505 805.265 585.305 806.112 588.139 805.704C590.974 805.297 592.902 803.811 592.42 802.389C591.938 800.966 589.265 800.14 586.346 800.558Z" fill="white" fill-opacity="0.6"/>
        <path d="M548.707 807.796C549.037 809.187 551.685 810.16 554.392 809.888C557.099 809.616 559.088 808.257 558.741 806.803C558.394 805.349 555.873 804.418 553.106 804.711C550.34 805.004 548.369 806.395 548.707 807.796Z" fill="white" fill-opacity="0.6"/>
        <path d="M521.127 808.466C518.649 808.8 517.024 810.286 517.667 811.603C518.31 812.921 520.899 813.831 523.395 813.497C525.89 813.162 527.439 811.739 526.779 810.359C526.119 808.978 523.716 808.121 521.127 808.466Z" fill="white" fill-opacity="0.6"/>
        <path d="M489.419 814.375C489.859 815.745 492.194 816.781 494.444 816.614C496.695 816.446 498.226 815.191 497.761 813.758C497.295 812.325 495.045 811.332 492.752 811.509C490.46 811.687 488.979 813.005 489.419 814.375Z" fill="white" fill-opacity="0.6"/>
        <path d="M465.003 816.708C465.672 818.088 467.761 819.051 469.673 818.852C471.585 818.653 472.617 817.346 471.966 815.955C471.315 814.563 469.259 813.612 467.313 813.863C465.367 814.114 464.352 815.369 465.003 816.708Z" fill="white" fill-opacity="0.6"/>
        <path d="M444.945 817.795C445.379 818.558 445.962 819.173 446.639 819.584C447.316 819.995 448.066 820.189 448.819 820.149C450.35 820.055 451.12 818.852 450.511 817.45C450.082 816.682 449.5 816.062 448.822 815.65C448.143 815.238 447.391 815.047 446.637 815.097C445.139 815.181 444.361 816.446 444.945 817.795Z" fill="white" fill-opacity="0.6"/>
        <path d="M429.065 816.185C429.445 816.956 429.948 817.624 430.542 818.149C431.137 818.674 431.811 819.045 432.525 819.239C433.87 819.511 434.446 818.59 433.904 817.147C433.551 816.403 433.074 815.761 432.505 815.261C431.937 814.762 431.289 814.417 430.605 814.25C429.276 813.957 428.54 814.856 429.065 816.185Z" fill="white" fill-opacity="0.6"/>
        <path d="M416.637 814.344C417.982 814.877 418.532 814.135 417.931 812.744C417.131 811.125 415.898 809.904 414.446 809.292C413.126 808.759 412.441 809.459 413.042 810.819C413.899 812.445 415.161 813.682 416.637 814.344Z" fill="white" fill-opacity="0.6"/>
        <path d="M397.035 801.876C397.885 803.601 399.087 805.019 400.521 805.987C401.866 806.834 402.458 806.384 401.891 804.993C401.078 803.298 399.908 801.907 398.507 800.966C397.187 800.13 396.494 800.538 397.035 801.876Z" fill="white" fill-opacity="0.6"/>
        <path d="M385.233 794.607C386.519 795.779 387.069 795.59 386.553 794.199C385.764 792.432 384.687 790.886 383.389 789.66C382.162 788.53 381.519 788.614 382.002 790.005C382.813 791.797 383.913 793.363 385.233 794.607Z" fill="white" fill-opacity="0.6"/>
        <path d="M371.782 780.78C372.873 782.14 373.279 782.045 372.738 780.613C371.974 778.796 371.006 777.123 369.861 775.644C368.821 774.337 368.364 774.379 368.872 775.77C369.641 777.605 370.621 779.292 371.782 780.78Z" fill="white" fill-opacity="0.6"/>
        <path d="M357.848 760.06C356.858 758.721 356.418 758.826 356.943 760.301C357.712 762.197 358.686 763.956 359.836 765.53C360.868 766.89 361.207 766.754 360.682 765.248C359.931 763.368 358.978 761.623 357.848 760.06Z" fill="white" fill-opacity="0.6"/>
        <path d="M344.862 744.068C345.591 746.03 346.546 747.851 347.696 749.475C348.77 750.96 349.168 750.919 348.669 749.391C347.966 747.45 347.046 745.641 345.936 744.015C344.913 742.572 344.405 742.593 344.862 744.068Z" fill="white" fill-opacity="0.6"/>
        <path d="M332.823 728.232C333.56 730.223 334.542 732.061 335.734 733.682C336.749 734.979 337.07 734.665 336.503 732.991C335.806 731.011 334.861 729.179 333.703 727.563C332.705 726.266 332.273 726.58 332.823 728.232Z" fill="white" fill-opacity="0.6"/>
        <path d="M323.416 718.129C324.456 719.384 324.82 719.018 324.262 717.302C323.594 715.301 322.642 713.463 321.453 711.874C320.429 710.608 319.947 710.985 320.472 712.679C321.185 714.696 322.182 716.541 323.416 718.129Z" fill="white" fill-opacity="0.6"/>
        <path d="M310.92 703.695C311.969 704.814 312.333 704.27 311.766 702.471C311.134 700.475 310.161 698.67 308.915 697.179C307.883 696.07 307.401 696.645 307.934 698.402C308.62 700.405 309.637 702.207 310.92 703.695Z" fill="white" fill-opacity="0.6"/>
        <path d="M295.049 685.726C295.685 687.708 296.712 689.462 298.035 690.83C299.135 691.803 299.541 691.133 299.025 689.313C298.429 687.342 297.44 685.59 296.149 684.22C295.074 683.226 294.542 683.948 295.049 685.726Z" fill="white" fill-opacity="0.6"/>
        <path d="M284.779 680.109C285.912 680.883 286.369 680.046 285.861 678.195C285.315 676.262 284.291 674.59 282.934 673.415C281.818 672.631 281.242 673.53 281.741 675.34C282.335 677.276 283.395 678.941 284.779 680.109Z" fill="white" fill-opacity="0.6"/>
        <path d="M267.935 667.715C268.463 669.61 269.559 671.182 270.997 672.108C272.207 672.704 272.689 671.721 272.258 669.859C271.778 667.965 270.718 666.377 269.305 665.435C268.172 664.859 267.486 665.926 267.935 667.715Z" fill="white" fill-opacity="0.6"/>
        <path d="M254.906 660.738C253.713 660.582 253.112 662.046 253.654 663.876C254.195 665.707 255.701 667.349 256.894 667.516C258.087 667.683 258.586 666.313 258.028 664.451C257.469 662.59 256.133 660.895 254.906 660.738Z" fill="white" fill-opacity="0.6"/>
        <path d="M251.886 645.51C253.087 645.803 253.95 644.61 253.764 642.999C253.665 642.255 253.4 641.559 252.998 640.992C252.595 640.424 252.072 640.007 251.488 639.788C251.211 639.725 250.926 639.748 250.658 639.856C250.39 639.963 250.148 640.152 249.954 640.404C249.759 640.656 249.619 640.965 249.546 641.301C249.473 641.638 249.469 641.991 249.534 642.33C249.64 643.082 249.918 643.781 250.335 644.344C250.751 644.907 251.29 645.312 251.886 645.51Z" fill="white" fill-opacity="0.6"/>
        <path d="M263.882 646.054C263.975 646.857 264.214 647.624 264.582 648.297C264.95 648.969 265.437 649.529 266.006 649.934C267.156 650.645 268.036 649.861 267.952 648.25C267.864 647.392 267.606 646.574 267.2 645.869C266.794 645.164 266.253 644.595 265.625 644.213C264.551 643.763 263.781 644.516 263.882 646.054Z" fill="white" fill-opacity="0.6"/>
        <path d="M280.015 658.144C281.107 658.887 281.801 658.019 281.53 656.325C281.246 654.656 280.452 653.187 279.313 652.225C278.18 651.43 277.444 652.225 277.723 654.055C278.032 655.733 278.853 657.198 280.015 658.144Z" fill="white" fill-opacity="0.6"/>
        <path d="M292.359 663.154C291.386 662.297 290.718 662.841 290.878 664.378C291.182 666.278 292.018 667.986 293.239 669.2C294.169 669.995 294.804 669.43 294.652 667.987C294.359 666.098 293.549 664.392 292.359 663.154Z" fill="white" fill-opacity="0.6"/>
        <path d="M305.903 682.41C306.927 683.55 307.595 683.048 307.291 681.364C306.957 679.576 306.231 677.937 305.193 676.626C304.135 675.423 303.45 675.904 303.729 677.672C304.082 679.471 304.835 681.113 305.903 682.41Z" fill="white" fill-opacity="0.6"/>
        <path d="M316.132 692.336C316.482 694.169 317.191 695.868 318.196 697.283C319.203 698.643 319.888 698.434 319.693 696.865C319.339 694.909 318.571 693.104 317.468 691.635C316.555 690.506 315.945 690.83 316.132 692.336Z" fill="white" fill-opacity="0.6"/>
        <path d="M328.322 708.485C328.69 710.32 329.397 712.024 330.387 713.464C331.377 714.865 332.02 714.719 331.791 713.15C331.407 711.183 330.643 709.36 329.566 707.837C328.678 706.707 328.103 706.99 328.322 708.485Z" fill="white" fill-opacity="0.6"/>
        <path d="M341.732 724.99C340.725 723.494 340.04 723.588 340.302 725.199C340.687 727.014 341.389 728.703 342.358 730.146C343.339 731.569 343.965 731.454 343.72 729.927C343.348 728.125 342.67 726.442 341.732 724.99Z" fill="white" fill-opacity="0.6"/>
        <path d="M352.163 741.924C352.562 743.701 353.269 745.35 354.236 746.756C355.209 748.147 355.818 748.042 355.572 746.557C355.156 744.656 354.392 742.899 353.339 741.422C352.467 740.292 351.926 740.512 352.163 741.924Z" fill="white" fill-opacity="0.6"/>
        <path d="M363.99 758.073C364.398 759.801 365.122 761.39 366.105 762.717C367.112 764.035 367.797 763.888 367.509 762.445C367.06 760.594 366.256 758.904 365.166 757.518C364.269 756.452 363.77 756.723 363.99 758.073Z" fill="white" fill-opacity="0.6"/>
        <path d="M378.838 772.203C377.695 771.157 376.942 771.251 377.069 772.496C377.458 774.328 378.338 775.943 379.565 777.077C380.758 778.123 381.553 777.841 381.257 776.408C380.798 774.746 379.957 773.285 378.838 772.203Z" fill="white" fill-opacity="0.6"/>
        <path d="M392.137 784.629C392.529 786.267 393.405 787.663 394.59 788.541C395.901 789.524 396.9 789.179 396.764 787.85C396.629 786.522 395.368 784.629 394.049 783.792C392.729 782.955 391.985 783.353 392.137 784.629Z" fill="white" fill-opacity="0.6"/>
        <path d="M410.182 792.181C408.93 791.699 408.075 792.296 408.245 793.603C408.453 794.451 408.827 795.222 409.332 795.85C409.838 796.477 410.461 796.941 411.146 797.201C412.322 797.63 413.168 796.94 413.025 795.705C412.825 794.872 412.461 794.113 411.964 793.498C411.468 792.883 410.855 792.43 410.182 792.181Z" fill="white" fill-opacity="0.6"/>
        <path d="M424.446 798.875C424.594 799.604 424.898 800.271 425.327 800.805C425.756 801.339 426.293 801.72 426.882 801.908C428.185 802.284 429.175 801.332 429.048 799.92C428.904 799.142 428.571 798.437 428.093 797.9C427.615 797.363 427.015 797.021 426.375 796.919C425.165 796.72 424.302 797.515 424.446 798.875Z" fill="white" fill-opacity="0.6"/>
        <path d="M440.232 800.203C440.404 800.937 440.766 801.581 441.263 802.036C441.76 802.492 442.363 802.733 442.982 802.724C444.454 802.776 445.638 801.531 445.444 800.109C445.249 798.686 443.752 797.515 442.28 797.619C440.807 797.724 440.046 798.843 440.232 800.203Z" fill="white" fill-opacity="0.6"/>
        <path d="M462.922 796.385C461.137 796.5 459.91 797.588 460.02 798.969C460.13 800.349 461.839 801.604 463.751 801.468C465.443 801.343 466.771 800.182 466.704 798.864C466.636 797.546 464.834 796.26 462.922 796.385Z" fill="white" fill-opacity="0.6"/>
        <path d="M484.588 796.866C484.715 798.205 486.466 799.262 488.471 799.22C490.696 799.22 492.583 797.839 492.498 796.396C492.414 794.952 490.282 793.885 488.023 794.105C485.925 794.314 484.453 795.496 484.588 796.866Z" fill="white" fill-opacity="0.6"/>
        <path d="M513.099 793.812C513.192 795.161 515.104 796.186 517.329 796.092C519.867 795.988 521.982 794.618 521.948 793.153C521.914 791.689 519.588 790.674 517.041 790.957C514.706 791.239 513.006 792.442 513.099 793.812Z" fill="white" fill-opacity="0.6"/>
        <path d="M544.722 790.078C544.773 791.438 546.812 792.432 549.257 792.296C551.973 792.139 554.333 790.727 554.333 789.252C554.333 787.777 551.88 786.794 549.113 787.16C546.558 787.432 544.672 788.687 544.722 790.078Z" fill="white" fill-opacity="0.6"/>
        <path d="M583.563 782.767C580.593 783.081 578.351 784.587 578.622 786.072C578.893 787.558 581.16 788.248 583.783 787.955C586.634 787.652 588.994 786.062 588.74 784.639C588.486 783.217 586.151 782.495 583.563 782.767Z" fill="white" fill-opacity="0.6"/>
        <path d="M619.095 783.384C622.014 783.081 624.526 781.481 624.374 780.037C624.239 778.751 621.836 777.862 619.23 778.134C616.201 778.437 613.773 779.964 613.951 781.46C614.129 782.955 616.421 783.667 619.095 783.384Z" fill="white" fill-opacity="0.6"/>
        <path d="M655.152 773.406C652.343 773.73 650.169 775.006 649.983 776.45C649.796 777.893 652.157 779.023 655.143 778.667C658.13 778.311 660.549 776.68 660.346 775.247C660.143 773.814 657.867 773.092 655.152 773.406Z" fill="white" fill-opacity="0.6"/>
        <path d="M690.574 774.055C693.433 773.762 695.963 772.161 695.912 770.697C695.861 769.233 693.62 768.469 691.039 768.72C688.078 769.024 685.608 770.561 685.684 772.078C685.76 773.594 687.951 774.327 690.574 774.055Z" fill="white" fill-opacity="0.6"/>
        <path d="M8.40532 759.087C9.39515 759.37 10.2665 758.313 10.2834 756.807C10.3013 756.161 10.1572 755.524 9.87102 754.983C9.58481 754.442 9.17044 754.023 8.68452 753.784C7.66085 753.481 6.74712 754.579 6.73866 756.127C6.72634 756.775 6.88124 757.412 7.18087 757.944C7.4805 758.476 7.90935 758.876 8.40532 759.087Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.652 759.861C17.6207 760.505 17.7062 761.149 17.9024 761.746C18.0986 762.343 18.4004 762.879 18.7857 763.313C19.4963 763.867 20.1393 763.03 20.1816 761.524C20.2239 760.018 19.6571 758.292 18.9295 757.905C18.2019 757.518 17.6859 758.407 17.652 759.861Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.2832 766.545C25.2309 767.872 25.4894 769.191 26.0277 770.341C26.5014 771.105 26.9329 770.457 26.9667 768.94C27.0477 767.531 26.7695 766.127 26.1715 764.924C25.7231 764.38 25.317 765.091 25.2832 766.545Z" fill="white" fill-opacity="0.6"/>
        <path d="M30.7145 779.849C31.0022 780.675 31.2391 780.037 31.2475 778.468C31.2687 777.11 31.1203 775.755 30.8076 774.452C30.5199 773.573 30.2408 774.232 30.2323 775.885C30.2138 777.23 30.3768 778.57 30.7145 779.849Z" fill="white" fill-opacity="0.6"/>
        <path d="M33.3878 785.842C33.244 784.744 33.0833 785.173 33.0748 786.742C33.0734 788.143 33.1611 789.541 33.3371 790.925C33.4894 791.971 33.5993 791.574 33.5993 790.078C33.6008 788.662 33.5302 787.247 33.3878 785.842Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.5046 798.372C34.4538 797.326 34.4116 797.567 34.42 798.895C34.4285 800.224 34.5216 802.441 34.5808 803.529C34.64 804.617 34.64 804.292 34.6231 803.048C34.6062 801.803 34.5469 799.481 34.5046 798.372Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.8599 812.294C34.8599 813.643 34.9783 815.578 34.9953 816.655C35.0122 817.733 34.9953 817.628 34.8937 816.216C34.7922 814.804 34.7922 812.597 34.7922 811.562C34.7922 810.526 34.8091 810.944 34.8599 812.294Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.1814 830.116C35.1814 831.267 35.1222 831.068 35.0291 829.677C34.936 828.286 34.8599 826.424 34.843 825.357C34.8261 824.291 34.843 824.312 34.9699 825.828C35.0968 827.345 35.156 829.06 35.1814 830.116Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.3421 838.955C35.4521 840.178 35.6213 842.092 35.6974 843.138C35.7736 844.184 35.6974 844.184 35.6128 842.919C35.5282 841.653 35.3252 839.519 35.2575 838.463C35.1898 837.407 35.2321 837.689 35.3421 838.955Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.2119 855.648C37.3811 856.578 37.3727 856.129 37.2119 854.727C37.0512 853.326 36.8059 851.704 36.6536 850.826C36.5013 849.947 36.4759 850.271 36.6536 851.767C36.7889 853.106 37.0343 854.8 37.2119 855.648Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.156 866.431C40.4859 867.31 40.6213 866.912 40.4436 865.594C40.2615 864.329 39.9781 863.088 39.5976 861.892C39.2592 860.971 39.1323 861.348 39.3184 862.729C39.4831 863.998 39.7641 865.24 40.156 866.431Z" fill="white" fill-opacity="0.6"/>
        <path d="M45.3165 875.51C45.8325 876.127 46.0948 875.447 45.8579 874.098C45.671 872.894 45.2468 871.766 44.6227 870.814C44.0897 870.155 43.8444 870.814 44.0897 872.215C44.2686 873.423 44.6905 874.556 45.3165 875.51Z" fill="white" fill-opacity="0.6"/>
        <path d="M51.4585 879.097C51.5512 879.702 51.7441 880.276 52.0252 880.783C52.3062 881.291 52.6692 881.721 53.0913 882.047C53.8527 882.476 54.3603 881.649 54.1319 880.321C54.0405 879.673 53.8265 879.06 53.5075 878.533C53.1886 878.006 52.7735 877.579 52.296 877.288C51.5939 877.005 51.247 877.821 51.4585 879.097Z" fill="white" fill-opacity="0.6"/>
        <path d="M62.0504 882.946C62.145 883.582 62.3862 884.17 62.745 884.639C63.1038 885.109 63.5648 885.44 64.0724 885.593C64.3075 885.653 64.551 885.642 64.7817 885.56C65.0124 885.477 65.2233 885.326 65.396 885.12C65.5687 884.914 65.6979 884.658 65.7725 884.376C65.847 884.094 65.8646 883.793 65.8237 883.501C65.7143 882.813 65.4308 882.185 65.0141 881.71C64.5974 881.234 64.0691 880.935 63.5056 880.855C63.2877 880.828 63.0677 880.866 62.8644 880.967C62.6611 881.067 62.4803 881.227 62.3375 881.432C62.1947 881.637 62.0941 881.882 62.0441 882.146C61.9942 882.409 61.9963 882.684 62.0504 882.946Z" fill="white" fill-opacity="0.6"/>
        <path d="M237.529 646.001C238.119 645.743 238.612 645.225 238.912 644.545C239.212 643.866 239.298 643.073 239.153 642.32C239.117 641.991 239.026 641.674 238.888 641.39C238.75 641.105 238.567 640.857 238.35 640.662C238.133 640.467 237.886 640.327 237.624 640.253C237.362 640.178 237.091 640.17 236.827 640.228C236.213 640.47 235.697 640.991 235.383 641.687C235.068 642.382 234.98 643.199 235.135 643.972C235.172 644.307 235.266 644.628 235.41 644.915C235.553 645.203 235.744 645.45 235.969 645.641C236.195 645.832 236.45 645.963 236.718 646.025C236.987 646.087 237.263 646.079 237.529 646.001Z" fill="white" fill-opacity="0.6"/>
        <path d="M223.181 647.497C222.635 647.973 222.197 648.614 221.91 649.36C221.622 650.105 221.495 650.93 221.54 651.754C221.65 653.553 222.707 654.316 223.815 653.459C224.336 652.973 224.753 652.339 225.032 651.611C225.31 650.882 225.442 650.08 225.414 649.275C225.33 647.518 224.289 646.66 223.181 647.497Z" fill="white" fill-opacity="0.6"/>
        <path d="M210.161 660.623C209.659 661.3 209.272 662.093 209.02 662.957C208.769 663.821 208.659 664.738 208.697 665.654C208.858 667.432 209.916 667.746 210.956 666.418C211.917 665.017 212.421 663.223 212.369 661.387C212.225 659.682 211.193 659.295 210.161 660.623Z" fill="white" fill-opacity="0.6"/>
        <path d="M196.532 683.958C196.76 685.422 197.674 685.433 198.579 683.958C199.589 682.247 200.077 680.151 199.958 678.038C199.696 676.438 198.723 676.584 197.725 678.404C196.837 680.039 196.417 681.994 196.532 683.958Z" fill="white" fill-opacity="0.6"/>
        <path d="M184.874 704.824C185.009 706.372 185.855 706.414 186.718 704.95C187.705 703.086 188.223 700.902 188.207 698.674C188.063 696.99 187.124 697.147 186.185 699.009C185.299 700.748 184.843 702.769 184.874 704.824Z" fill="white" fill-opacity="0.6"/>
        <path d="M175.686 727.249C176.583 725.304 177.133 722.26 176.888 720.89C176.642 719.52 175.855 719.99 175.026 721.779C174.104 723.777 173.52 726.611 173.783 728.054C174.045 729.498 174.866 729.027 175.686 727.249Z" fill="white" fill-opacity="0.6"/>
        <path d="M164.358 745.218C163.455 747.119 162.992 749.29 163.021 751.494C163.199 752.854 163.994 752.54 164.798 750.877C165.678 748.941 166.119 746.748 166.075 744.528C165.906 743.231 165.136 743.545 164.358 745.218Z" fill="white" fill-opacity="0.6"/>
        <path d="M154.324 773.992C155.185 772.126 155.629 770.011 155.61 767.863C155.466 766.566 154.764 766.817 153.969 768.406C153.086 770.237 152.624 772.331 152.632 774.462C152.776 775.801 153.546 775.55 154.324 773.992Z" fill="white" fill-opacity="0.6"/>
        <path d="M143.724 790.193C142.943 791.769 142.522 793.576 142.506 795.423C142.599 796.971 143.428 796.856 144.257 795.141C145.005 793.513 145.407 791.678 145.424 789.806C145.34 788.321 144.553 788.52 143.724 790.193Z" fill="white" fill-opacity="0.6"/>
        <path d="M133.885 808.717C133.011 810.174 132.547 811.944 132.565 813.758C132.692 815.045 133.411 815.139 134.164 813.946C135.014 812.439 135.456 810.637 135.425 808.8C135.306 807.566 134.613 807.545 133.885 808.717Z" fill="white" fill-opacity="0.6"/>
        <path d="M122.506 827.564C121.544 829.068 120.956 830.885 120.814 832.794C120.814 834.384 121.77 834.279 122.811 832.606C123.851 830.932 124.587 828.307 124.435 827.02C124.283 825.734 123.454 826.058 122.506 827.564Z" fill="white" fill-opacity="0.6"/>
        <path d="M109.495 853.263C110.645 851.579 111.457 848.943 111.254 847.761C111.051 846.579 110.129 847.008 109.055 848.556C107.862 850.282 107.007 852.74 107.219 853.995C107.43 855.25 108.429 854.811 109.495 853.263Z" fill="white" fill-opacity="0.6"/>
        <path d="M94.3596 867.048C93.7974 867.576 93.3261 868.238 92.9731 868.994C92.6201 869.75 92.3926 870.586 92.3038 871.452C92.3038 872.916 93.4713 873.094 94.7318 871.776C95.8404 870.638 96.5524 869.016 96.72 867.247C96.72 865.814 95.5863 865.741 94.3596 867.048Z" fill="white" fill-opacity="0.6"/>
        <path d="M78.6068 879.097C78.048 879.315 77.5575 879.742 77.203 880.319C76.8484 880.896 76.6473 881.595 76.6272 882.319C76.7457 883.699 77.9977 884.411 79.3006 883.71C79.8496 883.425 80.3221 882.953 80.6634 882.349C81.0047 881.746 81.201 881.036 81.2295 880.3C81.1364 878.961 79.9097 878.449 78.6068 879.097Z" fill="white" fill-opacity="0.6"/>
        <path d="M431.662 791.239C431.732 791.938 432.014 792.578 432.45 793.03C432.886 793.482 433.445 793.713 434.014 793.676C434.286 793.675 434.554 793.602 434.801 793.462C435.049 793.322 435.269 793.118 435.447 792.865C435.626 792.612 435.759 792.314 435.837 791.992C435.915 791.67 435.937 791.332 435.901 790.999C435.835 790.342 435.572 789.74 435.163 789.314C434.754 788.887 434.23 788.667 433.696 788.697C433.161 788.727 432.656 789.006 432.28 789.478C431.905 789.949 431.687 790.578 431.671 791.239L431.662 791.239Z" fill="white" fill-opacity="0.6"/>
        <path d="M415.681 787.945C415.771 788.698 416.043 789.401 416.461 789.961C416.879 790.521 417.424 790.912 418.025 791.082C419.294 791.469 420.224 790.643 420.199 789.252C420.135 788.506 419.885 787.802 419.484 787.24C419.082 786.678 418.548 786.284 417.957 786.114C416.773 785.832 415.69 786.637 415.681 787.945Z" fill="white" fill-opacity="0.6"/>
        <path d="M401.595 779.734C400.411 779.106 399.345 779.556 399.328 780.78C399.311 782.004 400.402 783.761 401.654 784.441C402.906 785.121 403.871 784.608 403.854 783.248C403.751 782.473 403.49 781.743 403.095 781.128C402.699 780.512 402.183 780.033 401.595 779.734Z" fill="white" fill-opacity="0.6"/>
        <path d="M383.575 770.53C383.728 771.366 384.019 772.154 384.431 772.844C384.844 773.534 385.367 774.11 385.969 774.536C387.179 775.383 387.941 774.902 387.814 773.49C387.66 772.681 387.369 771.922 386.961 771.262C386.552 770.603 386.036 770.057 385.445 769.662C384.286 768.846 383.457 769.17 383.575 770.53Z" fill="white" fill-opacity="0.6"/>
        <path d="M371.291 761.252C372.349 762.424 373.119 762.298 373.076 760.907C372.835 759.245 372.132 757.739 371.088 756.65C370.107 755.604 369.328 755.489 369.337 756.755C369.546 758.489 370.237 760.079 371.291 761.252Z" fill="white" fill-opacity="0.6"/>
        <path d="M357.231 741.035C357.45 742.795 358.076 744.44 359.033 745.773C359.955 747.028 360.572 746.871 360.496 745.427C360.24 743.746 359.597 742.192 358.643 740.951C357.78 739.821 357.171 739.727 357.231 741.035Z" fill="white" fill-opacity="0.6"/>
        <path d="M345.454 724.153C345.611 725.957 346.2 727.661 347.146 729.048C348.085 730.46 348.754 730.492 348.754 729.132C348.546 727.414 347.934 725.81 346.994 724.519C346.106 723.254 345.479 722.877 345.454 724.153Z" fill="white" fill-opacity="0.6"/>
        <path d="M333.61 707.45C333.834 709.276 334.48 710.983 335.471 712.366C336.41 713.621 336.994 713.412 336.901 711.811C336.616 710.057 335.929 708.442 334.921 707.157C334.041 705.975 333.525 705.985 333.61 707.45Z" fill="white" fill-opacity="0.6"/>
        <path d="M324.947 695.181C324.718 693.408 324.064 691.761 323.069 690.453C322.223 689.24 321.605 689.167 321.597 690.537C321.759 692.365 322.37 694.088 323.348 695.474C324.295 696.781 324.972 696.645 324.947 695.181Z" fill="white" fill-opacity="0.6"/>
        <path d="M309.355 675.047C309.553 676.842 310.229 678.505 311.275 679.774C312.248 680.873 312.858 680.486 312.764 678.854C312.511 677.094 311.794 675.487 310.726 674.283C309.812 673.258 309.27 673.53 309.355 675.047Z" fill="white" fill-opacity="0.6"/>
        <path d="M296.767 660.686C296.91 662.436 297.569 664.06 298.619 665.246C299.618 666.292 300.311 665.916 300.311 664.378C300.116 662.647 299.426 661.059 298.365 659.902C297.426 658.929 296.767 659.232 296.767 660.686Z" fill="white" fill-opacity="0.6"/>
        <path d="M285.692 652.946C286.716 653.804 287.46 653.281 287.435 651.743C287.355 650.918 287.14 650.121 286.802 649.402C286.465 648.682 286.012 648.056 285.472 647.56C284.516 646.765 283.78 647.288 283.78 648.742C283.841 649.569 284.043 650.372 284.373 651.097C284.702 651.821 285.152 652.452 285.692 652.946Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.303 639.401C270.32 640.189 270.5 640.959 270.826 641.636C271.152 642.314 271.613 642.877 272.165 643.271C273.247 643.983 274.06 643.407 274.094 641.922C274.072 641.133 273.891 640.362 273.567 639.681C273.244 639 272.788 638.429 272.241 638.021C272.049 637.85 271.822 637.752 271.586 637.737C271.35 637.722 271.116 637.792 270.911 637.938C270.707 638.084 270.541 638.299 270.433 638.559C270.325 638.818 270.28 639.111 270.303 639.401Z" fill="white" fill-opacity="0.6"/>
        <path d="M258.273 631.766C258.029 631.69 257.773 631.685 257.527 631.751C257.281 631.818 257.05 631.955 256.854 632.151C256.658 632.346 256.501 632.596 256.397 632.88C256.292 633.163 256.242 633.473 256.251 633.785C256.27 634.521 256.473 635.232 256.832 635.82C257.191 636.408 257.689 636.845 258.256 637.069C258.493 637.177 258.749 637.207 258.998 637.156C259.247 637.105 259.482 636.976 259.679 636.78C259.875 636.584 260.028 636.328 260.121 636.038C260.214 635.748 260.245 635.433 260.211 635.124C260.203 634.387 260.013 633.67 259.667 633.07C259.32 632.47 258.834 632.015 258.273 631.766Z" fill="white" fill-opacity="0.6"/>
        <path d="M448.599 790.769C448.65 792.139 450.004 793.216 451.569 793.111C453.134 793.007 454.344 791.825 454.242 790.402C454.106 789.635 453.741 788.959 453.221 788.508C452.701 788.057 452.063 787.863 451.433 787.965C449.944 788.101 448.557 789.409 448.599 790.769Z" fill="white" fill-opacity="0.6"/>
        <path d="M470.722 789.147C470.798 790.507 472.49 791.501 474.419 791.323C476.348 791.145 477.803 789.89 477.693 788.478C477.583 787.066 476.061 785.978 474.242 786.166C472.423 786.355 470.638 787.819 470.722 789.147Z" fill="white" fill-opacity="0.6"/>
        <path d="M497.329 786.407C497.278 787.788 499.106 788.792 501.365 788.635C503.623 788.478 505.425 787.244 505.417 785.811C505.409 784.378 503.852 783.28 501.678 783.437C499.503 783.593 497.38 785.037 497.329 786.407Z" fill="white" fill-opacity="0.6"/>
        <path d="M527.591 782.976C527.515 784.367 529.528 785.33 532.024 785.068C534.52 784.807 536.516 783.583 536.533 782.15C536.55 780.717 534.841 779.64 532.396 779.849C529.951 780.058 527.667 781.596 527.591 782.976Z" fill="white" fill-opacity="0.6"/>
        <path d="M560.67 779.253C560.923 780.613 563.208 781.345 565.949 780.905C568.69 780.466 570.441 779.096 570.179 777.663C569.917 776.23 567.717 775.383 565.103 775.843C562.489 776.303 560.416 777.914 560.67 779.253Z" fill="white" fill-opacity="0.6"/>
        <path d="M595.635 774.766C595.88 776.126 598.292 776.858 601.092 776.377C603.892 775.895 605.779 774.515 605.516 773.082C605.254 771.649 602.911 770.823 600.246 771.304C597.581 771.785 595.39 773.458 595.635 774.766Z" fill="white" fill-opacity="0.6"/>
        <path d="M631.65 769.871C631.65 771.251 634.018 772.088 636.81 771.712C639.602 771.335 641.793 769.913 641.726 768.48C641.658 767.047 639.289 766.106 636.649 766.472C634.01 766.838 631.641 768.49 631.65 769.871Z" fill="white" fill-opacity="0.6"/>
        <path d="M667.884 765.091C667.825 766.472 670.143 767.35 672.884 766.995C675.625 766.639 677.867 765.227 677.875 763.794C677.884 762.361 675.507 761.388 672.892 761.702C670.278 762.016 667.952 763.679 667.884 765.091Z" fill="white" fill-opacity="0.6"/>
        <path d="M703.475 760.426C703.391 761.817 705.607 762.717 708.255 762.382C710.903 762.047 713.095 760.646 713.128 759.244C713.162 757.843 710.827 756.828 708.298 757.152C705.768 757.477 703.569 759.046 703.475 760.426Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.0552566 746.264C0.0552564 747.833 0.706687 748.68 1.74728 748.733C2.01106 748.754 2.27559 748.708 2.52446 748.598C2.77334 748.488 3.00126 748.316 3.19404 748.092C3.38682 747.868 3.54036 747.598 3.64513 747.298C3.74991 746.998 3.80366 746.675 3.80309 746.348C3.81323 745.669 3.61887 745.009 3.25776 744.497C2.89664 743.984 2.39452 743.656 1.8488 743.576C0.791284 743.534 -0.0124243 744.643 0.0552566 746.264Z" fill="white" fill-opacity="0.6"/>
        <path d="M14.3188 751.201C15.1226 751.441 15.7655 750.469 15.7655 749.036C15.7804 748.404 15.6405 747.781 15.3642 747.249C15.088 746.718 14.6882 746.301 14.2173 746.055C13.4305 745.804 12.8383 746.693 12.8299 748.147C12.7854 748.785 12.9055 749.424 13.173 749.973C13.4405 750.522 13.8416 750.951 14.3188 751.201Z" fill="white" fill-opacity="0.6"/>
        <path d="M23.1259 751.713C22.6013 751.19 22.2122 751.713 22.1445 753.199C22.092 753.844 22.1459 754.495 22.3029 755.114C22.46 755.732 22.7169 756.305 23.0582 756.797C23.6166 757.341 24.0988 756.619 24.158 755.186C24.1817 754.551 24.1026 753.917 23.9254 753.321C23.7481 752.725 23.4763 752.178 23.1259 751.713Z" fill="white" fill-opacity="0.6"/>
        <path d="M28.3709 761.211C28.2895 762.557 28.4784 763.908 28.9209 765.143C29.2678 765.844 29.5808 765.227 29.64 763.784C29.6686 762.479 29.4361 761.185 28.9632 760.018C28.6248 759.328 28.4386 759.746 28.3709 761.211Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.4403 770.488C32.2626 769.651 32.1949 769.934 32.1272 771.366C32.0696 772.769 32.1577 774.175 32.3895 775.55C32.5841 776.418 32.7618 775.969 32.8125 774.504C32.8343 773.151 32.7091 771.8 32.4403 770.488Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.0224 783.489C33.9843 784.909 34.0182 786.331 34.1239 787.746C34.2085 788.614 34.2762 788.112 34.3101 786.595C34.3207 785.197 34.2614 783.8 34.1324 782.411C34.0562 781.554 34.0562 781.951 34.0224 783.489Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.7583 827.711C34.7583 828.851 34.7583 828.757 34.7583 827.428C34.7583 826.1 34.7583 823.977 34.7583 822.837C34.7583 821.697 34.6653 822.021 34.6907 823.349C34.716 824.678 34.7329 826.571 34.7583 827.711Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.2322 836.8C35.2914 838.233 35.4099 840.011 35.5114 840.91C35.6129 841.81 35.6214 841.35 35.5622 839.938C35.5029 838.526 35.5622 836.308 35.4268 835.503C35.2915 834.698 35.173 835.357 35.2322 836.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.9918 848.765C37.0567 850.086 37.2353 851.393 37.5248 852.667C37.7363 853.441 37.8378 852.939 37.7617 851.558C37.7322 850.122 37.6105 848.692 37.3979 847.28C37.2117 846.569 36.9157 847.385 36.9918 848.765Z" fill="white" fill-opacity="0.6"/>
        <path d="M41.3911 862.749C41.7803 863.398 42.0257 862.812 41.9411 861.473C41.9088 860.09 41.6827 858.724 41.2727 857.436C40.9004 856.809 40.469 857.739 40.5536 859.099C40.61 860.378 40.8961 861.625 41.3911 862.749Z" fill="white" fill-opacity="0.6"/>
        <path d="M46.5178 867.216C46.5439 868.484 46.9759 869.688 47.7191 870.563C48.3197 871.065 48.7681 870.364 48.692 869.036C48.6159 867.707 48.2098 865.814 47.643 865.322C47.0761 864.831 46.467 865.887 46.5178 867.216Z" fill="white" fill-opacity="0.6"/>
        <path d="M57.1353 875.614C57.9813 875.907 58.7004 875.029 58.6666 873.679C58.6327 872.33 57.9644 870.74 57.1438 870.437C56.3231 870.134 55.511 871.274 55.5363 872.613C55.522 873.255 55.6678 873.888 55.9538 874.425C56.2397 874.961 56.6522 875.377 57.1353 875.614Z" fill="white" fill-opacity="0.6"/>
        <path d="M241.624 632.477C241.646 632.81 241.722 633.135 241.847 633.431C241.972 633.727 242.143 633.99 242.351 634.203C242.559 634.416 242.799 634.576 243.058 634.673C243.317 634.77 243.588 634.802 243.857 634.768C244.128 634.756 244.393 634.673 244.636 634.525C244.878 634.377 245.093 634.167 245.266 633.909C245.438 633.651 245.565 633.351 245.637 633.029C245.709 632.706 245.725 632.369 245.685 632.038C245.672 631.701 245.605 631.371 245.488 631.067C245.371 630.762 245.206 630.49 245.003 630.265C244.799 630.04 244.562 629.868 244.304 629.757C244.047 629.647 243.774 629.601 243.502 629.622C242.955 629.716 242.457 630.062 242.108 630.592C241.76 631.122 241.586 631.796 241.624 632.477Z" fill="white" fill-opacity="0.6"/>
        <path d="M227.614 636.985C227.59 637.288 227.634 637.592 227.741 637.866C227.847 638.14 228.012 638.372 228.218 638.538C228.423 638.704 228.662 638.797 228.908 638.807C229.153 638.818 229.396 638.745 229.611 638.596C230.161 638.266 230.624 637.747 230.945 637.103C231.266 636.458 231.431 635.713 231.421 634.956C231.421 633.502 230.414 633.063 229.416 633.617C228.889 633.911 228.44 634.384 228.121 634.98C227.803 635.576 227.627 636.271 227.614 636.985Z" fill="white" fill-opacity="0.6"/>
        <path d="M214.399 647.947C214.399 649.442 215.296 649.84 216.286 648.846C216.801 648.277 217.217 647.583 217.508 646.808C217.799 646.033 217.959 645.192 217.978 644.338C217.978 642.843 216.963 643.041 216.024 644.004C215.539 644.477 215.143 645.075 214.863 645.756C214.583 646.436 214.425 647.184 214.399 647.947Z" fill="white" fill-opacity="0.6"/>
        <path d="M201.811 664.368C201.904 665.832 202.733 665.769 203.706 664.253C204.653 662.758 205.178 660.921 205.203 659.023C205.093 657.653 204.044 658.552 203.156 659.923C202.272 661.143 201.791 662.73 201.811 664.368Z" fill="white" fill-opacity="0.6"/>
        <path d="M189.933 684.042C189.933 685.6 190.694 685.517 191.625 683.874C192.536 682.194 193.073 680.246 193.181 678.226C193.181 676.762 192.183 677.714 191.329 679.272C190.494 680.658 190.008 682.317 189.933 684.042Z" fill="white" fill-opacity="0.6"/>
        <path d="M180.009 701.205C179.198 702.724 178.715 704.474 178.605 706.289C178.605 707.952 179.231 707.879 180.111 706.142C180.997 704.348 181.539 702.326 181.693 700.233C181.718 698.685 180.855 699.542 180.009 701.205Z" fill="white" fill-opacity="0.6"/>
        <path d="M169.113 724.728C168.338 726.319 167.87 728.108 167.742 729.958C167.742 731.579 168.334 731.454 169.18 729.644C170.026 727.806 170.548 725.767 170.712 723.662C170.737 722.208 169.916 723.024 169.113 724.728Z" fill="white" fill-opacity="0.6"/>
        <path d="M158.622 748.419C157.871 750.021 157.409 751.805 157.268 753.648C157.218 755.238 157.827 755.113 158.647 753.345C159.46 751.534 159.967 749.538 160.136 747.477C160.17 746.003 159.4 746.745 158.622 748.419Z" fill="white" fill-opacity="0.6"/>
        <path d="M147.108 776.46C147.15 777.778 147.819 777.402 148.614 775.571C149.37 773.876 149.806 771.992 149.891 770.059C149.832 768.846 149.003 769.777 148.276 771.44C147.564 772.972 147.164 774.695 147.108 776.46Z" fill="white" fill-opacity="0.6"/>
        <path d="M137.167 796.207C137.167 797.63 137.751 797.609 138.529 796.134C139.305 794.578 139.777 792.82 139.908 790.999C139.908 789.67 139.181 790.089 138.462 791.448C137.728 792.884 137.285 794.516 137.167 796.207Z" fill="white" fill-opacity="0.6"/>
        <path d="M128.327 809.02C127.43 810.479 126.848 812.196 126.635 814.009C126.558 815.494 127.252 815.442 128.166 813.957C129.077 812.465 129.66 810.706 129.858 808.853C129.909 807.493 129.173 807.681 128.327 809.02Z" fill="white" fill-opacity="0.6"/>
        <path d="M115.814 834.3C116.788 832.728 117.399 830.86 117.582 828.893C117.582 827.679 116.643 828.14 115.679 829.719C114.695 831.248 114.064 833.076 113.851 835.011C113.894 836.361 114.757 836.036 115.814 834.3Z" fill="white" fill-opacity="0.6"/>
        <path d="M99.5203 855.041C99.5626 856.327 100.535 856.087 101.703 854.57C102.87 853.054 103.7 850.732 103.64 849.529C103.581 848.326 102.6 848.483 101.517 849.937C100.434 851.391 99.478 853.734 99.5203 855.041Z" fill="white" fill-opacity="0.6"/>
        <path d="M86.3816 870.385C86.9548 869.919 87.4378 869.302 87.7961 868.579C88.1544 867.856 88.3791 867.044 88.4543 866.201C88.3951 864.956 87.363 864.538 86.2209 865.469C85.6349 865.949 85.1406 866.582 84.7724 867.322C84.4042 868.063 84.1709 868.894 84.0889 869.757C84.1312 871.086 85.1465 871.399 86.3816 870.385Z" fill="white" fill-opacity="0.6"/>
        <path d="M68.201 875.112C68.2304 875.456 68.318 875.789 68.4581 876.089C68.5982 876.388 68.7877 876.648 69.0142 876.851C69.2408 877.054 69.4993 877.196 69.7732 877.268C70.047 877.34 70.3299 877.339 70.6036 877.267C71.1739 877.18 71.6946 876.823 72.0561 876.271C72.4175 875.719 72.5914 875.015 72.541 874.307C72.4402 873.629 72.1263 873.029 71.668 872.637C71.2098 872.245 70.6445 872.093 70.096 872.215C69.5359 872.294 69.0233 872.642 68.6688 873.184C68.3143 873.726 68.1463 874.419 68.201 875.112Z" fill="white" fill-opacity="0.6"/>
        <path d="M424.048 781.167C424.049 781.864 424.263 782.535 424.647 783.046C425.032 783.556 425.558 783.868 426.121 783.918C426.633 783.957 427.139 783.767 427.548 783.383C427.956 782.999 428.239 782.446 428.346 781.826C428.401 781.499 428.399 781.161 428.338 780.835C428.277 780.51 428.16 780.205 427.994 779.941C427.828 779.677 427.618 779.461 427.377 779.308C427.137 779.155 426.873 779.068 426.603 779.054C426.04 778.943 425.464 779.098 424.99 779.49C424.516 779.882 424.18 780.481 424.048 781.167Z" fill="white" fill-opacity="0.6"/>
        <path d="M409.581 779.535C409.818 779.684 410.079 779.763 410.344 779.764C410.609 779.765 410.87 779.69 411.107 779.543C411.345 779.397 411.55 779.183 411.709 778.92C411.867 778.657 411.973 778.352 412.018 778.029C412.031 777.378 411.873 776.739 411.568 776.207C411.263 775.676 410.827 775.28 410.326 775.08C409.108 774.504 407.839 775.017 407.636 776.23C407.433 777.443 408.397 779.023 409.581 779.535Z" fill="white" fill-opacity="0.6"/>
        <path d="M391.206 767.842C391.138 769.013 392.221 770.802 393.38 771.45C394.539 772.099 395.377 771.659 395.512 770.593C395.519 769.839 395.344 769.1 395.01 768.47C394.675 767.841 394.196 767.349 393.634 767.057C392.424 766.304 391.274 766.649 391.206 767.842Z" fill="white" fill-opacity="0.6"/>
        <path d="M375.944 756.117C376.067 756.913 376.317 757.672 376.681 758.347C377.044 759.022 377.513 759.601 378.059 760.05C379.168 760.886 379.946 760.457 379.861 759.108C379.762 758.39 379.541 757.705 379.215 757.098C378.889 756.491 378.463 755.975 377.966 755.583C376.875 754.632 375.919 754.904 375.944 756.117Z" fill="white" fill-opacity="0.6"/>
        <path d="M364.269 745.386C365.115 746.557 365.961 746.567 365.961 745.386C365.849 743.943 365.355 742.587 364.557 741.526C363.643 740.261 362.772 740.041 362.679 741.087C362.81 742.698 363.369 744.209 364.269 745.386Z" fill="white" fill-opacity="0.6"/>
        <path d="M352.358 725.095C351.461 723.881 350.666 723.85 350.759 725.032C351.03 726.68 351.659 728.205 352.578 729.446C353.356 730.439 353.965 730.418 353.965 729.446C353.819 727.82 353.256 726.295 352.358 725.095Z" fill="white" fill-opacity="0.6"/>
        <path d="M340.344 707.952C339.498 706.697 338.728 706.665 338.813 707.879C339.109 709.56 339.75 711.12 340.674 712.407C341.444 713.453 342.028 713.453 342.002 712.407C341.816 710.753 341.239 709.203 340.344 707.952Z" fill="white" fill-opacity="0.6"/>
        <path d="M326.859 690.683C327.164 692.381 327.82 693.953 328.762 695.244C329.608 696.384 330.184 696.164 330.023 694.752C329.765 693.199 329.181 691.756 328.331 690.568C327.468 689.355 326.749 689.397 326.859 690.683Z" fill="white" fill-opacity="0.6"/>
        <path d="M314.778 674.011C315.053 675.711 315.71 677.28 316.673 678.54C317.578 679.586 318.145 679.356 317.993 677.913C317.762 676.348 317.175 674.896 316.301 673.729C315.429 672.589 314.702 672.704 314.778 674.011Z" fill="white" fill-opacity="0.6"/>
        <path d="M302.494 658.688C302.742 660.366 303.418 661.902 304.423 663.071C305.353 664.023 305.92 663.604 305.768 662.098C305.563 660.568 304.971 659.158 304.076 658.071C303.162 657.025 302.401 657.36 302.494 658.688Z" fill="white" fill-opacity="0.6"/>
        <path d="M291.521 644.244C290.574 643.386 289.829 643.909 289.897 645.342C290.109 646.971 290.818 648.438 291.877 649.442C292.723 650.08 293.306 649.62 293.281 648.396C293.231 647.593 293.049 646.81 292.746 646.097C292.444 645.383 292.027 644.752 291.521 644.244Z" fill="white" fill-opacity="0.6"/>
        <path d="M278.552 638.052C279.499 638.805 280.371 638.261 280.396 636.891C280.38 636.187 280.24 635.496 279.985 634.866C279.731 634.235 279.369 633.681 278.924 633.241C277.951 632.373 276.995 632.781 276.877 634.109C276.891 634.881 277.05 635.639 277.34 636.323C277.631 637.007 278.046 637.599 278.552 638.052Z" fill="white" fill-opacity="0.6"/>
        <path d="M263.324 626.913C263.355 627.635 263.557 628.33 263.905 628.912C264.252 629.493 264.729 629.937 265.278 630.187C266.319 630.511 267.055 629.591 266.97 628.095C266.957 627.441 266.79 626.806 266.489 626.267C266.189 625.728 265.768 625.309 265.278 625.062C265.048 624.965 264.801 624.94 264.561 624.988C264.321 625.037 264.094 625.159 263.901 625.342C263.708 625.525 263.554 625.764 263.453 626.038C263.352 626.312 263.308 626.613 263.324 626.913Z" fill="white" fill-opacity="0.6"/>
        <path d="M249.17 623.21C249.148 623.876 249.328 624.527 249.673 625.038C250.019 625.548 250.506 625.881 251.04 625.972C251.513 626 251.978 625.807 252.342 625.431C252.706 625.055 252.942 624.524 253.003 623.943C253.058 623.62 253.06 623.287 253.01 622.964C252.959 622.64 252.857 622.332 252.709 622.058C252.561 621.784 252.37 621.55 252.147 621.369C251.924 621.188 251.674 621.063 251.412 621.003C250.879 620.933 250.344 621.125 249.925 621.538C249.505 621.951 249.234 622.552 249.17 623.21Z" fill="white" fill-opacity="0.6"/>
        <path d="M440.004 781.889C439.991 782.231 440.04 782.573 440.146 782.89C440.252 783.206 440.414 783.49 440.619 783.721C440.824 783.952 441.068 784.123 441.333 784.224C441.598 784.325 441.878 784.353 442.153 784.305C442.734 784.257 443.29 783.995 443.75 783.554C444.209 783.112 444.552 782.51 444.733 781.826C445.029 780.362 444.183 779.253 442.829 779.274C442.192 779.27 441.572 779.524 441.062 779.995C440.552 780.467 440.18 781.132 440.004 781.889Z" fill="white" fill-opacity="0.6"/>
        <path d="M459.716 780.78C459.53 782.119 460.926 783.238 462.575 783.05C464.09 782.872 465.545 781.69 465.824 780.414C466.154 778.929 465.046 777.883 463.286 777.998C461.526 778.113 459.91 779.399 459.716 780.78Z" fill="white" fill-opacity="0.6"/>
        <path d="M484.301 778.374C483.971 779.734 485.51 780.895 487.448 780.749C489.199 780.602 491.026 779.389 491.441 778.102C491.923 776.586 490.705 775.487 488.666 775.582C486.627 775.676 484.639 776.952 484.301 778.374Z" fill="white" fill-opacity="0.6"/>
        <path d="M512.803 775.236C512.456 776.617 514.215 777.747 516.398 777.548C518.369 777.37 520.417 776.105 520.848 774.797C521.364 773.27 520.002 772.182 517.684 772.318C515.366 772.454 513.166 773.793 512.803 775.236Z" fill="white" fill-opacity="0.6"/>
        <path d="M544.367 771.618C544.206 772.998 546.372 773.981 548.766 773.615C550.966 773.291 552.996 771.952 553.25 770.634C553.58 769.097 551.871 768.135 549.325 768.427C546.778 768.72 544.528 770.206 544.367 771.618Z" fill="white" fill-opacity="0.6"/>
        <path d="M578.36 767.11C578.063 768.501 580.187 769.578 582.691 769.296C584.958 769.045 587.2 767.706 587.598 766.388C588.072 764.829 586.397 763.794 583.766 764.003C581.134 764.212 578.656 765.645 578.36 767.11Z" fill="white" fill-opacity="0.6"/>
        <path d="M613.697 762.643C613.613 764.024 616.041 764.976 618.63 764.568C621.007 764.202 623.088 762.832 623.308 761.503C623.562 759.955 621.616 759.025 618.9 759.359C616.185 759.694 613.782 761.221 613.697 762.643Z" fill="white" fill-opacity="0.6"/>
        <path d="M659.39 756.577C659.449 755.081 657.283 754.203 654.568 754.621C651.852 755.04 649.644 756.619 649.754 758.02C649.856 759.234 652.013 760.112 654.331 759.861C656.903 759.6 659.331 758.01 659.39 756.577Z" fill="white" fill-opacity="0.6"/>
        <path d="M695.007 751.912C695.007 750.427 692.875 749.538 690.21 749.935C687.545 750.333 685.422 751.912 685.574 753.303C685.709 754.527 687.858 755.395 690.134 755.165C692.655 754.914 694.99 753.345 695.007 751.912Z" fill="white" fill-opacity="0.6"/>
        <path d="M8.44751 740.438C9.29352 740.689 10.0888 739.727 10.0634 738.399C10.0683 737.81 9.93184 737.232 9.67135 736.739C9.41085 736.245 9.03798 735.859 8.59978 735.627C8.3762 735.56 8.14293 735.558 7.9187 735.622C7.69446 735.686 7.48549 735.814 7.30861 735.996C7.13173 736.178 6.99187 736.409 6.90021 736.67C6.80856 736.931 6.76769 737.215 6.78088 737.499C6.77476 738.143 6.93228 738.773 7.23131 739.301C7.53034 739.828 7.95575 740.226 8.44751 740.438Z" fill="white" fill-opacity="0.6"/>
        <path d="M18.8619 739.989C18.2105 739.518 17.6352 740.261 17.6691 741.537C17.6889 742.15 17.8191 742.749 18.0504 743.292C18.2817 743.835 18.608 744.307 19.0057 744.674C19.581 745.02 20.021 744.402 20.0125 743.262C20.0334 742.645 19.9406 742.029 19.7414 741.462C19.5421 740.895 19.2414 740.391 18.8619 739.989Z" fill="white" fill-opacity="0.6"/>
        <path d="M26.2137 751.86C26.6367 752.425 26.9074 751.797 26.8398 750.427C26.8134 749.276 26.542 748.154 26.053 747.174C25.613 746.526 25.207 747.101 25.2831 748.377C25.3352 749.62 25.6566 750.823 26.2137 751.86Z" fill="white" fill-opacity="0.6"/>
        <path d="M30.799 761.19C31.0444 761.984 31.2221 761.545 31.1882 760.227C31.1682 759.005 31.0174 757.792 30.7398 756.619C30.4775 755.719 30.2322 756.065 30.2491 757.32C30.2887 758.635 30.4737 759.937 30.799 761.19Z" fill="white" fill-opacity="0.6"/>
        <path d="M33.0833 768.229C33.1085 769.629 33.2018 771.026 33.3625 772.412C33.481 773.228 33.5571 772.988 33.5656 771.869C33.5717 770.469 33.4982 769.071 33.3456 767.685C33.2102 766.649 33.0748 766.942 33.0833 768.229Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.4031 780.592C34.4031 781.941 34.4455 784.075 34.4878 784.953C34.5301 785.832 34.5639 785.434 34.5808 784.2C34.5808 782.746 34.5808 780.79 34.5808 779.776C34.5808 778.761 34.4201 779.201 34.4031 780.592Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.8092 793.676C34.8092 795.14 34.7584 797.337 34.7584 798.195C34.7584 799.052 34.7584 798.592 34.8346 797.264C34.8938 795.705 34.9361 793.666 34.9276 792.662C34.9192 791.658 34.8515 792.181 34.8092 793.676Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.8937 806.865C34.826 808.372 34.7753 810.662 34.7922 811.562C34.8091 812.461 34.8514 811.98 34.9275 810.61C35.0121 808.999 35.0883 806.886 35.0714 805.851C35.0544 804.815 34.9699 805.328 34.8937 806.865Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.2406 820.002C35.1465 821.565 35.124 823.133 35.1729 824.699C35.2322 825.452 35.3506 824.897 35.4606 823.433C35.5828 821.837 35.6195 820.234 35.5706 818.632C35.5198 817.701 35.3506 818.36 35.2406 820.002Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.518 836.915C36.6703 837.731 36.8987 836.915 37.034 835.095C37.1745 833.643 37.1745 832.175 37.034 830.723C36.8818 829.813 36.628 830.524 36.4926 832.208C36.3544 833.773 36.3629 835.353 36.518 836.915Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.1745 843.076C39.0023 844.633 39.0741 846.217 39.386 847.74C39.6229 848.452 39.9782 847.824 40.1474 846.349C40.3588 844.757 40.3128 843.129 40.0121 841.559C39.7498 840.649 39.3437 841.371 39.1745 843.076Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.8784 852.541C43.7261 854.246 44.0222 856.338 44.4791 856.84C44.9359 857.342 45.3843 856.432 45.5535 854.874C45.7565 853.033 45.5535 851.066 45.0628 850.407C44.5721 849.749 44.0391 850.805 43.8784 852.541Z" fill="white" fill-opacity="0.6"/>
        <path d="M52.7784 857.018C52.0847 856.683 51.374 857.97 51.2555 859.737C51.1371 861.505 51.687 863.283 52.3638 863.471C53.0406 863.659 53.6244 862.551 53.7682 860.992C53.9374 859.172 53.489 857.363 52.7784 857.018Z" fill="white" fill-opacity="0.6"/>
        <path d="M63.3196 867.101C64.2418 867.341 65.2316 866.055 65.367 864.35C65.5024 862.645 64.9355 861.296 64.0811 860.961C63.2266 860.626 62.1522 861.787 61.9237 863.503C61.6953 865.218 62.389 866.87 63.3196 867.101Z" fill="white" fill-opacity="0.6"/>
        <path d="M236.243 627.133C236.783 626.945 237.269 626.573 237.645 626.058C238.021 625.544 238.271 624.908 238.367 624.225C238.4 623.973 238.38 623.715 238.308 623.476C238.236 623.237 238.116 623.025 237.958 622.861C237.801 622.696 237.611 622.585 237.408 622.538C237.206 622.491 236.996 622.51 236.801 622.593C236.244 622.775 235.736 623.137 235.33 623.643C234.924 624.148 234.634 624.779 234.492 625.47C234.331 626.641 235.177 627.415 236.243 627.133Z" fill="white" fill-opacity="0.6"/>
        <path d="M222.411 635.082C222.885 634.706 223.287 634.206 223.587 633.617C223.886 633.027 224.077 632.363 224.145 631.672C224.103 630.731 223.401 630.563 222.453 631.327C221.955 631.718 221.525 632.228 221.189 632.828C220.854 633.429 220.619 634.106 220.499 634.82C220.491 635.793 221.446 635.898 222.411 635.082Z" fill="white" fill-opacity="0.6"/>
        <path d="M209.29 648.616C210.229 647.413 210.922 645.698 210.838 644.809C210.753 643.92 210.076 644.171 209.196 645.259C208.317 646.346 207.395 648.313 207.428 649.223C207.462 650.133 208.384 649.777 209.29 648.616Z" fill="white" fill-opacity="0.6"/>
        <path d="M196.693 663.395C195.796 664.87 195.06 666.909 195.144 667.725C195.229 668.541 196.151 667.83 197.006 666.355C197.722 665.217 198.188 663.87 198.359 662.443C198.334 661.481 197.615 661.868 196.693 663.395Z" fill="white" fill-opacity="0.6"/>
        <path d="M183.571 688.832C183.571 689.784 184.366 689.188 185.178 687.64C185.887 686.323 186.373 684.843 186.608 683.289C186.659 682.149 186.05 682.462 185.187 684.042C184.366 685.47 183.814 687.107 183.571 688.832Z" fill="white" fill-opacity="0.6"/>
        <path d="M172.522 711.926C172.522 712.826 173.368 712.01 174.155 710.305C174.814 708.917 175.246 707.381 175.424 705.787C175.424 704.741 174.806 705.18 173.986 706.937C173.223 708.457 172.725 710.156 172.522 711.926Z" fill="white" fill-opacity="0.6"/>
        <path d="M163.351 730.397C162.627 731.941 162.172 733.655 162.015 735.428C162.074 736.328 162.861 735.428 163.605 733.682C164.23 732.258 164.629 730.699 164.781 729.09C164.764 728.034 164.113 728.609 163.351 730.397Z" fill="white" fill-opacity="0.6"/>
        <path d="M153.199 757.152C153.916 755.592 154.376 753.871 154.553 752.09C154.553 751.044 154.062 751.337 153.394 752.718C152.632 754.308 152.118 756.06 151.88 757.884C151.82 759.077 152.472 758.711 153.199 757.152Z" fill="white" fill-opacity="0.6"/>
        <path d="M142.023 778.876C142.023 780.048 142.658 779.723 143.36 778.249C144.016 776.844 144.447 775.295 144.629 773.688C144.705 772.329 144.223 772.412 143.479 773.887C142.724 775.41 142.229 777.108 142.023 778.876Z" fill="white" fill-opacity="0.6"/>
        <path d="M133.656 796.626C134.396 795.25 134.847 793.668 134.968 792.024C134.968 790.884 134.545 790.842 133.876 791.95C133.098 793.34 132.588 794.933 132.387 796.605C132.311 797.86 132.946 797.87 133.656 796.626Z" fill="white" fill-opacity="0.6"/>
        <path d="M122.726 810.306C121.782 811.907 121.145 813.753 120.865 815.704C120.814 816.98 121.711 816.613 122.557 815.034C123.372 813.576 123.923 811.917 124.173 810.171C124.274 808.717 123.657 808.738 122.726 810.306Z" fill="white" fill-opacity="0.6"/>
        <path d="M107.43 835.869C107.363 837.271 108.276 837.072 109.334 835.503C110.36 833.963 111.014 832.096 111.22 830.116C111.22 828.882 110.552 828.955 109.528 830.347C108.448 831.927 107.725 833.832 107.43 835.869Z" fill="white" fill-opacity="0.6"/>
        <path d="M92.5069 852.844C92.3546 854.455 93.3106 854.936 94.4104 853.754C95.5092 852.476 96.27 850.817 96.5931 848.995C96.83 847.165 96.1532 846.579 94.9857 847.678C93.7168 849.01 92.8443 850.829 92.5069 852.844Z" fill="white" fill-opacity="0.6"/>
        <path d="M78.4969 865.5C79.1543 865.126 79.7242 864.551 80.1532 863.831C80.5823 863.11 80.8566 862.267 80.9504 861.379C81.0857 859.789 80.4089 858.838 79.3429 859.151C78.6661 859.46 78.0643 859.978 77.5949 860.655C77.1255 861.333 76.8041 862.147 76.6611 863.021C76.4327 864.726 77.3463 865.94 78.4969 865.5Z" fill="white" fill-opacity="0.6"/>
        <path d="M436.307 770.802C435.751 770.848 435.217 771.089 434.767 771.496C434.318 771.904 433.971 772.461 433.769 773.103C433.7 773.376 433.688 773.666 433.734 773.947C433.78 774.227 433.882 774.489 434.031 774.708C434.18 774.927 434.372 775.095 434.587 775.199C434.803 775.302 435.037 775.337 435.266 775.299C435.872 775.267 436.456 775.006 436.938 774.552C437.42 774.097 437.779 773.471 437.965 772.758C438.134 771.555 437.432 770.708 436.307 770.802Z" fill="white" fill-opacity="0.6"/>
        <path d="M420.063 769.212C419.541 769.044 418.987 769.101 418.494 769.373C418.001 769.645 417.597 770.115 417.348 770.708C416.984 771.973 417.847 773.291 419.184 773.521C419.707 773.642 420.249 773.531 420.713 773.208C421.177 772.885 421.534 772.37 421.722 771.754C421.756 771.45 421.739 771.142 421.673 770.847C421.607 770.552 421.493 770.277 421.338 770.039C421.182 769.801 420.989 769.604 420.77 769.462C420.551 769.32 420.31 769.235 420.063 769.212Z" fill="white" fill-opacity="0.6"/>
        <path d="M402.246 767.549C403.389 768.124 404.556 767.716 404.894 766.681C405.233 765.645 404.556 764.265 403.439 763.648C402.128 762.915 400.825 763.282 400.563 764.505C400.301 765.729 401.087 766.963 402.246 767.549Z" fill="white" fill-opacity="0.6"/>
        <path d="M384.43 754.443C384.15 755.427 384.802 756.964 385.876 757.853C386.951 758.742 388.304 758.669 388.524 757.497C388.744 756.326 388.008 754.956 386.925 754.171C385.842 753.387 384.717 753.45 384.43 754.443Z" fill="white" fill-opacity="0.6"/>
        <path d="M371.342 745.302C372.289 746.264 373.228 746.212 373.423 745.229C373.618 744.246 372.983 742.593 372.036 741.578C370.953 740.417 369.904 740.407 369.785 741.578C369.802 742.304 369.95 743.015 370.22 743.66C370.489 744.304 370.873 744.866 371.342 745.302Z" fill="white" fill-opacity="0.6"/>
        <path d="M358.694 729.948C359.54 731.046 360.318 731.13 360.471 730.167C360.491 728.737 360.06 727.353 359.269 726.308C358.322 724.99 357.391 724.854 357.298 726.046C357.354 727.516 357.852 728.907 358.694 729.948Z" fill="white" fill-opacity="0.6"/>
        <path d="M346.901 709.291C346.055 708.098 345.209 707.952 345.133 708.956C345.19 710.46 345.665 711.893 346.478 713.014C347.4 714.363 348.288 714.531 348.339 713.359C348.237 711.844 347.731 710.413 346.901 709.291Z" fill="white" fill-opacity="0.6"/>
        <path d="M334.769 691.729C333.923 690.516 333.153 690.38 333.077 691.426C333.147 692.97 333.629 694.441 334.448 695.61C335.361 696.98 336.216 697.137 336.241 695.934C336.127 694.374 335.611 692.9 334.769 691.729Z" fill="white" fill-opacity="0.6"/>
        <path d="M322.832 674.377C321.986 673.143 321.14 672.944 321.038 673.938C320.937 674.932 321.588 677.076 322.544 678.425C323.39 679.555 324.109 679.617 324.186 678.551C324.111 677.015 323.636 675.551 322.832 674.377Z" fill="white" fill-opacity="0.6"/>
        <path d="M310.269 661.669C311.115 662.841 311.91 662.998 312.088 662.035C312.11 660.539 311.696 659.084 310.92 657.935C309.956 656.471 309.059 656.314 308.924 657.569C308.954 659.092 309.433 660.55 310.269 661.669Z" fill="white" fill-opacity="0.6"/>
        <path d="M296.614 642.832C296.624 643.571 296.755 644.3 296.997 644.976C297.24 645.651 297.59 646.26 298.027 646.765C298.966 647.863 299.854 647.643 299.88 646.315C299.807 644.813 299.268 643.404 298.374 642.382C297.494 641.43 296.716 641.671 296.614 642.832Z" fill="white" fill-opacity="0.6"/>
        <path d="M285.337 633.963C286.225 634.831 287.08 634.695 287.308 633.617C287.363 632.921 287.288 632.218 287.089 631.564C286.889 630.909 286.571 630.32 286.158 629.842C285.159 628.722 284.186 628.921 284 630.27C283.97 630.973 284.075 631.674 284.307 632.316C284.54 632.959 284.893 633.523 285.337 633.963Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.938 620.993C270.894 621.651 271.006 622.311 271.26 622.892C271.514 623.473 271.898 623.951 272.368 624.267C272.567 624.431 272.799 624.526 273.039 624.54C273.278 624.555 273.517 624.489 273.728 624.349C273.94 624.21 274.117 624.002 274.241 623.748C274.365 623.494 274.431 623.204 274.432 622.907C274.447 622.245 274.31 621.592 274.038 621.022C273.765 620.452 273.368 619.989 272.892 619.686C272.699 619.55 272.481 619.478 272.259 619.476C272.037 619.473 271.818 619.54 271.622 619.671C271.427 619.802 271.262 619.991 271.142 620.223C271.022 620.454 270.952 620.719 270.938 620.993Z" fill="white" fill-opacity="0.6"/>
        <path d="M259.517 613.619C259.286 613.553 259.046 613.543 258.812 613.591C258.578 613.639 258.354 613.744 258.153 613.9C257.951 614.055 257.777 614.258 257.64 614.497C257.502 614.736 257.404 615.007 257.351 615.293C257.295 615.615 257.292 615.948 257.344 616.271C257.395 616.595 257.499 616.902 257.649 617.174C257.8 617.446 257.993 617.677 258.218 617.854C258.443 618.03 258.695 618.148 258.958 618.2C259.187 618.256 259.423 618.252 259.65 618.189C259.878 618.127 260.092 618.007 260.281 617.837C260.469 617.667 260.627 617.451 260.744 617.202C260.862 616.953 260.936 616.677 260.963 616.391C261.025 615.792 260.914 615.186 260.649 614.679C260.384 614.172 259.983 613.797 259.517 613.619Z" fill="white" fill-opacity="0.6"/>
        <path d="M451.154 772.412C450.883 773.657 451.789 774.567 453.227 774.504C454.665 774.442 456.475 772.998 456.611 771.722C456.746 770.446 455.765 769.724 454.234 769.913C452.702 770.101 451.425 771.168 451.154 772.412Z" fill="white" fill-opacity="0.6"/>
        <path d="M473.489 770.561C473.218 771.816 474.394 772.653 476.154 772.507C478.049 772.329 480.045 770.844 480.164 769.547C480.282 768.25 478.962 767.591 477.152 767.852C475.341 768.114 473.751 769.275 473.489 770.561Z" fill="white" fill-opacity="0.6"/>
        <path d="M500.096 767.633C499.749 768.909 501.085 769.798 503.082 769.63C505.29 769.442 507.507 767.988 507.693 766.628C507.879 765.269 506.356 764.62 504.309 764.882C502.261 765.143 500.442 766.346 500.096 767.633Z" fill="white" fill-opacity="0.6"/>
        <path d="M530.095 764.171C529.892 765.457 531.482 766.262 533.724 765.98C536.11 765.677 538.496 764.076 538.504 762.769C538.513 761.462 536.761 760.865 534.494 761.232C532.227 761.598 530.298 762.895 530.095 764.171Z" fill="white" fill-opacity="0.6"/>
        <path d="M566.693 761.775C569.037 761.482 571.245 760.175 571.625 758.889C572.006 757.602 570.06 756.567 567.395 756.995C565.01 757.382 562.962 758.7 562.776 759.997C562.59 761.294 564.274 762.079 566.693 761.775Z" fill="white" fill-opacity="0.6"/>
        <path d="M597.217 755.521C597.158 756.797 599.07 757.529 601.557 757.184C604.239 756.807 606.633 755.144 606.489 753.826C606.345 752.508 604.324 751.985 601.786 752.425C599.248 752.864 597.268 754.234 597.217 755.521Z" fill="white" fill-opacity="0.6"/>
        <path d="M632.732 750.553C632.487 751.86 634.323 752.718 636.802 752.467C639.56 752.195 642.064 750.668 642.098 749.266C642.132 747.864 640.101 747.247 637.656 747.582C635.211 747.917 632.986 749.235 632.732 750.553Z" fill="white" fill-opacity="0.6"/>
        <path d="M668.425 745.741C668.18 747.07 669.982 747.948 672.452 747.728C675.202 747.488 677.672 746.003 677.689 744.591C677.689 743.336 675.701 742.499 673.307 742.823C670.913 743.147 668.662 744.434 668.425 745.741Z" fill="white" fill-opacity="0.6"/>
        <path d="M703.501 741.108C703.256 742.436 705.007 743.357 707.409 743.2C710.083 743.011 712.435 741.578 712.485 740.145C712.485 738.88 710.599 738.054 708.255 738.294C705.912 738.535 703.738 739.779 703.501 741.108Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.0637581 727.511C-0.00377303 728.119 0.126544 728.736 0.426246 729.226C0.725948 729.717 1.17056 730.04 1.66268 730.125C1.90902 730.181 2.16229 730.165 2.4031 730.081C2.6439 729.996 2.86586 729.845 3.05201 729.638C3.23816 729.431 3.38356 729.174 3.47708 728.887C3.5706 728.6 3.6098 728.29 3.59161 727.981C3.57192 727.376 3.38743 726.797 3.06802 726.337C2.74861 725.878 2.31301 725.565 1.83189 725.45C1.60001 725.404 1.36262 725.424 1.13805 725.509C0.913485 725.594 0.707785 725.742 0.536852 725.941C0.365918 726.141 0.234299 726.386 0.152263 726.658C0.0702272 726.93 0.0399738 727.222 0.0637581 727.511Z" fill="white" fill-opacity="0.6"/>
        <path d="M13.9635 728.724C13.2444 728.326 12.593 728.954 12.5337 730.125C12.4967 730.764 12.6125 731.402 12.8668 731.96C13.121 732.518 13.5024 732.971 13.9635 733.263C14.6742 733.567 15.2325 732.866 15.241 731.684C15.2517 731.096 15.1397 730.515 14.9159 729.997C14.6922 729.478 14.3642 729.04 13.9635 728.724Z" fill="white" fill-opacity="0.6"/>
        <path d="M22.5506 734.539C22.043 734.1 21.6369 734.748 21.7045 735.983C21.7141 737.145 22.0694 738.262 22.7028 739.12C23.2443 739.685 23.6674 738.964 23.5912 737.625C23.5711 736.471 23.1994 735.369 22.5506 734.539Z" fill="white" fill-opacity="0.6"/>
        <path d="M28.5316 747.237C28.8616 747.959 29.1154 747.666 29.1492 746.526C29.1624 745.296 28.9843 744.074 28.6247 742.928C28.244 741.997 27.914 742.384 27.914 743.764C27.9192 744.963 28.1297 746.146 28.5316 747.237Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.1864 757.372C32.3725 758.156 32.5333 757.853 32.5587 756.64C32.5832 755.348 32.4896 754.057 32.2795 752.791C32.0595 751.745 31.848 752.195 31.848 753.638C31.8418 754.895 31.9554 756.148 32.1864 757.372Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.2846 763.773C34.1831 762.989 34.0646 763.47 34.0223 764.819C33.9911 766.153 34.0307 767.487 34.1408 768.814C34.2339 769.777 34.3438 769.275 34.3861 767.769C34.4297 766.435 34.3958 765.1 34.2846 763.773Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.0292 777.171C34.9615 778.667 34.9446 780.466 34.9784 781.355C35.0123 782.244 35.0969 781.742 35.1731 780.309C35.2692 778.799 35.2945 777.284 35.2492 775.77C35.1984 775.038 35.0884 775.665 35.0292 777.171Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.2914 794.147C35.2914 795.005 35.4098 794.502 35.5283 792.986C35.6467 791.469 35.7313 789.419 35.7144 788.488C35.6975 787.558 35.5537 788.039 35.4352 789.806C35.3252 791.386 35.2744 793.268 35.2914 794.147Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.8328 802.378C35.6945 803.875 35.6351 805.381 35.6551 806.886C35.6974 807.838 35.8835 807.001 36.0443 805.181C36.1841 803.71 36.2378 802.228 36.2051 800.747C36.1458 799.994 35.9766 800.736 35.8328 802.378Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.6365 819.009C36.7295 819.95 36.958 819.479 37.1525 817.91C37.3477 816.312 37.4185 814.694 37.3641 813.078C37.2625 811.875 37.0087 812.461 36.7972 814.354C36.6149 815.895 36.5611 817.454 36.6365 819.009Z" fill="white" fill-opacity="0.6"/>
        <path d="M38.8277 830.273C39.0307 831.131 39.3691 830.535 39.606 828.893C39.8379 827.301 39.8721 825.675 39.7075 824.071C39.4876 822.952 39.1153 823.663 38.8615 825.629C38.6429 827.164 38.6314 828.734 38.8277 830.273Z" fill="white" fill-opacity="0.6"/>
        <path d="M42.8547 840.168C43.2016 840.795 43.7008 839.896 43.9461 838.149C44.2253 836.193 44.1068 833.965 43.6838 833.359C43.2608 832.752 42.8378 833.934 42.6179 835.775C42.3979 837.616 42.4994 839.53 42.8547 840.168Z" fill="white" fill-opacity="0.6"/>
        <path d="M49.259 848.033C49.792 848.462 50.4519 847.343 50.6973 845.544C50.9426 843.745 50.6973 841.758 50.1897 841.214C49.6821 840.67 48.8783 841.883 48.6499 843.965C48.4384 845.837 48.7007 847.594 49.259 848.033Z" fill="white" fill-opacity="0.6"/>
        <path d="M59.4195 846.6C58.6412 846.328 57.8459 847.583 57.5837 849.424C57.3214 851.265 57.719 852.991 58.4297 853.367C59.1403 853.744 60.2232 852.322 60.4262 850.387C60.6547 848.588 60.1809 846.862 59.4195 846.6Z" fill="white" fill-opacity="0.6"/>
        <path d="M244.703 616.433C245.21 616.361 245.688 616.103 246.075 615.69C246.461 615.278 246.738 614.731 246.869 614.121C246.916 613.858 246.912 613.585 246.858 613.324C246.805 613.063 246.702 612.821 246.559 612.617C246.416 612.414 246.236 612.255 246.034 612.153C245.833 612.051 245.614 612.008 245.397 612.029C244.792 612.172 244.232 612.523 243.783 613.044C243.334 613.564 243.013 614.232 242.859 614.968C242.791 616.109 243.587 616.715 244.703 616.433Z" fill="white" fill-opacity="0.6"/>
        <path d="M229.704 622.771C230.694 622.121 231.442 621.023 231.793 619.707C231.954 618.661 231.252 618.347 230.262 618.985C229.716 619.311 229.232 619.775 228.841 620.349C228.45 620.922 228.162 621.59 227.995 622.311C227.927 623.294 228.68 623.451 229.704 622.771Z" fill="white" fill-opacity="0.6"/>
        <path d="M215.668 634.82C216.578 633.918 217.274 632.729 217.682 631.379C217.876 630.427 217.259 630.333 216.371 631.17C215.348 632.119 214.586 633.434 214.188 634.935C214.078 635.845 214.746 635.772 215.668 634.82Z" fill="white" fill-opacity="0.6"/>
        <path d="M201.337 651.921C201.286 652.748 201.955 652.371 202.826 651.116C203.697 649.861 204.645 647.748 204.628 646.984C204.611 646.221 203.909 646.744 203.021 648.03C202.223 649.148 201.647 650.48 201.337 651.921Z" fill="white" fill-opacity="0.6"/>
        <path d="M190.77 670.738C191.518 669.46 192.071 668.024 192.403 666.501C192.513 665.456 191.819 666.052 190.889 667.757C190.148 669.007 189.621 670.43 189.341 671.94C189.307 672.746 189.95 672.202 190.77 670.738Z" fill="white" fill-opacity="0.6"/>
        <path d="M179.722 689.083C178.982 690.499 178.431 692.053 178.089 693.685C177.945 694.836 178.596 694.26 179.459 692.472C180.162 691.092 180.671 689.575 180.965 687.985C181.033 687.096 180.466 687.598 179.722 689.083Z" fill="white" fill-opacity="0.6"/>
        <path d="M167.446 716.528C167.327 717.574 167.827 717.229 168.554 715.702C169.327 714.129 169.874 712.402 170.17 710.598C170.221 709.709 169.679 710.242 168.977 711.769C168.272 713.249 167.756 714.854 167.446 716.528Z" fill="white" fill-opacity="0.6"/>
        <path d="M158.741 734.361C158.058 735.859 157.567 737.479 157.286 739.162C157.184 740.261 157.683 739.905 158.377 738.378C159.119 736.773 159.639 735.027 159.917 733.211C159.95 732.291 159.443 732.793 158.741 734.361Z" fill="white" fill-opacity="0.6"/>
        <path d="M147.514 760.322C147.379 761.535 147.836 761.367 148.504 760.008C149.257 758.411 149.783 756.667 150.061 754.851C150.128 753.805 149.663 754.098 148.995 755.531C148.295 757.018 147.795 758.636 147.514 760.322Z" fill="white" fill-opacity="0.6"/>
        <path d="M138.005 778.981C137.827 780.33 138.233 780.466 138.893 779.316C139.683 777.798 140.223 776.102 140.484 774.326C140.602 773.145 140.179 773.155 139.528 774.326C138.792 775.749 138.276 777.327 138.005 778.981Z" fill="white" fill-opacity="0.6"/>
        <path d="M127.946 795.894C127.794 797.264 128.31 797.264 129.097 795.988C129.989 794.422 130.6 792.639 130.89 790.758C130.975 789.534 130.476 789.566 129.68 790.925C128.837 792.42 128.247 794.11 127.946 795.894Z" fill="white" fill-opacity="0.6"/>
        <path d="M115.721 815.296C115.51 816.812 116.085 816.885 116.99 815.505C118.038 813.824 118.772 811.878 119.139 809.804C119.274 808.455 118.691 808.497 117.777 809.951C116.804 811.541 116.105 813.36 115.721 815.296Z" fill="white" fill-opacity="0.6"/>
        <path d="M101.61 833.024C101.339 834.75 101.965 835.241 102.98 834.143C104.231 832.608 105.095 830.657 105.468 828.527C105.654 826.958 105.002 826.613 103.987 827.774C102.822 829.223 102 831.039 101.61 833.024Z" fill="white" fill-opacity="0.6"/>
        <path d="M88.5644 842.124C87.2845 843.268 86.4068 844.975 86.1109 846.893C85.8825 848.692 86.6101 849.508 87.7184 848.734C89.1106 847.485 90.0447 845.604 90.3157 843.504C90.451 841.873 89.6642 841.245 88.5644 842.124Z" fill="white" fill-opacity="0.6"/>
        <path d="M69.91 852.384C69.6562 854.162 70.2314 855.784 71.3651 855.763C72.6341 855.763 73.9031 853.911 74.0977 851.966C74.2669 850.23 73.4716 848.943 72.3295 849.1C71.6955 849.257 71.1186 849.66 70.6832 850.251C70.2478 850.842 69.9769 851.589 69.91 852.384Z" fill="white" fill-opacity="0.6"/>
        <path d="M431.062 761.953C430.48 761.959 429.91 762.164 429.416 762.544C428.922 762.924 428.523 763.465 428.261 764.108C427.906 765.279 428.65 766.357 429.784 766.325C430.363 766.301 430.924 766.081 431.408 765.688C431.892 765.295 432.278 764.744 432.525 764.097C432.864 762.999 432.178 761.953 431.062 761.953Z" fill="white" fill-opacity="0.6"/>
        <path d="M411.4 759.966C411.303 760.25 411.257 760.558 411.267 760.867C411.277 761.176 411.342 761.478 411.457 761.752C411.573 762.026 411.736 762.265 411.936 762.452C412.135 762.639 412.365 762.768 412.61 762.832C413.143 763.038 413.716 763.029 414.245 762.807C414.774 762.586 415.232 762.162 415.554 761.598C416.02 760.499 415.554 759.265 414.378 758.794C413.846 758.574 413.27 758.569 412.735 758.779C412.199 758.99 411.732 759.405 411.4 759.966Z" fill="white" fill-opacity="0.6"/>
        <path d="M394.506 752.331C394.159 753.377 394.861 754.883 395.961 755.468C397.06 756.054 398.338 755.657 398.676 754.6C399.015 753.544 398.363 752.121 397.255 751.525C396.147 750.929 394.861 751.274 394.506 752.331Z" fill="white" fill-opacity="0.6"/>
        <path d="M380.157 744.601C381.206 745.396 382.315 745.166 382.594 744.12C382.873 743.074 382.213 741.484 381.206 740.71C380.2 739.936 379.049 740.083 378.753 741.15C378.457 742.216 379.142 743.838 380.157 744.601Z" fill="white" fill-opacity="0.6"/>
        <path d="M365.048 726.82C364.973 727.511 365.034 728.214 365.225 728.869C365.417 729.525 365.733 730.113 366.147 730.586C367.036 731.632 368 731.632 368.271 730.586C368.344 729.899 368.281 729.201 368.088 728.55C367.895 727.9 367.578 727.317 367.163 726.852C366.308 725.827 365.327 725.806 365.048 726.82Z" fill="white" fill-opacity="0.6"/>
        <path d="M354.591 710.65C353.745 709.604 352.823 709.604 352.628 710.65C352.603 711.384 352.703 712.116 352.921 712.799C353.14 713.482 353.472 714.101 353.897 714.614C354.743 715.66 355.64 715.66 355.826 714.614C355.856 713.883 355.762 713.153 355.549 712.47C355.336 711.787 355.01 711.167 354.591 710.65Z" fill="white" fill-opacity="0.6"/>
        <path d="M340.277 693.11C340.257 694.637 340.721 696.112 341.571 697.22C342.417 698.329 343.263 698.35 343.39 697.22C343.411 695.703 342.963 694.233 342.138 693.11C341.275 692.012 340.437 691.991 340.277 693.11Z" fill="white" fill-opacity="0.6"/>
        <path d="M330.099 675.465C329.304 674.252 328.407 674.064 328.162 675.089C328.115 675.843 328.19 676.6 328.382 677.318C328.574 678.036 328.878 678.701 329.279 679.272C330.082 680.475 330.971 680.663 331.174 679.67C331.28 678.148 330.894 676.635 330.099 675.465Z" fill="white" fill-opacity="0.6"/>
        <path d="M318.01 657.914C317.223 656.764 316.318 656.691 316.098 657.758C316.05 658.513 316.126 659.273 316.321 659.992C316.516 660.71 316.825 661.373 317.231 661.941C318.077 663.081 318.923 663.175 319.11 662.119C319.159 661.363 319.087 660.603 318.899 659.881C318.71 659.16 318.408 658.491 318.01 657.914Z" fill="white" fill-opacity="0.6"/>
        <path d="M305.912 641.556C305.117 640.51 304.22 640.562 303.974 641.692C303.919 642.424 303.992 643.162 304.189 643.855C304.386 644.549 304.703 645.181 305.117 645.708C305.963 646.754 306.809 646.754 307.02 645.614C307.074 644.88 307.003 644.139 306.812 643.441C306.621 642.742 306.315 642.1 305.912 641.556Z" fill="white" fill-opacity="0.6"/>
        <path d="M291.648 627.677C291.58 628.38 291.65 629.094 291.854 629.757C292.057 630.42 292.387 631.013 292.816 631.484C293.662 632.352 294.558 632.122 294.77 630.971C294.834 630.265 294.764 629.55 294.568 628.883C294.371 628.215 294.052 627.612 293.636 627.122C292.816 626.233 291.86 626.505 291.648 627.677Z" fill="white" fill-opacity="0.6"/>
        <path d="M280.075 619.853C280.238 620.031 280.435 620.155 280.648 620.214C280.861 620.273 281.082 620.264 281.291 620.189C281.5 620.114 281.691 619.975 281.845 619.784C281.999 619.594 282.112 619.358 282.173 619.1C282.295 618.45 282.266 617.771 282.088 617.141C281.909 616.511 281.59 615.957 281.166 615.544C280.998 615.362 280.795 615.236 280.577 615.178C280.359 615.12 280.132 615.132 279.919 615.213C279.705 615.293 279.512 615.44 279.357 615.639C279.202 615.838 279.091 616.082 279.034 616.349C278.912 616.995 278.946 617.671 279.131 618.293C279.315 618.916 279.644 619.458 280.075 619.853Z" fill="white" fill-opacity="0.6"/>
        <path d="M265.87 608.85C265.749 609.442 265.803 610.069 266.023 610.616C266.244 611.163 266.616 611.594 267.072 611.831C267.29 611.917 267.52 611.947 267.748 611.92C267.976 611.893 268.197 611.809 268.398 611.673C268.599 611.537 268.775 611.352 268.917 611.129C269.058 610.906 269.161 610.65 269.221 610.377C269.338 609.782 269.286 609.156 269.074 608.603C268.861 608.05 268.5 607.606 268.053 607.344C267.83 607.252 267.594 607.218 267.36 607.245C267.125 607.272 266.898 607.36 266.693 607.501C266.488 607.643 266.308 607.836 266.167 608.068C266.025 608.301 265.924 608.567 265.87 608.85Z" fill="white" fill-opacity="0.6"/>
        <path d="M252.097 605.639C252.028 605.897 252.008 606.171 252.038 606.44C252.069 606.71 252.15 606.967 252.275 607.191C252.399 607.415 252.564 607.6 252.757 607.732C252.949 607.864 253.164 607.938 253.383 607.95C253.887 607.959 254.379 607.764 254.785 607.396C255.191 607.028 255.489 606.507 255.634 605.911C255.705 605.653 255.728 605.379 255.701 605.108C255.674 604.838 255.598 604.579 255.478 604.35C255.359 604.121 255.199 603.929 255.01 603.788C254.821 603.648 254.609 603.561 254.39 603.536C253.879 603.549 253.383 603.758 252.972 604.135C252.561 604.512 252.256 605.037 252.097 605.639Z" fill="white" fill-opacity="0.6"/>
        <path d="M444.784 763.951C444.318 765.164 444.97 766.179 446.18 766.106C446.823 766.035 447.444 765.78 447.993 765.361C448.542 764.941 449.005 764.369 449.344 763.689C449.792 762.487 449.107 761.514 447.897 761.598C447.264 761.653 446.65 761.896 446.109 762.306C445.567 762.715 445.112 763.279 444.784 763.951Z" fill="white" fill-opacity="0.6"/>
        <path d="M464.843 762.382C464.31 763.648 465.156 764.641 466.687 764.537C468.218 764.432 470.02 763.24 470.519 762.016C471.018 760.792 470.172 759.819 468.633 759.924C467.093 760.029 465.367 761.137 464.843 762.382Z" fill="white" fill-opacity="0.6"/>
        <path d="M489.292 760.018C488.869 761.263 490.138 762.183 491.915 761.943C493.691 761.702 495.705 760.416 496.102 759.161C496.5 757.905 495.256 757.069 493.446 757.267C491.636 757.466 489.732 758.742 489.292 760.018Z" fill="white" fill-opacity="0.6"/>
        <path d="M521.804 753.837C519.757 754.192 517.819 755.531 517.515 756.818C517.21 758.104 518.818 758.91 520.848 758.512C522.879 758.115 524.926 756.755 525.163 755.521C525.4 754.286 523.801 753.502 521.804 753.837Z" fill="white" fill-opacity="0.6"/>
        <path d="M548.758 752.54C548.293 753.858 549.765 754.768 551.956 754.527C554.147 754.286 556.457 752.916 556.88 751.64C557.303 750.364 555.847 749.465 553.648 749.684C551.448 749.904 549.223 751.264 548.758 752.54Z" fill="white" fill-opacity="0.6"/>
        <path d="M586.98 745.218C584.739 745.49 582.412 746.881 582.057 748.178C581.701 749.475 583.393 750.364 585.695 750.061C587.996 749.758 590.331 748.356 590.644 747.08C590.957 745.804 589.273 744.957 586.98 745.218Z" fill="white" fill-opacity="0.6"/>
        <path d="M616.726 743.566C616.489 744.842 618.418 745.658 620.761 745.333C623.105 745.009 625.398 743.555 625.575 742.269C625.753 740.982 623.883 740.177 621.531 740.512C619.179 740.846 616.954 742.248 616.726 743.566Z" fill="white" fill-opacity="0.6"/>
        <path d="M652.004 738.88C651.937 740.177 654.01 740.972 656.387 740.512C658.764 740.051 660.854 738.619 660.862 737.374C660.871 736.129 658.789 735.345 656.471 735.753C654.153 736.161 652.064 737.583 652.004 738.88Z" fill="white" fill-opacity="0.6"/>
        <path d="M687.088 734.184C687.088 735.481 689.161 736.276 691.479 735.836C693.797 735.397 695.802 733.985 695.76 732.698C695.718 731.412 693.628 730.659 691.361 731.046C689.093 731.433 687.088 732.887 687.088 734.184Z" fill="white" fill-opacity="0.6"/>
        <path d="M6.70479 719.363C6.67202 719.968 6.79303 720.57 7.05091 721.086C7.30879 721.602 7.69065 722.005 8.14302 722.239C8.93828 722.532 9.67429 721.716 9.69967 720.513C9.7273 719.911 9.60934 719.311 9.36077 718.792C9.11221 718.272 8.7443 717.856 8.30377 717.595C7.51697 717.26 6.75555 718.108 6.70479 719.363Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.4237 723.902C17.4184 724.481 17.5183 725.054 17.7159 725.579C17.9136 726.104 18.2041 726.568 18.5659 726.935C19.1581 727.312 19.6319 726.59 19.6065 725.346C19.6218 724.755 19.5312 724.167 19.341 723.624C19.1509 723.082 18.8659 722.598 18.5067 722.208C17.9229 721.863 17.4068 722.637 17.4237 723.902Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.0801 730.429C25.0407 731.638 25.2909 732.835 25.7993 733.87C26.1884 734.466 26.5522 733.922 26.5776 732.709C26.6235 731.489 26.3889 730.278 25.9008 729.215C25.5032 728.588 25.1055 729.174 25.0801 730.429Z" fill="white" fill-opacity="0.6"/>
        <path d="M30.7736 737.844C30.5114 737.185 30.2406 737.844 30.2152 739.12C30.1844 740.374 30.3363 741.625 30.6636 742.813C30.9175 743.482 31.1459 742.917 31.1797 741.599C31.2206 740.328 31.0832 739.058 30.7736 737.844Z" fill="white" fill-opacity="0.6"/>
        <path d="M33.3962 749.35C33.3428 750.655 33.411 751.964 33.5993 753.251C33.7516 753.899 33.9038 753.251 33.9631 751.797C34.0279 750.469 33.9712 749.135 33.7939 747.823C33.6332 747.174 33.447 747.864 33.3962 749.35Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.1729 760.395C35.0984 761.788 35.0984 763.185 35.1729 764.578C35.2575 765.332 35.3928 764.693 35.4943 763.156C35.5852 761.729 35.5852 760.295 35.4943 758.868C35.4351 758.167 35.2744 758.815 35.1729 760.395Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.1036 772.161C35.9751 773.645 35.9214 775.136 35.9428 776.628C35.9428 777.402 36.129 776.701 36.2813 775.059C36.4242 773.556 36.4864 772.043 36.4674 770.53C36.4166 769.766 36.2474 770.519 36.1036 772.161Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.7379 784.158C36.5655 785.697 36.4836 787.249 36.4926 788.802C36.5433 789.576 36.7379 788.802 36.924 787.024C37.1107 785.466 37.2012 783.893 37.1947 782.317C37.1355 781.554 36.924 782.401 36.7379 784.158Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.381 800.736C37.4741 801.447 37.7448 800.517 37.9816 798.644C38.2054 797.046 38.2905 795.422 38.2355 793.802C38.1424 793.09 37.8547 794.074 37.6263 795.894C37.4085 797.493 37.3263 799.116 37.381 800.736Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.3098 807.315C39.0503 808.863 39.0045 810.455 39.1745 812.022C39.3606 812.587 39.7413 811.436 40.0205 809.48C40.2786 807.904 40.33 806.286 40.1728 804.69C39.9528 804.125 39.5721 805.349 39.3098 807.315Z" fill="white" fill-opacity="0.6"/>
        <path d="M42.3555 822.021C42.6177 822.722 43.1338 821.644 43.4468 819.699C43.7703 818.053 43.7992 816.34 43.5314 814.678C43.2776 813.946 42.7615 815.086 42.4485 817.063C42.1263 818.688 42.0946 820.38 42.3555 822.021Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.4317 825.839C47.0933 827.878 47.1779 830.022 47.6009 830.629C48.0239 831.236 48.7007 829.897 49.0306 827.889C49.3606 825.88 49.276 823.611 48.853 823.025C48.43 822.439 47.7701 823.778 47.4317 825.839Z" fill="white" fill-opacity="0.6"/>
        <path d="M54.8933 832.763C54.5549 834.854 54.8171 836.946 55.4347 837.25C56.0523 837.553 56.9068 836.12 57.2367 834.112C57.5667 832.104 57.2875 829.928 56.6699 829.552C56.0523 829.175 55.2233 830.671 54.8933 832.763Z" fill="white" fill-opacity="0.6"/>
        <path d="M66.2974 841.444C67.1434 841.716 68.2009 840.178 68.5562 838.17C68.9116 836.162 68.5055 834.133 67.7102 833.798C66.915 833.463 65.849 835.053 65.4767 837.124C65.1045 839.195 65.4514 841.151 66.2974 841.444Z" fill="white" fill-opacity="0.6"/>
        <path d="M239.407 607.04C238.319 607.643 237.444 608.713 236.945 610.053C236.675 611.099 237.343 611.538 238.375 611.036C239.464 610.466 240.342 609.414 240.837 608.086C241.099 607.009 240.431 606.507 239.407 607.04Z" fill="white" fill-opacity="0.6"/>
        <path d="M222.166 621.244C221.963 622.164 222.631 622.196 223.578 621.338C224.536 620.489 225.29 619.336 225.761 618.002C225.956 617.081 225.313 616.956 224.348 617.866C223.394 618.736 222.641 619.901 222.166 621.244Z" fill="white" fill-opacity="0.6"/>
        <path d="M210.601 632.875C209.74 633.948 209.064 635.225 208.613 636.63C208.435 637.508 209.061 637.268 209.932 636.159C210.786 635.093 211.459 633.827 211.912 632.436C212.081 631.578 211.489 631.766 210.601 632.875Z" fill="white" fill-opacity="0.6"/>
        <path d="M196.1 655.164C195.914 656.105 196.456 655.781 197.276 654.484C198.065 653.248 198.688 651.864 199.12 650.384C199.307 649.474 198.791 649.767 197.97 651.043C197.173 652.284 196.542 653.675 196.1 655.164Z" fill="white" fill-opacity="0.6"/>
        <path d="M187.319 670.56C187.42 669.754 186.853 670.298 186.109 671.731C185.372 673.069 184.802 674.536 184.417 676.082C184.299 676.95 184.891 676.365 185.652 674.879C186.375 673.548 186.937 672.093 187.319 670.56Z" fill="white" fill-opacity="0.6"/>
        <path d="M174.662 696.98C175.359 695.525 175.9 693.964 176.27 692.336C176.414 691.395 175.94 691.834 175.238 693.288C174.527 694.743 173.975 696.308 173.597 697.942C173.419 698.946 173.952 698.476 174.662 696.98Z" fill="white" fill-opacity="0.6"/>
        <path d="M163.368 719.959C163.174 721.099 163.622 720.775 164.299 719.3C164.984 717.77 165.518 716.144 165.889 714.457C166.059 713.411 165.644 713.683 164.984 715.116C164.284 716.64 163.741 718.267 163.368 719.959Z" fill="white" fill-opacity="0.6"/>
        <path d="M155.128 736.37C154.442 737.902 153.924 739.541 153.588 741.244C153.419 742.426 153.876 742.122 154.536 740.627C155.207 739.093 155.713 737.458 156.042 735.763C156.194 734.665 155.771 734.947 155.128 736.37Z" fill="white" fill-opacity="0.6"/>
        <path d="M144.172 760.447C143.961 761.786 144.375 761.765 145.018 760.447C145.716 758.925 146.235 757.287 146.558 755.583C146.744 754.328 146.372 754.339 145.712 755.583C145.012 757.104 144.493 758.742 144.172 760.447Z" fill="white" fill-opacity="0.6"/>
        <path d="M134.976 776.544C134.714 778.05 135.044 778.479 135.661 777.454C136.414 776.012 136.966 774.425 137.294 772.758C137.548 771.304 137.294 770.875 136.651 771.848C135.876 773.28 135.309 774.87 134.976 776.544Z" fill="white" fill-opacity="0.6"/>
        <path d="M124.993 794.513C125.912 792.889 126.588 791.076 126.99 789.158C127.269 787.599 126.871 787.233 126.093 788.457C125.151 790.105 124.452 791.947 124.029 793.896C123.724 795.528 124.207 795.789 124.993 794.513Z" fill="white" fill-opacity="0.6"/>
        <path d="M112.092 813.204C113.21 811.662 114.031 809.828 114.495 807.838C114.884 805.987 114.495 805.255 113.572 806.238C112.414 807.765 111.572 809.617 111.119 811.635C110.713 813.517 111.178 814.25 112.092 813.204Z" fill="white" fill-opacity="0.6"/>
        <path d="M96.263 826.979C95.9161 828.966 96.5337 829.865 97.5658 828.924C98.8262 827.468 99.7094 825.582 100.104 823.506C100.442 821.592 99.8754 820.682 98.8517 821.561C97.5662 823.002 96.6637 824.891 96.263 826.979Z" fill="white" fill-opacity="0.6"/>
        <path d="M82.7438 832.25C81.6101 832.888 80.4172 835.105 80.1465 837.176C79.8758 839.247 80.688 840.429 81.8385 839.76C82.5143 839.196 83.0885 838.465 83.5253 837.614C83.962 836.762 84.2518 835.809 84.3766 834.813C84.5881 832.857 83.8521 831.633 82.7438 832.25Z" fill="white" fill-opacity="0.6"/>
        <path d="M440.291 755.74C439.708 756.839 440.215 757.832 441.29 757.769C441.902 757.677 442.49 757.42 443.013 757.017C443.536 756.614 443.982 756.075 444.318 755.437C444.809 754.391 444.479 753.596 443.472 753.628C442.853 753.687 442.25 753.906 441.702 754.27C441.154 754.633 440.674 755.134 440.291 755.74Z" fill="white" fill-opacity="0.6"/>
        <path d="M423.312 754.402C422.754 755.448 423.312 756.64 424.471 756.86C425.06 756.951 425.658 756.839 426.198 756.536C426.739 756.234 427.201 755.752 427.534 755.144C427.999 754.098 427.584 753.22 426.544 752.979C425.952 752.844 425.341 752.905 424.774 753.154C424.206 753.404 423.702 753.834 423.312 754.402Z" fill="white" fill-opacity="0.6"/>
        <path d="M409.116 748.095C407.94 747.582 406.493 747.959 405.994 748.973C405.495 749.988 406.155 751.337 407.297 751.787C407.787 751.992 408.314 752.016 408.814 751.856C409.315 751.697 409.766 751.36 410.114 750.887C410.732 749.873 410.3 748.618 409.116 748.095Z" fill="white" fill-opacity="0.6"/>
        <path d="M389.294 739.863C388.913 740.909 389.573 742.352 390.707 743.001C391.841 743.649 393.084 743.221 393.414 742.143C393.744 741.066 393.169 739.978 392.145 739.34C391.121 738.702 389.658 738.859 389.294 739.863Z" fill="white" fill-opacity="0.6"/>
        <path d="M375.157 730.575C376.122 731.621 377.264 731.621 377.695 730.575C377.87 730.022 377.891 729.413 377.753 728.844C377.615 728.275 377.326 727.78 376.934 727.437C375.978 726.392 374.768 726.172 374.252 727.009C373.736 727.845 374.244 729.529 375.157 730.575Z" fill="white" fill-opacity="0.6"/>
        <path d="M361.283 711.874C360.885 712.721 361.283 714.541 362.129 715.618C362.975 716.696 363.982 716.779 364.345 715.786C364.477 715.198 364.478 714.576 364.349 713.987C364.219 713.399 363.964 712.864 363.609 712.439C362.772 711.257 361.697 710.995 361.283 711.874Z" fill="white" fill-opacity="0.6"/>
        <path d="M349.506 698.706C350.352 699.898 351.351 700.034 351.655 699.03C351.754 698.395 351.728 697.74 351.581 697.12C351.434 696.5 351.169 695.932 350.809 695.463C349.963 694.177 348.889 693.842 348.525 694.742C348.161 695.641 348.669 697.534 349.506 698.706Z" fill="white" fill-opacity="0.6"/>
        <path d="M336.08 676.835C336.02 677.578 336.09 678.329 336.284 679.036C336.478 679.743 336.792 680.391 337.206 680.935C338.052 682.086 338.965 682.117 339.194 681.009C339.248 680.337 339.188 679.659 339.018 679.017C338.847 678.376 338.57 677.786 338.204 677.285C337.358 676.04 336.334 675.831 336.08 676.835Z" fill="white" fill-opacity="0.6"/>
        <path d="M323.915 658.856C323.853 659.603 323.923 660.358 324.118 661.069C324.314 661.78 324.631 662.431 325.048 662.977C325.894 664.096 326.74 664.023 326.986 662.914C327.034 662.237 326.97 661.555 326.799 660.91C326.627 660.264 326.351 659.669 325.988 659.159C325.15 657.935 324.152 657.799 323.915 658.856Z" fill="white" fill-opacity="0.6"/>
        <path d="M314.008 641.933C313.162 640.709 312.206 640.552 311.885 641.577C311.563 642.602 312.071 644.526 312.883 645.625C313.695 646.723 314.44 646.733 314.778 645.907C315.116 645.081 314.829 643.135 314.008 641.933Z" fill="white" fill-opacity="0.6"/>
        <path d="M299.77 626.076C299.657 626.767 299.685 627.482 299.852 628.156C300.019 628.83 300.319 629.442 300.726 629.936C301.572 630.982 302.477 630.835 302.765 629.685C302.864 629.056 302.838 628.408 302.691 627.794C302.544 627.18 302.279 626.62 301.919 626.16C301.115 625.051 300.125 625.02 299.77 626.076Z" fill="white" fill-opacity="0.6"/>
        <path d="M287.384 613.284C287.285 613.943 287.338 614.623 287.539 615.245C287.74 615.867 288.079 616.406 288.518 616.799C288.685 616.975 288.888 617.093 289.104 617.14C289.321 617.188 289.544 617.162 289.75 617.068C289.956 616.973 290.138 616.811 290.277 616.601C290.416 616.39 290.507 616.137 290.54 615.868C290.623 615.267 290.574 614.649 290.398 614.079C290.222 613.509 289.927 613.007 289.542 612.626C289.384 612.429 289.188 612.286 288.972 612.211C288.757 612.136 288.529 612.132 288.312 612.198C288.094 612.265 287.895 612.4 287.732 612.59C287.57 612.781 287.45 613.02 287.384 613.284Z" fill="white" fill-opacity="0.6"/>
        <path d="M275.608 606.57C275.798 606.728 276.015 606.829 276.242 606.865C276.469 606.902 276.7 606.873 276.916 606.781C277.133 606.689 277.329 606.536 277.489 606.334C277.65 606.132 277.77 605.887 277.841 605.618C278.006 605.099 278.025 604.527 277.894 603.994C277.764 603.461 277.492 602.998 277.122 602.679C276.936 602.508 276.721 602.39 276.494 602.332C276.266 602.275 276.031 602.28 275.806 602.347C275.58 602.414 275.369 602.541 275.187 602.719C275.005 602.897 274.857 603.123 274.753 603.38C274.577 603.952 274.565 604.582 274.721 605.163C274.877 605.744 275.19 606.241 275.608 606.57Z" fill="white" fill-opacity="0.6"/>
        <path d="M261.471 598.045C261.382 598.295 261.338 598.565 261.343 598.838C261.348 599.11 261.401 599.378 261.498 599.623C261.596 599.867 261.736 600.082 261.908 600.252C262.08 600.422 262.28 600.544 262.495 600.608C262.927 600.702 263.371 600.622 263.764 600.381C264.157 600.14 264.477 599.751 264.677 599.269C264.798 599.035 264.874 598.769 264.9 598.493C264.926 598.217 264.9 597.937 264.825 597.675C264.75 597.413 264.627 597.175 264.467 596.98C264.307 596.785 264.112 596.638 263.899 596.55C263.417 596.416 262.912 596.491 262.47 596.764C262.028 597.036 261.675 597.489 261.471 598.045Z" fill="white" fill-opacity="0.6"/>
        <path d="M457.965 754.632C457.474 755.793 458.286 756.629 459.733 756.42C461.179 756.211 462.787 754.998 463.21 753.816C463.633 752.634 462.863 752.069 461.569 752.205C460.147 752.393 458.438 753.586 457.965 754.632Z" fill="white" fill-opacity="0.6"/>
        <path d="M480.341 752.32C479.732 753.471 480.722 754.412 482.372 754.245C484.021 754.077 485.959 752.854 486.466 751.63C486.974 750.406 486.128 749.789 484.614 749.904C482.913 750.019 480.976 751.107 480.341 752.32Z" fill="white" fill-opacity="0.6"/>
        <path d="M506.618 749.235C506.026 750.417 507.253 751.327 509.156 751.107C511.06 750.887 513.141 749.611 513.606 748.356C514.072 747.101 513.048 746.536 511.322 746.683C509.351 746.85 507.236 748 506.618 749.235Z" fill="white" fill-opacity="0.6"/>
        <path d="M539.147 747.258C541.271 746.965 543.42 745.616 543.775 744.371C544.13 743.126 542.929 742.583 540.992 742.792C538.851 743.032 536.601 744.266 536.085 745.501C535.569 746.735 537.066 747.551 539.147 747.258Z" fill="white" fill-opacity="0.6"/>
        <path d="M567.996 741.307C567.692 742.53 569.417 743.262 571.685 742.865C573.952 742.467 576.075 741.066 576.245 739.832C576.414 738.598 574.933 738.127 572.861 738.42C570.61 738.744 568.292 740.104 567.996 741.307Z" fill="white" fill-opacity="0.6"/>
        <path d="M606.794 733.713C604.425 733.995 602.082 735.282 601.718 736.537C601.354 737.792 603.131 738.629 605.432 738.252C607.505 737.949 609.56 736.778 610.076 735.617C610.592 734.456 609.112 733.42 606.794 733.713Z" fill="white" fill-opacity="0.6"/>
        <path d="M639.864 733.462C642.267 733.201 644.475 731.925 644.822 730.586C645.126 729.404 643.79 728.672 641.658 728.839C639.272 729.027 636.853 730.251 636.32 731.538C635.787 732.824 637.563 733.713 639.864 733.462Z" fill="white" fill-opacity="0.6"/>
        <path d="M671.048 727.04C670.955 728.285 672.952 729.017 675.278 728.609C677.605 728.201 679.618 726.779 679.576 725.534C679.533 724.289 677.884 723.798 675.718 724.101C673.408 724.436 671.141 725.827 671.048 727.04Z" fill="white" fill-opacity="0.6"/>
        <path d="M705.278 722.469C705.278 723.714 707.249 724.457 709.508 724.07C711.766 723.683 713.645 722.271 713.526 721.015C713.408 719.76 711.758 719.269 709.685 719.562C707.409 719.875 705.294 721.256 705.278 722.469Z" fill="white" fill-opacity="0.6"/>
        <path d="M1.78967 711.592C2.01177 711.611 2.23456 711.569 2.44198 711.469C2.64941 711.369 2.8363 711.213 2.98922 711.013C3.14215 710.813 3.2573 710.573 3.32636 710.312C3.39542 710.05 3.41669 709.773 3.38863 709.5C3.38451 708.891 3.19796 708.306 2.86509 707.858C2.53222 707.41 2.07667 707.13 1.58663 707.073C1.38165 707.067 1.1778 707.113 0.988222 707.21C0.798643 707.306 0.627525 707.451 0.485751 707.634C0.343977 707.817 0.234678 708.035 0.164936 708.273C0.0951941 708.512 0.06651 708.766 0.0807178 709.019C0.0744733 709.628 0.242556 710.22 0.555253 710.691C0.86795 711.162 1.30516 711.481 1.78967 711.592Z" fill="white" fill-opacity="0.6"/>
        <path d="M13.752 715.095C14.4457 715.388 14.9872 714.551 14.9364 713.265C14.9423 712.74 14.8378 712.222 14.6331 711.762C14.4285 711.301 14.1307 710.915 13.7689 710.64C13.0583 710.263 12.4153 710.995 12.4068 712.229C12.4072 712.811 12.5318 713.383 12.768 713.886C13.0042 714.389 13.3436 714.806 13.752 715.095Z" fill="white" fill-opacity="0.6"/>
        <path d="M21.5777 717.627C21.5522 718.803 21.8842 719.949 22.5083 720.838C23.0074 721.319 23.405 720.618 23.3543 719.29C23.3952 718.22 23.115 717.17 22.5675 716.34C22.0853 715.786 21.6115 716.382 21.5777 717.627Z" fill="white" fill-opacity="0.6"/>
        <path d="M28.0579 724.959C28.0155 726.175 28.216 727.385 28.6417 728.483C28.9886 729.08 29.2762 728.421 29.3016 727.029C29.3558 725.919 29.1872 724.808 28.8109 723.798C28.4556 723.128 28.1087 723.662 28.0579 724.959Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.3811 734.016C32.3134 735.265 32.4251 736.518 32.7111 737.719C32.931 738.273 33.1426 737.468 33.1933 735.951C33.2621 734.803 33.1676 733.649 32.9141 732.542C32.6942 731.904 32.4234 732.615 32.3811 734.016Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.5197 742.426C35.3843 741.746 35.1559 742.52 35.0629 744.026C34.9645 745.35 34.9957 746.685 35.1559 748C35.2744 748.534 35.4351 747.917 35.5451 746.526C35.6824 745.163 35.6739 743.785 35.5197 742.426Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.1356 753C37.051 752.299 36.8226 753.136 36.6872 754.757C36.5439 756.162 36.5099 757.58 36.5857 758.993C36.6619 759.558 36.8226 758.878 36.9834 757.382C37.1518 755.931 37.2028 754.463 37.1356 753Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.7448 765.823C37.56 767.311 37.4864 768.816 37.5248 770.321C37.5925 770.979 37.8294 769.944 38.0493 768.072C38.2284 766.689 38.302 765.288 38.2693 763.888C38.1932 763.187 37.9394 764.045 37.7448 765.823Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.4369 774.787C39.3354 774.107 39.0139 775.195 38.777 777.046C38.5448 778.562 38.4597 780.107 38.5232 781.648C38.6163 782.171 38.8616 781.303 39.1154 779.556C39.3757 777.988 39.4836 776.387 39.4369 774.787Z" fill="white" fill-opacity="0.6"/>
        <path d="M41.2049 785.413C41.0695 784.577 40.6973 785.528 40.3589 787.505C40.0725 789.136 39.97 790.808 40.0543 792.473C40.1812 793.111 40.5112 792.275 40.8073 790.559C41.1374 788.876 41.2713 787.143 41.2049 785.413Z" fill="white" fill-opacity="0.6"/>
        <path d="M42.7954 802.546C43.0407 803.163 43.5484 801.845 43.8953 799.669C44.1976 798.179 44.2579 796.629 44.0729 795.109C43.836 794.377 43.3453 795.507 42.9985 797.557C42.6649 799.187 42.5959 800.882 42.7954 802.546Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.1608 806.719C46.797 808.894 46.8309 811.08 47.2285 811.467C47.6261 811.854 48.2691 810.275 48.6159 808.016C48.9205 806.029 48.8952 804.104 48.5483 803.633C48.2014 803.163 47.5077 804.596 47.1608 806.719Z" fill="white" fill-opacity="0.6"/>
        <path d="M53.5482 813.8C53.1337 815.892 53.2352 818.276 53.7513 818.737C54.2674 819.197 55.0795 817.691 55.4433 815.39C55.8071 813.298 55.7225 811.405 55.2572 810.871C54.7919 810.338 53.9797 811.614 53.5482 813.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M62.4651 819.751C62.0929 821.99 62.4651 824.008 63.2012 824.134C63.9372 824.259 64.8932 822.418 65.2654 820.128C65.5616 818.099 65.2654 816.321 64.5802 816.08C63.8949 815.839 62.8289 817.565 62.4651 819.751Z" fill="white" fill-opacity="0.6"/>
        <path d="M250.084 595.451C248.882 595.937 247.863 596.943 247.216 598.286C246.827 599.332 247.52 599.876 248.629 599.447C249.11 599.351 249.567 599.122 249.967 598.778C250.366 598.434 250.698 597.984 250.938 597.46C251.429 596.33 251.048 595.399 250.084 595.451Z" fill="white" fill-opacity="0.6"/>
        <path d="M231.328 608.149C231.083 609.038 231.785 609.069 232.825 608.243C233.84 607.485 234.657 606.381 235.177 605.064C235.363 604.279 234.78 604.206 233.815 604.938C232.775 605.724 231.918 606.831 231.328 608.149Z" fill="white" fill-opacity="0.6"/>
        <path d="M219.103 618.577C218.181 619.572 217.425 620.78 216.878 622.133C216.684 622.928 217.343 622.635 218.308 621.526C219.124 620.615 219.803 619.531 220.313 618.326C220.643 617.374 220.101 617.468 219.103 618.577Z" fill="white" fill-opacity="0.6"/>
        <path d="M203.74 639.182C203.461 640.071 204.01 639.747 204.856 638.554C205.68 637.407 206.359 636.113 206.87 634.716C207.073 633.963 206.658 634.172 205.88 635.249C205.018 636.426 204.297 637.751 203.74 639.182Z" fill="white" fill-opacity="0.6"/>
        <path d="M193.469 654.682C192.701 655.972 192.07 657.378 191.591 658.866C191.422 659.661 191.989 659.065 192.801 657.622C193.483 656.446 194.051 655.175 194.493 653.835C194.755 652.863 194.307 653.176 193.469 654.682Z" fill="white" fill-opacity="0.6"/>
        <path d="M182.319 675.036C181.565 676.461 180.938 677.983 180.449 679.576C180.187 680.622 180.644 680.193 181.363 678.77C182.032 677.445 182.599 676.044 183.055 674.587C183.385 673.478 183.055 673.656 182.319 675.036Z" fill="white" fill-opacity="0.6"/>
        <path d="M170.035 700.829C169.756 701.969 170.145 701.697 170.821 700.285C171.509 698.803 172.075 697.24 172.514 695.62C172.725 694.658 172.437 694.846 171.828 696.091C171.097 697.588 170.496 699.176 170.035 700.829Z" fill="white" fill-opacity="0.6"/>
        <path d="M160.932 721.361C161.632 719.832 162.199 718.215 162.624 716.539C162.852 715.493 162.624 715.545 162.023 716.738C161.295 718.274 160.7 719.901 160.246 721.591C159.942 722.846 160.289 722.731 160.932 721.361Z" fill="white" fill-opacity="0.6"/>
        <path d="M150.83 741.034C150.568 742.352 150.923 742.29 151.566 740.919C152.265 739.373 152.813 737.73 153.199 736.024C153.411 734.926 153.14 734.979 152.565 736.129C151.829 737.672 151.246 739.319 150.83 741.034Z" fill="white" fill-opacity="0.6"/>
        <path d="M142.252 758.69C143.024 757.218 143.611 755.61 143.995 753.92C144.316 752.477 144.189 752.006 143.665 752.822C142.871 754.254 142.258 755.827 141.846 757.487C141.465 759.056 141.668 759.652 142.252 758.69Z" fill="white" fill-opacity="0.6"/>
        <path d="M134.215 767.653C133.308 769.242 132.627 771.011 132.201 772.883C131.888 774.473 132.303 774.714 133.047 773.469C133.829 772.033 134.425 770.456 134.816 768.794C135.213 767.026 134.968 766.482 134.215 767.653Z" fill="white" fill-opacity="0.6"/>
        <path d="M122.853 784.723C121.815 786.436 121.035 788.367 120.552 790.423C120.213 792.149 120.713 792.411 121.567 791.02C122.474 789.453 123.163 787.71 123.606 785.863C124.054 783.918 123.733 783.416 122.853 784.723Z" fill="white" fill-opacity="0.6"/>
        <path d="M106.754 805.119C106.305 807.137 106.83 808.1 107.794 807.127C109.028 805.574 109.904 803.643 110.332 801.531C110.696 799.722 110.332 798.927 109.486 799.69C108.179 801.144 107.231 803.026 106.754 805.119Z" fill="white" fill-opacity="0.6"/>
        <path d="M93.9619 812.158C92.8451 812.911 91.5 815.494 91.17 817.555C90.8401 819.615 91.5507 820.693 92.659 819.824C93.8999 818.539 94.7722 816.793 95.1463 814.846C95.5862 812.608 95.1124 811.405 93.9619 812.158Z" fill="white" fill-opacity="0.6"/>
        <path d="M77.3042 818.925C76.1113 819.186 74.9438 821.017 74.6223 823.192C74.3008 825.368 74.9015 827.23 76.0774 826.843C77.2534 826.456 78.2348 824.678 78.6155 822.659C79.1231 820.295 78.5309 818.663 77.3042 818.925Z" fill="white" fill-opacity="0.6"/>
        <path d="M101.931 776.774C100.975 777.266 99.6555 779.587 99.0718 781.847C98.4288 784.315 98.9279 786.125 100.011 785.371C101.094 784.618 102.21 782.234 102.71 779.995C103.209 777.757 102.93 776.261 101.931 776.774Z" fill="white" fill-opacity="0.6"/>
        <path d="M117.244 766.817C116.398 767.465 115.163 769.819 114.588 771.942C113.953 774.274 114.342 775.718 115.307 774.787C116.498 773.301 117.371 771.471 117.845 769.463C118.361 767.266 118.124 766.137 117.244 766.817Z" fill="white" fill-opacity="0.6"/>
        <path d="M128.149 760.29C127.624 762.141 127.751 763.125 128.386 762.382C129.37 760.876 130.134 759.17 130.645 757.341C131.321 755.029 131.245 753.952 130.442 755.029C129.447 756.613 128.672 758.391 128.149 760.29Z" fill="white" fill-opacity="0.6"/>
        <path d="M139.316 747.185C140.17 745.809 140.838 744.273 141.296 742.635C141.846 740.773 141.897 739.706 141.389 740.229C140.411 741.721 139.662 743.423 139.181 745.25C138.733 746.934 138.733 747.844 139.316 747.185Z" fill="white" fill-opacity="0.6"/>
        <path d="M148.149 732.897C147.734 734.424 147.827 734.989 148.335 734.236C149.141 732.708 149.781 731.055 150.238 729.32C150.627 727.824 150.501 727.406 149.934 728.358C149.189 729.774 148.589 731.298 148.149 732.897Z" fill="white" fill-opacity="0.6"/>
        <path d="M156.829 718.191C156.406 719.593 156.465 720.126 156.939 719.311C157.634 717.94 158.22 716.49 158.69 714.98C159.248 713.213 159.214 712.658 158.605 713.757C157.901 715.162 157.306 716.647 156.829 718.191Z" fill="white" fill-opacity="0.6"/>
        <path d="M165.966 701.959C166.641 700.582 167.232 699.145 167.734 697.66C168.191 696.258 168.191 695.829 167.734 696.687C166.972 698.171 166.318 699.736 165.779 701.362C165.356 702.617 165.466 702.9 165.966 701.959Z" fill="white" fill-opacity="0.6"/>
        <path d="M175.111 683.1C174.781 684.146 175.001 684.052 175.576 682.933C176.303 681.491 176.936 679.98 177.471 678.415C177.776 677.442 177.522 677.651 176.887 678.906C176.216 680.251 175.622 681.652 175.111 683.1Z" fill="white" fill-opacity="0.6"/>
        <path d="M187.183 659.797C186.494 661.07 185.878 662.402 185.339 663.782C184.958 664.828 185.339 664.462 186.084 663.05C186.741 661.804 187.329 660.504 187.843 659.159C188.148 658.28 187.869 658.542 187.183 659.797Z" fill="white" fill-opacity="0.6"/>
        <path d="M196.464 644.401C196.1 645.29 196.362 645.154 197.005 644.129C197.741 642.938 198.411 641.687 199.01 640.385C199.518 639.224 199.264 639.339 198.461 640.625C197.72 641.821 197.052 643.083 196.464 644.401Z" fill="white" fill-opacity="0.6"/>
        <path d="M210.728 622.499C209.946 623.571 209.247 624.73 208.638 625.961C208.266 626.829 208.79 626.401 209.679 625.135C210.422 624.089 211.087 622.961 211.667 621.767C211.963 621.045 211.565 621.349 210.728 622.499Z" fill="white" fill-opacity="0.6"/>
        <path d="M222.149 609.017C221.836 609.728 222.276 609.551 223.113 608.641C224.045 607.639 224.862 606.485 225.541 605.21C225.829 604.52 225.338 604.771 224.416 605.806C223.557 606.757 222.795 607.836 222.149 609.017Z" fill="white" fill-opacity="0.6"/>
        <path d="M237.275 594.217C236.827 595.023 237.225 595.159 238.121 594.51C239.134 593.727 240.035 592.74 240.786 591.592C241.412 590.546 241.015 590.337 239.94 591.226C238.925 592.035 238.025 593.046 237.275 594.217Z" fill="white" fill-opacity="0.6"/>
        <path d="M255.168 583.434C256.165 583.181 257.044 582.461 257.622 581.425C258.146 580.442 257.943 579.731 257.131 579.815C255.882 580.461 254.792 581.499 253.967 582.827C253.586 583.695 254.136 583.957 255.168 583.434Z" fill="white" fill-opacity="0.6"/>
        <path d="M84.9519 782.757C83.9113 782.934 82.7269 784.744 82.1686 787.003C81.5679 789.482 81.9401 792.013 83.1076 791.616C84.2751 791.218 85.4511 789.011 85.9333 786.689C86.4156 784.367 86.0517 782.568 84.9519 782.757Z" fill="white" fill-opacity="0.6"/>
        <path d="M69.9352 783.761C69.4276 785.936 69.4953 788.3 70.0875 788.75C70.6797 789.2 71.6949 787.641 72.2787 785.361C72.9555 782.683 72.8793 780.205 72.1095 779.964C71.3396 779.723 70.4597 781.512 69.9352 783.761Z" fill="white" fill-opacity="0.6"/>
        <path d="M60.5699 783.834C61.0099 784.378 61.8474 782.945 62.3889 780.696C62.9303 778.447 63.0742 776.042 62.6596 775.351C62.2451 774.661 61.2383 776.251 60.6715 778.751C60.1892 780.895 60.1469 783.311 60.5699 783.834Z" fill="white" fill-opacity="0.6"/>
        <path d="M53.8781 772.412C53.4297 774.504 53.2943 776.868 53.5989 777.475C53.9034 778.081 54.5887 776.784 55.0879 774.63C55.6716 772.109 55.8154 769.588 55.3924 769.16C54.9694 768.731 54.3349 770.321 53.8781 772.412Z" fill="white" fill-opacity="0.6"/>
        <path d="M50.2995 761.953C50.0457 761.493 49.4535 762.832 49.0643 764.84C48.6075 767.047 48.4298 769.703 48.7343 770.07C49.0389 770.436 49.5804 768.877 49.9949 766.733C50.4095 764.589 50.5617 762.434 50.2995 761.953Z" fill="white" fill-opacity="0.6"/>
        <path d="M45.2743 761.42C45.4012 762.12 45.8495 761.075 46.2387 759.14C46.614 757.504 46.788 755.808 46.7548 754.109C46.6279 753.199 46.1118 754.422 45.6888 756.598C45.3554 758.17 45.2156 759.796 45.2743 761.42Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.2524 748.115C42.9819 749.547 42.8766 751.019 42.9393 752.487C43.0832 753.063 43.5146 751.609 43.8699 749.35C44.1567 748.01 44.2398 746.616 44.1153 745.239C43.9799 744.946 43.54 746.264 43.2524 748.115Z" fill="white" fill-opacity="0.6"/>
        <path d="M42.1354 736.506C42.0339 735.889 41.7124 736.778 41.4586 738.367C41.1823 739.919 41.0714 741.508 41.1287 743.095C41.2302 743.639 41.5263 742.666 41.8055 740.857C42.0697 739.431 42.1806 737.968 42.1354 736.506Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.479 729.78C39.2979 731.075 39.258 732.394 39.3606 733.703C39.496 734.288 39.8006 733.096 40.029 731.13C40.2313 729.914 40.2571 728.664 40.1051 727.437C39.9528 727.113 39.6567 728.222 39.479 729.78Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.1103 720.608C36.9681 721.877 36.9681 723.166 37.1103 724.436C37.2457 725.084 37.5079 724.436 37.6772 723.013C37.884 721.63 37.884 720.212 37.6772 718.829C37.5418 718.39 37.2626 719.196 37.1103 720.608Z" fill="white" fill-opacity="0.6"/>
        <path d="M33.9632 715.932C34.1916 716.424 34.4539 715.733 34.5723 714.311C34.7167 713.13 34.6532 711.924 34.3862 710.776C34.1324 710.138 33.7686 711.027 33.684 712.533C33.587 713.678 33.682 714.835 33.9632 715.932Z" fill="white" fill-opacity="0.6"/>
        <path d="M28.7516 704.971C28.6907 706.055 28.8691 707.14 29.2677 708.109C29.5977 708.6 29.9276 707.973 30.0122 706.728C30.1137 705.243 29.8938 703.684 29.4962 703.287C29.0985 702.889 28.8447 703.737 28.7516 704.971Z" fill="white" fill-opacity="0.6"/>
        <path d="M21.8905 698.8C21.8768 699.316 21.9486 699.829 22.1013 700.31C22.2541 700.79 22.4846 701.226 22.7788 701.592C23.2949 701.969 23.701 701.049 23.6248 699.605C23.6248 698.35 23.1933 697.168 22.7196 696.969C22.2458 696.771 21.8736 697.649 21.8905 698.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M13.7352 696.394C14.3781 696.645 14.8604 695.955 14.8773 694.752C14.919 694.228 14.8432 693.7 14.6581 693.226C14.4731 692.752 14.1859 692.35 13.8282 692.064C13.1345 691.698 12.4662 692.587 12.5169 693.842C12.5198 694.363 12.634 694.873 12.848 695.321C13.062 695.769 13.368 696.139 13.7352 696.394Z" fill="white" fill-opacity="0.6"/>
        <path d="M711.936 705.316C713.932 705.044 715.641 703.946 715.912 702.753C716.183 701.561 714.93 700.819 712.985 700.996C710.853 701.195 708.636 702.586 708.552 703.789C708.467 704.992 710.04 705.578 711.936 705.316Z" fill="white" fill-opacity="0.6"/>
        <path d="M679.711 705.661C677.706 705.996 675.777 707.188 675.481 708.287C675.185 709.385 677.063 710.295 679.237 709.834C681.251 709.416 682.96 708.182 683.087 707.073C683.214 705.965 681.775 705.327 679.711 705.661Z" fill="white" fill-opacity="0.6"/>
        <path d="M641.793 713.328C641.616 714.374 643.164 715.043 645.177 714.729C647.461 714.363 649.509 712.972 649.585 711.738C649.661 710.504 648.054 710.138 645.981 710.577C643.908 711.016 641.971 712.292 641.793 713.328Z" fill="white" fill-opacity="0.6"/>
        <path d="M611.422 719.656C613.477 719.342 615.381 718.223 615.888 717.051C616.396 715.88 615.279 715.179 613.291 715.409C611.125 715.65 608.655 717.051 608.342 718.223C608.029 719.394 609.476 719.949 611.422 719.656Z" fill="white" fill-opacity="0.6"/>
        <path d="M575.762 723.024C575.39 724.07 576.676 724.749 578.546 724.477C580.72 724.153 582.894 722.783 583.199 721.57C583.503 720.357 582.107 719.959 580.111 720.367C578.114 720.775 576.118 721.999 575.762 723.024Z" fill="white" fill-opacity="0.6"/>
        <path d="M549.164 724.927C547.353 725.262 545.323 726.392 544.815 727.437C544.308 728.483 545.661 729.352 547.7 728.891C549.578 728.463 551.397 727.26 551.787 726.193C552.176 725.126 551.067 724.582 549.164 724.927Z" fill="white" fill-opacity="0.6"/>
        <path d="M516.051 731.569C515.518 732.615 516.432 733.305 518.039 733.096C519.892 732.855 522.016 731.558 522.515 730.366C523.014 729.174 521.948 728.745 520.205 729.08C518.462 729.414 516.601 730.575 516.051 731.569Z" fill="white" fill-opacity="0.6"/>
        <path d="M491.686 736.558C493.201 736.401 494.636 735.65 495.781 734.414C496.627 733.316 496.272 732.479 494.935 732.51C493.274 732.621 491.694 733.433 490.46 734.811C489.749 735.805 490.333 736.642 491.686 736.558Z" fill="white" fill-opacity="0.6"/>
        <path d="M468.337 737.541C467.719 738.535 468.21 739.288 469.402 739.152C470.878 738.945 472.237 738.06 473.209 736.673C473.768 735.627 473.167 735.041 471.856 735.282C470.518 735.552 469.285 736.344 468.337 737.541Z" fill="white" fill-opacity="0.6"/>
        <path d="M450.985 740.899C452.195 740.684 453.32 740.004 454.2 738.953C455.046 737.907 455.046 737.06 454.157 737.06C452.805 737.249 451.542 737.985 450.562 739.152C449.843 740.093 450.063 740.93 450.985 740.899Z" fill="white" fill-opacity="0.6"/>
        <path d="M433.506 740.867C434.096 740.928 434.69 740.84 435.252 740.608C435.813 740.376 436.33 740.005 436.772 739.518C437.618 738.556 437.5 737.635 436.544 737.374C435.895 737.272 435.236 737.344 434.611 737.584C433.987 737.824 433.411 738.227 432.923 738.765C432.765 738.914 432.649 739.121 432.591 739.356C432.533 739.592 432.537 739.844 432.602 740.076C432.666 740.309 432.788 740.51 432.951 740.652C433.113 740.794 433.308 740.869 433.506 740.867Z" fill="white" fill-opacity="0.6"/>
        <path d="M415.064 734.1C414.912 734.281 414.803 734.509 414.747 734.76C414.692 735.011 414.691 735.276 414.747 735.527C414.802 735.778 414.911 736.006 415.062 736.187C415.213 736.369 415.4 736.497 415.605 736.558C416.146 736.771 416.721 736.819 417.281 736.699C417.84 736.578 418.368 736.293 418.82 735.868C419.725 734.884 419.497 733.713 418.295 733.284C417.742 733.107 417.163 733.089 416.603 733.23C416.043 733.371 415.517 733.669 415.064 734.1Z" fill="white" fill-opacity="0.6"/>
        <path d="M397.788 725.638C397.652 725.853 397.558 726.104 397.512 726.37C397.466 726.637 397.471 726.913 397.524 727.178C397.578 727.442 397.68 727.688 397.823 727.896C397.965 728.104 398.144 728.269 398.346 728.379C398.815 728.706 399.349 728.862 399.887 728.829C400.425 728.796 400.944 728.574 401.384 728.191C402.23 727.281 401.967 725.973 400.817 725.293C400.334 725.003 399.797 724.882 399.263 724.943C398.729 725.004 398.219 725.244 397.788 725.638Z" fill="white" fill-opacity="0.6"/>
        <path d="M381.959 713.84C381.761 714.373 381.729 714.977 381.869 715.538C382.01 716.098 382.313 716.573 382.721 716.874C382.926 717.084 383.164 717.242 383.419 717.337C383.675 717.432 383.943 717.462 384.208 717.426C384.474 717.39 384.73 717.288 384.963 717.127C385.196 716.965 385.4 716.748 385.563 716.487C385.744 715.942 385.744 715.332 385.563 714.788C385.383 714.244 385.036 713.81 384.599 713.579C383.643 712.889 382.382 712.993 381.959 713.84Z" fill="white" fill-opacity="0.6"/>
        <path d="M368.432 698.863C368.234 699.412 368.184 700.024 368.287 700.611C368.391 701.198 368.643 701.728 369.007 702.126C369.853 703.172 371.08 703.172 371.545 702.126C371.734 701.568 371.764 700.948 371.63 700.366C371.496 699.785 371.207 699.277 370.809 698.925C369.997 698.099 368.846 698.047 368.432 698.863Z" fill="white" fill-opacity="0.6"/>
        <path d="M357.569 682.065C356.782 681.019 355.674 680.684 355.158 681.385C354.642 682.086 354.946 684.125 355.826 685.213C356.706 686.301 357.671 686.322 358.136 685.496C358.353 684.925 358.415 684.282 358.313 683.664C358.211 683.046 357.95 682.485 357.569 682.065Z" fill="white" fill-opacity="0.6"/>
        <path d="M344.524 663.521C343.745 662.475 342.713 662.182 342.265 662.977C341.816 663.772 342.172 665.895 343.035 666.962C343.898 668.029 344.786 668.008 345.192 667.108C345.37 666.494 345.402 665.829 345.283 665.193C345.165 664.557 344.901 663.977 344.524 663.521Z" fill="white" fill-opacity="0.6"/>
        <path d="M330.488 648.156C331.241 649.286 332.18 649.474 332.629 648.606C332.848 648.022 332.925 647.373 332.851 646.736C332.778 646.099 332.556 645.502 332.214 645.018C331.436 643.721 330.235 643.449 329.828 644.474C329.679 645.107 329.662 645.776 329.777 646.42C329.892 647.063 330.137 647.66 330.488 648.156Z" fill="white" fill-opacity="0.6"/>
        <path d="M319.939 626.808C319.22 625.763 318.247 625.668 317.781 626.516C317.316 627.363 317.671 629.476 318.483 630.459C319.296 631.442 320.176 631.327 320.539 630.375C320.723 629.771 320.764 629.115 320.658 628.484C320.552 627.852 320.302 627.271 319.939 626.808Z" fill="white" fill-opacity="0.6"/>
        <path d="M306.309 613.985C307.054 614.916 307.951 614.916 308.424 613.985C308.647 613.436 308.729 612.816 308.661 612.208C308.593 611.599 308.378 611.03 308.044 610.576C307.282 609.477 306.098 609.53 305.683 610.576C305.526 611.16 305.501 611.787 305.612 612.388C305.722 612.989 305.963 613.542 306.309 613.985Z" fill="white" fill-opacity="0.6"/>
        <path d="M294.042 600.116C294.185 600.307 294.359 600.457 294.553 600.555C294.747 600.654 294.956 600.699 295.166 600.687C295.375 600.675 295.58 600.606 295.766 600.486C295.952 600.366 296.115 600.197 296.242 599.991C296.493 599.513 296.608 598.947 296.572 598.379C296.535 597.812 296.348 597.275 296.039 596.853C295.89 596.624 295.699 596.442 295.482 596.32C295.265 596.199 295.029 596.142 294.791 596.155C294.553 596.167 294.321 596.248 294.113 596.391C293.905 596.534 293.728 596.735 293.594 596.978C293.4 597.495 293.339 598.073 293.419 598.635C293.499 599.196 293.717 599.713 294.042 600.116Z" fill="white" fill-opacity="0.6"/>
        <path d="M281.048 587.283C280.872 587.758 280.846 588.298 280.976 588.795C281.106 589.293 281.382 589.712 281.75 589.971C281.969 590.092 282.205 590.155 282.445 590.157C282.685 590.16 282.922 590.1 283.142 589.983C283.362 589.866 283.561 589.694 283.724 589.478C283.888 589.261 284.014 589.005 284.093 588.726C284.179 588.488 284.225 588.23 284.229 587.969C284.233 587.709 284.194 587.449 284.115 587.207C284.036 586.965 283.919 586.745 283.77 586.561C283.621 586.376 283.443 586.23 283.247 586.132C282.83 585.977 282.381 586.006 281.98 586.217C281.578 586.427 281.248 586.804 281.048 587.283Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.456 580.317C269.993 580.291 269.533 580.419 269.124 580.687C268.714 580.956 268.371 581.355 268.129 581.844C268.008 582.046 267.933 582.285 267.913 582.535C267.893 582.786 267.929 583.038 268.016 583.265C268.102 583.493 268.237 583.687 268.406 583.827C268.575 583.967 268.772 584.048 268.975 584.061C269.431 584.053 269.876 583.893 270.265 583.599C270.653 583.304 270.971 582.886 271.183 582.388C271.623 581.352 271.31 580.453 270.456 580.317Z" fill="white" fill-opacity="0.6"/>
        <path d="M244.483 592.397C243.457 593.094 242.585 594.093 241.945 595.305C241.548 596.288 242.258 596.351 243.519 595.472C244.517 594.803 245.35 593.813 245.93 592.606C246.175 591.822 245.524 591.707 244.483 592.397Z" fill="white" fill-opacity="0.6"/>
        <path d="M228.748 604.792C227.849 605.665 227.082 606.727 226.48 607.929C226.133 608.818 226.793 608.578 227.927 607.427C228.798 606.597 229.528 605.563 230.076 604.384C230.27 603.714 229.644 603.903 228.748 604.792Z" fill="white" fill-opacity="0.6"/>
        <path d="M212.47 623.723C212.149 624.549 212.555 624.351 213.426 623.284C214.258 622.254 214.988 621.106 215.601 619.863C216.007 618.933 215.482 619.142 214.56 620.313C213.75 621.336 213.048 622.482 212.47 623.723Z" fill="white" fill-opacity="0.6"/>
        <path d="M200.491 641.441C201.261 640.276 201.944 639.026 202.53 637.707C202.953 636.661 202.53 636.86 201.726 638.136C200.97 639.287 200.313 640.531 199.763 641.849C199.416 642.717 199.738 642.592 200.491 641.441Z" fill="white" fill-opacity="0.6"/>
        <path d="M189.958 657.601C189.252 658.856 188.638 660.186 188.122 661.575C187.793 662.621 188.122 662.37 188.782 661.136C189.549 659.786 190.212 658.35 190.762 656.848C190.999 656.053 190.635 656.398 189.958 657.601Z" fill="white" fill-opacity="0.6"/>
        <path d="M177.361 682.138C177.099 683.079 177.437 682.734 178.114 681.364C178.769 680.029 179.335 678.629 179.806 677.18C180.128 676.134 179.739 676.48 179.019 677.934C178.37 679.274 177.815 680.68 177.361 682.138Z" fill="white" fill-opacity="0.6"/>
        <path d="M169.189 697.607C168.526 699.011 167.96 700.48 167.497 702C167.141 703.245 167.336 703.266 167.937 702.053C168.665 700.545 169.282 698.959 169.781 697.315C170.035 696.3 169.781 696.426 169.189 697.607Z" fill="white" fill-opacity="0.6"/>
        <path d="M158.487 721.434C159.158 720.015 159.724 718.523 160.179 716.978C160.602 715.503 160.416 715.273 159.79 716.528C159.111 717.957 158.544 719.463 158.098 721.026C157.734 722.417 157.903 722.584 158.487 721.434Z" fill="white" fill-opacity="0.6"/>
        <path d="M151.203 734.309C151.558 732.929 151.44 732.521 150.923 733.399C150.134 734.936 149.509 736.591 149.062 738.326C148.732 739.717 148.944 739.915 149.528 738.796C150.23 737.389 150.793 735.883 151.203 734.309Z" fill="white" fill-opacity="0.6"/>
        <path d="M142.074 747.875C141.321 749.272 140.737 750.798 140.34 752.404C139.9 754.203 140.137 754.653 140.839 753.45C141.57 752.04 142.139 750.513 142.531 748.91C142.81 747.477 142.616 747.007 142.074 747.875Z" fill="white" fill-opacity="0.6"/>
        <path d="M132.159 762.162C131.241 763.697 130.528 765.402 130.044 767.214C129.553 769.086 129.714 769.798 130.382 768.888C131.39 767.276 132.149 765.451 132.624 763.501C133.022 761.859 132.811 761.221 132.159 762.162Z" fill="white" fill-opacity="0.6"/>
        <path d="M119.867 777.537C118.759 779.034 117.94 780.822 117.472 782.767C116.965 784.943 117.472 785.591 118.53 784.242C119.58 782.723 120.349 780.938 120.78 779.012C121.102 777.339 120.687 776.596 119.867 777.537Z" fill="white" fill-opacity="0.6"/>
        <path d="M103.674 797.839C104.605 797.18 105.814 794.931 106.356 792.881C106.957 790.591 106.542 789.085 105.51 789.921C104.234 791.309 103.314 793.126 102.862 795.151C102.354 797.389 102.701 798.508 103.674 797.839Z" fill="white" fill-opacity="0.6"/>
        <path d="M86.5848 803.999C86.0857 806.29 86.5256 807.723 87.5916 807.378C88.6575 807.033 89.8927 804.983 90.4341 802.849C91.0433 800.475 90.5188 798.582 89.3766 799.115C88.2345 799.648 87.067 801.803 86.5848 803.999Z" fill="white" fill-opacity="0.6"/>
        <path d="M74.0468 800.538C73.3277 800.171 72.2702 801.74 71.7372 803.926C71.2042 806.112 71.3141 808.267 72.0417 808.727C72.7693 809.187 74.0722 807.556 74.5798 805.161C75.0197 803.006 74.7743 800.914 74.0468 800.538Z" fill="white" fill-opacity="0.6"/>
        <path d="M61.2976 804.585C61.8814 804.941 62.7528 803.539 63.2181 801.447C63.7342 799.094 63.5904 796.521 62.9305 796.354C62.2706 796.186 61.4753 797.902 61.0354 800.088C60.5955 802.274 60.697 804.209 61.2976 804.585Z" fill="white" fill-opacity="0.6"/>
        <path d="M53.2859 798.498C53.7004 798.958 54.411 797.661 54.851 795.601C55.3417 793.31 55.3332 790.674 54.851 790.371C54.3687 790.068 53.7004 791.689 53.2859 793.833C52.8713 795.977 52.8459 798.017 53.2859 798.498Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.6769 790.925C47.9984 791.333 48.5229 790.047 48.9459 787.965C49.3689 785.685 49.4197 783.185 49.0474 782.997C48.6752 782.809 48.1253 784.409 47.7615 786.501C47.3977 788.593 47.3639 790.528 47.6769 790.925Z" fill="white" fill-opacity="0.6"/>
        <path d="M44.1068 777.642C43.7837 779.092 43.706 780.609 43.8784 782.098C44.0899 782.621 44.5213 781.522 44.8851 779.525C45.2743 777.37 45.4097 774.849 45.1559 774.525C44.9021 774.201 44.4452 775.655 44.1068 777.642Z" fill="white" fill-opacity="0.6"/>
        <path d="M41.7211 768.02C41.4477 769.458 41.3535 770.939 41.4419 772.412C41.5773 772.988 41.9241 772.005 42.2372 770.132C42.5617 768.534 42.6732 766.88 42.5671 765.237C42.4064 764.84 42.0172 766.137 41.7211 768.02Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.782 755.437C40.6635 754.987 40.359 756.085 40.1136 757.832C39.8516 759.287 39.7689 760.782 39.8683 762.267C39.9868 762.633 40.2998 761.409 40.5366 759.642C40.764 758.259 40.8465 756.846 40.782 755.437Z" fill="white" fill-opacity="0.6"/>
        <path d="M38.5315 751.63C38.6161 752.31 38.8445 751.63 39.0475 750.05C39.2802 748.541 39.3626 747.003 39.2929 745.469C39.1914 744.988 38.9291 745.929 38.7345 747.561C38.5486 748.904 38.4805 750.268 38.5315 751.63Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.0172 741.286C37.1272 741.976 37.3302 741.422 37.4994 740.03C37.6825 738.588 37.7166 737.122 37.6009 735.669C37.474 735.125 37.2371 735.92 37.0848 737.395C36.9352 738.683 36.9125 739.99 37.0172 741.286Z" fill="white" fill-opacity="0.6"/>
        <path d="M34.6739 727.845C34.5617 729.053 34.6073 730.275 34.8092 731.464C34.9784 732.102 35.2069 731.59 35.3253 730.303C35.4702 728.952 35.433 727.581 35.2153 726.245C34.9954 725.754 34.75 726.486 34.6739 727.845Z" fill="white" fill-opacity="0.6"/>
        <path d="M31.9244 721.089C32.0058 719.934 31.8846 718.771 31.569 717.679C31.2729 717.041 30.9345 717.857 30.8922 719.352C30.8244 720.426 30.9726 721.504 31.3236 722.49C31.6028 723.024 31.8567 722.406 31.9244 721.089Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.3677 711.748C25.3132 712.798 25.572 713.837 26.0953 714.667C26.5014 715.085 26.8229 714.478 26.8567 713.255C26.8905 712.031 26.5267 710.263 26.0953 709.97C25.6638 709.677 25.3677 710.504 25.3677 711.748Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.4576 705.63C17.4466 706.143 17.5399 706.651 17.7292 707.108C17.9184 707.565 18.1973 707.956 18.5405 708.245C19.1158 708.548 19.5388 707.91 19.5388 706.738C19.5388 705.567 18.9889 703.967 18.3967 703.778C17.8045 703.59 17.4238 704.427 17.4576 705.63Z" fill="white" fill-opacity="0.6"/>
        <path d="M8.01607 699.323C7.30542 699.208 6.70473 700.086 6.73011 701.226C6.71763 701.815 6.87786 702.389 7.1811 702.843C7.48435 703.296 7.91012 703.599 8.37985 703.695C9.10743 703.695 9.62352 702.806 9.51354 701.687C9.48248 701.153 9.32341 700.643 9.05665 700.222C8.7899 699.801 8.42757 699.488 8.01607 699.323Z" fill="white" fill-opacity="0.6"/>
        <path d="M1.75575 688.623C1.55599 688.594 1.35349 688.616 1.16131 688.69C0.969138 688.763 0.791528 688.886 0.640042 689.05C0.488555 689.213 0.366544 689.414 0.281839 689.64C0.197134 689.865 0.15164 690.11 0.148307 690.359C0.143 690.965 0.309259 691.554 0.618303 692.024C0.927347 692.493 1.35969 692.815 1.84033 692.932C2.03848 692.931 2.23418 692.878 2.41458 692.777C2.59498 692.675 2.75601 692.528 2.88706 692.344C3.0181 692.16 3.1162 691.944 3.17493 691.711C3.23366 691.477 3.25168 691.23 3.22781 690.987C3.24279 690.443 3.10405 689.909 2.83542 689.478C2.56679 689.046 2.18504 688.744 1.75575 688.623Z" fill="white" fill-opacity="0.6"/>
        <path d="M693.636 716.832C695.743 716.57 697.723 715.409 698.036 714.29C698.349 713.171 696.488 712.198 694.237 712.543C692.207 712.899 690.362 714.133 690.168 715.284C689.973 716.434 691.454 717.114 693.636 716.832Z" fill="white" fill-opacity="0.6"/>
        <path d="M656.065 720.116C655.922 721.246 657.52 721.842 659.729 721.497C661.937 721.151 663.874 719.98 664.136 718.84C664.398 717.7 662.444 716.811 660.177 717.282C658.121 717.71 656.218 719.018 656.065 720.116Z" fill="white" fill-opacity="0.6"/>
        <path d="M625.135 726.392C627.276 726.099 629.365 724.938 629.848 723.819C630.33 722.699 628.553 721.727 626.26 722.135C624.196 722.511 622.208 723.745 621.861 724.885C621.514 726.025 622.919 726.684 625.135 726.392Z" fill="white" fill-opacity="0.6"/>
        <path d="M588.114 729.812C587.818 730.931 589.256 731.527 591.414 731.182C593.571 730.837 595.576 729.686 595.982 728.557C596.388 727.427 594.645 726.559 592.412 727.019C590.398 727.437 588.402 728.724 588.114 729.812Z" fill="white" fill-opacity="0.6"/>
        <path d="M555.687 734.403C555.323 735.512 556.626 736.119 558.682 735.794C560.737 735.47 562.725 734.341 563.199 733.221C563.673 732.102 562.091 731.224 559.959 731.663C558.022 732.06 556.05 733.326 555.687 734.403Z" fill="white" fill-opacity="0.6"/>
        <path d="M527.472 740.072C529.266 739.915 531.364 738.869 532.117 737.771C532.87 736.673 531.821 735.617 529.824 735.878C528.039 736.108 526.068 737.206 525.417 738.336C524.765 739.466 525.637 740.24 527.472 740.072Z" fill="white" fill-opacity="0.6"/>
        <path d="M497.693 742.007C497.05 743.137 497.811 743.859 499.495 743.691C501.088 743.556 502.598 742.765 503.784 741.442C504.579 740.261 503.556 739.351 501.779 739.591C500.163 739.832 498.285 740.919 497.693 742.007Z" fill="white" fill-opacity="0.6"/>
        <path d="M473.48 744.831C472.812 745.95 473.37 746.683 474.817 746.578C476.263 746.443 477.629 745.706 478.692 744.486C479.538 743.336 478.692 742.394 477.194 742.583C475.783 742.78 474.471 743.574 473.48 744.831Z" fill="white" fill-opacity="0.6"/>
        <path d="M260.143 587.22C259.665 587.254 259.201 587.434 258.793 587.742C258.384 588.05 258.043 588.478 257.799 588.988C257.343 590.033 257.656 590.839 258.502 590.954C259.022 590.993 259.539 590.843 259.991 590.521C260.442 590.199 260.807 589.72 261.04 589.144C261.135 588.951 261.187 588.728 261.189 588.501C261.192 588.274 261.145 588.05 261.053 587.853C260.961 587.657 260.828 587.494 260.668 587.382C260.508 587.271 260.327 587.215 260.143 587.22Z" fill="white" fill-opacity="0.6"/>
        <path d="M274.237 593.37C274.33 593.14 274.386 592.89 274.4 592.633C274.415 592.377 274.388 592.119 274.322 591.875C274.256 591.631 274.152 591.406 274.015 591.212C273.879 591.018 273.713 590.859 273.527 590.745C273.058 590.551 272.548 590.587 272.099 590.843C271.651 591.1 271.299 591.559 271.115 592.125C271.033 592.359 270.992 592.612 270.996 592.867C270.999 593.123 271.047 593.374 271.136 593.604C271.225 593.834 271.353 594.038 271.512 594.202C271.67 594.365 271.855 594.485 272.055 594.552C272.472 594.717 272.925 594.688 273.326 594.471C273.728 594.253 274.052 593.862 274.237 593.37Z" fill="white" fill-opacity="0.6"/>
        <path d="M283.941 599.834C283.789 600.371 283.791 600.956 283.944 601.493C284.098 602.029 284.396 602.484 284.787 602.783C284.954 602.938 285.147 603.044 285.352 603.094C285.557 603.144 285.768 603.136 285.97 603.072C286.172 603.008 286.36 602.889 286.52 602.723C286.68 602.557 286.807 602.349 286.894 602.114C287.084 601.521 287.102 600.864 286.943 600.257C286.784 599.65 286.458 599.13 286.022 598.788C285.831 598.677 285.624 598.617 285.413 598.61C285.202 598.604 284.992 598.651 284.797 598.749C284.601 598.847 284.425 598.994 284.277 599.181C284.13 599.368 284.016 599.59 283.941 599.834Z" fill="white" fill-opacity="0.6"/>
        <path d="M296.327 611.402C296.188 611.996 296.194 612.628 296.344 613.218C296.495 613.808 296.783 614.329 297.173 614.717C298.019 615.554 298.865 615.45 299.211 614.466C299.381 613.815 299.392 613.116 299.241 612.457C299.091 611.798 298.786 611.209 298.365 610.764C298.211 610.59 298.025 610.467 297.823 610.404C297.621 610.342 297.409 610.342 297.207 610.405C297.005 610.468 296.819 610.592 296.665 610.766C296.512 610.94 296.395 611.159 296.327 611.402Z" fill="white" fill-opacity="0.6"/>
        <path d="M308.458 625.982C308.336 626.615 308.349 627.276 308.498 627.9C308.646 628.524 308.924 629.089 309.304 629.538C310.099 630.532 310.996 630.584 311.326 629.632C311.481 628.938 311.484 628.206 311.335 627.509C311.187 626.813 310.892 626.178 310.48 625.668C309.702 624.863 308.78 625.009 308.458 625.982Z" fill="white" fill-opacity="0.6"/>
        <path d="M320.565 642.644C320.415 643.278 320.401 643.949 320.526 644.591C320.65 645.233 320.909 645.824 321.275 646.305C322.02 647.434 322.967 647.706 323.348 646.911C323.729 646.116 323.433 643.972 322.604 642.842C321.774 641.713 320.92 641.734 320.565 642.644Z" fill="white" fill-opacity="0.6"/>
        <path d="M334.947 661.261C334.245 660.09 333.255 659.724 332.798 660.487C332.341 661.251 332.553 662.83 333.28 664.085C334.008 665.341 335.184 665.916 335.59 664.974C335.996 664.033 335.666 662.464 334.947 661.261Z" fill="white" fill-opacity="0.6"/>
        <path d="M345.082 679.147C344.752 680.078 345.141 681.667 345.928 682.787C346.715 683.906 347.679 684.157 348.068 683.31C348.458 682.462 348.068 680.391 347.155 679.304C346.241 678.216 345.403 678.279 345.082 679.147Z" fill="white" fill-opacity="0.6"/>
        <path d="M358.017 696.907C357.569 697.733 357.865 699.208 358.694 700.348C359.523 701.488 360.547 701.781 361.029 701.101C361.511 700.421 361.224 698.434 360.318 697.315C359.413 696.195 358.449 696.06 358.017 696.907Z" fill="white" fill-opacity="0.6"/>
        <path d="M370.987 713.108C370.769 713.647 370.709 714.262 370.817 714.85C370.925 715.437 371.194 715.96 371.579 716.33C372.382 717.302 373.491 717.491 374.007 716.79C374.523 716.089 374.261 714.217 373.347 713.255C372.433 712.292 371.452 712.303 370.987 713.108Z" fill="white" fill-opacity="0.6"/>
        <path d="M385.182 727.082C384.751 728.002 385.182 729.289 386.232 730.01C386.628 730.378 387.119 730.556 387.613 730.512C388.107 730.468 388.572 730.204 388.922 729.77C389.463 728.829 388.922 727.249 387.814 726.58C386.705 725.91 385.597 726.193 385.182 727.082Z" fill="white" fill-opacity="0.6"/>
        <path d="M401.553 737.562C400.994 738.472 401.383 739.654 402.458 740.198C402.946 740.478 403.488 740.58 404.022 740.493C404.555 740.406 405.057 740.133 405.47 739.706C406.147 738.796 405.656 737.363 404.497 736.84C404.002 736.598 403.461 736.537 402.939 736.665C402.416 736.793 401.935 737.104 401.553 737.562Z" fill="white" fill-opacity="0.6"/>
        <path d="M418.845 744.695C418.346 745.658 418.845 746.63 420.004 746.892C420.554 747.027 421.122 746.985 421.654 746.769C422.186 746.552 422.664 746.17 423.041 745.658C423.667 744.612 423.041 743.44 421.84 743.262C421.284 743.176 420.719 743.262 420.196 743.512C419.674 743.762 419.209 744.169 418.845 744.695Z" fill="white" fill-opacity="0.6"/>
        <path d="M439.64 745.731C438.451 745.787 437.329 746.422 436.518 747.498C435.85 748.544 436.18 749.392 437.237 749.486C437.885 749.502 438.527 749.334 439.113 748.995C439.7 748.656 440.216 748.154 440.621 747.53C441.104 746.547 440.689 745.731 439.64 745.731Z" fill="white" fill-opacity="0.6"/>
        <path d="M453.354 746.881C452.795 747.927 453.278 748.638 454.479 748.492C455.737 748.328 456.898 747.58 457.728 746.4C458.404 745.26 457.787 744.371 456.509 744.612C455.273 744.881 454.154 745.686 453.354 746.881Z" fill="white" fill-opacity="0.6"/>
        <path d="M447.965 731.621C447.161 732.563 447.449 733.337 448.582 733.295C449.844 733.155 451.034 732.504 451.966 731.443C452.575 730.648 452.339 729.99 451.433 729.979C450.162 730.036 448.942 730.613 447.965 731.621Z" fill="white" fill-opacity="0.6"/>
        <path d="M429.818 729.529C428.972 730.408 429.251 731.37 430.376 731.621C430.976 731.724 431.586 731.673 432.169 731.469C432.751 731.266 433.293 730.915 433.76 730.439C434.369 729.697 434.133 728.829 433.253 728.557C432.657 728.413 432.043 728.425 431.452 728.593C430.86 728.76 430.304 729.079 429.818 729.529Z" fill="white" fill-opacity="0.6"/>
        <path d="M412.602 725.879C413.144 726.083 413.722 726.104 414.273 725.941C414.825 725.777 415.331 725.434 415.74 724.948C416.223 724.153 415.859 723.264 414.894 722.856C414.354 722.611 413.771 722.544 413.203 722.662C412.635 722.779 412.101 723.077 411.654 723.526C411.003 724.383 411.434 725.461 412.602 725.879Z" fill="white" fill-opacity="0.6"/>
        <path d="M394.869 713.401C394.133 714.154 394.353 715.399 395.36 716.152C395.822 716.499 396.357 716.67 396.898 716.642C397.438 716.614 397.96 716.389 398.397 715.995C398.518 715.802 398.602 715.579 398.644 715.341C398.686 715.103 398.685 714.856 398.64 714.619C398.596 714.382 398.509 714.16 398.387 713.969C398.264 713.778 398.109 713.623 397.932 713.516C397.489 713.122 396.96 712.901 396.415 712.88C395.869 712.86 395.331 713.041 394.869 713.401Z" fill="white" fill-opacity="0.6"/>
        <path d="M382.551 700.494C381.705 699.542 380.479 699.354 379.87 700.097C379.26 700.839 379.472 702.272 380.343 703.161C380.664 703.548 381.081 703.789 381.526 703.846C381.971 703.902 382.419 703.772 382.797 703.475C383.516 702.806 383.406 701.457 382.551 700.494Z" fill="white" fill-opacity="0.6"/>
        <path d="M366.207 684.084C365.682 684.91 366.004 686.426 366.917 687.462C367.831 688.497 368.981 688.508 369.455 687.661C369.631 687.139 369.665 686.56 369.55 686.012C369.436 685.465 369.18 684.978 368.821 684.627C367.924 683.508 366.731 683.278 366.207 684.084Z" fill="white" fill-opacity="0.6"/>
        <path d="M353.432 669.43C354.278 670.476 355.378 670.643 355.801 669.723C355.961 669.172 355.988 668.576 355.88 668.006C355.772 667.436 355.533 666.918 355.192 666.512C354.346 665.32 353.237 665.037 352.747 665.884C352.256 666.732 352.569 668.321 353.432 669.43Z" fill="white" fill-opacity="0.6"/>
        <path d="M342.375 647.685C341.622 646.451 340.522 646.074 340.014 646.859C339.507 647.643 339.752 649.317 340.547 650.467C341.343 651.618 342.121 651.817 342.671 651.21C343.221 650.603 343.145 648.94 342.375 647.685Z" fill="white" fill-opacity="0.6"/>
        <path d="M327.713 628.179C327.189 629.015 327.409 630.626 328.179 631.766C328.949 632.906 329.981 633.074 330.429 632.185C330.598 631.641 330.648 631.052 330.574 630.477C330.499 629.902 330.304 629.362 330.006 628.911C329.279 627.697 328.238 627.363 327.713 628.179Z" fill="white" fill-opacity="0.6"/>
        <path d="M317.934 611.266C317.214 610.136 316.182 609.917 315.658 610.764C315.412 611.317 315.321 611.954 315.397 612.578C315.474 613.202 315.714 613.777 316.081 614.215C316.749 615.146 317.595 615.345 318.128 614.697C318.661 614.048 318.644 612.385 317.934 611.266Z" fill="white" fill-opacity="0.6"/>
        <path d="M305.751 595.514C305.604 595.313 305.42 595.159 305.215 595.063C305.01 594.966 304.79 594.932 304.572 594.961C304.354 594.991 304.145 595.084 303.961 595.233C303.778 595.382 303.626 595.582 303.518 595.817C303.337 596.364 303.309 596.969 303.438 597.537C303.567 598.105 303.845 598.604 304.228 598.955C304.373 599.107 304.54 599.223 304.721 599.294C304.901 599.365 305.092 599.392 305.281 599.371C305.47 599.351 305.653 599.284 305.821 599.175C305.989 599.066 306.138 598.917 306.259 598.736C306.468 598.203 306.532 597.601 306.44 597.02C306.349 596.439 306.107 595.91 305.751 595.514Z" fill="white" fill-opacity="0.6"/>
        <path d="M291.792 586.446C291.965 586.61 292.162 586.73 292.373 586.799C292.583 586.868 292.803 586.885 293.018 586.848C293.234 586.811 293.442 586.721 293.629 586.584C293.817 586.447 293.98 586.265 294.11 586.049C294.308 585.624 294.387 585.132 294.337 584.646C294.287 584.161 294.109 583.709 293.831 583.36C293.67 583.171 293.479 583.025 293.27 582.931C293.061 582.837 292.838 582.798 292.616 582.815C292.394 582.832 292.177 582.906 291.98 583.031C291.782 583.157 291.607 583.332 291.466 583.545C291.326 583.758 291.222 584.004 291.162 584.269C291.102 584.534 291.087 584.811 291.117 585.083C291.148 585.355 291.223 585.617 291.34 585.852C291.456 586.086 291.61 586.288 291.792 586.446Z" fill="white" fill-opacity="0.6"/>
        <path d="M279.288 578.235C279.721 578.394 280.185 578.378 280.61 578.19C281.036 578.002 281.403 577.652 281.657 577.189C281.757 577.016 281.828 576.818 281.864 576.609C281.9 576.4 281.9 576.184 281.865 575.975C281.83 575.766 281.76 575.567 281.661 575.393C281.561 575.219 281.433 575.072 281.285 574.962C280.862 574.757 280.397 574.731 279.961 574.886C279.526 575.041 279.143 575.369 278.873 575.819C278.727 575.989 278.618 576.203 278.558 576.44C278.498 576.677 278.488 576.929 278.53 577.172C278.571 577.415 278.663 577.64 278.796 577.827C278.929 578.013 279.098 578.154 279.288 578.235Z" fill="white" fill-opacity="0.6"/>
        <path d="M268.171 571.772C267.682 571.778 267.199 571.921 266.76 572.189C266.321 572.457 265.936 572.843 265.633 573.32C265.05 574.25 265.295 574.993 266.158 575.024C266.587 575 267.007 574.87 267.394 574.639C267.781 574.409 268.126 574.085 268.408 573.686C269.094 572.765 269.017 571.876 268.171 571.772Z" fill="white" fill-opacity="0.6"/>
        <path d="M468.675 728.149C467.398 728.392 466.215 729.124 465.291 730.241C464.589 731.224 465.003 731.841 466.238 731.6C467.409 731.303 468.498 730.64 469.402 729.676C470.164 728.713 469.817 727.992 468.675 728.149Z" fill="white" fill-opacity="0.6"/>
        <path d="M486.077 729.289C487.539 729.096 488.921 728.368 490.045 727.197C490.68 726.371 490.299 725.638 489.267 725.68C487.808 725.812 486.417 726.495 485.282 727.636C484.351 728.661 484.707 729.414 486.077 729.289Z" fill="white" fill-opacity="0.6"/>
        <path d="M512.667 722.407C511.018 722.699 509.165 723.787 508.615 724.802C508.065 725.816 508.979 726.339 510.696 725.973C512.084 725.672 513.38 724.909 514.444 723.766C515.163 722.762 514.317 722.124 512.667 722.407Z" fill="white" fill-opacity="0.6"/>
        <path d="M535.56 720.838C535.027 721.884 536.085 722.427 537.954 722.051C539.824 721.674 541.711 720.451 542.066 719.478C542.421 718.505 541.431 718.139 539.866 718.432C538.047 718.704 536.085 719.813 535.56 720.838Z" fill="white" fill-opacity="0.6"/>
        <path d="M569.434 713.84C567.48 714.154 565.458 715.294 564.984 716.34C564.511 717.386 565.695 717.951 567.7 717.553C569.392 717.219 571.202 716.194 571.786 715.252C572.37 714.311 571.296 713.579 569.434 713.84Z" fill="white" fill-opacity="0.6"/>
        <path d="M596.422 711.33C595.728 712.449 596.811 713.14 598.833 712.868C600.855 712.596 602.961 711.414 603.452 710.357C603.867 709.468 602.809 708.82 601.21 708.966C599.231 709.165 597.082 710.221 596.422 711.33Z" fill="white" fill-opacity="0.6"/>
        <path d="M633.384 703.998C631.37 704.249 629.23 705.389 628.765 706.477C628.299 707.565 629.611 708.203 631.717 707.858C633.595 707.554 635.287 706.644 635.897 705.609C636.506 704.573 635.431 703.737 633.384 703.998Z" fill="white" fill-opacity="0.6"/>
        <path d="M661.75 701.446C661.311 702.565 662.673 703.235 664.771 702.91C666.869 702.586 668.806 701.383 669.001 700.327C669.195 699.271 667.96 698.8 666.251 698.999C664.246 699.197 662.182 700.348 661.75 701.446Z" fill="white" fill-opacity="0.6"/>
        <path d="M694.499 696.844C694.389 697.942 695.954 698.497 698.027 698.078C700.1 697.66 701.75 696.394 701.69 695.369C701.631 694.344 700.261 693.968 698.501 694.25C696.454 694.585 694.618 695.767 694.499 696.844Z" fill="white" fill-opacity="0.6"/>
        <path d="M6.75551 682.577C6.75367 683.106 6.89151 683.621 7.14806 684.044C7.40461 684.467 7.76583 684.775 8.17682 684.92C8.92132 685.067 9.47972 684.24 9.4205 683.079C9.40466 682.616 9.27689 682.169 9.05372 681.796C8.83055 681.423 8.52218 681.142 8.16835 680.988C7.9865 680.946 7.79945 680.958 7.62172 681.021C7.444 681.084 7.28038 681.196 7.14367 681.35C7.00695 681.504 6.90081 681.695 6.83348 681.908C6.76616 682.121 6.73948 682.35 6.75551 682.577Z" fill="white" fill-opacity="0.6"/>
        <path d="M18.8111 688.999C19.3779 689.209 19.8094 688.34 19.7586 687.138C19.7761 686.684 19.697 686.233 19.5292 685.829C19.3615 685.426 19.1109 685.083 18.8026 684.837C18.2104 684.544 17.7197 685.297 17.7282 686.489C17.6987 686.991 17.7847 687.494 17.9763 687.938C18.168 688.382 18.4574 688.75 18.8111 688.999Z" fill="white" fill-opacity="0.6"/>
        <path d="M26.8904 689.941C26.4421 689.606 26.0444 690.38 26.0444 691.646C26.0444 692.911 26.3744 694.135 26.8059 694.396C27.2373 694.658 27.5165 693.936 27.5757 692.869C27.6349 691.803 27.3135 690.255 26.8904 689.941Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.8803 695.997C32.5504 695.662 32.2204 696.561 32.1697 697.942C32.1189 699.323 32.3389 700.63 32.6604 700.881C32.9819 701.132 33.2187 700.243 33.3033 699.124C33.3964 697.817 33.2018 696.31 32.8803 695.997Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.8227 708.067C37.0427 708.506 37.3473 707.607 37.4826 706.142C37.615 705.097 37.5718 704.029 37.3557 703.004C37.1357 702.461 36.8058 703.214 36.6619 704.647C36.5032 705.787 36.5582 706.957 36.8227 708.067Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.5959 710.378C40.4352 709.761 40.1222 710.567 39.9107 712.125C39.7058 713.369 39.6857 714.648 39.8514 715.901C40.0037 716.361 40.2491 715.723 40.4521 714.353C40.6864 713.048 40.735 711.703 40.5959 710.378Z" fill="white" fill-opacity="0.6"/>
        <path d="M42.5163 720.116C42.2662 721.435 42.1947 722.795 42.3048 724.143C42.4401 724.634 42.787 723.526 43.0408 721.8C43.2557 720.588 43.3241 719.344 43.2438 718.108C43.1339 717.459 42.7701 718.38 42.5163 720.116Z" fill="white" fill-opacity="0.6"/>
        <path d="M44.8257 728.253C44.5326 729.616 44.438 731.033 44.5465 732.437C44.7073 732.855 45.0965 731.6 45.3926 729.644C45.6493 728.398 45.7379 727.108 45.6548 725.827C45.5195 725.293 45.1134 726.392 44.8257 728.253Z" fill="white" fill-opacity="0.6"/>
        <path d="M48.3791 733.556C48.2268 732.908 47.7784 734.027 47.3892 736.004C47.0297 737.477 46.9005 739.021 47.0085 740.553C47.1608 741.003 47.533 740.093 47.9053 738.357C48.2917 736.804 48.4521 735.178 48.3791 733.556Z" fill="white" fill-opacity="0.6"/>
        <path d="M50.2912 748.356C50.5534 748.733 51.1371 747.247 51.5432 744.999C51.9493 742.75 52.0424 740.815 51.7463 740.668C51.4502 740.522 50.968 741.965 50.6042 743.806C50.1812 745.877 50.0373 747.99 50.2912 748.356Z" fill="white" fill-opacity="0.6"/>
        <path d="M55.0709 750.636C54.5717 752.885 54.4448 755.05 54.7748 755.458C55.1047 755.866 55.8154 754.224 56.2722 751.996C56.6699 750.071 56.7883 748.053 56.526 747.582C56.2638 747.111 55.5531 748.419 55.0709 750.636Z" fill="white" fill-opacity="0.6"/>
        <path d="M61.0691 761.765C61.5513 761.943 62.3888 760.029 62.8541 757.644C63.2602 755.552 63.2602 753.732 62.8541 753.46C62.448 753.188 61.5851 754.809 61.0775 757.173C60.5699 759.537 60.5699 761.587 61.0691 761.765Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.4749 758.407C70.8996 757.937 69.9606 759.537 69.3853 761.943C68.81 764.348 68.81 766.566 69.4276 766.869C70.0452 767.172 70.7558 765.823 71.3311 763.731C71.9825 761.305 72.0418 758.878 71.4749 758.407Z" fill="white" fill-opacity="0.6"/>
        <path d="M80.0873 766.367C79.5797 768.867 79.8504 770.781 80.7049 770.697C81.5594 770.613 82.6761 768.344 83.0907 765.949C83.4629 763.784 83.2007 762.089 82.4985 761.995C81.7963 761.901 80.578 763.909 80.0873 766.367Z" fill="white" fill-opacity="0.6"/>
        <path d="M249.314 581.08C248.866 581.886 249.424 581.865 250.549 581.08C251.604 580.353 252.535 579.38 253.29 578.214C253.62 577.545 253.13 577.535 252.157 578.214C251.076 578.948 250.113 579.92 249.314 581.08Z" fill="white" fill-opacity="0.6"/>
        <path d="M233.02 594.772C232.656 595.483 233.172 595.242 234.196 594.238C235.105 593.377 235.917 592.367 236.607 591.236C236.861 590.692 236.395 590.891 235.533 591.717C234.592 592.597 233.747 593.623 233.02 594.772Z" fill="white" fill-opacity="0.6"/>
        <path d="M218.545 610.597C218.156 611.35 218.545 611.057 219.475 609.959C220.297 608.97 221.044 607.892 221.709 606.737C221.996 606.151 221.633 606.381 220.863 607.302C220.012 608.312 219.236 609.415 218.545 610.597Z" fill="white" fill-opacity="0.6"/>
        <path d="M206.337 627.352C207.095 626.252 207.79 625.088 208.418 623.869C208.756 623.148 208.333 623.556 207.572 624.738C206.876 625.755 206.234 626.827 205.651 627.949C205.212 628.785 205.567 628.503 206.337 627.352Z" fill="white" fill-opacity="0.6"/>
        <path d="M194.569 645.667C195.284 644.475 195.937 643.227 196.523 641.933C196.862 641.127 196.523 641.483 195.787 642.707C195.133 643.809 194.531 644.958 193.985 646.148C193.587 647.079 193.858 646.848 194.569 645.667Z" fill="white" fill-opacity="0.6"/>
        <path d="M183.96 664.138C184.642 662.87 185.258 661.549 185.804 660.184C186.101 659.379 185.906 659.525 185.348 660.508C184.642 661.785 184.009 663.12 183.452 664.504C183.055 665.55 183.292 665.372 183.96 664.138Z" fill="white" fill-opacity="0.6"/>
        <path d="M174.206 682.201C174.885 680.861 175.488 679.463 176.008 678.017C176.321 677.086 176.177 677.076 175.669 678.017C174.961 679.348 174.342 680.747 173.816 682.201C173.419 683.414 173.579 683.404 174.206 682.201Z" fill="white" fill-opacity="0.6"/>
        <path d="M165.094 699.145C165.8 697.769 166.403 696.316 166.896 694.804C167.302 693.549 167.141 693.413 166.549 694.522C165.901 695.797 165.335 697.133 164.857 698.517C164.426 699.919 164.527 700.243 165.094 699.145Z" fill="white" fill-opacity="0.6"/>
        <path d="M156.313 713.087C155.856 714.625 155.974 715.022 156.558 713.987C157.284 712.564 157.886 711.049 158.351 709.468C158.698 708.255 158.614 707.899 158.148 708.663C157.391 710.039 156.775 711.525 156.313 713.087Z" fill="white" fill-opacity="0.6"/>
        <path d="M149.968 720.252C149.152 721.522 148.517 722.954 148.089 724.488C147.565 726.235 147.582 727.134 148.089 726.423C148.803 725.245 149.373 723.945 149.781 722.563C150.374 720.754 150.458 719.708 149.968 720.252Z" fill="white" fill-opacity="0.6"/>
        <path d="M140.535 732.008C139.566 733.452 138.816 735.1 138.318 736.872C137.726 738.88 137.81 739.748 138.479 738.869C139.321 737.556 139.987 736.083 140.45 734.508C141.093 732.489 141.144 731.339 140.535 732.008Z" fill="white" fill-opacity="0.6"/>
        <path d="M128.877 744.172C128.09 744.643 126.88 746.871 126.288 748.994C125.696 751.117 125.941 752.31 126.77 751.63C127.835 750.459 128.632 748.962 129.08 747.289C129.757 745.166 129.68 743.744 128.877 744.172Z" fill="white" fill-opacity="0.6"/>
        <path d="M111.787 758.47C111.161 760.834 111.466 762.267 112.439 761.66C113.411 761.054 114.672 758.523 115.197 756.368C115.653 754.454 115.408 753.125 114.638 753.45C113.716 753.847 112.413 756.127 111.787 758.47Z" fill="white" fill-opacity="0.6"/>
        <path d="M95.4171 765.426C94.7402 767.936 95.0787 769.745 96.1362 769.463C97.1937 769.18 98.5642 766.817 99.1311 764.453C99.6133 762.455 99.3172 760.729 98.4881 760.792C97.4729 760.907 96.0854 762.947 95.4171 765.426Z" fill="white" fill-opacity="0.6"/>
        <path d="M464.022 724.54C465.328 724.247 466.547 723.525 467.55 722.448C468.345 721.57 468.396 720.942 467.507 721.015C466.181 721.321 464.94 722.041 463.903 723.107C463.066 724.007 463.125 724.634 464.022 724.54Z" fill="white" fill-opacity="0.6"/>
        <path d="M445.951 725.482C447.258 725.453 448.517 724.874 449.521 723.839C450.156 723.055 449.927 722.312 449.014 722.239C447.707 722.262 446.444 722.825 445.427 723.839C444.767 724.624 444.987 725.419 445.951 725.482Z" fill="white" fill-opacity="0.6"/>
        <path d="M427.035 719.917C426.265 720.649 426.349 721.591 427.204 722.009C427.8 722.202 428.423 722.232 429.029 722.097C429.636 721.961 430.211 721.664 430.715 721.225C431.561 720.367 431.332 719.342 430.224 719.049C429.11 718.83 427.971 719.14 427.035 719.917Z" fill="white" fill-opacity="0.6"/>
        <path d="M411.993 711.748C411.452 711.509 410.869 711.46 410.308 711.606C409.746 711.752 409.228 712.087 408.812 712.575C408.295 713.38 408.702 714.395 409.658 714.792C410.155 714.999 410.686 715.054 411.205 714.952C411.723 714.85 412.214 714.594 412.636 714.206C413.431 713.328 413.118 712.198 411.993 711.748Z" fill="white" fill-opacity="0.6"/>
        <path d="M395.36 700.714C394.906 700.367 394.37 700.22 393.84 700.298C393.31 700.376 392.817 700.674 392.441 701.143C392.334 701.366 392.268 701.616 392.247 701.874C392.227 702.132 392.253 702.393 392.323 702.638C392.393 702.882 392.506 703.105 392.654 703.289C392.801 703.474 392.98 703.616 393.177 703.705C393.596 704.007 394.077 704.151 394.561 704.119C395.045 704.088 395.511 703.882 395.901 703.527C396.612 702.701 396.358 701.404 395.36 700.714Z" fill="white" fill-opacity="0.6"/>
        <path d="M378.541 689.083C378.866 689.571 379.328 689.89 379.835 689.975C380.341 690.06 380.855 689.906 381.274 689.543C381.417 689.353 381.529 689.13 381.601 688.886C381.674 688.642 381.707 688.383 381.699 688.123C381.69 687.863 381.64 687.608 381.551 687.373C381.462 687.137 381.336 686.926 381.181 686.751C380.851 686.268 380.388 685.954 379.882 685.869C379.376 685.784 378.862 685.934 378.44 686.291C378.292 686.478 378.176 686.701 378.101 686.946C378.025 687.191 377.991 687.452 378.001 687.714C378.01 687.976 378.063 688.232 378.156 688.468C378.249 688.703 378.38 688.913 378.541 689.083Z" fill="white" fill-opacity="0.6"/>
        <path d="M364.32 668.865C364.058 669.346 363.944 669.928 364 670.504C364.056 671.08 364.278 671.612 364.625 672.003C365.42 673.112 366.723 673.405 367.298 672.599C367.543 672.111 367.646 671.535 367.591 670.964C367.535 670.394 367.325 669.864 366.993 669.461C366.241 668.416 364.921 668.112 364.32 668.865Z" fill="white" fill-opacity="0.6"/>
        <path d="M351.258 653.27C352.019 654.421 353.254 654.714 353.796 653.877C354.011 653.358 354.095 652.771 354.04 652.192C353.984 651.613 353.79 651.067 353.483 650.624C352.764 649.484 351.52 649.191 350.945 650.039C350.717 650.55 350.625 651.137 350.681 651.717C350.737 652.296 350.939 652.84 351.258 653.27Z" fill="white" fill-opacity="0.6"/>
        <path d="M340.632 631.212C339.921 630.093 338.745 629.915 338.221 630.825C338.025 631.363 337.956 631.959 338.022 632.544C338.088 633.128 338.286 633.678 338.593 634.13C339.236 635.082 340.285 635.406 340.818 634.799C341.351 634.193 341.351 632.373 340.632 631.212Z" fill="white" fill-opacity="0.6"/>
        <path d="M326.131 612.176C325.863 612.632 325.719 613.181 325.719 613.745C325.719 614.308 325.863 614.858 326.131 615.314C326.766 616.506 327.908 616.872 328.5 616.077C328.78 615.531 328.907 614.885 328.861 614.241C328.815 613.596 328.599 612.988 328.246 612.511C328.129 612.311 327.98 612.142 327.808 612.017C327.636 611.891 327.446 611.809 327.247 611.778C327.049 611.747 326.848 611.766 326.656 611.834C326.464 611.903 326.286 612.019 326.131 612.176Z" fill="white" fill-opacity="0.6"/>
        <path d="M314.101 595.451C313.84 595.892 313.703 596.426 313.711 596.972C313.718 597.517 313.87 598.045 314.143 598.474C314.257 598.718 314.414 598.926 314.601 599.085C314.789 599.244 315.003 599.349 315.228 599.392C315.453 599.435 315.683 599.416 315.9 599.335C316.118 599.254 316.319 599.113 316.487 598.924C316.769 598.41 316.899 597.791 316.856 597.172C316.813 596.554 316.599 595.972 316.25 595.525C316.116 595.338 315.953 595.187 315.771 595.082C315.588 594.977 315.39 594.919 315.188 594.912C314.987 594.905 314.786 594.949 314.599 595.042C314.412 595.135 314.243 595.274 314.101 595.451Z" fill="white" fill-opacity="0.6"/>
        <path d="M304.144 581.154C303.955 580.999 303.741 580.897 303.517 580.854C303.293 580.81 303.065 580.827 302.846 580.902C302.628 580.978 302.426 581.11 302.253 581.29C302.079 581.471 301.94 581.695 301.843 581.948C301.653 582.407 301.607 582.936 301.713 583.434C301.819 583.932 302.07 584.364 302.418 584.647C302.744 584.936 303.149 585.054 303.545 584.975C303.941 584.897 304.296 584.629 304.533 584.229C304.805 583.755 304.917 583.168 304.845 582.593C304.772 582.019 304.52 581.502 304.144 581.154Z" fill="white" fill-opacity="0.6"/>
        <path d="M289.728 571.531C289.598 571.694 289.498 571.89 289.436 572.105C289.373 572.32 289.349 572.549 289.365 572.777C289.381 573.005 289.437 573.225 289.529 573.424C289.621 573.622 289.746 573.794 289.897 573.926C290.296 574.198 290.762 574.281 291.207 574.16C291.652 574.039 292.047 573.721 292.317 573.267C292.458 573.076 292.561 572.847 292.618 572.598C292.675 572.349 292.685 572.087 292.646 571.832C292.608 571.578 292.522 571.338 292.396 571.131C292.27 570.925 292.106 570.757 291.919 570.642C291.524 570.487 291.101 570.489 290.707 570.649C290.314 570.809 289.971 571.117 289.728 571.531Z" fill="white" fill-opacity="0.6"/>
        <path d="M276.894 566.427C276.437 567.222 276.631 568.079 277.274 568.236C277.753 568.271 278.23 568.158 278.664 567.908C279.098 567.657 279.475 567.278 279.762 566.803C280.193 565.998 280.024 565.109 279.389 564.931C278.907 564.912 278.427 565.039 277.993 565.299C277.558 565.56 277.181 565.947 276.894 566.427Z" fill="white" fill-opacity="0.6"/>
        <path d="M482.414 722.208C483.809 721.911 485.101 721.11 486.128 719.907C486.686 719.07 486.255 718.516 485.206 718.714C483.82 719.018 482.535 719.814 481.509 721.005C480.9 721.884 481.365 722.469 482.414 722.208Z" fill="white" fill-opacity="0.6"/>
        <path d="M503.251 717.804C502.464 718.756 502.786 719.394 504.004 719.29C505.54 719.08 506.992 718.312 508.175 717.083C509.021 716.037 508.42 715.378 506.931 715.671C505.574 715.967 504.304 716.704 503.251 717.804Z" fill="white" fill-opacity="0.6"/>
        <path d="M529.706 715.462C531.398 715.137 533.403 713.934 533.936 712.941C534.469 711.947 533.555 711.55 532.193 711.801C530.569 712.094 528.623 713.265 528.014 714.321C527.481 715.179 528.31 715.733 529.706 715.462Z" fill="white" fill-opacity="0.6"/>
        <path d="M557.345 711.163C559.096 710.964 561.228 709.845 561.913 708.768C562.599 707.69 561.913 707.188 560.407 707.314C558.726 707.483 557.134 708.323 555.873 709.709C555.188 710.703 555.814 711.33 557.345 711.163Z" fill="white" fill-opacity="0.6"/>
        <path d="M585.61 705.065C585.136 705.996 586.092 706.613 587.657 706.383C589.485 706.111 591.684 704.845 592.15 703.81C592.615 702.774 591.608 702.293 590.085 702.502C588.359 702.733 586.16 703.988 585.61 705.065Z" fill="white" fill-opacity="0.6"/>
        <path d="M619.526 701.289C621.574 700.86 623.511 699.605 623.697 698.57C623.883 697.534 622.606 697.179 620.981 697.524C619.036 697.9 617.132 699.145 616.819 700.243C616.565 701.132 617.868 701.645 619.526 701.289Z" fill="white" fill-opacity="0.6"/>
        <path d="M651.268 696.332C653.248 696.112 655.329 694.94 655.752 693.821C656.175 692.702 655.168 692.2 653.502 692.347C651.674 692.503 649.559 693.685 649.043 694.846C648.527 696.007 649.551 696.52 651.268 696.332Z" fill="white" fill-opacity="0.6"/>
        <path d="M685.455 687.368C683.916 687.535 681.953 688.592 681.403 689.575C680.743 690.725 681.911 691.593 683.78 691.363C685.396 691.165 687.359 690.108 687.867 689.167C688.467 688.048 687.308 687.18 685.455 687.368Z" fill="white" fill-opacity="0.6"/>
        <path d="M716.445 682.787C714.55 683.163 712.976 684.418 713.002 685.537C713.002 686.479 714.457 686.97 716.14 686.583C717.824 686.196 719.347 685.182 719.524 684.199C719.77 683.08 718.331 682.4 716.445 682.787Z" fill="white" fill-opacity="0.6"/>
        <path d="M1.67956 670.12C1.47403 670.115 1.27007 670.166 1.08243 670.27C0.894788 670.373 0.72815 670.527 0.594566 670.721C0.460981 670.914 0.363771 671.141 0.309996 671.387C0.25622 671.632 0.247209 671.889 0.283609 672.139C0.333039 672.622 0.500415 673.075 0.763417 673.438C1.02642 673.8 1.37259 674.055 1.75572 674.168C1.93338 674.174 2.1101 674.135 2.27565 674.056C2.44119 673.976 2.59221 673.856 2.71983 673.703C2.84744 673.55 2.94912 673.367 3.01891 673.165C3.08869 672.963 3.12519 672.746 3.12626 672.526C3.14725 671.98 3.01458 671.442 2.75081 671.003C2.48704 670.565 2.10847 670.253 1.67956 670.12Z" fill="white" fill-opacity="0.6"/>
        <path d="M12.8046 674.702C12.7707 675.176 12.8408 675.652 13.0076 676.081C13.1743 676.51 13.4315 676.875 13.7521 677.139C14.3951 677.505 15.0042 676.783 15.038 675.622C15.0718 674.461 14.5473 673.216 13.9044 673.059C13.2614 672.903 12.8469 673.656 12.8046 674.702Z" fill="white" fill-opacity="0.6"/>
        <path d="M22.5505 678.843C22.5075 679.312 22.5506 679.787 22.6765 680.233C22.8025 680.678 23.0082 681.083 23.2781 681.416C23.7688 681.762 24.2511 680.925 24.2764 679.701C24.3018 678.477 23.9042 677.149 23.4304 677.034C22.9567 676.919 22.5844 677.703 22.5505 678.843Z" fill="white" fill-opacity="0.6"/>
        <path d="M30.8753 681.95C30.5707 681.605 30.1646 682.295 30.0293 683.383C29.8432 684.497 29.9905 685.656 30.4438 686.646C30.7568 686.991 31.1375 686.332 31.2898 685.265C31.4764 684.135 31.3293 682.958 30.8753 681.95Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.672 689.282C35.5208 690.347 35.5788 691.441 35.8412 692.472C36.095 693.058 36.4757 692.273 36.628 690.83C36.7803 689.386 36.6703 687.692 36.3827 687.431C36.095 687.169 35.8073 688.006 35.672 689.282Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.9443 695.516C39.7691 696.667 39.7691 697.848 39.9443 698.999C40.1305 699.626 40.4858 698.758 40.6973 697.189C40.9088 695.62 40.9088 693.8 40.6973 693.476C40.4858 693.152 40.1305 694.125 39.9443 695.516Z" fill="white" fill-opacity="0.6"/>
        <path d="M44.0475 699.877C43.8614 699.532 43.4637 700.923 43.2015 702.659C43.0035 703.787 42.9748 704.95 43.1168 706.09C43.2776 706.383 43.616 705.368 43.8529 703.904C44.1306 702.59 44.1968 701.222 44.0475 699.877Z" fill="white" fill-opacity="0.6"/>
        <path d="M46.2048 708.914C45.9323 710.239 45.8182 711.606 45.8664 712.972C45.9764 713.673 46.3909 712.596 46.7124 710.723C47.0339 708.851 47.2031 706.749 47.017 706.425C46.8309 706.1 46.5178 707.261 46.2048 708.914Z" fill="white" fill-opacity="0.6"/>
        <path d="M48.6836 720.22C48.8613 720.639 49.3858 719.08 49.7581 717.083C50.0544 715.752 50.1659 714.37 50.088 712.993C49.9103 712.564 49.3858 714.154 49.0136 716.215C48.7205 717.517 48.609 718.872 48.6836 720.22Z" fill="white" fill-opacity="0.6"/>
        <path d="M51.9916 727.082C52.2115 727.479 52.8376 725.764 53.2522 723.599C53.6244 721.685 53.7936 719.666 53.6075 719.3C53.4214 718.934 52.7615 720.618 52.3385 722.835C51.9747 724.708 51.8054 726.747 51.9916 727.082Z" fill="white" fill-opacity="0.6"/>
        <path d="M56.6699 728.578C56.2046 730.669 55.9846 732.761 56.1792 733.347C56.3738 733.933 57.0844 732.573 57.609 730.282C58.1335 727.992 58.3365 725.534 58.0235 725.262C57.7105 724.99 57.1606 726.527 56.6699 728.578Z" fill="white" fill-opacity="0.6"/>
        <path d="M63.9203 730.69C63.5227 730.408 62.6767 732.468 62.1521 734.958C61.7207 737.05 61.6192 739.141 61.9576 739.382C62.296 739.623 63.0574 737.991 63.5565 735.941C64.1318 733.556 64.2841 730.973 63.9203 730.69Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.6103 735.397C71.0858 735.198 70.1044 737.405 69.5545 739.999C69.0976 742.091 69.0976 744.277 69.5545 744.413C70.0113 744.549 70.9081 742.813 71.4411 740.668C72.0418 738.179 72.1348 735.596 71.6103 735.397Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.326 743.681C78.7677 746.086 78.7846 748.126 79.3683 748.398C79.9521 748.67 81.1957 746.547 81.7625 744.005C82.2701 741.735 82.2448 739.57 81.7033 739.246C81.1619 738.922 79.9352 741.034 79.326 743.681Z" fill="white" fill-opacity="0.6"/>
        <path d="M262.892 567.933C262.385 568.728 262.757 568.885 263.738 568.31C264.741 567.859 265.653 567.146 266.412 566.218C266.877 565.444 266.589 564.816 266.04 565.015C264.832 565.696 263.759 566.691 262.892 567.933Z" fill="white" fill-opacity="0.6"/>
        <path d="M245.185 580.85C244.737 581.551 245.067 581.551 245.938 580.85C246.977 579.998 247.929 578.993 248.773 577.859C249.196 577.189 248.849 577.179 248.011 577.859C246.973 578.708 246.024 579.713 245.185 580.85Z" fill="white" fill-opacity="0.6"/>
        <path d="M230.194 595.556C231.111 594.602 231.961 593.552 232.732 592.418C233.088 591.822 232.732 591.948 232.013 592.711C231.096 593.665 230.247 594.715 229.475 595.849C229.12 596.476 229.45 596.34 230.194 595.556Z" fill="white" fill-opacity="0.6"/>
        <path d="M217.86 608.923C217.038 609.968 216.28 611.087 215.592 612.27C215.262 612.887 215.592 612.646 216.252 611.789C217.005 610.806 217.716 609.776 218.384 608.703C218.892 607.835 218.638 607.94 217.86 608.923Z" fill="white" fill-opacity="0.6"/>
        <path d="M205.499 625.679C204.788 626.731 204.124 627.831 203.511 628.974C202.936 630.019 203.029 630.155 203.697 629.183C204.366 628.21 205.229 626.746 205.694 625.878C206.252 624.842 206.159 624.748 205.499 625.679Z" fill="white" fill-opacity="0.6"/>
        <path d="M194.205 643.052C193.476 644.256 192.82 645.525 192.242 646.848C191.912 647.633 192.149 647.424 192.758 646.399C193.368 645.374 194.171 643.826 194.577 642.926C194.983 642.027 194.882 641.933 194.205 643.052Z" fill="white" fill-opacity="0.6"/>
        <path d="M182.666 663.636C183.371 662.338 184.01 660.986 184.578 659.588C184.908 658.709 184.764 658.709 184.256 659.588C183.534 660.882 182.892 662.242 182.336 663.657C181.98 664.598 182.141 664.577 182.666 663.636Z" fill="white" fill-opacity="0.6"/>
        <path d="M173.419 678.906C172.928 680.224 172.861 680.768 173.258 680.14C173.979 678.84 174.613 677.469 175.153 676.04C175.686 674.628 175.695 674.158 175.153 675.068C174.5 676.293 173.92 677.576 173.419 678.906Z" fill="white" fill-opacity="0.6"/>
        <path d="M166.702 688.905C165.938 690.3 165.294 691.79 164.781 693.351C164.367 694.616 164.383 695.17 164.781 694.47C165.433 693.216 165.999 691.897 166.473 690.527C167.099 688.947 167.184 688.152 166.702 688.905Z" fill="white" fill-opacity="0.6"/>
        <path d="M156.558 706.77C157.357 705.383 158.008 703.875 158.495 702.283C158.935 700.85 158.994 699.992 158.614 700.463C157.794 701.835 157.138 703.346 156.668 704.95C156.202 706.435 156.152 707.303 156.558 706.77Z" fill="white" fill-opacity="0.6"/>
        <path d="M148.039 716.926C148.964 715.567 149.676 714.006 150.137 712.324C150.67 710.619 150.763 709.562 150.331 709.908C149.9 710.253 148.842 712.502 148.199 714.562C147.658 716.382 147.599 717.396 148.039 716.926Z" fill="white" fill-opacity="0.6"/>
        <path d="M137.887 726.527C137.286 728.462 137.21 729.665 137.717 729.299C138.225 728.933 139.409 726.444 140.069 724.321C140.729 722.197 140.746 720.911 140.069 721.695C139.131 723.146 138.393 724.779 137.887 726.527Z" fill="white" fill-opacity="0.6"/>
        <path d="M124.951 738.064C125.873 737.374 127.142 734.78 127.658 732.562C128.098 730.638 127.861 729.331 127.125 729.801C126.279 730.366 124.951 732.939 124.384 735.376C123.911 737.342 124.198 738.618 124.951 738.064Z" fill="white" fill-opacity="0.6"/>
        <path d="M109.046 742.08C108.429 744.444 108.606 746.107 109.435 745.919C110.264 745.731 111.796 743.241 112.439 740.689C113.082 738.137 112.794 736.338 111.753 736.945C110.713 737.552 109.664 739.717 109.046 742.08Z" fill="white" fill-opacity="0.6"/>
        <path d="M94.7403 741.662C93.8943 741.589 92.6083 743.43 91.9569 745.647C91.1786 748.272 91.1701 750.971 92.2022 750.95C93.099 750.95 94.4949 748.942 95.1548 746.766C95.9839 744.172 95.7978 741.756 94.7403 741.662Z" fill="white" fill-opacity="0.6"/>
        <path d="M465.705 714.719C464.469 714.855 463.283 715.385 462.262 716.256C461.416 717.041 461.484 717.773 462.381 717.783C463.603 717.621 464.77 717.065 465.765 716.173C466.729 715.315 466.695 714.677 465.705 714.719Z" fill="white" fill-opacity="0.6"/>
        <path d="M447.085 715.713C447.855 714.918 447.576 714.206 446.45 714.102C445.246 714.029 444.056 714.452 443.066 715.305C442.389 716.068 442.711 716.874 443.735 716.989C444.938 717.069 446.126 716.616 447.085 715.713Z" fill="white" fill-opacity="0.6"/>
        <path d="M424.522 709.803C423.794 710.472 423.972 711.508 424.877 711.895C425.419 712.089 425.988 712.136 426.546 712.033C427.104 711.93 427.637 711.68 428.109 711.299C428.955 710.556 428.803 709.678 427.788 709.27C427.242 709.084 426.672 709.036 426.111 709.127C425.55 709.219 425.01 709.449 424.522 709.803Z" fill="white" fill-opacity="0.6"/>
        <path d="M406.764 700.703C406.147 701.467 406.443 702.555 407.407 703.078C407.886 703.337 408.413 703.433 408.932 703.356C409.451 703.279 409.944 703.031 410.36 702.638C411.036 701.906 410.749 700.892 409.725 700.348C409.248 700.09 408.726 699.988 408.207 700.05C407.689 700.112 407.192 700.337 406.764 700.703Z" fill="white" fill-opacity="0.6"/>
        <path d="M391.578 690.516C391.968 690.867 392.44 691.051 392.922 691.042C393.404 691.032 393.871 690.829 394.252 690.464C394.895 689.753 394.692 688.581 393.812 687.849C393.424 687.499 392.956 687.31 392.475 687.31C391.995 687.31 391.526 687.499 391.138 687.849C391.009 688.053 390.918 688.289 390.87 688.541C390.822 688.793 390.819 689.054 390.86 689.308C390.902 689.562 390.988 689.801 391.112 690.01C391.236 690.218 391.395 690.391 391.578 690.516Z" fill="white" fill-opacity="0.6"/>
        <path d="M379.582 672.369C378.736 671.428 377.492 671.125 376.917 671.752C376.342 672.38 376.494 673.844 377.247 674.764C377.57 675.22 378.01 675.522 378.494 675.62C378.977 675.717 379.472 675.603 379.895 675.298C380.572 674.639 380.437 673.352 379.582 672.369Z" fill="white" fill-opacity="0.6"/>
        <path d="M363.093 656.44C363.358 656.92 363.763 657.256 364.223 657.376C364.683 657.497 365.164 657.394 365.564 657.088C366.198 656.387 366.08 655.049 365.268 653.95C364.455 652.852 363.322 652.58 362.78 653.218C362.239 653.856 362.4 655.446 363.093 656.44Z" fill="white" fill-opacity="0.6"/>
        <path d="M349.667 633.712C349.083 634.308 349.117 635.803 349.735 636.923C350.352 638.042 351.427 638.439 352.087 637.822C352.747 637.205 352.713 635.803 352.028 634.684C351.342 633.565 350.285 633.074 349.667 633.712Z" fill="white" fill-opacity="0.6"/>
        <path d="M339.371 615.24C338.695 614.121 337.679 613.755 337.096 614.425C336.512 615.094 336.571 616.6 337.163 617.657C337.755 618.713 338.804 619.079 339.422 618.42C339.728 617.973 339.892 617.404 339.882 616.818C339.873 616.233 339.691 615.672 339.371 615.24Z" fill="white" fill-opacity="0.6"/>
        <path d="M327.045 596.759C326.92 596.55 326.76 596.377 326.576 596.25C326.393 596.124 326.19 596.046 325.98 596.024C325.771 596.001 325.56 596.033 325.362 596.118C325.163 596.204 324.981 596.34 324.828 596.518C324.61 597.015 324.521 597.583 324.574 598.145C324.627 598.707 324.819 599.235 325.125 599.656C325.246 599.87 325.404 600.048 325.587 600.177C325.771 600.306 325.976 600.383 326.186 600.402C326.397 600.421 326.608 600.382 326.805 600.287C327.002 600.193 327.179 600.045 327.324 599.855C327.576 599.379 327.685 598.81 327.635 598.245C327.584 597.68 327.375 597.155 327.045 596.759Z" fill="white" fill-opacity="0.6"/>
        <path d="M313.061 583.664C313.197 583.855 313.365 584.01 313.552 584.118C313.739 584.226 313.943 584.284 314.149 584.29C314.356 584.296 314.561 584.249 314.752 584.152C314.944 584.055 315.117 583.91 315.26 583.727C315.549 583.313 315.696 582.777 315.669 582.231C315.642 581.686 315.444 581.175 315.116 580.808C314.976 580.62 314.807 580.468 314.618 580.361C314.429 580.254 314.225 580.196 314.018 580.188C313.811 580.18 313.604 580.223 313.411 580.316C313.217 580.408 313.041 580.547 312.891 580.725C312.646 581.166 312.53 581.697 312.56 582.231C312.591 582.765 312.767 583.27 313.061 583.664Z" fill="white" fill-opacity="0.6"/>
        <path d="M303.289 570.548C303.419 570.364 303.514 570.147 303.569 569.912C303.624 569.677 303.636 569.431 303.605 569.19C303.575 568.948 303.502 568.718 303.392 568.516C303.281 568.313 303.137 568.143 302.968 568.017C302.6 567.776 302.177 567.701 301.769 567.804C301.362 567.907 300.996 568.182 300.734 568.581C300.621 568.777 300.54 568.999 300.497 569.234C300.453 569.469 300.448 569.712 300.481 569.949C300.514 570.187 300.585 570.414 300.689 570.617C300.794 570.82 300.93 570.996 301.09 571.134C301.451 571.386 301.874 571.466 302.28 571.358C302.685 571.25 303.044 570.962 303.289 570.548Z" fill="white" fill-opacity="0.6"/>
        <path d="M288.636 562.735C289.048 562.865 289.481 562.849 289.885 562.688C290.289 562.527 290.648 562.227 290.921 561.825C291.513 560.967 291.411 560.057 290.675 559.733C290.257 559.619 289.823 559.645 289.416 559.806C289.009 559.968 288.643 560.26 288.357 560.653C288.233 560.796 288.14 560.974 288.086 561.171C288.031 561.369 288.017 561.581 288.045 561.787C288.072 561.993 288.141 562.187 288.244 562.353C288.347 562.518 288.482 562.649 288.636 562.735Z" fill="white" fill-opacity="0.6"/>
        <path d="M275.312 558.54C274.821 559.304 275.049 559.921 275.769 559.869C276.675 559.719 277.505 559.165 278.104 558.31C278.654 557.495 278.467 556.867 277.681 556.951C276.769 557.136 275.934 557.697 275.312 558.54Z" fill="white" fill-opacity="0.6"/>
        <path d="M483.345 712.094C482.131 712.374 480.986 713 480.003 713.924C479.233 714.729 479.351 715.388 480.274 715.273C481.51 715.008 482.674 714.364 483.658 713.401C484.504 712.491 484.377 711.926 483.345 712.094Z" fill="white" fill-opacity="0.6"/>
        <path d="M499.241 711.246C498.539 712.094 498.962 712.669 500.138 712.449C501.467 712.153 502.709 711.432 503.742 710.357C504.529 709.458 504.131 708.935 502.854 709.186C501.532 709.498 500.291 710.206 499.241 711.246Z" fill="white" fill-opacity="0.6"/>
        <path d="M522.244 707.513C521.5 708.381 521.982 709.04 523.242 708.862C524.68 708.616 526.036 707.893 527.168 706.77C528.014 705.808 527.591 705.232 526.178 705.462C524.747 705.722 523.393 706.427 522.244 707.513Z" fill="white" fill-opacity="0.6"/>
        <path d="M547.997 703.381C547.43 704.27 548.242 704.803 549.689 704.521C551.152 704.268 552.522 703.482 553.622 702.262C554.282 701.321 553.521 700.819 551.93 701.153C550.479 701.437 549.118 702.207 547.997 703.381Z" fill="white" fill-opacity="0.6"/>
        <path d="M576.202 698.748C575.729 699.668 576.693 700.16 578.326 699.793C579.959 699.427 581.71 698.423 582.251 697.44C582.793 696.457 581.862 696.018 580.162 696.394C578.461 696.771 576.642 697.869 576.202 698.748Z" fill="white" fill-opacity="0.6"/>
        <path d="M606.151 693.654C605.711 694.553 606.794 695.108 608.427 694.815C610.059 694.522 611.878 693.466 612.411 692.472C612.944 691.478 611.963 690.976 610.195 691.332C608.427 691.688 606.574 692.775 606.151 693.654Z" fill="white" fill-opacity="0.6"/>
        <path d="M639.458 689.7C641.15 689.46 642.986 688.424 643.527 687.41C644.069 686.395 643.104 685.82 641.345 686.102C639.585 686.385 637.707 687.441 637.233 688.372C636.759 689.303 637.876 689.93 639.458 689.7Z" fill="white" fill-opacity="0.6"/>
        <path d="M668.679 683.383C668.434 684.314 669.728 684.868 671.403 684.554C673.079 684.241 674.787 683.174 675.084 682.159C675.38 681.145 674.238 680.653 672.427 681.009C670.617 681.364 668.916 682.462 668.679 683.383Z" fill="white" fill-opacity="0.6"/>
        <path d="M702.655 679.701C704.347 679.45 705.971 678.415 706.301 677.39C706.631 676.365 705.54 675.779 703.763 676.072C701.986 676.365 700.303 677.442 700.041 678.352C699.778 679.262 701.047 679.931 702.655 679.701Z" fill="white" fill-opacity="0.6"/>
        <path d="M8.23627 665.968C8.90462 666.125 9.45454 665.393 9.47146 664.336C9.51184 663.859 9.41179 663.379 9.18963 662.986C8.96747 662.592 8.63807 662.31 8.26168 662.192C8.08939 662.183 7.91746 662.219 7.75698 662.297C7.59651 662.375 7.45103 662.493 7.3299 662.645C7.20877 662.797 7.11464 662.978 7.05365 663.178C6.99265 663.377 6.96612 663.59 6.97573 663.803C6.98625 664.278 7.11206 664.739 7.33682 665.125C7.56158 665.511 7.87491 665.805 8.23627 665.968Z" fill="white" fill-opacity="0.6"/>
        <path d="M19.1835 669.169C19.708 669.388 20.1564 668.646 20.1987 667.526C20.241 666.407 19.8603 665.382 19.2935 665.205C18.7266 665.027 18.2444 665.822 18.2275 666.826C18.2048 667.286 18.2814 667.745 18.4493 668.157C18.6173 668.569 18.8706 668.918 19.1835 669.169Z" fill="white" fill-opacity="0.6"/>
        <path d="M27.9818 668.865C27.5673 668.677 27.1358 669.503 27.0766 670.581C27.0174 671.658 27.3642 672.913 27.7534 673.143C28.1426 673.373 28.5402 672.599 28.5994 671.386C28.6586 670.173 28.4218 669.116 27.9818 668.865Z" fill="white" fill-opacity="0.6"/>
        <path d="M33.9632 675.089C33.8786 676.291 34.0816 677.63 34.3862 677.871C34.6907 678.111 35.0461 677.275 35.1646 675.936C35.283 674.597 35.1138 673.415 34.7669 673.195C34.42 672.976 34.0478 673.907 33.9632 675.089Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.1053 677.954C39.843 677.703 39.4708 678.655 39.327 679.942C39.1492 680.987 39.2078 682.073 39.4962 683.079C39.7415 683.393 40.0799 682.483 40.2576 681.04C40.4352 679.597 40.376 678.216 40.1053 677.954Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.6077 688.644C43.8192 688.822 44.1576 687.692 44.386 686.06C44.6145 684.429 44.5891 683.09 44.3437 682.923C44.0984 682.755 43.7346 684.021 43.557 685.464C43.3793 686.908 43.3878 688.466 43.6077 688.644Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.1608 690.893C46.9154 692.514 46.8562 694.156 47.0423 694.376C47.2284 694.595 47.6176 693.392 47.8883 691.625C48.159 689.857 48.2352 688.372 48.0152 688.205C47.7953 688.037 47.3638 689.324 47.1608 690.893Z" fill="white" fill-opacity="0.6"/>
        <path d="M50.291 700.16C50.4686 700.494 50.9085 699.25 51.2723 697.367C51.6361 695.484 51.7546 693.8 51.5431 693.528C51.3316 693.256 50.8747 694.574 50.5701 696.258C50.2768 697.525 50.182 698.849 50.291 700.16Z" fill="white" fill-opacity="0.6"/>
        <path d="M55.2909 698.727C55.071 698.497 54.5211 699.971 54.1573 701.781C53.7935 703.59 53.6328 705.598 53.8273 705.891C54.0219 706.184 54.5464 704.845 54.9694 702.753C55.3925 700.662 55.5363 698.978 55.2909 698.727Z" fill="white" fill-opacity="0.6"/>
        <path d="M58.0489 711.372C58.2942 711.707 58.8949 710.232 59.3771 708.088C59.8593 705.944 60.0285 704.019 59.7409 703.747C59.4533 703.475 58.8441 705.034 58.4211 706.969C57.9981 708.904 57.8204 711.037 58.0489 711.372Z" fill="white" fill-opacity="0.6"/>
        <path d="M63.4381 716.507C63.8019 716.612 64.4956 714.886 65.0117 712.564C65.5277 710.242 65.5955 708.381 65.2063 708.381C64.8171 708.381 64.0811 710.18 63.6327 712.271C63.1843 714.363 63.1166 716.403 63.4381 716.507Z" fill="white" fill-opacity="0.6"/>
        <path d="M72.3717 712.585C71.9233 712.481 71.0434 714.384 70.5612 716.528C70.079 718.673 69.9859 720.879 70.409 721.057C70.832 721.235 71.6526 719.467 72.2279 717.051C72.8032 714.635 72.8539 712.7 72.3717 712.585Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.3345 724.917C79.8675 725.168 80.8235 723.369 81.4411 720.921C82.0587 718.474 82.0925 716.372 81.5003 716.204C80.9081 716.037 79.9521 717.909 79.4107 720.137C78.8692 722.365 78.8185 724.676 79.3345 724.917Z" fill="white" fill-opacity="0.6"/>
        <path d="M90.7047 722.835C90.0702 725.168 90.0364 727.542 90.654 727.95C91.2716 728.358 92.3461 726.611 93.0652 724.132C93.7843 721.653 93.7758 719.384 93.1075 719.091C92.4391 718.798 91.3562 720.388 90.7047 722.835Z" fill="white" fill-opacity="0.6"/>
        <path d="M259.568 567.159C260.575 566.415 261.502 565.515 262.326 564.481C262.825 563.77 262.326 563.854 261.26 564.659C260.254 565.42 259.326 566.326 258.493 567.358C258.019 568.017 258.544 567.923 259.568 567.159Z" fill="white" fill-opacity="0.6"/>
        <path d="M244.153 578.706C243.244 579.55 242.395 580.487 241.615 581.509C241.116 582.189 241.395 582.158 242.199 581.425C243.103 580.582 243.951 579.651 244.737 578.643C245.329 577.869 245.05 577.869 244.153 578.706Z" fill="white" fill-opacity="0.6"/>
        <path d="M228.874 594.144C228.054 595.064 227.282 596.049 226.565 597.093C226.125 597.773 226.396 597.637 227.123 596.822C227.939 595.902 228.71 594.924 229.433 593.893C229.932 593.129 229.687 593.244 228.874 594.144Z" fill="white" fill-opacity="0.6"/>
        <path d="M215.635 610.011C214.877 611.007 214.165 612.054 213.503 613.149C213.003 613.996 213.096 614.058 213.706 613.285C214.315 612.511 215.288 611.078 215.829 610.147C216.371 609.216 216.286 609.184 215.635 610.011Z" fill="white" fill-opacity="0.6"/>
        <path d="M203.765 626.275C203.06 627.328 202.402 628.428 201.794 629.57C201.362 630.427 201.498 630.438 202.064 629.57C202.631 628.702 203.537 627.206 204.027 626.275C204.518 625.344 204.399 625.355 203.765 626.275Z" fill="white" fill-opacity="0.6"/>
        <path d="M191.591 645.803C192.267 644.692 192.888 643.532 193.452 642.33C193.96 641.284 193.909 641.075 193.342 641.964C192.657 643.069 192.033 644.229 191.473 645.436C191.033 646.43 191.092 646.608 191.591 645.803Z" fill="white" fill-opacity="0.6"/>
        <path d="M182.141 660.404C181.693 661.523 181.71 661.858 182.183 661.094C182.848 659.926 183.448 658.703 183.977 657.433C184.485 656.199 184.476 655.896 183.977 656.743C183.289 657.902 182.675 659.126 182.141 660.404Z" fill="white" fill-opacity="0.6"/>
        <path d="M173.554 673.917C173.089 675.204 173.097 675.674 173.554 674.963C174.234 673.743 174.826 672.452 175.322 671.104C175.822 669.744 175.83 669.305 175.322 670.058C174.634 671.271 174.042 672.563 173.554 673.917Z" fill="white" fill-opacity="0.6"/>
        <path d="M165.526 685.182C165.026 686.636 164.967 687.462 165.39 686.887C166.108 685.668 166.704 684.348 167.167 682.954C167.717 681.375 167.784 680.642 167.311 681.28C166.587 682.484 165.987 683.795 165.526 685.182Z" fill="white" fill-opacity="0.6"/>
        <path d="M157.666 694.062C157.159 695.693 157.099 696.718 157.539 696.216C158.311 694.983 158.924 693.611 159.358 692.148C159.908 690.443 159.976 689.491 159.502 690.056C158.721 691.261 158.101 692.614 157.666 694.062Z" fill="white" fill-opacity="0.6"/>
        <path d="M150.577 701.017C151.16 699.145 151.16 698.172 150.577 698.925C149.671 700.322 148.96 701.895 148.47 703.58C147.937 705.358 147.971 706.351 148.529 705.672C149.416 704.272 150.108 702.699 150.577 701.017Z" fill="white" fill-opacity="0.6"/>
        <path d="M137.176 715.116C137.861 714.865 138.935 712.93 139.604 710.807C140.272 708.684 140.238 707.188 139.519 707.471C138.8 707.753 137.65 709.772 137.049 711.759C136.448 713.746 136.533 715.357 137.176 715.116Z" fill="white" fill-opacity="0.6"/>
        <path d="M122.751 717.606C122.109 719.792 122.227 721.674 122.971 721.538C123.716 721.402 124.951 719.3 125.662 716.957C126.372 714.614 126.305 712.889 125.484 713.087C124.664 713.286 123.411 715.357 122.751 717.606Z" fill="white" fill-opacity="0.6"/>
        <path d="M106.11 722.909C105.518 725.178 105.797 727.207 106.677 727.092C107.557 726.977 108.877 724.802 109.571 722.302C110.264 719.802 110.053 718.014 109.055 718.233C108.056 718.453 106.711 720.618 106.11 722.909Z" fill="white" fill-opacity="0.6"/>
        <path d="M479.538 707.565C478.692 708.422 478.912 708.83 480.096 708.475C481.187 708.119 482.218 707.527 483.142 706.728C483.988 705.923 483.988 705.347 483.023 705.578C481.769 705.956 480.585 706.631 479.538 707.565Z" fill="white" fill-opacity="0.6"/>
        <path d="M460.562 708.987C459.64 709.793 459.783 710.472 460.875 710.472C461.98 710.397 463.054 709.991 464.005 709.291C464.969 708.517 465.003 707.784 464.005 707.69C462.782 707.72 461.59 708.169 460.562 708.987Z" fill="white" fill-opacity="0.6"/>
        <path d="M444.276 705.326C443.109 705.147 441.93 705.521 440.977 706.372C440.393 707.042 440.647 707.722 441.569 707.931C442.752 708.137 443.955 707.802 444.953 706.99C445.681 706.299 445.359 705.473 444.276 705.326Z" fill="white" fill-opacity="0.6"/>
        <path d="M422.796 701.289C423.319 701.538 423.88 701.638 424.439 701.58C424.997 701.522 425.537 701.308 426.019 700.955C426.755 700.296 426.544 699.271 425.588 698.863C424.629 698.514 423.598 698.626 422.703 699.176C421.764 699.794 421.806 700.745 422.796 701.289Z" fill="white" fill-opacity="0.6"/>
        <path d="M408.397 688.56C407.957 688.222 407.453 688.031 406.935 688.008C406.417 687.984 405.904 688.127 405.444 688.424C405.29 688.533 405.161 688.69 405.071 688.88C404.982 689.071 404.934 689.287 404.934 689.507C404.934 689.727 404.982 689.943 405.071 690.133C405.161 690.323 405.29 690.481 405.444 690.589C405.859 690.981 406.353 691.224 406.873 691.292C407.392 691.361 407.916 691.251 408.388 690.976C408.573 690.868 408.73 690.698 408.839 690.485C408.949 690.272 409.008 690.024 409.009 689.772C409.01 689.519 408.953 689.271 408.844 689.057C408.736 688.842 408.581 688.67 408.397 688.56Z" fill="white" fill-opacity="0.6"/>
        <path d="M390.58 674.189C390.398 674.33 390.248 674.526 390.145 674.759C390.041 674.991 389.986 675.252 389.986 675.517C389.986 675.783 390.041 676.044 390.145 676.276C390.248 676.509 390.398 676.705 390.58 676.846C390.904 677.199 391.299 677.438 391.724 677.535C392.148 677.633 392.586 677.586 392.991 677.4C393.179 677.281 393.339 677.107 393.459 676.892C393.578 676.678 393.653 676.43 393.677 676.171C393.7 675.912 393.671 675.65 393.593 675.409C393.515 675.167 393.39 674.953 393.228 674.785C392.893 674.355 392.457 674.066 391.982 673.959C391.507 673.852 391.016 673.932 390.58 674.189Z" fill="white" fill-opacity="0.6"/>
        <path d="M375.817 659.525C376.604 660.571 377.814 660.958 378.457 660.331C379.1 659.703 378.939 658.239 378.169 657.287C377.873 656.897 377.49 656.624 377.068 656.503C376.647 656.382 376.205 656.418 375.8 656.607C375.014 657.161 375.022 658.406 375.817 659.525Z" fill="white" fill-opacity="0.6"/>
        <path d="M361.791 637.331C361.097 637.958 361.148 639.349 361.901 640.468C362.146 640.892 362.493 641.209 362.892 641.376C363.291 641.543 363.723 641.551 364.126 641.399C364.845 640.939 364.904 639.506 364.252 638.345C363.601 637.184 362.459 636.734 361.791 637.331Z" fill="white" fill-opacity="0.6"/>
        <path d="M348.745 617.541C348.068 618.2 348.111 619.633 348.821 620.679C349.043 621.099 349.371 621.414 349.755 621.575C350.138 621.736 350.554 621.734 350.936 621.568C351.63 621.077 351.698 619.612 351.089 618.431C350.479 617.249 349.388 616.903 348.745 617.541Z" fill="white" fill-opacity="0.6"/>
        <path d="M336.326 598.411C335.991 598.829 335.803 599.393 335.803 599.98C335.803 600.568 335.991 601.132 336.326 601.549C336.544 601.948 336.866 602.242 337.239 602.382C337.612 602.521 338.013 602.496 338.373 602.313C339.05 601.8 339.143 600.346 338.559 599.175C338.458 598.938 338.319 598.728 338.149 598.56C337.98 598.392 337.785 598.268 337.576 598.196C337.367 598.125 337.148 598.107 336.933 598.144C336.718 598.181 336.511 598.272 336.326 598.411Z" fill="white" fill-opacity="0.6"/>
        <path d="M324.27 581.07C323.955 581.462 323.778 581.992 323.778 582.545C323.778 583.097 323.955 583.627 324.27 584.019C324.502 584.386 324.83 584.64 325.198 584.74C325.565 584.839 325.949 584.777 326.284 584.563C326.616 584.193 326.823 583.683 326.865 583.133C326.908 582.583 326.782 582.033 326.512 581.593C326.401 581.365 326.252 581.169 326.075 581.017C325.898 580.865 325.697 580.761 325.486 580.712C325.274 580.663 325.056 580.669 324.847 580.731C324.638 580.792 324.441 580.908 324.27 581.07Z" fill="white" fill-opacity="0.6"/>
        <path d="M312.113 566.908C311.978 567.1 311.879 567.327 311.824 567.571C311.768 567.816 311.756 568.073 311.79 568.324C311.824 568.575 311.903 568.813 312.02 569.022C312.137 569.232 312.29 569.406 312.468 569.533C312.767 569.798 313.132 569.924 313.499 569.89C313.865 569.855 314.21 569.663 314.473 569.345C314.616 569.156 314.725 568.932 314.795 568.689C314.866 568.446 314.895 568.187 314.882 567.929C314.868 567.671 314.813 567.419 314.718 567.189C314.624 566.959 314.492 566.754 314.332 566.589C314.172 566.423 313.986 566.299 313.785 566.226C313.585 566.152 313.375 566.129 313.167 566.159C312.959 566.189 312.759 566.271 312.578 566.399C312.396 566.528 312.238 566.701 312.113 566.908Z" fill="white" fill-opacity="0.6"/>
        <path d="M300.1 558.635C300.471 558.873 300.895 558.955 301.307 558.868C301.719 558.781 302.098 558.53 302.384 558.154C302.517 558 302.62 557.811 302.686 557.601C302.751 557.391 302.776 557.165 302.76 556.941C302.743 556.717 302.686 556.501 302.591 556.308C302.497 556.116 302.368 555.953 302.215 555.832C301.865 555.677 301.487 555.644 301.124 555.736C300.76 555.828 300.423 556.042 300.151 556.355C299.474 557.139 299.431 558.122 300.1 558.635Z" fill="white" fill-opacity="0.6"/>
        <path d="M287.494 551.324C286.817 552.139 286.86 552.882 287.579 553.039C288.347 553.113 289.107 552.808 289.694 552.192C290.379 551.46 290.455 550.56 289.846 550.288C289.416 550.225 288.98 550.285 288.572 550.465C288.164 550.645 287.795 550.939 287.494 551.324Z" fill="white" fill-opacity="0.6"/>
        <path d="M497.388 704.427C496.399 705.337 496.466 705.944 497.558 705.776C498.695 705.517 499.779 704.98 500.747 704.197C501.754 703.329 501.821 702.618 500.891 702.67C499.627 702.904 498.427 703.506 497.388 704.427Z" fill="white" fill-opacity="0.6"/>
        <path d="M518.217 700.996C517.244 701.948 517.473 702.544 518.75 702.325C519.986 702.074 521.165 701.504 522.21 700.651C523.217 699.762 523.115 699.051 521.999 699.135C520.633 699.361 519.335 700.001 518.217 700.996Z" fill="white" fill-opacity="0.6"/>
        <path d="M542.971 698.151C544.431 697.902 545.811 697.18 546.973 696.06C547.7 695.191 547.117 694.606 545.788 694.857C544.509 695.146 543.296 695.778 542.235 696.708C541.237 697.785 541.55 698.402 542.971 698.151Z" fill="white" fill-opacity="0.6"/>
        <path d="M568.673 692.179C567.785 693.225 568.309 693.769 569.866 693.507C571.218 693.265 572.508 692.647 573.639 691.698C574.578 690.767 574.172 690.035 572.793 690.16C571.296 690.354 569.871 691.053 568.673 692.179Z" fill="white" fill-opacity="0.6"/>
        <path d="M596.819 687.587C596.447 688.477 597.581 688.79 599.357 688.278C600.872 687.859 602.344 686.918 602.741 686.186C603.139 685.454 602.158 684.847 600.542 685.234C598.926 685.621 597.209 686.646 596.819 687.587Z" fill="white" fill-opacity="0.6"/>
        <path d="M626.878 681.929C626.176 682.975 626.98 683.54 628.68 683.247C630.051 683.035 631.353 682.378 632.453 681.343C633.248 680.371 632.563 679.649 631.007 679.806C629.482 679.949 628.035 680.693 626.878 681.929Z" fill="white" fill-opacity="0.6"/>
        <path d="M661.302 674.524C659.805 674.702 657.833 675.81 657.343 676.752C656.852 677.693 657.554 678.164 658.976 678.038C660.617 677.902 662.529 676.992 663.146 676.009C663.764 675.026 662.901 674.336 661.302 674.524Z" fill="white" fill-opacity="0.6"/>
        <path d="M691.488 669.462C689.88 669.754 688.247 670.853 687.985 671.825C687.723 672.798 688.645 673.133 690.151 672.924C691.843 672.683 693.611 671.648 693.941 670.717C694.271 669.786 693.07 669.179 691.488 669.462Z" fill="white" fill-opacity="0.6"/>
        <path d="M721.775 664.619C720.303 664.786 718.585 665.884 718.281 666.847C717.976 667.809 718.822 668.28 720.235 668.165C721.876 668.029 723.619 667.119 723.974 666.135C724.33 665.152 723.323 664.431 721.775 664.619Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.427454 653.176C0.395003 653.669 0.514858 654.159 0.762881 654.547C1.01091 654.936 1.36864 655.193 1.7641 655.268C2.07776 655.301 2.3897 655.184 2.63536 654.941C2.88102 654.697 3.04159 654.346 3.08387 653.961C3.12685 653.477 3.03211 652.99 2.81593 652.583C2.59975 652.177 2.27569 651.876 1.89949 651.733C1.72408 651.689 1.54293 651.692 1.36878 651.744C1.19463 651.795 1.03169 651.893 0.891404 652.031C0.75112 652.168 0.636902 652.342 0.55675 652.54C0.476599 652.738 0.432466 652.955 0.427454 653.176Z" fill="white" fill-opacity="0.6"/>
        <path d="M13.1768 655.446C13.1203 655.903 13.193 656.37 13.3825 656.768C13.5721 657.166 13.867 657.471 14.2174 657.632C14.7419 657.747 15.2241 657.172 15.3172 656.325C15.3856 655.86 15.3343 655.381 15.1703 654.955C15.0063 654.529 14.738 654.177 14.4035 653.95C13.8197 653.689 13.2275 654.41 13.1768 655.446Z" fill="white" fill-opacity="0.6"/>
        <path d="M23.422 658.688C23.422 659.828 23.8873 660.665 24.4034 660.592C24.9194 660.519 25.1563 659.724 25.1648 658.762C25.1648 657.632 24.7672 656.67 24.3188 656.596C23.8704 656.523 23.3966 657.538 23.422 658.688Z" fill="white" fill-opacity="0.6"/>
        <path d="M31.6114 661.491C31.5014 662.736 31.7383 663.887 32.1274 664.075C32.5166 664.263 32.8212 663.552 32.9734 662.569C33.1173 661.397 32.9311 660.079 32.5504 659.766C32.1697 659.452 31.7129 660.299 31.6114 661.491Z" fill="white" fill-opacity="0.6"/>
        <path d="M38.3964 667.924C38.701 668.154 39.0901 667.213 39.2424 665.905C39.3947 664.598 39.2424 663.27 38.904 663.155C38.5656 663.039 38.2948 663.897 38.151 665.016C37.9564 666.397 38.058 667.683 38.3964 667.924Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.4469 672.003C43.6922 672.306 44.073 671.334 44.2929 669.911C44.5129 668.489 44.4452 666.972 44.1999 666.773C43.9545 666.575 43.633 667.537 43.4384 668.75C43.1339 670.33 43.1931 671.742 43.4469 672.003Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.8463 672.965C47.5501 674.639 47.4994 676.166 47.7193 676.438C47.9393 676.71 48.2438 675.852 48.5061 674.555C48.7941 673.337 48.8691 672.057 48.7261 670.8C48.5484 670.351 48.1254 671.376 47.8463 672.965Z" fill="white" fill-opacity="0.6"/>
        <path d="M51.8562 677.233C51.5009 679.053 51.3994 680.705 51.6109 680.956C51.8224 681.207 52.1693 680.193 52.4569 678.77C52.7954 677.475 52.9135 676.108 52.8038 674.754C52.6515 674.262 52.1946 675.444 51.8562 677.233Z" fill="white" fill-opacity="0.6"/>
        <path d="M56.9322 678.519C56.7122 678.321 56.1623 679.889 55.7816 681.803C55.4695 682.922 55.3767 684.115 55.5109 685.286C55.7139 685.652 56.2215 684.397 56.6107 682.525C56.9998 680.653 57.1521 678.728 56.9322 678.519Z" fill="white" fill-opacity="0.6"/>
        <path d="M60.1976 685.935C59.7492 688.079 59.6562 689.69 59.9861 689.669C60.3161 689.648 60.7729 688.33 61.1621 686.583C61.6021 684.607 61.7797 682.536 61.5174 682.347C61.2552 682.159 60.6376 683.843 60.1976 685.935Z" fill="white" fill-opacity="0.6"/>
        <path d="M66.9575 686.008C66.6275 685.883 65.9168 687.723 65.4262 689.962C64.9947 691.928 64.8763 693.497 65.147 693.758C65.4177 694.02 66.1622 692.482 66.6529 690.349C67.1436 688.215 67.2789 686.134 66.9575 686.008Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.712 697.587C72.1181 697.806 72.9472 696.039 73.4632 693.811C73.9793 691.583 74.0808 689.46 73.6578 689.418C73.2348 689.376 72.5918 690.945 72.0927 692.89C71.4751 695.286 71.3059 697.356 71.712 697.587Z" fill="white" fill-opacity="0.6"/>
        <path d="M82.177 692.441C81.7371 692.158 80.7557 694.156 80.1635 696.488C79.6305 698.58 79.5121 700.463 79.8928 700.86C80.2735 701.258 81.2464 699.814 81.864 697.472C82.4816 695.129 82.6423 692.744 82.177 692.441Z" fill="white" fill-opacity="0.6"/>
        <path d="M90.2225 700.065C89.6979 702.534 89.9095 704.155 90.7132 703.737C91.3815 703.381 92.2783 701.477 92.7521 699.396C93.302 696.949 93.1751 694.951 92.4814 695.108C91.7876 695.265 90.7555 697.545 90.2225 700.065Z" fill="white" fill-opacity="0.6"/>
        <path d="M273.459 553.572C272.808 554.367 273.18 554.409 274.305 553.666C275.135 553.336 275.904 552.802 276.564 552.097C277.241 551.334 277.249 550.748 276.564 550.895C275.444 551.626 274.401 552.525 273.459 553.572Z" fill="white" fill-opacity="0.6"/>
        <path d="M254.83 567.577C254.229 568.33 254.534 568.268 255.524 567.452C256.416 566.72 257.264 565.909 258.062 565.025C258.73 564.241 258.519 564.168 257.622 564.868C256.63 565.671 255.696 566.578 254.83 567.577Z" fill="white" fill-opacity="0.6"/>
        <path d="M239.035 582.377C239.912 581.513 240.743 580.581 241.522 579.585C241.979 578.957 241.632 579.124 240.803 579.951C240.031 580.729 239.296 581.564 238.604 582.451C237.986 583.266 238.181 583.235 239.035 582.377Z" fill="white" fill-opacity="0.6"/>
        <path d="M224.357 597.909C223.815 598.736 223.985 598.683 224.737 597.794C225.49 596.905 226.286 595.88 226.776 595.179C227.267 594.479 227.276 594.259 226.599 595.012C225.806 595.923 225.058 596.89 224.357 597.909Z" fill="white" fill-opacity="0.6"/>
        <path d="M211.971 613.295C211.421 614.236 211.514 614.289 212.174 613.421C212.834 612.552 213.562 611.454 214.044 610.67C214.526 609.885 214.602 609.551 214.044 610.262C213.3 611.215 212.608 612.229 211.971 613.295Z" fill="white" fill-opacity="0.6"/>
        <path d="M200.998 628.409C200.491 629.455 200.609 629.455 201.252 628.503C201.857 627.575 202.422 626.608 202.944 625.606C203.443 624.643 203.427 624.413 202.894 625.145C202.203 626.18 201.57 627.27 200.998 628.409Z" fill="white" fill-opacity="0.6"/>
        <path d="M193.139 639.098C192.447 640.189 191.828 641.346 191.286 642.56C190.863 643.554 190.872 643.794 191.286 643.146C191.968 642.078 192.585 640.949 193.131 639.767C193.63 638.711 193.621 638.387 193.139 639.098Z" fill="white" fill-opacity="0.6"/>
        <path d="M182.598 655.205C182.065 656.544 182.082 656.9 182.598 656.021C183.205 655.004 183.742 653.927 184.205 652.8C184.722 651.555 184.798 650.959 184.366 651.566C183.675 652.699 183.082 653.919 182.598 655.205Z" fill="white" fill-opacity="0.6"/>
        <path d="M174.595 665.916C174.02 667.464 173.96 668.133 174.451 667.432C175.077 666.413 175.61 665.311 176.041 664.148C176.608 662.694 176.778 661.753 176.397 662.192C175.669 663.332 175.063 664.584 174.595 665.916Z" fill="white" fill-opacity="0.6"/>
        <path d="M167.015 674.179C166.38 675.957 166.211 677.044 166.634 676.616C167.295 675.661 167.831 674.585 168.224 673.426C168.85 671.783 169.07 670.476 168.783 670.643C168.01 671.666 167.409 672.867 167.015 674.179Z" fill="white" fill-opacity="0.6"/>
        <path d="M160.89 677.097C160.013 678.355 159.331 679.802 158.876 681.364C158.343 683.027 158.258 683.969 158.665 683.676C159.526 682.565 160.189 681.243 160.61 679.795C161.194 678.049 161.338 676.72 160.89 677.097Z" fill="white" fill-opacity="0.6"/>
        <path d="M148.952 690.736C148.402 692.671 148.572 693.392 149.308 692.388C150.136 691.057 150.793 689.575 151.253 687.995C151.82 686.081 151.786 685.025 151.16 685.736C150.186 687.224 149.438 688.918 148.952 690.736Z" fill="white" fill-opacity="0.6"/>
        <path d="M136.271 693.884C135.543 696.258 135.619 697.921 136.406 697.639C137.193 697.356 138.098 695.746 138.724 693.905C139.469 691.729 139.57 689.721 138.876 689.721C138.183 689.721 136.973 691.614 136.271 693.884Z" fill="white" fill-opacity="0.6"/>
        <path d="M120.848 698.036C120.07 700.62 120.188 702.492 121.11 702.272C121.872 702.095 122.98 700.421 123.648 698.423C124.435 696.112 124.494 693.874 123.648 693.696C122.802 693.518 121.592 695.578 120.848 698.036Z" fill="white" fill-opacity="0.6"/>
        <path d="M102.955 702.335C102.439 704.845 102.896 706.351 104.089 705.64C105.087 705.044 106.212 702.952 106.627 700.871C107.16 698.423 106.711 696.687 105.645 697.064C104.579 697.44 103.479 699.752 102.955 702.335Z" fill="white" fill-opacity="0.6"/>
        <path d="M478.616 701.289C477.643 702.074 477.693 702.753 478.709 702.618C479.856 702.375 480.953 701.848 481.932 701.07C482.998 700.212 482.981 699.626 481.881 699.793C480.728 700.042 479.621 700.549 478.616 701.289Z" fill="white" fill-opacity="0.6"/>
        <path d="M459.233 702.743C460.476 702.721 461.679 702.208 462.668 701.279C463.269 700.588 462.947 700.086 461.907 700.076C460.78 700.112 459.679 700.493 458.7 701.185C457.863 701.917 458.134 702.712 459.233 702.743Z" fill="white" fill-opacity="0.6"/>
        <path d="M442.508 696.06C441.341 695.776 440.131 696.052 439.124 696.834C438.54 697.44 438.76 698.287 439.564 698.559C440.145 698.704 440.743 698.704 441.324 698.56C441.905 698.417 442.457 698.132 442.948 697.723C443.616 697.032 443.43 696.321 442.508 696.06Z" fill="white" fill-opacity="0.6"/>
        <path d="M421.138 690.276C421.601 690.498 422.096 690.597 422.591 690.566C423.087 690.535 423.572 690.376 424.014 690.098C424.953 689.449 424.911 688.435 423.921 687.901C423.459 687.679 422.966 687.574 422.47 687.592C421.975 687.61 421.487 687.751 421.036 688.006C420.849 688.101 420.691 688.265 420.583 688.477C420.475 688.688 420.423 688.936 420.434 689.185C420.445 689.435 420.519 689.674 420.645 689.87C420.771 690.065 420.943 690.207 421.138 690.276Z" fill="white" fill-opacity="0.6"/>
        <path d="M404.463 675.748C404.3 675.918 404.176 676.138 404.102 676.385C404.027 676.632 404.006 676.899 404.039 677.159C404.072 677.42 404.159 677.665 404.291 677.872C404.423 678.079 404.596 678.241 404.793 678.341C405.192 678.606 405.64 678.741 406.093 678.732C406.546 678.723 406.99 678.57 407.382 678.289C407.536 678.17 407.662 678.004 407.75 677.807C407.838 677.61 407.884 677.389 407.884 677.165C407.884 676.94 407.838 676.719 407.75 676.523C407.662 676.326 407.536 676.16 407.382 676.04C406.973 675.641 406.478 675.4 405.958 675.348C405.439 675.296 404.919 675.435 404.463 675.748Z" fill="white" fill-opacity="0.6"/>
        <path d="M392.154 660.498C391.308 659.577 389.903 659.358 389.32 660.069C389.09 660.478 388.994 660.981 389.052 661.474C389.11 661.967 389.318 662.414 389.633 662.726C390 663.176 390.48 663.457 390.995 663.521C391.509 663.586 392.025 663.431 392.458 663.081C393.034 662.454 392.907 661.345 392.154 660.498Z" fill="white" fill-opacity="0.6"/>
        <path d="M377.357 644.652C377.62 644.245 377.744 643.727 377.701 643.208C377.659 642.69 377.453 642.213 377.129 641.88C376.376 640.834 375.056 640.541 374.497 641.263C374.276 641.704 374.178 642.222 374.219 642.739C374.26 643.255 374.438 643.74 374.726 644.119C375.028 644.603 375.468 644.927 375.956 645.026C376.445 645.125 376.945 644.991 377.357 644.652Z" fill="white" fill-opacity="0.6"/>
        <path d="M360.877 621.192C360.259 621.861 360.395 623.524 361.148 624.486C361.414 624.877 361.784 625.137 362.192 625.222C362.599 625.306 363.018 625.209 363.373 624.947C363.658 624.561 363.816 624.057 363.816 623.535C363.816 623.012 363.658 622.508 363.373 622.123C362.704 620.951 361.503 620.512 360.877 621.192Z" fill="white" fill-opacity="0.6"/>
        <path d="M350.319 601.926C349.65 600.88 348.457 600.681 347.933 601.466C347.724 601.924 347.629 602.449 347.661 602.973C347.692 603.498 347.849 604 348.111 604.415C348.23 604.654 348.391 604.858 348.582 605.012C348.772 605.166 348.988 605.265 349.213 605.304C349.439 605.343 349.668 605.32 349.886 605.237C350.103 605.154 350.303 605.013 350.471 604.823C350.719 604.392 350.839 603.869 350.811 603.341C350.783 602.813 350.609 602.313 350.319 601.926Z" fill="white" fill-opacity="0.6"/>
        <path d="M335.717 582.649C335.447 583.12 335.321 583.695 335.363 584.27C335.405 584.845 335.611 585.383 335.945 585.787C336.214 586.13 336.578 586.335 336.964 586.36C337.35 586.386 337.73 586.229 338.026 585.923C338.29 585.535 338.435 585.044 338.435 584.537C338.435 584.03 338.29 583.539 338.026 583.151C337.909 582.92 337.753 582.722 337.57 582.57C337.386 582.418 337.179 582.314 336.961 582.267C336.744 582.22 336.521 582.229 336.306 582.295C336.092 582.361 335.891 582.482 335.717 582.649Z" fill="white" fill-opacity="0.6"/>
        <path d="M323.847 566.291C323.541 566.67 323.365 567.179 323.354 567.714C323.343 568.249 323.498 568.769 323.788 569.167C324.063 569.491 324.423 569.681 324.803 569.702C325.182 569.723 325.555 569.574 325.852 569.282C326.02 569.109 326.156 568.895 326.251 568.652C326.347 568.41 326.4 568.145 326.408 567.876C326.415 567.606 326.376 567.338 326.293 567.088C326.211 566.838 326.086 566.613 325.928 566.427C325.649 566.106 325.288 565.917 324.908 565.892C324.527 565.867 324.152 566.008 323.847 566.291Z" fill="white" fill-opacity="0.6"/>
        <path d="M311.902 556.25C312.292 556.478 312.735 556.525 313.151 556.383C313.568 556.241 313.929 555.919 314.169 555.476C314.276 555.311 314.353 555.119 314.394 554.913C314.434 554.707 314.438 554.493 314.404 554.285C314.37 554.077 314.299 553.882 314.197 553.711C314.095 553.541 313.964 553.401 313.814 553.3C313.466 553.118 313.083 553.063 312.711 553.143C312.339 553.223 311.993 553.434 311.715 553.75C311.575 553.918 311.468 554.122 311.4 554.348C311.332 554.574 311.306 554.815 311.324 555.055C311.342 555.295 311.403 555.527 311.503 555.734C311.603 555.94 311.739 556.117 311.902 556.25Z" fill="white" fill-opacity="0.6"/>
        <path d="M299.651 547.872C300.088 547.932 300.529 547.85 300.933 547.636C301.336 547.421 301.687 547.081 301.952 546.648C302.384 545.885 302.206 545.184 301.538 545.017C301.148 544.974 300.754 545.034 300.384 545.192C300.013 545.351 299.674 545.605 299.389 545.937C298.805 546.701 298.941 547.673 299.651 547.872Z" fill="white" fill-opacity="0.6"/>
        <path d="M286.504 544.107C285.912 544.849 286.098 545.435 286.851 545.299C287.664 545.083 288.414 544.594 289.017 543.887C289.575 543.186 289.559 542.632 289.017 542.632C288.073 542.778 287.193 543.295 286.504 544.107Z" fill="white" fill-opacity="0.6"/>
        <path d="M496.712 699.312C497.809 699.019 498.852 698.468 499.783 697.691C500.857 696.781 500.95 696.154 499.994 696.331C498.904 696.635 497.867 697.177 496.932 697.932C495.942 698.758 495.823 699.459 496.712 699.312Z" fill="white" fill-opacity="0.6"/>
        <path d="M515.383 694.867C514.537 695.714 514.884 696.269 516.068 695.965C517.248 695.611 518.36 694.975 519.342 694.093C520.121 693.309 520.011 692.807 519.097 692.943C517.757 693.227 516.487 693.886 515.383 694.867Z" fill="white" fill-opacity="0.6"/>
        <path d="M541.338 688.811C539.916 689.072 538.568 689.762 537.413 690.819C536.719 691.541 536.956 692.116 537.921 692.022C539.335 691.763 540.675 691.073 541.821 690.014C542.574 689.209 542.362 688.686 541.338 688.811Z" fill="white" fill-opacity="0.6"/>
        <path d="M562.066 686.259C561.346 687.148 562.065 687.682 563.521 687.305C564.793 686.978 565.985 686.283 566.998 685.276C567.666 684.45 567.277 683.958 566.101 684.136C564.63 684.399 563.239 685.131 562.066 686.259Z" fill="white" fill-opacity="0.6"/>
        <path d="M589.095 681.05C588.3 681.981 588.943 682.619 590.398 682.347C591.721 682.078 592.967 681.398 594.019 680.371C594.865 679.325 594.281 678.77 592.759 679.084C591.428 679.369 590.172 680.043 589.095 681.05Z" fill="white" fill-opacity="0.6"/>
        <path d="M617.513 675.758C616.785 676.699 617.564 677.358 619.078 677.076C620.405 676.826 621.652 676.132 622.682 675.068C623.469 674.022 622.758 673.436 621.168 673.75C619.83 674.019 618.571 674.711 617.513 675.758Z" fill="white" fill-opacity="0.6"/>
        <path d="M650.566 668.342C649.01 668.625 647.098 669.796 646.751 670.675C646.404 671.553 647.394 671.94 648.663 671.721C650.194 671.47 652.047 670.319 652.411 669.367C652.774 668.416 651.979 668.081 650.566 668.342Z" fill="white" fill-opacity="0.6"/>
        <path d="M680.295 663.029C678.738 663.301 676.911 664.483 676.649 665.372C676.386 666.261 677.444 666.658 678.713 666.418C680.253 666.167 681.995 665.027 682.3 664.054C682.605 663.081 681.708 662.778 680.295 663.029Z" fill="white" fill-opacity="0.6"/>
        <path d="M706.53 659.975C706.047 660.948 707.088 661.669 708.619 661.418C709.846 661.28 710.987 660.578 711.817 659.452C712.257 658.552 711.614 657.893 710.345 657.946C708.898 657.977 707.02 658.992 706.53 659.975Z" fill="white" fill-opacity="0.6"/>
        <path d="M7.2463 644.736C7.21652 645.212 7.31945 645.686 7.53799 646.08C7.75654 646.474 8.07754 646.764 8.44763 646.901C9.006 646.984 9.52205 646.315 9.58973 645.405C9.64696 644.944 9.56227 644.474 9.35236 644.086C9.14244 643.699 8.82261 643.423 8.4561 643.313C8.14764 643.323 7.85297 643.473 7.62939 643.736C7.40581 643.999 7.2693 644.355 7.2463 644.736Z" fill="white" fill-opacity="0.6"/>
        <path d="M19.9787 645.133C19.4372 645.028 18.8958 645.98 18.9211 647.026C18.9465 648.072 19.378 648.878 19.8179 648.993C20.2579 649.108 20.8416 648.198 20.8332 647.058C20.8247 645.918 20.4694 645.227 19.9787 645.133Z" fill="white" fill-opacity="0.6"/>
        <path d="M28.4472 648.898C28.3372 650.028 28.6755 651.273 29.0986 651.388C29.5216 651.503 29.86 650.708 29.9446 649.662C30.0715 648.428 29.7838 647.267 29.3185 647.194C28.8532 647.12 28.5318 647.947 28.4472 648.898Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.4403 651.743C37.5418 650.541 37.3641 649.547 37.0341 649.442C36.7042 649.338 36.1881 650.488 36.1374 651.691C36.0697 652.737 36.2812 653.898 36.5688 654.034C36.8565 654.17 37.3387 653.093 37.4403 651.743Z" fill="white" fill-opacity="0.6"/>
        <path d="M42.7109 656.868C42.9563 657.004 43.3201 656.074 43.557 654.777C43.7854 653.281 43.7177 651.879 43.3962 651.775C43.0747 651.67 42.7448 652.643 42.5502 653.804C42.2879 655.195 42.4233 656.712 42.7109 656.868Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.897 656.523C47.6347 658.029 47.6347 659.661 47.897 659.839C48.1593 660.017 48.4892 658.96 48.743 657.559C48.9905 656.519 49.0311 655.421 48.8615 654.358C48.6246 653.919 48.1593 654.986 47.897 656.523Z" fill="white" fill-opacity="0.6"/>
        <path d="M52.6007 659.567C52.3046 661.23 52.2708 662.903 52.533 662.883C52.7953 662.862 53.1845 661.554 53.4552 660.069C53.7259 658.584 53.8105 657.078 53.6244 656.827C53.4383 656.576 52.8968 657.852 52.6007 659.567Z" fill="white" fill-opacity="0.6"/>
        <path d="M57.1438 662.433C56.7884 664.263 56.7123 666.041 56.9915 665.947C57.2707 665.853 57.7106 664.431 58.0321 662.809C58.3536 661.188 58.4889 659.588 58.2859 659.368C58.0828 659.149 57.516 660.592 57.1438 662.433Z" fill="white" fill-opacity="0.6"/>
        <path d="M61.8898 665.225C61.4752 667.192 61.3653 669.095 61.6783 668.98C61.9913 668.865 62.5243 667.317 62.8712 665.55C63.2181 663.782 63.4127 662.088 63.1927 661.868C62.9727 661.648 62.3128 663.228 61.8898 665.225Z" fill="white" fill-opacity="0.6"/>
        <path d="M67.2365 667.924C66.7627 670.016 66.6443 672.108 66.9996 671.93C67.355 671.752 67.9472 670.12 68.3786 668.238C68.8101 666.355 68.9793 664.546 68.734 664.315C68.4886 664.085 67.7187 665.79 67.2365 667.924Z" fill="white" fill-opacity="0.6"/>
        <path d="M75.2821 666.679C74.9437 666.303 74.14 668.06 73.5901 670.382C73.1332 672.254 72.9471 674.241 73.1925 674.67C73.4378 675.099 74.3177 673.29 74.8845 670.947C75.4175 668.865 75.5867 666.993 75.2821 666.679Z" fill="white" fill-opacity="0.6"/>
        <path d="M81.0605 677.369C81.4412 677.494 82.2448 675.821 82.8286 673.698C83.5139 671.166 83.6746 668.897 83.1924 668.823C82.7101 668.75 81.9657 670.466 81.4327 672.453C80.8067 674.764 80.6205 677.233 81.0605 677.369Z" fill="white" fill-opacity="0.6"/>
        <path d="M90.8062 675.078C90.1886 677.505 90.1886 679.827 90.8062 679.68C91.4238 679.534 92.2783 677.588 92.8282 675.434C93.3781 673.279 93.4965 671.187 93.0397 670.915C92.5828 670.643 91.4407 672.589 90.8062 675.078Z" fill="white" fill-opacity="0.6"/>
        <path d="M105.045 676.94C105.595 674.597 105.544 672.756 104.909 672.683C104.275 672.61 102.963 674.984 102.371 677.431C101.889 679.523 101.94 681.615 102.49 681.751C103.04 681.887 104.41 679.565 105.045 676.94Z" fill="white" fill-opacity="0.6"/>
        <path d="M269.314 553.75C268.654 554.503 268.975 554.514 269.965 553.75C270.922 553.026 271.83 552.207 272.681 551.303C273.417 550.476 273.112 550.466 272.038 551.303C271.081 552.033 270.171 552.851 269.314 553.75Z" fill="white" fill-opacity="0.6"/>
        <path d="M251.996 568.393C252.841 567.633 253.651 566.816 254.424 565.946C255.109 565.14 254.889 565.151 253.959 565.946C253.108 566.708 252.294 567.533 251.522 568.414C250.905 569.178 251.141 569.157 251.996 568.393Z" fill="white" fill-opacity="0.6"/>
        <path d="M236.395 583.36C237.03 582.702 238.028 581.551 238.578 580.84C239.128 580.129 239.103 579.961 238.299 580.777C237.534 581.567 236.806 582.408 236.116 583.298C235.524 584.082 235.659 584.145 236.395 583.36Z" fill="white" fill-opacity="0.6"/>
        <path d="M222.876 598.234C223.426 597.596 224.331 596.393 224.864 595.619C225.49 594.688 225.431 594.573 224.737 595.357C224.171 596.016 223.257 597.219 222.749 597.972C222.242 598.725 222.242 598.976 222.876 598.234Z" fill="white" fill-opacity="0.6"/>
        <path d="M213.181 609.571C213.613 608.797 213.545 608.693 213.02 609.341C212.283 610.303 211.604 611.331 210.99 612.416C210.609 613.117 210.694 613.232 211.151 612.657C211.878 611.681 212.556 610.65 213.181 609.571Z" fill="white" fill-opacity="0.6"/>
        <path d="M200.99 625.281C200.423 626.401 200.373 626.829 200.872 626.15C201.489 625.237 202.055 624.272 202.564 623.263C203.173 622.091 203.224 621.683 202.691 622.395C202.062 623.299 201.494 624.264 200.99 625.281Z" fill="white" fill-opacity="0.6"/>
        <path d="M193.723 634.046C193.007 635.112 192.38 636.265 191.853 637.487C191.447 638.471 191.397 638.952 191.752 638.481C192.466 637.409 193.09 636.248 193.613 635.019C194.053 633.952 194.104 633.534 193.723 634.046Z" fill="white" fill-opacity="0.6"/>
        <path d="M185.525 644.045C184.79 645.155 184.171 646.375 183.681 647.675C183.249 648.815 183.165 649.505 183.495 649.097C184.225 647.976 184.845 646.751 185.339 645.447C185.821 644.202 185.897 643.606 185.525 644.045Z" fill="white" fill-opacity="0.6"/>
        <path d="M176.278 655.268C175.669 656.879 175.432 658.092 175.847 657.695C176.514 656.699 177.066 655.593 177.488 654.41C178.14 652.706 178.334 651.597 177.928 652.015C177.256 652.997 176.7 654.092 176.278 655.268Z" fill="white" fill-opacity="0.6"/>
        <path d="M168.969 660.697C168.318 662.485 168.055 664.023 168.436 663.834C169.17 662.88 169.744 661.757 170.128 660.529C170.72 658.918 170.974 657.58 170.796 657.454C170.619 657.329 169.62 658.887 168.969 660.697Z" fill="white" fill-opacity="0.6"/>
        <path d="M159.95 669.984C160.77 668.871 161.41 667.576 161.837 666.167C162.573 664.075 162.776 662.59 162.26 663.029C161.429 664.138 160.777 665.434 160.34 666.847C159.663 668.782 159.468 670.361 159.95 669.984Z" fill="white" fill-opacity="0.6"/>
        <path d="M149.257 675.611C149.815 675.549 150.822 673.907 151.482 671.993C152.269 669.712 152.328 667.809 151.66 667.882C151.084 667.955 150.018 669.65 149.392 671.491C148.665 673.624 148.606 675.695 149.257 675.611Z" fill="white" fill-opacity="0.6"/>
        <path d="M135.188 673.781C134.46 676.134 134.536 678.289 135.332 678.111C136.127 677.933 137.134 676.019 137.81 673.928C138.597 671.428 138.563 669.409 137.717 669.618C137.041 669.796 135.805 671.794 135.188 673.781Z" fill="white" fill-opacity="0.6"/>
        <path d="M118.479 676.542C117.743 679.032 117.946 681.333 118.868 681.165C119.791 680.998 120.873 679.021 121.55 676.762C122.354 674.116 122.202 671.961 121.22 672.181C120.417 672.359 119.088 674.451 118.479 676.542Z" fill="white" fill-opacity="0.6"/>
        <path d="M497.016 692.231C496.229 692.932 496.424 693.277 497.456 692.953C498.614 692.557 499.713 691.935 500.713 691.112C501.492 690.411 501.331 690.066 500.341 690.38C499.16 690.775 498.038 691.4 497.016 692.231Z" fill="white" fill-opacity="0.6"/>
        <path d="M477.135 694.721C476.289 695.422 476.492 695.934 477.507 695.903C478.681 695.768 479.815 695.307 480.824 694.553C481.593 693.894 481.424 693.361 480.46 693.413C479.285 693.525 478.147 693.973 477.135 694.721Z" fill="white" fill-opacity="0.6"/>
        <path d="M457.618 694.313C458.738 694.398 459.853 694.051 460.807 693.319C461.45 692.681 461.112 692.054 460.079 691.939C458.964 691.836 457.85 692.173 456.899 692.901C456.222 693.56 456.543 694.198 457.618 694.313Z" fill="white" fill-opacity="0.6"/>
        <path d="M438.159 688.476C439.194 688.79 440.286 688.583 441.197 687.901C441.806 687.274 441.484 686.489 440.511 686.165C440.004 686.003 439.475 685.968 438.956 686.063C438.437 686.159 437.939 686.382 437.491 686.719C436.848 687.368 437.144 688.152 438.159 688.476Z" fill="white" fill-opacity="0.6"/>
        <path d="M419.801 676.386C419.065 676.94 419.133 677.902 419.953 678.477C420.389 678.765 420.874 678.921 421.369 678.932C421.863 678.943 422.352 678.809 422.796 678.54C423.498 678.028 423.414 676.992 422.635 676.448C422.201 676.158 421.718 676.001 421.224 675.99C420.731 675.979 420.243 676.115 419.801 676.386Z" fill="white" fill-opacity="0.6"/>
        <path d="M404.378 665.1C404.748 665.418 405.185 665.598 405.635 665.616C406.085 665.635 406.53 665.492 406.917 665.205C407.483 664.587 407.289 663.489 406.502 662.82C406.137 662.489 405.699 662.301 405.246 662.283C404.793 662.264 404.346 662.415 403.964 662.715C403.355 663.353 403.532 664.41 404.378 665.1Z" fill="white" fill-opacity="0.6"/>
        <path d="M388.617 647.643C388.928 648.073 389.339 648.374 389.793 648.505C390.247 648.636 390.723 648.591 391.155 648.376C391.773 647.884 391.697 646.566 390.995 645.677C390.689 645.238 390.279 644.93 389.822 644.799C389.366 644.667 388.888 644.718 388.457 644.945C387.814 645.478 387.881 646.702 388.617 647.643Z" fill="white" fill-opacity="0.6"/>
        <path d="M376.19 625.919C375.479 624.957 374.388 624.664 373.829 625.302C373.271 625.94 373.372 627.195 374.108 628.189C374.844 629.183 375.944 629.434 376.477 628.827C377.01 628.22 376.883 626.861 376.19 625.919Z" fill="white" fill-opacity="0.6"/>
        <path d="M360.386 604.948C360.122 605.386 359.999 605.929 360.041 606.473C360.084 607.016 360.288 607.52 360.615 607.888C361.258 608.839 362.307 609.122 362.848 608.494C363.099 608.046 363.215 607.503 363.175 606.96C363.135 606.416 362.941 605.908 362.628 605.524C361.951 604.551 360.919 604.29 360.386 604.948Z" fill="white" fill-opacity="0.6"/>
        <path d="M350.04 585.892C349.838 585.433 349.502 585.088 349.1 584.929C348.698 584.769 348.262 584.807 347.882 585.034C347.566 585.383 347.375 585.873 347.35 586.396C347.324 586.919 347.467 587.433 347.747 587.827C347.819 588.085 347.939 588.319 348.096 588.51C348.254 588.702 348.445 588.847 348.655 588.932C348.864 589.018 349.087 589.043 349.306 589.004C349.525 588.966 349.734 588.866 349.916 588.712C350.099 588.558 350.25 588.354 350.359 588.116C350.467 587.878 350.53 587.612 350.542 587.34C350.554 587.067 350.515 586.794 350.427 586.543C350.34 586.292 350.208 586.069 350.04 585.892Z" fill="white" fill-opacity="0.6"/>
        <path d="M335.564 567.316C335.304 567.703 335.174 568.2 335.202 568.703C335.231 569.206 335.415 569.675 335.717 570.014C335.844 570.2 336 570.353 336.176 570.464C336.351 570.575 336.543 570.643 336.739 570.662C336.936 570.681 337.133 570.653 337.321 570.577C337.508 570.502 337.682 570.382 337.832 570.224C338.08 569.82 338.202 569.319 338.174 568.813C338.146 568.307 337.97 567.831 337.679 567.473C337.552 567.286 337.394 567.133 337.217 567.023C337.04 566.913 336.847 566.849 336.649 566.834C336.451 566.82 336.253 566.855 336.067 566.938C335.88 567.02 335.709 567.149 335.564 567.316Z" fill="white" fill-opacity="0.6"/>
        <path d="M325.7 552.338C325.396 552.034 325.013 551.879 324.623 551.903C324.233 551.926 323.864 552.125 323.585 552.464C323.451 552.621 323.346 552.812 323.277 553.024C323.208 553.237 323.177 553.465 323.186 553.693C323.194 553.921 323.243 554.144 323.328 554.348C323.413 554.551 323.532 554.729 323.678 554.869C323.979 555.169 324.356 555.326 324.742 555.312C325.128 555.299 325.498 555.116 325.785 554.796C325.91 554.628 326.007 554.431 326.071 554.216C326.134 554.002 326.163 553.774 326.155 553.545C326.147 553.317 326.103 553.093 326.025 552.886C325.947 552.678 325.836 552.492 325.7 552.338Z" fill="white" fill-opacity="0.6"/>
        <path d="M311.605 541.889C310.996 542.559 310.979 543.448 311.563 543.897C311.908 544.104 312.295 544.179 312.674 544.114C313.054 544.049 313.409 543.846 313.695 543.531C313.833 543.417 313.946 543.264 314.026 543.085C314.106 542.906 314.151 542.706 314.156 542.502C314.161 542.297 314.126 542.094 314.055 541.909C313.983 541.725 313.878 541.564 313.746 541.44C313.394 541.239 313.001 541.174 312.619 541.255C312.237 541.335 311.884 541.556 311.605 541.889Z" fill="white" fill-opacity="0.6"/>
        <path d="M299.068 537.005C298.475 537.674 298.501 538.323 299.118 538.448C299.923 538.497 300.71 538.151 301.31 537.486C301.868 536.858 301.851 536.105 301.31 535.98C300.9 535.958 300.49 536.038 300.105 536.214C299.721 536.39 299.368 536.659 299.068 537.005Z" fill="white" fill-opacity="0.6"/>
        <path d="M514.359 688.686C513.513 689.449 513.674 689.868 514.655 689.658C515.81 689.34 516.902 688.742 517.87 687.901C518.632 687.18 518.471 686.74 517.549 686.939C516.404 687.26 515.321 687.854 514.359 688.686Z" fill="white" fill-opacity="0.6"/>
        <path d="M534.537 684.628C533.648 685.422 533.75 685.945 534.782 685.789C536.017 685.52 537.192 684.936 538.234 684.073C539.08 683.32 538.936 682.766 537.954 682.912C536.731 683.183 535.566 683.767 534.537 684.628Z" fill="white" fill-opacity="0.6"/>
        <path d="M557.142 680.371C556.516 681.145 557.032 681.5 558.326 681.186C559.591 680.88 560.778 680.202 561.786 679.21C562.362 678.467 561.786 678.101 560.577 678.394C559.321 678.706 558.144 679.384 557.142 680.371Z" fill="white" fill-opacity="0.6"/>
        <path d="M582.319 675.34C581.777 676.124 582.429 676.48 583.816 676.145C585.108 675.866 586.315 675.162 587.31 674.105C587.809 673.352 587.099 672.986 585.796 673.3C584.513 673.592 583.314 674.295 582.319 675.34Z" fill="white" fill-opacity="0.6"/>
        <path d="M609.425 669.828C608.782 670.664 609.374 671.145 610.728 670.874C612.062 670.638 613.32 669.959 614.366 668.907C614.975 668.133 614.315 667.6 613.046 667.861C611.718 668.103 610.467 668.782 609.425 669.828Z" fill="white" fill-opacity="0.6"/>
        <path d="M639.416 665.372C640.863 665.037 642.453 664.064 642.8 663.28C643.147 662.496 642.267 662.088 640.879 662.412C639.492 662.736 637.893 663.698 637.495 664.504C637.098 665.309 637.935 665.728 639.416 665.372Z" fill="white" fill-opacity="0.6"/>
        <path d="M666.48 658.877C665.997 659.734 666.776 660.236 668.172 659.996C669.476 659.82 670.696 659.114 671.64 657.988C672.097 657.193 671.234 656.638 669.898 656.869C668.611 657.058 667.411 657.763 666.48 658.877Z" fill="white" fill-opacity="0.6"/>
        <path d="M695.405 653.741C695.151 654.578 696.115 654.996 697.587 654.683C699.059 654.369 700.481 653.396 700.701 652.591C700.921 651.785 699.905 651.356 698.501 651.649C697.097 651.942 695.684 652.894 695.405 653.741Z" fill="white" fill-opacity="0.6"/>
        <path d="M1.92488 636.441C2.08921 636.428 2.24974 636.375 2.39689 636.283C2.54404 636.192 2.67479 636.065 2.78137 635.91C2.88795 635.754 2.96818 635.574 3.01725 635.38C3.06632 635.186 3.08326 634.981 3.06702 634.778C3.02442 634.352 2.85904 633.959 2.60116 633.671C2.34328 633.383 2.01011 633.219 1.66262 633.209C1.49622 633.218 1.3331 633.269 1.18354 633.36C1.03398 633.45 0.90129 633.578 0.793892 633.736C0.686494 633.893 0.606726 634.076 0.55967 634.274C0.512614 634.471 0.499278 634.679 0.52053 634.883C0.556249 635.312 0.71982 635.709 0.979421 635.997C1.23902 636.285 1.57605 636.444 1.92488 636.441Z" fill="white" fill-opacity="0.6"/>
        <path d="M13.6336 635.95C13.578 636.358 13.6421 636.777 13.8143 637.132C13.9864 637.486 14.2554 637.753 14.5727 637.885C15.1395 638.021 15.6556 637.383 15.7402 636.462C15.7872 636.054 15.7207 635.638 15.5517 635.283C15.3826 634.928 15.121 634.654 14.8096 634.506C14.2343 634.35 13.6928 635.04 13.6336 635.95Z" fill="white" fill-opacity="0.6"/>
        <path d="M24.5217 637.487C24.4794 638.533 24.8262 639.349 25.3169 639.412C25.8076 639.475 26.2222 638.627 26.2645 637.655C26.3068 636.682 25.943 635.751 25.4777 635.709C25.0124 635.667 24.5217 636.441 24.5217 637.487Z" fill="white" fill-opacity="0.6"/>
        <path d="M33.4471 638.973C33.3963 640.081 33.6839 640.928 34.09 640.908C34.4961 640.887 34.8768 639.862 34.936 638.816C34.9953 637.77 34.6992 636.839 34.3015 636.881C33.9039 636.923 33.4978 637.874 33.4471 638.973Z" fill="white" fill-opacity="0.6"/>
        <path d="M40.9596 639.956C40.7904 641.179 40.9596 642.278 41.2472 642.445C41.5349 642.612 41.9579 641.755 42.1355 640.541C42.3132 639.328 42.1355 638.178 41.8563 638.031C41.5772 637.885 41.1203 638.763 40.9596 639.956Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.2877 641.472C47.0932 642.811 47.1693 643.972 47.4485 644.066C47.7277 644.16 48.1338 643.02 48.2945 641.734C48.4552 640.447 48.413 639.171 48.1422 639.109C47.8715 639.046 47.4823 640.134 47.2877 641.472Z" fill="white" fill-opacity="0.6"/>
        <path d="M53.836 643.083C54.0898 641.661 54.0813 640.27 53.836 640.217C53.5907 640.165 53.1338 641.368 52.88 642.832C52.6262 644.296 52.6346 645.583 52.88 645.667C53.1253 645.75 53.5907 644.516 53.836 643.083Z" fill="white" fill-opacity="0.6"/>
        <path d="M58.1504 643.962C57.812 645.593 57.7359 647.037 57.9643 647.235C58.1928 647.434 58.6496 646.263 58.988 644.662C59.3264 643.062 59.4026 641.525 59.1741 641.368C58.9457 641.211 58.4719 642.372 58.1504 643.962Z" fill="white" fill-opacity="0.6"/>
        <path d="M64.5293 642.487C64.3093 642.246 63.7848 643.533 63.3957 645.217C63.0065 646.901 62.8542 648.574 63.0826 648.825C63.3111 649.076 63.8186 647.853 64.2247 646.127C64.6308 644.401 64.7577 642.717 64.5293 642.487Z" fill="white" fill-opacity="0.6"/>
        <path d="M70.2062 643.533C69.9016 643.533 69.2925 645.102 68.8695 646.974C68.4465 648.846 68.3449 650.415 68.6495 650.478C68.9541 650.541 69.5801 648.878 69.9862 647.068C70.3923 645.259 70.5361 643.564 70.2062 643.533Z" fill="white" fill-opacity="0.6"/>
        <path d="M75.003 652.047C75.3245 652.162 76.0182 650.572 76.5089 648.585C76.9996 646.597 77.118 644.757 76.805 644.662C76.492 644.568 75.7814 646.158 75.2991 648.166C74.8169 650.174 74.6731 651.932 75.003 652.047Z" fill="white" fill-opacity="0.6"/>
        <path d="M84.4612 645.803C84.0466 645.803 83.2514 647.581 82.7691 649.693C82.2869 651.806 82.1516 653.553 82.5746 653.605C82.9976 653.657 83.8182 651.785 84.2666 649.767C84.715 647.748 84.8588 645.855 84.4612 645.803Z" fill="white" fill-opacity="0.6"/>
        <path d="M91.6439 651.356C91.1193 653.584 91.1447 655.226 91.6946 655.09C92.2445 654.954 93.1582 652.999 93.6573 650.792C94.1565 648.585 94.1227 646.901 93.5897 647.026C93.0567 647.152 92.1599 649.149 91.6439 651.356Z" fill="white" fill-opacity="0.6"/>
        <path d="M102.693 656.565C103.327 656.471 104.385 654.473 104.884 652.204C105.383 649.934 105.324 648.135 104.706 648.229C104.089 648.323 103.073 650.321 102.524 652.632C101.974 654.944 102.041 656.659 102.693 656.565Z" fill="white" fill-opacity="0.6"/>
        <path d="M286.064 539.609C286.904 539.234 287.676 538.662 288.34 537.925C288.839 537.319 288.916 536.701 287.866 537.455C286.843 538.174 285.878 539.011 284.982 539.954C284.499 540.54 284.965 540.394 286.064 539.609Z" fill="white" fill-opacity="0.6"/>
        <path d="M266.378 554.336C267.266 553.601 268.114 552.794 268.916 551.92C269.322 551.418 268.857 551.648 267.926 552.411C267.037 553.146 266.19 553.953 265.388 554.827C264.99 555.35 265.405 555.141 266.378 554.336Z" fill="white" fill-opacity="0.6"/>
        <path d="M249.314 569.073C250.113 568.316 250.879 567.506 251.607 566.646C252.004 566.144 251.607 566.354 250.828 567.096C250.03 567.842 249.27 568.649 248.553 569.512C248.138 570.056 248.468 569.857 249.314 569.073Z" fill="white" fill-opacity="0.6"/>
        <path d="M234.12 583.632C233.646 584.323 233.798 584.291 234.492 583.58C235.234 582.797 235.941 581.962 236.607 581.08C237.064 580.453 236.878 580.484 236.226 581.143C235.481 581.915 234.777 582.746 234.12 583.632Z" fill="white" fill-opacity="0.6"/>
        <path d="M221.565 597.219C221.142 597.93 221.303 597.92 221.929 597.219C222.62 596.408 223.265 595.538 223.858 594.615C224.255 593.956 224.078 593.977 223.477 594.615C222.785 595.419 222.146 596.29 221.565 597.219Z" fill="white" fill-opacity="0.6"/>
        <path d="M212.657 606.779C211.989 607.618 211.386 608.532 210.855 609.509C210.364 610.419 210.389 610.638 210.914 610.011C211.574 609.165 212.18 608.255 212.724 607.291C213.181 606.465 213.181 606.224 212.657 606.779Z" fill="white" fill-opacity="0.6"/>
        <path d="M201.438 621.38C202.08 620.484 202.647 619.51 203.13 618.472C203.57 617.531 203.562 617.186 203.13 617.74C202.479 618.627 201.911 619.602 201.438 620.648C200.939 621.673 200.956 621.987 201.438 621.38Z" fill="white" fill-opacity="0.6"/>
        <path d="M194.797 626.892C194.125 627.796 193.556 628.81 193.105 629.904C192.589 631.149 192.505 631.735 192.919 631.264C193.58 630.352 194.148 629.344 194.611 628.262C195.085 627.133 195.178 626.484 194.797 626.892Z" fill="white" fill-opacity="0.6"/>
        <path d="M185.263 639.098C185.955 638.136 186.525 637.05 186.955 635.877C187.437 634.601 187.53 633.785 187.141 634.214C186.439 635.17 185.867 636.262 185.449 637.446C184.958 638.816 184.874 639.579 185.263 639.098Z" fill="white" fill-opacity="0.6"/>
        <path d="M178.393 642.288C177.818 643.847 177.623 644.997 177.962 644.819C178.696 643.93 179.272 642.861 179.654 641.682C180.187 640.249 180.407 639.004 180.085 639.14C179.338 640.019 178.759 641.095 178.393 642.288Z" fill="white" fill-opacity="0.6"/>
        <path d="M170.881 645.855C170.289 647.581 170.136 648.794 170.542 648.585C171.345 647.606 171.964 646.42 172.353 645.112C172.903 643.522 173.081 642.152 172.708 642.33C172.336 642.508 171.482 644.098 170.881 645.855Z" fill="white" fill-opacity="0.6"/>
        <path d="M163.808 647.309C163.402 647.392 162.488 649.055 161.828 650.928C161.168 652.8 160.982 654.306 161.405 654.17C161.828 654.034 162.708 652.413 163.368 650.572C164.028 648.731 164.214 647.235 163.808 647.309Z" fill="white" fill-opacity="0.6"/>
        <path d="M151.423 647.183C150.729 647.256 149.604 649.118 149.02 651.199C148.436 653.281 148.512 654.871 149.24 654.756C149.968 654.641 151.067 652.747 151.626 650.792C152.184 648.836 152.15 647.11 151.423 647.183Z" fill="white" fill-opacity="0.6"/>
        <path d="M133.758 651.754C133.047 654.044 133.123 655.938 133.902 656C134.68 656.063 135.848 654.316 136.55 652.109C137.252 649.903 137.167 647.926 136.423 647.832C135.678 647.738 134.443 649.516 133.758 651.754Z" fill="white" fill-opacity="0.6"/>
        <path d="M115.797 653.417C115.138 655.749 115.188 657.799 116.102 657.82C117.016 657.841 118.293 655.969 118.97 653.637C119.647 651.304 119.41 649.317 118.539 649.285C117.667 649.254 116.457 651.084 115.797 653.417Z" fill="white" fill-opacity="0.6"/>
        <path d="M496.373 686.646C495.527 687.316 495.629 687.828 496.635 687.587C497.656 687.343 498.635 686.883 499.529 686.228C500.468 685.537 500.535 685.035 499.689 685.13C498.52 685.39 497.398 685.903 496.373 686.646Z" fill="white" fill-opacity="0.6"/>
        <path d="M475.798 687.42C475.003 688.037 475.155 688.707 476.162 688.665C477.163 688.642 478.144 688.319 479.022 687.723C479.868 687.106 479.927 686.479 479.022 686.364C477.894 686.388 476.788 686.751 475.798 687.42Z" fill="white" fill-opacity="0.6"/>
        <path d="M455.545 683.78C454.775 684.356 454.91 685.182 455.875 685.339C456.813 685.527 457.775 685.344 458.624 684.816C459.47 684.251 459.513 683.498 458.684 683.195C457.614 682.957 456.51 683.163 455.545 683.78Z" fill="white" fill-opacity="0.6"/>
        <path d="M436.273 676.072C435.613 676.689 435.884 677.567 436.84 677.892C437.709 678.142 438.622 678.02 439.429 677.547C440.122 676.992 439.987 676.051 439.166 675.674C438.688 675.487 438.184 675.425 437.686 675.494C437.187 675.562 436.706 675.759 436.273 676.072Z" fill="white" fill-opacity="0.6"/>
        <path d="M421.705 666.439C421.869 666.359 422.012 666.224 422.117 666.049C422.223 665.873 422.287 665.665 422.302 665.448C422.317 665.23 422.282 665.012 422.202 664.818C422.122 664.623 422 664.46 421.849 664.347C421.453 664.036 420.999 663.857 420.531 663.825C420.062 663.794 419.596 663.913 419.175 664.169C419.052 664.27 418.948 664.404 418.872 664.561C418.795 664.718 418.748 664.894 418.733 665.076C418.719 665.258 418.737 665.442 418.787 665.615C418.837 665.787 418.918 665.944 419.023 666.073C419.409 666.407 419.857 666.616 420.326 666.68C420.795 666.744 421.269 666.661 421.705 666.439Z" fill="white" fill-opacity="0.6"/>
        <path d="M403.431 650.907C403.78 651.31 404.216 651.579 404.686 651.681C405.156 651.783 405.64 651.714 406.079 651.482C406.223 651.311 406.332 651.101 406.398 650.868C406.464 650.635 406.485 650.386 406.459 650.142C406.432 649.897 406.36 649.664 406.247 649.461C406.134 649.257 405.983 649.09 405.808 648.972C405.496 648.637 405.116 648.415 404.709 648.328C404.302 648.242 403.883 648.294 403.499 648.48C402.686 648.909 402.686 650.039 403.431 650.907Z" fill="white" fill-opacity="0.6"/>
        <path d="M387.636 629.622C387.078 630.26 387.281 631.588 388.059 632.383C388.351 632.691 388.708 632.891 389.088 632.962C389.467 633.032 389.855 632.969 390.208 632.781C390.8 632.237 390.707 630.867 390.039 630.04C389.741 629.634 389.338 629.37 388.899 629.293C388.46 629.217 388.013 629.333 387.636 629.622Z" fill="white" fill-opacity="0.6"/>
        <path d="M373.482 611.684C374.066 612.636 375.225 613.034 375.775 612.479C376.051 612.072 376.191 611.551 376.168 611.021C376.144 610.49 375.958 609.991 375.648 609.624C375.419 609.256 375.098 608.993 374.735 608.876C374.372 608.758 373.988 608.793 373.643 608.975C373.476 609.129 373.338 609.326 373.238 609.552C373.138 609.778 373.078 610.028 373.063 610.285C373.048 610.541 373.077 610.799 373.15 611.041C373.222 611.282 373.335 611.502 373.482 611.684Z" fill="white" fill-opacity="0.6"/>
        <path d="M360.208 588.705C359.967 589.144 359.865 589.678 359.921 590.205C359.977 590.731 360.188 591.214 360.513 591.561C360.759 591.87 361.08 592.071 361.427 592.132C361.775 592.192 362.128 592.109 362.433 591.895C362.697 591.484 362.834 590.971 362.822 590.447C362.81 589.923 362.648 589.421 362.366 589.029C362.245 588.827 362.092 588.658 361.916 588.532C361.74 588.405 361.545 588.324 361.344 588.294C361.142 588.264 360.937 588.284 360.742 588.355C360.547 588.426 360.365 588.545 360.208 588.705Z" fill="white" fill-opacity="0.6"/>
        <path d="M347.857 569.251C347.573 569.612 347.414 570.096 347.414 570.6C347.414 571.104 347.573 571.588 347.857 571.949C348.062 572.296 348.365 572.536 348.706 572.623C349.046 572.71 349.401 572.638 349.701 572.42C350.011 572.096 350.208 571.638 350.252 571.14C350.296 570.641 350.184 570.141 349.938 569.742C349.711 569.342 349.366 569.067 348.978 568.976C348.589 568.884 348.187 568.983 347.857 569.251Z" fill="white" fill-opacity="0.6"/>
        <path d="M337.857 552.631C337.633 552.255 337.297 552.005 336.923 551.935C336.549 551.866 336.169 551.982 335.865 552.26C335.561 552.537 335.358 552.952 335.302 553.414C335.246 553.876 335.34 554.347 335.564 554.723C335.804 555.098 336.146 555.352 336.527 555.435C336.908 555.517 337.3 555.424 337.629 555.173C337.781 555.027 337.909 554.844 338.003 554.636C338.098 554.428 338.158 554.199 338.179 553.962C338.2 553.725 338.183 553.485 338.127 553.256C338.072 553.027 337.98 552.815 337.857 552.631Z" fill="white" fill-opacity="0.6"/>
        <path d="M323.703 541.523C324.02 541.784 324.398 541.905 324.778 541.867C325.157 541.829 325.514 541.634 325.793 541.314C325.924 541.182 326.029 541.015 326.102 540.826C326.174 540.637 326.212 540.43 326.212 540.221C326.212 540.012 326.174 539.805 326.102 539.616C326.029 539.427 325.924 539.261 325.793 539.128C325.502 538.936 325.171 538.857 324.844 538.902C324.516 538.947 324.207 539.113 323.957 539.379C323.807 539.479 323.678 539.621 323.58 539.793C323.482 539.965 323.419 540.164 323.394 540.372C323.369 540.581 323.384 540.793 323.438 540.993C323.492 541.193 323.583 541.375 323.703 541.523Z" fill="white" fill-opacity="0.6"/>
        <path d="M311.47 531.744C310.954 532.424 311.106 533.156 311.766 533.281C312.447 533.311 313.114 533.028 313.636 532.486C314.177 531.827 314.152 530.97 313.577 530.782C313.189 530.731 312.798 530.792 312.433 530.959C312.067 531.126 311.738 531.394 311.47 531.744Z" fill="white" fill-opacity="0.6"/>
        <path d="M298.628 531.127C299.53 530.951 300.371 530.453 301.047 529.694C301.53 529.066 301.369 528.596 300.717 528.773C299.916 529.034 299.189 529.567 298.619 530.311C298.425 530.698 298.628 530.928 298.628 531.127Z" fill="white" fill-opacity="0.6"/>
        <path d="M514.579 683.508C515.777 683.182 516.912 682.571 517.921 681.709C518.657 681.03 518.539 680.611 517.701 680.883C516.639 681.252 515.643 681.866 514.765 682.693C514.427 683.163 514.613 683.362 514.579 683.508Z" fill="white" fill-opacity="0.6"/>
        <path d="M532.971 678.718C532.125 679.45 532.252 679.963 533.234 679.68C534.241 679.391 535.198 678.881 536.059 678.174C536.956 677.432 536.998 676.877 536.178 677.003C535.033 677.326 533.946 677.908 532.971 678.718Z" fill="white" fill-opacity="0.6"/>
        <path d="M553.504 674.848C553.832 675.102 554.218 675.216 554.604 675.172C555.784 674.892 556.889 674.252 557.819 673.311C558.419 672.599 557.954 672.212 556.888 672.547C555.624 672.959 554.462 673.749 553.504 674.848Z" fill="white" fill-opacity="0.6"/>
        <path d="M577.294 669.43C576.701 670.183 577.294 670.539 578.537 670.162C579.604 669.823 580.607 669.23 581.49 668.416C582.133 667.663 581.684 667.181 580.602 667.453C579.389 667.784 578.257 668.461 577.294 669.43Z" fill="white" fill-opacity="0.6"/>
        <path d="M602.471 664.525C602.957 664.763 603.48 664.867 604.002 664.828C605.224 664.598 606.358 663.908 607.251 662.851C607.691 662.109 606.938 661.732 605.736 662.098C604.464 662.45 603.319 663.301 602.471 664.525Z" fill="white" fill-opacity="0.6"/>
        <path d="M629.628 658.406C629.052 659.19 629.746 659.703 631.032 659.347C632.132 659.114 633.162 658.526 634.018 657.642C634.695 656.816 634.221 656.262 633.029 656.44C631.77 656.696 630.593 657.376 629.628 658.406Z" fill="white" fill-opacity="0.6"/>
        <path d="M657.385 652.727C656.878 653.542 657.647 654.076 658.942 653.72C660.032 653.522 661.051 652.929 661.869 652.015C662.487 651.179 661.937 650.593 660.71 650.76C659.466 650.984 658.307 651.67 657.385 652.727Z" fill="white" fill-opacity="0.6"/>
        <path d="M686.725 648.396C688.679 647.915 690.007 646.974 690.21 646.19C690.413 645.405 689.364 644.997 688.146 645.363C686.928 645.729 685.354 646.88 685.515 647.539C685.616 648.009 686.403 648.187 686.725 648.396Z" fill="white" fill-opacity="0.6"/>
        <path d="M713.518 642.184C713.315 642.989 714.364 643.397 715.658 643.02C716.631 642.793 717.507 642.144 718.128 641.19C718.399 640.395 717.468 639.841 716.276 640.144C715.083 640.447 713.721 641.357 713.518 642.184Z" fill="white" fill-opacity="0.6"/>
        <path d="M7.60164 626.066C7.60267 626.264 7.63594 626.461 7.69944 626.643C7.76295 626.826 7.8554 626.991 7.97136 627.128C8.08731 627.266 8.22442 627.373 8.37453 627.444C8.52464 627.515 8.68468 627.548 8.84525 627.541C9.10722 627.437 9.33589 627.233 9.4985 626.959C9.66111 626.684 9.74932 626.354 9.75049 626.013C9.75049 625.156 9.1837 624.298 8.62533 624.319C8.30544 624.391 8.02032 624.613 7.82956 624.938C7.63881 625.264 7.55714 625.668 7.60164 626.066Z" fill="white" fill-opacity="0.6"/>
        <path d="M21.5777 626.85C21.6708 625.898 21.3154 624.999 20.8078 624.884C20.3002 624.769 19.818 625.658 19.7841 626.589C19.7841 627.3 20.1141 628.21 20.4694 628.335C20.8247 628.461 21.4931 627.76 21.5777 626.85Z" fill="white" fill-opacity="0.6"/>
        <path d="M30.0207 627.028C29.9446 628.074 30.2915 629.026 30.6975 628.9C31.1036 628.775 31.3997 628.315 31.5435 627.29C31.6874 626.264 31.3997 625.271 30.9852 625.198C30.5707 625.124 30.0969 625.982 30.0207 627.028Z" fill="white" fill-opacity="0.6"/>
        <path d="M38.65 627.467C38.5654 628.607 38.8361 629.496 39.2084 629.308C39.6569 628.777 39.926 628.056 39.9613 627.29C40.0798 626.181 39.8514 625.114 39.5045 625.198C39.1577 625.281 38.7346 626.327 38.65 627.467Z" fill="white" fill-opacity="0.6"/>
        <path d="M46.0441 627.582C45.9002 628.827 46.0441 629.8 46.4163 629.58C46.8557 628.947 47.1274 628.162 47.1946 627.331C47.3554 626.108 47.1946 624.936 46.9154 625.009C46.6363 625.083 46.1794 626.317 46.0441 627.582Z" fill="white" fill-opacity="0.6"/>
        <path d="M52.5414 627.572C52.3468 628.942 52.4568 630.019 52.7614 629.768C53.2084 629.04 53.4995 628.184 53.6074 627.279C53.8273 625.93 53.7681 624.643 53.4889 624.727C53.2097 624.811 52.736 626.181 52.5414 627.572Z" fill="white" fill-opacity="0.6"/>
        <path d="M58.565 627.175C58.2774 628.66 58.2689 630.103 58.565 629.904C58.8611 629.706 59.1403 629.005 59.4618 627.488C59.7833 625.972 59.8425 624.518 59.5972 624.35C59.3518 624.183 58.8442 625.668 58.565 627.175Z" fill="white" fill-opacity="0.6"/>
        <path d="M64.3685 627.028C64.0301 628.639 63.9624 630.166 64.2585 629.978C64.5546 629.789 64.8846 628.984 65.2569 627.331C65.6291 625.679 65.7475 624.099 65.5022 623.953C65.2568 623.807 64.7069 625.428 64.3685 627.028Z" fill="white" fill-opacity="0.6"/>
        <path d="M70.3584 626.944C69.9608 628.691 69.8593 630.396 70.1808 630.166C70.5023 629.936 70.8576 629.12 71.2975 627.321C71.7374 625.522 71.8897 623.828 71.6359 623.65C71.3821 623.472 70.7645 625.156 70.3584 626.944Z" fill="white" fill-opacity="0.6"/>
        <path d="M76.5257 630.459C77.0502 630.208 77.7186 628.44 78.1331 626.505C78.5477 624.57 78.5646 623.033 78.1924 623.367C77.4586 624.654 76.9646 626.126 76.7457 627.677C76.6178 628.596 76.5443 629.526 76.5257 630.459Z" fill="white" fill-opacity="0.6"/>
        <path d="M85.5357 627.415C86.0856 625.375 86.2632 623.451 85.9333 623.231C85.6034 623.012 84.8081 624.968 84.3005 626.997C83.9198 628.524 83.7252 630.469 83.9367 630.699C84.1482 630.929 85.0196 629.381 85.5357 627.415Z" fill="white" fill-opacity="0.6"/>
        <path d="M94.444 627.708C95.0362 625.616 95.2054 623.524 94.8078 623.336C94.4102 623.148 93.5219 625.145 92.9719 627.269C92.5574 628.879 92.3798 630.919 92.6251 631.17C92.8705 631.421 93.8941 629.768 94.444 627.708Z" fill="white" fill-opacity="0.6"/>
        <path d="M105.019 628.21C105.654 625.982 105.789 623.88 105.315 623.65C104.842 623.42 103.835 625.511 103.251 627.718C102.811 629.381 102.659 631.515 102.972 631.777C103.285 632.038 104.402 630.344 105.019 628.21Z" fill="white" fill-opacity="0.6"/>
        <path d="M115.501 628.398C114.901 630.626 114.917 632.822 115.586 632.582C116.254 632.341 116.931 631.275 117.599 629.015C118.268 626.756 118.352 624.549 117.794 624.288C117.236 624.026 116.119 626.129 115.501 628.398Z" fill="white" fill-opacity="0.6"/>
        <path d="M280.659 541.053C281.041 540.863 281.411 540.639 281.767 540.383C282.691 539.69 283.57 538.91 284.398 538.051C284.804 537.549 284.271 537.81 283.323 538.553C282.375 539.284 281.483 540.12 280.659 541.053Z" fill="white" fill-opacity="0.6"/>
        <path d="M264.677 553.363C263.85 554.065 263.062 554.833 262.317 555.664C261.953 556.104 262.173 556.03 262.816 555.497C263.672 554.771 264.494 553.985 265.278 553.144C265.76 552.589 265.456 552.704 264.677 553.363Z" fill="white" fill-opacity="0.6"/>
        <path d="M246.615 569.847C247.474 569.052 248.288 568.185 249.052 567.253C249.441 566.73 249.119 566.877 248.417 567.556C247.69 568.229 247.013 568.982 246.395 569.805C246.268 570.035 246.548 569.847 246.615 569.847Z" fill="white" fill-opacity="0.6"/>
        <path d="M232.952 582.639C232.462 583.35 232.546 583.476 233.164 582.827C233.787 582.182 234.372 581.483 234.915 580.735C235.456 579.982 235.465 579.762 234.915 580.254C234.209 580.981 233.552 581.778 232.952 582.639Z" fill="white" fill-opacity="0.6"/>
        <path d="M221.26 594.604C220.829 595.378 220.956 595.472 221.539 594.813C222.118 594.117 222.658 593.372 223.155 592.586C223.612 591.812 223.57 591.54 223.071 592.094C222.402 592.855 221.795 593.695 221.26 594.604Z" fill="white" fill-opacity="0.6"/>
        <path d="M211.405 604.676C210.905 605.639 210.838 606.13 211.32 605.576C211.882 604.894 212.385 604.141 212.817 603.327C213.359 602.355 213.494 601.8 213.122 602.124C212.462 602.879 211.885 603.737 211.405 604.676Z" fill="white" fill-opacity="0.6"/>
        <path d="M202.631 613.515C202.158 614.561 202.158 615.063 202.631 614.498C203.189 613.716 203.682 612.868 204.103 611.967C204.594 610.921 204.687 610.262 204.306 610.649C203.643 611.504 203.08 612.468 202.631 613.515Z" fill="white" fill-opacity="0.6"/>
        <path d="M196.625 617.123C195.953 617.931 195.409 618.885 195.017 619.937C194.603 620.92 194.366 621.913 194.56 621.851C195.215 621.117 195.746 620.232 196.126 619.246C196.684 617.97 196.912 616.998 196.625 617.123Z" fill="white" fill-opacity="0.6"/>
        <path d="M187.801 624.737C187.277 626.181 187.175 627.112 187.59 626.683C188.178 625.833 188.668 624.886 189.045 623.869C189.569 622.51 189.781 621.328 189.459 621.547C188.743 622.466 188.179 623.55 187.801 624.737Z" fill="white" fill-opacity="0.6"/>
        <path d="M181.033 626.589C180.466 628.168 180.187 629.643 180.601 629.371C181.247 628.661 181.738 627.762 182.031 626.756C182.632 625.187 182.928 623.723 182.682 623.618C182.437 623.514 181.633 624.936 181.033 626.589Z" fill="white" fill-opacity="0.6"/>
        <path d="M172.522 632.205C173.473 631.13 174.181 629.768 174.578 628.252C175.111 626.547 175.187 625.281 174.722 625.721C173.863 626.937 173.221 628.362 172.835 629.904C172.662 630.655 172.557 631.426 172.522 632.205Z" fill="white" fill-opacity="0.6"/>
        <path d="M164.519 630.867C165.187 628.963 165.365 627.007 164.84 626.683C164.316 626.359 163.241 628.043 162.607 629.998C162.133 631.473 161.989 633.429 162.336 633.732C162.683 634.036 163.876 632.697 164.519 630.867Z" fill="white" fill-opacity="0.6"/>
        <path d="M148.521 628.649C147.963 630.804 148.199 632.446 148.995 632.069C149.629 631.766 150.687 629.904 151.16 628.283C151.761 626.191 151.677 624.204 150.983 624.288C150.289 624.371 149.088 626.484 148.521 628.649Z" fill="white" fill-opacity="0.6"/>
        <path d="M134.503 624.152C133.8 623.974 132.413 626.139 131.778 628.398C131.296 630.124 131.347 632.226 131.88 632.446C132.574 632.739 133.927 630.877 134.613 628.702C135.298 626.526 135.298 624.34 134.503 624.152Z" fill="white" fill-opacity="0.6"/>
        <path d="M145.712 578.779C145.162 580.808 145.391 582.346 146.211 582.231C147.032 582.116 148.064 580.536 148.572 578.507C149.079 576.478 148.825 574.899 148.081 574.93C147.336 574.962 146.287 576.666 145.712 578.779Z" fill="white" fill-opacity="0.6"/>
        <path d="M162.751 584.783C163.377 585.023 164.333 583.737 164.908 581.969C165.483 580.202 165.526 578.361 164.908 577.963C164.29 577.566 163.368 579.009 162.751 580.871C162.133 582.733 162.116 584.532 162.751 584.783Z" fill="white" fill-opacity="0.6"/>
        <path d="M176.431 587.22C175.881 588.977 175.898 590.536 176.431 590.703C176.964 590.87 177.81 589.741 178.334 587.994C178.859 586.247 178.833 584.605 178.334 584.375C177.835 584.145 176.997 585.39 176.431 587.22Z" fill="white" fill-opacity="0.6"/>
        <path d="M186.422 590.19C185.821 591.864 185.627 593.119 185.957 593.182C186.286 593.245 186.972 592.073 187.505 590.577C188.038 589.082 188.266 587.701 187.978 587.534C187.691 587.366 186.955 588.611 186.422 590.19Z" fill="white" fill-opacity="0.6"/>
        <path d="M195.026 590.923C195.13 590.307 195.167 589.678 195.136 589.05C195.009 588.663 194.29 590.096 193.723 591.571C193.156 593.046 193.055 594.144 193.368 594.113C193.681 594.081 194.298 593.004 195.026 590.923Z" fill="white" fill-opacity="0.6"/>
        <path d="M200.212 592.627C199.662 593.987 199.45 595.023 199.713 595.064C199.975 595.106 200.559 594.165 201.058 592.973C201.557 591.78 201.785 590.64 201.565 590.504C201.345 590.368 200.736 591.341 200.212 592.627Z" fill="white" fill-opacity="0.6"/>
        <path d="M206.845 591.77C206.312 592.983 206.134 593.862 206.413 593.778C206.692 593.694 207.259 592.795 207.767 591.686C208.274 590.577 208.443 589.699 208.198 589.667C207.953 589.636 207.352 590.63 206.845 591.77Z" fill="white" fill-opacity="0.6"/>
        <path d="M215.305 586.854C214.975 587.398 214.459 588.172 214.027 588.946C213.528 589.918 213.503 590.494 213.883 590.211C214.475 589.614 214.962 588.874 215.313 588.036C215.719 587.157 215.787 586.54 215.305 586.854Z" fill="white" fill-opacity="0.6"/>
        <path d="M222.242 584.71C222.829 584.185 223.331 583.528 223.722 582.775C224.188 581.938 224.281 581.436 223.942 581.614C223.354 582.129 222.854 582.783 222.47 583.538C221.963 584.469 221.87 584.961 222.242 584.71Z" fill="white" fill-opacity="0.6"/>
        <path d="M232.132 577.074C232.737 576.54 233.278 575.903 233.739 575.181C234.196 574.46 234.221 574.135 233.815 574.397C233.213 574.92 232.679 575.555 232.233 576.28C231.726 577.085 231.683 577.42 232.132 577.074Z" fill="white" fill-opacity="0.6"/>
        <path d="M243.84 567.473C244.48 566.925 245.073 566.298 245.608 565.601C246.074 564.963 246.048 564.764 245.558 565.13C244.917 565.659 244.331 566.284 243.815 566.992C243.299 567.692 243.307 567.902 243.84 567.473Z" fill="white" fill-opacity="0.6"/>
        <path d="M259.085 554.221C258.671 554.65 257.952 555.361 257.495 555.884C257.038 556.407 257.241 556.407 257.926 555.832C258.613 555.26 259.258 554.616 259.855 553.907C260.219 553.436 259.991 553.478 259.085 554.221Z" fill="white" fill-opacity="0.6"/>
        <path d="M273.814 542.747C273.374 543.249 273.654 543.134 274.457 542.485C275.213 541.881 275.937 541.218 276.623 540.498C277.004 540.059 276.682 540.195 275.955 540.77C275.209 541.375 274.495 542.035 273.814 542.747Z" fill="white" fill-opacity="0.6"/>
        <path d="M293.763 527.529C294.642 526.871 295.489 526.152 296.301 525.374C296.834 524.83 296.589 524.893 295.743 525.51C294.883 526.149 294.052 526.848 293.256 527.602C292.655 528.25 292.858 528.209 293.763 527.529Z" fill="white" fill-opacity="0.6"/>
        <path d="M128.961 578.633C128.428 580.662 128.513 582.168 129.156 581.98C129.799 581.792 130.687 580.139 131.186 578.099C131.685 576.06 131.567 574.512 130.992 574.627C130.416 574.742 129.52 576.51 128.961 578.633Z" fill="white" fill-opacity="0.6"/>
        <path d="M116.076 582.492C116.559 582.545 117.388 581.049 117.929 579.135C118.471 577.221 118.555 575.505 118.107 575.338C117.659 575.171 116.829 576.782 116.271 578.758C115.713 580.735 115.577 582.43 116.076 582.492Z" fill="white" fill-opacity="0.6"/>
        <path d="M104.892 583.716C105.315 583.716 106.06 582.273 106.584 580.432C107.109 578.591 107.202 576.959 106.813 576.823C106.424 576.687 105.688 578.277 105.163 580.17C104.638 582.064 104.512 583.695 104.892 583.716Z" fill="white" fill-opacity="0.6"/>
        <path d="M95.3495 585.515C95.7978 585.264 96.4324 583.758 96.8639 581.928C97.2953 580.097 97.2953 578.79 96.9146 578.988C96.5339 579.187 95.8656 580.777 95.4003 582.67C94.935 584.563 94.9349 585.756 95.3495 585.515Z" fill="white" fill-opacity="0.6"/>
        <path d="M86.9569 584.772C86.4916 586.603 86.3985 587.91 86.7285 587.858C87.0584 587.806 87.6591 586.456 88.0736 584.804C88.4881 583.151 88.5981 581.729 88.2936 581.666C87.989 581.603 87.3883 583.099 86.9569 584.772Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.2076 587.659C78.8015 589.375 78.7253 590.556 79.0299 590.515C79.3344 590.473 79.8759 589.165 80.2312 587.628C80.5865 586.09 80.6881 584.762 80.4089 584.731C80.1297 584.699 79.5883 586.069 79.2076 587.659Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.8558 591.142C71.5597 592.596 71.5766 593.579 71.9066 593.328C72.4784 592.452 72.8547 591.407 72.9979 590.295C73.2771 588.893 73.2517 587.827 72.9472 588.036C72.3866 588.948 72.0123 590.013 71.8558 591.142Z" fill="white" fill-opacity="0.6"/>
        <path d="M64.8255 593.945C64.5294 595.389 64.5294 596.372 64.8255 596.309C65.1216 596.246 65.5615 595.117 65.8238 593.799C66.0861 592.481 66.0945 591.393 65.8238 591.404C65.5531 591.414 65.1301 592.596 64.8255 593.945Z" fill="white" fill-opacity="0.6"/>
        <path d="M58.2773 594.834C58.0827 595.493 57.7359 596.403 57.5244 597.376C57.2706 598.558 57.4313 599.384 57.7612 599.143C58.2843 598.436 58.6053 597.536 58.6749 596.581C58.8441 595.41 58.7257 594.541 58.2773 594.834Z" fill="white" fill-opacity="0.6"/>
        <path d="M49.8343 599.959C49.6482 601.141 49.7582 601.947 50.0966 601.884C50.435 601.821 50.8242 600.89 50.9934 599.792C51.1626 598.694 51.0526 597.826 50.7311 597.847C50.4096 597.868 50.012 598.851 49.8343 599.959Z" fill="white" fill-opacity="0.6"/>
        <path d="M42.3724 600.608C41.8732 600.608 41.4587 601.507 41.3233 602.511C41.188 603.516 41.3233 604.31 41.7294 604.258C42.1355 604.206 42.5754 603.306 42.5754 602.417C42.5751 601.805 42.5067 601.197 42.3724 600.608Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.2795 606.183C32.711 606.183 33.1256 605.409 33.2102 604.52C33.2948 603.631 33.0495 602.898 32.6264 602.888C32.2034 602.878 31.7804 603.672 31.7043 604.582C31.6281 605.492 31.8481 606.225 32.2795 606.183Z" fill="white" fill-opacity="0.6"/>
        <path d="M21.8652 604.53C21.5472 604.541 21.2451 604.704 21.0226 604.985C20.8 605.266 20.6744 605.643 20.6724 606.036C20.6047 606.925 20.9262 607.542 21.4253 607.532C21.7079 607.48 21.9642 607.298 22.143 607.023C22.3218 606.748 22.4099 606.399 22.3898 606.047C22.2852 605.508 22.1079 604.995 21.8652 604.53Z" fill="white" fill-opacity="0.6"/>
        <path d="M7.90628 606.695C7.87816 606.867 7.88025 607.044 7.91242 607.215C7.94459 607.386 8.00609 607.546 8.09274 607.685C8.17939 607.824 8.28917 607.938 8.41464 608.02C8.54011 608.102 8.67836 608.149 8.81998 608.159C9.10679 608.163 9.38338 608.028 9.59164 607.784C9.79989 607.54 9.9236 607.206 9.93672 606.852C9.97037 606.503 9.89164 606.152 9.71753 605.874C9.54341 605.596 9.28785 605.414 9.00608 605.367C8.72066 605.374 8.4483 605.516 8.24408 605.763C8.03987 606.009 7.91909 606.343 7.90628 606.695Z" fill="white" fill-opacity="0.6"/>
        <path d="M697.731 627.562C696.552 627.662 695.442 628.282 694.601 629.308C694.136 630.082 694.601 630.49 695.768 630.27C696.936 630.051 698.306 629.225 698.399 628.649C698.492 628.074 697.943 627.823 697.731 627.562Z" fill="white" fill-opacity="0.6"/>
        <path d="M671.496 633.304C670.293 633.44 669.156 634.053 668.256 635.05C667.715 635.824 668.138 636.211 669.271 635.992C670.405 635.772 671.886 634.946 672.021 634.35C672.156 633.753 671.64 633.513 671.496 633.304Z" fill="white" fill-opacity="0.6"/>
        <path d="M642.157 641.023C641.666 641.713 642.199 642.069 643.341 641.723C644.385 641.501 645.35 640.892 646.108 639.977C646.497 639.328 645.93 638.983 644.906 639.245C643.874 639.511 642.922 640.126 642.157 641.023Z" fill="white" fill-opacity="0.6"/>
        <path d="M616.98 646.901C616.43 647.581 616.887 647.884 617.987 647.581C619.035 647.324 620.01 646.729 620.812 645.855C621.261 645.217 620.812 644.882 619.789 645.154C618.749 645.438 617.784 646.038 616.98 646.901Z" fill="white" fill-opacity="0.6"/>
        <path d="M593.114 652.559C592.496 653.229 592.835 653.542 593.875 653.25C594.916 652.976 595.89 652.402 596.718 651.576C597.242 650.938 596.879 650.614 595.948 650.854C594.916 651.166 593.95 651.748 593.114 652.559Z" fill="white" fill-opacity="0.6"/>
        <path d="M571.312 658.678C572.339 658.409 573.314 657.894 574.18 657.161C574.899 656.492 574.815 656.115 573.994 656.251C572.982 656.531 572.022 657.05 571.169 657.778C570.356 658.5 570.416 658.877 571.312 658.678Z" fill="white" fill-opacity="0.6"/>
        <path d="M551.304 662.673C550.458 663.374 550.382 663.719 551.169 663.594C552.147 663.318 553.079 662.829 553.919 662.15C554.688 661.502 554.722 661.104 554.02 661.23C553.055 661.515 552.136 662.004 551.304 662.673Z" fill="white" fill-opacity="0.6"/>
        <path d="M533.91 667.119C533.157 667.736 533.149 668.06 533.91 667.83C534.832 667.519 535.706 667.024 536.499 666.366C537.168 665.79 537.176 665.435 536.499 665.633C535.581 665.965 534.708 666.465 533.91 667.119Z" fill="white" fill-opacity="0.6"/>
        <path d="M517.447 670.759C516.796 671.104 515.696 671.616 514.951 672.108C514.055 672.693 514.385 672.976 515.358 672.735C516.397 672.467 517.391 671.975 518.293 671.281C518.936 670.738 518.733 670.455 517.447 670.759Z" fill="white" fill-opacity="0.6"/>
        <path d="M493.522 673.865C492.82 674.419 493.048 674.848 494.013 674.806C495.03 674.762 496.022 674.403 496.898 673.76C497.515 673.227 497.287 672.788 496.407 672.808C495.392 672.879 494.404 673.24 493.522 673.865Z" fill="white" fill-opacity="0.6"/>
        <path d="M474.809 670.351C474.017 670.542 473.237 670.8 472.474 671.125C471.628 671.564 472 672.17 472.947 672.338C473.908 672.499 474.884 672.26 475.722 671.658C476.289 671.145 476.052 670.57 474.809 670.351Z" fill="white" fill-opacity="0.6"/>
        <path d="M454.733 663.5C453.986 663.587 453.247 663.758 452.525 664.012C451.738 664.389 452.051 665.163 452.922 665.508C453.352 665.682 453.807 665.744 454.257 665.688C454.707 665.632 455.143 665.46 455.537 665.184C456.095 664.682 455.883 663.97 454.733 663.5Z" fill="white" fill-opacity="0.6"/>
        <path d="M434.649 652.57C433.921 653.02 433.904 653.783 434.649 654.369C435.001 654.657 435.406 654.832 435.827 654.878C436.248 654.923 436.672 654.838 437.06 654.63C437.72 654.191 437.72 653.406 437.06 652.81C436.704 652.531 436.298 652.362 435.878 652.32C435.458 652.279 435.036 652.364 434.649 652.57Z" fill="white" fill-opacity="0.6"/>
        <path d="M419.852 637.33C419.193 637.211 418.523 637.211 417.864 637.33C417.153 637.602 417.441 638.742 418.236 639.422C418.552 639.776 418.947 640 419.37 640.066C419.792 640.131 420.22 640.034 420.596 639.788C421.104 639.36 420.901 638.314 419.852 637.33Z" fill="white" fill-opacity="0.6"/>
        <path d="M404.04 619.069C403.194 617.845 402.204 617.479 401.595 617.876C400.986 618.274 400.944 619.278 401.595 620.167C402.247 621.056 403.38 621.568 403.744 620.972C404.108 620.376 403.981 619.55 404.04 619.069Z" fill="white" fill-opacity="0.6"/>
        <path d="M388.414 597.209C387.859 597.056 387.284 597.035 386.722 597.146C386.096 597.428 386.282 598.715 386.934 599.572C387.585 600.43 388.482 600.692 388.964 600.116C389.446 599.541 389.277 598.37 388.414 597.209Z" fill="white" fill-opacity="0.6"/>
        <path d="M373.135 575.976C373.014 576.151 372.921 576.354 372.863 576.574C372.805 576.793 372.782 577.024 372.796 577.255C372.81 577.485 372.861 577.709 372.945 577.915C373.03 578.121 373.146 578.305 373.288 578.455C373.914 579.334 374.726 579.553 375.208 578.968C375.425 578.586 375.529 578.123 375.501 577.658C375.473 577.193 375.316 576.754 375.056 576.416C374.954 576.229 374.823 576.07 374.671 575.947C374.519 575.824 374.349 575.74 374.17 575.699C373.991 575.658 373.808 575.661 373.63 575.709C373.453 575.757 373.284 575.848 373.135 575.976Z" fill="white" fill-opacity="0.6"/>
        <path d="M362.645 556.951C362.002 555.779 361.148 555.518 360.589 555.999C360.449 556.134 360.333 556.304 360.25 556.498C360.166 556.692 360.117 556.905 360.105 557.125C360.093 557.344 360.118 557.564 360.18 557.77C360.242 557.976 360.338 558.164 360.462 558.321C360.97 559.158 361.926 559.576 362.281 558.928C362.504 558.303 362.627 557.632 362.645 556.951Z" fill="white" fill-opacity="0.6"/>
        <path d="M348.457 538.636C348.32 538.753 348.206 538.905 348.122 539.082C348.038 539.26 347.986 539.457 347.971 539.662C347.956 539.867 347.977 540.073 348.033 540.266C348.089 540.459 348.179 540.635 348.297 540.781C348.509 541.113 348.815 541.335 349.153 541.403C349.491 541.471 349.838 541.379 350.124 541.147C350.251 541.014 350.356 540.851 350.432 540.668C350.509 540.485 350.555 540.285 350.568 540.08C350.581 539.875 350.562 539.669 350.51 539.473C350.459 539.277 350.376 539.096 350.268 538.94C350.054 538.615 349.747 538.402 349.411 538.346C349.075 538.29 348.734 538.394 348.457 538.636Z" fill="white" fill-opacity="0.6"/>
        <path d="M338.483 525.458C338.218 525.134 337.866 524.944 337.494 524.922C337.122 524.901 336.757 525.051 336.47 525.343C335.894 525.907 335.835 526.681 336.343 527.184C336.595 527.443 336.915 527.578 337.242 527.565C337.57 527.551 337.882 527.39 338.119 527.11C338.333 526.597 338.457 526.034 338.483 525.458Z" fill="white" fill-opacity="0.6"/>
        <path d="M324.177 517.53C323.686 518.105 323.746 518.701 324.312 518.868C324.642 518.95 324.983 518.93 325.305 518.81C325.626 518.689 325.919 518.472 326.157 518.178C326.605 517.613 326.546 516.933 326.038 516.756C325.701 516.706 325.359 516.75 325.037 516.884C324.715 517.018 324.422 517.238 324.177 517.53Z" fill="white" fill-opacity="0.6"/>
        <path d="M311.512 517.122C312.208 516.978 312.863 516.618 313.416 516.076C313.957 515.521 313.991 515.093 313.492 515.124C312.793 515.305 312.14 515.693 311.588 516.254C310.996 516.839 310.962 517.205 311.512 517.122Z" fill="white" fill-opacity="0.6"/>
        <path d="M276.885 542.13C277.179 541.987 277.463 541.812 277.731 541.607C278.626 540.925 279.475 540.156 280.269 539.306C280.616 538.898 280.168 539.149 279.297 539.85C278.451 540.54 277.646 541.302 276.885 542.13Z" fill="white" fill-opacity="0.6"/>
        <path d="M259.373 556.355C259.584 556.302 259.584 556.428 259.923 556.198C260.65 555.594 261.345 554.934 262.004 554.221C262.715 553.447 262.681 553.29 261.801 554.064C261.192 554.597 260.414 555.361 259.373 556.355Z" fill="white" fill-opacity="0.6"/>
        <path d="M246.666 567.013C246.251 567.452 245.532 568.174 244.974 568.78C244.289 569.586 244.365 569.763 244.974 569.209C245.635 568.607 246.257 567.942 246.835 567.222C247.402 566.573 247.402 566.364 246.666 567.013Z" fill="white" fill-opacity="0.6"/>
        <path d="M232.132 581.007C232.837 580.34 233.481 579.579 234.052 578.737C234.526 578.037 234.517 577.807 234.052 578.225C233.346 578.876 232.714 579.643 232.174 580.505C231.979 580.892 232.132 580.903 232.132 581.007Z" fill="white" fill-opacity="0.6"/>
        <path d="M221.235 590.421C221.362 590.598 221.32 590.912 221.573 590.745C222.254 590.052 222.847 589.237 223.333 588.329C223.68 587.67 223.587 587.408 223.054 588.046C222.521 588.684 221.997 589.375 221.235 590.421Z" fill="white" fill-opacity="0.6"/>
        <path d="M212.335 597.826C212.234 598.432 212.039 599.028 212.124 599.164C212.208 599.3 212.97 598.192 213.52 597.25C214.163 596.068 214.442 595.159 213.943 595.671C213.621 596.037 213.063 596.874 212.335 597.826Z" fill="white" fill-opacity="0.6"/>
        <path d="M204.044 605.555C204.711 604.9 205.248 604.067 205.618 603.118C206.117 602.072 206.295 601.267 206.024 601.392C205.333 602.085 204.785 602.97 204.425 603.976C204.25 604.483 204.123 605.013 204.044 605.555Z" fill="white" fill-opacity="0.6"/>
        <path d="M197.589 606.998C196.912 608.661 196.684 609.707 196.895 609.739C197.107 609.77 197.902 608.484 198.494 606.978C198.985 605.733 199.18 604.75 198.765 605.21C198.341 605.772 197.948 606.37 197.589 606.998Z" fill="white" fill-opacity="0.6"/>
        <path d="M192.107 606.517C191.643 607.202 191.221 607.929 190.846 608.693C190.229 610 189.899 611.705 190.178 611.747C190.457 611.789 191.024 610.774 191.582 609.321C192.141 607.867 192.403 606.727 192.107 606.517Z" fill="white" fill-opacity="0.6"/>
        <path d="M183.114 612.782C183.901 611.099 184.485 610.032 184.95 608.829C185.534 607.333 185.644 606.183 185.297 606.277C184.95 606.371 183.876 608.285 183.52 609.707C183.32 610.718 183.184 611.746 183.114 612.782Z" fill="white" fill-opacity="0.6"/>
        <path d="M174.849 611.946C174.849 612.458 174.679 613.619 174.849 613.86C175.018 614.1 176.185 611.967 176.777 610.011C177.26 608.431 177.277 607.375 176.777 607.731C175.883 608.936 175.224 610.378 174.849 611.946Z" fill="white" fill-opacity="0.6"/>
        <path d="M162.793 611.161C163.632 609.712 164.398 608.199 165.086 606.632C165.762 604.844 165.661 603.233 165.086 603.139C164.51 603.045 163.038 605.116 162.767 606.957C162.651 608.355 162.66 609.765 162.793 611.161Z" fill="white" fill-opacity="0.6"/>
        <path d="M147.48 607.793C148.495 607.793 149.468 606.224 150.094 604.133C150.72 602.041 150.67 600.367 150.001 600.263C149.155 600.137 147.734 602.26 147.463 604.31C147.334 605.466 147.34 606.639 147.48 607.793Z" fill="white" fill-opacity="0.6"/>
        <path d="M131.364 599.907C130.576 601.01 129.897 602.224 129.342 603.526C128.555 605.691 128.809 607.929 129.807 607.71C130.611 607.511 131.702 605.618 132.252 603.526C132.802 601.434 132.607 599.771 131.364 599.907Z" fill="white" fill-opacity="0.6"/>
        <path d="M115.95 602.396C115.18 605.147 115.104 607.04 115.527 607.302C115.95 607.563 117.083 605.681 117.726 603.118C118.268 601.026 118.293 599.164 117.625 599.761C116.989 600.559 116.427 601.444 115.95 602.396Z" fill="white" fill-opacity="0.6"/>
        <path d="M103.792 607.406C104.452 607.333 105.214 605.733 105.772 603.641C106.33 601.549 106.407 599.949 105.975 599.938C105.544 599.928 104.368 602.03 104.021 604.122C103.863 605.206 103.787 606.305 103.792 607.406Z" fill="white" fill-opacity="0.6"/>
        <path d="M94.148 603.871C94.0106 605.168 93.9428 606.475 93.9449 607.783C94.0465 608.567 94.884 606.925 95.4339 605.095C96.1192 602.783 96.4069 600.399 95.8654 600.911C95.2233 601.833 94.6486 602.824 94.148 603.871Z" fill="white" fill-opacity="0.6"/>
        <path d="M85.3579 608.745C85.8317 608.745 86.4239 607.291 86.9146 605.377C87.4053 603.463 87.5152 602.009 87.2191 601.968C86.923 601.926 85.9755 603.882 85.6455 605.691C85.4936 606.7 85.3975 607.72 85.3579 608.745Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.2838 603.652C78.873 604.671 78.5086 605.719 78.1925 606.789C77.7103 608.578 77.7187 610.199 78.1163 609.812C78.7366 608.694 79.1735 607.436 79.4023 606.109C79.7745 604.426 79.783 603.306 79.2838 603.652Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.1705 609.174C71.1298 609.796 71.1583 610.423 71.2551 611.036C71.4497 611.412 72.1011 609.362 72.5072 607.49C72.7948 606.005 72.761 604.99 72.4395 605.294C71.7942 606.458 71.3613 607.782 71.1705 609.174Z" fill="white" fill-opacity="0.6"/>
        <path d="M64.7156 612.531C65.0879 612.469 65.5109 611.308 65.8324 609.77C66.1539 608.233 66.1961 607.093 65.9508 607.093C65.7054 607.093 65.0202 608.693 64.8256 610.126C64.7355 610.923 64.6988 611.727 64.7156 612.531Z" fill="white" fill-opacity="0.6"/>
        <path d="M58.4126 614.602C58.8356 613.128 59.1825 612.165 59.4109 611.098C59.6986 609.781 59.6055 608.829 59.3179 609.007C59.0302 609.184 58.3873 610.827 58.3026 612.029C58.2851 612.889 58.3219 613.75 58.4126 614.602Z" fill="white" fill-opacity="0.6"/>
        <path d="M52.3047 610.785C52.0156 611.478 51.7722 612.198 51.5771 612.939C51.2979 614.184 51.4671 615.387 51.8225 615.209C52.3134 614.513 52.6101 613.64 52.6685 612.72C52.8462 611.475 52.7531 610.628 52.3047 610.785Z" fill="white" fill-opacity="0.6"/>
        <path d="M44.4622 616.935C44.7871 616 45.0695 615.043 45.3082 614.069C45.5028 613.023 45.3082 612.207 44.9444 612.312C44.5806 612.416 44.0138 613.807 44.0476 614.791C44.124 615.522 44.2629 616.24 44.4622 616.935Z" fill="white" fill-opacity="0.6"/>
        <path d="M35.8752 617.228C36.3998 617.343 36.7805 616.642 36.9327 615.638C37.085 614.634 36.9328 613.713 36.5859 613.598C36.239 613.483 35.5876 614.414 35.5791 615.429C35.6152 616.042 35.7147 616.646 35.8752 617.228Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.7484 615.742C25.6553 616.935 25.9683 617.74 26.3744 617.834C26.7805 617.928 27.322 617.081 27.3643 615.973C27.3643 615.042 27.0682 614.278 26.6282 614.561C26.1883 614.843 25.9684 615.429 25.7484 615.742Z" fill="white" fill-opacity="0.6"/>
        <path d="M14.8264 617.939C14.9799 617.977 15.1383 617.973 15.2905 617.928C15.4427 617.882 15.585 617.796 15.7075 617.676C15.83 617.555 15.9297 617.403 15.9996 617.23C16.0696 617.057 16.108 616.867 16.1123 616.673C16.1885 616.329 16.161 615.962 16.0349 615.641C15.9089 615.32 15.693 615.066 15.4271 614.927C15.2666 614.903 15.104 614.925 14.9513 614.99C14.7987 615.056 14.6598 615.163 14.5452 615.303C14.4305 615.444 14.343 615.615 14.2892 615.803C14.2354 615.991 14.2166 616.192 14.2342 616.391C14.3539 616.947 14.5546 617.472 14.8264 617.939Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.34279 615.91C0.715568 616.529 1.14733 617.091 1.62874 617.583C2.11097 617.939 2.77086 617.311 2.90622 616.537C3.06696 615.606 2.56781 614.613 1.891 614.759C1.33098 615.044 0.808995 615.432 0.34279 615.91Z" fill="white" fill-opacity="0.6"/>
        <path d="M704.668 636.703C706.301 636.368 707.409 635.605 707.782 634.82C708.154 634.036 707.545 633.607 706.453 633.774C705.1 634.015 703.433 635.082 703.552 635.741C703.636 636.264 704.381 636.441 704.668 636.703Z" fill="white" fill-opacity="0.6"/>
        <path d="M676.116 641.472C676.59 641.8 677.121 641.986 677.664 642.016C678.251 641.97 678.824 641.768 679.342 641.423C679.861 641.079 680.313 640.599 680.667 640.019C680.921 639.318 680.185 639.025 678.975 639.349C677.844 639.6 676.827 640.356 676.116 641.472Z" fill="white" fill-opacity="0.6"/>
        <path d="M649.982 647.811C651.243 647.633 652.427 646.971 653.366 645.918C653.874 645.165 653.366 644.736 652.334 644.955C650.989 645.227 649.179 646.294 649.136 646.932C649.103 647.403 649.72 647.602 649.982 647.811Z" fill="white" fill-opacity="0.6"/>
        <path d="M622.234 652.465C622.699 652.904 622.817 653.365 623.401 653.511C624.51 653.364 625.566 652.847 626.455 652.015C627.394 651.095 627.157 650.384 625.829 650.813C624.89 651.085 623.782 651.733 622.234 652.465Z" fill="white" fill-opacity="0.6"/>
        <path d="M598.114 659.358C599.165 658.71 600.187 657.995 601.176 657.214C601.963 656.481 601.422 656.168 600.33 656.44C599.087 656.743 597.2 657.852 597.099 658.406C596.997 658.96 597.666 658.96 598.114 659.358Z" fill="white" fill-opacity="0.6"/>
        <path d="M573.182 663.929C573.402 664.054 573.521 664.42 574.138 664.378C575.331 664.112 576.444 663.455 577.361 662.475C577.861 661.847 577.361 661.596 576.346 661.92C575.184 662.29 574.103 662.976 573.182 663.929Z" fill="white" fill-opacity="0.6"/>
        <path d="M554.435 666.972C553.842 667.317 552.743 667.851 551.998 668.405C550.957 669.158 551.245 669.639 552.32 669.346C553.342 669.015 554.306 668.451 555.162 667.683C555.873 666.993 555.737 666.648 554.435 666.972Z" fill="white" fill-opacity="0.6"/>
        <path d="M532.802 672.85C532.092 673.457 532.032 673.896 532.675 673.781C533.791 673.476 534.845 672.892 535.772 672.066C536.474 671.417 536.372 671.114 535.535 671.344C534.567 671.67 533.645 672.178 532.802 672.85Z" fill="white" fill-opacity="0.6"/>
        <path d="M297.875 526.022C297.13 526.796 297.392 526.765 298.188 526.211C299.031 525.71 299.83 525.103 300.573 524.401C301.081 523.837 300.971 523.659 300.277 524.046C299.474 524.694 298.484 525.51 297.875 526.022Z" fill="white" fill-opacity="0.6"/>
        <path d="M311.462 524.527C312.181 524.123 312.882 523.669 313.56 523.167C314.177 522.602 314.067 522.058 313.509 522.006C313.11 522.009 312.716 522.113 312.351 522.31C311.986 522.508 311.657 522.796 311.385 523.157C311.191 523.596 311.411 523.92 311.462 524.527Z" fill="white" fill-opacity="0.6"/>
        <path d="M324.245 527.424C323.517 528.198 323.449 529.003 323.856 529.411C324.196 529.642 324.59 529.722 324.972 529.636C325.354 529.55 325.701 529.305 325.954 528.941C326.461 528.313 326.495 527.466 325.852 527.299C325.314 527.248 324.773 527.29 324.245 527.424Z" fill="white" fill-opacity="0.6"/>
        <path d="M335.742 538.772C335.735 539.38 335.833 539.983 336.03 540.54C336.419 541.304 337.544 541.105 338.077 540.31C338.181 540.155 338.257 539.975 338.3 539.781C338.342 539.587 338.351 539.384 338.324 539.185C338.298 538.987 338.237 538.798 338.146 538.631C338.055 538.464 337.937 538.323 337.798 538.218C337.458 537.992 337.064 537.923 336.687 538.025C336.31 538.127 335.975 538.392 335.742 538.772Z" fill="white" fill-opacity="0.6"/>
        <path d="M347.789 555.831C348.534 556.877 349.329 556.94 349.887 556.375C350.149 556.089 350.309 555.687 350.331 555.255C350.354 554.824 350.236 554.399 350.006 554.074C349.405 553.185 348.254 552.966 347.933 553.823C347.78 554.475 347.731 555.157 347.789 555.831Z" fill="white" fill-opacity="0.6"/>
        <path d="M360.158 571.594C360.059 572.549 360.059 573.515 360.158 574.47C360.291 574.908 360.558 575.263 360.902 575.459C361.246 575.655 361.638 575.675 361.994 575.516C362.183 575.402 362.348 575.232 362.472 575.021C362.596 574.81 362.676 574.565 362.706 574.306C362.735 574.048 362.712 573.784 362.64 573.539C362.567 573.294 362.447 573.075 362.29 572.901C361.632 572.343 360.915 571.903 360.158 571.594Z" fill="white" fill-opacity="0.6"/>
        <path d="M375.005 592.816C374.466 592.564 373.889 592.457 373.313 592.502C372.569 592.753 372.687 594.385 373.389 595.326C373.616 595.691 373.948 595.936 374.315 596.01C374.683 596.084 375.06 595.981 375.369 595.723C375.902 595.159 375.868 594.092 375.005 592.816Z" fill="white" fill-opacity="0.6"/>
        <path d="M387.399 613.18C387.229 613.319 387.085 613.5 386.976 613.713C386.867 613.925 386.796 614.164 386.767 614.413C386.738 614.662 386.752 614.916 386.809 615.158C386.865 615.4 386.963 615.624 387.095 615.816C387.207 616.063 387.359 616.279 387.541 616.451C387.723 616.623 387.932 616.748 388.154 616.817C388.377 616.887 388.608 616.9 388.835 616.855C389.061 616.811 389.278 616.71 389.472 616.558C389.606 616.394 389.708 616.194 389.771 615.974C389.833 615.754 389.854 615.519 389.832 615.287C389.81 615.055 389.745 614.832 389.643 614.636C389.541 614.439 389.405 614.274 389.243 614.153C388.67 613.721 388.049 613.393 387.399 613.18Z" fill="white" fill-opacity="0.6"/>
        <path d="M402.196 635.605C403.152 636.755 404.116 636.985 404.776 636.536C405.436 636.086 405.419 635.103 404.776 634.308C404.006 633.325 402.585 632.864 402.238 633.638C402.001 634.214 402.204 635.124 402.196 635.605Z" fill="white" fill-opacity="0.6"/>
        <path d="M418.465 651.513C418.487 652.138 418.636 652.745 418.896 653.281C419.253 653.637 419.695 653.839 420.154 653.854C420.612 653.869 421.062 653.697 421.434 653.365C421.933 652.831 421.722 651.921 420.977 651.377C420.591 651.105 420.15 650.973 419.705 650.997C419.26 651.021 418.83 651.2 418.465 651.513Z" fill="white" fill-opacity="0.6"/>
        <path d="M437.525 664.41C436.805 664.364 436.084 664.427 435.376 664.598C434.463 664.943 434.682 666.073 435.596 666.564C436.007 666.759 436.447 666.839 436.886 666.799C437.325 666.759 437.751 666.601 438.134 666.334C438.76 665.801 438.659 665.089 437.525 664.41Z" fill="white" fill-opacity="0.6"/>
        <path d="M456.67 673.718C455.853 673.733 455.038 673.831 454.234 674.011C453.21 674.346 453.278 675.34 454.166 675.716C455.076 676.032 456.048 675.913 456.89 675.381C457.677 674.879 457.677 674.22 456.67 673.718Z" fill="white" fill-opacity="0.6"/>
        <path d="M474.529 679.272C473.523 680.005 473.556 680.622 474.275 680.799C475.403 680.958 476.541 680.645 477.507 679.91C478.201 679.335 478.099 678.76 477.059 678.802C476.209 678.895 475.364 679.053 474.529 679.272Z" fill="white" fill-opacity="0.6"/>
        <path d="M494.96 681.563C496.127 681.482 497.264 681.074 498.285 680.371C499.131 679.774 499.131 679.325 498.285 679.325C497.117 679.414 495.986 679.863 494.994 680.632C494.69 681.05 494.96 681.343 494.96 681.563Z" fill="white" fill-opacity="0.6"/>
        <path d="M515.654 676.919C515.704 677.275 515.51 677.641 515.823 677.703C516.836 677.414 517.807 676.931 518.699 676.271C519.816 675.476 520.163 674.879 519.038 675.319C518.242 675.622 517.109 676.187 515.654 676.919Z" fill="white" fill-opacity="0.6"/>
        <path d="M534.85 662.182C535.019 662.234 535.044 662.475 535.594 662.339C536.605 661.932 537.567 661.359 538.454 660.634C538.927 660.184 538.944 659.985 538.107 660.278C536.954 660.721 535.858 661.362 534.85 662.182Z" fill="white" fill-opacity="0.6"/>
        <path d="M513.708 667.317C514.741 667.156 515.736 666.729 516.627 666.062C517.261 665.55 517.287 665.267 516.627 665.257C515.596 665.383 514.595 665.76 513.683 666.366C512.887 666.93 512.904 667.391 513.708 667.317Z" fill="white" fill-opacity="0.6"/>
        <path d="M491.517 667.443C492.627 667.611 493.751 667.344 494.732 666.679C495.366 666.198 495.4 665.843 494.8 665.633C493.831 665.504 492.853 665.722 491.982 666.261C491.593 666.711 491.669 667.14 491.517 667.443Z" fill="white" fill-opacity="0.6"/>
        <path d="M473.328 661.397C472.414 661.176 471.466 661.365 470.655 661.931C470.096 662.454 470.35 663.186 471.23 663.28C472.032 663.402 472.846 663.224 473.565 662.768C474.309 662.245 474.208 661.659 473.328 661.397Z" fill="white" fill-opacity="0.6"/>
        <path d="M451.89 654.787C452.604 654.708 453.308 654.529 453.988 654.254C454.564 653.909 454.479 653.312 453.76 652.884C453.361 652.653 452.925 652.539 452.485 652.55C452.045 652.561 451.613 652.696 451.222 652.946C450.638 653.459 450.757 654.222 451.89 654.787Z" fill="white" fill-opacity="0.6"/>
        <path d="M433.693 640.217C433.745 640.824 433.923 641.404 434.209 641.901C434.545 642.171 434.941 642.306 435.342 642.289C435.742 642.272 436.129 642.104 436.451 641.807C436.84 641.316 436.882 640.761 436.239 640.186C435.863 639.868 435.417 639.701 434.962 639.707C434.506 639.712 434.063 639.89 433.693 640.217Z" fill="white" fill-opacity="0.6"/>
        <path d="M416.392 622.489C416.472 623.229 416.673 623.941 416.984 624.581C417.272 624.932 417.652 625.144 418.056 625.179C418.459 625.213 418.859 625.068 419.184 624.769C419.539 624.246 419.547 623.577 418.888 622.813C418.573 622.396 418.147 622.133 417.689 622.074C417.23 622.014 416.77 622.162 416.392 622.489Z" fill="white" fill-opacity="0.6"/>
        <path d="M400.918 604.143C401.161 604.525 401.512 604.778 401.9 604.852C402.288 604.925 402.684 604.814 403.008 604.541C403.406 604.018 403.439 603.327 402.906 602.449C402.687 602.037 402.357 601.735 401.974 601.596C401.592 601.456 401.182 601.488 400.817 601.685C400.681 601.847 400.575 602.043 400.506 602.26C400.436 602.477 400.405 602.71 400.415 602.943C400.425 603.176 400.475 603.403 400.561 603.61C400.648 603.817 400.77 603.999 400.918 604.143Z" fill="white" fill-opacity="0.6"/>
        <path d="M388.211 580.683C388.102 580.502 387.963 580.352 387.804 580.242C387.645 580.131 387.469 580.063 387.288 580.041C387.106 580.02 386.923 580.045 386.75 580.116C386.577 580.187 386.418 580.302 386.282 580.453C386.09 580.869 386.03 581.36 386.114 581.828C386.199 582.296 386.422 582.708 386.739 582.984C386.991 583.142 387.266 583.237 387.548 583.262C387.83 583.287 388.113 583.242 388.381 583.13C389.023 582.775 388.854 581.499 388.211 580.683Z" fill="white" fill-opacity="0.6"/>
        <path d="M372.992 559.597C373.004 560.349 373.124 561.093 373.347 561.793C373.446 561.975 373.577 562.127 373.731 562.235C373.885 562.344 374.057 562.408 374.233 562.421C374.41 562.434 374.587 562.397 374.75 562.311C374.914 562.226 375.059 562.095 375.174 561.929C375.356 561.62 375.445 561.242 375.426 560.861C375.408 560.48 375.283 560.118 375.073 559.837C374.965 559.626 374.819 559.449 374.647 559.318C374.475 559.188 374.281 559.108 374.08 559.085C373.879 559.061 373.676 559.095 373.488 559.184C373.299 559.273 373.129 559.414 372.992 559.597Z" fill="white" fill-opacity="0.6"/>
        <path d="M362.679 540.634C362.591 540.45 362.472 540.29 362.331 540.166C362.19 540.042 362.03 539.955 361.86 539.911C361.69 539.868 361.515 539.869 361.346 539.914C361.177 539.96 361.017 540.048 360.877 540.174C360.618 540.461 360.462 540.863 360.441 541.291C360.421 541.72 360.538 542.142 360.767 542.465C360.947 542.749 361.204 542.945 361.491 543.016C361.779 543.087 362.077 543.029 362.332 542.852C362.483 542.746 362.614 542.601 362.715 542.426C362.816 542.252 362.886 542.052 362.919 541.842C362.952 541.631 362.947 541.414 362.906 541.205C362.864 540.997 362.787 540.802 362.679 540.634Z" fill="white" fill-opacity="0.6"/>
        <path d="M348.686 526.462C348.935 526.742 349.262 526.891 349.599 526.877C349.935 526.863 350.254 526.688 350.488 526.389C350.697 526.184 350.833 525.887 350.866 525.56C350.899 525.233 350.827 524.904 350.666 524.642C350.446 524.332 350.14 524.136 349.808 524.096C349.477 524.055 349.145 524.172 348.88 524.422C348.754 524.535 348.647 524.679 348.568 524.846C348.488 525.012 348.438 525.197 348.42 525.389C348.401 525.581 348.416 525.775 348.461 525.96C348.507 526.145 348.584 526.316 348.686 526.462Z" fill="white" fill-opacity="0.6"/>
        <path d="M336.749 515.26C337.066 515.386 337.407 515.397 337.729 515.291C338.051 515.186 338.342 514.968 338.568 514.664C338.948 514.12 339.025 513.618 338.568 513.231C338.273 513.054 337.941 512.995 337.618 513.062C337.295 513.13 336.997 513.32 336.766 513.607C336.275 514.193 336.258 514.967 336.749 515.26Z" fill="white" fill-opacity="0.6"/>
        <path d="M324.202 509.214C324.253 509.424 324.202 509.894 324.456 510.051C324.71 510.208 325.801 509.675 326.258 509.078C326.715 508.482 326.723 508.189 326.258 508.032C325.869 508.051 325.487 508.165 325.134 508.368C324.781 508.571 324.465 508.859 324.202 509.214Z" fill="white" fill-opacity="0.6"/>
        <path d="M551.82 657.025C551.017 657.663 550.839 658.124 551.44 657.988C552.372 657.68 553.254 657.173 554.045 656.492C554.705 655.927 554.832 655.634 554.375 655.676C553.468 655.943 552.604 656.399 551.82 657.025Z" fill="white" fill-opacity="0.6"/>
        <path d="M569.925 652.13C569.155 652.789 569.079 653.25 569.79 653.082C570.766 652.789 571.686 652.259 572.497 651.524C573.114 650.938 573.174 650.635 572.624 650.698C571.66 650.965 570.745 651.45 569.925 652.13Z" fill="white" fill-opacity="0.6"/>
        <path d="M593.038 645.321C592.035 645.588 591.089 646.116 590.263 646.869C589.569 647.549 589.688 647.915 590.542 647.737C591.407 647.474 592.225 647.013 592.953 646.378C593.833 645.646 593.833 645.175 593.038 645.321Z" fill="white" fill-opacity="0.6"/>
        <path d="M613.012 642.027C614.03 641.751 614.978 641.169 615.77 640.332C616.261 639.726 616.176 639.433 615.449 639.527C614.422 639.77 613.455 640.303 612.623 641.085C611.955 641.776 612.141 642.225 613.012 642.027Z" fill="white" fill-opacity="0.6"/>
        <path d="M636.979 636.086C637.994 635.84 638.932 635.245 639.687 634.371C640.118 633.753 639.974 633.45 639.196 633.544C638.159 633.763 637.185 634.311 636.37 635.134C635.77 635.835 636.057 636.295 636.979 636.086Z" fill="white" fill-opacity="0.6"/>
        <path d="M663.544 627.593C662.57 627.849 661.684 628.47 661.006 629.371C660.668 630.03 661.311 630.417 662.309 630.019C663.15 629.749 663.917 629.207 664.534 628.451C665.024 627.698 664.61 627.321 663.544 627.593Z" fill="white" fill-opacity="0.6"/>
        <path d="M686.42 623.252C685.989 623.974 686.479 624.424 687.469 624.204C688.443 624.013 689.326 623.383 689.948 622.436C690.202 621.819 689.948 621.495 689.102 621.621C688.082 621.765 687.135 622.342 686.42 623.252Z" fill="white" fill-opacity="0.6"/>
        <path d="M713.932 616.067C712.908 616.381 711.868 617.249 711.749 617.908C711.631 618.567 712.595 618.954 713.501 618.42C714.19 618.053 714.812 617.516 715.328 616.841C715.819 616.067 715.015 615.743 713.932 616.067Z" fill="white" fill-opacity="0.6"/>
        <path d="M1.84871 598.788C1.99442 598.784 2.13795 598.744 2.27033 598.668C2.40271 598.593 2.52109 598.485 2.618 598.35C2.71492 598.216 2.7883 598.058 2.83354 597.887C2.87877 597.715 2.89485 597.534 2.88083 597.355C2.89135 597.054 2.81031 596.76 2.65368 596.529C2.49706 596.299 2.27622 596.149 2.03481 596.11C1.8909 596.092 1.7454 596.108 1.60664 596.159C1.46788 596.21 1.33862 596.294 1.22623 596.407C1.11384 596.519 1.02052 596.658 0.951676 596.816C0.882828 596.973 0.839792 597.146 0.82501 597.324C0.806295 597.685 0.903086 598.041 1.09456 598.315C1.28602 598.589 1.55687 598.758 1.84871 598.788Z" fill="white" fill-opacity="0.6"/>
        <path d="M15.385 598.035C15.5489 598.039 15.7117 598.002 15.8635 597.925C16.0152 597.849 16.1525 597.734 16.2668 597.589C16.3811 597.444 16.47 597.271 16.5279 597.081C16.5857 596.892 16.6114 596.689 16.6033 596.487C16.6033 595.765 16.3917 595.295 15.9433 595.169C15.8012 595.165 15.6599 595.196 15.5276 595.26C15.3953 595.325 15.2748 595.421 15.1731 595.544C15.0714 595.667 14.9906 595.813 14.9355 595.975C14.8803 596.137 14.852 596.311 14.852 596.487C14.9587 597.036 15.1389 597.559 15.385 598.035Z" fill="white" fill-opacity="0.6"/>
        <path d="M27.9987 593.056C27.5419 593.056 27.0935 593.82 27.0258 594.709C26.9581 595.598 27.305 596.288 27.7365 596.173C28.1679 596.058 28.4302 595.483 28.5825 594.73C28.7517 593.82 28.4471 593.056 27.9987 593.056Z" fill="white" fill-opacity="0.6"/>
        <path d="M38.6923 590.013C38.2862 590.013 37.8463 590.891 37.7617 591.864C37.6771 592.837 37.9224 593.59 38.3116 593.443C38.7008 593.297 38.9461 592.648 39.0984 591.812C39.2845 590.807 39.0815 589.981 38.6923 590.013Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.3132 588.266C47.1693 589.312 47.3132 590.159 47.7024 590.013C48.0915 589.866 48.4553 588.799 48.5484 587.701C48.6414 586.781 48.5484 586.205 48.2354 586.216C47.9223 586.226 47.457 587.199 47.3132 588.266Z" fill="white" fill-opacity="0.6"/>
        <path d="M56.7799 581.948C56.293 582.732 55.9992 583.673 55.9339 584.657C55.8324 585.703 56.0777 586.561 56.4076 585.881C56.7298 585.142 56.9743 584.354 57.1352 583.538C57.3552 582.304 57.1521 581.593 56.7799 581.948Z" fill="white" fill-opacity="0.6"/>
        <path d="M63.9455 580.317C64.0132 580.683 64.0301 581.561 64.2247 581.687C64.4193 581.812 64.9777 580.202 65.1723 578.884C65.3415 577.786 65.333 577.085 65.0454 577.158C64.7577 577.231 64.2839 578.424 63.9455 580.317Z" fill="white" fill-opacity="0.6"/>
        <path d="M72.0588 577.179C72.2957 576.363 72.7103 575.213 72.9641 574.041C73.2179 572.87 73.1925 572.106 72.8964 572.253C72.6003 572.399 72.0926 573.738 71.8219 575.139C71.5512 576.541 71.5935 577.629 72.0588 577.179Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.6982 570.15C79.3428 571.667 79.2752 572.985 79.5544 572.901C79.8335 572.817 80.4004 571.405 80.7388 569.763C81.0349 568.372 81.0856 567.525 80.8657 567.4C80.6457 567.274 80.0366 568.665 79.6982 570.15Z" fill="white" fill-opacity="0.6"/>
        <path d="M87.8284 568.456C88.133 567.515 88.6744 566.197 88.9705 564.879C89.2666 563.561 89.3343 562.578 89.0128 562.703C88.6913 562.829 88.0822 564.345 87.71 565.998C87.3377 567.651 87.3124 568.968 87.8284 568.456Z" fill="white" fill-opacity="0.6"/>
        <path d="M96.0937 564.806C96.559 564.806 97.2274 563.237 97.6843 561.365C98.0819 559.754 98.175 558.739 97.955 558.467C97.735 558.195 96.8806 559.9 96.5506 561.438C96.3445 562.548 96.1919 563.673 96.0937 564.806Z" fill="white" fill-opacity="0.6"/>
        <path d="M105.907 561.48C106.33 561.249 107.109 559.388 107.532 557.515C107.896 555.915 107.98 554.848 107.625 554.827C107.27 554.807 106.508 556.407 106.034 558.3C105.561 560.193 105.493 561.71 105.907 561.48Z" fill="white" fill-opacity="0.6"/>
        <path d="M116.567 556.511C116.618 557.108 116.567 558.499 116.804 558.771C117.041 559.043 118.115 556.679 118.547 554.712C118.902 553.07 118.995 551.92 118.547 551.951C118.099 551.983 117.286 553.666 116.567 556.511Z" fill="white" fill-opacity="0.6"/>
        <path d="M128.826 554.765C128.902 555.382 128.826 556.857 129.181 557.108C129.536 557.359 130.679 554.838 131.085 552.924C131.431 551.25 131.491 550.058 130.975 550.089C130.459 550.121 129.545 551.857 128.826 554.765Z" fill="white" fill-opacity="0.6"/>
        <path d="M310.717 513.022C311.573 512.472 312.393 511.843 313.171 511.139C313.636 510.647 313.704 510.428 313.348 510.532C312.417 511.156 311.536 511.888 310.717 512.718C310.472 513.022 310.7 512.917 310.717 513.022Z" fill="white" fill-opacity="0.6"/>
        <path d="M289.178 528.993C288.67 529.526 288.89 529.485 289.652 528.909C290.449 528.3 291.212 527.626 291.936 526.891C292.291 526.493 292.207 526.472 291.53 526.964C290.711 527.577 289.925 528.254 289.178 528.993Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.828 543.144C271.082 543.088 271.326 542.974 271.547 542.81C272.24 542.267 272.891 541.647 273.493 540.958C273.747 540.613 273.687 540.561 273.028 541.053C272.241 541.661 271.505 542.361 270.828 543.144Z" fill="white" fill-opacity="0.6"/>
        <path d="M255.98 554.242C255.481 554.859 255.481 555.11 255.98 554.754C256.646 554.243 257.259 553.636 257.808 552.945C258.205 552.422 258.214 552.265 257.808 552.495C257.142 552.981 256.529 553.568 255.98 554.242Z" fill="white" fill-opacity="0.6"/>
        <path d="M243.265 564.189C243.884 563.663 244.436 563.029 244.906 562.306C245.219 561.783 245.253 561.542 244.847 561.793C244.224 562.264 243.669 562.862 243.206 563.561C242.791 564.272 242.791 564.544 243.265 564.189Z" fill="white" fill-opacity="0.6"/>
        <path d="M234.17 568.644C233.57 569.082 233.058 569.683 232.673 570.401C232.216 571.196 232.132 571.719 232.521 571.447C233.032 571.037 233.477 570.512 233.832 569.899C234.331 569.073 234.509 568.456 234.17 568.644Z" fill="white" fill-opacity="0.6"/>
        <path d="M225.203 573.215C224.915 573.215 224.281 574.073 223.807 575.014C223.333 575.955 223.198 576.656 223.528 576.478C224.025 576.068 224.442 575.525 224.746 574.888C225.287 573.937 225.482 573.184 225.203 573.215Z" fill="white" fill-opacity="0.6"/>
        <path d="M216.277 577.252C215.795 578.298 215.575 579.271 215.821 579.271C216.066 579.271 216.667 578.35 217.191 577.231C217.606 576.29 217.75 575.683 217.589 575.516C217.428 575.349 216.76 576.238 216.277 577.252Z" fill="white" fill-opacity="0.6"/>
        <path d="M209.052 579.836C209.289 579.898 209.898 578.894 210.415 577.629C210.846 576.583 211.015 575.83 210.871 575.537C210.728 575.244 210.068 576.196 209.577 577.367C209.086 578.539 208.824 579.762 209.052 579.836Z" fill="white" fill-opacity="0.6"/>
        <path d="M204.552 573.351C204.247 573.403 203.562 574.669 203.122 576.008C202.682 577.346 202.538 578.654 202.953 578.099C203.43 577.392 203.842 576.62 204.18 575.798C204.721 574.449 204.873 573.299 204.552 573.351Z" fill="white" fill-opacity="0.6"/>
        <path d="M196.405 574.836C196.667 574.993 197.344 573.79 197.894 572.242C198.351 570.903 198.537 569.952 198.376 569.575C198.215 569.199 197.53 570.255 196.963 571.667C196.396 573.079 196.151 574.679 196.405 574.836Z" fill="white" fill-opacity="0.6"/>
        <path d="M190.61 567.264C190.33 567.013 189.594 568.215 189.019 569.899C188.444 571.583 188.241 573.1 188.588 573.1C188.935 573.1 189.434 572.127 189.967 570.778C190.601 569.125 190.889 567.525 190.61 567.264Z" fill="white" fill-opacity="0.6"/>
        <path d="M176.853 561.636C176.904 562.421 176.803 564.032 177.099 564.638C177.395 565.245 178.545 563.592 179.053 561.647C179.485 560.036 179.578 558.76 179.112 558.279C178.647 557.798 177.649 558.927 176.853 561.636Z" fill="white" fill-opacity="0.6"/>
        <path d="M161.515 555.758C161.625 556.511 161.609 558.133 162.006 558.645C162.404 559.158 163.698 557.16 164.172 555.11C164.586 553.394 164.629 552.108 164.028 551.773C163.427 551.439 162.353 552.83 161.515 555.758Z" fill="white" fill-opacity="0.6"/>
        <path d="M142.895 553.959C143.098 554.629 143.149 556.166 143.673 556.522C144.198 556.877 145.602 554.493 146.017 552.432C146.363 550.696 146.321 549.43 145.551 549.347C144.781 549.263 143.673 550.947 142.895 553.959Z" fill="white" fill-opacity="0.6"/>
        <path d="M533.631 658.406C534.917 657.768 535.882 657.36 536.601 656.91C537.582 656.314 537.396 655.969 536.381 656.251C535.485 656.485 534.627 656.902 533.843 657.486C533.479 657.82 533.716 657.998 533.631 658.406Z" fill="white" fill-opacity="0.6"/>
        <path d="M511.458 661.335C512.727 660.874 513.674 660.592 514.368 660.236C515.324 659.745 515.095 659.19 514.08 659.316C513.215 659.36 512.372 659.669 511.627 660.216C511.254 660.561 511.508 660.843 511.458 661.335Z" fill="white" fill-opacity="0.6"/>
        <path d="M489.537 658.343C489.588 658.626 489.444 659.065 489.774 659.316C490.733 659.55 491.728 659.38 492.6 658.835C493.116 658.375 493.091 657.977 492.304 657.789C491.358 657.634 490.396 657.827 489.537 658.343Z" fill="white" fill-opacity="0.6"/>
        <path d="M468.793 651.984C468.793 652.266 468.683 652.768 468.988 653.114C469.413 653.325 469.868 653.428 470.327 653.415C470.785 653.402 471.236 653.275 471.653 653.04C472.135 652.57 472.11 652.11 471.382 651.754C470.527 651.42 469.601 651.502 468.793 651.984Z" fill="white" fill-opacity="0.6"/>
        <path d="M449.978 641.211C449.978 641.546 449.834 642.1 450.097 642.55C450.444 642.857 450.849 643.046 451.274 643.1C451.698 643.153 452.127 643.068 452.516 642.853C452.99 642.393 452.982 641.807 452.347 641.336C451.99 641.079 451.588 640.932 451.175 640.911C450.762 640.889 450.352 640.992 449.978 641.211Z" fill="white" fill-opacity="0.6"/>
        <path d="M435.173 626.317C434.406 626.079 433.62 625.953 432.83 625.94C432.068 626.129 432.204 626.923 432.677 627.645C433.151 628.367 434.792 628.932 435.097 628.409C435.402 627.886 435.165 627.248 435.173 626.317Z" fill="white" fill-opacity="0.6"/>
        <path d="M417.779 606.894C417.09 606.59 416.369 606.41 415.639 606.361C414.937 606.517 415.03 607.406 415.444 608.212C415.859 609.017 417.348 609.739 417.652 609.174C417.957 608.609 417.745 607.919 417.779 606.894Z" fill="white" fill-opacity="0.6"/>
        <path d="M399.911 587.377C400.562 587.692 401.246 587.892 401.942 587.973C402.627 587.868 402.534 586.467 401.857 585.588C401.181 584.71 400.529 584.542 400.165 585.044C399.802 585.546 399.996 586.341 399.911 587.377Z" fill="white" fill-opacity="0.6"/>
        <path d="M385.944 562.839C385.947 563.718 386.053 564.592 386.257 565.433C386.663 566.427 387.729 566.72 388.118 566.05C388.307 565.714 388.384 565.301 388.333 564.896C388.283 564.491 388.109 564.126 387.848 563.875C387.257 563.417 386.616 563.068 385.944 562.839Z" fill="white" fill-opacity="0.6"/>
        <path d="M373.254 542.799C373.16 543.418 373.16 544.053 373.254 544.672C373.465 545.508 374.675 546 375.064 545.466C375.271 545.178 375.385 544.806 375.385 544.42C375.385 544.035 375.271 543.663 375.064 543.375C374.976 543.19 374.859 543.028 374.721 542.899C374.582 542.77 374.424 542.676 374.256 542.622C374.088 542.569 373.912 542.557 373.741 542.587C373.569 542.618 373.403 542.69 373.254 542.799Z" fill="white" fill-opacity="0.6"/>
        <path d="M361.029 524.987C360.944 525.554 360.944 526.135 361.029 526.702C361.115 526.883 361.231 527.04 361.368 527.164C361.505 527.287 361.662 527.375 361.827 527.421C361.993 527.467 362.164 527.471 362.331 527.432C362.498 527.393 362.657 527.312 362.797 527.194C362.997 526.937 363.109 526.594 363.109 526.237C363.109 525.88 362.997 525.537 362.797 525.28C362.357 524.631 361.841 524.527 361.029 524.987Z" fill="white" fill-opacity="0.6"/>
        <path d="M351.08 510.93C350.486 510.923 349.894 511.018 349.32 511.212C349.207 511.278 349.107 511.374 349.027 511.493C348.947 511.613 348.89 511.752 348.859 511.902C348.828 512.053 348.825 512.209 348.849 512.361C348.873 512.513 348.924 512.657 348.999 512.781C349.234 513.062 349.545 513.225 349.872 513.238C350.198 513.252 350.517 513.115 350.767 512.854C350.968 512.242 351.074 511.59 351.08 510.93Z" fill="white" fill-opacity="0.6"/>
        <path d="M336.783 503.273C336.884 503.838 336.783 504.319 337.036 504.56C337.29 504.801 338.407 504.56 338.796 503.932C339.185 503.305 339.134 502.886 338.593 502.824C337.978 502.892 337.371 503.042 336.783 503.273Z" fill="white" fill-opacity="0.6"/>
        <path d="M324.118 503.608C324.964 503.085 325.531 502.771 325.988 502.395C326.622 501.872 326.588 501.412 325.988 501.589C325.396 501.765 324.852 502.125 324.405 502.635C324.126 502.928 324.228 503.137 324.118 503.608Z" fill="white" fill-opacity="0.6"/>
        <path d="M553.013 651.764C552.946 651.921 552.641 652.298 552.793 652.371C553.659 652.098 554.483 651.649 555.23 651.043C555.83 650.509 555.966 650.237 555.441 650.363C554.58 650.677 553.761 651.149 553.013 651.764Z" fill="white" fill-opacity="0.6"/>
        <path d="M569.164 646.963C569.299 647.225 569.164 647.549 569.527 647.518C570.448 647.248 571.313 646.742 572.065 646.033C572.59 645.478 572.522 645.248 571.735 645.572C571.16 645.855 570.306 646.346 569.164 646.963Z" fill="white" fill-opacity="0.6"/>
        <path d="M588.165 641.671C588.165 641.828 588.021 642.205 588.317 642.257C589.291 642.008 590.211 641.505 591.016 640.782C591.523 640.228 591.515 639.945 590.796 640.113C589.847 640.422 588.954 640.951 588.165 641.671Z" fill="white" fill-opacity="0.6"/>
        <path d="M609.29 636.724C610.491 635.939 611.405 635.416 611.98 634.925C612.691 634.318 612.124 633.973 611.134 634.37C610.32 634.638 609.573 635.152 608.96 635.866C608.799 636.232 609.112 636.368 609.29 636.724Z" fill="white" fill-opacity="0.6"/>
        <path d="M631.624 630.762C632.792 630.03 633.697 629.538 634.298 629.047C635.144 628.346 634.704 627.928 633.68 628.252C632.835 628.478 632.052 628.975 631.413 629.695C631.108 630.114 631.497 630.312 631.624 630.762Z" fill="white" fill-opacity="0.6"/>
        <path d="M654.331 623.702C654.864 623.953 655.042 624.288 655.6 624.246C656.574 624.062 657.468 623.476 658.138 622.583C658.392 621.987 658.02 621.746 657.072 622.123C656.137 622.591 655.222 623.118 654.331 623.702Z" fill="white" fill-opacity="0.6"/>
        <path d="M678.933 617.562C679.136 617.761 679.237 618.18 679.72 618.232C680.728 618.078 681.667 617.52 682.393 616.642C682.655 616.046 682.393 615.732 681.488 615.931C680.525 616.154 679.637 616.721 678.933 617.562Z" fill="white" fill-opacity="0.6"/>
        <path d="M703.086 611.684C703.746 611.967 704.034 612.322 704.626 612.291C705.531 612.239 706.851 611.245 706.978 610.617C707.105 609.99 706.623 609.749 705.684 610.105C704.792 610.565 703.925 611.092 703.086 611.684Z" fill="white" fill-opacity="0.6"/>
        <path d="M7.90618 587.722C8.41378 588.203 8.70989 588.695 9.07367 588.768C9.6405 588.914 10.1904 588.046 10.0889 587.314C9.98737 586.582 9.61511 586.195 9.11596 586.425C8.61682 586.655 8.44762 587.147 7.90618 587.722Z" fill="white" fill-opacity="0.6"/>
        <path d="M21.29 585.682C21.73 586.153 21.9668 586.645 22.2883 586.728C22.779 586.833 23.2951 585.839 23.2274 585.055C23.1597 584.27 22.8382 583.915 22.3814 584.208C21.9245 584.501 21.7638 585.023 21.29 585.682Z" fill="white" fill-opacity="0.6"/>
        <path d="M33.1512 582.346C33.5149 582.837 33.7011 583.392 33.9972 583.392C34.4286 583.476 34.9278 582.346 34.9024 581.52C34.877 580.693 34.6147 580.296 34.2087 580.631C33.8069 581.153 33.4523 581.728 33.1512 582.346Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.9375 578.026C44.0475 578.361 44.1406 579.009 44.3521 579.177C44.5636 579.344 45.2827 578.131 45.3419 577.231C45.4011 576.332 45.2573 575.851 44.8681 575.987C44.479 576.123 44.2083 576.719 43.9375 578.026Z" fill="white" fill-opacity="0.6"/>
        <path d="M53.4129 572.828C53.6498 573.393 53.7428 574.01 53.9628 574.052C54.1827 574.093 54.8088 572.734 54.8765 571.709C54.9442 570.684 54.7834 570.244 54.4197 570.663C54.0361 571.349 53.6994 572.073 53.4129 572.828Z" fill="white" fill-opacity="0.6"/>
        <path d="M62.6767 569.146C63.0416 568.258 63.3692 567.347 63.6581 566.416C63.9626 565.255 63.8019 564.324 63.4127 564.67C62.9416 565.295 62.6451 566.09 62.5667 566.939C62.5359 567.678 62.5728 568.418 62.6767 569.146Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.0521 563.226C71.5174 561.961 71.8981 561.187 72.118 560.266C72.4649 558.99 72.3633 557.923 71.9826 558.321C71.4938 559.018 71.1793 559.875 71.0775 560.789C71.0083 561.599 70.9998 562.415 71.0521 563.226Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.3853 557.327C79.8845 555.967 80.2313 555.141 80.5528 554.137C80.942 552.767 80.8912 551.564 80.5021 551.972C79.9859 552.731 79.6466 553.649 79.5207 554.629C79.414 555.522 79.3687 556.425 79.3853 557.327Z" fill="white" fill-opacity="0.6"/>
        <path d="M87.8622 548.688C87.9383 549.556 87.8622 550.487 88.0568 550.602C88.2513 550.717 89.0297 548.866 89.3004 547.38C89.5712 545.895 89.5289 545.215 89.1397 545.78C88.6662 546.716 88.2395 547.687 87.8622 548.688Z" fill="white" fill-opacity="0.6"/>
        <path d="M96.9482 543.103C96.8697 543.778 96.8527 544.462 96.8975 545.142C97.0413 545.623 97.9719 543.542 98.2849 542.088C98.598 540.634 98.6572 539.693 98.3357 539.766C98.0142 539.839 97.5066 541.021 96.9482 543.103Z" fill="white" fill-opacity="0.6"/>
        <path d="M106.584 537.883C106.493 538.648 106.471 539.424 106.517 540.195C106.677 540.728 107.701 538.574 108.056 537.057C108.412 535.541 108.471 534.463 108.107 534.547C107.743 534.631 107.312 535.572 106.584 537.883Z" fill="white" fill-opacity="0.6"/>
        <path d="M119.173 529.118C118.553 530.341 117.988 531.605 117.481 532.905C116.897 534.651 116.897 535.75 117.159 536.105C117.422 536.461 118.555 534.369 118.851 532.863C119.029 531.626 119.137 530.374 119.173 529.118Z" fill="white" fill-opacity="0.6"/>
        <path d="M129.147 530.269C129.059 531.155 129.059 532.05 129.147 532.936C129.359 533.606 130.602 531.388 131 529.683C131.398 527.978 131.457 526.796 131 526.786C130.543 526.776 129.976 527.79 129.147 530.269Z" fill="white" fill-opacity="0.6"/>
        <path d="M142.573 528.365C142.476 529.264 142.476 530.175 142.573 531.074C142.836 531.817 144.189 529.631 144.604 527.884C145.018 526.138 145.052 524.903 144.536 524.746C144.02 524.59 143.335 525.928 142.573 528.365Z" fill="white" fill-opacity="0.6"/>
        <path d="M305.861 514.873C306.935 513.942 307.79 513.231 308.34 512.718C309.101 512.017 308.644 512.216 307.654 512.98C306.925 513.522 306.226 514.126 305.565 514.789C305.345 515.051 305.709 514.852 305.861 514.873Z" fill="white" fill-opacity="0.6"/>
        <path d="M285.362 529.966C285.447 529.966 285.413 530.123 285.735 529.966C286.524 529.398 287.277 528.755 287.985 528.041C288.315 527.654 288.196 527.633 287.511 528.125C286.759 528.671 286.04 529.286 285.362 529.966Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.709 540.153C269.863 540.906 269.246 541.471 268.831 541.91C268.248 542.538 268.383 542.59 268.831 542.36C269.539 541.866 270.2 541.277 270.803 540.603C270.913 540.32 270.709 540.341 270.709 540.153Z" fill="white" fill-opacity="0.6"/>
        <path d="M254.568 552.296C255.312 551.543 255.879 551.031 256.26 550.55C256.826 549.891 256.708 549.64 256.124 550.037C255.574 550.413 255.082 550.905 254.669 551.491C254.475 551.867 254.635 551.972 254.568 552.296Z" fill="white" fill-opacity="0.6"/>
        <path d="M244.695 556.459C244.01 557.254 243.468 557.808 243.147 558.29C242.732 558.928 242.715 559.252 243.096 559.095C243.707 558.696 244.23 558.118 244.619 557.411C244.788 557.024 244.669 556.867 244.695 556.459Z" fill="white" fill-opacity="0.6"/>
        <path d="M233.485 562.651C233.485 562.954 233.265 563.509 233.35 563.697C233.435 563.885 234.424 562.839 234.763 562.139C235.101 561.438 235.203 560.978 234.89 561.04C234.577 561.103 234.162 561.553 233.485 562.651Z" fill="white" fill-opacity="0.6"/>
        <path d="M227.039 562.264C226.557 562.842 226.105 563.457 225.685 564.105C225.186 564.994 225.084 565.611 225.22 565.831C225.355 566.05 226.243 565.036 226.539 564.262C226.754 563.616 226.921 562.947 227.039 562.264Z" fill="white" fill-opacity="0.6"/>
        <path d="M219.704 564C219.816 563.411 219.87 562.807 219.865 562.201C219.746 561.887 219.298 562.473 218.807 563.509C218.316 564.544 218.037 565.852 218.426 565.601C218.888 565.113 219.315 564.577 219.704 564Z" fill="white" fill-opacity="0.6"/>
        <path d="M211.92 563.906C212.546 562.86 212.961 562.264 213.333 561.459C213.79 560.465 213.951 559.001 213.604 559.199C213.029 559.706 212.616 560.447 212.436 561.291C212.219 562.148 212.047 563.022 211.92 563.906Z" fill="white" fill-opacity="0.6"/>
        <path d="M206.565 556.25C206.389 556.989 206.259 557.745 206.176 558.509C206.176 559.241 207.022 558.059 207.445 556.825C207.868 555.591 208.063 554.597 207.877 554.252C207.69 553.907 207.259 554.587 206.565 556.25Z" fill="white" fill-opacity="0.6"/>
        <path d="M199.721 551.522C199.556 552.322 199.445 553.137 199.391 553.959C199.391 554.671 200.398 553.227 200.829 551.867C201.261 550.508 201.439 549.451 201.185 549.2C200.931 548.949 200.491 549.629 199.721 551.522Z" fill="white" fill-opacity="0.6"/>
        <path d="M192.488 541.178C191.792 541.847 191.174 542.632 190.652 543.51C190.376 544.057 190.197 544.669 190.125 545.307C190.054 545.945 190.092 546.595 190.237 547.213C190.508 548.113 191.701 547.108 192.048 545.602C192.289 544.144 192.436 542.665 192.488 541.178Z" fill="white" fill-opacity="0.6"/>
        <path d="M176.803 538.281C177.594 537.199 178.307 536.033 178.935 534.798C179.535 533.344 179.282 531.001 178.579 531.21C177.877 531.42 177.141 532.329 176.828 534.191C176.712 535.55 176.703 536.92 176.803 538.281Z" fill="white" fill-opacity="0.6"/>
        <path d="M159.832 532.832C160.695 531.732 161.479 530.542 162.175 529.275C162.937 527.57 162.717 525.437 161.879 525.489C161.042 525.541 160.263 526.702 159.883 528.439C159.595 529.756 159.832 530.917 159.832 532.832Z" fill="white" fill-opacity="0.6"/>
        <path d="M554.122 646.786C554.325 646.974 554.181 647.277 554.621 647.152C555.491 646.832 556.322 646.368 557.091 645.771C557.937 645.154 557.861 644.955 557.091 645.248C556.541 645.478 555.509 646.085 554.122 646.786Z" fill="white" fill-opacity="0.6"/>
        <path d="M531.567 652.329C531.804 652.643 531.702 653.009 532.159 652.999C533.028 652.869 533.864 652.512 534.613 651.953C535.383 651.388 535.315 651.085 534.528 651.231C533.953 651.367 532.929 651.838 531.567 652.329Z" fill="white" fill-opacity="0.6"/>
        <path d="M509.359 654.421C510.284 654.118 511.194 653.748 512.084 653.312C512.828 652.831 512.523 652.496 511.728 652.465C510.903 652.424 510.088 652.698 509.393 653.25C509.097 653.595 509.351 653.909 509.359 654.421Z" fill="white" fill-opacity="0.6"/>
        <path d="M487.16 649.265C487.397 649.861 487.313 650.31 487.744 650.551C488.508 650.812 489.324 650.696 490.02 650.227C490.722 649.767 490.646 649.275 489.91 649.108C488.991 649.078 488.073 649.13 487.16 649.265Z" fill="white" fill-opacity="0.6"/>
        <path d="M466.983 640.876C467.127 641.598 466.983 642.142 467.355 642.476C467.675 642.694 468.033 642.814 468.398 642.825C468.763 642.836 469.125 642.738 469.453 642.539C470.122 642.142 470.181 641.556 469.453 641.211C468.64 641.005 467.813 640.893 466.983 640.876Z" fill="white" fill-opacity="0.6"/>
        <path d="M448.836 628.273C448.98 629.12 448.836 629.747 449.208 630.155C449.472 630.43 449.796 630.603 450.139 630.653C450.481 630.704 450.829 630.629 451.137 630.438C451.763 630.04 451.738 629.392 451.137 628.911C450.394 628.584 449.621 628.37 448.836 628.273Z" fill="white" fill-opacity="0.6"/>
        <path d="M430.605 610.44C430.825 611.486 430.774 612.228 431.155 612.741C431.382 613.08 431.694 613.316 432.042 613.41C432.39 613.505 432.754 613.453 433.075 613.264C433.625 612.824 433.523 612.008 432.906 611.402C432.177 610.955 431.403 610.632 430.605 610.44Z" fill="white" fill-opacity="0.6"/>
        <path d="M414.226 589.448C414.311 590.494 414.184 591.278 414.471 591.885C414.627 592.263 414.89 592.557 415.21 592.71C415.53 592.863 415.885 592.863 416.206 592.711C416.781 592.376 416.84 591.54 416.282 590.787C415.65 590.224 414.958 589.774 414.226 589.448Z" fill="white" fill-opacity="0.6"/>
        <path d="M401.375 567.996C400.529 568.059 399.886 567.881 399.548 568.194C399.209 568.508 399.209 569.554 399.81 570.286C400.411 571.018 401.113 571.228 401.401 570.527C401.604 570.056 401.409 569.188 401.375 567.996Z" fill="white" fill-opacity="0.6"/>
        <path d="M385.766 546.052C385.815 546.927 385.981 547.786 386.257 548.594C386.68 549.294 387.365 549.493 387.788 548.866C387.89 548.715 387.966 548.541 388.012 548.354C388.058 548.167 388.074 547.971 388.058 547.776C388.042 547.581 387.996 547.392 387.92 547.22C387.845 547.048 387.742 546.896 387.619 546.774C387.213 546.345 386.612 546.355 385.766 546.052Z" fill="white" fill-opacity="0.6"/>
        <path d="M375.36 526.619C374.765 526.564 374.167 526.617 373.584 526.776C373.135 527.006 373.127 528.261 373.533 528.69C373.939 529.118 374.667 529.579 375.039 528.888C375.245 528.158 375.353 527.392 375.36 526.619Z" fill="white" fill-opacity="0.6"/>
        <path d="M361.232 510.292C361.3 511.181 361.173 511.902 361.427 512.289C361.516 512.419 361.626 512.525 361.75 512.599C361.874 512.674 362.01 512.715 362.147 512.721C362.285 512.727 362.422 512.697 362.55 512.634C362.678 512.57 362.794 512.474 362.89 512.352C363.008 512.264 363.107 512.142 363.178 511.996C363.249 511.85 363.29 511.684 363.297 511.514C363.305 511.344 363.279 511.174 363.221 511.02C363.163 510.865 363.076 510.73 362.966 510.626C362.696 510.313 362.078 510.417 361.232 510.292Z" fill="white" fill-opacity="0.6"/>
        <path d="M349.295 499.299C349.371 499.989 349.244 500.596 349.498 500.847C349.735 501.011 350.01 501.07 350.277 501.015C350.545 500.96 350.789 500.794 350.97 500.544C351.461 499.989 351.469 499.383 351.046 499.142C350.46 499.075 349.868 499.128 349.295 499.299Z" fill="white" fill-opacity="0.6"/>
        <path d="M338.94 493.808C338.336 494.181 337.751 494.6 337.189 495.063C336.69 495.575 336.8 495.921 337.299 495.9C337.583 495.887 337.862 495.804 338.119 495.655C338.377 495.506 338.607 495.294 338.796 495.032C339.016 494.665 338.889 494.362 338.94 493.808Z" fill="white" fill-opacity="0.6"/>
        <path d="M569.866 641.671C570.111 641.818 570.052 642.079 570.458 641.953C571.205 641.661 571.906 641.211 572.531 640.625C573.064 640.113 573.114 639.82 572.361 640.175C571.828 640.468 570.991 641.044 569.866 641.671Z" fill="white" fill-opacity="0.6"/>
        <path d="M587.293 637.1C588.427 636.337 589.299 635.824 589.831 635.375C590.559 634.757 590.381 634.548 589.73 634.768C588.956 635.038 588.234 635.497 587.607 636.117C587.26 636.441 587.403 636.672 587.293 637.1Z" fill="white" fill-opacity="0.6"/>
        <path d="M609.112 628.597C608.004 629.434 607.132 629.999 606.701 630.427C606.117 631.024 606.354 631.222 607.2 630.898C607.988 630.632 608.713 630.138 609.315 629.455C609.543 629.12 609.23 628.984 609.112 628.597Z" fill="white" fill-opacity="0.6"/>
        <path d="M627.267 624.235C627.563 624.539 627.538 624.936 627.995 624.873C628.809 624.667 629.566 624.206 630.194 623.535C630.829 622.865 630.685 622.489 629.949 622.771C629.036 623.203 628.141 623.692 627.267 624.235Z" fill="white" fill-opacity="0.6"/>
        <path d="M649.314 618.085C649.771 618.347 649.873 618.723 650.397 618.608C651.212 618.396 651.961 617.898 652.554 617.175C653.028 616.506 652.74 616.234 651.971 616.496C651.061 616.961 650.174 617.492 649.314 618.085Z" fill="white" fill-opacity="0.6"/>
        <path d="M673.408 612.751C674.198 612.192 674.956 611.567 675.676 610.879C676.2 610.199 675.735 609.979 674.914 610.22C674.124 610.397 673.412 610.921 672.909 611.695C672.732 612.103 673.129 612.291 673.408 612.751Z" fill="white" fill-opacity="0.6"/>
        <path d="M695.794 605.796C696.504 606.005 696.817 606.329 697.393 606.193C697.775 606.118 698.14 605.947 698.468 605.692C698.795 605.437 699.077 605.102 699.296 604.708C699.525 604.091 699.144 603.788 698.264 604.133C697.41 604.614 696.584 605.169 695.794 605.796Z" fill="white" fill-opacity="0.6"/>
        <path d="M719.583 599.708C720.15 600.054 720.379 600.493 720.912 600.451C721.294 600.406 721.665 600.263 722 600.03C722.335 599.798 722.627 599.482 722.858 599.102C723.238 598.391 722.858 598.056 722.062 598.244C721.204 598.643 720.374 599.133 719.583 599.708Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.672733 578.999C1.29033 579.428 1.6541 579.898 2.02635 579.888C2.1568 579.893 2.2864 579.86 2.40495 579.793C2.52351 579.725 2.62784 579.625 2.70977 579.499C2.7917 579.373 2.84901 579.226 2.87723 579.069C2.90545 578.911 2.90375 578.748 2.87237 578.591C2.80469 577.922 2.40708 577.461 1.89101 577.66C1.47646 577.796 1.19727 578.392 0.672733 578.999Z" fill="white" fill-opacity="0.6"/>
        <path d="M15.1394 577.221C15.6893 577.545 15.9854 577.963 16.3492 577.901C16.5982 577.806 16.8102 577.601 16.9444 577.325C17.0787 577.049 17.1258 576.721 17.0768 576.405C17.0091 575.725 16.6623 575.275 16.1885 575.6C15.8501 575.777 15.6047 576.478 15.1394 577.221Z" fill="white" fill-opacity="0.6"/>
        <path d="M28.058 573.686C28.5317 573.989 28.8025 574.418 29.107 574.303C29.3496 574.145 29.5471 573.899 29.6727 573.597C29.7983 573.296 29.846 572.955 29.8092 572.619C29.8092 571.897 29.4877 571.447 29.0478 571.834C28.6609 572.402 28.3287 573.023 28.058 573.686Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.7498 568.289C40.0459 568.843 40.1813 569.46 40.4266 569.491C40.672 569.523 41.1373 568.801 41.2726 567.912C41.408 567.023 41.2726 566.406 40.8411 566.615C40.4097 566.824 40.232 567.515 39.7498 568.289Z" fill="white" fill-opacity="0.6"/>
        <path d="M50.3418 562.442C50.6126 562.954 50.731 563.603 50.9679 563.488C51.2048 563.373 51.6362 562.599 51.8139 561.553C51.9916 560.507 51.7631 560.026 51.4247 560.308C51.0017 560.968 50.6385 561.684 50.3418 562.442Z" fill="white" fill-opacity="0.6"/>
        <path d="M60.1724 555.716C60.4177 556.208 60.5108 556.877 60.7307 556.762C61.2245 556.169 61.5254 555.373 61.5767 554.524C61.7206 553.478 61.5768 552.903 61.2299 553.311C60.8244 554.075 60.4707 554.88 60.1724 555.716Z" fill="white" fill-opacity="0.6"/>
        <path d="M69.8338 550.204C70.2061 549.18 70.5365 548.132 70.8236 547.067C71.0774 545.864 70.9252 545.362 70.5952 545.665C70.1198 546.361 69.8249 547.217 69.7492 548.123C69.7164 548.819 69.7447 549.516 69.8338 550.204Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.9266 536.911C79.4275 538.438 79.0806 539.379 78.8183 540.352C78.5561 541.324 78.6322 542.444 78.9199 542.057C79.4226 541.29 79.7551 540.374 79.8843 539.4C79.96 538.574 79.9741 537.74 79.9266 536.911Z" fill="white" fill-opacity="0.6"/>
        <path d="M89.0213 529.066C88.4798 530.834 88.0652 531.963 87.8537 532.947C87.5576 534.285 87.6422 534.913 88.0314 534.4C88.5633 533.528 88.9147 532.508 89.0551 531.43C89.1232 530.643 89.1118 529.849 89.0213 529.066Z" fill="white" fill-opacity="0.6"/>
        <path d="M96.9652 525.698C97.1175 526.409 97.1006 527.33 97.329 527.183C97.9164 526.305 98.3073 525.252 98.4627 524.129C98.7588 522.686 98.7503 521.818 98.3442 522.404C97.8311 523.466 97.3706 524.567 96.9652 525.698Z" fill="white" fill-opacity="0.6"/>
        <path d="M108.547 514.371C107.887 516.295 107.388 517.509 107.092 518.659C106.677 520.259 106.754 521.044 107.16 520.625C107.805 519.707 108.237 518.591 108.412 517.394C108.536 516.394 108.581 515.381 108.547 514.371Z" fill="white" fill-opacity="0.6"/>
        <path d="M119.274 508.242C118.547 510.334 117.997 511.599 117.692 512.791C117.278 514.413 117.345 515.281 117.828 514.8C118.528 513.842 118.997 512.661 119.181 511.39C119.306 510.348 119.338 509.292 119.274 508.242Z" fill="white" fill-opacity="0.6"/>
        <path d="M129.24 507.551C129.317 508.765 129.24 510.124 129.46 510.177C129.68 510.229 130.45 509.026 130.949 507.122C131.448 505.219 131.44 504.11 131.009 504.382C130.332 505.36 129.739 506.422 129.24 507.551Z" fill="white" fill-opacity="0.6"/>
        <path d="M142.117 504.288C142.286 505.501 142.227 506.871 142.54 506.871C142.853 506.871 143.682 505.564 144.172 503.608C144.663 501.652 144.587 500.554 144.054 500.91C143.673 501.182 142.963 502.73 142.117 504.288Z" fill="white" fill-opacity="0.6"/>
        <path d="M322.925 499.717C323.337 499.544 323.734 499.319 324.109 499.048C324.72 498.695 325.29 498.241 325.801 497.699C326.301 497.165 326.199 497.071 325.734 497.353C325.091 497.845 324.177 498.65 322.925 499.717Z" fill="white" fill-opacity="0.6"/>
        <path d="M300.54 516.661C300.959 516.468 301.366 516.237 301.758 515.971C302.422 515.481 303.053 514.925 303.644 514.308C303.966 513.921 303.712 514.005 302.908 514.622C302.333 515.113 301.597 515.741 300.54 516.661Z" fill="white" fill-opacity="0.6"/>
        <path d="M282.122 529.756C282.325 529.756 282.291 529.976 282.638 529.756C283.247 529.348 283.814 528.853 284.33 528.282C284.829 527.748 284.787 527.612 284.169 528.041C283.763 528.365 283.061 528.993 282.122 529.756Z" fill="white" fill-opacity="0.6"/>
        <path d="M266.666 539.839C266.784 540.017 266.666 540.299 266.987 540.184C267.547 539.856 268.054 539.406 268.484 538.856C268.95 538.281 268.967 538.04 268.484 538.354C268.104 538.574 267.478 539.17 266.666 539.839Z" fill="white" fill-opacity="0.6"/>
        <path d="M253.789 547.088C253.984 547.286 253.975 547.59 254.263 547.454C254.788 547.12 255.242 546.64 255.591 546.052C255.921 545.498 255.879 545.226 255.397 545.55C254.837 546.022 254.3 546.536 253.789 547.088Z" fill="white" fill-opacity="0.6"/>
        <path d="M243.341 551.188C243.451 551.564 243.392 552.003 243.62 551.941C244.14 551.637 244.569 551.14 244.847 550.518C245.185 549.849 245.211 549.472 244.796 549.681C244.281 550.138 243.795 550.641 243.341 551.188Z" fill="white" fill-opacity="0.6"/>
        <path d="M234.813 552.474C234.813 553.081 234.678 553.708 234.813 553.729C234.949 553.75 235.549 553.175 235.964 552.296C236.379 551.418 236.421 550.895 236.099 551.02C235.632 551.45 235.202 551.937 234.813 552.474Z" fill="white" fill-opacity="0.6"/>
        <path d="M227.563 551.501C227.563 552.192 227.462 552.882 227.631 552.924C227.8 552.966 228.367 552.265 228.739 551.292C229.111 550.319 229.221 549.629 228.866 549.828C228.389 550.333 227.953 550.893 227.563 551.501Z" fill="white" fill-opacity="0.6"/>
        <path d="M221.472 547.6C221.312 548.316 221.227 549.054 221.218 549.797C221.353 550.058 221.802 549.504 222.276 548.343C222.749 547.182 222.893 546.324 222.665 546.251C222.436 546.178 222.022 546.952 221.472 547.6Z" fill="white" fill-opacity="0.6"/>
        <path d="M217.242 539.62C216.633 540.666 216.235 541.283 215.863 542.109C215.491 542.935 215.203 544.567 215.423 544.556C215.643 544.546 216.108 544.149 216.531 542.831C216.818 541.778 217.055 540.706 217.242 539.62Z" fill="white" fill-opacity="0.6"/>
        <path d="M209.856 534.735C209.683 535.651 209.592 536.587 209.586 537.528C209.738 537.883 210.228 537.224 210.736 535.781C211.244 534.338 211.404 533.239 211.159 533.114C210.914 532.988 210.457 533.94 209.856 534.735Z" fill="white" fill-opacity="0.6"/>
        <path d="M202.428 529.495C202.428 530.708 202.251 531.911 202.428 532.047C202.606 532.183 203.274 531.273 203.731 529.683C204.188 528.093 204.323 527.016 203.943 527.173C203.37 527.875 202.862 528.654 202.428 529.495Z" fill="white" fill-opacity="0.6"/>
        <path d="M190.804 515.72C191.007 517.331 190.956 518.858 191.269 519.14C191.582 519.423 192.42 518.711 192.885 516.902C193.351 515.092 193.334 513.764 192.767 513.66C192.361 513.607 191.692 514.726 190.804 515.72Z" fill="white" fill-opacity="0.6"/>
        <path d="M177.979 502.374C177.19 503.667 176.494 505.042 175.898 506.484C175.339 508.231 175.551 509.539 176.211 509.559C176.871 509.58 177.691 508.597 178.013 506.725C178.132 505.278 178.12 503.818 177.979 502.374Z" fill="white" fill-opacity="0.6"/>
        <path d="M157.184 506.558C158.055 505.214 158.825 503.775 159.485 502.259C160.086 500.449 159.764 499.205 159.037 499.226C158.309 499.247 157.345 500.575 157.032 502.364C156.812 503.608 157.099 504.717 157.184 506.558Z" fill="white" fill-opacity="0.6"/>
        <path d="M191.54 455.684C190.841 456.369 190.212 457.16 189.671 458.037C189.07 459.229 189.087 461.551 189.671 461.551C189.98 461.477 190.272 461.318 190.525 461.087C190.778 460.855 190.986 460.556 191.134 460.212C191.385 458.722 191.521 457.206 191.54 455.684Z" fill="white" fill-opacity="0.6"/>
        <path d="M204.467 470.902C204.438 472.102 204.497 473.303 204.645 474.489C204.941 475.535 205.812 474.918 206.134 473.444C206.363 472.468 206.404 471.44 206.252 470.442C205.922 470.044 205.313 470.599 204.467 470.902Z" fill="white" fill-opacity="0.6"/>
        <path d="M215.863 488.85C215.699 489.845 215.584 490.851 215.516 491.862C215.567 492.689 216.193 492.061 216.574 490.816C216.83 490.019 216.976 489.174 217.005 488.317C216.853 487.992 216.413 488.526 215.863 488.85Z" fill="white" fill-opacity="0.6"/>
        <path d="M223.824 496.423C223.297 497.303 222.822 498.229 222.403 499.194C222.214 499.833 222.15 500.517 222.217 501.192C222.327 501.359 222.902 500.659 223.063 500.209C223.371 498.965 223.625 497.701 223.824 496.423Z" fill="white" fill-opacity="0.6"/>
        <path d="M227.775 509.068C227.646 509.869 227.561 510.679 227.521 511.494C227.58 512.132 228.172 511.442 228.46 510.449C228.672 509.782 228.787 509.073 228.798 508.357C228.655 508.148 228.257 508.67 227.775 509.068Z" fill="white" fill-opacity="0.6"/>
        <path d="M233.172 518.335C232.665 519.851 232.614 520.824 232.978 520.542C233.34 520.17 233.629 519.702 233.824 519.172C234.01 518.586 234.154 517.477 234.035 517.467C233.917 517.456 233.401 518.084 233.172 518.335Z" fill="white" fill-opacity="0.6"/>
        <path d="M238.891 525.154C238.758 525.797 238.665 526.45 238.612 527.11C238.654 527.654 239.187 527.246 239.509 526.43C239.724 525.934 239.843 525.383 239.856 524.82C239.729 524.621 239.348 524.956 238.891 525.154Z" fill="white" fill-opacity="0.6"/>
        <path d="M246.954 528.711C246.56 529.069 246.19 529.464 245.845 529.892C245.456 530.468 245.262 531.514 245.575 531.461C245.908 531.329 246.202 531.078 246.421 530.74C246.647 530.085 246.826 529.405 246.954 528.711Z" fill="white" fill-opacity="0.6"/>
        <path d="M255.312 530.353C254.826 530.925 254.371 531.537 253.95 532.183C253.823 532.424 253.95 532.884 254.128 532.842C254.484 532.646 254.797 532.349 255.041 531.974C255.197 531.453 255.288 530.907 255.312 530.353Z" fill="white" fill-opacity="0.6"/>
        <path d="M264.204 530.405C264.305 530.761 264.263 531.064 264.441 531.095C264.695 531.034 264.937 530.909 265.153 530.729C265.368 530.55 265.551 530.319 265.693 530.049C265.837 529.736 265.87 529.307 265.693 529.359C265.175 529.66 264.677 530.009 264.204 530.405Z" fill="white" fill-opacity="0.6"/>
        <path d="M278.924 523.899C278.23 524.506 277.765 524.872 277.418 525.228C277.071 525.583 276.995 526.106 277.511 525.792C277.941 525.516 278.346 525.187 278.721 524.809C278.941 524.464 278.865 524.276 278.924 523.899Z" fill="white" fill-opacity="0.6"/>
        <path d="M292.943 516.756C293.122 516.778 293.303 516.742 293.467 516.651C294.074 516.297 294.629 515.82 295.108 515.239C295.184 515.061 295.032 515.019 294.744 515.145C294.077 515.557 293.469 516.101 292.943 516.756Z" fill="white" fill-opacity="0.6"/>
        <path d="M314.753 501.328C313.636 502.374 312.874 502.981 312.485 503.42C312.282 503.629 312.553 503.598 312.908 503.347C313.466 502.936 314.005 502.489 314.524 502.008C314.812 501.642 314.702 501.61 314.753 501.328Z" fill="white" fill-opacity="0.6"/>
        <path d="M335.505 485.984C335.798 485.835 336.081 485.656 336.351 485.451C336.947 485.068 337.498 484.59 337.992 484.028C338.128 483.798 338.06 483.715 337.883 483.777C337.039 484.425 336.244 485.164 335.505 485.984Z" fill="white" fill-opacity="0.6"/>
        <path d="M172.463 449.021C171.606 450.39 170.853 451.854 170.213 453.393C170.018 454.021 170.399 455.485 170.788 455.485C171.177 455.485 172.311 454.157 172.429 453.55C172.683 452.305 172.488 451.113 172.463 449.021Z" fill="white" fill-opacity="0.6"/>
        <path d="M155.331 455.297C154.84 457.629 155.094 458.612 155.737 457.64C156.269 456.765 156.683 455.79 156.964 454.753C157.133 453.843 157.006 452.588 156.761 452.807C156.211 453.569 155.732 454.404 155.331 455.297Z" fill="white" fill-opacity="0.6"/>
        <path d="M141.491 460.139C141.431 460.987 141.457 461.841 141.567 462.681C141.812 463.173 142.658 461.761 142.996 460.296C143.216 459.344 143.335 457.901 143.149 457.932C142.963 457.964 142.269 459.051 141.491 460.139Z" fill="white" fill-opacity="0.6"/>
        <path d="M128.86 467.419C128.953 468.465 128.86 469.26 129.063 469.396C129.266 469.532 130.146 468.057 130.399 466.718C130.569 465.829 130.628 464.626 130.442 464.72C129.841 465.55 129.31 466.455 128.86 467.419Z" fill="white" fill-opacity="0.6"/>
        <path d="M118.75 471.969C118.056 474.061 117.574 475.347 117.312 476.445C117.185 476.968 117.363 477.669 117.591 477.418C118.021 476.698 118.361 475.901 118.598 475.054C118.731 474.035 118.782 473.001 118.75 471.969Z" fill="white" fill-opacity="0.6"/>
        <path d="M106.424 485.074C106.077 486.946 106.28 487.501 106.754 486.497C107.133 485.667 107.433 484.786 107.65 483.871C107.777 483.15 107.65 482.344 107.481 482.616C107.085 483.406 106.731 484.227 106.424 485.074Z" fill="white" fill-opacity="0.6"/>
        <path d="M95.8655 495.241C95.5525 496.977 95.7301 497.51 96.187 496.58C96.5396 495.789 96.8232 494.955 97.033 494.09C97.3037 492.908 97.033 492.396 96.6438 493.222C96.3571 493.878 96.0974 494.552 95.8655 495.241Z" fill="white" fill-opacity="0.6"/>
        <path d="M85.4935 505.993C85.265 507.572 85.4935 507.959 85.9249 507.039C86.2418 506.299 86.4888 505.516 86.661 504.706C86.7371 504.1 86.5764 503.483 86.4072 503.755C86.0607 504.473 85.7553 505.221 85.4935 505.993Z" fill="white" fill-opacity="0.6"/>
        <path d="M76.1872 513.858C75.7388 515.04 75.4258 515.72 75.2143 516.463C74.9266 517.509 75.0536 518.44 75.4089 517.917C75.7466 517.355 76.0147 516.735 76.2042 516.076C76.2659 515.338 76.2602 514.594 76.1872 513.858Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.748976 560.005C1.22274 560.538 1.42576 560.936 1.73032 561.051C1.85173 561.095 1.97964 561.105 2.10458 561.08C2.22951 561.055 2.34828 560.995 2.45219 560.906C2.55611 560.817 2.64247 560.7 2.70495 560.564C2.76743 560.428 2.80446 560.276 2.81323 560.12C2.81323 559.733 2.44944 559.147 2.22102 559.147C1.84032 559.179 1.42578 559.597 0.748976 560.005Z" fill="white" fill-opacity="0.6"/>
        <path d="M16.6539 555.465C16.3689 556.124 16.1582 556.828 16.0279 557.557C16.0279 557.808 16.5439 558.133 16.8062 558.017C17.1434 557.768 17.3891 557.367 17.4915 556.898C17.4238 556.428 17.0769 556.135 16.6539 555.465Z" fill="white" fill-opacity="0.6"/>
        <path d="M29.2171 552.244C29.6401 552.589 29.8262 552.924 30.0631 552.924C30.4777 552.924 31.0191 551.972 30.9092 551.407C30.8415 551.01 30.5539 550.518 30.3593 550.633C30.0801 550.832 29.7586 551.491 29.2171 552.244Z" fill="white" fill-opacity="0.6"/>
        <path d="M42.7109 542.872C42.4262 543.709 42.197 544.573 42.0257 545.456C42.0257 545.749 42.4063 546.021 42.6348 545.832C42.9507 545.425 43.1778 544.924 43.2947 544.379C43.2693 543.845 43.0155 543.563 42.7109 542.872Z" fill="white" fill-opacity="0.6"/>
        <path d="M64.6056 526.901C64.4787 528.229 64.7494 528.575 65.1555 527.79C65.4446 527.169 65.6611 526.5 65.7984 525.803C65.9507 524.882 65.5785 524.59 65.2316 525.28C64.9945 525.803 64.7854 526.344 64.6056 526.901Z" fill="white" fill-opacity="0.6"/>
        <path d="M54.538 534.191C54.2262 534.898 53.9439 535.624 53.692 536.367C53.4636 537.203 53.692 537.998 54.0812 537.549C54.4097 537.099 54.6628 536.572 54.8257 536.001C54.8103 535.382 54.7131 534.771 54.538 534.191Z" fill="white" fill-opacity="0.6"/>
        <path d="M706.521 588.611C707.109 588.171 707.675 587.686 708.213 587.157C708.678 586.624 708.213 586.174 707.528 586.383C706.843 586.592 705.751 587.502 705.777 587.921C705.802 588.339 706.191 588.329 706.521 588.611Z" fill="white" fill-opacity="0.6"/>
        <path d="M685.921 592.324C685.238 592.933 684.591 593.601 683.983 594.322C683.84 594.542 684.407 594.73 684.83 594.573C685.389 594.319 685.905 593.94 686.352 593.454C686.488 593.046 686.166 592.837 685.921 592.324Z" fill="white" fill-opacity="0.6"/>
        <path d="M662.47 600.88C662.605 600.953 662.715 601.194 663.096 601.141C663.851 600.962 664.537 600.48 665.05 599.771C665.134 599.468 664.957 599.227 664.627 599.217C663.787 599.485 663.034 600.065 662.47 600.88Z" fill="white" fill-opacity="0.6"/>
        <path d="M644.399 605.471C643.434 606.141 642.775 606.517 642.301 606.957C641.632 607.521 641.709 608.003 642.428 607.72C643.026 607.463 643.594 607.111 644.12 606.674C644.424 606.235 644.306 605.963 644.399 605.471Z" fill="white" fill-opacity="0.6"/>
        <path d="M625.144 611.82C623.942 612.688 623.156 613.18 622.665 613.63C622.411 613.87 622.47 614.226 622.775 614.153C623.339 613.929 623.879 613.62 624.382 613.232C624.78 612.793 624.805 612.479 625.144 611.82Z" fill="white" fill-opacity="0.6"/>
        <path d="M606.921 618.211C605.973 618.849 605.347 619.257 604.84 619.623C604.146 620.167 603.994 620.669 604.662 620.365C605.232 620.081 605.778 619.731 606.295 619.32C606.561 619.005 606.774 618.628 606.921 618.211Z" fill="white" fill-opacity="0.6"/>
        <path d="M590.627 624.016C589.518 624.832 588.757 625.344 588.258 625.773C588.013 625.982 588.046 626.254 588.334 626.139C588.874 625.876 589.392 625.551 589.882 625.166C590.179 624.835 590.43 624.446 590.627 624.016Z" fill="white" fill-opacity="0.6"/>
        <path d="M572.624 631.756C572.705 631.812 572.796 631.847 572.889 631.858C572.982 631.869 573.076 631.855 573.165 631.818C574.016 631.499 574.822 631.015 575.551 630.386C575.737 630.145 575.635 630.009 575.306 630.082C574.349 630.473 573.446 631.037 572.624 631.756Z" fill="white" fill-opacity="0.6"/>
        <path d="M549.426 638.973C550.678 638.335 551.634 637.927 552.167 637.55C552.455 637.341 552.226 637.079 551.871 637.048C551.032 637.178 550.226 637.535 549.511 638.094C549.282 638.418 549.452 638.533 549.426 638.973Z" fill="white" fill-opacity="0.6"/>
        <path d="M528.521 638.805C527.655 639.174 526.807 639.607 525.983 640.102C525.772 640.27 526.254 640.625 526.719 640.604C527.36 640.521 527.985 640.313 528.572 639.987C528.843 639.642 528.598 639.37 528.521 638.805Z" fill="white" fill-opacity="0.6"/>
        <path d="M503.065 636.734C503.361 637.278 503.361 637.634 503.708 637.78C504.071 637.923 504.455 637.97 504.834 637.918C505.213 637.866 505.579 637.716 505.908 637.477C506.119 637.184 506.077 636.661 505.747 636.588C504.851 636.564 503.956 636.613 503.065 636.734Z" fill="white" fill-opacity="0.6"/>
        <path d="M484.707 628.838C483.902 628.945 483.108 629.155 482.338 629.465C482.118 629.601 482.406 630.302 482.786 630.511C483.341 630.688 483.923 630.688 484.478 630.511C484.749 630.114 484.639 629.601 484.707 628.838Z" fill="white" fill-opacity="0.6"/>
        <path d="M465.638 616.945C464.9 616.957 464.167 617.083 463.455 617.322C463.252 617.447 463.455 618.295 463.819 618.546C464.296 618.825 464.833 618.905 465.35 618.776C465.646 618.42 465.545 617.813 465.638 616.945Z" fill="white" fill-opacity="0.6"/>
        <path d="M444.631 599.164C444.107 599.886 444.428 600.869 445.224 601.256C445.685 601.513 446.207 601.547 446.687 601.351C447.144 601.026 446.687 599.823 445.96 599.457C445.53 599.283 445.083 599.185 444.631 599.164Z" fill="white" fill-opacity="0.6"/>
        <path d="M427.559 580.38C428.077 580.527 428.611 580.573 429.141 580.516C429.623 580.285 429.386 579.093 428.82 578.424C428.253 577.754 427.331 577.618 427.212 578.33C427.246 579.03 427.363 579.721 427.559 580.38Z" fill="white" fill-opacity="0.6"/>
        <path d="M412.229 557.892C412.872 557.965 413.38 558.195 413.701 558.028C414.167 557.787 413.972 556.553 413.465 555.936C412.957 555.319 412.102 555.12 411.967 555.853C411.979 556.544 412.068 557.23 412.229 557.892Z" fill="white" fill-opacity="0.6"/>
        <path d="M398.228 532.622C398.18 533.356 398.209 534.094 398.313 534.819C398.558 535.687 399.412 536.262 399.742 535.76C399.864 535.529 399.94 535.266 399.965 534.991C399.99 534.717 399.963 534.44 399.886 534.181C399.401 533.56 398.841 533.035 398.228 532.622Z" fill="white" fill-opacity="0.6"/>
        <path d="M387.213 512.781C386.426 513.022 385.876 513.011 385.572 513.367C385.419 513.534 385.572 514.591 385.851 514.915C386.13 515.239 386.883 515.396 386.985 515.176C387.164 514.398 387.241 513.589 387.213 512.781Z" fill="white" fill-opacity="0.6"/>
        <path d="M373.685 496.287C373.186 497.092 373.322 497.939 373.897 498.159C374.086 498.223 374.284 498.235 374.477 498.193C374.67 498.152 374.853 498.058 375.014 497.918C375.101 497.793 375.164 497.643 375.195 497.481C375.227 497.32 375.227 497.152 375.196 496.99C375.164 496.829 375.102 496.679 375.015 496.553C374.927 496.427 374.817 496.328 374.692 496.266C374.358 496.203 374.018 496.21 373.685 496.287Z" fill="white" fill-opacity="0.6"/>
        <path d="M363.296 484.415C362.704 484.641 362.136 484.953 361.604 485.346C361.435 485.514 361.528 486.235 361.748 486.392C362.126 486.521 362.529 486.464 362.873 486.235C363.104 485.666 363.248 485.049 363.296 484.415Z" fill="white" fill-opacity="0.6"/>
        <path d="M351.08 479.75C350.564 480.001 350.061 480.291 349.574 480.619C349.075 481.027 348.99 481.665 349.456 481.581C349.661 481.603 349.868 481.571 350.064 481.489C350.259 481.407 350.439 481.276 350.591 481.104C350.744 480.933 350.866 480.724 350.95 480.491C351.034 480.258 351.078 480.006 351.08 479.75Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.4912 535.833C37.1667 536.416 36.8836 537.032 36.6451 537.674C36.459 538.406 36.8482 538.982 37.2289 538.532C37.5344 538.121 37.7499 537.621 37.8549 537.078C37.948 536.67 37.6942 536.398 37.4912 535.833Z" fill="white" fill-opacity="0.6"/>
        <path d="M350.488 473.349C349.811 473.872 349.371 474.176 349.05 474.489C348.576 474.96 348.576 475.368 349.05 475.117C349.454 474.882 349.832 474.583 350.175 474.228C350.42 473.967 350.369 473.747 350.488 473.349Z" fill="white" fill-opacity="0.6"/>
        <path d="M361.131 474.793C361.283 475.201 361.283 475.567 361.486 475.734C361.689 475.902 362.484 475.535 362.763 474.971C363.043 474.406 362.916 474.165 362.45 474.218C361.995 474.349 361.552 474.542 361.131 474.793Z" fill="white" fill-opacity="0.6"/>
        <path d="M374.684 482.261C374.224 482.384 373.78 482.585 373.364 482.857C372.983 483.233 373.178 484.269 373.635 484.29C373.823 484.292 374.009 484.248 374.184 484.16C374.358 484.072 374.516 483.942 374.65 483.777C374.819 483.505 374.684 482.972 374.684 482.261Z" fill="white" fill-opacity="0.6"/>
        <path d="M385.2 496.789C385.158 497.386 385.183 497.988 385.276 498.577C385.308 498.705 385.365 498.822 385.442 498.918C385.518 499.013 385.612 499.085 385.716 499.127C385.819 499.169 385.929 499.18 386.036 499.159C386.144 499.138 386.245 499.085 386.333 499.006C386.454 498.927 386.557 498.814 386.635 498.675C386.712 498.536 386.762 498.376 386.778 498.208C386.795 498.041 386.778 497.871 386.73 497.713C386.682 497.555 386.604 497.414 386.502 497.301C386.095 497.04 385.654 496.867 385.2 496.789Z" fill="white" fill-opacity="0.6"/>
        <path d="M398.989 515.929C398.355 515.982 397.915 515.856 397.636 516.076C397.357 516.295 397.484 517.822 397.966 518.063C398.137 518.154 398.326 518.183 398.511 518.146C398.695 518.109 398.867 518.007 399.006 517.854C399.176 517.582 399.015 516.85 398.989 515.929Z" fill="white" fill-opacity="0.6"/>
        <path d="M412.289 537.8C411.62 537.8 411.155 537.612 410.876 537.8C410.597 537.988 410.766 539.588 411.273 539.892C411.442 540.004 411.634 540.053 411.825 540.033C412.015 540.012 412.197 539.923 412.348 539.777C412.517 539.526 412.339 538.752 412.289 537.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M425.275 560.266C425.313 560.979 425.427 561.682 425.613 562.358C425.926 563.164 426.874 563.488 427.085 562.829C427.132 562.545 427.13 562.253 427.081 561.97C427.031 561.688 426.935 561.421 426.798 561.187C426.51 560.737 425.943 560.632 425.275 560.266Z" fill="white" fill-opacity="0.6"/>
        <path d="M443.523 583.099C442.985 582.993 442.437 582.993 441.899 583.099C441.459 583.36 441.899 584.772 442.5 585.065C442.697 585.173 442.911 585.221 443.126 585.204C443.341 585.188 443.55 585.108 443.735 584.971C443.921 584.678 443.65 583.957 443.523 583.099Z" fill="white" fill-opacity="0.6"/>
        <path d="M460.477 602.679C460.612 603.453 460.562 603.944 460.816 604.405C460.99 604.696 461.233 604.915 461.512 605.031C461.79 605.147 462.09 605.154 462.372 605.053C463.041 604.844 463.083 604.007 462.372 603.516C461.762 603.169 461.127 602.889 460.477 602.679Z" fill="white" fill-opacity="0.6"/>
        <path d="M479.538 617.5C479.631 618.127 479.538 618.546 479.783 618.88C479.989 619.105 480.243 619.252 480.514 619.304C480.785 619.355 481.063 619.31 481.314 619.173C482 618.88 482.093 618.19 481.424 617.876C480.804 617.691 480.173 617.566 479.538 617.5Z" fill="white" fill-opacity="0.6"/>
        <path d="M502.041 626.923C501.376 627.07 500.72 627.276 500.079 627.541C499.546 627.875 500.079 628.639 500.832 628.587C501.36 628.565 501.875 628.378 502.329 628.043C502.515 627.823 502.202 627.457 502.041 626.923Z" fill="white" fill-opacity="0.6"/>
        <path d="M522.176 632.331C522.371 632.728 522.337 633.063 522.667 633.22C523.282 633.379 523.922 633.256 524.469 632.875C525.197 632.425 525.205 631.892 524.393 631.913C523.843 631.934 523.141 632.143 522.176 632.331Z" fill="white" fill-opacity="0.6"/>
        <path d="M545.594 632.624C545.848 632.896 545.856 633.168 546.22 633.22C546.995 633.198 547.745 632.872 548.36 632.289C548.504 632.038 548.259 631.777 547.853 631.829C547.088 632.038 546.333 632.304 545.594 632.624Z" fill="white" fill-opacity="0.6"/>
        <path d="M569.35 628.179C569.739 628.252 569.849 628.419 570.272 628.325C571.062 628.078 571.8 627.625 572.438 626.997C572.742 626.61 572.48 626.547 571.634 626.923C571.042 627.195 570.331 627.624 569.35 628.179Z" fill="white" fill-opacity="0.6"/>
        <path d="M589.459 620.617C589.459 620.805 589.366 621.035 589.552 621.014C590.19 620.718 590.782 620.29 591.304 619.748C591.718 619.32 591.718 619.152 591.177 619.456C590.779 619.707 590.212 620.114 589.459 620.617Z" fill="white" fill-opacity="0.6"/>
        <path d="M604.467 614.665C604.467 614.927 604.298 615.209 604.467 615.23C605 615.061 605.496 614.75 605.922 614.32C606.625 613.692 606.836 613.274 606.244 613.536C605.846 613.745 605.246 614.174 604.467 614.665Z" fill="white" fill-opacity="0.6"/>
        <path d="M623.257 607.783C623.19 607.532 623.333 607.176 623.054 607.27C622.52 607.512 622.012 607.831 621.54 608.222C620.88 608.766 620.778 609.268 621.396 608.975C621.802 608.777 622.428 608.316 623.257 607.783Z" fill="white" fill-opacity="0.6"/>
        <path d="M639.111 602.072C639.331 602.25 639.357 602.501 639.67 602.459C640.361 602.2 640.983 601.716 641.472 601.058C641.768 600.597 641.615 600.441 640.973 600.786C640.499 601.037 639.915 601.507 639.111 602.072Z" fill="white" fill-opacity="0.6"/>
        <path d="M661.065 594.541C660.871 594.28 660.904 593.903 660.591 594.008C660.041 594.245 659.52 594.576 659.043 594.991C658.417 595.566 658.51 596.089 659.17 595.786C659.635 595.566 660.228 595.096 661.065 594.541Z" fill="white" fill-opacity="0.6"/>
        <path d="M680.701 587C680.114 587.487 679.549 588.014 679.009 588.58C678.586 589.134 679.204 589.395 679.855 588.946C680.334 588.627 680.764 588.206 681.124 587.701C681.285 587.408 680.929 587.283 680.701 587Z" fill="white" fill-opacity="0.6"/>
        <path d="M700.362 582.9C700.913 582.457 701.423 581.941 701.885 581.363C702.029 581.049 701.817 580.725 701.487 580.735C700.946 580.735 699.88 581.635 699.795 582.084C699.711 582.534 700.1 582.534 700.362 582.9Z" fill="white" fill-opacity="0.6"/>
        <path d="M49.7835 526.932C49.5175 527.637 49.2914 528.364 49.1067 529.108C48.9798 529.851 49.479 530.154 49.792 529.568C50.0394 529.039 50.2057 528.457 50.2827 527.853C50.2996 527.455 50.0289 527.34 49.7835 526.932Z" fill="white" fill-opacity="0.6"/>
        <path d="M61.3061 520.061C61.5915 519.312 61.8264 518.535 62.0083 517.739C62.0506 517.268 61.8899 516.818 61.6953 516.86C61.5007 516.902 60.8493 518.283 60.8493 518.952C60.9001 519.391 61.1115 519.548 61.3061 520.061Z" fill="white" fill-opacity="0.6"/>
        <path d="M73.4631 506.254C73.2939 505.826 73.2432 505.208 73.0655 505.397C72.722 505.89 72.4603 506.462 72.2956 507.081C72.008 508.032 72.1772 508.89 72.5325 508.367C72.8784 507.688 73.1892 506.982 73.4631 506.254Z" fill="white" fill-opacity="0.6"/>
        <path d="M84.0637 492.793C83.6492 494.111 83.3446 494.885 83.2177 495.638C82.9977 496.684 83.3277 497.103 83.6745 496.297C83.9625 495.581 84.1839 494.826 84.3345 494.048C84.3852 493.525 84.1991 493.348 84.0637 492.793Z" fill="white" fill-opacity="0.6"/>
        <path d="M93.9619 483.568C94.0888 484.06 94.1057 484.614 94.2749 484.614C94.4441 484.614 95.1209 483.014 95.2309 482.01C95.2901 481.445 95.1632 480.964 94.9771 481.162C94.5907 481.932 94.2513 482.736 93.9619 483.568Z" fill="white" fill-opacity="0.6"/>
        <path d="M105.205 473.632C105.591 472.609 105.932 471.561 106.229 470.494C106.364 469.814 106.322 469.103 106.17 469.092C106.017 469.082 105.231 470.85 105.121 471.822C105.084 472.427 105.112 473.036 105.205 473.632Z" fill="white" fill-opacity="0.6"/>
        <path d="M116.322 460.432C116.407 461.07 116.381 461.781 116.55 461.876C116.72 461.97 117.498 460.233 117.667 459.041C117.769 458.372 117.667 457.713 117.481 457.901C117.04 458.705 116.652 459.551 116.322 460.432Z" fill="white" fill-opacity="0.6"/>
        <path d="M128.234 449.858C128.185 450.528 128.207 451.203 128.301 451.866C128.496 452.18 128.919 451.27 129.317 450.004C129.799 448.425 129.917 447.044 129.494 447.546C129.03 448.278 128.608 449.051 128.234 449.858Z" fill="white" fill-opacity="0.6"/>
        <path d="M140.56 441.971C140.729 442.599 140.754 443.321 140.974 443.352C141.194 443.383 142.015 441.543 142.286 440.057C142.506 438.823 142.387 438.3 141.905 439.011C141.416 439.969 140.967 440.957 140.56 441.971Z" fill="white" fill-opacity="0.6"/>
        <path d="M154.02 437.777C154.572 436.803 155.059 435.775 155.475 434.702C155.72 433.884 155.821 433.01 155.771 432.14C155.627 431.502 154.612 433.186 154.325 434.566C154.163 435.627 154.062 436.7 154.02 437.777Z" fill="white" fill-opacity="0.6"/>
        <path d="M168.647 433.447C169.224 432.457 169.73 431.407 170.162 430.309C170.401 429.489 170.468 428.608 170.356 427.747C170.153 427.13 169.028 428.929 168.8 430.215C168.677 431.286 168.626 432.367 168.647 433.447Z" fill="white" fill-opacity="0.6"/>
        <path d="M186.955 434.964C187.742 433.573 188.419 432.799 188.647 431.941C188.853 431.064 188.796 430.124 188.486 429.295C188.029 428.605 186.913 430.1 186.752 431.554C186.725 432.696 186.793 433.839 186.955 434.964Z" fill="white" fill-opacity="0.6"/>
        <path d="M204.01 445.957C204.721 444.806 205.296 444.262 205.508 443.446C205.711 442.511 205.711 441.526 205.508 440.591C205.178 439.691 204.196 440.81 203.985 442.275C203.916 443.501 203.925 444.732 204.01 445.957Z" fill="white" fill-opacity="0.6"/>
        <path d="M216.87 464.124C217.496 463.465 217.961 463.35 218.198 462.66C218.461 461.701 218.578 460.691 218.545 459.679C218.452 458.529 217.504 458.936 217.233 460.213C217.051 461.507 216.93 462.813 216.87 464.124Z" fill="white" fill-opacity="0.6"/>
        <path d="M224.94 478.893C225.363 478.243 225.731 477.542 226.04 476.801C226.257 476.07 226.372 475.299 226.379 474.521C226.311 473.82 225.66 474.782 225.372 475.985C225.189 476.945 225.045 477.915 224.94 478.893Z" fill="white" fill-opacity="0.6"/>
        <path d="M232.123 485.555C231.741 486.153 231.396 486.786 231.091 487.449C230.736 488.369 230.592 490.042 230.897 489.781C231.268 489.333 231.557 488.791 231.743 488.191C231.925 487.326 232.052 486.445 232.123 485.555Z" fill="white" fill-opacity="0.6"/>
        <path d="M235.846 499.257C235.762 499.95 235.72 500.649 235.719 501.349C235.803 501.841 236.336 500.962 236.666 499.843C236.937 498.902 237.005 498.201 236.734 498.264C236.463 498.326 236.243 498.776 235.846 499.257Z" fill="white" fill-opacity="0.6"/>
        <path d="M241.057 509.162C240.987 509.737 240.964 510.318 240.989 510.898C241.091 511.317 241.615 510.511 241.929 509.528C242.242 508.545 242.225 508.106 241.929 508.179C241.632 508.252 241.455 508.702 241.057 509.162Z" fill="white" fill-opacity="0.6"/>
        <path d="M246.92 518.628C247.281 518.202 247.599 517.725 247.867 517.205C248.041 516.771 248.117 516.287 248.087 515.804C247.986 515.385 247.394 516.013 247.174 516.766C247.051 517.378 246.966 518 246.92 518.628Z" fill="white" fill-opacity="0.6"/>
        <path d="M254.559 521.19C254.486 521.616 254.467 522.054 254.5 522.487C254.619 522.791 254.965 522.487 255.295 521.943C255.625 521.399 255.862 520.49 255.541 520.531C255.192 520.698 254.862 520.919 254.559 521.19Z" fill="white" fill-opacity="0.6"/>
        <path d="M265.109 521.671C264.699 522.007 264.306 522.374 263.933 522.77C263.544 523.282 263.527 523.941 263.933 523.753C264.291 523.547 264.609 523.248 264.864 522.874C265.041 522.571 265.007 522.226 265.109 521.671Z" fill="white" fill-opacity="0.6"/>
        <path d="M276.632 519.59C276.198 519.986 275.782 520.412 275.388 520.866C275.05 521.326 275.388 521.619 275.811 521.264C276.168 520.976 276.487 520.623 276.758 520.218C276.868 520.008 276.632 519.893 276.632 519.59Z" fill="white" fill-opacity="0.6"/>
        <path d="M289.584 514.716C289.745 514.852 289.745 515.051 289.981 514.988C290.442 514.791 290.861 514.47 291.208 514.047C291.69 513.492 291.648 513.21 291.09 513.565C290.709 513.806 290.235 514.214 289.584 514.716Z" fill="white" fill-opacity="0.6"/>
        <path d="M307.333 504.121C307.536 504.121 307.536 504.298 307.815 504.121C308.342 503.826 308.83 503.435 309.262 502.96C309.829 502.364 309.778 502.175 309.118 502.646C308.67 503.012 308.12 503.483 307.333 504.121Z" fill="white" fill-opacity="0.6"/>
        <path d="M331.224 486.58C330.285 487.459 329.625 488.045 329.245 488.432C328.712 488.986 329.245 488.787 329.998 488.128C330.527 487.687 331.035 487.209 331.52 486.695C331.74 486.434 331.394 486.612 331.224 486.58Z" fill="white" fill-opacity="0.6"/>
        <path d="M338.881 488.16C338.669 487.961 338.652 487.762 338.415 487.794C337.864 487.977 337.362 488.338 336.96 488.84C336.85 489.08 336.893 489.363 337.104 489.268C337.711 488.937 338.304 488.567 338.881 488.16Z" fill="white" fill-opacity="0.6"/>
        <path d="M317.13 502.196C317.65 501.914 318.158 501.6 318.653 501.255C318.932 501.046 320.015 500.083 320.235 499.843C320.455 499.602 320.176 499.676 319.846 499.927C319.211 500.418 318.399 501.14 317.13 502.196Z" fill="white" fill-opacity="0.6"/>
        <path d="M299.017 515.113C298.027 515.982 297.325 516.578 296.885 517.007C296.69 517.195 296.936 517.184 297.223 517.007C297.86 516.581 298.455 516.069 299 515.48C299.169 515.249 299.025 515.249 299.017 515.113Z" fill="white" fill-opacity="0.6"/>
        <path d="M279.508 528.397C279.601 528.397 279.626 528.606 279.939 528.47C280.376 528.163 280.792 527.814 281.183 527.424C281.42 527.152 281.462 526.912 281.301 526.932C280.635 527.279 280.027 527.775 279.508 528.397Z" fill="white" fill-opacity="0.6"/>
        <path d="M265.329 536.126C265.38 536.241 265.329 536.524 265.591 536.492C265.989 536.221 266.358 535.891 266.691 535.509C266.919 535.216 267.004 534.871 266.894 534.808C266.784 534.746 265.929 535.352 265.329 536.126Z" fill="white" fill-opacity="0.6"/>
        <path d="M253.586 540.456C253.764 540.802 253.781 541.126 254.026 541.105C254.397 540.828 254.726 540.474 254.999 540.059C255.185 539.745 255.228 539.233 255.075 539.295C254.555 539.634 254.058 540.022 253.586 540.456Z" fill="white" fill-opacity="0.6"/>
        <path d="M244.39 541.806C244.39 542.078 244.263 542.621 244.39 542.778C244.517 542.935 245.05 542.234 245.236 541.868C245.448 541.516 245.572 541.094 245.592 540.655C245.592 540.299 244.966 540.822 244.39 541.806Z" fill="white" fill-opacity="0.6"/>
        <path d="M236.708 540.279C236.612 540.696 236.577 541.132 236.607 541.565C236.607 541.68 237.208 541.063 237.453 540.634C237.673 540.18 237.814 539.674 237.867 539.149C237.851 538.657 237.284 539.17 236.708 540.279Z" fill="white" fill-opacity="0.6"/>
        <path d="M230.253 536.179C230.138 536.7 230.078 537.238 230.076 537.779C230.076 537.936 230.642 537.298 230.871 536.817C231.112 536.263 231.272 535.661 231.345 535.038C231.379 534.421 230.837 534.913 230.253 536.179Z" fill="white" fill-opacity="0.6"/>
        <path d="M225.507 530.217C225.586 529.598 225.612 528.97 225.583 528.344C225.465 527.957 224.805 528.962 224.56 529.903C224.382 530.593 224.213 531.681 224.323 531.66C224.433 531.639 224.957 530.907 225.507 530.217Z" fill="white" fill-opacity="0.6"/>
        <path d="M219.179 521.389C219.04 522.1 218.952 522.824 218.917 523.554C218.917 523.795 219.475 523.083 219.763 522.508C220.035 521.77 220.223 520.988 220.321 520.186C220.355 519.297 219.805 519.778 219.179 521.389Z" fill="white" fill-opacity="0.6"/>
        <path d="M212.834 512.666C212.741 513.544 212.741 514.434 212.834 515.312C212.902 515.469 213.545 514.371 213.782 513.691C214.02 512.982 214.141 512.221 214.137 511.453C213.909 511.359 213.452 511.976 212.834 512.666Z" fill="white" fill-opacity="0.6"/>
        <path d="M205.906 499.006C205.241 499.856 204.672 500.813 204.213 501.851C204.032 502.768 204.032 503.726 204.213 504.644C204.442 505.512 205.322 504.403 205.643 502.834C205.797 501.566 205.885 500.288 205.906 499.006Z" fill="white" fill-opacity="0.6"/>
        <path d="M192.945 484.342C192.161 485.158 191.469 486.101 190.889 487.145C190.694 488.096 190.694 489.092 190.889 490.043C191.117 490.984 192.183 489.896 192.53 488.369C192.743 487.04 192.882 485.695 192.945 484.342Z" fill="white" fill-opacity="0.6"/>
        <path d="M173.233 478.841C173.715 480.294 173.859 481.529 174.307 481.821C174.527 481.968 175.542 480.441 175.788 479.552C176.033 478.663 175.94 476.864 175.669 476.853C175.111 476.853 174.392 477.826 173.233 478.841Z" fill="white" fill-opacity="0.6"/>
        <path d="M156.126 480.995C156.922 480.535 157.7 478.726 157.962 477.125C158.199 475.734 158.055 475.033 157.505 475.651C156.936 476.414 156.481 477.295 156.16 478.255C156.049 479.162 156.037 480.084 156.126 480.995Z" fill="white" fill-opacity="0.6"/>
        <path d="M144.029 480.504C143.809 479.646 143.783 478.83 143.538 478.778C143.157 478.694 142.21 480.776 142.049 482.02C141.93 482.93 141.981 484.112 142.201 483.924C142.883 482.846 143.494 481.702 144.029 480.504Z" fill="white" fill-opacity="0.6"/>
        <path d="M129.334 487.009C129.334 487.522 129.283 488.62 129.486 488.84C129.98 488.164 130.362 487.375 130.611 486.518C130.849 485.697 130.955 484.826 130.924 483.955C130.806 483.296 129.993 484.781 129.334 487.009Z" fill="white" fill-opacity="0.6"/>
        <path d="M117.718 493.567C117.718 494.038 117.718 495.053 117.862 495.23C118.308 494.54 118.657 493.762 118.894 492.929C119.115 492.161 119.212 491.348 119.181 490.534C119.071 489.959 118.327 491.423 117.718 493.567Z" fill="white" fill-opacity="0.6"/>
        <path d="M106.745 501.203C106.948 501.914 106.973 502.667 107.21 502.594C107.619 501.83 107.938 500.999 108.158 500.125C108.336 499.372 108.361 498.201 108.209 498.368C107.661 499.264 107.172 500.211 106.745 501.203Z" fill="white" fill-opacity="0.6"/>
        <path d="M96.7707 509.811C96.7707 510.177 96.7706 511.024 96.9483 511.118C97.3165 510.46 97.6018 509.737 97.7943 508.974C97.9703 508.321 98.0365 507.63 97.9889 506.945C97.8959 506.505 97.2783 507.896 96.7707 509.811Z" fill="white" fill-opacity="0.6"/>
        <path d="M87.1261 520.186C87.7046 519.23 88.085 518.113 88.2344 516.933C88.3528 515.982 88.2344 515.636 87.8959 516.316C87.5482 517.022 87.2645 517.772 87.0499 518.555C87.0073 519.1 87.033 519.65 87.1261 520.186Z" fill="white" fill-opacity="0.6"/>
        <path d="M77.2787 528.031C77.3464 528.324 77.3718 529.003 77.5664 529.077C77.8923 528.493 78.1434 527.851 78.3109 527.173C78.4411 526.63 78.4615 526.055 78.3701 525.5C78.2094 525.144 77.651 526.399 77.2787 528.031Z" fill="white" fill-opacity="0.6"/>
        <path d="M68.5817 533.94C68.1939 534.89 67.8465 535.864 67.5411 536.858C67.505 537.054 67.5053 537.258 67.5419 537.453C67.5785 537.649 67.6505 537.832 67.7526 537.988C67.9641 538.145 68.5309 536.764 68.5986 535.896C68.6424 535.245 68.6367 534.59 68.5817 533.94Z" fill="white" fill-opacity="0.6"/>
        <path d="M8.5577 568.717C8.7692 568.927 9.03992 569.387 9.4037 569.47C9.5729 569.47 10.0636 568.864 10.1228 568.498C10.1266 568.292 10.0883 568.088 10.0114 567.905C9.9345 567.722 9.82153 567.566 9.6829 567.452C9.29374 567.18 8.6846 567.787 8.5577 568.717Z" fill="white" fill-opacity="0.6"/>
        <path d="M22.466 565.318C22.6522 565.527 22.8806 565.977 23.2021 566.029C23.3459 566.029 23.8197 565.297 23.8789 564.9C23.8928 564.702 23.8669 564.503 23.8035 564.32C23.74 564.138 23.6409 563.978 23.5151 563.854C23.1767 563.54 22.6099 564.272 22.466 565.318Z" fill="white" fill-opacity="0.6"/>
        <path d="M36.0358 557.798C35.6768 558.481 35.3682 559.202 35.1136 559.952C35.0544 560.224 35.3675 560.716 35.5959 560.81C35.8243 560.904 36.4419 559.942 36.4419 559.252C36.3661 558.745 36.2292 558.255 36.0358 557.798Z" fill="white" fill-opacity="0.6"/>
        <path d="M57.6682 547.161C57.9856 546.397 58.268 545.611 58.5142 544.807C58.7511 543.814 58.565 543.04 58.2096 543.542C57.8946 544.03 57.6565 544.587 57.5074 545.184C57.4881 545.849 57.5422 546.514 57.6682 547.161Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.4401 550.958C47.1341 551.767 46.8823 552.607 46.6871 553.468C46.677 553.638 46.7192 553.807 46.8055 553.941C46.8917 554.075 47.0156 554.164 47.1524 554.189C47.457 554.189 47.9053 552.966 47.8715 552.234C47.8546 551.721 47.66 551.554 47.4401 550.958Z" fill="white" fill-opacity="0.6"/>
        <path d="M24.0227 545.968C24.2932 545.448 24.4985 544.881 24.6318 544.284C24.6251 544.102 24.5689 543.927 24.4722 543.789C24.3756 543.65 24.2441 543.556 24.0989 543.521C23.6843 543.521 23.1852 544.452 23.3205 544.985C23.4051 545.341 23.6758 545.519 24.0227 545.968Z" fill="white" fill-opacity="0.6"/>
        <path d="M9.75037 547.966C9.42282 548.392 9.1387 548.867 8.90434 549.378C8.72667 549.974 9.24278 550.602 9.67425 550.309C9.99866 550.052 10.2316 549.652 10.3257 549.19C10.3595 548.834 10.038 548.531 9.75037 547.966Z" fill="white" fill-opacity="0.6"/>
        <path d="M714.778 591.874C714.018 592.403 713.291 593 712.604 593.663C712.418 593.893 712.849 594.249 713.23 594.311C713.6 594.268 713.958 594.13 714.281 593.905C714.605 593.679 714.887 593.373 715.108 593.004C715.201 592.638 714.947 592.397 714.778 591.874Z" fill="white" fill-opacity="0.6"/>
        <path d="M691.801 598.045C691.101 598.599 690.439 599.222 689.821 599.907C689.652 600.169 690.134 600.399 690.557 600.367C690.922 600.297 691.271 600.131 691.581 599.88C691.89 599.629 692.152 599.3 692.35 598.913C692.41 598.6 692.08 598.453 691.801 598.045Z" fill="white" fill-opacity="0.6"/>
        <path d="M667.766 606.779C668.629 606.555 669.398 605.953 669.931 605.084C670.058 604.645 669.728 604.509 669.001 604.896C668.414 605.192 667.864 605.59 667.368 606.078C667.266 606.455 667.639 606.612 667.766 606.779Z" fill="white" fill-opacity="0.6"/>
        <path d="M644.949 612.688C645.516 612.856 645.719 613.086 646.243 612.981C646.82 612.696 647.359 612.305 647.842 611.82C648.087 611.517 647.918 611.12 647.614 611.224C647.021 611.444 646.201 611.988 644.949 612.688Z" fill="white" fill-opacity="0.6"/>
        <path d="M627.233 617.165C626.083 617.949 625.254 618.431 624.695 618.901C624.45 619.11 624.602 619.435 624.865 619.476C625.63 619.277 626.34 618.837 626.929 618.2C627.191 617.855 627.106 617.615 627.233 617.165Z" fill="white" fill-opacity="0.6"/>
        <path d="M604.789 625.198C605.17 625.365 605.246 625.595 605.686 625.491C606.259 625.215 606.806 624.865 607.318 624.445C607.606 624.141 607.615 623.754 607.318 623.859C606.811 624.016 605.999 624.528 604.789 625.198Z" fill="white" fill-opacity="0.6"/>
        <path d="M349.329 490.597C349.845 490.398 350.348 490.154 350.835 489.865C351.368 489.446 351.452 488.735 350.97 488.704C350.554 488.703 350.145 488.84 349.786 489.101C349.506 489.478 349.515 489.917 349.329 490.597Z" fill="white" fill-opacity="0.6"/>
        <path d="M363.39 498.609C363.212 497.876 363.212 497.385 362.992 497.102C362.886 497.004 362.765 496.934 362.637 496.896C362.508 496.858 362.375 496.853 362.245 496.882C362.115 496.91 361.991 496.971 361.881 497.062C361.771 497.152 361.676 497.269 361.604 497.406C361.486 497.803 361.503 498.546 361.706 498.65C362.265 498.745 362.834 498.731 363.39 498.609Z" fill="white" fill-opacity="0.6"/>
        <path d="M373.542 511.296C373.505 511.846 373.554 512.4 373.685 512.927C373.778 513.178 374.531 513.325 374.811 513.126C374.95 512.949 375.054 512.733 375.113 512.497C375.171 512.26 375.184 512.01 375.149 511.766C375.031 511.076 374.218 510.762 373.542 511.296Z" fill="white" fill-opacity="0.6"/>
        <path d="M385.843 529.641C385.809 530.255 385.864 530.871 386.003 531.461C386.115 531.705 386.301 531.884 386.52 531.959C386.739 532.034 386.973 531.998 387.171 531.859C387.368 531.72 387.513 531.49 387.573 531.219C387.634 530.949 387.604 530.66 387.492 530.415C387.442 530.214 387.356 530.03 387.24 529.876C387.123 529.722 386.98 529.604 386.821 529.529C386.661 529.454 386.491 529.426 386.321 529.445C386.152 529.465 385.988 529.532 385.843 529.641Z" fill="white" fill-opacity="0.6"/>
        <path d="M398.896 550.759C398.882 551.404 398.956 552.048 399.116 552.662C399.273 552.859 399.465 553.008 399.676 553.096C399.888 553.185 400.113 553.212 400.335 553.175C400.597 553.018 400.732 552.244 400.639 551.711C400.585 551.498 400.495 551.302 400.374 551.136C400.254 550.97 400.107 550.838 399.942 550.748C399.777 550.658 399.598 550.612 399.418 550.614C399.238 550.616 399.06 550.665 398.896 550.759Z" fill="white" fill-opacity="0.6"/>
        <path d="M413.084 575.192C413.632 575.399 414.202 575.509 414.776 575.516C415.36 575.359 415.368 574.198 414.776 573.602C414.387 573.204 413.583 572.797 413.431 572.995C413.16 573.361 413.219 574.177 413.084 575.192Z" fill="white" fill-opacity="0.6"/>
        <path d="M431.324 595.399C430.628 595.107 429.901 594.942 429.167 594.908C428.955 594.908 429.065 595.953 429.277 596.497C429.564 597.24 430.706 597.69 431.036 597.198C431.366 596.707 431.205 596.257 431.324 595.399Z" fill="white" fill-opacity="0.6"/>
        <path d="M447.288 616.276C448.303 617.05 449.208 616.987 449.454 616.412C449.699 615.837 449.454 615.303 448.76 614.969C448.244 614.707 447.237 614.519 447.161 614.707C446.983 615.167 447.237 615.868 447.288 616.276Z" fill="white" fill-opacity="0.6"/>
        <path d="M467.787 629.57C466.991 629.503 466.192 629.534 465.401 629.664C465.164 629.747 465.274 630.48 465.502 630.846C465.78 631.096 466.11 631.243 466.454 631.269C466.797 631.295 467.139 631.199 467.44 630.992C467.694 630.689 467.643 630.25 467.787 629.57Z" fill="white" fill-opacity="0.6"/>
        <path d="M487.634 639.422C486.757 639.489 485.886 639.64 485.028 639.872C484.783 639.977 484.944 640.583 485.214 640.918C485.556 641.091 485.925 641.164 486.293 641.133C486.661 641.103 487.018 640.968 487.338 640.74C487.592 640.395 487.507 640.029 487.634 639.422Z" fill="white" fill-opacity="0.6"/>
        <path d="M509.723 645.75C509.258 645.384 509.139 645.081 508.716 644.976C508.065 644.819 506.661 645.238 506.517 645.635C506.495 645.722 506.491 645.815 506.504 645.904C506.517 645.994 506.547 646.078 506.592 646.151C506.636 646.223 506.694 646.282 506.761 646.321C506.827 646.361 506.9 646.38 506.974 646.378C507.901 646.251 508.82 646.041 509.723 645.75Z" fill="white" fill-opacity="0.6"/>
        <path d="M529.164 646.608C529.283 646.775 529.274 647.11 529.706 647.183C530.357 647.053 530.991 646.817 531.592 646.482C531.982 646.231 532.092 645.844 531.889 645.719C530.931 645.698 529.988 646.005 529.164 646.608Z" fill="white" fill-opacity="0.6"/>
        <path d="M555.627 641.776C555.309 641.663 554.977 641.62 554.646 641.65C553.807 641.884 553.012 642.314 552.303 642.916C552.176 643.146 552.362 643.376 552.726 643.24C553.394 642.989 554.308 642.466 555.627 641.776Z" fill="white" fill-opacity="0.6"/>
        <path d="M571.888 636.253C571.888 636.389 571.659 636.703 571.888 636.692C572.416 636.437 572.926 636.126 573.41 635.762C573.791 635.469 574.079 635.082 574.037 634.988C573.26 635.231 572.53 635.661 571.888 636.253Z" fill="white" fill-opacity="0.6"/>
        <path d="M587.429 631.484C588.262 631.154 589.037 630.632 589.713 629.946C590.051 629.538 589.933 629.402 589.282 629.758C588.697 630.053 588.131 630.403 587.59 630.804C587.327 631.212 587.454 631.327 587.429 631.484Z" fill="white" fill-opacity="0.6"/>
        <path d="M588.918 616.464C589.379 616.422 589.837 616.338 590.288 616.213C590.968 615.983 591.608 615.6 592.175 615.084C592.395 614.874 592.31 614.697 592.04 614.812C591.583 615 590.864 615.45 588.918 616.464Z" fill="white" fill-opacity="0.6"/>
        <path d="M566.025 624.194C566.871 623.723 567.59 623.399 568.055 623.064C568.224 622.949 568.055 622.708 567.725 622.75C567.066 622.822 566.437 623.119 565.906 623.608C565.72 623.817 565.966 623.89 566.025 624.194Z" fill="white" fill-opacity="0.6"/>
        <path d="M540.518 626.338C540.981 626.546 541.456 626.71 541.939 626.829C542.581 626.915 543.226 626.723 543.766 626.286C543.97 626.108 543.843 625.783 543.572 625.783C543.123 625.783 542.413 625.982 540.518 626.338Z" fill="white" fill-opacity="0.6"/>
        <path d="M520.586 623.671C519.486 623.838 518.936 623.848 518.522 623.984C518.107 624.12 518.099 624.779 518.699 624.863C518.996 624.947 519.304 624.954 519.602 624.881C519.901 624.809 520.184 624.661 520.434 624.445C520.611 624.204 520.544 623.89 520.586 623.671Z" fill="white" fill-opacity="0.6"/>
        <path d="M498.581 617.019C497.945 616.972 497.306 616.972 496.669 617.019C496.17 617.134 496.255 617.855 496.805 618.064C497.355 618.274 498.091 618.368 498.412 617.897C498.59 617.615 498.531 617.311 498.581 617.019Z" fill="white" fill-opacity="0.6"/>
        <path d="M476.45 604.854C476.797 605.785 476.805 606.172 477.059 606.413C477.249 606.645 477.499 606.79 477.765 606.822C478.032 606.855 478.299 606.773 478.523 606.591C478.878 606.172 478.523 605.722 477.913 605.409C477.533 605.21 477.135 605.064 476.45 604.854Z" fill="white" fill-opacity="0.6"/>
        <path d="M456.916 587.032C457.059 587.827 457.017 588.235 457.22 588.601C457.355 588.917 457.576 589.164 457.843 589.3C458.111 589.435 458.408 589.451 458.684 589.343C458.87 589.228 458.811 588.538 458.608 588.297C458.078 587.81 457.511 587.386 456.916 587.032Z" fill="white" fill-opacity="0.6"/>
        <path d="M439.116 565.318C439.125 565.896 439.199 566.47 439.336 567.023C439.682 567.839 440.258 568.153 440.647 567.891C440.816 567.776 440.791 567.023 440.647 566.751C440.175 566.214 439.662 565.734 439.116 565.318Z" fill="white" fill-opacity="0.6"/>
        <path d="M424.784 543.04C424.217 542.914 423.811 542.663 423.634 542.82C423.228 543.165 423.456 543.939 423.93 544.504C424.175 544.807 424.776 545.017 424.835 544.86C424.886 544.253 424.869 543.641 424.784 543.04Z" fill="white" fill-opacity="0.6"/>
        <path d="M410.74 520.113C410.207 520.029 409.835 519.799 409.657 519.967C409.268 520.343 409.454 521.096 409.886 521.63C410.114 521.902 410.732 522.079 410.732 521.922C410.805 521.323 410.808 520.713 410.74 520.113Z" fill="white" fill-opacity="0.6"/>
        <path d="M396.215 498.211C396.393 498.922 396.602 499.62 396.841 500.303C397.137 500.972 397.568 501.265 397.89 500.837C397.961 500.656 397.998 500.457 397.998 500.256C397.998 500.055 397.961 499.856 397.89 499.676C397.384 499.101 396.821 498.609 396.215 498.211Z" fill="white" fill-opacity="0.6"/>
        <path d="M384.176 481.382C384.341 481.975 384.536 482.555 384.76 483.118C385.039 483.652 385.453 483.83 385.783 483.38C385.854 483.219 385.89 483.04 385.89 482.857C385.89 482.674 385.854 482.495 385.783 482.334C385.58 482.041 385.191 481.937 384.176 481.382Z" fill="white" fill-opacity="0.6"/>
        <path d="M372.873 470.138C372.873 470.724 372.789 471.08 372.873 471.299C373.085 471.728 373.533 471.686 373.88 471.299C374.032 471.132 374.091 470.567 373.965 470.452C373.618 470.27 373.249 470.164 372.873 470.138Z" fill="white" fill-opacity="0.6"/>
        <path d="M360.851 465.955C360.792 466.363 360.64 466.76 360.758 466.76C361.116 466.683 361.466 466.567 361.807 466.415C361.867 466.185 362.061 465.787 361.951 465.756C361.579 465.756 361.208 465.823 360.851 465.955Z" fill="white" fill-opacity="0.6"/>
        <path d="M605.102 609.436C605.026 609.728 604.865 609.969 604.975 609.979C605.476 609.836 605.939 609.537 606.32 609.111C606.54 608.902 606.684 608.588 606.532 608.641C606.379 608.693 605.728 609.069 605.102 609.436Z" fill="white" fill-opacity="0.6"/>
        <path d="M620.482 603.181C620.482 603.463 620.33 603.714 620.482 603.725C621.01 603.586 621.498 603.276 621.895 602.825C622.107 602.606 622.2 602.281 622.03 602.344C621.501 602.584 620.984 602.864 620.482 603.181Z" fill="white" fill-opacity="0.6"/>
        <path d="M639.407 595.295C638.485 595.922 638.037 596.184 637.665 596.487C637.292 596.79 637.157 597.324 637.665 597.135C638.229 596.918 638.741 596.53 639.154 596.006C639.273 595.79 639.36 595.549 639.407 595.295Z" fill="white" fill-opacity="0.6"/>
        <path d="M655.126 590.295C655.52 590.31 655.913 590.253 656.294 590.128C656.677 589.854 657.042 589.542 657.385 589.197C657.14 589.095 656.879 589.067 656.624 589.113C656.106 589.469 655.606 589.864 655.126 590.295Z" fill="white" fill-opacity="0.6"/>
        <path d="M674.703 582.827C674.762 583.183 674.703 583.591 674.855 583.517C675.375 583.258 675.883 582.965 676.378 582.639C676.378 582.43 676.539 582.011 676.378 582.053C675.806 582.262 675.246 582.521 674.703 582.827Z" fill="white" fill-opacity="0.6"/>
        <path d="M696.318 574.899C695.472 575.506 695.007 575.767 694.677 576.081C694.347 576.395 694.44 576.97 694.956 576.771C695.53 576.623 696.036 576.207 696.369 575.61C696.471 575.317 696.344 575.139 696.318 574.899Z" fill="white" fill-opacity="0.6"/>
        <path d="M713.704 570.119C714.154 570.207 714.61 570.252 715.066 570.255C715.347 570.223 715.62 570.112 715.861 569.931C716.103 569.75 716.309 569.503 716.462 569.209C716.484 569.148 716.492 569.081 716.484 569.015C716.477 568.949 716.455 568.887 716.421 568.836C716.386 568.785 716.341 568.747 716.29 568.726C716.239 568.705 716.184 568.702 716.132 568.717C715.301 569.126 714.49 569.594 713.704 570.119Z" fill="white" fill-opacity="0.6"/>
        <path d="M0.83367 541.419C1.45971 541.91 1.67968 542.214 1.89118 542.224C1.99408 542.243 2.09916 542.233 2.19862 542.196C2.29807 542.158 2.38936 542.093 2.46573 542.005C2.5421 541.918 2.60159 541.81 2.6398 541.691C2.678 541.571 2.69393 541.442 2.68642 541.314C2.68642 540.718 2.25496 540.613 1.84042 540.802C1.49481 540.981 1.15857 541.187 0.83367 541.419Z" fill="white" fill-opacity="0.6"/>
        <path d="M16.2901 537.454C16.5608 537.894 16.7131 538.364 16.8484 538.302C17.1893 538.079 17.5027 537.797 17.7791 537.465C17.6606 537.214 17.5675 536.743 17.4152 536.764C17.0183 536.935 16.6403 537.167 16.2901 537.454Z" fill="white" fill-opacity="0.6"/>
        <path d="M31.366 531.848C31.568 531.319 31.7239 530.765 31.8313 530.196C31.8313 530.039 31.459 529.945 31.2221 530.039C30.9853 530.133 30.4861 531.137 30.6469 531.44C30.8076 531.744 31.0191 531.671 31.366 531.848Z" fill="white" fill-opacity="0.6"/>
        <path d="M43.5485 522.32C44.0053 522.634 44.1322 522.906 44.3268 522.811C44.5434 522.675 44.7252 522.466 44.8521 522.21C44.979 521.953 45.046 521.658 45.0459 521.358C45.0459 520.657 44.7582 520.709 44.386 521.138C44.1491 521.389 43.9207 521.797 43.5485 522.32Z" fill="white" fill-opacity="0.6"/>
        <path d="M56.2977 511.453C56.5176 511.829 56.5853 512.185 56.7461 512.174C57.0676 512.174 57.3975 511.474 57.5159 510.72C57.5752 510.386 57.4144 509.946 57.279 510.051C56.9168 510.479 56.5882 510.948 56.2977 511.453Z" fill="white" fill-opacity="0.6"/>
        <path d="M68.7933 500.533C69.1148 499.613 69.394 498.954 69.5462 498.337C69.6055 498.107 69.4109 497.73 69.2586 497.845C69.0414 498.046 68.8628 498.304 68.7355 498.601C68.6083 498.898 68.5356 499.225 68.5226 499.56C68.5141 499.947 68.658 500.073 68.7933 500.533Z" fill="white" fill-opacity="0.6"/>
        <path d="M79.9266 486.57C80.2311 486.967 80.2904 487.323 80.4596 487.198C80.898 486.693 81.1744 486.009 81.2379 485.273C81.3648 484.353 81.1194 484.415 80.7557 484.98C80.5357 485.346 80.2904 485.827 79.9266 486.57Z" fill="white" fill-opacity="0.6"/>
        <path d="M91.4408 473.831C91.7792 473.695 91.9569 473.831 92.1938 473.475C92.4283 472.904 92.6291 472.314 92.7944 471.707C92.6252 471.644 92.4899 471.383 92.2699 471.592C91.9534 472.315 91.6764 473.062 91.4408 473.831Z" fill="white" fill-opacity="0.6"/>
        <path d="M103.2 459.449C103.285 460.056 103.26 460.589 103.386 460.62C103.513 460.652 104.021 459.784 104.232 458.738C104.342 458.277 104.309 457.587 104.19 457.692C103.823 458.244 103.492 458.831 103.2 459.449Z" fill="white" fill-opacity="0.6"/>
        <path d="M114.655 447.578C114.985 447.578 115.138 447.735 115.391 447.4C115.659 446.767 115.894 446.114 116.094 445.444C115.941 445.298 115.84 444.952 115.611 445.12C115.249 445.912 114.929 446.733 114.655 447.578Z" fill="white" fill-opacity="0.6"/>
        <path d="M126.812 434.681C127.015 435.466 127.007 436.041 127.168 435.968C127.472 435.842 127.912 434.985 128.174 433.719C128.437 432.453 128.217 432.38 127.811 432.945C127.548 433.311 127.252 433.907 126.812 434.681Z" fill="white" fill-opacity="0.6"/>
        <path d="M139.579 423.856C139.579 424.735 139.579 425.435 139.672 425.55C139.925 425.801 140.391 424.881 140.729 423.647C140.881 423.103 140.898 422.172 140.771 422.235C140.32 422.711 139.92 423.256 139.579 423.856Z" fill="white" fill-opacity="0.6"/>
        <path d="M152.632 416.388C152.996 416.576 153.165 416.963 153.478 416.712C153.811 416.048 154.094 415.348 154.324 414.62C154.155 414.307 154.062 413.752 153.8 413.805C153.356 414.626 152.965 415.49 152.632 416.388Z" fill="white" fill-opacity="0.6"/>
        <path d="M168.817 406.441C168.312 407.153 167.849 407.908 167.429 408.7C167.04 409.589 167.023 411.106 167.429 410.907C167.835 410.709 168.377 410.029 168.664 408.544C168.763 407.849 168.814 407.146 168.817 406.441Z" fill="white" fill-opacity="0.6"/>
        <path d="M345.911 472.753C346.935 472.042 347.256 471.833 347.688 471.509C348.198 471.16 348.668 470.727 349.084 470.222C349.236 470.023 349.227 469.856 349.084 469.961C348.94 470.065 347.865 471.027 345.911 472.753Z" fill="white" fill-opacity="0.6"/>
        <path d="M324.913 488.641C323.991 489.436 323.492 489.844 323.136 490.178C322.781 490.513 322.925 490.66 323.5 490.241C324.056 489.873 324.566 489.408 325.015 488.861C325.116 488.683 324.955 488.704 324.913 488.641Z" fill="white" fill-opacity="0.6"/>
        <path d="M304.677 501.987C303.898 502.615 303.484 502.907 303.179 503.2C302.875 503.493 302.942 503.765 303.408 503.472C303.898 503.217 304.339 502.835 304.702 502.353C304.812 502.165 304.694 502.113 304.677 501.987Z" fill="white" fill-opacity="0.6"/>
        <path d="M288.247 510.093C287.587 510.574 287.24 510.783 286.978 511.034C286.716 511.285 286.707 511.725 287.088 511.557C287.529 511.403 287.918 511.076 288.196 510.626C288.306 510.407 288.239 510.271 288.247 510.093Z" fill="white" fill-opacity="0.6"/>
        <path d="M273.865 515.239C274.235 514.937 274.588 514.605 274.923 514.245C275.016 514.13 274.923 513.775 274.813 513.754C274.621 513.745 274.431 513.804 274.266 513.924C274.101 514.045 273.968 514.222 273.882 514.434C273.772 514.674 273.865 514.862 273.865 515.239Z" fill="white" fill-opacity="0.6"/>
        <path d="M263.324 513.66C263.544 513.942 263.637 514.224 263.84 514.235C264.096 514.09 264.313 513.858 264.466 513.565C264.677 513.042 264.567 512.718 264.204 512.865C263.887 513.087 263.592 513.354 263.324 513.66Z" fill="white" fill-opacity="0.6"/>
        <path d="M255.143 508.535C255.193 508.973 255.278 509.404 255.397 509.821C255.549 510.145 255.837 510.02 256.124 509.382C256.251 509.11 256.319 508.482 256.243 508.409C256.167 508.336 255.828 508.43 255.143 508.535Z" fill="white" fill-opacity="0.6"/>
        <path d="M249.948 500.261C249.551 500.502 249.365 500.502 249.179 500.753C248.992 501.004 248.739 502.217 248.908 502.395C249.077 502.573 249.348 502.531 249.635 501.684C249.761 501.217 249.865 500.742 249.948 500.261Z" fill="white" fill-opacity="0.6"/>
        <path d="M243.477 490.837C243.544 491.528 243.527 492.113 243.654 492.27C243.781 492.427 244.094 491.831 244.204 491.465C244.466 490.639 244.483 489.98 244.204 489.98C243.925 489.98 243.866 490.377 243.477 490.837Z" fill="white" fill-opacity="0.6"/>
        <path d="M238.95 477.314C238.832 478.129 238.758 478.954 238.73 479.782C238.815 480.294 239.077 480.169 239.399 479.165C239.72 478.161 239.703 477.554 239.509 477.355C239.314 477.157 239.204 477.324 238.95 477.314Z" fill="white" fill-opacity="0.6"/>
        <path d="M233.832 466.823C234.169 466.224 234.468 465.594 234.729 464.94C234.806 464.453 234.806 463.952 234.729 463.465C234.619 463.016 234.086 463.894 233.942 464.72C233.871 465.418 233.834 466.12 233.832 466.823Z" fill="white" fill-opacity="0.6"/>
        <path d="M228.028 450.841C227.929 451.735 227.881 452.637 227.885 453.539C228.02 454.042 228.35 453.801 228.731 452.588C229.111 451.374 229.027 450.747 228.731 450.632C228.434 450.517 228.35 450.736 228.028 450.841Z" fill="white" fill-opacity="0.6"/>
        <path d="M216.92 429.713C217.076 430.551 217.326 431.358 217.665 432.108C217.834 432.286 218.249 431.418 218.511 431.062C218.446 430.232 218.301 429.414 218.08 428.625C218.003 428.301 217.547 429.086 216.92 429.713Z" fill="white" fill-opacity="0.6"/>
        <path d="M201.802 412.277C202.115 414.118 202.124 415.06 202.352 415.415C202.775 415.959 203.367 415.624 203.706 414.181C204.044 412.738 203.706 412.016 203.173 411.943C202.705 411.967 202.243 412.08 201.802 412.277Z" fill="white" fill-opacity="0.6"/>
        <path d="M182.268 405.238C182.53 406.577 182.742 407.529 183.038 407.686C183.588 407.979 184.281 407.393 184.603 405.918C184.924 404.443 184.493 403.941 183.816 404.151C183.239 404.362 182.71 404.734 182.268 405.238Z" fill="white" fill-opacity="0.6"/>
        <path d="M587.48 611.632C586.951 611.945 586.437 612.294 585.94 612.678C585.771 612.877 585.982 613.034 586.27 612.929C586.765 612.723 587.24 612.446 587.683 612.103C587.894 611.852 587.59 611.81 587.48 611.632Z" fill="white" fill-opacity="0.6"/>
        <path d="M562.759 617.541C562.244 617.753 561.743 618.015 561.262 618.326C561.093 618.514 561.321 618.755 561.617 618.713C562.1 618.599 562.566 618.391 562.996 618.096C563.199 617.908 562.886 617.772 562.759 617.541Z" fill="white" fill-opacity="0.6"/>
        <path d="M536.516 619.382C536.945 619.515 537.386 619.575 537.827 619.56C538.183 619.476 538.775 619.1 538.767 618.954C538.758 618.807 538.191 618.629 537.853 618.682C537.388 618.856 536.94 619.091 536.516 619.382Z" fill="white" fill-opacity="0.6"/>
        <path d="M513.513 614.958C513.877 615.24 514.08 615.565 514.359 615.586C514.745 615.608 515.128 615.487 515.459 615.24C515.535 615.042 515.214 614.676 514.892 614.602C514.423 614.653 513.96 614.773 513.513 614.958Z" fill="white" fill-opacity="0.6"/>
        <path d="M491.974 605.471C492.422 606.015 492.6 606.392 492.938 606.517C493.336 606.615 493.743 606.647 494.148 606.612C494.081 606.319 494.148 605.942 493.911 605.743C493.274 605.576 492.625 605.485 491.974 605.471Z" fill="white" fill-opacity="0.6"/>
        <path d="M474.419 591.529C473.836 591.404 473.277 591.142 473.108 591.278C472.939 591.414 473.108 591.927 473.108 592.272C473.496 592.489 473.911 592.624 474.335 592.669C474.555 592.596 474.411 592.063 474.419 591.529Z" fill="white" fill-opacity="0.6"/>
        <path d="M453.185 570.914C453.362 571.43 453.602 571.909 453.895 572.336C454.174 572.556 454.741 572.619 454.809 572.41C454.795 571.934 454.631 571.483 454.352 571.154C454.124 570.903 453.701 570.998 453.185 570.914Z" fill="white" fill-opacity="0.6"/>
        <path d="M435.934 547.485C436.126 548.138 436.376 548.762 436.679 549.347C436.971 549.622 437.305 549.821 437.66 549.933C437.683 549.488 437.628 549.042 437.5 548.625C437.33 548.311 436.89 548.154 435.934 547.485Z" fill="white" fill-opacity="0.6"/>
        <path d="M421.383 524.527C421.494 525.064 421.668 525.579 421.899 526.054C422.119 526.294 422.618 526.347 422.669 526.127C422.696 525.643 422.585 525.162 422.356 524.767C422.145 524.527 421.815 524.663 421.383 524.527Z" fill="white" fill-opacity="0.6"/>
        <path d="M409.387 503.891C409.304 503.355 409.153 502.84 408.938 502.364C408.832 502.262 408.699 502.213 408.565 502.224C408.431 502.236 408.305 502.308 408.211 502.426C408.177 502.863 408.27 503.301 408.473 503.661C408.609 503.901 408.989 503.807 409.387 503.891Z" fill="white" fill-opacity="0.6"/>
        <path d="M395.58 482.198C395.825 483.244 395.842 483.788 396.02 483.966C396.198 484.143 396.528 484.028 396.79 484.039C396.792 483.623 396.729 483.211 396.604 482.826C396.435 482.533 396.079 482.48 395.58 482.198Z" fill="white" fill-opacity="0.6"/>
        <path d="M384.024 467.764C384.024 468.277 383.888 468.747 384.024 468.904C384.134 468.999 384.262 469.057 384.395 469.071C384.529 469.086 384.663 469.057 384.785 468.988C384.859 468.849 384.906 468.69 384.921 468.525C384.935 468.359 384.918 468.192 384.87 468.036C384.76 467.837 384.387 467.869 384.024 467.764Z" fill="white" fill-opacity="0.6"/>
        <path d="M371.706 459.815C371.943 460.16 372.01 460.453 372.213 460.505C372.416 460.558 372.746 460.338 373.059 460.244C373.059 459.972 373.152 459.606 373.017 459.491C372.882 459.376 372.492 459.585 371.706 459.815Z" fill="white" fill-opacity="0.6"/>
        <path d="M359.819 459.846C359.819 460.077 359.701 460.38 359.819 460.349C360.127 460.211 360.413 460.01 360.665 459.752C360.818 459.564 360.852 459.261 360.733 459.25C360.412 459.407 360.105 459.607 359.819 459.846Z" fill="white" fill-opacity="0.6"/>
        <path d="M607.183 603.442C606.684 603.85 606.21 604.195 606.024 604.405C605.838 604.614 605.796 604.833 605.965 604.76C606.321 604.546 606.659 604.287 606.972 603.986C607.175 603.777 607.107 603.662 607.183 603.442Z" fill="white" fill-opacity="0.6"/>
        <path d="M620.161 598.694C620.719 598.223 621.252 597.826 621.388 597.648C621.523 597.47 621.388 597.407 621.32 597.303C620.965 597.596 620.474 597.941 620.271 598.171C620.068 598.401 620.195 598.474 620.161 598.694Z" fill="white" fill-opacity="0.6"/>
        <path d="M635.423 591.843C635.744 591.843 635.829 592 636.117 591.843C636.501 591.602 636.874 591.333 637.233 591.038C637.233 590.881 637.369 590.577 637.182 590.619C636.579 590.99 635.992 591.398 635.423 591.843Z" fill="white" fill-opacity="0.6"/>
        <path d="M654.483 583.455C654.058 583.773 653.648 584.122 653.257 584.501C653.054 584.752 653.155 584.898 653.121 585.097C653.513 584.863 653.89 584.59 654.246 584.281C654.466 584.009 654.399 583.81 654.483 583.455Z" fill="white" fill-opacity="0.6"/>
        <path d="M672.317 576.551C671.908 576.86 671.52 577.21 671.158 577.597C671.023 577.817 671.158 578.058 671.395 577.974C671.786 577.763 672.146 577.473 672.461 577.116C672.622 576.897 672.393 576.823 672.317 576.551Z" fill="white" fill-opacity="0.6"/>
        <path d="M689.195 571.05C689.643 571.05 689.838 571.228 690.151 571.05C690.539 570.81 690.91 570.53 691.259 570.213C691.183 570.046 691.259 569.722 690.98 569.763C690.361 570.139 689.764 570.569 689.195 571.05Z" fill="white" fill-opacity="0.6"/>
        <path d="M709.059 563.781C709.194 564.053 709.186 564.419 709.406 564.356C709.795 564.195 710.15 563.931 710.447 563.582C710.482 563.536 710.506 563.478 710.516 563.416C710.526 563.354 710.521 563.289 710.503 563.23C710.484 563.171 710.452 563.119 710.411 563.081C710.369 563.044 710.32 563.021 710.269 563.017C709.845 563.218 709.439 563.474 709.059 563.781Z" fill="white" fill-opacity="0.6"/>
        <path d="M8.73538 530.802C9.20914 530.938 9.47138 531.148 9.71672 531.043C9.96206 530.938 10.1482 530.551 10.3682 530.279C10.199 530.07 10.0551 529.704 9.84364 529.704C9.63214 529.704 9.40372 530.112 8.73538 530.802Z" fill="white" fill-opacity="0.6"/>
        <path d="M23.9635 524.527C24.1919 524.83 24.3103 525.228 24.4795 525.165C24.7612 524.979 24.9801 524.675 25.0971 524.307C25.1062 524.168 25.0745 524.028 25.0077 523.915C24.941 523.802 24.8436 523.722 24.7333 523.69C24.5726 523.753 24.2934 524.213 23.9635 524.527Z" fill="white" fill-opacity="0.6"/>
        <path d="M37.9816 516.034C38.1762 516.316 38.2778 516.735 38.4385 516.64C38.7169 516.374 38.9234 516.009 39.0308 515.595C39.09 515.323 38.8869 514.957 38.7177 514.988C38.5485 515.019 38.2947 515.584 37.9816 516.034Z" fill="white" fill-opacity="0.6"/>
        <path d="M52.0591 503.713C51.8146 504.162 51.6103 504.642 51.45 505.146C51.4077 505.48 51.5938 505.648 51.6784 505.888C51.9189 505.518 52.1233 505.115 52.2876 504.686C52.3552 504.372 52.1776 504.142 52.0591 503.713Z" fill="white" fill-opacity="0.6"/>
        <path d="M63.9962 493.149C64.2416 493.149 64.4192 493.274 64.58 493.023C64.801 492.608 64.9534 492.142 65.0284 491.653C65.0303 491.59 65.0156 491.527 64.9864 491.475C64.9572 491.423 64.9152 491.384 64.8666 491.364C64.8179 491.344 64.7654 491.344 64.7168 491.364C64.6681 491.384 64.6261 491.423 64.5969 491.475C64.3544 492.009 64.1532 492.569 63.9962 493.149Z" fill="white" fill-opacity="0.6"/>
        <path d="M77.1264 477.136C76.8917 477.71 76.6909 478.304 76.5257 478.914C76.4834 479.269 76.6611 479.573 76.8049 479.384C77.0674 478.946 77.2651 478.454 77.3886 477.931C77.4563 477.554 77.2533 477.46 77.1264 477.136Z" fill="white" fill-opacity="0.6"/>
        <path d="M88.4799 464.856C88.6998 464.856 88.8352 465.055 88.996 464.773C89.2355 464.272 89.4123 463.729 89.5205 463.162C89.5205 462.817 89.3343 462.67 89.1651 462.911C88.9017 463.54 88.6728 464.19 88.4799 464.856Z" fill="white" fill-opacity="0.6"/>
        <path d="M100.501 450.475C100.798 450.193 101.001 450.193 101.17 449.806C101.385 449.263 101.536 448.686 101.618 448.09C101.618 447.829 101.314 447.944 101.144 448.289C100.894 449.001 100.679 449.731 100.501 450.475Z" fill="white" fill-opacity="0.6"/>
        <path d="M112.997 436.25C113.29 435.529 113.544 434.785 113.758 434.022C113.826 433.573 113.632 433.531 113.547 433.311C113.295 433.908 113.072 434.523 112.879 435.152C112.786 435.623 112.938 435.801 112.997 436.25Z" fill="white" fill-opacity="0.6"/>
        <path d="M124.807 422.099C125.078 422.214 125.179 422.538 125.391 422.277C125.688 421.734 125.951 421.164 126.178 420.572C126.178 420.206 126.178 419.526 126.051 419.526C125.585 420.344 125.169 421.204 124.807 422.099Z" fill="white" fill-opacity="0.6"/>
        <path d="M139.037 406.609C138.702 407.308 138.405 408.035 138.149 408.784C138.08 409.212 138.08 409.653 138.149 410.081C138.468 409.517 138.751 408.922 138.995 408.303C139.08 407.744 139.095 407.173 139.037 406.609Z" fill="white" fill-opacity="0.6"/>
        <path d="M151.423 397.969C151.423 398.68 151.338 399.58 151.49 399.517C151.862 399.09 152.151 398.564 152.336 397.98C152.489 397.478 152.506 396.589 152.336 396.526C152.167 396.463 151.846 397.352 151.423 397.969Z" fill="white" fill-opacity="0.6"/>
        <path d="M164.959 390.648C165.263 391.035 165.365 391.547 165.61 391.369C165.969 390.876 166.284 390.336 166.549 389.759C166.549 389.257 166.609 388.388 166.439 388.347C166.27 388.305 165.889 389.236 164.959 390.648Z" fill="white" fill-opacity="0.6"/>
        <path d="M180.483 384.247C180.483 385.094 180.398 386.098 180.568 386.098C180.737 386.098 181.321 385.324 181.515 384.738C181.677 384.191 181.677 383.591 181.515 383.044C181.405 382.908 180.94 383.692 180.483 384.247Z" fill="white" fill-opacity="0.6"/>
        <path d="M341.867 473.025C341.486 473.234 341.116 473.472 340.759 473.736C340.393 474.046 340.05 474.396 339.735 474.782C339.676 474.908 340.141 474.636 340.48 474.364C340.818 474.092 341.266 473.621 341.867 473.025Z" fill="white" fill-opacity="0.6"/>
        <path d="M317.181 490.89C317.324 490.952 317.316 491.13 317.544 490.994C317.932 490.733 318.298 490.425 318.636 490.074C318.805 489.886 318.678 489.781 318.45 489.896C318.221 490.011 317.731 490.461 317.181 490.89Z" fill="white" fill-opacity="0.6"/>
        <path d="M298.399 501.464C298.685 501.486 298.972 501.443 299.245 501.338C299.58 501.128 299.9 500.883 300.201 500.606C300.125 500.512 300.151 500.303 299.948 500.366C299.415 500.695 298.898 501.062 298.399 501.464Z" fill="white" fill-opacity="0.6"/>
        <path d="M285.515 505.616C285.303 505.512 285.202 505.313 285.007 505.386C284.743 505.542 284.513 505.775 284.339 506.066C284.271 506.223 284.466 506.401 284.652 506.349C284.958 506.141 285.247 505.895 285.515 505.616Z" fill="white" fill-opacity="0.6"/>
        <path d="M273.62 505.355C273.345 505.5 273.091 505.698 272.867 505.941C272.74 506.16 272.791 506.453 272.757 506.725C273.003 506.637 273.236 506.495 273.442 506.307C273.577 506.118 273.552 505.784 273.62 505.355Z" fill="white" fill-opacity="0.6"/>
        <path d="M264.466 502.269C264.381 501.903 264.373 501.474 264.246 501.464C264.119 501.454 263.789 501.851 263.696 502.113C263.603 502.374 263.696 502.803 263.798 502.845C263.899 502.886 264.178 502.562 264.466 502.269Z" fill="white" fill-opacity="0.6"/>
        <path d="M256.53 493.86C256.53 494.76 256.53 495.293 256.615 495.356C256.7 495.419 256.962 495.053 257.148 494.875C257.191 494.528 257.191 494.175 257.148 493.829C257.08 493.63 256.835 493.808 256.53 493.86Z" fill="white" fill-opacity="0.6"/>
        <path d="M251.074 483.171C250.961 483.688 250.888 484.216 250.854 484.75C250.921 484.949 251.184 484.844 251.319 484.562C251.466 484.142 251.543 483.691 251.547 483.233C251.531 482.993 251.251 483.181 251.074 483.171Z" fill="white" fill-opacity="0.6"/>
        <path d="M246.175 469.856C246.093 470.548 246.067 471.249 246.099 471.948C246.184 472.063 246.429 471.613 246.607 471.404C246.687 470.919 246.727 470.424 246.725 469.929C246.691 469.647 246.471 469.845 246.175 469.856Z" fill="white" fill-opacity="0.6"/>
        <path d="M242.318 456.468C242.369 455.923 242.369 455.372 242.318 454.826C242.233 454.732 241.912 455.286 241.785 455.736C241.674 456.201 241.645 456.689 241.7 457.169C241.793 457.315 242.055 456.803 242.318 456.468Z" fill="white" fill-opacity="0.6"/>
        <path d="M236.599 440.758C236.599 441.417 236.599 442.212 236.666 442.254C236.734 442.296 237.14 441.679 237.233 441.292C237.352 440.782 237.375 440.245 237.301 439.723C237.225 439.587 236.92 440.256 236.599 440.758Z" fill="white" fill-opacity="0.6"/>
        <path d="M228.908 420.53C228.926 421.63 229.025 422.726 229.204 423.804C229.382 424.065 229.72 423.532 229.983 423.354C230.025 422.582 229.997 421.807 229.898 421.042C229.754 420.582 229.399 420.729 228.908 420.53Z" fill="white" fill-opacity="0.6"/>
        <path d="M217.276 398.053C216.827 398.555 216.37 398.775 216.235 399.318C216.1 400.015 216.144 400.746 216.362 401.41C216.574 401.63 217.132 401.232 217.293 400.72C217.379 399.834 217.373 398.937 217.276 398.053Z" fill="white" fill-opacity="0.6"/>
        <path d="M200.288 384.372C199.738 384.968 199.205 385.261 199.044 385.805C198.954 386.498 198.954 387.204 199.044 387.897C199.442 387.604 199.975 387.489 200.203 386.955C200.331 386.104 200.36 385.234 200.288 384.372Z" fill="white" fill-opacity="0.6"/>
        <path d="M605.102 600.085C605.322 600.158 605.339 600.336 605.635 600.21C606.078 599.961 606.511 599.682 606.929 599.374C606.87 599.28 606.929 599.039 606.701 599.133C606.472 599.227 605.838 599.646 605.102 600.085Z" fill="white" fill-opacity="0.6"/>
        <path d="M580.094 608.651C580.331 608.808 580.365 609.028 580.661 608.965C581.1 608.79 581.53 608.581 581.947 608.337C581.879 608.201 581.947 607.95 581.684 607.982C581.145 608.169 580.614 608.392 580.094 608.651Z" fill="white" fill-opacity="0.6"/>
        <path d="M555.179 612.228C555.424 612.458 555.458 612.709 555.754 612.709C556.182 612.626 556.601 612.489 557.006 612.301C556.93 612.134 557.006 611.852 556.736 611.831C556.21 611.915 555.689 612.048 555.179 612.228Z" fill="white" fill-opacity="0.6"/>
        <path d="M531.787 611.695C532.185 611.566 532.567 611.369 532.921 611.109C532.988 610.952 532.625 610.67 532.345 610.649C531.978 610.658 531.619 610.788 531.305 611.025C531.203 611.193 531.55 611.402 531.787 611.695Z" fill="white" fill-opacity="0.6"/>
        <path d="M509.833 604.248C509.326 604.415 508.793 604.488 508.75 604.645C508.708 604.802 508.987 605.22 509.258 605.315C509.595 605.365 509.936 605.281 510.231 605.074C510.349 604.938 510.036 604.624 509.833 604.248Z" fill="white" fill-opacity="0.6"/>
        <path d="M488.226 592.983C488.404 593.464 488.421 593.841 488.649 594.029C488.97 594.165 489.308 594.228 489.647 594.217C489.597 593.935 489.647 593.579 489.461 593.391C489.066 593.188 488.651 593.051 488.226 592.983Z" fill="white" fill-opacity="0.6"/>
        <path d="M467.711 574.972C467.914 575.579 467.947 576.018 468.184 576.311C468.494 576.539 468.835 576.696 469.191 576.771C469.123 576.416 469.191 575.997 468.954 575.725C468.569 575.407 468.15 575.153 467.711 574.972Z" fill="white" fill-opacity="0.6"/>
        <path d="M449.217 553.06C449.379 553.614 449.601 554.138 449.877 554.618C450.097 554.848 450.427 554.765 450.723 554.817C450.632 554.387 450.486 553.977 450.291 553.604C450.063 553.332 449.691 553.3 449.217 553.06Z" fill="white" fill-opacity="0.6"/>
        <path d="M433.202 529.38C433.33 529.941 433.518 530.479 433.76 530.98C433.946 531.231 434.259 531.137 434.513 531.189C434.443 530.753 434.323 530.33 434.158 529.934C433.989 529.652 433.642 529.621 433.202 529.38Z" fill="white" fill-opacity="0.6"/>
        <path d="M420.038 506.15C419.683 506.15 419.319 506.035 419.277 506.244C419.257 506.681 419.352 507.114 419.547 507.478C419.7 507.708 420.114 507.761 420.216 507.572C420.317 507.384 420.131 506.809 420.038 506.15Z" fill="white" fill-opacity="0.6"/>
        <path d="M406.257 484.499C406.338 484.999 406.478 485.481 406.671 485.932C406.84 486.131 407.12 485.932 407.348 485.932C407.311 485.537 407.223 485.152 407.086 484.792C406.917 484.604 406.646 484.635 406.257 484.499Z" fill="white" fill-opacity="0.6"/>
        <path d="M394.37 466.854C394.438 467.398 394.37 467.848 394.539 468.057C394.709 468.266 394.979 468.193 395.208 468.245C395.208 467.911 395.292 467.482 395.208 467.262C395.123 467.042 394.742 467.001 394.37 466.854Z" fill="white" fill-opacity="0.6"/>
        <path d="M383.457 454.899C383.118 455.14 382.78 455.255 382.729 455.433C382.704 455.566 382.71 455.705 382.746 455.834C382.782 455.964 382.846 456.078 382.932 456.165C383.052 456.172 383.171 456.146 383.281 456.088C383.391 456.03 383.489 455.942 383.567 455.83C383.66 455.663 383.516 455.328 383.457 454.899Z" fill="white" fill-opacity="0.6"/>
        <path d="M370.699 451.071C370.767 451.333 370.699 451.615 370.877 451.636C371.054 451.657 371.333 451.395 371.57 451.259C371.57 451.061 371.663 450.736 371.57 450.705C371.266 450.769 370.972 450.892 370.699 451.071Z" fill="white" fill-opacity="0.6"/>
        <path d="M620.956 592.251C620.482 592.701 620.034 593.077 619.941 593.224C619.848 593.37 619.941 593.516 620.102 593.422C620.405 593.203 620.689 592.943 620.948 592.648C621.117 592.512 620.956 592.429 620.956 592.251Z" fill="white" fill-opacity="0.6"/>
        <path d="M634.45 586.645C634.653 586.707 634.687 586.864 634.907 586.739C635.229 586.497 635.537 586.228 635.829 585.933C635.744 585.86 635.761 585.672 635.558 585.766C635.177 586.035 634.807 586.329 634.45 586.645Z" fill="white" fill-opacity="0.6"/>
        <path d="M651.691 578.56C651.335 578.876 650.998 579.226 650.685 579.606C650.6 579.794 650.761 579.909 650.972 579.804C651.284 579.583 651.569 579.309 651.818 578.988C651.979 578.779 651.776 578.727 651.691 578.56Z" fill="white" fill-opacity="0.6"/>
        <path d="M668.662 571.447C668.315 571.767 667.987 572.116 667.681 572.493C667.613 572.692 667.816 572.807 668.045 572.702C668.362 572.488 668.649 572.209 668.891 571.876C669.018 571.667 668.789 571.594 668.662 571.447Z" fill="white" fill-opacity="0.6"/>
        <path d="M685.32 565.318C685.481 565.506 685.498 565.757 685.718 565.695C686.048 565.49 686.362 565.249 686.657 564.973C686.606 564.816 686.657 564.502 686.479 564.544C686.074 564.757 685.686 565.016 685.32 565.318Z" fill="white" fill-opacity="0.6"/>
        <path d="M703.645 558.3C703.848 558.499 703.89 558.76 704.118 558.687C704.443 558.492 704.749 558.253 705.032 557.976C704.964 557.808 705.032 557.495 704.795 557.526C704.394 557.742 704.009 558.001 703.645 558.3Z" fill="white" fill-opacity="0.6"/>
        <path d="M1.21441 523.063C1.59512 523.251 1.84046 523.502 2.06042 523.439C2.28038 523.376 2.3819 522.958 2.53418 522.696C2.33114 522.55 2.13654 522.278 1.9335 522.32C1.73046 522.362 1.52743 522.707 1.21441 523.063Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.2715 516.954C17.1013 517.414 16.9818 517.899 16.9162 518.398C16.9336 518.528 16.9868 518.647 17.067 518.735C17.1471 518.822 17.2492 518.873 17.3561 518.879C17.5966 518.711 17.7822 518.445 17.8806 518.126C17.9229 517.906 17.686 517.707 17.2715 516.954Z" fill="white" fill-opacity="0.6"/>
        <path d="M31.3574 510.679C31.6197 510.867 31.7635 511.139 31.9412 511.045C32.1188 510.951 32.3134 510.48 32.4995 510.177C32.3726 510.009 32.2795 509.675 32.1103 509.727C31.9411 509.779 31.7043 510.239 31.3574 510.679Z" fill="white" fill-opacity="0.6"/>
        <path d="M46.0187 500.627C46.1894 500.198 46.3173 499.745 46.3994 499.278C46.3994 499.069 46.0864 499.006 45.9341 499.184C45.731 499.487 45.5889 499.845 45.5196 500.23C45.528 500.47 45.7903 500.47 46.0187 500.627Z" fill="white" fill-opacity="0.6"/>
        <path d="M58.7258 487.742C59.0219 487.742 59.1911 487.877 59.3603 487.647C59.546 487.252 59.6991 486.835 59.8171 486.403C59.6733 486.403 59.5295 486.162 59.3688 486.34C59.1188 486.781 58.9035 487.25 58.7258 487.742Z" fill="white" fill-opacity="0.6"/>
        <path d="M71.7797 473.098C71.9743 473.266 72.0504 473.579 72.2112 473.423C72.4174 473.046 72.5958 472.647 72.7441 472.23C72.6595 472.052 72.6172 471.645 72.4819 471.749C72.2155 472.172 71.9805 472.624 71.7797 473.098Z" fill="white" fill-opacity="0.6"/>
        <path d="M85.1972 455.966C84.984 456.533 84.8086 457.121 84.6726 457.723C84.6726 458.006 84.8757 458.1 85.0365 457.88C85.255 457.435 85.4176 456.952 85.5187 456.447C85.561 456.144 85.3495 456.165 85.1972 455.966Z" fill="white" fill-opacity="0.6"/>
        <path d="M97.5829 442.327C97.7998 441.715 97.9835 441.086 98.1328 440.444C98.1328 440.152 97.9382 440.057 97.7944 440.298C97.5603 440.76 97.3862 441.265 97.2783 441.794C97.236 442.128 97.4391 442.107 97.5829 442.327Z" fill="white" fill-opacity="0.6"/>
        <path d="M109.774 425.697C109.909 425.979 109.935 426.418 110.087 426.262C110.327 425.804 110.539 425.325 110.721 424.829C110.721 424.567 110.721 424.023 110.569 424.117C110.27 424.617 110.004 425.145 109.774 425.697Z" fill="white" fill-opacity="0.6"/>
        <path d="M123.327 408.428C123.068 409.107 122.844 409.806 122.658 410.52C122.658 410.897 122.785 411.127 122.946 410.907C123.205 410.388 123.407 409.829 123.547 409.244C123.614 408.836 123.437 408.753 123.327 408.428Z" fill="white" fill-opacity="0.6"/>
        <path d="M135.915 397.237C136.188 396.539 136.423 395.819 136.618 395.082C136.677 394.706 136.491 394.444 136.33 394.674C136.055 395.185 135.841 395.741 135.695 396.327C135.619 396.766 135.805 396.86 135.915 397.237Z" fill="white" fill-opacity="0.6"/>
        <path d="M149.375 384.895C149.662 384.182 149.913 383.449 150.128 382.699C150.187 382.291 150.01 381.956 149.841 382.165C149.542 382.673 149.313 383.239 149.164 383.839C149.079 384.299 149.265 384.445 149.375 384.895Z" fill="white" fill-opacity="0.6"/>
        <path d="M162.979 373.683C163.199 374.017 163.267 374.53 163.461 374.331C163.762 373.8 164.017 373.232 164.223 372.637C164.13 372.375 164.104 371.81 163.918 371.925C163.557 372.47 163.242 373.059 162.979 373.683Z" fill="white" fill-opacity="0.6"/>
        <path d="M178.783 363.997C178.455 364.665 178.172 365.365 177.937 366.089C177.877 366.581 178.047 367.135 178.241 366.978C178.582 366.501 178.838 365.942 178.994 365.336C179.087 364.928 178.893 364.604 178.783 363.997Z" fill="white" fill-opacity="0.6"/>
        <path d="M357.002 457.451C357.417 457.202 357.818 456.919 358.203 456.604C358.5 456.318 358.782 456.011 359.049 455.684C358.965 455.684 358.889 455.621 358.542 455.924C358.195 456.228 357.696 456.74 357.002 457.451Z" fill="white" fill-opacity="0.6"/>
        <path d="M331.986 477.167C332.273 477.094 332.366 477.167 332.646 476.968C332.925 476.77 333.356 476.33 333.729 475.996C333.619 475.996 333.602 475.839 333.356 475.996C333.111 476.153 332.612 476.634 331.986 477.167Z" fill="white" fill-opacity="0.6"/>
        <path d="M311.453 490.294C311.665 490.294 311.724 490.471 311.944 490.356C312.258 490.139 312.56 489.898 312.849 489.635C312.765 489.572 312.781 489.384 312.578 489.467C312.375 489.551 311.969 489.917 311.453 490.294Z" fill="white" fill-opacity="0.6"/>
        <path d="M295.379 497.761C295.638 497.536 295.876 497.276 296.09 496.988C296.09 496.852 295.904 496.747 295.726 496.82C295.474 496.959 295.249 497.162 295.066 497.416C295.007 497.573 295.244 497.625 295.379 497.761Z" fill="white" fill-opacity="0.6"/>
        <path d="M282.156 499.686C282.444 499.372 282.74 499.142 282.79 498.964C282.798 498.918 282.797 498.87 282.788 498.823C282.778 498.777 282.761 498.734 282.737 498.697C282.713 498.66 282.682 498.63 282.648 498.61C282.613 498.589 282.575 498.578 282.537 498.577C282.31 498.667 282.112 498.843 281.97 499.079C281.902 499.268 282.063 499.425 282.156 499.686Z" fill="white" fill-opacity="0.6"/>
        <path d="M271.733 495.67C271.733 496.077 271.733 496.454 271.835 496.538C271.936 496.621 272.182 496.391 272.368 496.297C272.368 496.036 272.444 495.649 272.368 495.544C272.292 495.44 272.021 495.607 271.733 495.67Z" fill="white" fill-opacity="0.6"/>
        <path d="M263.815 488.557C263.874 489.038 263.815 489.488 263.958 489.603C264.102 489.718 264.271 489.331 264.432 489.174C264.432 488.882 264.432 488.442 264.364 488.338C264.297 488.233 264.068 488.411 263.815 488.557Z" fill="white" fill-opacity="0.6"/>
        <path d="M257.825 477.585C257.759 478.034 257.733 478.49 257.749 478.945C257.808 479.112 258.019 478.882 258.163 478.83C258.235 478.456 258.275 478.074 258.282 477.69C258.239 477.46 258.019 477.617 257.825 477.585Z" fill="white" fill-opacity="0.6"/>
        <path d="M252.842 464.302C252.796 464.785 252.796 465.273 252.842 465.756C252.91 465.871 253.121 465.463 253.273 465.296C253.322 464.894 253.336 464.488 253.316 464.083C253.256 463.863 253.062 464.177 252.842 464.302Z" fill="white" fill-opacity="0.6"/>
        <path d="M248.434 450.726C248.658 450.417 248.839 450.063 248.967 449.68C249.06 449.265 249.083 448.83 249.035 448.404C248.967 448.278 248.705 448.603 248.612 448.948C248.516 449.534 248.457 450.128 248.434 450.726Z" fill="white" fill-opacity="0.6"/>
        <path d="M244.204 431.889C244.159 432.456 244.159 433.027 244.204 433.594C244.28 433.73 244.517 433.217 244.678 432.987C244.735 432.516 244.755 432.04 244.737 431.564C244.669 431.366 244.483 431.711 244.204 431.889Z" fill="white" fill-opacity="0.6"/>
        <path d="M239.754 414.171C239.431 414.791 239.148 415.441 238.908 416.116C238.782 416.645 238.727 417.197 238.747 417.748C238.747 417.967 239.094 417.748 239.23 417.34C239.449 416.296 239.625 415.238 239.754 414.171Z" fill="white" fill-opacity="0.6"/>
        <path d="M228.409 392.604C228.807 392.311 229.154 392.332 229.255 391.903C229.383 391.264 229.383 390.596 229.255 389.957C229.209 389.884 229.149 389.827 229.079 389.792C229.01 389.757 228.935 389.744 228.861 389.756C228.786 389.768 228.716 389.803 228.656 389.858C228.596 389.914 228.549 389.988 228.519 390.072C228.41 390.909 228.374 391.757 228.409 392.604Z" fill="white" fill-opacity="0.6"/>
        <path d="M213.266 370.357C213.41 371.35 213.41 372.26 213.613 372.448C213.816 372.637 214.213 372.156 214.526 371.967C214.526 371.319 214.526 370.377 214.391 370.106C214.256 369.834 213.79 370.221 213.266 370.357Z" fill="white" fill-opacity="0.6"/>
        <path d="M193.427 361.696C193.672 362.491 193.723 363.307 193.994 363.391C194.264 363.474 194.713 362.795 195.085 362.46C195 361.905 195.034 361.016 194.797 360.88C194.56 360.744 194.061 361.278 193.427 361.696Z" fill="white" fill-opacity="0.6"/>
        <path d="M223.773 332.023C223.426 332.023 223.037 331.94 223.02 332.023C222.97 332.631 222.951 333.243 222.961 333.854C223.164 333.854 223.486 333.979 223.545 333.791C223.658 333.21 223.734 332.619 223.773 332.023Z" fill="white" fill-opacity="0.6"/>
        <path d="M237.774 354.741C237.876 355.368 237.986 356.006 238.087 356.634C238.231 356.425 238.502 356.257 238.502 355.985C238.471 355.353 238.403 354.724 238.299 354.103L237.774 354.741Z" fill="white" fill-opacity="0.6"/>
        <path d="M247.757 384.55C247.782 384.903 247.83 385.253 247.901 385.596C248.037 385.379 248.135 385.13 248.189 384.864C248.188 384.444 248.166 384.025 248.121 383.609L247.757 384.55Z" fill="white" fill-opacity="0.6"/>
        <path d="M252.732 402.383C252.639 403 252.588 403.429 252.529 403.774C252.647 403.774 252.749 403.889 252.867 403.774C252.944 403.431 253 403.081 253.036 402.728L252.732 402.383Z" fill="white" fill-opacity="0.6"/>
        <path d="M256.573 421.806C256.488 422.381 256.437 422.747 256.378 423.103C256.488 423.103 256.581 423.249 256.691 423.103C256.761 422.776 256.815 422.444 256.852 422.109L256.573 421.806Z" fill="white" fill-opacity="0.6"/>
        <path d="M260.27 441.783C260.406 441.551 260.522 441.302 260.617 441.041C260.634 440.78 260.634 440.517 260.617 440.256C260.474 440.384 260.357 440.553 260.278 440.748C260.278 440.748 260.27 441.313 260.27 441.783Z" fill="white" fill-opacity="0.6"/>
        <path d="M264.88 456.322L264.728 457.368C264.83 457.451 264.923 457.608 265.033 457.566C265.103 457.331 265.146 457.085 265.16 456.834C265.092 456.644 264.997 456.471 264.88 456.322Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.997 469.804C270.845 469.804 270.684 469.647 270.676 469.699C270.617 470.02 270.572 470.345 270.54 470.672C270.633 470.672 270.769 470.808 270.803 470.672C270.883 470.388 270.948 470.098 270.997 469.804Z" fill="white" fill-opacity="0.6"/>
        <path d="M278.121 479.635C278.205 479.817 278.314 479.98 278.442 480.117C278.552 480.01 278.636 479.869 278.687 479.709C278.62 479.51 278.534 479.32 278.434 479.144L278.121 479.635Z" fill="white" fill-opacity="0.6"/>
        <path d="M288.34 484.217C288.34 484.478 288.34 484.635 288.34 484.802C288.533 484.733 288.717 484.634 288.89 484.509C288.949 484.447 288.89 484.217 288.89 484.06L288.34 484.217Z" fill="white" fill-opacity="0.6"/>
        <path d="M302.308 483.328C302.097 483.428 301.891 483.544 301.69 483.673C301.665 483.842 301.665 484.016 301.69 484.185C301.86 484.116 302.022 484.021 302.173 483.903C302.25 483.725 302.296 483.529 302.308 483.328Z" fill="white" fill-opacity="0.6"/>
        <path d="M318.517 477.01C318.517 477.157 318.568 477.261 318.585 477.355C318.864 477.146 319.177 476.947 319.431 476.717C319.516 476.644 319.49 476.393 319.431 476.435C319.135 476.581 318.83 476.801 318.517 477.01Z" fill="white" fill-opacity="0.6"/>
        <path d="M339.769 463.225C339.82 463.34 339.854 463.413 339.879 463.486C340.226 463.183 340.606 462.859 340.903 462.555C341.004 462.451 340.962 462.252 340.903 462.315C340.53 462.566 340.158 462.901 339.769 463.225Z" fill="white" fill-opacity="0.6"/>
        <path d="M365.801 441.898L366.867 441.041C366.995 440.861 367.111 440.669 367.214 440.465C366.939 440.583 366.681 440.757 366.452 440.978C366.266 441.25 366.088 441.511 365.801 441.898Z" fill="white" fill-opacity="0.6"/>
        <path d="M203.833 321.522L202.86 322.171C202.953 322.694 203.038 323.217 203.122 323.739C203.376 323.551 203.79 323.373 203.833 323.164C203.879 322.618 203.879 322.068 203.833 321.522Z" fill="white" fill-opacity="0.6"/>
        <path d="M186.794 326.971L187.226 327.588C187.407 327.203 187.558 326.797 187.674 326.375C187.674 325.988 187.471 325.821 187.353 325.57C187.2 325.957 187.04 326.344 186.794 326.971Z" fill="white" fill-opacity="0.6"/>
        <path d="M171.727 335.213L172.141 335.673C172.312 335.277 172.456 334.864 172.573 334.439C172.573 334.084 172.378 333.979 172.268 333.77L171.727 335.213Z" fill="white" fill-opacity="0.6"/>
        <path d="M157.505 347.105C157.7 346.478 157.92 345.882 158.047 345.338C158.047 345.181 157.81 345.066 157.776 345.139C157.553 345.675 157.355 346.227 157.184 346.792C157.15 346.896 157.345 346.97 157.505 347.105Z" fill="white" fill-opacity="0.6"/>
        <path d="M143.741 358.935C143.453 359.5 143.284 359.845 143.106 360.18L143.208 361.142C143.386 360.832 143.542 360.503 143.673 360.159C143.733 359.755 143.755 359.345 143.741 358.935Z" fill="white" fill-opacity="0.6"/>
        <path d="M129.206 376.287C129.206 376.716 129.206 376.988 129.206 377.26C129.441 376.871 129.653 376.462 129.841 376.036C129.909 375.879 129.934 375.189 129.841 375.252C129.612 375.58 129.4 375.926 129.206 376.287Z" fill="white" fill-opacity="0.6"/>
        <path d="M116.178 392.509C115.916 393.032 115.62 393.555 115.611 393.649C115.591 393.998 115.591 394.347 115.611 394.695C115.779 394.409 115.926 394.104 116.051 393.785C116.12 393.365 116.162 392.938 116.178 392.509Z" fill="white" fill-opacity="0.6"/>
        <path d="M101.847 412.612L102.304 412.539C102.42 412.197 102.521 411.848 102.608 411.493C102.549 411.294 102.371 411.378 102.244 411.347L101.847 412.612Z" fill="white" fill-opacity="0.6"/>
        <path d="M88.2936 430.77L88.7589 430.654C88.879 430.318 88.9725 429.967 89.0381 429.609C88.9789 429.441 88.7927 429.525 88.6574 429.514L88.2936 430.77Z" fill="white" fill-opacity="0.6"/>
        <path d="M74.6391 448.352L75.1383 448.226C75.2486 447.92 75.3336 447.601 75.3921 447.274C75.3159 447.118 75.1214 447.201 74.9776 447.201L74.6391 448.352Z" fill="white" fill-opacity="0.6"/>
        <path d="M60.8323 464.218L60.9846 464.846C61.1871 464.511 61.3736 464.162 61.543 463.8C61.5937 463.675 61.543 463.235 61.4583 463.298C61.2349 463.589 61.0258 463.896 60.8323 464.218Z" fill="white" fill-opacity="0.6"/>
        <path d="M46.6787 478.767L46.8987 479.311C47.0983 478.98 47.2765 478.631 47.4317 478.265C47.4825 478.119 47.3471 477.951 47.2878 477.795L46.6787 478.767Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.1359 490.984L32.7196 490.984C32.7958 490.733 32.9734 490.314 32.9227 490.252C32.8719 490.189 32.6012 490.126 32.432 490.074C32.3558 490.346 32.2712 490.597 32.1359 490.984Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.1362 499.341L17.4069 499.926C17.6073 499.706 17.7887 499.461 17.9483 499.194C18.0076 499.079 17.8384 498.87 17.7707 498.703L17.1362 499.341Z" fill="white" fill-opacity="0.6"/>
        <path d="M711.978 540.415C712.185 540.126 712.375 539.818 712.545 539.494C712.587 539.41 712.24 539.358 712.206 539.4C711.967 539.663 711.749 539.954 711.555 540.268C711.529 540.32 711.783 540.352 711.978 540.415Z" fill="white" fill-opacity="0.6"/>
        <path d="M694.838 547.537C695.058 547.243 695.265 546.932 695.455 546.606C695.498 546.533 695.168 546.481 695.134 546.523C694.891 546.791 694.665 547.081 694.457 547.391C694.415 547.464 694.66 547.537 694.838 547.537Z" fill="white" fill-opacity="0.6"/>
        <path d="M678.781 553.687C678.451 553.949 678.07 554.21 678.061 554.252C677.997 554.464 677.957 554.686 677.943 554.911C678.146 554.781 678.339 554.627 678.518 554.451C678.63 554.211 678.719 553.954 678.781 553.687Z" fill="white" fill-opacity="0.6"/>
        <path d="M662.148 562.18C662.361 561.894 662.561 561.594 662.749 561.281C662.749 561.197 662.546 561.281 662.436 561.281C662.208 561.551 661.993 561.837 661.793 562.139C661.751 562.18 661.979 562.18 662.148 562.18Z" fill="white" fill-opacity="0.6"/>
        <path d="M646.522 569.355L646.869 569.471C647.051 569.275 647.224 569.065 647.385 568.843C647.385 568.697 647.25 568.676 647.165 568.613L646.522 569.355Z" fill="white" fill-opacity="0.6"/>
        <path d="M633.274 575.485C632.969 575.725 632.631 575.976 632.614 576.018C632.517 576.217 632.435 576.428 632.369 576.646C632.554 576.518 632.729 576.371 632.893 576.206C633.038 575.981 633.165 575.739 633.274 575.485Z" fill="white" fill-opacity="0.6"/>
        <path d="M618.503 583.758C618.833 583.423 619.188 583.089 619.349 582.827C619.408 582.743 618.909 582.827 618.858 582.89C618.509 583.17 618.176 583.477 617.86 583.81C617.851 583.863 618.223 583.789 618.503 583.758Z" fill="white" fill-opacity="0.6"/>
        <path d="M592.818 593.558C593.115 593.311 593.397 593.038 593.664 592.742C593.723 592.669 593.207 592.659 593.156 592.742C592.816 592.964 592.491 593.22 592.183 593.506C592.15 593.475 592.53 593.475 592.818 593.558Z" fill="white" fill-opacity="0.6"/>
        <path d="M566.676 597.648L566.769 598.171C567.161 598.05 567.545 597.897 567.92 597.711C568.055 597.637 567.92 597.439 567.92 597.292L566.676 597.648Z" fill="white" fill-opacity="0.6"/>
        <path d="M541.931 597.052L542.024 597.637C542.392 597.583 542.757 597.496 543.115 597.376C543.242 597.376 543.115 597.073 543.115 596.916L541.931 597.052Z" fill="white" fill-opacity="0.6"/>
        <path d="M519.215 592.167C519.473 592.049 519.718 591.891 519.943 591.697C519.943 591.644 519.537 591.278 519.495 591.278C519.2 591.34 518.915 591.456 518.649 591.623C518.649 591.623 518.962 591.916 519.215 592.167Z" fill="white" fill-opacity="0.6"/>
        <path d="M498.725 580.62C498.448 580.513 498.165 580.429 497.879 580.369C497.845 580.632 497.845 580.901 497.879 581.164C498.099 581.227 498.471 581.405 498.522 581.331C498.573 581.258 498.666 580.882 498.725 580.62Z" fill="white" fill-opacity="0.6"/>
        <path d="M477.55 562.944C477.276 562.752 476.994 562.58 476.704 562.431C476.671 562.74 476.671 563.053 476.704 563.362C476.932 563.488 477.313 563.791 477.363 563.728C477.414 563.666 477.499 563.226 477.55 562.944Z" fill="white" fill-opacity="0.6"/>
        <path d="M458.396 540.53L457.635 539.965L457.812 541.126C458.007 541.251 458.345 541.555 458.379 541.471C458.428 541.161 458.434 540.842 458.396 540.53Z" fill="white" fill-opacity="0.6"/>
        <path d="M441.552 517.205C441.747 517.132 441.967 517.122 442.085 516.954C442.018 516.655 441.915 516.369 441.78 516.107C441.592 516.021 441.385 516.021 441.197 516.107C441.294 516.483 441.412 516.85 441.552 517.205Z" fill="white" fill-opacity="0.6"/>
        <path d="M427.61 492.103L427.001 491.57C427.001 491.967 427.06 492.375 427.085 492.772C427.246 492.887 427.508 493.18 427.542 493.097C427.598 492.77 427.621 492.436 427.61 492.103Z" fill="white" fill-opacity="0.6"/>
        <path d="M414.759 469.856L414.192 469.448C414.192 469.835 414.192 470.212 414.251 470.599C414.395 470.682 414.641 470.923 414.674 470.839C414.733 470.516 414.761 470.187 414.759 469.856Z" fill="white" fill-opacity="0.6"/>
        <path d="M402.306 451.312C402.306 451.688 402.247 451.929 402.221 452.159C402.393 452.302 402.586 452.405 402.788 452.462C402.847 452.462 402.915 451.908 402.873 451.845C402.701 451.641 402.51 451.462 402.306 451.312Z" fill="white" fill-opacity="0.6"/>
        <path d="M390.944 440.361C391.11 440.157 391.255 439.929 391.375 439.681C391.375 439.597 391.223 439.378 391.138 439.221C390.97 439.387 390.817 439.577 390.682 439.785C390.648 439.806 390.842 440.11 390.944 440.361Z" fill="white" fill-opacity="0.6"/>
        <path d="M379.184 435.895C379.368 435.673 379.535 435.432 379.684 435.173C379.684 435.1 379.506 434.901 379.481 434.932C379.282 435.121 379.101 435.334 378.939 435.57C378.939 435.57 379.066 435.738 379.184 435.895Z" fill="white" fill-opacity="0.6"/>
        <path d="M348.356 461.039C348.864 460.62 349.295 460.275 349.727 459.888C349.769 459.888 350.006 459.47 349.896 459.522C349.596 459.69 349.313 459.896 349.05 460.139C348.802 460.419 348.571 460.72 348.356 461.039Z" fill="white" fill-opacity="0.6"/>
        <path d="M325.133 478.056C325.548 477.774 325.895 477.554 326.25 477.293C326.25 477.293 326.487 476.926 326.402 476.937C326.166 477.023 325.941 477.15 325.734 477.313C325.517 477.539 325.316 477.788 325.133 478.056Z" fill="white" fill-opacity="0.6"/>
        <path d="M307.088 487.386L306.546 487.867L307.088 487.867C307.24 487.7 307.528 487.438 307.502 487.386C307.477 487.334 307.223 487.386 307.088 487.386Z" fill="white" fill-opacity="0.6"/>
        <path d="M291.716 491.58L291.665 492.019L292.367 491.705C292.317 491.59 292.325 491.402 292.215 491.381C292.041 491.415 291.873 491.482 291.716 491.58Z" fill="white" fill-opacity="0.6"/>
        <path d="M280.43 489.833L280.041 490.116C280.126 490.293 280.219 490.461 280.312 490.639C280.422 490.492 280.633 490.325 280.633 490.231C280.633 490.137 280.506 490.011 280.43 489.833Z" fill="white" fill-opacity="0.6"/>
        <path d="M271.226 483.599C271.226 483.83 271.149 484.049 271.107 484.269L271.573 484.269C271.573 484.049 271.615 483.756 271.573 483.641C271.53 483.526 271.344 483.62 271.226 483.599Z" fill="white" fill-opacity="0.6"/>
        <path d="M264.686 473.276L264.381 473.485C264.381 473.81 264.441 474.123 264.466 474.427C264.568 474.291 264.728 474.144 264.745 473.998C264.755 473.755 264.735 473.512 264.686 473.276Z" fill="white" fill-opacity="0.6"/>
        <path d="M259.001 460.286C259.077 460.52 259.168 460.747 259.271 460.965C259.366 460.749 259.434 460.516 259.474 460.275C259.474 459.993 259.432 459.627 259.356 459.606C259.28 459.585 259.17 459.951 259.001 460.286Z" fill="white" fill-opacity="0.6"/>
        <path d="M255.126 443.624L254.847 443.854C254.847 444.272 254.847 444.701 254.847 445.12C254.94 444.942 255.101 444.785 255.126 444.597C255.147 444.273 255.147 443.948 255.126 443.624Z" fill="white" fill-opacity="0.6"/>
        <path d="M251.192 425.801L250.905 426.063L250.905 427.443C251.006 427.245 251.167 427.067 251.192 426.858C251.213 426.506 251.213 426.153 251.192 425.801Z" fill="white" fill-opacity="0.6"/>
        <path d="M246.48 407.916L246.252 409.108L246.641 409.276C246.697 408.889 246.731 408.497 246.742 408.104C246.708 407.874 246.573 407.958 246.48 407.916Z" fill="white" fill-opacity="0.6"/>
        <path d="M239.788 385.805L239.323 385.941C239.399 386.61 239.466 387.269 239.543 387.949C239.686 387.75 239.932 387.625 239.949 387.343C239.934 386.825 239.88 386.31 239.788 385.805Z" fill="white" fill-opacity="0.6"/>
        <path d="M227.149 359.814L226.573 360.232C226.709 360.797 226.844 361.372 226.971 361.937C227.14 361.665 227.445 361.383 227.445 361.121C227.399 360.67 227.299 360.229 227.149 359.814Z" fill="white" fill-opacity="0.6"/>
        <path d="M209.103 345.014L209.433 346.06C209.729 345.673 210.017 345.286 210.279 344.909C210.093 344.616 209.932 344.114 209.704 344.093C209.476 344.072 209.323 344.689 209.103 345.014Z" fill="white" fill-opacity="0.6"/>
        <path d="M191.084 342.043L190.483 342.985L190.838 343.905C191.043 343.532 191.213 343.132 191.346 342.713C191.371 342.378 191.185 342.252 191.084 342.043Z" fill="white" fill-opacity="0.6"/>
        <path d="M175.297 348.81C175.255 349.197 175.221 349.595 175.187 349.982C175.458 349.616 175.728 349.26 176.033 348.936C176.033 348.643 176.033 348.141 175.906 348.131C175.779 348.12 175.5 348.518 175.297 348.81Z" fill="white" fill-opacity="0.6"/>
        <path d="M161.397 357.931L161.169 357.45L160.483 359.165C160.627 359.165 160.737 359.333 160.906 359.165C161.103 358.775 161.267 358.362 161.397 357.931Z" fill="white" fill-opacity="0.6"/>
        <path d="M146.465 371.036L146.668 371.434L147.328 369.739C147.201 369.739 147.099 369.551 146.939 369.739C146.751 370.154 146.593 370.588 146.465 371.036Z" fill="white" fill-opacity="0.6"/>
        <path d="M132.811 385.418L133.014 385.742L133.64 384.058C133.513 384.058 133.411 383.943 133.251 384.173C133.077 384.573 132.93 384.989 132.811 385.418Z" fill="white" fill-opacity="0.6"/>
        <path d="M119.359 401.776C119.621 401.421 119.841 401.159 120.07 400.793C120.155 400.501 120.204 400.194 120.213 399.883C120.146 399.789 119.943 399.883 119.807 400.218C119.63 400.725 119.481 401.245 119.359 401.776Z" fill="white" fill-opacity="0.6"/>
        <path d="M106.212 418.595C106.474 418.229 106.694 417.946 106.914 417.549C106.987 417.275 107.027 416.99 107.033 416.702C106.957 416.639 106.753 416.796 106.618 417.078C106.456 417.572 106.32 418.079 106.212 418.595Z" fill="white" fill-opacity="0.6"/>
        <path d="M93.3019 434.87C93.3019 435.11 93.3019 435.34 93.3019 435.581C93.5134 435.152 93.7334 434.723 93.9534 434.294C93.8857 434.179 93.8603 433.886 93.7503 433.981C93.5783 434.259 93.4281 434.556 93.3019 434.87Z" fill="white" fill-opacity="0.6"/>
        <path d="M80.1383 451.824L80.1891 452.473C80.4006 452.065 80.6121 451.657 80.832 451.249C80.7559 451.144 80.722 450.883 80.6036 450.977C80.4275 451.241 80.2718 451.524 80.1383 451.824Z" fill="white" fill-opacity="0.6"/>
        <path d="M66.7035 468.664C66.9658 468.339 67.1858 468.078 67.4142 467.764C67.4142 467.764 67.5073 467.126 67.4142 467.095C67.3211 467.063 67.0927 467.231 66.9658 467.461C66.8504 467.852 66.7626 468.254 66.7035 468.664Z" fill="white" fill-opacity="0.6"/>
        <path d="M53.1166 483.223C53.3874 482.93 53.6074 482.71 53.8358 482.418C53.8358 482.418 53.9119 481.832 53.8358 481.811C53.7596 481.79 53.4973 481.916 53.3789 482.125C53.2551 482.476 53.167 482.845 53.1166 483.223Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.9529 494.613L39.5722 494.509L39.1746 495.554C39.3353 495.492 39.513 495.554 39.6568 495.345C39.7839 495.12 39.8837 494.874 39.9529 494.613Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.4524 504.654L25.0379 504.487C24.911 504.8 24.7756 505.114 24.6487 505.439C24.8228 505.448 24.9963 505.409 25.1563 505.323C25.2807 505.119 25.3805 504.894 25.4524 504.654Z" fill="white" fill-opacity="0.6"/>
        <path d="M9.8011 511.17L9.44578 511.735L10.0718 511.965C10.148 511.756 10.3341 511.432 10.2833 511.348C10.2326 511.264 9.97031 511.223 9.8011 511.17Z" fill="white" fill-opacity="0.6"/>
        <path d="M1.54416 504.079C1.64568 504.382 1.71334 504.57 1.78102 504.759C1.9992 504.643 2.20404 504.491 2.3902 504.309C2.44096 504.236 2.30556 503.776 2.24634 503.786C2.00476 503.854 1.76949 503.952 1.54416 504.079Z" fill="white" fill-opacity="0.6"/>
        <path d="M716.944 545.843L717.274 546.042L718.027 545.184C717.849 545.132 717.663 545.132 717.485 545.184C717.325 545.289 717.13 545.623 716.944 545.843Z" fill="white" fill-opacity="0.6"/>
        <path d="M699.127 552.694L699.322 552.987L700.168 552.129C699.998 552.129 699.863 552.024 699.668 552.129C699.474 552.295 699.292 552.484 699.127 552.694Z" fill="white" fill-opacity="0.6"/>
        <path d="M682.275 559.252L681.649 559.858L682.131 559.973C682.317 559.754 682.647 559.419 682.638 559.346C682.63 559.273 682.41 559.273 682.275 559.252Z" fill="white" fill-opacity="0.6"/>
        <path d="M664.559 567.41C664.889 567.243 665.16 567.128 665.456 566.96C665.456 566.96 665.769 566.5 665.727 566.458C665.642 566.436 665.555 566.436 665.471 566.458C665.386 566.479 665.306 566.522 665.236 566.584C664.993 566.836 664.766 567.112 664.559 567.41Z" fill="white" fill-opacity="0.6"/>
        <path d="M648.747 574.303C648.747 574.397 648.798 574.491 648.815 574.575L649.72 573.686C649.602 573.686 649.517 573.602 649.34 573.686C649.128 573.87 648.93 574.077 648.747 574.303Z" fill="white" fill-opacity="0.6"/>
        <path d="M634.314 580.767L633.688 581.331L634.001 581.446C634.194 581.262 634.378 581.062 634.551 580.85C634.585 580.735 634.407 580.777 634.314 580.767Z" fill="white" fill-opacity="0.6"/>
        <path d="M369.76 444.429L369.574 444.252L368.939 444.973C369.058 444.973 369.151 444.973 369.303 444.973C369.47 444.812 369.623 444.63 369.76 444.429Z" fill="white" fill-opacity="0.6"/>
        <path d="M381.875 444.555C381.816 444.398 381.765 444.241 381.706 444.095L381.105 444.586C381.206 444.691 381.299 444.869 381.435 444.879C381.57 444.89 381.722 444.67 381.875 444.555Z" fill="white" fill-opacity="0.6"/>
        <path d="M392.687 452.933C392.923 453.153 393.118 453.351 393.321 453.508C393.321 453.508 393.55 453.153 393.524 453.017C393.502 452.903 393.461 452.796 393.403 452.704C393.344 452.612 393.27 452.536 393.186 452.483C393.059 452.452 392.915 452.713 392.687 452.933Z" fill="white" fill-opacity="0.6"/>
        <path d="M404.81 468.8L405.258 468.8C405.14 468.433 405.013 468.057 404.886 467.691C404.751 467.806 404.522 467.848 404.514 468.036C404.505 468.224 404.708 468.528 404.81 468.8Z" fill="white" fill-opacity="0.6"/>
        <path d="M417.644 488.965L417.441 488.034L416.823 488.034C416.919 488.344 417.044 488.639 417.196 488.913C417.322 489.112 417.5 488.965 417.644 488.965Z" fill="white" fill-opacity="0.6"/>
        <path d="M430.469 510.752C430.469 510.992 430.419 511.233 430.385 511.484L431.155 512.268C431.155 511.957 431.109 511.649 431.019 511.359C430.901 511.097 430.655 510.93 430.469 510.752Z" fill="white" fill-opacity="0.6"/>
        <path d="M446.459 536.304L446.163 535.342L445.452 535.248C445.585 535.582 445.747 535.897 445.934 536.189C446.087 536.356 446.281 536.273 446.459 536.304Z" fill="white" fill-opacity="0.6"/>
        <path d="M462.753 558.52C463.125 558.948 463.438 559.304 463.76 559.618C463.76 559.618 464.022 559.367 463.954 559.189C463.821 558.85 463.62 558.558 463.371 558.342C463.159 558.216 463.007 558.415 462.753 558.52Z" fill="white" fill-opacity="0.6"/>
        <path d="M483.057 578.978C483.184 579.229 483.311 579.48 483.446 579.731L484.368 579.731C484.181 579.431 483.962 579.163 483.717 578.936C483.505 578.852 483.252 578.915 483.057 578.978Z" fill="white" fill-opacity="0.6"/>
        <path d="M504.207 593.109L503.497 593.171L504.123 593.914C504.317 593.83 504.698 593.778 504.681 593.673C504.664 593.569 504.393 593.297 504.207 593.109Z" fill="white" fill-opacity="0.6"/>
        <path d="M525.51 601.622C525.51 601.79 525.467 601.957 525.442 602.125L526.643 602.125C526.559 601.968 526.559 601.737 526.373 601.654C526.087 601.605 525.797 601.594 525.51 601.622Z" fill="white" fill-opacity="0.6"/>
        <path d="M550.535 605.252L550.069 604.938L549.037 605.451C549.277 605.552 549.528 605.605 549.782 605.607C550.044 605.528 550.297 605.409 550.535 605.252Z" fill="white" fill-opacity="0.6"/>
        <path d="M574.671 603.892L575.517 603.421L574.671 603.306C574.443 603.495 574.037 603.756 574.045 603.85C574.053 603.944 574.426 603.892 574.671 603.892Z" fill="white" fill-opacity="0.6"/>
        <path d="M599.417 597.125L599.831 597.209L600.973 596.372C600.725 596.381 600.477 596.426 600.237 596.508C599.953 596.691 599.679 596.897 599.417 597.125Z" fill="white" fill-opacity="0.6"/>
        <path d="M620 587.879L619.746 588.339L620.592 587.743C620.592 587.638 620.694 587.45 620.592 587.471C620.386 587.585 620.187 587.721 620 587.879Z" fill="white" fill-opacity="0.6"/>
        <path d="M230.101 298.982L230.177 300.185C230.27 300.122 230.44 300.112 230.448 299.997C230.465 299.621 230.465 299.243 230.448 298.867L230.101 298.982Z" fill="white" fill-opacity="0.6"/>
        <path d="M245.042 323.551C245.042 323.949 245.042 324.388 245.042 324.827C245.118 324.827 245.245 324.89 245.253 324.765C245.27 324.357 245.27 323.948 245.253 323.541L245.042 323.551Z" fill="white" fill-opacity="0.6"/>
        <path d="M254.517 357.094C254.517 357.094 254.627 357.094 254.644 357.031C254.644 356.728 254.712 356.372 254.745 355.985C254.745 355.985 254.635 355.912 254.627 355.985C254.585 356.372 254.551 356.739 254.517 357.094Z" fill="white" fill-opacity="0.6"/>
        <path d="M258.916 376.956C258.846 377.126 258.784 377.301 258.73 377.479C258.73 377.553 258.73 377.846 258.73 378.034C258.803 377.861 258.868 377.683 258.925 377.5C258.931 377.319 258.928 377.137 258.916 376.956Z" fill="white" fill-opacity="0.6"/>
        <path d="M262.165 398.544C262.165 398.869 262.114 399.193 262.08 399.517C262.08 399.517 262.165 399.517 262.173 399.517C262.216 399.245 262.233 398.921 262.258 398.618L262.165 398.544Z" fill="white" fill-opacity="0.6"/>
        <path d="M265.633 419.16C265.575 419.291 265.524 419.427 265.481 419.568C265.481 419.62 265.481 419.881 265.481 420.049C265.548 419.916 265.604 419.776 265.65 419.63C265.651 419.473 265.646 419.316 265.633 419.16Z" fill="white" fill-opacity="0.6"/>
        <path d="M269.601 437.62C269.601 437.882 269.601 438.143 269.559 438.405C269.559 438.405 269.652 438.405 269.66 438.405C269.669 438.405 269.66 437.913 269.66 437.673L269.601 437.62Z" fill="white" fill-opacity="0.6"/>
        <path d="M275.024 453.058L275.024 453.707C275.024 453.707 275.126 453.707 275.134 453.634C275.143 453.425 275.143 453.215 275.134 453.006L275.024 453.058Z" fill="white" fill-opacity="0.6"/>
        <path d="M282.461 464.365C282.461 464.532 282.46 464.72 282.503 464.909C282.503 464.909 282.63 464.909 282.63 464.804C282.638 464.63 282.638 464.455 282.63 464.281L282.461 464.365Z" fill="white" fill-opacity="0.6"/>
        <path d="M292.553 470.599C292.553 470.735 292.613 470.881 292.638 471.027C292.697 471.027 292.807 470.933 292.807 470.881C292.798 470.738 292.772 470.597 292.731 470.463L292.553 470.599Z" fill="white" fill-opacity="0.6"/>
        <path d="M306.183 470.567C306.092 470.696 306.013 470.837 305.946 470.986C305.946 470.986 306.047 471.059 306.098 471.09C306.196 470.954 306.284 470.807 306.36 470.651C306.36 470.651 306.242 470.599 306.183 470.567Z" fill="white" fill-opacity="0.6"/>
        <path d="M323.602 463.601C323.494 463.758 323.398 463.926 323.314 464.103C323.314 464.103 323.45 464.103 323.517 464.103C323.636 463.936 323.743 463.758 323.839 463.57C323.839 463.57 323.686 463.591 323.602 463.601Z" fill="white" fill-opacity="0.6"/>
        <path d="M345.64 448.571C345.522 448.78 345.361 449.01 345.285 449.188C345.209 449.366 345.454 449.188 345.547 449.115C345.683 448.885 345.852 448.644 345.945 448.446C345.962 448.404 345.75 448.519 345.64 448.571Z" fill="white" fill-opacity="0.6"/>
        <path d="M372.958 424.484C372.806 424.756 372.611 425.059 372.518 425.268C372.425 425.477 372.721 425.164 372.839 425.101C372.941 424.881 373.135 424.567 373.22 424.358C373.237 424.316 373 424.484 372.958 424.484Z" fill="white" fill-opacity="0.6"/>
        <path d="M209.797 288.272C209.856 288.607 209.907 288.931 209.958 289.318C210.076 289.192 210.288 289.056 210.296 288.952C210.28 288.63 210.237 288.311 210.169 288L209.797 288.272Z" fill="white" fill-opacity="0.6"/>
        <path d="M193.825 292.121L193.875 292.989C193.974 292.843 194.057 292.681 194.121 292.508C194.138 292.226 194.138 291.943 194.121 291.661L193.825 292.121Z" fill="white" fill-opacity="0.6"/>
        <path d="M178.495 301.242L178.622 301.493C178.749 301.126 178.884 300.75 178.977 300.384C178.977 300.3 178.892 300.248 178.842 300.175L178.495 301.242Z" fill="white" fill-opacity="0.6"/>
        <path d="M163.588 313.081C163.588 313.291 163.588 313.531 163.656 313.772C163.744 313.588 163.821 313.395 163.884 313.196C163.888 312.962 163.874 312.727 163.842 312.496C163.757 312.684 163.673 312.872 163.588 313.081Z" fill="white" fill-opacity="0.6"/>
        <path d="M148.792 328.938L148.927 329.084C149.037 328.708 149.164 328.321 149.249 327.955C149.249 327.871 149.155 327.881 149.105 327.85L148.792 328.938Z" fill="white" fill-opacity="0.6"/>
        <path d="M134.308 346.122C134.308 346.29 134.367 346.478 134.401 346.677C134.469 346.468 134.604 346.175 134.613 346.091C134.605 345.894 134.583 345.697 134.545 345.505L134.308 346.122Z" fill="white" fill-opacity="0.6"/>
        <path d="M119.875 365.294L119.977 365.828C120.056 365.645 120.127 365.456 120.188 365.263C120.179 365.075 120.153 364.889 120.112 364.709L119.875 365.294Z" fill="white" fill-opacity="0.6"/>
        <path d="M105.51 385.313C105.51 385.47 105.594 385.638 105.628 385.795C105.71 385.618 105.778 385.433 105.831 385.24C105.817 385.072 105.786 384.907 105.738 384.749L105.51 385.313Z" fill="white" fill-opacity="0.6"/>
        <path d="M91.1532 405.437L91.3055 405.824C91.3761 405.652 91.4355 405.474 91.4832 405.291C91.463 405.139 91.4199 404.994 91.3562 404.862L91.1532 405.437Z" fill="white" fill-opacity="0.6"/>
        <path d="M76.7373 424.829L76.9065 425.184C76.9821 425.03 77.0418 424.865 77.0842 424.693C77.0552 424.551 77.0066 424.416 76.9404 424.295C76.8727 424.473 76.805 424.64 76.7373 424.829Z" fill="white" fill-opacity="0.6"/>
        <path d="M62.372 443.153C62.4479 443.009 62.5104 442.855 62.5581 442.693C62.5338 442.545 62.4879 442.403 62.4227 442.275C62.3455 442.418 62.2776 442.568 62.2197 442.725C62.2512 442.877 62.3027 443.022 62.372 443.153Z" fill="white" fill-opacity="0.6"/>
        <path d="M47.5246 458.905L47.7107 458.905C47.7783 458.644 47.863 458.372 47.9053 458.121C47.9053 458.121 47.7783 458.121 47.7107 458.121C47.6514 458.403 47.5922 458.633 47.5246 458.905Z" fill="white" fill-opacity="0.6"/>
        <path d="M32.7702 471.111C32.7115 471.33 32.6635 471.554 32.6264 471.781C32.6264 471.843 32.7533 471.781 32.8209 471.864C32.8852 471.627 32.9389 471.387 32.9817 471.143C32.9817 471.09 32.8463 471.122 32.7702 471.111Z" fill="white" fill-opacity="0.6"/>
        <path d="M17.5591 480.399C17.4993 480.603 17.454 480.813 17.4237 481.027C17.4237 481.089 17.5506 481.11 17.6183 481.152C17.6812 480.933 17.7349 480.709 17.7791 480.483C17.7791 480.483 17.6352 480.42 17.5591 480.399Z" fill="white" fill-opacity="0.6"/>
        <path d="M717.578 521.243C717.605 521.391 717.639 521.538 717.68 521.682C717.756 521.567 717.908 521.41 717.917 521.347C717.905 521.194 717.88 521.043 717.841 520.897L717.578 521.243Z" fill="white" fill-opacity="0.6"/>
        <path d="M701.783 528.188C701.667 528.393 701.56 528.606 701.462 528.826C701.462 528.888 701.547 528.909 701.597 528.951C701.716 528.732 701.851 528.491 701.953 528.271C701.97 528.229 701.843 528.219 701.783 528.188Z" fill="white" fill-opacity="0.6"/>
        <path d="M685.709 536.335L685.836 536.461C685.963 536.241 686.115 536.011 686.217 535.792C686.217 535.792 686.132 535.718 686.09 535.687C685.963 535.896 685.845 536.116 685.709 536.335Z" fill="white" fill-opacity="0.6"/>
        <path d="M670.82 543.354C670.701 543.563 670.557 543.782 670.464 543.981C670.371 544.18 670.532 544.065 670.574 544.096C670.712 543.883 670.84 543.66 670.955 543.427C670.955 543.427 670.87 543.354 670.82 543.354Z" fill="white" fill-opacity="0.6"/>
        <path d="M656.15 550.895C656.02 551.096 655.898 551.305 655.786 551.522C655.786 551.585 655.845 551.606 655.879 551.637C656.015 551.418 656.158 551.188 656.268 550.979C656.378 550.769 656.192 550.926 656.15 550.895Z" fill="white" fill-opacity="0.6"/>
        <path d="M641.903 558.677C641.903 558.802 641.903 558.938 641.903 559.084C641.988 558.969 642.14 558.823 642.148 558.76C642.178 558.624 642.192 558.483 642.191 558.342C642.106 558.446 642.005 558.561 641.903 558.677Z" fill="white" fill-opacity="0.6"/>
        <path d="M627.589 566.364L627.749 566.667C627.885 566.542 628.13 566.343 628.13 566.291C628.13 566.239 628.062 566.071 628.012 565.967C627.876 566.134 627.724 566.26 627.589 566.364Z" fill="white" fill-opacity="0.6"/>
        <path d="M601.151 577.273L601.405 577.378C601.582 577.169 601.794 576.959 601.938 576.761C602.082 576.562 601.76 576.708 601.667 576.687L601.151 577.273Z" fill="white" fill-opacity="0.6"/>
        <path d="M575.043 582.44L575.221 582.869C575.356 582.796 575.593 582.691 575.602 582.639C575.58 582.486 575.534 582.34 575.466 582.21L575.043 582.44Z" fill="white" fill-opacity="0.6"/>
        <path d="M549.714 582.639C549.773 582.806 549.824 582.973 549.883 583.13C550.01 583.13 550.238 583.015 550.238 582.963C550.226 582.799 550.182 582.642 550.112 582.503L549.714 582.639Z" fill="white" fill-opacity="0.6"/>
        <path d="M525.848 577.796L526.076 578.068C526.233 577.969 526.38 577.85 526.516 577.712C526.516 577.712 526.364 577.545 526.279 577.451L525.848 577.796Z" fill="white" fill-opacity="0.6"/>
        <path d="M504.309 567.044L504.469 567.64C504.571 567.64 504.757 567.64 504.749 567.588C504.726 567.391 504.686 567.198 504.63 567.013L504.309 567.044Z" fill="white" fill-opacity="0.6"/>
        <path d="M482.837 549.138C482.727 549.2 482.583 549.242 482.516 549.357C482.448 549.472 482.651 549.64 482.727 549.797C482.858 549.746 482.981 549.668 483.091 549.566C483.019 549.413 482.934 549.269 482.837 549.138Z" fill="white" fill-opacity="0.6"/>
        <path d="M463.058 526.514L463.235 527.006C463.357 526.981 463.472 526.924 463.574 526.838C463.574 526.838 463.447 526.535 463.379 526.368L463.058 526.514Z" fill="white" fill-opacity="0.6"/>
        <path d="M446.501 501.349C446.501 501.621 446.577 501.893 446.611 502.165C446.687 502.165 446.831 502.332 446.831 502.259C446.82 502.006 446.794 501.754 446.755 501.506L446.501 501.349Z" fill="white" fill-opacity="0.6"/>
        <path d="M432.094 476.822L432.229 477.334C432.331 477.291 432.424 477.22 432.5 477.125C432.466 476.957 432.421 476.792 432.364 476.634L432.094 476.822Z" fill="white" fill-opacity="0.6"/>
        <path d="M419.37 454.062C419.37 454.219 419.437 454.376 419.48 454.533C419.581 454.474 419.671 454.389 419.742 454.282C419.715 454.121 419.675 453.963 419.623 453.811L419.37 454.062Z" fill="white" fill-opacity="0.6"/>
        <path d="M407.763 435.142C407.763 435.361 407.763 435.612 407.813 435.863C407.873 435.863 407.983 435.863 407.991 435.811C407.999 435.759 407.991 435.34 407.991 435.11L407.763 435.142Z" fill="white" fill-opacity="0.6"/>
        <path d="M396.477 422.674L396.477 423.333C396.544 423.333 396.654 423.27 396.663 423.207C396.675 423.002 396.675 422.796 396.663 422.59L396.477 422.674Z" fill="white" fill-opacity="0.6"/>
        <path d="M384.988 418.836C384.988 418.898 385.047 418.972 385.081 419.045C385.186 418.867 385.282 418.682 385.369 418.49C385.369 418.49 385.301 418.375 385.267 418.313L384.988 418.836Z" fill="white" fill-opacity="0.6"/>
        <path d="M355.31 446.218C355.555 446.051 355.784 445.904 356.055 445.695C356.139 445.622 356.325 445.381 356.469 445.214L355.682 445.758C355.55 445.9 355.425 446.054 355.31 446.218Z" fill="white" fill-opacity="0.6"/>
        <path d="M332.197 463.936C331.984 464.015 331.775 464.109 331.571 464.218C331.46 464.304 331.363 464.414 331.284 464.543C331.284 464.605 331.394 464.616 331.444 464.543C331.698 464.375 331.943 464.145 332.197 463.936Z" fill="white" fill-opacity="0.6"/>
        <path d="M311.969 474.929C312.131 474.916 312.293 474.888 312.452 474.845C312.546 474.778 312.631 474.693 312.705 474.594C312.532 474.608 312.36 474.636 312.189 474.678C312.108 474.751 312.035 474.835 311.969 474.929Z" fill="white" fill-opacity="0.6"/>
        <path d="M296.843 478.182C296.963 478.239 297.088 478.281 297.215 478.307C297.215 478.307 297.35 478.192 297.418 478.129C297.291 478.07 297.161 478.021 297.029 477.983C297.029 477.983 296.936 478.108 296.843 478.182Z" fill="white" fill-opacity="0.6"/>
        <path d="M285.235 475.263C285.315 475.378 285.406 475.48 285.506 475.567C285.506 475.567 285.616 475.483 285.667 475.431C285.58 475.311 285.487 475.2 285.388 475.096C285.388 475.096 285.286 475.201 285.235 475.263Z" fill="white" fill-opacity="0.6"/>
        <path d="M276.852 467.492C276.784 467.314 276.733 467.116 276.665 466.948C276.598 466.781 276.547 466.948 276.53 467.063C276.507 467.17 276.507 467.281 276.53 467.388C276.633 467.439 276.741 467.474 276.852 467.492Z" fill="white" fill-opacity="0.6"/>
        <path d="M270.185 454.449L270.05 454.533C270.05 454.753 270.117 454.993 270.16 455.182C270.202 455.37 270.244 455.182 270.286 455.182L270.185 454.449Z" fill="white" fill-opacity="0.6"/>
        <path d="M265.194 438.478C265.194 438.729 265.194 439.001 265.27 439.21C265.346 439.419 265.346 439.21 265.388 439.137C265.388 438.865 265.337 438.583 265.304 438.342C265.295 438.3 265.227 438.426 265.194 438.478Z" fill="white" fill-opacity="0.6"/>
        <path d="M261.353 419.934C261.353 420.216 261.353 420.53 261.353 420.781C261.353 420.865 261.429 420.781 261.463 420.781C261.463 420.467 261.463 420.132 261.463 419.85C261.454 419.756 261.403 419.861 261.353 419.934Z" fill="white" fill-opacity="0.6"/>
        <path d="M257.859 399.695C257.859 399.988 257.859 400.322 257.909 400.573C257.909 400.647 257.986 400.511 258.028 400.469C258.028 400.145 258.028 399.81 257.977 399.517C257.969 399.465 257.901 399.632 257.859 399.695Z" fill="white" fill-opacity="0.6"/>
        <path d="M253.874 379.383C253.874 379.686 253.874 380.011 253.95 380.262C253.95 380.335 254.035 380.167 254.085 380.115C254.085 379.801 254.043 379.456 254.009 379.174C254.001 379.143 253.925 379.31 253.874 379.383Z" fill="white" fill-opacity="0.6"/>
        <path d="M247.571 354.155L247.36 354.155C247.427 354.615 247.487 355.107 247.571 355.525C247.571 355.672 247.715 355.609 247.783 355.651C247.715 355.149 247.647 354.647 247.571 354.155Z" fill="white" fill-opacity="0.6"/>
        <path d="M235.414 326.375C235.304 325.973 235.177 325.579 235.034 325.193C235.034 325.12 234.814 325.193 234.78 325.392C234.762 325.622 234.785 325.854 234.847 326.072C235.023 326.208 235.214 326.31 235.414 326.375Z" fill="white" fill-opacity="0.6"/>
        <path d="M217.208 308.27C217.368 308.53 217.546 308.771 217.741 308.992C217.809 309.044 217.969 308.814 218.088 308.72C217.917 308.439 217.731 308.173 217.53 307.925C217.53 307.925 217.327 308.145 217.208 308.27Z" fill="white" fill-opacity="0.6"/>
        <path d="M198.385 306.733L198.977 306.095C198.926 305.969 198.901 305.718 198.833 305.76C198.642 305.924 198.461 306.106 198.292 306.304C198.325 306.45 198.359 306.586 198.385 306.733Z" fill="white" fill-opacity="0.6"/>
        <path d="M182.852 312.799C182.982 312.858 183.12 312.879 183.258 312.862C183.355 312.686 183.439 312.5 183.512 312.307C183.374 312.244 183.227 312.212 183.08 312.213C182.991 312.4 182.915 312.596 182.852 312.799Z" fill="white" fill-opacity="0.6"/>
        <path d="M167.869 323.101C167.997 323.142 168.131 323.142 168.258 323.101C168.357 322.92 168.442 322.727 168.512 322.526C168.375 322.495 168.234 322.495 168.097 322.526C168.012 322.712 167.936 322.904 167.869 323.101Z" fill="white" fill-opacity="0.6"/>
        <path d="M153.267 336.542C153.394 336.552 153.521 336.527 153.639 336.468C153.738 336.283 153.823 336.087 153.893 335.883C153.765 335.87 153.635 335.888 153.512 335.935C153.416 336.128 153.334 336.331 153.267 336.542Z" fill="white" fill-opacity="0.6"/>
        <path d="M138.902 352.565C139.039 352.519 139.17 352.448 139.291 352.356C139.291 352.356 139.435 351.959 139.519 351.749C139.377 351.79 139.24 351.857 139.113 351.948C139.113 351.948 138.978 352.356 138.902 352.565Z" fill="white" fill-opacity="0.6"/>
        <path d="M124.782 370.273C124.915 370.226 125.041 370.152 125.154 370.053C125.247 369.867 125.326 369.671 125.391 369.468C125.254 369.51 125.123 369.577 125.002 369.666C124.919 369.863 124.846 370.066 124.782 370.273Z" fill="white" fill-opacity="0.6"/>
        <path d="M110.764 389.131C110.899 389.064 111.027 388.976 111.144 388.87C111.187 388.87 111.288 388.483 111.373 388.284C111.231 388.344 111.098 388.429 110.975 388.535C110.892 388.726 110.821 388.926 110.764 389.131Z" fill="white" fill-opacity="0.6"/>
        <path d="M96.7707 408.428C96.9147 408.335 97.0483 408.219 97.1683 408.083C97.248 407.906 97.3159 407.72 97.3713 407.529C97.2229 407.625 97.0814 407.737 96.9483 407.864C96.8806 408.048 96.8213 408.236 96.7707 408.428Z" fill="white" fill-opacity="0.6"/>
        <path d="M83.387 426.513C83.2393 426.591 83.0977 426.685 82.964 426.795C82.8886 426.955 82.8316 427.128 82.7947 427.307C82.7947 427.423 82.8794 427.485 82.9132 427.423C83.0739 427.151 83.2262 426.826 83.387 426.513Z" fill="white" fill-opacity="0.6"/>
        <path d="M68.6495 445.318C68.7956 445.231 68.9345 445.126 69.0641 445.005C69.137 444.849 69.1965 444.684 69.2417 444.513C69.0907 444.608 68.9439 444.713 68.8018 444.827C68.7397 444.985 68.6887 445.15 68.6495 445.318Z" fill="white" fill-opacity="0.6"/>
        <path d="M54.352 461.53C54.5007 461.482 54.6432 461.408 54.775 461.311C54.8546 461.173 54.9226 461.026 54.978 460.871C54.821 460.924 54.6682 460.994 54.5212 461.081C54.4538 461.224 54.3972 461.374 54.352 461.53Z" fill="white" fill-opacity="0.6"/>
        <path d="M39.936 475.535L40.4014 474.856C40.3337 474.856 40.2575 474.741 40.2068 474.856C40.0479 475.017 39.8954 475.188 39.7499 475.368L39.936 475.535Z" fill="white" fill-opacity="0.6"/>
        <path d="M25.4863 485.827C25.3258 485.812 25.1646 485.812 25.0041 485.827C24.9267 485.925 24.8738 486.049 24.8518 486.183C24.8518 486.267 24.9703 486.361 25.0126 486.329C25.1777 486.173 25.3359 486.005 25.4863 485.827Z" fill="white" fill-opacity="0.6"/>
        <path d="M9.46287 493.243C9.62336 493.259 9.78461 493.259 9.9451 493.243C9.9874 493.243 10.055 493.044 10.1143 492.929C9.94506 492.929 9.77588 492.929 9.60667 492.929C9.57283 492.919 9.51363 493.149 9.46287 493.243Z" fill="white" fill-opacity="0.6"/>
        <path d="M2.00952 485.283C1.94729 485.477 1.89641 485.677 1.85725 485.88C1.85725 485.942 1.98414 486.005 2.06028 486.068C2.12804 485.864 2.18458 485.654 2.22949 485.44C2.22949 485.44 2.08566 485.336 2.00952 485.283Z" fill="white" fill-opacity="0.6"/>
        <path d="M706.403 534.442C706.546 534.463 706.691 534.463 706.834 534.442C706.938 534.349 707.034 534.244 707.122 534.129C706.973 534.112 706.823 534.112 706.673 534.129C706.577 534.225 706.487 534.33 706.403 534.442Z" fill="white" fill-opacity="0.6"/>
        <path d="M690.016 541.816C690.156 541.835 690.298 541.835 690.439 541.816C690.489 541.816 690.625 541.607 690.718 541.492C690.569 541.472 690.418 541.472 690.269 541.492C690.178 541.592 690.093 541.7 690.016 541.816Z" fill="white" fill-opacity="0.6"/>
        <path d="M674.119 549.295C674.256 549.292 674.392 549.271 674.525 549.232C674.624 549.136 674.715 549.027 674.796 548.908C674.654 548.911 674.513 548.928 674.373 548.96C674.281 549.062 674.196 549.174 674.119 549.295Z" fill="white" fill-opacity="0.6"/>
        <path d="M658.84 556.773C658.968 556.774 659.096 556.757 659.221 556.721C659.321 556.623 659.415 556.514 659.5 556.396C659.371 556.382 659.24 556.382 659.111 556.396C659.111 556.396 658.933 556.658 658.84 556.773Z" fill="white" fill-opacity="0.6"/>
        <path d="M644.331 564.147C644.448 564.148 644.565 564.131 644.678 564.094C644.778 563.996 644.872 563.888 644.957 563.77C644.835 563.773 644.713 563.791 644.593 563.822C644.502 563.925 644.414 564.033 644.331 564.147Z" fill="white" fill-opacity="0.6"/>
        <path d="M630.744 571.28C630.85 571.271 630.954 571.253 631.057 571.228C631.057 571.228 631.235 571.019 631.328 570.914C631.218 570.899 631.108 570.899 630.998 570.914C630.906 571.028 630.822 571.15 630.744 571.28Z" fill="white" fill-opacity="0.6"/>
        <path d="M609.958 581.007C610.195 580.955 610.415 580.934 610.677 580.85C610.815 580.748 610.947 580.636 611.075 580.516C610.822 580.55 610.57 580.602 610.322 580.672C610.192 580.769 610.07 580.881 609.958 581.007Z" fill="white" fill-opacity="0.6"/>
        <path d="M583.969 588.496C584.2 588.512 584.431 588.512 584.662 588.496C584.814 588.429 584.958 588.341 585.094 588.234C584.857 588.234 584.637 588.182 584.375 588.234C584.233 588.307 584.098 588.394 583.969 588.496Z" fill="white" fill-opacity="0.6"/>
        <path d="M558.352 590.891C558.58 590.958 558.812 591.007 559.045 591.038C559.18 590.996 559.308 590.929 559.426 590.839C559.186 590.765 558.943 590.709 558.698 590.672C558.575 590.723 558.458 590.797 558.352 590.891Z" fill="white" fill-opacity="0.6"/>
        <path d="M534.054 588.025C534.252 588.142 534.455 588.243 534.663 588.329C534.799 588.317 534.933 588.282 535.061 588.224C534.843 588.091 534.62 587.972 534.393 587.868C534.276 587.905 534.162 587.958 534.054 588.025Z" fill="white" fill-opacity="0.6"/>
        <path d="M511.559 579.961C511.748 580.13 511.946 580.284 512.151 580.421C512.21 580.421 512.363 580.421 512.473 580.348C512.261 580.181 512.067 580.003 511.847 579.857C511.821 579.794 511.661 579.888 511.559 579.961Z" fill="white" fill-opacity="0.6"/>
        <path d="M490.544 565.831C490.737 566.084 490.94 566.325 491.153 566.552C491.272 566.611 491.398 566.639 491.526 566.636C491.314 566.375 491.111 566.103 490.883 565.862C490.832 565.81 490.671 565.841 490.544 565.831Z" fill="white" fill-opacity="0.6"/>
        <path d="M470.621 545.906C470.443 545.602 470.274 545.289 470.079 544.996C470.037 544.933 469.825 544.881 469.809 544.943C469.812 545.1 469.857 545.25 469.935 545.372C470.164 545.581 470.367 545.728 470.621 545.906Z" fill="white" fill-opacity="0.6"/>
        <path d="M452.043 521.086C452.176 521.398 452.323 521.702 452.482 521.996C452.533 522.09 452.66 522.1 452.753 522.153C452.609 521.818 452.466 521.483 452.296 521.169C452.263 521.096 452.127 521.107 452.043 521.086Z" fill="white" fill-opacity="0.6"/>
        <path d="M436.721 496.287C436.83 496.603 436.954 496.91 437.094 497.207C437.144 497.301 437.254 497.301 437.339 497.354C437.212 497.019 437.094 496.674 436.95 496.36C436.916 496.287 436.797 496.287 436.721 496.287Z" fill="white" fill-opacity="0.6"/>
        <path d="M423.219 472.617C423.338 472.9 423.439 473.203 423.566 473.454C423.566 473.527 423.701 473.454 423.777 473.454C423.651 473.151 423.541 472.837 423.414 472.555C423.388 472.534 423.287 472.607 423.219 472.617Z" fill="white" fill-opacity="0.6"/>
        <path d="M411.028 452.012C411.118 452.267 411.219 452.515 411.333 452.755C411.333 452.828 411.468 452.755 411.544 452.755C411.443 452.483 411.349 452.19 411.24 451.939C411.214 451.939 411.104 452.002 411.028 452.012Z" fill="white" fill-opacity="0.6"/>
        <path d="M399.489 436.512C399.577 436.701 399.676 436.883 399.785 437.056C399.785 437.056 399.92 437.056 399.988 436.972C399.892 436.766 399.788 436.567 399.675 436.376C399.675 436.376 399.556 436.46 399.489 436.512Z" fill="white" fill-opacity="0.6"/>
        <path d="M388.135 428.385L388.584 428.28C388.584 428.186 388.533 428.029 388.491 428.029C388.351 428.035 388.212 428.059 388.076 428.103C388.1 428.195 388.119 428.29 388.135 428.385Z" fill="white" fill-opacity="0.6"/>
        <path d="M376.164 428.856C376.271 428.865 376.379 428.865 376.486 428.856C376.565 428.769 376.638 428.674 376.706 428.573C376.593 428.552 376.479 428.552 376.367 428.573C376.29 428.656 376.222 428.751 376.164 428.856Z" fill="white" fill-opacity="0.6"/>
        </g>
        <rect width="1600" height="1200" fill="#151D21"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1600" height="1200">
        <rect width="1600" height="1200" fill="#060039"/>
        </mask>
        <g mask="url(#mask0)">
        <path d="M562 -37.0939C562 -3.90524 588.863 23 622 23C655.137 23 682 -3.90524 682 -37.0939V-105" stroke="#44AB96" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M673 866.036C676.59 866.036 679.5 863.126 679.5 859.536C679.5 855.946 676.59 853.036 673 853.036V866.036ZM567.622 1028.96C571.212 1028.96 574.122 1026.05 574.122 1022.46C574.122 1018.87 571.212 1015.96 567.622 1015.96V1028.96ZM366.84 1312.5C370.43 1312.5 373.34 1309.59 373.34 1306C373.34 1302.41 370.43 1299.5 366.84 1299.5V1312.5ZM574.655 1107.05C574.655 1103.46 571.745 1100.55 568.155 1100.55C564.565 1100.55 561.655 1103.46 561.655 1107.05H574.655ZM396.262 482C396.262 478.41 393.351 475.5 389.762 475.5C386.172 475.5 383.262 478.41 383.262 482H396.262ZM666.5 773.376C666.5 776.966 669.41 779.876 673 779.876C676.59 779.876 679.5 776.966 679.5 773.376H666.5ZM673 853.036H493.563V866.036H673V853.036ZM493.563 853.036C444.778 853.036 405.169 892.385 405.169 940.996H418.169C418.169 899.63 451.892 866.036 493.563 866.036V853.036ZM405.169 940.996C405.169 989.607 444.778 1028.96 493.563 1028.96V1015.96C451.892 1015.96 418.169 982.363 418.169 940.996H405.169ZM493.563 1028.96H567.622V1015.96H493.563V1028.96ZM366.84 1299.5H356.143V1312.5H366.84V1299.5ZM356.143 1299.5C328.181 1299.5 305.5 1276.8 305.5 1248.8H292.5C292.5 1283.97 320.988 1312.5 356.143 1312.5V1299.5ZM305.5 1248.8C305.5 1220.78 328.182 1198.08 356.143 1198.08V1185.08C320.987 1185.08 292.5 1213.62 292.5 1248.8H305.5ZM356.143 1198.08H483.723V1185.08H356.143V1198.08ZM483.723 1198.08C533.95 1198.08 574.655 1157.32 574.655 1107.05H561.655C561.655 1150.15 526.756 1185.08 483.723 1185.08V1198.08ZM383.262 482V546.285H396.262V482H383.262ZM383.262 546.285C383.262 628.172 449.569 694.566 531.38 694.566V681.566C456.763 681.566 396.262 621.007 396.262 546.285H383.262ZM531.38 694.566H587.787V681.566H531.38V694.566ZM587.787 694.566C631.252 694.566 666.5 729.844 666.5 773.376H679.5C679.5 722.678 638.445 681.566 587.787 681.566V694.566Z" fill="#FE655D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M588 327C610.091 327 628 309.091 628 287C628 264.909 610.091 247 588 247C565.909 247 548 264.909 548 287C548 309.091 565.909 327 588 327Z" stroke="#44AB96" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M184 214C218.794 214 247 185.794 247 151C247 116.206 218.794 88 184 88C149.206 88 121 116.206 121 151C121 185.794 149.206 214 184 214Z" stroke="#44AB96" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M184.5 288C262.095 288 325 225.992 325 149.5C325 73.0084 262.095 11 184.5 11C106.905 11 44 73.0084 44 149.5" stroke="#FFB017" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M481.83 1113.5C485.42 1113.5 488.33 1110.59 488.33 1107C488.33 1103.41 485.42 1100.5 481.83 1100.5V1113.5ZM356.371 853.553C358.128 850.423 357.016 846.46 353.886 844.703C350.755 842.945 346.793 844.058 345.036 847.188L356.371 853.553ZM587 775.5C590.59 775.5 593.5 772.59 593.5 769C593.5 765.41 590.59 762.5 587 762.5V775.5ZM388.363 795.169C385.531 797.375 385.024 801.459 387.23 804.291C389.436 807.123 393.52 807.63 396.352 805.424L388.363 795.169ZM481.83 1100.5C401.007 1100.5 334.5 1028.54 334.5 938.512H321.5C321.5 1034.59 392.739 1113.5 481.83 1113.5V1100.5ZM334.5 938.512C334.5 907.319 342.516 878.227 356.371 853.553L345.036 847.188C330.093 873.799 321.5 905.082 321.5 938.512H334.5ZM587 762.5H482.203V775.5H587V762.5ZM482.203 762.5C447.131 762.5 414.712 774.642 388.363 795.169L396.352 805.424C420.566 786.561 450.208 775.5 482.203 775.5V762.5Z" fill="#FE655D"/>
        <path d="M328.5 308C296.743 308 271 333.743 271 365.5C271 397.257 296.743 423 328.5 423C360.257 423 386 397.257 386 365.5V308" stroke="#44AB96" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M346 -28.5C346 13.7495 380.026 48 422 48C463.974 48 498 13.7495 498 -28.5C498 -70.7498 463.974 -105 422 -105H346" stroke="#E53855" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M71.5 760.582C34.7731 760.582 5 731.127 5 694.791C5 658.455 34.7731 629 71.5 629C108.227 629 138 658.455 138 694.791V896" stroke="#44AB96" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M62.5 462.708C30.7436 462.708 5 488.069 5 519.354C5 550.639 30.7436 576 62.5 576C94.2564 576 120 550.639 120 519.354V288" stroke="#FE655D" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M688.704 288C688.704 231.115 643.327 185 587.352 185C531.377 185 486 231.115 486 288C486 344.885 531.377 391 587.352 391H853" stroke="#FFB017" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M266.5 765C233.639 765 207 738.406 207 705.6V536.4C207 503.594 233.639 477 266.5 477V477C299.361 477 326 503.594 326 536.4V705.6C326 738.406 299.361 765 266.5 765V765Z" stroke="#FFB017" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M71.75 846H66.9392C32.7312 846 5 873.758 5 908V908C5 942.242 32.7311 970 66.9392 970H210.061C244.269 970 272 942.242 272 908V908C272 873.758 244.269 846 210.061 846H205.25" stroke="#FFB017" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M497 946H704.802C783.888 946 848 1010.47 848 1090C848 1169.52 783.888 1234 704.802 1234H642.288" stroke="#44AB96" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M586 595H536.813C503.779 595 477 568.137 477 535C477 501.863 503.779 475 536.813 475H635.187C668.221 475 695 501.863 695 535V540.33C695 570.524 670.6 595 640.5 595" stroke="#6BB5FF" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M675 97H540.319C455.091 97 386 166.396 386 252" stroke="#8F52FF" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M44.5 220C44.5 229.313 44.5 346.548 44.5 404" stroke="#44AB96" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <rect width="720" height="541" fill="url(#paint0_linear)"/>
        <g clip-path="url(#clip2)">
        <path d="M75.0479 187.133V171.943H72V165.198H75.0479V162.509C75.0298 161.072 75.2515 159.642 75.7038 158.278C76.0972 157.084 76.719 155.978 77.534 155.024C78.3135 154.126 79.2823 153.415 80.3696 152.943C81.5494 152.445 82.819 152.201 84.0978 152.224C86.1611 152.224 88.4436 152.696 90.9453 153.639L89.442 160.434C88.8828 160.237 88.3108 160.079 87.7302 159.961C87.199 159.844 86.6577 159.78 86.1141 159.771C84.6747 159.771 83.9556 160.573 83.9569 162.176V165.203H89.35V171.949H83.9588V187.133H75.0479Z" fill="white"/>
        <path d="M103.325 187.606C101.104 187.606 99.1578 187.252 97.4866 186.544C95.9035 185.896 94.4676 184.93 93.2661 183.706C92.1298 182.535 91.2508 181.136 90.6861 179.6C89.5311 176.449 89.5311 172.986 90.6861 169.835C91.2513 168.301 92.1303 166.902 93.2661 165.731C94.4682 164.508 95.9039 163.543 97.4866 162.893C99.1591 162.187 101.105 161.834 103.325 161.834C105.512 161.834 107.45 162.187 109.139 162.893C110.731 163.54 112.175 164.505 113.384 165.731C114.523 166.904 115.404 168.305 115.972 169.843C117.129 172.993 117.129 176.457 115.972 179.608C115.407 181.143 114.529 182.542 113.393 183.714C112.185 184.94 110.74 185.906 109.148 186.552C107.455 187.254 105.514 187.606 103.325 187.606ZM98.9636 174.728C98.9636 176.396 99.3701 177.701 100.183 178.643C100.579 179.088 101.063 179.444 101.604 179.688C102.146 179.932 102.732 180.058 103.325 180.058C103.918 180.058 104.505 179.932 105.046 179.688C105.587 179.444 106.072 179.088 106.467 178.643C107.279 177.701 107.685 176.396 107.687 174.728C107.688 173.061 107.281 171.756 106.467 170.813C106.072 170.368 105.587 170.012 105.046 169.768C104.505 169.524 103.918 169.398 103.325 169.398C102.732 169.398 102.146 169.524 101.604 169.768C101.063 170.012 100.579 170.368 100.183 170.813C99.3701 171.754 98.9636 173.059 98.9636 174.726V174.728Z" fill="white"/>
        <path d="M131.132 187.606C128.914 187.606 126.967 187.252 125.294 186.544C123.711 185.895 122.275 184.93 121.073 183.706C119.938 182.535 119.059 181.136 118.495 179.6C117.338 176.45 117.338 172.986 118.495 169.835C119.06 168.301 119.938 166.902 121.073 165.731C122.276 164.508 123.711 163.543 125.294 162.893C126.966 162.187 128.912 161.834 131.132 161.834C133.319 161.834 135.257 162.187 136.946 162.893C138.538 163.539 139.982 164.505 141.191 165.731C142.326 166.903 143.205 168.301 143.771 169.835C144.926 172.986 144.926 176.449 143.771 179.6C143.205 181.135 142.326 182.534 141.191 183.706C139.983 184.933 138.538 185.899 136.946 186.544C135.259 187.252 133.321 187.606 131.132 187.606ZM126.771 174.728C126.771 176.396 127.177 177.701 127.99 178.643C128.386 179.088 128.87 179.444 129.411 179.688C129.953 179.932 130.539 180.058 131.132 180.058C131.725 180.058 132.312 179.932 132.853 179.688C133.395 179.444 133.879 179.088 134.274 178.643C135.086 177.701 135.492 176.396 135.494 174.728C135.495 173.061 135.088 171.756 134.274 170.813C133.879 170.368 133.395 170.012 132.853 169.768C132.312 169.524 131.725 169.398 131.132 169.398C130.539 169.398 129.953 169.524 129.411 169.768C128.87 170.012 128.386 170.368 127.99 170.813C127.177 171.754 126.771 173.059 126.771 174.726V174.728Z" fill="white"/>
        <path d="M157.111 187.606C155.507 187.622 153.918 187.299 152.445 186.66C151.043 186.046 149.788 185.137 148.764 183.994C147.702 182.797 146.882 181.403 146.349 179.89C145.754 178.219 145.458 176.454 145.474 174.679C145.459 172.948 145.728 171.226 146.271 169.584C146.756 168.096 147.511 166.713 148.499 165.504C149.431 164.374 150.593 163.459 151.906 162.819C153.267 162.164 154.759 161.833 156.267 161.853C157.796 161.836 159.304 162.209 160.651 162.937C161.953 163.633 163.021 164.7 163.724 166.004V152.192H172.633V177.226C172.633 178.075 172.757 178.666 173.009 178.995C173.26 179.324 173.694 179.523 174.324 179.585V187.133C173.089 187.411 171.831 187.569 170.566 187.604C167.533 187.604 165.782 186.456 165.314 184.16L165.126 183.265C164.359 184.701 163.161 185.857 161.704 186.567C160.27 187.257 158.7 187.612 157.111 187.606ZM159.554 180.058C161.183 180.058 162.589 179.209 163.775 177.511V173.074C163.377 172.032 162.714 171.113 161.852 170.41C161.105 169.77 160.159 169.412 159.178 169.396C158.55 169.388 157.929 169.534 157.369 169.82C156.82 170.105 156.34 170.507 155.963 170.999C155.557 171.526 155.24 172.115 155.023 172.745C154.788 173.428 154.673 174.146 154.681 174.868C154.681 176.441 155.127 177.699 156.019 178.641C156.911 179.583 158.088 180.055 159.55 180.056L159.554 180.058Z" fill="white"/>
        <path d="M186.232 187.606C184.785 187.618 183.355 187.287 182.058 186.639C180.769 185.986 179.635 185.061 178.734 183.926C177.76 182.694 177.013 181.295 176.53 179.797C175.986 178.131 175.717 176.386 175.733 174.632C175.719 172.895 176.013 171.169 176.602 169.536C177.131 168.047 177.934 166.67 178.969 165.48C179.991 164.321 181.249 163.397 182.657 162.771C184.064 162.144 185.589 161.83 187.128 161.849C189.007 161.849 190.587 162.227 191.866 162.984C193.175 163.767 194.283 164.849 195.1 166.144V162.32H202.838V186.803C202.838 187.651 202.994 188.241 203.308 188.572C203.621 188.904 204.185 189.1 204.999 189.161V196.708C204.159 196.875 203.311 196.995 202.458 197.066C201.55 197.145 200.753 197.185 200.066 197.185C199.259 197.191 198.457 197.055 197.697 196.784C196.989 196.531 196.331 196.156 195.752 195.675C195.167 195.189 194.699 194.576 194.382 193.881C194.065 193.187 193.909 192.43 193.923 191.666V183.453C193.24 184.768 192.177 185.845 190.875 186.542C189.562 187.251 188.015 187.606 186.232 187.606ZM189.703 180.058C191.331 180.058 192.738 179.209 193.923 177.511V173.075C193.526 172.031 192.862 171.112 191.999 170.41C191.252 169.77 190.308 169.411 189.327 169.396C188.7 169.389 188.08 169.534 187.521 169.82C186.973 170.105 186.493 170.507 186.116 170.999C185.711 171.526 185.393 172.116 185.176 172.745C184.939 173.427 184.82 174.145 184.825 174.868C184.825 176.441 185.27 177.699 186.161 178.641C187.051 179.583 188.232 180.055 189.703 180.056V180.058Z" fill="white"/>
        <path d="M218.495 187.606C216.274 187.606 214.327 187.252 212.656 186.544C211.073 185.895 209.638 184.93 208.436 183.706C207.299 182.535 206.42 181.136 205.856 179.6C204.701 176.449 204.701 172.986 205.856 169.835C206.421 168.301 207.3 166.902 208.436 165.731C209.638 164.508 211.074 163.543 212.656 162.893C214.329 162.187 216.275 161.834 218.495 161.834C220.682 161.834 222.62 162.187 224.309 162.893C225.9 163.54 227.345 164.505 228.554 165.731C229.688 166.903 230.567 168.301 231.132 169.835C232.289 172.986 232.289 176.45 231.132 179.6C230.567 181.135 229.689 182.534 228.554 183.706C227.345 184.933 225.901 185.899 224.309 186.544C222.621 187.252 220.683 187.606 218.495 187.606ZM214.133 174.728C214.133 176.396 214.54 177.701 215.353 178.643C215.748 179.088 216.232 179.444 216.774 179.688C217.315 179.932 217.902 180.058 218.495 180.058C219.088 180.058 219.674 179.932 220.216 179.688C220.757 179.444 221.241 179.088 221.637 178.643C222.448 177.701 222.855 176.396 222.856 174.728C222.857 173.061 222.451 171.756 221.637 170.813C221.241 170.368 220.757 170.012 220.216 169.768C219.674 169.524 219.088 169.398 218.495 169.398C217.902 169.398 217.315 169.524 216.774 169.768C216.232 170.012 215.748 170.368 215.353 170.813C214.54 171.754 214.133 173.059 214.133 174.726V174.728Z" fill="white"/>
        <path d="M175.254 113.259C174.429 113.246 173.615 113.07 172.858 112.741C172.101 112.411 171.416 111.935 170.842 111.338L153.975 93.8516C152.84 92.6421 152.224 91.0314 152.26 89.3679C152.295 87.7045 152.98 86.122 154.165 84.9627C155.35 83.8035 156.941 83.1607 158.594 83.1735C160.246 83.1862 161.827 83.8534 162.995 85.0307L179.862 102.517C180.728 103.415 181.311 104.552 181.536 105.784C181.76 107.015 181.618 108.286 181.125 109.436C180.632 110.586 179.812 111.563 178.768 112.243C177.724 112.923 176.503 113.277 175.26 113.259H175.254Z" fill="white"/>
        <path d="M159.603 128.558C158.359 128.54 157.148 128.152 156.124 127.441C155.1 126.73 154.308 125.73 153.849 124.566C153.39 123.401 153.284 122.126 153.545 120.902C153.806 119.677 154.422 118.558 155.315 117.686L170.966 102.381C172.167 101.238 173.767 100.618 175.419 100.654C177.071 100.69 178.643 101.379 179.794 102.572C180.945 103.766 181.584 105.368 181.571 107.032C181.559 108.695 180.896 110.287 179.727 111.463L164.077 126.77C163.485 127.35 162.785 127.807 162.017 128.114C161.25 128.421 160.429 128.572 159.603 128.558Z" fill="white"/>
        <path d="M118.602 111.949C117.359 111.93 116.149 111.541 115.126 110.83C114.102 110.119 113.311 109.119 112.853 107.955C112.394 106.791 112.288 105.517 112.548 104.293C112.809 103.069 113.424 101.95 114.316 101.077L142.227 73.788C143.428 72.6455 145.028 72.025 146.68 72.0608C148.332 72.0966 149.903 72.7858 151.055 73.9794C152.206 75.1729 152.844 76.7748 152.832 78.4386C152.819 80.1024 152.157 81.6943 150.987 82.87L123.078 110.161C122.486 110.741 121.786 111.198 121.017 111.505C120.249 111.811 119.429 111.962 118.602 111.949Z" fill="white"/>
        <path d="M132.993 126.393C131.749 126.375 130.538 125.986 129.514 125.275C128.489 124.564 127.698 123.563 127.239 122.398C126.78 121.234 126.674 119.959 126.936 118.734C127.197 117.509 127.813 116.39 128.706 115.518L142.279 102.249C142.867 101.65 143.568 101.176 144.341 100.853C145.113 100.531 145.942 100.367 146.778 100.37C147.615 100.374 148.442 100.546 149.211 100.876C149.981 101.206 150.677 101.687 151.26 102.291C151.842 102.895 152.299 103.61 152.604 104.394C152.909 105.178 153.055 106.016 153.035 106.858C153.014 107.699 152.827 108.529 152.484 109.297C152.141 110.065 151.649 110.756 151.038 111.331L137.465 124.6C136.873 125.18 136.174 125.637 135.406 125.945C134.639 126.253 133.819 126.405 132.993 126.393Z" fill="white"/>
        <path d="M148.544 139.471C151.996 139.471 154.794 136.654 154.794 133.178C154.794 129.703 151.996 126.885 148.544 126.885C145.093 126.885 142.294 129.703 142.294 133.178C142.294 136.654 145.093 139.471 148.544 139.471Z" fill="white"/>
        <path d="M185.338 123.926C188.789 123.926 191.588 121.109 191.588 117.633C191.588 114.157 188.789 111.34 185.338 111.34C181.886 111.34 179.088 114.157 179.088 117.633C179.088 121.109 181.886 123.926 185.338 123.926Z" fill="white"/>
        </g>
        </g>
        <defs>
        <linearGradient id="paint0_linear" x1="360" y1="0" x2="360" y2="541" gradientUnits="userSpaceOnUse">
        <stop stop-opacity="0.81"/>
        <stop offset="1" stop-opacity="0"/>
        </linearGradient>
        <clipPath id="clip0">
        <rect width="720" height="1024" fill="white"/>
        </clipPath>
        <clipPath id="clip1">
        <rect width="1000" height="1047" fill="white" transform="translate(0 -23)"/>
        </clipPath>
        <clipPath id="clip2">
        <rect width="160" height="125.181" fill="white" transform="translate(72 72)"/>
        </clipPath>
        </defs>
      </svg>
    </v-col> -->

    <v-col>
      <v-row justify="center" align="center" class="full-height">
        <v-col cols="11" sm="10">
          <v-card v-if="!this.login" class="mx-lg-auto mx-auto">
            <v-progress-linear
              v-if="loading"
              rounded
              color="primary"
              indeterminate
              absolute
            ></v-progress-linear>
            <div class="d-flex justify-center mt-10">
              <img src="../assets/foodqo-icon-white.svg" height="36px" width="36px" alt="foodqo icon" />
              <div class="font-weight-bold pt-1 pl-2 title-1">Backoffice</div>
            </div>
            <v-card-title class="pa-6 py-6 justify-center text-no-wrap">
              Login to your account
            </v-card-title>

            <v-card-text class="px-8 pt-0 pb-2">
              <v-form ref="form" v-model="form">
                <v-text-field
                  v-model="email"
                  :rules="email =='' ? [] : [rules.email]"
                  :disabled="isDisable"
                  clearable
                  clear-icon="mdi-close"
                  label="Email"
                  type="text"
                  hint="Write your email address"
                  @keyup.enter="onLogin"
                >
                </v-text-field>
                <v-text-field
                  class="pt-4"
                  v-if="!this.forget"
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :disabled="isDisable"
                  :type="show1 ? 'text' : 'password'"
                  label="Password"
                  hint="Write your password"
                  @click:append="show1 = !show1"
                  @keyup.enter="onLogin"
                >
                </v-text-field>
              </v-form>
            </v-card-text>

            <v-card-text class="text-center py-0 px-7">
              <v-flex v-if="!this.forget" ma-0 xs12 row wrap justify-space-between>
                <v-checkbox
                  class="ma-0"
                  v-model="checkbox"
                  label="Remember me"
                  required
                ></v-checkbox>
                <div class="primary--text text-center cursor-pointer" @click="onForget">Forgot Password?</div>
              </v-flex>
              <v-flex v-else xs12 row wrap ma-0 pt-3 pb-16 justify-end>
                <v-btn
                  :disabled="!form || isDisable || email.length <= 0 || password.length <= 0"
                  @click="onLogin"
                  class="primary font-weight-bold"
                >Login</v-btn>
              </v-flex>
            </v-card-text>
            <v-card-actions v-if="this.forget" class="justify-center pb-2">
              <v-btn color="primary text-center" text @click="onForget">Go Back to Login</v-btn>
            </v-card-actions>
            <v-card-actions v-else class="justify-center py-3 px-7 mb-8">
              <v-btn
                :disabled="!form || isDisable || email.length <= 0 || password.length <= 0"
                width="100%"
                @click="onLogin"
                class="primary font-weight-bold black--text"
              >Login</v-btn>
            </v-card-actions>

          </v-card>

          <v-card v-else class="py-5 px-7 mx-auto">
            <v-card-title class="justify-center my-2 text-no-wrap">
              Two Factor Authentication
            </v-card-title>

            <v-card-text class="mt-10">
              <v-form ref="form" v-model="form">
                <v-text-field
                  :rules="[rules.auth]"
                  label="Authentication Code"
                  type="text"
                  hint="Write the code sent to your phone"
                  outlined
                >
                </v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions class="mx-2">
              <v-btn @click="onVerify" :disabled="!form" block class="primary font-weight-bold px-5">
                Verify
              </v-btn>
            </v-card-actions>

          </v-card>

        </v-col>
      </v-row>
    </v-col>

    <v-snackbar
        v-model="snackbar"
        color="red"
        timeout="3000"
        top
        right
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import store from '../store'
import router from '../router'

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      checkbox: null,
      show1: false,
      form: false,
      forget: false,
      login: false,
      loading: false,
      snackbar: false,
      text: 'Something Went Wrong!',
      isDisable: false,
      rules: {
        email: v => !!(v || '').match(/^[A-Za-z0-9._-]+@foodqo.com$/) || 'Please enter a valid email',
        auth: v => (v || '').length > 0 || 'Please enter your code',
      },
    }
  },

  methods: {
    onForget() {
      this.forget = !this.forget
    },
    onLogin() {
      if (this.$refs.form.validate()) {
        this.isDisable = true
        this.loading = true
        this.$refs.form.resetValidation(); // Reset all validation first after submitting.
        store
          .dispatch("retrieveToken", {
            email: this.email,
            password: this.password
          })
          .then((res) => {
            if (res.status > 201) {
              this.text = res.data.message
              this.snackbar = true
              this.text = res.data.message
            }
            this.loading = false
            this.isDisable = false
          })
      }

      // this.$refs.form.reset()
    },
    onVerify() {
      // Authenticate against API, but not using now
      router.push('/')
    }
  },
}
</script>

<style scoped>
input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
}
  .bg {
    margin: 0;
    padding: 0;
    /* background-image: url(../assets/background-image-lg.svg); */
    /* background-size: cover; */
    /* background-repeat: repeat; */
  }
  svg {
    display: block;
    position: relative;
  }
  svg path {
    stroke-dasharray: 800;
    stroke-dashoffset: 800;
    animation: stroke 3s linear forwards;
  }
  @keyframes stroke {
    to {
      stroke-dashoffset: 0;
    }
  }

  .full-height {
    height: 100vh !important;
  }

  .v-card {
    background-color: #151D21 !important;
    border: 2px solid #31383C !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
    border-radius: 12px !important;
    font-family: 'Lato', sans-serif;
    /* height: 500px; */
    max-width: 458px;
  }

  .v-card__title {
    font-family: 'Raleway', sans-serif;
  }
</style>
