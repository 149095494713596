import axios from 'axios'

const state = {
  nbrList: '',
}

const getters = {
  getList: state => {
    return state.nbrList
  }
}

const mutations = {
  setNBRlist(state, payload) {
    state.nbrList = payload
  }
}

const actions = {
  async getNBRlist(context) {
    try {
      // console.log('foodcourt_id for edit in call', state.foodcourt_id)
      const res = await axios.get('/nbr/categories')
      if (res.data.data.length) {
        context.commit('setNBRlist', res.data.data[0].children)
      }
      // console.log('in attach branch call',payload, res)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getCircleParent (context, payload) {
    try {
      // console.log('foodcourt_id for edit in call', state.foodcourt_id)
      const res = await axios.get('/nbr/circle/' + payload + '/get-parent-role')
      return res.data.data
    } catch (error) {
      return error.response
    }
  },
  async attachCircle (context, payload) {
    try {
      // console.log('foodcourt_id for edit in call', state.foodcourt_id)
      await axios.post('/nbr/attach-circle', payload)
    } catch (error) {
      return error.response
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
};
