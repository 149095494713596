<template>
  <div>
    <TenantDetails v-if="tenantDetails" />
    <div v-else>
      <div justify="center" align="center">
        <div class="text-h3 font-weight-bold mb-1">404</div>
        <div class="text-body-1 mb-1">Oops! We couldn't find any restaurant.</div>
        <router-link :to="{ name: 'home' }">Back to Home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../main";
import TenantDetails from "../components/Tenant/SingleTenant/TenantDetails.vue";
export default {
  components: { TenantDetails },
  data() {
    return {
      tenantDetails: true,
    };
  },
  beforeMount() {
    eventBus.$on("empty-tenant", () => {
      this.tenantDetails = false;
    });
  },
  beforeDestroy() {
    eventBus.$off("empty-tenant");
  },
};
</script>

<style>
</style>
