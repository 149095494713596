<template>
  <div>
    <v-tabs
      background-color="#151D21"
      v-model="tab"
      fixed-tabs
      centered
      class="mt-n3"
    >
      <v-tab v-for="(item, i) in tabList" :key="i">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="background-color: #212429">
      <v-tab-item v-for="(item, i) in tabList" :key="i">
        <div v-if="loading" class="pt-16 text-center">
          <v-progress-circular size="80" color="primary" indeterminate />
          <p class="pt-8">Loading...</p>
        </div>
        <component v-else :is="item.component" :editBranchData="editBranchData" :step4edit="step4edit" :data="data" :editFc="editFc" :step2Status="step2Status"/>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar top right v-model="snackbar" :color="snackbarColor" timeout="3000">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { eventBus } from '../main'
import store from '../store'
export default {
  data() {
    return {
      tab: null,
      tabList: [
        {
          name: "Foodcourt",
          component: () => import("../components/Foodcourt/FoodcourtList"),
        },
        {
          name: "Add Foodcourt",
          component: () => import("../components/Foodcourt/AddFoodcourt"),
        }
      ],

      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      loading: false,
      data: '',
      step2Status: false,
      editFc: false,
      step4edit: false,
      editBranchData: ''
    }
  },
  beforeMount () {
    // console.log('event bus on for foodcourt')

    eventBus.$on('editBranch', (res) => {
      // console.log('branch item in fc page', res)
      // got to stepper 2 with initial branch info
      this.step4edit = true
      this.step2Status = false
      this.editBranchData = res
      this.tab = 1
      this.backToList()
    })

    eventBus.$on('step2', () => {
      // console.log('step 2 Moving')
      this.step2Status = true
      this.tab = 1
      this.backToList()
    })

    eventBus.$on('single-foodcourt', id => {
      // console.log('single foodcourt',id)
      this.getSingleFc(id, 0)
    })

    // this can be simplified in on event for the list and ad tab
    eventBus.$on('backToList', () => {
      this.step4edit = false
      this.step2Status = false
      this.tab = 0
      this.backToList()
    })
    eventBus.$on('backToAddFc', () => {
      this.editFc = true
      this.tab = 1
      this.backToList()
    })
    eventBus.$on('cancelEdit', () => {
      this.editFc = false
      this.tab = 0
      this.backToList()
    })

    eventBus.$on('snackbar', res => {
      if(res.go) {
        this.tab = 0
        this.backToList()
      }
      if(res.step2) {
        this.step2Status = true
        this.step4edit = false
        // console.log('from branch coming')
        this.getSingleFc(store.getters.FcId, 1)
      }
      this.snackbar = true
      this.snackbarText = res.snackbarText
      this.snackbarColor = res.snackbarColor
    })
  },
  methods: {
    getSingleFc(id, n) {
      this.data = ''
      // console.log('details page call')
      this.loading = true
      store.dispatch('getSingleFoodcourt', id)
      .then(res => {
        this.loading = false
        this.data = res.data.data
        this.tab = n
        this.backToDetails()
        // console.log('Single FC details ',this.data)
      })
      .catch(err => {
        console.log(err)
        this.loading = false
        this.snackbar = true
        this.snackbarText = '#FF5252'
        this.snackbarColor = "Something Went Wrong!"
      })
    },
    backToDetails() {
      this.tabList[0].name = 'Foodcourt Details'
      this.tabList[0].component = () => import("../components/Foodcourt/FoodcourtDetails")
      this.tabList[1].name = 'Foodcourt Branch'
      this.tabList[1].component = () => import("../components/Foodcourt/FoodcourtBranch")
    },
    backToList() {
      // console.log('tab no',this.tab)
      this.tabList[0].name = "Foodcourt"
      this.tabList[0].component = () => import("../components/Foodcourt/FoodcourtList")
      this.tabList[1].name = "Add Foodcourt"
      this.tabList[1].component = () => import("../components/Foodcourt/AddFoodcourt")
    }
  }
}
</script>
